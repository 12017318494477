import React, { Component } from 'react';
import '../HomeWaranty/HomeWarantyMain.scss';
import Image from '../../Assets/images/energy/energyMainImage.svg';
import Button from '../../components/atoms/Button';
import AutoComplete from '../../components/atoms/autoComplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { geocodeByAddress } from 'react-places-autocomplete';
import './EnergyPage.scss';
import * as EnergyAction from '../../store/energy/actions';
import localStorage from '../../utils/localStorage';
import AnalyticsService from '../../utils/analytics-service';
import * as CommonAction from '../../store/common/actions';
import { UIContext } from '../../UIContext';
import EnergySavingsNotFoundImage from '../../Assets/images/energy/energySavingsNotFoundImage.png'


type initialProps = {
    history?: any;
    energyAction?: any;
    energyState?: any;
    commonState?: any;
    commonAction?: any;
};

type initialState = {
    date: any;
    summaryData: any;
    address: string | null;
    address_data: {
        street: string;
        city: string;
        zipcode: string;
        address: string;
    };
    differenceInTime: any;
    anchorEl?: any;
    isAnalitycsTriggerredOnce: any;
};

export class EnergyMainPagePODS extends Component<initialProps, initialState> {
    appContext = this.context;
    state: initialState = {
        date: null,
        summaryData: [],
        differenceInTime: '',
        address: '',
        address_data: {
            street: '',
            city: '',
            zipcode: '',
            address: '',
        },
        anchorEl: null,
        isAnalitycsTriggerredOnce: {
            address: false,
            date: false
        }
    };

    componentDidMount() {
        this.props.energyAction.getEnergySummary();
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const addressObj = localStorage.getItem('pods_destination_address')
        if(addressObj){
            this.setState({address: JSON.parse(addressObj)?.address})
        }
        const getMovingDate = localStorage.getItem('pods_dashboard_moving_date');
        if(getMovingDate){
            let date1: any = new Date(getMovingDate);
            let date2: any = new Date();
            if (date1 > date2) {
                this.setState({date : getMovingDate});
                this.dateDiffrenceCalculator(getMovingDate)
            }
        }
        AnalyticsService.pageViewed({ page: "Energy Savings" });
    }

    componentDidUpdate(prevProps: any) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ summaryData: curEnergy.getEnergySummary });
        }
    }

    dateDiffrenceCalculator = (getMovingDate: any) => {
        let currentYear = new Date().getFullYear();
        let currentDate = new Date().getDate();
        let currentMonth =  new Date().getMonth();
        let date1: any = new Date(getMovingDate);
        let date2: any = new Date(currentYear, currentMonth, currentDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        if(diffDays === 0){
            this.setState({differenceInTime: "Today"})
        }else if(diffDays === 1){
            this.setState({differenceInTime: "Tomorrow"})
        }else{
            this.setState({differenceInTime: "In " + diffDays + " Days"})
        }
    }

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    analitycsTracking = (fieldName: string) => {
        AnalyticsService.formFieldInput({
            page: "Energy Savings",
            hasMinimalFields: true,
            category_name: 'Energy Savings',
            form_field_name: fieldName
        });
    }

    handleAddressChange = (address: string) => {
        if (!this.state.isAnalitycsTriggerredOnce.address) {
            this.analitycsTracking('Enter Address');
        }
        
        this.setState({
            isAnalitycsTriggerredOnce: {
                ...this.state.isAnalitycsTriggerredOnce,
                address: true
            }
        });

        this.setState({ address: address });
    }

    dateHandler = (e: any) => {
        if (!this.state.isAnalitycsTriggerredOnce.date) {
            this.analitycsTracking('Your Moving Date');
        }

        this.setState({
            isAnalitycsTriggerredOnce: {
                ...this.state.isAnalitycsTriggerredOnce,
                date: true
            }
        });
        
        this.setState({
            date: e,
        });

        let now:any = new Date(e);
        const dateNumber = now.getDate();
        const year = now.getFullYear();
        let monthnum: number = new Date(e).getMonth() + 1;
        let moving_date: any;
        
        if(!isNaN(e)) {
            moving_date = `${monthnum}/${dateNumber}/${year.toString().substring(2)}`;
            localStorage.setItem('pods_dashboard_moving_date', moving_date);
        }
        moving_date = year + "-" + monthnum + "-" + dateNumber  ;
        this.dateDiffrenceCalculator(moving_date);
        localStorage.setItem('pods_moving_date', moving_date);
    };

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                    const addressString = results[0].formatted_address;
                    let addressObj = {
                        street: `${streetNumber} ${route}`,
                        city,
                        zipcode: zip,
                        address: addressString,
                        state
                    };
                    localStorage.setItem('pods_destination_address', addressObj);

                    this.props.commonAction.header({ apiPath: "pods", slug: this.appContext.domain, state, city, zipcode: zip })
    
                    if (country === 'USA' || country === 'US') {
                        this.setState({
                            address: addressString,
                            address_data: { ...addressObj}
                        });
                    }

            })
            .catch(error => console.error('error', error));
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let showCompletedElectricity: boolean = false;
        let showCompletedGas: boolean = false;
        this.state.summaryData &&
            this.state.summaryData.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });
        // check for ES slug available or not
        let isESAvailable: any;
        isESAvailable = this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
            ? this.props.commonState.header.filter((el: any) => el.slug === 'energy-savings')
            : [];

        return (
            <div>
                <div style={{ marginTop: '40px' }}></div>
                <div className="main-head">
                    <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Energy Savings</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Energy Savings</p>
                    </div>
                </div>
                <div className="WarantycoverDiv">
                    <div className="landingPage">
                    {isESAvailable.length > 0 ? 
                    <div className="utility">
                        <img className="mainImg" src={Image} />
                        <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Save on your utilities </h2>
                        <p className="subtext"> Quickly and easily get multiple saving quotes in minutes!</p>
                        <div className="line"></div>
                        <div className="btn-div">
                        <div style={{ position: 'relative' }} className="control_box control_box1">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disablePast
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date"
                                        inputVariant="filled"
                                        label="Your Moving Date"
                                        className='dateInsurance1'
                                        value={this.state.date}
                                        onChange={(e: any) => {
                                            this.dateHandler(e);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Your Moving Date',
                                            onFocus: e => {
                                                this.handleClick(e);
                                            },
                                        }}
                                        autoOk={true}
                                    />
                                </MuiPickersUtilsProvider>
                                <p className="remaindays">{this.state.differenceInTime ? this.state.differenceInTime : ""}</p>
                            </div>
                            <div className='address-input'>
                                <AutoComplete
                                    value={this.state.address ? this.state.address : ''}
                                    onChange={e => this.handleAddressChange(e)}
                                    onSelect={this.handleSelect}
                                    placeholder="Enter address"
                                    className="input"
                                />
                            </div>

                            <div className="btn-div2">
                                <Button
                                    className="btnICbtn"
                                    height="50px"
                                    // width="125px"
                                    width="100%"
                                    borderRadius='4px'
                                    color={slug?.primary_font_color ? slug.primary_font_color : '#FFF'}
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                    // onClick={() => this.tabChangeHandler('addressCard')}
                                    disabled={
                                        this.state.date && this.state.address ? false : true
                                    }
                                    onClick={() => {
                                        AnalyticsService.buttonClicked({
                                            page: 'Energy Savings',
                                            category_name: 'Energy Savings',
                                            button_copy: 'Get Started',
                                            form_button: true,
                                        });

                                        this.props.history.push({
                                            pathname: '/dashboard/energy-savings/energySteps',
                                            state: {
                                                showCompletedElectricity: showCompletedElectricity,
                                                showCompletedGas: showCompletedGas,
                                            },
                                        })
                                    }}
                                >
                                    Get Started
                                </Button>
                            </div>
                        </div>
                    </div>
                    : (
                        <div className='utility-section energySavingsNotAvailableImg'>
                            <div className='utitlity-image'>
                                <img src={EnergySavingsNotFoundImage} />
                            </div>
                            <div className='energy-oops-text energySavingsNotAvailable'>
                                <span className="energy-oops-text-pera" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>The address you entered is not serviced at this time.</span>
                                <div className="backToHomeUtilitySteps">
                                    <Button
                                        borderRadius='4px'
                                        color={slug?.primary_font_color ? slug.primary_font_color : '#FFF'}
                                        onClick={() => {
                                            this.props.history.push('/dashboard');
                                            let slug: any = localStorage.getItem('slug');
                                            if (slug) {
                                                slug = JSON.parse(slug);
                                            }
                                        }}
                                    >
                                        Back to Dashboard
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
    commonState: state.common,
});
EnergyMainPagePODS.contextType = UIContext;
export default connect(mapStateToProps, mapDispatchToProps)(EnergyMainPagePODS)
