import React from 'react';
import { connect } from 'react-redux';
import './TrustedProsAlmostDone.scss';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import InfoIcon from '@material-ui/icons/Info';
import Button from '../../components/atoms/Button';
import SelectedVendor from '../../components/TrustedProsComponents/SelectedVendor';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import { formCompletedSegment, buttonClicked } from '../../utils/utlis';

type InitialProps = {
    history: any;
    location: any;
    commonState: any;
    commonAction: any;
    trustedProsAction: any;
    trustedProsState: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type InitialState = {
    fullName: string;
    address: string;
    date: string;
    time: string;
    isEmail: boolean;
    isPhone: boolean;
    email: string;
    phone: string;
    selectedVendors: any;
    slug: any;
    buffer: boolean;
    isDataArrived: boolean;
    serviceName: any;
    serviceId: any;
    homeTaskId: any;
    isDataArrived2: boolean;
    checklistDataArrived: boolean;
    checklistTaskId: any;
    timeOptions: any;
    dateOptions: any;
    dateError: string;
    communicationError: string;
    moveId: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

class TrustedProsAlmostDone extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        fullName: '',
        address: '',
        date: '',
        time: '',
        isEmail: false,
        isPhone: false,
        email: '',
        phone: '',
        buffer: false,
        isDataArrived: true,
        slug: { primary_color: '' },
        selectedVendors: [],
        serviceId: '',
        serviceName: '',
        homeTaskId: '',
        isDataArrived2: true,
        checklistDataArrived: true,
        checklistTaskId: '',
        dateOptions: [
            { value: 'Tomorrow', label: 'Tomorrow' },
            { value: 'Within 3 days', label: 'Within 3 days' },
            { value: 'Within a week', label: 'Within a week' },
            { value: 'Within 30 days', label: 'Within 30 days' },
        ],
        timeOptions: [
            { value: 'Morning (8am - 12pm)', label: 'Morning (8am - 12pm)' },
            { value: 'Afternoon (12pm - 5pm)', label: 'Afternoon (12pm - 5pm)' },
            { value: 'Evening (5pm - 9.30pm)', label: 'Evening (5pm - 9.30pm)' },
            { value: 'I’m flexible', label: 'I’m flexible' },
        ],
        dateError: '',
        communicationError: '',
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            slug: slug,
            brokerage_name: slug.display_name,
            brokerage_id: slug.brokerage_link,
            agent_id: slug.id,
            agent_name: slug.contact_name,
        });

        if (
            !(
                this.props &&
                this.props.CheckListDashboardState &&
                this.props.CheckListDashboardState.checklist &&
                this.props.CheckListDashboardState.checklist.length
            )
        ) {
            this.props.dashboardAction.checklist();
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        } else {
            this.props.commonState.move.map((item: any) => {
                item.destination_detail
                    ? this.setState({
                          address: item.destination_detail,
                      })
                    : this.setState({
                          address: '',
                      });

                item.user_ref && (item.user_ref.first_name || item.user_ref.last_name)
                    ? this.setState({
                          fullName: item.user_ref.first_name + ' ' + item.user_ref.last_name,
                      })
                    : this.setState({
                          fullName: '',
                      });

                if (this.state.fullName && this.state.address) {
                    this.setState({ prefilled: true });
                }
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            nextProps.commonState.move.map((item: any) => {
                currentState.address = item.destination_detail ? item.destination_detail : '';
                currentState.fullName =
                    item.user_ref && (item.user_ref.first_name || item.user_ref.last_name)
                        ? item.user_ref.first_name + ' ' + item.user_ref.last_name
                        : '';
            });
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.postVendors &&
            nextProps.trustedProsState.postVendors.length &&
            nextProps.trustedProsState.postVendorsArrived
        ) {
            nextProps.trustedProsState.postVendorsArrived = false;

            nextProps.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 100,
                },
                service_id: currentState.checklistTaskId,
            });

            nextProps.history.push({
                pathname: '/dashboard/ourvendors/order_confirm',
                state: {
                    ...nextProps.history.location.state,
                    selectedVendors: currentState.selectedVendors,
                    date: currentState.date,
                    time: currentState.time,
                    address: currentState.address,
                },
            });
            window.scrollTo(0, 0);
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.checklistDataArrived
        ) {
            currentState.checklistDataArrived = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/ourvendors')) {
                    currentState.checklistTaskId = item.id;
                }
            });
        }

        return currentState;
    }

    deleteHandler = async (item: any) => {
        let checkedItemsNext: string[] = this.state.selectedVendors;
        checkedItemsNext = checkedItemsNext.filter((item2: any) => item2 != item);
        await this.setState({
            selectedVendors: checkedItemsNext,
        });

        if (this.state.selectedVendors.length === 0) {
            this.props.history.push({
                pathname: '/dashboard/ourvendors/recommended',
                state: {
                    ...this.props.history.location.state,
                    checkedItemsAlmost: this.state.selectedVendors,
                    service_name: this.state.serviceName,
                    id: this.state.serviceId,
                    date: this.state.date,
                    time: this.state.time,
                    homeTaskId: this.state.homeTaskId,
                },
            });
            window.scrollTo(0, 0);
        }
    };
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'isEmail' && e.target.checked) {
            this.setState({
                isEmail: e.target.checked,
                isPhone: false,
            });
        } else if (e.target.name === 'isPhone' && e.target.checked) {
            this.setState({
                isPhone: e.target.checked,
                isEmail: false,
            });
        }
    };
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    };

    onConfirmAppointement = () => {
        let payload: any = [];
        let obj: any = [];

        this.state.selectedVendors.map((item: any) => {
            if (this.state.homeTaskId) {
                if (item.service_provider_id) {
                    obj = {
                        appointment_slots: [],
                        category: item.type_name,
                        date: new Date().toISOString().split('T')[0],
                        vendor_id: item.id,
                        date_str: this.state.date,
                        time_str: this.state.time,
                        service_provider_id: item.service_provider_id,
                        communication_preference: this.state.isEmail ? 'Email' : 'Phone',
                        description:
                            this.props &&
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.description
                                ? this.props.location.state.description
                                : '',
                    };
                } else {
                    obj = {
                        appointment_slots: [],
                        category: item.type_name,
                        date: new Date().toISOString().split('T')[0],
                        vendor_id: item.id,
                        date_str: this.state.date,
                        time_str: this.state.time,
                        home_advisor_task_id: this.state.homeTaskId,
                        communication_preference: this.state.isEmail ? 'Email' : 'Phone',
                        description:
                            this.props &&
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.description
                                ? this.props.location.state.description
                                : '',
                    };
                }
            } else {
                obj = {
                    appointment_slots: [],
                    category: item.type_name,
                    date: new Date().toISOString().split('T')[0],
                    vendor_id: item.id,
                    date_str: this.state.date,
                    time_str: this.state.time,
                    communication_preference: this.state.isEmail ? 'Email' : 'Phone',
                    description:
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.description
                            ? this.props.location.state.description
                            : '',
                };
            }

            payload.push(obj);
        });
        if (this.state.date && (this.state.isEmail || this.state.isPhone)) {
            this.props.trustedProsAction.postVendors(payload);
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Trusted Pros',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                'Trusted Pros',
                'Send Request',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                1,
                '',
                move_id,
            );

            formCompletedSegment(
                'Form Completed',
                'Trusted Pros',
                'Trusted Pros',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                '',
                'Confirm Appointment',
                true,
                this.state.prefilled,
                'Service confirmation details',
                1,
                move_id,
            );
        } else {
            this.setState({
                dateError: 'please select the date',
                communicationError: 'Please select any one way of communication',
            });
        }
    };
    setValue = (checkedItems: any, date: any, timeOfDay: any, serviceName: any, serviceId: any, homeTaskId: any) => {
        this.setState({
            selectedVendors: checkedItems,
            date: date ? date : this.state.date,
            time: timeOfDay ? timeOfDay : this.state.time,
            serviceName: serviceName ? serviceName : this.state.serviceName,
            serviceId: serviceId ? serviceId : this.state.serviceId,
            homeTaskId: homeTaskId ? homeTaskId : this.state.homeTaskId,
            buffer: true,
        });
    };

    addVendorsHandler = () => {
        this.props.history.push({
            pathname: '/dashboard/ourvendors/recommended',
            state: {
                ...this.props.history.location.state,
                checkedItemsAlmost: this.state.selectedVendors,
                service_name: this.state.serviceName,
                id: this.state.serviceId,
                date: this.state.date,
                time: this.state.time,
                homeTaskId: this.state.homeTaskId,
            },
        });
    };
    render() {
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.checkedItems &&
            this.props.location.state.checkedItems.length &&
            // this.props.location.state.serviceId &&
            // this.props.location.state.serviceName &&
            // this.props.location.state.homeTaskId &&
            this.state.buffer === false
        ) {
            this.setValue(
                this.props.location.state.checkedItems,
                this.props.location.state.date,
                this.props.location.state.timeOfDay,
                this.props.location.state.serviceName,
                this.props.location.state.serviceId,
                this.props.location.state.homeTaskId,
            );
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
                : [];
        return (
            <div className="trustedPros-main-page">
                <div
                    className={
                        isDouglasElliman
                            ? 'trustedPros-AlmostDonePage trustedPros-AlmostDonePageDouglas'
                            : 'trustedPros-AlmostDonePage'
                    }
                >
                    <div className="main-head-2">
                        <h2 className="heading">Trusted Pros</h2>
                        <div className="path">
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (
                                        localStorage.getItem('isHomeOwner') &&
                                        localStorage.getItem('isHomeOwner') == 'true'
                                    ) {
                                        this.props.history.push('/dashboardhomeOwner');
                                    } else {
                                        this.props.history.push('/dashboard');
                                    }
                                }}
                            >
                                Dashboard
                            </p>
                            <p
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.props.history.push('/dashboard/ourvendors');
                                }}
                            >
                                Trusted Pros
                            </p>
                            <p
                                style={{ marginLeft: '5px' }}
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/dashboard/ourvendors/recommended',
                                        state: {
                                            ...this.props.history.location.state,
                                            checkedItemsAlmost: this.state.selectedVendors,
                                            service_name: this.state.serviceName,
                                            id: this.state.serviceId,
                                            date: this.state.date,
                                            time: this.state.time,
                                            homeTaskId: this.state.homeTaskId,
                                        },
                                    });
                                }}
                            >
                                {'>'} Vendors
                            </p>
                            <p className="para-para">{'>'} Order Placing</p>
                        </div>
                    </div>
                    <div className="main-title">Great, you are almost done</div>
                    <div className="main-para">Confirm your contact details and submit your service request</div>
                    <div className="almostDoneFlexDiv">
                        <div className="scheduleAppointmentDiv">
                            <div
                                className="appointmentTitle"
                                style={{
                                    backgroundColor: isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59',
                                    color: '#ffffff',
                                }}
                            >
                                Contact details
                            </div>
                            <div className="formDiv">
                                <div className="allInfoDiv">
                                    <div className={isDouglasElliman ? 'row1Div InputDoug' : 'row1Div'}>
                                        <div className="leftInputDiv">
                                            <TextField
                                                className="trustedProsInput"
                                                label="Full Name"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                variant="outlined"
                                                value={this.state.fullName}
                                                name="fullName"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="rightInputDiv">
                                            <TextField
                                                className="trustedProsInput"
                                                label="Selected Address"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                name="address"
                                                variant="outlined"
                                                value={this.state.address}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className={isDouglasElliman ? 'row2Div InputDoug' : 'row2Div'}>
                                        <div className="leftInputDiv">
                                            <TextField
                                                // error={Boolean(this.state.dateError)}
                                                select
                                                className="trustedProsInput"
                                                label="Date Preference"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                name="date"
                                                variant="outlined"
                                                value={this.state.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {this.state.dateOptions.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <div
                                                className="errorTrusted"
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                            >
                                                {!this.state.date && this.state.dateError ? (
                                                    <div className="error">{this.state.dateError}</div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className="rightInputDiv">
                                            <TextField
                                                select
                                                className="trustedProsInput"
                                                label="Time Preference"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                name="time"
                                                variant="outlined"
                                                value={this.state.time}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {this.state.timeOptions.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                </div>
                                <div className="mailPhoneDiv">
                                    <div className="textDiv">
                                        Select your preferred communication
                                        {/* <InfoIcon className="InfoIcon" /> */}
                                    </div>
                                    <div className="mailPhoneFlexDiv">
                                        <div className="mailDiv">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.isEmail}
                                                        value={this.state.isEmail}
                                                        onChange={this.handleChange}
                                                        name="isEmail"
                                                        style={{ color: this.state.isEmail ? '#2270EE' : '#BEBEBE' }}
                                                    />
                                                }
                                                label={
                                                    <span
                                                        style={{ fontWeight: this.state.isEmail ? 'bold' : 'normal' }}
                                                    >
                                                        Email
                                                    </span>
                                                }
                                            />
                                        </div>
                                        <div className="mailDiv">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.isPhone}
                                                        value={this.state.isPhone}
                                                        onChange={this.handleChange}
                                                        name="isPhone"
                                                        style={{ color: this.state.isPhone ? '#2270EE' : '#BEBEBE' }}
                                                    />
                                                }
                                                label={
                                                    <span
                                                        style={{ fontWeight: this.state.isEmail ? 'bold' : 'normal' }}
                                                    >
                                                        Phone
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="errorTrusted"
                                        // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                    >
                                        {this.state.isEmail == false && this.state.isPhone == false ? (
                                            <div className="error">{this.state.communicationError}</div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {/* <div className="mailPhoneFlexDiv">
                                        {this.state.isEmail ? (
                                            <TextField
                                                className="trustedProsInput"
                                                label="Email ID"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                name="email"
                                                variant="outlined"
                                                value={this.state.email}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ marginRight: '10px' }}
                                            />
                                        ) : null}
                                        {this.state.isPhone ? (
                                            <TextField
                                                className="trustedProsInput"
                                                label="Phone Number"
                                                id="outlined-start-adornment"
                                                onChange={this.onChange}
                                                name="phone"
                                                variant="outlined"
                                                value={this.state.phone}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        ) : null}
                                    </div> */}
                                </div>

                                <Button
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : this.state.slug && this.state.slug.primary_color
                                            ? this.state.slug.primary_color
                                            : '#273E59'
                                    }
                                    className="trustedProsAppointmentButton"
                                    onClick={this.onConfirmAppointement}
                                >
                                    Send Request
                                </Button>
                            </div>
                        </div>
                        <div className="slectedVendorsDiv">
                            <div className="selectedVendorsTitleDiv">
                                <div className="selectedVendorsTitle">
                                    Vendor Summary {this.state.selectedVendors.length}
                                </div>
                                <div
                                    className="selectedVendorsAddButton"
                                    onClick={() => {
                                        this.addVendorsHandler();
                                    }}
                                >
                                    + Add Vendors
                                </div>
                            </div>

                            <div className="venderList">
                                {this.state.selectedVendors.map((item: any) => {
                                    return (
                                        <SelectedVendor
                                            image={item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1)}
                                            name={item.name}
                                            id={item.id}
                                            deleteHandler={() => {
                                                this.deleteHandler(item);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mortgage-last-div">
                    <div className="mortgage-pleasenote">Please Note:</div>
                    <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                        {displayData && displayData[0] && displayData[0].disclaimer ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: displayData && displayData[0] && displayData[0].disclaimer,
                                }}
                            />
                        ) : (
                            'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    trustedProsState: state.trustedPros,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsAlmostDone);
