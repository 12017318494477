import { combineReducers } from 'redux';

import todoReducer from './todo/reducer';
import loginReducer from './login/reducer';
import internetReducer from './internet/reducer';
import commonReducer from './common/reducer';
import moverReducer from './move/reducer';
import insuranceReducer from './insurance/reducer';
import utilityReducer from './utility/reducer';
import checklistReducer from './checklist/reducer';
import checklistDashboardReducer from './dashboard/reducer';
import dmvReducer from './dmv/reducer';
import signupReducer from './login/signup/reducer';
import updateAddressReducer from './updateAddress/reducer';
import trustedProsReducer from './trustedPros/reducer';
import autoShippingReducer from './autoshipping/reducer';
import premoveReducer from './premove/reducer';
import securityReducer from './security/reducer';
import warrantyReducer from './homeWarranty/reducer';
import energyReducer from './energy/reducer';
import documentryReducer from './documentary/reducer';
import serviceReducer from './savings/reducer';
import homeOwnerdashboardReducer from './homeOwnerDashboard/reducers';
import nextMoveReducer from './nextMove/reducer';
const rootReducer = combineReducers({
    todo: todoReducer,
    login: loginReducer,
    internet: internetReducer,
    common: commonReducer,
    move: moverReducer,
    insurance: insuranceReducer,
    utility: utilityReducer,
    checklist: checklistReducer,
    checklistDashboard: checklistDashboardReducer,
    dmv: dmvReducer,
    signup: signupReducer,
    updateAddress: updateAddressReducer,
    trustedPros: trustedProsReducer,
    autoShipping: autoShippingReducer,
    premove: premoveReducer,
    security: securityReducer,
    warranty: warrantyReducer,
    energy: energyReducer,
    documentry: documentryReducer,
    service: serviceReducer,
    homeOwnerdashboard: homeOwnerdashboardReducer,
    nextMove: nextMoveReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
