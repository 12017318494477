import React from 'react';
import CompareCard from '../../components/atoms/CompareCard';
// import Circle from '../../Assets/images/circle.svg';
import '../../styles/compareComponent.css';
import Logo from '../../Assets/images/move_easy.svg'

interface compareCardProps {
    width?:string;
    height?:string;
    borderRadius?:string;
    border?:any;
    className?:string;
    imgWidth?:string;
    imgHeight?:string;
    cardImage1?:any;
    cardContent1?:string; 
    boxShadow?:any;
    color?:string;
    cardImage2?:any;
    cardContent2?:string; 
    
}

const CompareComponent= (props:compareCardProps) => {
    return(
        <div className="compareCard-main-div">
          
            <div className="card-Div">
            <p className="compareHeading">Current Experience</p>
            <CompareCard
                        width={props.width}
                        height={props.height}
                        cardImage={props.cardImage1}
                        cardContent={props.cardContent1}
                        className={props.className ? props.className : "compareCard"}
                    />
            </div>
                 <div className="vl-line-main">
                 <div className="vl">

                     </div>
                     <div className="circle">Vs </div>
                     <div className="vl">

                     </div>
                 </div>
                 <div className="hr-line-main">
                 <div className="hr">

                     </div>
                     <div className="circle">Vs </div>
                     <div className="hr">

                     </div>
                 </div>
                   
                    <div className="card-Div">
                        <p className="compareHeading" style={{display: "flex", justifyContent: "center"}}><div className="image-moveEasy"><img src={Logo} /></div> Experience</p>
            <CompareCard
                       width={props.width}
                       height={props.height}
                       cardImage={props.cardImage2}
                       cardContent={props.cardContent2}
                       color={props.color}
                       border={props.border}
                       fontWeight="bold"
                       className={props.className ? props.className : "compareCard"}
                    />
            </div>
                   
        </div>
    )
}
export default CompareComponent
