// signup
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const SIGNUP_UPDATE = 'SIGNUP_UPDATE';
export const SIGNUP_UPDATE_SUCCESS = 'SIGNUP_UPDATE_SUCCESS';
export const SIGNUP_UPDATE_ERROR = 'SIGNUP_UPDATE_ERROR';

// landing move
export const LANDING_MOVE_POST = 'LANDING_MOVE_POST';
export const LANDING_MOVE_POST_SUCCESS = 'LANDING_MOVE_POST_SUCCESS';
export const LANDING_MOVE_POST_ERROR = 'LANDING_MOVE_POST_ERROR';

// landing move for security recommendation
export const LANDING_MOVE_POST_FOR_SECURITY = 'LANDING_MOVE_POST_FOR_SECURITY';
export const LANDING_MOVE_POST_FOR_SECURITY_SUCCESS = 'LANDING_MOVE_POST_FOR_SECURITY_SUCCESS';
export const LANDING_MOVE_POST_FOR_SECURITY_ERROR = 'LANDING_MOVE_POST_FOR_SECURITY_ERROR';

//ghost
export const POST_GHOST_LOGIN = 'POST_GHOST_LOGIN';
export const POST_GHOST_LOGIN_SUCCESS = 'POST_GHOST_LOGIN_SUCCESS';
export const POST_GHOST_LOGIN_ERROR = 'POST_GHOST_LOGIN_ERROR';

export const LANDING_MOVE_GET = 'LANDING_MOVE_GET';
export const LANDING_MOVE_RESET = 'LANDING_MOVE_RESET';
export const LANDING_MOVE_GET_SUCCESS = 'LANDING_MOVE_GET_SUCCESS';
export const LANDING_MOVE_GET_ERROR = 'LANDING_MOVE_GET_ERROR';