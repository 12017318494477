import React, { Component } from 'react';
import '../../components/dashboardComponents/dashboardCss/moveFrommoveToCard2.scss';
// import moveTruck from '../../Assets/images/DashBoard/moveTruck.svg';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import { store } from 'react-notifications-component';
// import history from '../../routes/History';

interface initialProps {
    history?: any;
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    commonState?: any;
    commonAction?: any;
    toAddress?: string;
    toPin?: string;
    fromAddress?: string;
    fromPin?: string;
    movingDate?: any;
}

type initialState = {
    fromPin?: string;
    fromAddress?: string;
    street?: any;
    clicked1?: boolean;
    isDataArrived: boolean;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    moveId?: string;
    brokerage_key: any;
    agent_key: any;
};
class DashboardHomeOwnerTopCard extends Component<initialProps, initialState> {
    state: initialState = {
        fromPin: this.props.fromPin ? this.props.fromPin : '',
        fromAddress: this.props.fromAddress ? this.props.fromAddress : '',
        clicked1: false,
        street: '',
        isDataArrived: true,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        moveId: '',
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        // this.props.commonAction.moveGet();
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
            let move_id: any = localStorage.getItem('moveKey');
            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Home Address Edited',
                {
                    page: 'Home Owner Dashboard',
                    brokerage_name: this.state.brokerage_name,
                    brokerage_key: this.state.brokerage_key,
                    agent_name: this.state.agent_name,
                    agent_key: this.state.agent_key,
                },
                { user_id: move_id },
            );
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                moveId: cur.move[0].id,
                fromAddress: cur.move[0].source && cur.move[0].source.lookup_name,
                fromPin: cur.move[0].source && cur.move[0].source.zip_code,
                street: `${cur.move[0].source_full_address}`,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            let moveData = nextProps.commonState.move[0];

            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address
            ) {
                currentState.moveId = moveData.id;
                currentState.fromAddress = moveData.source && moveData.source.lookup_name;
                currentState.fromPin = moveData.source && moveData.source.zip_code;
                currentState.brokerage_name =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.display_name;
                currentState.brokerage_id =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.brokerage_link;
                currentState.agent_id =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.id;
                currentState.brokerage_key =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.brokerage_key;
                currentState.agent_key =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.realtor_key;
                currentState.agent_name =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.contact_name;
                currentState.street = `${moveData.source_full_address}`;
            }

            // else {
            //     setTimeout(() => {
            //         localStorage.clear();
            //         history.push({
            //             pathname: `/signUp?hash_code=${moveData.hash_code}`,
            //         });
            //     }, 2000);
            // }
        }
        return currentState;
    }

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street: `${streetNumber} ${route}, ${city}, ${state}, ${zip} `,
                        fromAddress: `${streetNumber} ${route} ${city} ${state} `,
                        fromPin: zip,
                        clicked1: false,
                    });
                }
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        source_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                    // if (
                    //     this.props.commonAction.moveAddressUpdate({
                    //         source_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    //         destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    //         move_id: this.state.moveId,
                    //     })
                    // ) {
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                    // }
                    // this.props.commonAction.moveAddressUpdate({
                    //     source_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    //     move_id: this.state.moveId,
                    // });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleClickAway = () => {
        this.setState({
            clicked1: false,
        });
    };

    render() {
        // const isOpen=Boolean(this.state.anchorEl)
        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <div
                    style={{
                        width: this.props.width ? this.props.width : 'auto',
                        height: this.props.height ? this.props.height : '90px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderRadius: this.props.borderRadius ? this.props.borderRadius : '3px',
                        boxShadow: this.props.boxShadow ? this.props.boxShadow : null,
                        border: this.props.border ? this.props.border : '1px solid #DADCE0',
                        opacity: '1',
                        margin: this.props.cardMargin ? this.props.cardMargin : '',
                        padding: this.props.cardPadding ? this.props.cardPadding : '5px',
                    }}
                    onClick={this.props.onClick}
                    className={this.props.className ? `${this.props.className} ` : ` card-height-width-2`}
                >
                    <div
                        className="cardContent"
                        // style={{
                        //     display: 'flex',
                        //     flexDirection: 'row',
                        // }}
                    >
                        <div className="card-section-1">
                            <div style={{ display: 'flex', margin: '10px' }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    onClick={() => {
                                        this.setState({ clicked1: true });
                                    }}
                                >
                                    <div className="section-1-heading">
                                        YOUR NEW HOME ADDRESS
                                        {this.state.clicked1 == false ? (
                                            <div>
                                                <div className="section-1-number">{this.state.fromPin}</div>
                                                <div className="section-1-content">{this.state.street}</div>
                                            </div>
                                        ) : (
                                            <div className="overflow-auto">
                                                <AutoComplete
                                                    className="input1-moveCard"
                                                    value={this.state.street}
                                                    onChange={e => {
                                                        this.setState({ street: e });
                                                    }}
                                                    onSelect={this.handleSelect}
                                                    placeholder={this.state.street == '' ? 'From' : ''}

                                                    // inputTitle="To"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHomeOwnerTopCard);
