import {
    GET_CHECKLIST,
    GET_CHECKLIST_ERROR,
    GET_CHECKLIST_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE,
    UPDATE_SERVICE_PERCENTAGE_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE_ERROR,
    GET_PODS_CHECKLIST,
    GET_PODS_CHECKLIST_SUCCESS,
    GET_PODS_CHECKLIST_ERROR
} from './actionTypes';
import {
    CheckList,
    CheckListRequest,
    CheckListFailure,
    CheckListFailurePayload,
    CheckListSuccess,
    CheckListSuccessPayload,
    PODSCheckList,
    PODSCheckListRequest,
    PODSCheckListSuccess,
    PODSCheckListSuccessPayload,
    PODSCheckListFailure,
    PODSCheckListFailurePayload,
    UpdateServicePercentage,
    UpdateServicePercentageRequest,
    UpdateServicePercentageFailure,
    UpdateServicePercentageFailurePayload,
    UpdateServicePercentageSuccess,
    UpdateServicePercentageSuccessPayload
   
} from './types';

export const checklist = (payload: CheckListRequest): CheckList => ({
    type: GET_CHECKLIST,
    payload,
});

export const checklistSuccess = (payload: CheckListSuccessPayload): CheckListSuccess => ({
    type: GET_CHECKLIST_SUCCESS,
    payload,
});

export const checklistFailure = (payload: CheckListFailurePayload): CheckListFailure => ({
    type: GET_CHECKLIST_ERROR,
    payload,
});

// PODS actions

export const podsChecklist = (payload: PODSCheckListRequest): PODSCheckList => ({
    type: GET_PODS_CHECKLIST,
    payload,
});

export const podsChecklistSuccess = (payload: PODSCheckListSuccessPayload): PODSCheckListSuccess => ({
    type: GET_PODS_CHECKLIST_SUCCESS,
    payload,
});

export const podschecklistFailure = (payload: PODSCheckListFailurePayload): PODSCheckListFailure => ({
    type: GET_PODS_CHECKLIST_ERROR,
    payload,
});

// patch move api

export const updateServicePercentage = (payload: UpdateServicePercentageRequest): UpdateServicePercentage => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE,
        payload,
    };
};

export const updateServicePercentageSuccess = (
    payload: UpdateServicePercentageSuccessPayload,
): UpdateServicePercentageSuccess => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE_SUCCESS,
        payload,
    };
};

export const updateServicePercentageFailure = (
    payload: UpdateServicePercentageFailurePayload,
): UpdateServicePercentageFailure => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE_ERROR,
        payload,
    };
};

