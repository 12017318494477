import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Image1 from "../../../Assets/images/PreMove/preMoveCarousel1.svg";
import Image2 from "../../../Assets/images/PreMove/preMoveCarousel2.svg";
import Image3 from "../../../Assets/images/PreMove/preMoveCarousel3.svg";
import './Signup.scss';

type InitialProps = {};

type InitialState = {};

class SignupCarousel extends React.Component<InitialProps, InitialState> {
    render() {
        let carouselItems: any = [
            {
                image: Image1,
                name: 'Before the move',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
                image: Image2,
                name: 'During the move',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
              image: Image3,
              name: 'After the move',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
        ];
        return (
            <div className="preMoveSignupCarouselMain">
                <Carousel
                    indicators={true}
                    navButtonsAlwaysVisible={false}
                    autoPlay={true}
                >
                    {carouselItems.map((item: any) => {
                        return (
                            <div className="carouselCardDiv">
                                <div className="carouselImageDiv">
                                  <img src={item.image} />
                                </div>
                                <div className="carouselName">{item.name}</div>
                                <div className="carouselDescription">{item.description}</div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}

export default SignupCarousel;
