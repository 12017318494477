import React, { Component } from 'react';
import './HomeWarantyBooked.scss';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

type initialProps = {
    title: string;
    editPlan: any;
    submissionDate: any;
    policyNumber: any;
    effectiveDate: any;
    expirationDate: any;
};

type initialState = {};

export class HomeWarantyBooked extends Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        return (
            <div className="main-div-HomeWarantyBooked">
                <div className="booked-main-div">
                    <div className="booked-title">
                        <div className="title-booked">
                            <span style={{ fontWeight: 'bold' }}>{this.props.title}</span>
                        </div>
                        {/* <div className="edit-plan-booked" onClick={this.props.editPlan}>
                            Edit Plan
                        </div> */}
                    </div>
                    <div className="booked-main-box">
                        <div className="booked-inner-div">
                            <div className="booked-subValue">Date of submission</div>
                            <div className="booked-subValue">{this.props.submissionDate}</div>
                        </div>
                        <div className="booked-inner-div">
                            <div className="booked-subValue">Policy Number</div>
                            <div className="booked-subValue">{this.props.policyNumber}</div>
                        </div>
                        {this.props.effectiveDate &&
                        this.props.effectiveDate != '' &&
                        this.props.effectiveDate != null ? (
                            <div className="booked-inner-div">
                                <div className="booked-subValue">Effective Date</div>
                                <div className="booked-subValue">{this.props.effectiveDate}</div>
                            </div>
                        ) : null}
                        {this.props.expirationDate &&
                        this.props.expirationDate != '' &&
                        this.props.expirationDate != null ? (
                            <div className="booked-inner-div">
                                <div className="booked-subValue">Expiration Date</div>
                                <div className="booked-subValue">{this.props.expirationDate}</div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="homewarrantycontact">
                    <div className="waranty-thanku-payment">
                        To manage your service, please call the customer service number.
                    </div>
                    <div className="waranty-thanku-contact">
                        <span>
                            {' '}
                            <WhatsAppIcon className="checkit" />
                        </span>{' '}
                        <span>800-735-4663</span>
                    </div>
                </div>
            </div>
        );
    }
}
export default HomeWarantyBooked;
