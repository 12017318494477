import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from './Button';
import '../../styles/longCard.scss';
import iIcon from '../../Assets/images/Moving/iIcon.svg';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    bestForImage?: any;
    bestForTitle?: string;
    availableSpeed?: string;
    channels?: string;
    currency?: string;
    ammount?: string;
    planRate?: string;
    planValidFor?: string;
    reward?: string;
    boxShadow?: any;
    features?: any;
    scheduleCall?: any;
    viewDetails?: any;
}

const LongCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
            },
        },
    }));

    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);

    const domain = window.location.hostname;
    let name = domain.split('.');

    let availableSpeed: string = '';
    let str1: any = props && props.availableSpeed && props.availableSpeed.split(' ');
    if (parseInt(str1[0]) > 10000 && parseInt(str1[0]) < 100000) {
        availableSpeed = str1[0].substring(0, 2) + 'Mbps';
    } else if (parseInt(str1[0]) > 100000) {
        availableSpeed = str1[0].substring(0, 3) + 'Mbps';
    } else {
        availableSpeed = str1[0] + 'Mbps';
    }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div
            style={{
                width: props.width ? props.width : 'inherit',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 10px #9C9DA1',
                border: props.border ? props.border : null,
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '',
                padding: props.cardPadding ? props.cardPadding : '15px',
                cursor: 'pointer',
                textAlign: 'center',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : classes.root}
        >
            <div
                className="cardContent"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10px',
                }}
            >
                <div>
                    <img
                        width={props.imgWidth ? props.imgWidth : '80px'}
                        height={props.imgHeight ? props.imgHeight : '35px'}
                        src={props.cardImage}
                        style={{
                            marginRight: 'auto',
                        }}
                        alt=""
                    />
                </div>

                <div>
                    <div className="cardTitle">{props.cardTitle}</div>
                </div>

                {/* <div>
                    <div className="bestForTitle">
                        <img
                            width={props.imgWidth ? props.imgWidth : '30px'}
                            height={props.imgHeight ? props.imgHeight : '30px'}
                            src={props.bestForImage}
                            style={{
                                marginRight: '15px',
                            }}
                            alt=""
                        />
                        {props.bestForTitle}
                    </div>
                </div> */}
                <div className="featuresFlexDivlong">
                    {props.features.map((item: any) => {
                        return (
                            <div className={isDouglasElliman ? 'featureDiv' : 'featureDivnoDoug'}>
                                <img
                                    width={item.width ? item.width : '25px'}
                                    height={item.height ? item.height : '25px'}
                                    src={item.icon}
                                    alt=""
                                    style={{ marginRight: '5px' }}
                                />
                                <div style={{ fontSize: '16px' }}> {item.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="availableSpeedMainDiv">
                <div className="availableSpeedContent">{availableSpeed ? availableSpeed : 'NA'}</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div className="availableSpeedTitle">Available Speed</div>
                    <div style={{ marginLeft: '5px' }}>
                        <img src={iIcon} width="15px" height="15px" />
                    </div>
                </div>
            </div>

            <div className="availableSpeedMainDiv">
                <div className="availableSpeedContent">{props.channels ? props.channels : 'NA'}</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div className="availableSpeedTitle">TV Channels</div>
                    <div style={{ marginLeft: '5px' }}>
                        <img src={iIcon} width="15px" height="15px" />
                    </div>
                </div>
            </div>

            <div>
                <div className="planRateText">Plans starting as low as:</div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    <div className="currency">{props.currency ? props.currency : ''}</div>
                    <div className="amount" style={{ fontWeight: 800 }}>
                        {props.ammount ? props.ammount : 'NA'}
                    </div>
                    <div className="rate">
                        <div>{props.planRate ? props.planRate : 'NA'}</div>
                        <div>/mo</div>
                    </div>
                </div>
                <div className="validFor">{props.planValidFor}</div>
            </div>
            {props.viewDetails === 'NoData' ? (
                <div className="viewDetails" style={{ color: '#6B6C6F' }}>
                    No Details Available
                </div>
            ) : (
                <div className="viewDetails" style={{color: isDouglasElliman ? "#009cbd" : "#006dff"}} onClick={props.viewDetails}>
                    View Details
                </div>
            )}
            <div>
                <Button
                    width="97%"
                    height="50px"
                    backgroundColor={
                        isDouglasElliman
                            ? '#100B28'
                            : slug && slug.primary_color
                            ? slug.primary_color
                            : name[0].includes('internet') ||
                              name[0].includes('insurance') ||
                              name[0].includes('security')
                            ? '#30AB86'
                            : '#273E59'
                    }
                    onClick={props.scheduleCall}
                >
                    Select
                </Button>
            </div>
            {/* <div className="rewardDiv">
                <div className="reward">{props.reward}</div>
            </div> */}
        </div>
    );
};

export default LongCard;
