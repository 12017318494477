import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromLanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={67.056}
            height={71}
            viewBox="0 0 67.056 71"
        >
            <defs>
                <style>
                    {`.trophyStepprefix__a{fill:${
                          props.fromLanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="trophyStepprefix__a"
                d="M15.488 33.886h1.165a20.2 20.2 0 003.4 4.573 18.864 18.864 0 0012.221 5.494V63.11h-7.02a6.641 6.641 0 00-6.634 6.634A1.255 1.255 0 0019.874 71h27.308a1.255 1.255 0 001.255-1.255 6.641 6.641 0 00-6.634-6.634h-7.02V43.954a18.879 18.879 0 0012.233-5.537 20.188 20.188 0 003.381-4.531h1.171a15.565 15.565 0 0015.488-15.557v-5.654a1.928 1.928 0 00-1.972-1.917H52.712V3.076A3.123 3.123 0 0049.612 0H17.529a3.04 3.04 0 00-3.007 3.076v7.682H1.972A1.928 1.928 0 000 12.675v5.655a15.565 15.565 0 0015.488 15.556zm30.243 34.6H21.324a4.194 4.194 0 013.929-2.869H41.8a4.194 4.194 0 013.929 2.869zm18.814-55.222v5.062a13.052 13.052 0 01-12.978 13.047h-.056a19.946 19.946 0 001.2-6.51v-11.6zM17.033 3.076a.529.529 0 01.5-.565h32.08a.612.612 0 01.593.565v21.78a16.834 16.834 0 01-16.647 16.63 16.5 16.5 0 01-16.526-16.537zM2.51 13.268h12.013v11.681a20.106 20.106 0 001.037 6.427h-.072A13.052 13.052 0 012.51 18.329z"
            />
            <path
                className="trophyStepprefix__a"
                d="M24.863 25.168l5.238.761 2.343 4.747a1.255 1.255 0 002.251 0l2.343-4.747 5.238-.761a1.255 1.255 0 00.7-2.141l-3.79-3.695.895-5.217a1.255 1.255 0 00-1.821-1.323l-4.685 2.463-4.685-2.463a1.255 1.255 0 00-1.821 1.323l.895 5.217-3.79 3.695a1.255 1.255 0 00.7 2.141zm5.32-4.5a1.255 1.255 0 00.361-1.112l-.577-3.361 3.019 1.587a1.254 1.254 0 001.168 0l3.018-1.587-.576 3.361a1.255 1.255 0 00.361 1.112l2.442 2.38-3.375.49a1.255 1.255 0 00-.945.687l-1.509 3.058-1.509-3.058a1.255 1.255 0 00-.945-.687l-3.375-.49z"
            />
        </svg>
    );
}

export default SvgComponent;
