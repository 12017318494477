import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    selectedMoversSuccess,
    selectedMoversFailure,
    moversSuccess,
    moversFailure,
    PostmoversFailure,
    PostmoversSuccess,
    reverseStorageSuccess,
    reverseStorageFailure,
    reserveStorageByFacilityIdSuccess,
    reserveStorageByFacilityIdFailure,
    postReserveStorageFailure,
    postReserveStorageSuccess,
    updateMoveSourceHomeInfoFailure,
    updateMoveSourceHomeInfoSuccess,
    updateDisclaimerFailure,
    updateDisclaimerSuccess,
    updateMoveDestinationHomeInfoFailure,
    updateMoveDestinationHomeInfoSuccess,
    diyFailure,
    diySuccess,
} from './actions';
import {
    GET_MOVERS,
    GET_SELECTED_MOVERS,
    GET_REVERSE_STORAGE,
    GET_RESERVE_STORAGE_BY_FACILITY_ID,
    POST_MOVERS,
    POST_RESERVE_STORAGE,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION,
    GET_DIY,
    UPDATE_DISCLAIMER,

} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* movers() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/move/movers/`,
        });
        if (response.data && response.status === 200) {
            yield put(moversSuccess({ movers: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(moversFailure({ error: e.message }));
        // history.push('/server-error');
    }
}
function* selectedMoversFunc() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/move/move-appointment/`,
        });
        if (response.data && response.status === 200) {
            yield put(selectedMoversSuccess({ selectedMovers: response.data[0].movers }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(selectedMoversFailure({ error: e.message }));
        // history.push('/server-error');
    }
}
function* Postmovers(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/move-appointment/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(PostmoversSuccess({ Postmovers: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(PostmoversFailure({ error: e.message }));
        store.addNotification({
            title: 'Error',
            message: 'Error Occurred!',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* reverseStorage(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/concierge/reserve-storage/?zip_code=${action.payload.zip_code}`,
        });
        if (response.data && response.status === 200) {
            yield put(reverseStorageSuccess({ reverseStorage: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(reverseStorageFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* reserveStorageByFacilityId(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/concierge/reserve-storage/?facility_id=${action.payload.facility_id}`,
        });
        if (response.data && response.status === 200) {
            yield put(reserveStorageByFacilityIdSuccess({ reserveStorageByFacilityId: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(reserveStorageByFacilityIdFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* postReserveStorage(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/concierge/reserve-storage/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(postReserveStorageSuccess({ postReserveStorage: response.data }));
            // store.addNotification({
            //     title: 'Success',
            //     message: 'Successfully created appointment',
            //     type: 'success',
            //     insert: 'top',
            //     container: 'top-right',
            //     animationIn: ['animate__animated', 'animate__fadeIn'],
            //     animationOut: ['animate__animated', 'animate__fadeOut'],
            //     dismiss: {
            //         duration: 2000,
            //         onScreen: true,
            //     },
            // });
        } else {
            store.addNotification({
                title: 'Error',
                message: response && response.data && response.data.message ? response.data.message : 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(postReserveStorageFailure({ error: e.message }));
        store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occurred!',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

//update move api

function* updateMoveSourceHomeInfo(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/move/move-api/${action.payload.move_id}/update-move-source-home-information/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(updateMoveSourceHomeInfoSuccess({ updateMoveSourceHomeInfo: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateMoveSourceHomeInfoFailure({ error: e.message }));
        // history.push('/server-error');
    }
}



function* updateDisclaimer(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/move/move-api/${action.payload.move_id}/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(updateDisclaimerSuccess({ updateDisclaimer: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateDisclaimerFailure({ error: e.message }));
        // history.push('/server-error');
    }
}
function* updateMoveDestinationHomeInfo(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `api/move/move-api/${action.payload.move_id}/update-move-destination-home-information/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(updateMoveDestinationHomeInfoSuccess({ updateMoveDestinationHomeInfo: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateMoveDestinationHomeInfoFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* diy() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `csr/api/v1/diy-providers/`,
        });
        if (response.data && response.status === 200) {
            yield put(diySuccess({ diy: response.data }));
        }
    } catch (e) {
        yield put(diyFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* moversSaga() {
    yield all([takeLatest(GET_SELECTED_MOVERS, selectedMoversFunc)]);
    yield all([takeLatest(GET_MOVERS, movers)]);
    yield all([takeLatest(POST_MOVERS, Postmovers)]);
    yield all([takeLatest(GET_REVERSE_STORAGE, reverseStorage)]);
    yield all([takeLatest(POST_RESERVE_STORAGE, postReserveStorage)]);
    yield all([takeLatest(GET_RESERVE_STORAGE_BY_FACILITY_ID, reserveStorageByFacilityId)]);
    yield all([takeLatest(UPDATE_MOVE_SOURCE_HOME_INFORMATION, updateMoveSourceHomeInfo)]);
    yield all([takeLatest(UPDATE_DISCLAIMER,updateDisclaimer)]);
    yield all([takeLatest(UPDATE_MOVE_DESTINATION_HOME_INFORMATION, updateMoveDestinationHomeInfo)]);
    yield all([takeLatest(GET_DIY, diy)]);
}
