import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Button from '../../components/atoms/Button';
import "./ICmain.scss";
import { UIContext } from '../../UIContext';
import Image from '../../Assets/images/ICImages/MainPageBanner';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import AnalyticsService from '../../utils/analytics-service';

const InternetAndCableGetStart = (props: any) => {
    const { history } = props;
    const [slug, setSlug] = useState<any>();
    const [address, setAddress] = useState<any>({});
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState({
        address: false
    })

    const appContext = useContext(UIContext);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        return () => { };
    }, [])

    useEffect(() => {
        let getSlug: any = localStorage.getItem('slug');
        if (getSlug) {
            setSlug(JSON.parse(getSlug));
        }
        let getAddress: any = localStorage.getItem('pods_destination_address')
        if (getAddress) {
            setAddress(JSON.parse(getAddress));
        }

        AnalyticsService.pageViewed({ page: "Internet & Cable" });
        return () => { }
    }, [])


    const getStartInternet = () => { 

        AnalyticsService.buttonClicked({
            page: 'Internet & Cable',
            category_name: 'Internet & Cable Started Button',
            button_copy: 'Get Started',
            form_button: true,
        });

        history.push('/dashboard/internet');
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                const addressString = results[0].formatted_address;
                let addressObj: any = {
                    street: `${streetNumber} ${route}`,
                    city,
                    zipcode: zip,
                    address: addressString,
                    state
                };
                localStorage.setItem('pods_destination_address', JSON.stringify(addressObj));

                if (country === 'USA' || country === 'US') {
                    setAddress(addressObj)
                }
            })
            .catch(error => console.error('error', error));
    };

    const onChangeHandlerStep1 = (e: any, name: string) => {
        let newAddress: any;
        if (name === 'address') {
            newAddress = { ...address, [name]: e };
        } else {
            newAddress = { ...address, [name]: e.target.value };
        }
        setAddress(newAddress);

        if (!isAnalitycsTriggerredOnce.address) {
        AnalyticsService.formFieldInput({
            page: "Internet & Cable",
            hasMinimalFields: true,
            category_name: 'Internet & Cable',
            form_field_name: 'Enter Address'
        });
       }

        setIsAnalitycsTriggerredOnce({
            address: true
        })
    };

    return (
        <div>
            <div style={{ marginTop: '40px' }}></div>
            <div className="main-head">
                <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Internet And Cable</h2>
                <div className="path">
                    <p className="Para" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (
                                localStorage.getItem('isHomeOwner') &&
                                localStorage.getItem('isHomeOwner') == 'true'
                            ) {
                                history.push('/dashboardhomeOwner');
                            } else {
                                history.push('/dashboard');
                            }
                        }}
                    >
                        Dashboard {'>'}
                    </p>{' '}
                    <p style={{ marginLeft: '5px' }}>Internet And Cable</p>
                </div>
            </div>
            <div className="internet-Div">
                <div className="mainPagedl">
                    <div className='internet-image'>
                        <Image className="mainImg" />
                    </div>
                    <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>
                        Let us help you find the best internet & cable deals
                    </h2>
                    <p className="sub-heading">
                    Compare plans and order your service in a matter of minutes
                    </p>
                    <div className="lineICPage"></div>
                    <div className="btn-div">
                        <div className='insurance-address internet-address' >
                            <AutoComplete
                                value={address?.address}
                                onChange={e => onChangeHandlerStep1(e, 'address')}
                                onSelect={handleSelect}
                                placeholder="Enter Address"
                                className='input'
                            />
                            {/* {isError && <span className='error'> Please enter the address</span>} */}
                        </div>
                        <Button
                            backgroundColor={
                                slug && slug.primary_color
                                    ? slug.primary_color
                                    : appContext.colors.button.bg
                            }
                            color={
                                slug && slug.primary_font_color
                                    ? slug.primary_font_color
                                    : appContext.colors.button.text
                            }
                            fontSize="16px"
                            width="100%"
                            height="54px"
                            borderRadius={'4px'}
                            onClick={getStartInternet}
                            disabled={!address?.street}
                        >
                            Get Started
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InternetAndCableGetStart