import React from 'react';
import ButtonNew from '../atoms/Button';
import './upcomingTaskSecondCard.scss';
import Skeleton from 'react-loading-skeleton';

type initialProps = {
    image?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    onButtonClick?: any;
    showButton?: boolean;
};

const UpcomingTaskSecondCard = (props: initialProps) => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div className="UpcomingTaskSecondCardMain">
            <div className="UpcomingTaskSecondCardLeftDiv">
                <div className="UpcomingTaskSecondTitle">{props.title}</div>
                <div className="UpcomingTaskSecondDescription">{props.description}</div>
                <div className="UpcomingTaskSecondButtonDiv">
                    {props.showButton ? (
                        <ButtonNew
                            backgroundColor="#ffffff"
                            color={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug && slug.primary_color
                                    : '#273E59'
                            }
                            border={`1px solid ${
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug && slug.primary_color
                                    : '#273E59'
                            }`}
                            className="buttonUpcomingTaskSecond"
                            onClick={props.onButtonClick}
                        >
                            {props.buttonText}
                        </ButtonNew>
                    ) : (
                        <Skeleton width="180px" height="40px" />
                    )}
                </div>
            </div>
            <div className="imageUpcomingTaskSecondDiv">
                <img src={props.image} className="imageUpcomingTaskSecond" />
            </div>
        </div>
    );
};

export default UpcomingTaskSecondCard;
