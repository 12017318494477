import React, { Component } from 'react';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import TextField from '@material-ui/core/TextField';
import './updateAddressModal.scss';
import Back from '../../../Assets/images/UpdateAddress/updateAddBackArrow.svg';

type initialProps = {
    showVerificationModal: boolean;
    handleCloseModal?: any;
    heading: string;
    subHeading: string;
    btnText: string;
    label: string;
    value: any;
    onChangeHandler: any;
    link: string;
    btnBackgroundColor: any;
    onSubmitHandler: any;
    onSubmitViaCall: any;
    btnWidth: string;
    btnBorderColor: any;
    showError: any;
    errorMsg: any;
    isDouglasElliman?: boolean;
};

export default class VerificationModal extends Component<initialProps> {
    render() {
        const {
            showVerificationModal,
            handleCloseModal,
            heading,
            subHeading,
            btnText,
            label,
            value,
            onChangeHandler,
            link,
            btnBackgroundColor,
            onSubmitHandler,
            onSubmitViaCall,
            btnWidth,
            btnBorderColor,
            showError,
            errorMsg,
        } = this.props;
        return (
            <Modal
                isShowModal={showVerificationModal}
                onCloseModal={() => handleCloseModal()}
                showClose={false}
                className={
                    this.props.isDouglasElliman ? 'updateAddVerification updateAddressModals' : 'updateAddVerification'
                }
            >
                <div>
                    {window.innerWidth <= 600 && (
                        <div
                            style={{ paddingLeft: '12px', paddingTop: '23px', cursor: 'pointer' }}
                            onClick={() => handleCloseModal()}
                        >
                            <img src={Back} width="24px" height="24px" />
                        </div>
                    )}
                    <div className="updateAddMain">
                        <div className="updateAddHeading">{heading}</div>
                        <div className="updateAddSubHeading">{subHeading}</div>
                        <div className="updateAddRow">
                            <TextField
                                className={
                                    this.props.isDouglasElliman ? 'updateAddPhone updateAddPhoneDoug' : 'updateAddPhone'
                                }
                                id="outlined-start-adornment"
                                variant="filled"
                                value={value}
                                label={label}
                                onChange={(e: any) => onChangeHandler(e)}
                            />
                        </div>
                        <div className="updateAddRow">
                            <Button
                                backgroundColor={
                                    this.props.isDouglasElliman
                                        ? '#100B28'
                                        : btnBackgroundColor
                                        ? btnBackgroundColor
                                        : '#273E59'
                                }
                                borderRadius={this.props.isDouglasElliman ? '0px' : '4px'}
                                // color="#fff"
                                border={`1px solid ${btnBorderColor}`}
                                fontSize="16px"
                                fontWeight="unset"
                                width={btnWidth}
                                onClick={onSubmitHandler}
                            >
                                {btnText}
                            </Button>
                        </div>
                        <div
                            className={
                                this.props.isDouglasElliman ? 'updateAddLink updateAddLinkDoug' : 'updateAddLink'
                            }
                            onClick={onSubmitViaCall}
                        >
                            {link}
                        </div>
                        {showError && <div className="updateAddModalErr">{errorMsg}</div>}
                    </div>
                </div>
            </Modal>
        );
    }
}
