import * as React from 'react';
// import { navigationIconColorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" 
            width="36.406" 
            height="36.406" 
            viewBox="0 0 36.406 36.406"
        >
            <defs>
                <style>
                    {`.podsFaq__a{
                        fill:#1c3947
                    }.podsFaqSvgSvgprefix__d{
                        fill:${props.active ? slug.primary_color : '#1c3947'}
                    }`}
                </style>
            </defs>
            <path className='podsFaqSvgSvgprefix__d' id="noun-faq-1272960" d="M106.406,18.2A18.2,18.2,0,1,1,88.2,0,18.2,18.2,0,0,1,106.406,18.2Zm-1.568,0A16.636,16.636,0,1,0,88.2,34.839,16.635,16.635,0,0,0,104.839,18.2ZM88.227,28.6a1.734,1.734,0,1,1,1.733-1.734A1.734,1.734,0,0,1,88.227,28.6Zm5.2-15.6a5.2,5.2,0,0,1-4.334,5.129v4.353H87.36V16.469h.867A3.467,3.467,0,1,0,84.76,13v.867H83.026V13a5.2,5.2,0,1,1,10.4,0Z" transform="translate(-70)" fill="#1c3947" />
        </svg>
    );
}

export default SvgComponent;
