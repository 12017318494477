import React from 'react';
import ButtonNew from '../../components/atoms/Button';
import './SavingsSummary.scss';
import Image from '../../Assets/images/homeownerSavings/summaryimage.png';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';

// import FileDownloadIcon from '@material-ui/icons/FileDownload';

type initialProps = {
    history?: any;
};
type initialState = {};

class SavingsSummaryPage extends React.Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        return (
            <div className="savingsSummaryFlowMainPage">
                <div className="savingSummaryFlexMainDiv">
                    <div className="savingsSummaryTitle">Done, call has been scheduled for savings</div>
                    <div className="savingsSummarySubTitle">We have sent an email on the meeting schedule</div>
                    <div className="savingsSummaryCardMainDiv">
                        <div className="savingsSummaryImageDiv">
                            <img src={Image} className="savingsSummaryImage" />
                        </div>
                        <div className="savingsSummaryDetailsDiv">
                            <div className="savingsSummaryName">Mike</div>
                            <div className="savingsSummarySubName">Dedicated Concierge</div>
                            <div className="savingsSummaryPhoneDiv">
                                <LocalPhoneIcon className="savingsSummaryIcon" /> 614-350-3691
                            </div>
                            <div className="savingsSummaryPhoneDiv">
                                <AccessTimeIcon className="savingsSummaryIcon" /> 30 min
                            </div>
                            <div className="savingsSummaryPhoneDiv">
                                <DateRangeIcon className="savingsSummaryIcon" /> 12:30am - 12:45am, Friday, July 23,
                                2021
                            </div>
                            <div className="savingsSummaryDetailsButtonDiv">
                                <ButtonNew
                                    className="rescheduleButton"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }`}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    backgroundColor="#ffffff"
                                >
                                    Reshedule
                                </ButtonNew>
                                <ButtonNew
                                    className="downloadButton"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }`}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    backgroundColor="#ffffff"
                                >
                                    {/* <FileDownloadIcon className="savingsSummaryIcon" /> */}
                                    Download Vcard
                                </ButtonNew>
                            </div>
                        </div>
                    </div>
                    <div className="savingsSummaryButtonDiv">
                        <ButtonNew
                            className="savingsSummaryButton"
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Back To Dashboard
                        </ButtonNew>
                    </div>
                </div>
            </div>
        );
    }
}

export default SavingsSummaryPage;
