import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '../../components/atoms/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// import CustomizedInput from '../../components/atoms/CustomizedInput';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import BrokerageCard from './brokerageCard';
// import ApprovalCard from './ApprovalCard';
// import ActivateCard from './ActivateCard';
// import nextMove from '../../Assets/images/NextMove/profileImage.png';
// import brokLogo from '../../Assets/images/NextMove/brokLogo.png';
import brokLogoMobile from '../../Assets/images/NextMove/brokLogoMobile.png';
// import guarantee from '../../Assets/images/NextMove/guarantee.png';
// import guaranteeMobile from '../../Assets/images/NextMove/mobileApproval.png';
import homeInsurance from '../../Assets/images/NextMove/homeInsurance.png';
import homeSecurity from '../../Assets/images/NextMove/homeSecurity.png';
import homeWaranty from '../../Assets/images/NextMove/homeWaranty.png';
import internet from '../../Assets/images/NextMove/internet.png';
import mortrage from '../../Assets/images/NextMove/mortrage.png';
import movingService from '../../Assets/images/NextMove/movingService.png';
import title from '../../Assets/images/NextMove/title.png';
import utility from '../../Assets/images/NextMove/utility.png';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import sideImage from '../../Assets/images/NextMove/sideImageNext1.svg';
import './NextMove.scss';
import { connect } from 'react-redux';
import * as Action from '../../store/nextMove/actions';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import { CircularProgress } from '@material-ui/core';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
type initialProps = {
    history?: any;
    nextMoveAction: any;

    commonState?: any;
    nextMoveState?: any;
};
type initialState = {
    activeStep: number;
    step2: {
        address: string;
    };

    // loanTermOptions: any;
    // insuranceProviderOptions: any;
    // internetProviderOptions: any;
    // securityProviderOptions: any;
    // electricityProviderOptions: any;
    // gasProviderOptions: any;
    houseOption: string;
    activateData: any;
    slug: any;
    moveId: any;
    isMoveDataArrived: boolean;
    destinationZip: string;
    destinationAddress: string;
    destinationAddressPay: string;
    isSubmitClicked: boolean;
    realtorName: string;
    contact_logo: any;
    website_url: any;
    phone: string;
    email: string;
    logoDynamic: string;
    loader: boolean;
    street: any;
    source?: any;
    streetSource: string;
    streetError?: any;
    brokerage?: any;
    brokerage_id?: any;
    agent_id?: any;
    brokerage_key: any;
    agent_key: any;
};

class NextMove extends React.Component<initialProps, initialState> {
    state: initialState = {
        slug: '',
        activeStep: 0,
        // step1: {
        //     loanBalance: '',
        //     loanTerm: null,
        //     loanPercentage: '',
        // },
        step2: {
            address: '',
        },
        // step3: {
        //     internetProvider: null,
        //     internetPayingAmount: '',
        //     internet: false,
        //     tv: false,
        //     phone: false,
        // },
        // step4: {
        //     securityProvider: null,
        //     securityPayingAmount: '',
        // },
        // step5: {
        //     electricityProvider: null,
        //     electricityPayingAmount: '',
        //     gasProvider: null,
        //     gasPayingAmount: '',
        // },
        // loanTermOptions: [
        //     { value: '30-years fixed', label: '30-years fixed' },
        //     { value: '20-years fixed', label: '20-years fixed' },
        //     { value: '15-years fixed', label: '15-years fixed' },
        //     { value: '10-years fixed', label: '10-years fixed' },
        // ],
        // insuranceProviderOptions: [],
        // internetProviderOptions: [],
        // securityProviderOptions: [],
        // electricityProviderOptions: [],
        // gasProviderOptions: [],
        houseOption: '',
        activateData: [
            { head: 'Utility Providers', logo: utility },
            { head: 'Utility Providers', logo: homeInsurance },
            { head: 'Utility Providers', logo: homeSecurity },
            { head: 'Utility Providers', logo: homeWaranty },
            { head: 'Utility Providers', logo: internet },
            { head: 'Utility Providers', logo: mortrage },
            { head: 'Utility Providers', logo: movingService },
            { head: 'Utility Providers', logo: title },
        ],
        moveId: '',
        isMoveDataArrived: true,
        destinationZip: '',
        destinationAddress: '',
        destinationAddressPay: '',
        isSubmitClicked: false,
        realtorName: '',
        contact_logo: null,
        website_url: null,
        phone: '',
        email: '',
        logoDynamic: '',
        loader: false,
        street: '',
        streetSource: '',
        source: '',
        brokerage: '',
        brokerage_id: '',
        agent_id: '',
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        if (this.props && this.props.commonState && this.props.commonState.move && this.props.commonState.move[0]) {
            this.setState({
                destinationZip: this.props.commonState.move[0].destination_full_address,
                destinationAddress:
                    this.props.commonState.move[0].destination &&
                    this.props.commonState.move[0].destination &&
                    this.props.commonState.move[0].destination.lookup_name,
                moveId: this.props.commonState.move[0].id,
                destinationAddressPay: this.props.commonState.move[0].destination_full_address,
            });
        }
    }
    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;

        if (prev.move != cur.move && cur.move[0]) {
            this.setState({
                destinationZip: cur.move[0].destination_full_address,
                destinationAddress:
                    cur.move[0].destination && cur.move[0].destination && cur.move[0].destination.lookup_name,
                moveId: this.props.commonState.move[0].id,
                destinationAddressPay: cur.move[0].destination_full_address,
                realtorName: cur.move[0].realtor && cur.move[0].realtor.contact_name,
                contact_logo: cur.move[0].realtor && cur.move[0].realtor.contact_logo,
                website_url: cur.move[0].realtor && cur.move[0].realtor.website_url,
                phone: cur.move[0].realtor && cur.move[0].realtor.phone,
                email: cur.move[0].realtor && cur.move[0].realtor.realtor_email,
                logoDynamic: cur.move[0].realtor && cur.move[0].realtor.logo,
            });
        }
    }
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name
                    ? nextProps.commonState.move[0].realtor.display_name
                    : '';
            currentState.brokerage_id =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.brokerage_link
                    ? nextProps.commonState.move[0].realtor.brokerage_link
                    : '';
            currentState.agent_id =
                nextProps.commonState.move &&
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id
                    ? nextProps.commonState.move[0].realtor.id
                    : '';
        }
        if (
            nextProps &&
            nextProps.nextMoveState &&
            nextProps.nextMoveState.sendRealtorNotification &&
            nextProps.nextMoveState.isSendRealtorNotification
        ) {
            nextProps.nextMoveState.isSendRealtorNotification = false;
            currentState.isSubmitClicked = false;
            currentState.loader = false;
            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                `Next Move Notification Completed`,
                {
                    page: 'Next Move',
                    brokerage_name: currentState.brokerage,
                    brokerage_key: currentState.brokerage_key,
                    agent_key: currentState.agent_key,
                    agent_name: currentState.realtorName,
                },
                { user_id: currentState.moveId },
            );
            nextProps.history.push('/dashboard/nextMoveFinal');
        } else if (
            nextProps &&
            nextProps.nextMoveState &&
            nextProps.nextMoveState.postSubmitContractError &&
            nextProps.nextMoveState.postSubmitContractError != ''
        ) {
            currentState.loader = false;
        }
        return currentState;
    }
    onChangeStep2 = (e: any, name: string) => {
        let step2 = { ...this.state.step2, [name]: e.target.value };
        this.setState({ step2: step2 });
    };

    handleHouseChange = (e: any) => {
        this.setState({ houseOption: e.target.value, isSubmitClicked: false });
    };
    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        // street: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                        street: `${streetNumber} ${route}  ${city}, ${state} ${zip}`,
                        source: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        streetSource: `${streetNumber} ${route}`,
                        isSubmitClicked: false,

                        //  ${streetNumber} ${route}, ${city}, ${state} ${zip}
                    });
                }
            })
            .catch(error => console.error('error', error));
    };
    handleNext = () => {
        if (this.state.activeStep === 0) {
            this.setState({
                isSubmitClicked: true,
            });
            if (this.state.houseOption) {
                this.setState({
                    activeStep: 1,
                });
            }
        } else if (this.state.activeStep === 1) {
            // this.setState({
            //     isSubmitClicked: true,
            // });
            if (this.state.moveId && this.state.street && this.state.houseOption) {
                let payload = {
                    move_id: this.state.moveId,
                    destination_address: this.state.street,
                    transaction_type: this.state.houseOption,
                    moving_date: '',
                };
                this.props.nextMoveAction.postNextMove(payload);
                this.setState({
                    activeStep: 2,
                });
            }
        } else if (this.state.activeStep === 2) {
            let payload = {
                moveId: this.state.moveId,
            };
            this.props.nextMoveAction.sendRealtorNotification(payload);
        }
    };
    handleFinal = () => {
        this.props.history.push('/dashboard/nextMoveFinal');
    };

    getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <div className="nextMoveStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <div className="addressNextMoveMain">{this.state.destinationZip}</div>
                            </div>
                        </div>
                        <div className="inputDivSavings">
                            <div className="inputFlowNextMove">
                                <RadioGroup
                                    aria-label="contact"
                                    name="contact"
                                    className="energyRadio"
                                    value={this.state.houseOption}
                                    onChange={this.handleHouseChange}
                                >
                                    <FormControlLabel
                                        className="energyRadio1"
                                        value="Sell"
                                        control={<Radio />}
                                        label="Sell It"
                                    />
                                    <FormControlLabel
                                        className="energyRadio1"
                                        value="Rent"
                                        control={<Radio />}
                                        label="Rent It"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.houseOption == '' ? 'Please Select Any One' : ''}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="nextMoveStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <AutoComplete
                                    className="input1-signUp2"
                                    error={Boolean(this.state.streetError)}
                                    value={this.state.street}
                                    onChange={e => {
                                        this.setState({ street: e, isSubmitClicked: false });
                                    }}
                                    onSelect={this.handleSelect}
                                    placeholder="City/State"

                                    // inputTitle="To"
                                />
                            </div>
                        </div>
                        {/* {this.state.isSubmitClicked && this.state.street == '' ? (
                            <div className="error">
                                {''}

                                <div> Please enter the destination address</div>
                            </div>
                        ) : null} */}
                    </div>
                );
            case 2:
                return (
                    <div className="nextMoveStepsContent">
                        <div className="inputDivSavings">
                            <div className="addressNextMoveMain">
                                <span className="greatBrokerageNextMove">
                                    Great, I can help you with your next move
                                </span>
                            </div>
                        </div>

                        <div className="">
                            <BrokerageCard
                                profileLogo={this.state.contact_logo}
                                profileName={this.state.realtorName}
                                phoneNumber={this.state.phone}
                                email={this.state.email}
                                website={this.state.website_url}
                                brokLogo={this.state.logoDynamic}
                                brokLogoMobile={brokLogoMobile}
                                handleAgentBrok={this.handleNext}
                            />
                        </div>
                    </div>
                );
            // case 3:
            //     return (
            //         <div className="nextMoveStepsContent">
            //             <div className="nextMoveStepsApproval">
            //                 <ApprovalCard
            //                     guranteeCard={guarantee}
            //                     guranteeCardMobile={guaranteeMobile}
            //                     inquiryHandle={this.handleNext}
            //                 />
            //             </div>
            //         </div>
            //     );
            // case 4:
            //     return (
            //         <div className="nextMoveStepsContent">
            //             <div className="activateNextMove">Let’s activate your new moving account</div>
            //             <div className="activateGetAccess">
            //                 Get access to all our moving related services, you don’t have to do all of these now, but we
            //                 are just getting you started
            //             </div>
            //             <div className="activateLastStep">
            //                 {this.state.activateData.map((item: any) => {
            //                     return (
            //                         <div className="activateCardNextMove">
            //                             <ActivateCard activateLogo={item.logo} head={item.head} />
            //                         </div>
            //                     );
            //                 })}
            //             </div>
            //             <div className="nextMoveStepButton">
            //                 <Button
            //                     backgroundColor={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                     color={'fff'}
            //                     className=""
            //                     onClick={this.handleFinal}
            //                 >
            //                     Activate My Account
            //                 </Button>
            //             </div>
            //         </div>
            //     );
            default:
                return 'Unknown step';
        }
    }

    getSteps() {
        return [
            'What Do You Plan To Do With Your Current House Located At:',
            'Where are you moving?',
            'How Can We Help You?',
            // 'Get Pre Approval On Your Mortgage',
            // 'Activate Your Moving Account',
        ];
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const steps = this.getSteps();

        return (
            <div className="nextMoveFlowMainPage">
                <div className="progressBarFinalMove">
                    <Box className="checkcheck">
                        <LinearProgress variant="determinate" value={((this.state.activeStep + 1) / 5) * 100} />
                    </Box>
                </div>
                {this.state.loader ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '60vh',
                        }}
                    >
                        <CircularProgress />
                        <div style={{ marginTop: '10px' }}>processing your request...please wait</div>
                    </div>
                ) : (
                    <div className="nextMoveFlowMainFlexDiv">
                        <div className="imageContentNextMoveDiv">
                            <div className="image">
                                <img src={sideImage} />
                            </div>
                        </div>
                        <div className="stepperNextMoveMainDiv">
                            <div
                                className="backIcon"
                                onClick={() => {
                                    this.props.history.push('/dashboardHomeOwner');
                                }}
                            >
                                <ArrowBackIcon />
                            </div>
                            <div className="nextMoveHeading">Let us know some information about your next move</div>
                            <div className="StepperDivNextMove">
                                <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel>
                                                <div className="labelDiv">
                                                    <div
                                                        className={
                                                            this.state.activeStep === index
                                                                ? 'labelDivMain'
                                                                : 'labelNextMove'
                                                        }
                                                    >
                                                        {label}
                                                    </div>
                                                </div>
                                                {/* {this.state.activeStep > index ? (
                                                    <KeyboardArrowDownIcon
                                                        onClick={() => {
                                                            if (this.state.activeStep > index) {
                                                                this.setState({ activeStep: index });
                                                            }
                                                        }}
                                                    />
                                                ) : null} */}

                                                <div>
                                                    {/* {this.state.activeStep === 0 || this.state.activeStep === 1 ? (
                                                        this.state.activeStep===index?(
                                                        <KeyboardArrowUpIcon />
                                                    ) : ((
                                                        <KeyboardArrowDownIcon
                                                            onClick={() => {
                                                                if (this.state.activeStep > index) {
                                                                    this.setState({ activeStep: index });
                                                                }
                                                            }}
                                                        />
                                                    )) */}

                                                    {this.state.activeStep > index ? (
                                                        this.state.activeStep === index ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                onClick={() => {
                                                                    if (
                                                                        this.state.activeStep === 0 ||
                                                                        this.state.activeStep === 1
                                                                    ) {
                                                                        if (this.state.activeStep > index) {
                                                                            this.setState({ activeStep: index });
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    ) : null}
                                                </div>
                                            </StepLabel>
                                            <StepContent>
                                                <div>{this.getStepContent(index)}</div>
                                                {this.state.activeStep === 0 || this.state.activeStep === 1 ? (
                                                    <div className="savingStepButton">
                                                        <Button
                                                            // backgroundColor={
                                                            //     slug && slug.primary_color
                                                            //         ? slug.primary_color
                                                            //         : '#273E59'
                                                            // }
                                                            className="savingNextButton"
                                                            // color={'#fff'}
                                                            onClick={this.handleNext}
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                ) : null}
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        nextMoveAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    nextMoveState: state.nextMove,
});
export default connect(mapStateToProps, mapDispatchToProps)(NextMove);
