import { all, call, put, takeLatest } from 'redux-saga/effects';
// import { store } from 'react-notifications-component';
import {
  offersFailure,
  offersSuccess,
  postSecurityFailure,
  postSecuritySuccess
} from './actions';
import {

    GET_OFFERS,
    POST_SECURITY
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* offers() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: ``,
        });
        if (response.data && response.status === 200) {
            yield put(offersSuccess({ offers: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(offersFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}


//post security
function* PostSecurity(action: any) {
    let paramObj : { method: string, url:string, token?:string, body?:string } = {
        method: 'post',
        url: `/api/services/move-selected-security-plans/`,
    }
    if(action.payload.token){
        paramObj = {...paramObj, token: action.payload.token }
    }

    if(action.payload.apiPayload){
        paramObj = {...paramObj, body: action.payload.apiPayload }
    }

    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, paramObj);
        if (response.data && response.status === 201) {
            yield put(postSecuritySuccess({ postSecurity: response.data }));
 
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postSecurityFailure({ error: (e as Error).message }));
        // store.addNotification({
        //     title: 'Error',
        //     message: 'Error Occurred!',
        //     type: 'danger',
        //     insert: 'top',
        //     container: 'top-right',
        //     animationIn: ['animate__animated', 'animate__fadeIn'],
        //     animationOut: ['animate__animated', 'animate__fadeOut'],
        //     dismiss: {
        //         duration: 2000,
        //         onScreen: true,
        //     },
        // });
        // history.push('/server-error');
    }
}



export default function* securitySaga() {
   
    yield all([takeLatest(GET_OFFERS, offers)]);
    yield all([takeLatest(POST_SECURITY, PostSecurity)]);
  
}
