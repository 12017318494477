import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54.575} {...props}>
            <defs>
                <style>
                    {slug && slug.primary_color
                        ? props.isDouglasElliman
                            ? `.projectclosingprefix__c{fill:#100b28}`
                            : `.projectclosingprefix__c{fill:${slug.primary_color}}`
                        : '.projectclosingprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <g transform="translate(-419.268 -293.713)">
                <path
                    data-name="Rectangle 4636"
                    fill={
                        slug && slug.color_shade4 ? (props.isDouglasElliman ? '#ffffff' : slug.color_shade4) : '#5b7088'
                    }
                    d="M421 300h47v10h-47z"
                />
                <circle
                    cx={10.5}
                    cy={10.5}
                    r={10.5}
                    transform="translate(452 327)"
                    fill={
                        slug && slug.color_shade4 ? (props.isDouglasElliman ? '#ffffff' : slug.color_shade4) : '#5b7088'
                    }
                />
                <g fill="#fff">
                    <path
                        className="projectclosingprefix__c"
                        d="M432.251 316.405a4.136 4.136 0 1 0 4.136 4.136 4.119 4.119 0 0 0-4.136-4.136Zm0 5.8a1.666 1.666 0 1 1 1.666-1.666 1.667 1.667 0 0 1-1.666 1.666Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M444.373 316.405a4.136 4.136 0 1 0 4.136 4.136 4.119 4.119 0 0 0-4.136-4.136Zm0 5.8a1.666 1.666 0 1 1 1.664-1.663 1.667 1.667 0 0 1-1.664 1.663Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M456.498 324.677a4.136 4.136 0 1 0-4.136-4.136 4.119 4.119 0 0 0 4.136 4.136Zm0-5.8a1.666 1.666 0 1 1-1.666 1.666 1.667 1.667 0 0 1 1.666-1.668Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M432.251 329.043a4.136 4.136 0 1 0 4.136 4.136 4.119 4.119 0 0 0-4.136-4.136Zm0 5.8a1.666 1.666 0 1 1 1.666-1.666 1.667 1.667 0 0 1-1.666 1.666Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M444.373 329.043a4.136 4.136 0 1 0 4.136 4.136 4.119 4.119 0 0 0-4.136-4.136Zm0 5.8a1.666 1.666 0 1 1 1.664-1.663 1.667 1.667 0 0 1-1.664 1.663Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M469.477 329.158v-25.277a5.394 5.394 0 0 0-5.4-5.4h-3.562v-1.091a3.7 3.7 0 0 0-3.676-3.675h-.8a3.7 3.7 0 0 0-3.677 3.677v1.092H436.33v-1.094a3.7 3.7 0 0 0-3.676-3.675h-.8a3.7 3.7 0 0 0-3.685 3.675v1.092h-3.5a5.345 5.345 0 0 0-5.4 5.342v35.272a5.394 5.394 0 0 0 5.4 5.4h29.413a10.916 10.916 0 0 0 19.187-7.181 10.8 10.8 0 0 0-3.792-8.157Zm-14.534-31.768a1.152 1.152 0 0 1 1.149-1.149h.8a1.152 1.152 0 0 1 1.149 1.149v4.653a1.152 1.152 0 0 1-1.149 1.149h-.8a1.152 1.152 0 0 1-1.149-1.149Zm-24.243 0a1.152 1.152 0 0 1 1.149-1.149h.8a1.152 1.152 0 0 1 1.149 1.149v4.653a1.152 1.152 0 0 1-1.149 1.149h-.8a1.152 1.152 0 0 1-1.149-1.149Zm-6.031 3.561h3.5v1.092a3.7 3.7 0 0 0 3.681 3.672h.8a3.7 3.7 0 0 0 3.677-3.677v-1.087h16.142v1.092a3.7 3.7 0 0 0 3.681 3.672h.8a3.7 3.7 0 0 0 3.677-3.677v-1.087h3.447a2.942 2.942 0 0 1 2.93 2.93v5.342h-45.208v-5.341a2.894 2.894 0 0 1 2.872-2.93Zm0 41.075a2.942 2.942 0 0 1-2.93-2.93v-27.4h45.21v15.8a10.72 10.72 0 0 0-4.711-1.092 10.923 10.923 0 0 0-9.881 15.683h-27.688Zm37.628 3.789a8.445 8.445 0 1 1 8.445-8.445 8.459 8.459 0 0 1-8.445 8.445Z"
                    />
                    <path
                        className="projectclosingprefix__c"
                        d="M465.915 333.696a1.322 1.322 0 0 0-1.781 0l-1.9 1.9-1.9-1.9a1.26 1.26 0 1 0-1.781 1.781l1.9 1.9-1.9 1.9a1.322 1.322 0 0 0 0 1.781 1.249 1.249 0 0 0 1.723 0l1.9-1.9 1.9 1.9a1.267 1.267 0 0 0 .862.345 1.169 1.169 0 0 0 .862-.345 1.322 1.322 0 0 0 0-1.781l-1.723-1.953 1.9-1.9a1.2 1.2 0 0 0-.058-1.723Z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
