import React, { Component } from 'react';
import './utility.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonNew from '../../components/atoms/Button';
// import Button from '../../components/atoms/Button';
import * as EnergyAction from '../../store/energy/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QuestionSkeleton } from '../../components/skeletonLoaders/EnergySkeleton/QuestionSkeleton';
import SelectInput from '../../components/atoms/SelectInput';
import { UIContext } from '../../UIContext';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
};
type initialState = {
    selectAddressOptions: any;
    selectAddress: any;
    slug: any;
    serviceTypeId: any;
    selectedCompany: any;
    energyType: any;
    selectedEnergyType: any;
    energyTypeSelectedObject: any;
    electricityCompleted: boolean;
    gasCompleted: boolean;
    summaryData: any;
    loader: boolean;
    isLoading: boolean;
    prefilled: boolean;
    isAnalitycsTriggerredOnce: any;
    isFormStarted: boolean;
};

export class utilityStepsPODS extends Component<initialProps, initialState> {
    appContext = this.context;

    state: initialState = {
        selectAddressOptions: [],
        selectAddress: '',
        slug: { primary_color: '' },
        serviceTypeId: '',
        selectedCompany: [],
        energyType: [],
        selectedEnergyType: 'Electricity',
        energyTypeSelectedObject: [],
        electricityCompleted: false,
        gasCompleted: false,
        summaryData: [],
        loader: false,
        isLoading: true,
        prefilled: false,
        isAnalitycsTriggerredOnce: {
            energyType: false,
            provider: false
        },
        isFormStarted: false
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.energyAction.getEnergySummary();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            let addressData: any = localStorage.getItem('pods_destination_address');
            if (addressData) {
                addressData = JSON.parse(addressData)
            }
            if (this.props.history.location.state.serviceTypeId && addressData) {
                this.setState({ serviceTypeId: this.props.history.location.state.serviceTypeId });
                this.props.energyAction.getCompany({
                    service_type_id: this.props.history.location.state.serviceTypeId,
                    zip_code: addressData ? addressData.zipcode : ""
                });
            }
            if (this.props.history.location.state.showCompletedElectricity) {
                this.setState({
                    electricityCompleted: this.props.history.location.state.showCompletedElectricity,
                    selectedEnergyType: 'Natural Gas',
                });
            }
            if (this.props.history.location.state.showCompletedGas) {
                this.setState({ gasCompleted: this.props.history.location.state.showCompletedGas });
            }
            if (this.props.history.location.state.selectedCompany) {
                this.setState({
                    selectAddress: this.props.history.location.state.selectedCompany[0].utility_name,
                    selectedCompany: this.props.history.location.state.selectedCompany,
                });
            }
        }
        this.props.energyAction.getEnergyType();
        if (this.state.selectedEnergyType && this.state.selectAddress) {
            this.setState({
                prefilled: true,
            });
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getEnergyType != curEnergy.getEnergyType &&
            curEnergy.getEnergyType.services &&
            curEnergy.isGetEnergyType
        ) {
            curEnergy.isGetEnergyType = false;
            let energyType: any = [];
            let data: any = [];
            curEnergy.getEnergyType.services &&
                curEnergy.getEnergyType.services.map((item: any) => {
                    if (item.name != 'Community Solar') {
                        energyType.push(item);
                    }
                    return energyType;
                });
            data.push(energyType.find((item: any) => item.name === this.state.selectedEnergyType));
            this.setState({
                selectedEnergyType: this.state.selectedEnergyType,
                energyTypeSelectedObject: data,
                serviceTypeId: data[0].serviceTypeId,
            });
            let addressData: any = localStorage.getItem('pods_destination_address');
            if (addressData) {
                addressData = JSON.parse(addressData)
            }
            this.props.energyAction.getCompany({
                service_type_id: data[0].serviceTypeId,
                zip_code: addressData ? addressData.zipcode : ""
            });
            this.setState({ energyType: energyType });
        }

        if (prevEnergy.getCompany != curEnergy.getCompany && curEnergy.getCompany.utilities && curEnergy.isGetCompany) {
            curEnergy.isGetCompany = false;

            let options: any = [];
            curEnergy.getCompany.utilities &&
                curEnergy.getCompany.utilities.map((item: any) => {
                    options.push({ item: item, label: item.utility_name, value: item.utility_name });
                    return options;
                });
            this.setState({ selectAddressOptions: options, isLoading: false });
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ summaryData: curEnergy.getEnergySummary });
        }
    }

    handleAnalyticsTracking = (fieldName: string, prefilled: boolean) => {
        const trackProperties = {
            page: "Energy Savings",
            category_name: 'Energy Savings',
            form_field_name: fieldName,
            form_pre_filled: prefilled,
            form_step_number: 1,
            button_copy: 'Next',
            form_button: true,
        }

        this.state.isFormStarted ? AnalyticsService.formFieldInput(trackProperties) : AnalyticsService.formFieldStarted(trackProperties);
        this.setState({ isFormStarted: true });
    }

    handleChangeSelectAddress = (e: any) => {
        if (!this.state.isAnalitycsTriggerredOnce.provider) {
            this.handleAnalyticsTracking('Select Current Provider', false);
        }

        this.setState({
            isAnalitycsTriggerredOnce: {
                ...this.state.isAnalitycsTriggerredOnce,
                provider: true
            }
        });

        let data: any = [];

        this.state.selectAddressOptions.map((item: any) => {
            if (item && item.item && item.item.utility_name === e.value) {
                data.push(item.item);
                return data;
            }
        });
        
        this.setState({ selectAddress: e.value, selectedCompany: data });
    };

    handleChangeEnergyType = (e: any) => {
        if (!this.state.isAnalitycsTriggerredOnce.energyType) {
            this.handleAnalyticsTracking(`Select your current ${this.state.selectedEnergyType} Provider`, true);
        }

        this.setState({
            isAnalitycsTriggerredOnce: {
                ...this.state.isAnalitycsTriggerredOnce,
                energyType: true
            }
        });

        let data: any = [];
        data.push(this.state.energyType.find((item: any) => item.name === e.target.value));
        this.setState({
            selectedEnergyType: e.target.value,
            energyTypeSelectedObject: data,
            serviceTypeId: data[0].serviceTypeId,
        });
        let addressData: any = localStorage.getItem('pods_destination_address');
        if (addressData) {
            addressData = JSON.parse(addressData)
        }
        this.props.energyAction.getCompany({
            service_type_id: data[0].serviceTypeId,
            zip_code: addressData ? addressData.zipcode : ""
        });
    };

    analiticsTracking = (buttonCopy: string) => {
        AnalyticsService.buttonClicked({
            page: 'Energy Savings',
            category_name: 'Energy Savings EnergySteps',
            button_copy: buttonCopy,
            form_button: true,
        });
    }

    handleGoBack = () => {
        this.analiticsTracking('Go Back');
        this.props.history.push('/dashboard/energy-savings');
    }

    handleNext = () => {
        this.analiticsTracking('Next');
        AnalyticsService.formCompleted({
            page: 'Energy Savings',
            category_name: 'Energy Savings EnergySteps',
            button_copy: 'Next',
            form_name: 'Select your current ... Provider'
        })

        if (this.state.selectedEnergyType && this.state.selectedCompany.length !== 0) {
            this.props.history.push({
                pathname: '/dashboard/energy-savings/utilitiesPowerSaving',
                state: {
                    ...this.props.history.location.state,
                    serviceTypeId: this.state.serviceTypeId,
                    serviceTypeName: this.state.selectedEnergyType,
                    selectedCompany: this.state.selectedCompany,
                },
            });
        }
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        // let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let showCompletedElectricity: boolean = false;
        let showCompletedGas: boolean = false;
        this.state.summaryData &&
            this.state.summaryData.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });

        return (
            <div>
                <div className="utility-powersaving-head">
                    <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>Energy Savings</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Energy Savings</p>
                    </div>
                    <div className="utility">
                        <div className="utilitesSteps">
                            <div className="utilityhead" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>
                                Select your current {this.state.selectedEnergyType} Provider
                            </div>
                            {this.state.energyType && this.state.energyType.length ? (
                                <div className="utilityInput">
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={this.state.selectedEnergyType}
                                            onChange={this.handleChangeEnergyType}
                                        >
                                            {this.state.energyType.map((option: any) => {
                                                return (
                                                    <div
                                                        className="radioInputsUtility"
                                                        style={{
                                                            border:
                                                                this.state.selectedEnergyType === option.name
                                                                    ? '1px solid #273E59'
                                                                    : '1px solid #BEBEBE',
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value={option.name}
                                                            control={
                                                                <Radio
                                                                    disabled={
                                                                        option.name === 'Electricity' &&
                                                                            showCompletedElectricity
                                                                            ? true
                                                                            : option.name === 'Natural Gas' &&
                                                                                showCompletedGas
                                                                                ? true
                                                                                : false
                                                                    }
                                                                />
                                                            }
                                                            label={option.name}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            ) : (
                                <div
                                    className="utilityInput"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <QuestionSkeleton />
                                    <QuestionSkeleton />
                                </div>
                            )}
                            <div className="selectDiv">
                                <SelectInput
                                    label={'Select Current Provider'}
                                    value={this.state.selectAddress}
                                    onChange={this.handleChangeSelectAddress}
                                    placeholder={
                                        this.state.selectAddress ? this.state.selectAddress : 'Select current provider'
                                    }
                                    options={this.state.selectAddressOptions}
                                    isLoading={this.state.isLoading}
                                />
                            </div>
                            <div className="buttonUtilitySteps">
                                <ButtonNew
                                    className="btnUtilitySecondSteps"
                                    color={slug.primary_color ? slug.primary_color : '#FFF'}
                                    // backgroundColor={slug.primary_color ? slug.primary_color : 'rgb(206, 32, 47)'}
                                    backgroundColor={'#fff'}
                                    // color={
                                    //     isDouglasElliman
                                    //         ? '#100B28'
                                    //         : this.state.slug && this.state.slug.primary_color
                                    //         ? this.state.slug.primary_color
                                    //         : '#273E59'
                                    // }
                                    border={slug.primary_color ? '1px solid' + slug.primary_color : '1px solid rgb(206, 32, 47)'}
                                    // border={
                                    //     isDouglasElliman
                                    //         ? '#100B28'
                                    //         : this.state.slug && this.state.slug.primary_color
                                    //         ? `1px solid ${this.state.slug.primary_color}`
                                    //         : '1px solid #273E59'
                                    // }
                                    onClick={this.handleGoBack}
                                >
                                    Go Back
                                </ButtonNew>
                                <ButtonNew
                                    className="btnUtilitySecondSteps"
                                    // buttonBackGroundColor={slug.primary_color ? slug.primary_color : 'rgb(206, 32, 47)'}
                                    color={slug.primary_font_color ? slug.primary_font_color : '#FFF'}
                                    backgroundColor={slug.primary_color ? slug.primary_color : '#ce202f'}
                                    // color={'#fff'}
                                    onClick={this.handleNext}
                                    disabled={
                                        this.state.selectedEnergyType &&
                                            this.state.selectedCompany.length !== 0 &&
                                            this.state.selectAddress
                                            ? false
                                            : true
                                    }
                                >
                                    Next
                                </ButtonNew>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

utilityStepsPODS.contextType = UIContext;

export default connect(mapStateToProps, mapDispatchToProps)(utilityStepsPODS);
