import React from 'react';
import '../DashboardHomeOwner/dashboardHomeOwner.scss';
import './DashboardPODS.scss';
import BlogIcon from '../../Assets/images/DashBoard/auto_stories_black_24dp.svg';
import BlogCardTopImage from '../../components/dashboardComponents/BlogCardTopImage';
import BlogCardRightImage from '../../components/dashboardComponents/BlogCardRightImage';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Blog1 from '../../Assets/images/DashBoard/dashboardInterCable.jpg';
import Blog2 from '../../Assets/images/DashBoard/dashboardGuideToHome.png';
import Blog3 from '../../Assets/images/DashBoard/dashboardThingsTodo.jpg';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import MovingNextCard from '../../components/atoms/MovingNextCard';
import localStorage from '../../utils/localStorage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/dashboard/actions';
import * as CommonAction from '../../store/common/actions';
import { UIContext } from '../../UIContext';
import AnalyticsService from '../../utils/analytics-service';
import { capitalizeFirstLetter, isElementOnViewPort } from '../../utils/helpers';
import { blogsTitles } from '../../utils/dataUtils';
import homeIcon from '../../Assets/images/DashboardPODS/home-black-icon.svg'

type initialProps = {
    history?: any;
    checkListDashboardState?: any;
    commonState?: any;
    dashboardAction?:any;
    commonAction?: any;
};

type initialState = {
    date: any;
    destination_address: any;
    address_data: {
        street: string;
        city: string;
        apt: string;
        zipcode: string;
        address: string;
    };
    checklistData?: any;
    differenceInTime: any;
    size: number;
    anchorEl?: any;
    isAnalitycsTriggerredOnce: any;
    isFormStarted: boolean;
};


class DashboardPODS extends React.Component<initialProps, initialState> {
    appContext = this.context;
    state: initialState = {
        date: null,
        destination_address: '',
        address_data: {
            street: '',
            city: '',
            apt: '',
            zipcode: '',
            address: '',
        },
        checklistData: [],
        differenceInTime: '',
        size: 0,
        anchorEl: null,
        isAnalitycsTriggerredOnce: {
            address: false,
            date: false,
            blogs: false,
            checklists: false
        },
        isFormStarted: false
    };

    componentDidMount() {
        this.props.dashboardAction.podsChecklist();
        const addressObj = localStorage.getItem('pods_destination_address')
        if(addressObj){
            this.setState({destination_address: JSON.parse(addressObj)?.address})
        }
        const getMovingDate = localStorage.getItem('pods_dashboard_moving_date');
        if(getMovingDate){
            let date1: any = new Date(getMovingDate);
            let date2: any = new Date();
            if (date1 > date2) {
                this.setState({date : getMovingDate});
                this.dateDiffrenceCalculator(getMovingDate)
            }
        }
        AnalyticsService.pageViewed({ page: "Dashboard" });

        window.addEventListener('scroll', () => this.checkIsInViewPort('checklists-section'), false);
        window.addEventListener('scroll', () => this.checkIsInViewPort('dashboard-blogs-section'), false);
    }

    checkIsInViewPort = (className: string) => {
        if (isElementOnViewPort(className)) {
            const param = className === 'checklists-section' ? 'checklists' : 'blogs';
            this.cardViewAnalitycs(param);
        }
    }

    componentWillUnmount(): void {
       this.killEventListener();
    }

    killEventListener = () => {
        window.removeEventListener('scroll', () => this.checkIsInViewPort);
    }

    cardViewAnalitycs = (cardType: string) => {
        if (this.state.isAnalitycsTriggerredOnce.checklists && this.state.isAnalitycsTriggerredOnce.blogs) {
            this.killEventListener();
        }

       let cardTitle: string[] = [];

        if (cardType === 'checklists') {
            if (this.state.isAnalitycsTriggerredOnce.checklists) return;
            const data = this.state.checklistData.map((item: any) => item.title);
            cardTitle = data;
        } else {
            if (this.state.isAnalitycsTriggerredOnce.blogs) return;
            cardTitle = blogsTitles;
        }

        if (!this.state.isAnalitycsTriggerredOnce[cardType]) {
            AnalyticsService.cardViewed({
                page: 'Dashboard',
                category_name: `Dashboard ${capitalizeFirstLetter(cardType)} Cards`,
                card: true,
                cardTitle,
            })
    
            this.setState({
                isAnalitycsTriggerredOnce: {
                    ...this.state.isAnalitycsTriggerredOnce,
                    [cardType]: true
                }
            });
        }
    }

    componentDidUpdate(prevProps: initialProps, prevState: initialState) {
        const prev = prevProps && prevProps.checkListDashboardState;
        const cur = this.props && this.props.checkListDashboardState;

        if (this.state.isAnalitycsTriggerredOnce.date !== prevState.isAnalitycsTriggerredOnce.date || this.state.isAnalitycsTriggerredOnce.address !== prevState.isAnalitycsTriggerredOnce.address) {
            if (this.state.isAnalitycsTriggerredOnce.date && this.state.isAnalitycsTriggerredOnce.address) this.formCompleted();
        }

        if(prev.podsChecklist && cur.podsChecklist && prev.podsChecklist?.length != cur.podsChecklist?.length){
            let array: any[] = [];
            let getCheckListData = localStorage.getItem('checklist_data');
            let orderedChecklist = getCheckListData ? JSON.parse(getCheckListData) :  this.props.checkListDashboardState.podsChecklist;

            orderedChecklist = orderedChecklist
                .sort((a: any, b: any) => (a.ordering > b.ordering ? 1 : -1))
                .map((item: any) => {
                    return item;
            });

            orderedChecklist.map((item: any) => {
                let obj = {
                    title: item.name,
                    subTitle: item.description_short,
                    icon: item.svg_image_file,
                    buttonText: item.do_it_now && item.do_it_now.heading,
                    url: item.do_it_now && item.do_it_now.new_url,
                };
                array.push(obj);
            });

            this.setState({checklistData : array})
        }
    }

    dateDiffrenceCalculator = (getMovingDate: any) => {
        let currentYear = new Date().getFullYear();
        let currentDate = new Date().getDate();
        let currentMonth =  new Date().getMonth();
        let date1: any = new Date(getMovingDate);
        let date2: any = new Date(currentYear, currentMonth, currentDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        if(diffDays === 0){
            this.setState({differenceInTime: "Today"})
        }else if(diffDays === 1){
            this.setState({differenceInTime: "Tomorrow"})
        }else{
            this.setState({differenceInTime: "In " + diffDays + " Days"})
        }
    }

    setSize = () => {
        this.setState({ size: window.screen.width });
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });

        let now:any = new Date(e);
        const dateNumber = now.getDate();
        const year = now.getFullYear();
        let monthnum: number = new Date(e).getMonth() + 1;
        let moving_date: any;
        
        if(!isNaN(e)) {
            moving_date = `${monthnum}/${dateNumber}/${year.toString().substring(2)}`;
            localStorage.setItem('pods_dashboard_moving_date', moving_date);
        }
        moving_date = year + "-" + monthnum + "-" + dateNumber  ;
        this.dateDiffrenceCalculator(moving_date);
        localStorage.setItem('pods_moving_date', moving_date);
    };

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                const addressString = results[0].formatted_address;
                let addressObj = {
                    street: `${streetNumber} ${route}`,
                    city,
                    zipcode: zip,
                    address: addressString,
                    state
                };
                localStorage.setItem('pods_destination_address', addressObj);
                this.props.commonAction.header({ apiPath: "pods", slug: this.appContext.domain, state, city, zipcode: zip })

                if (country === 'USA' || country === 'US') {
                    this.setState({
                        destination_address: addressString,
                        address_data: { ...addressObj, apt: ''}
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    serviceCardHandler = (url: any, title: string, buttonText: string) => {
        AnalyticsService.buttonClicked({
            category_name: `Checklist Card`,
            button_copy: buttonText,
            card: true,
            card_title: title,
            card_type: 'Checklist Card'
        });

        try {
            const urlObj = new URL(url)
            if(window.location.host == urlObj.host)
                window.location.href = url
            else
                window.open(url, '_blank');
        } catch(_err){
            this.props.history.push(url)
        }
    };

    handleInputAnalytics = (isDateInput: boolean) => {
        const trackProperties = {
            page: "Dashboard",
            hasMinimalFields: true,
            category_name: 'Moving Address And Date Change',
            form_field_name: isDateInput ? 'Your Moving Date' : 'Your Destination Address',
            form_pre_filled: true
        }

       this.state.isFormStarted ? AnalyticsService.formFieldInput(trackProperties) : AnalyticsService.formFieldStarted(trackProperties);
       this.setState({
        isFormStarted: true
       })
    }

    inputChangeHandle = (e: any, isDateInput = false) => {
        if (isDateInput) {
            this.dateHandler(e);
            !this.state.isAnalitycsTriggerredOnce.date && this.handleInputAnalytics(isDateInput);
            this.setState({ isAnalitycsTriggerredOnce : {...this.state.isAnalitycsTriggerredOnce, date: true} });
        } else {
            !this.state.isAnalitycsTriggerredOnce.address && this.handleInputAnalytics(isDateInput);
            this.setState({ destination_address: e, isAnalitycsTriggerredOnce : {...this.state.isAnalitycsTriggerredOnce, address: true} }); 
        }
    }


    formCompleted = () => {
        AnalyticsService.formCompleted({ 
            page: "Dashboard",
            category_name: 'Moving Address And Date Form',
            form_pre_filled: true,
            form_button: false
        });
    }

    render() {
        window.onresize = this.setSize;
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }

        return (
            <div
                className="dashboard-main-page-home-owner"
                style={{
                    marginTop: window.location.pathname === '/' ? '0px' : '0px',
                }}
            >
                <div className="dash_banner">
                    <div className="ctaTitleCover">
                        <h4>Get Started! Enter your address & moving date to explore services and deals for your home.</h4>
                    </div>
                    <div className="dashcontent">
                        <div className="dashcontrols">
                            <div className="dashcontrol_add">
                                <h4 className="controltitle">Destination Address</h4>
                                <div className="control_box">
                                    <img src={homeIcon} className="homeIcon" alt="" />
                                    <AutoComplete
                                        value={this.state.destination_address}
                                        onChange={e => this.inputChangeHandle(e)}
                                        onSelect={this.handleSelect}
                                        placeholder="Enter address"
                                        className="input"
                                    />
                                </div>
                            </div>
                            <div className="dashcontrol_date">
                                <h4 className="controltitle">Your Moving Date</h4>
                                <div style={{ position: 'relative' }} className="control_box">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disablePast
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date"
                                            inputVariant="filled"
                                            label="Enter moving date"
                                            className='dateInsurance1'
                                            value={this.state.date}
                                            onChange={(e: any) => this.inputChangeHandle(e, true)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                onFocus: e => {
                                                    this.handleClick(e);
                                                },
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <p className="remaindays">{this.state.differenceInTime ? this.state.differenceInTime : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ckecklist_section checklists-section">  {/* checklists-section class is used for fetching element, DO NOT REMOVE/CHANGE IT */}
                    <div className="custom_container">
                        <div className="blogsHeadingDiv">
                            <div className="blogsTitleDiv pods-dashboard section_title">
                                <div className="checklistIconDiv">
                                    <svg id="pending_actions_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <rect id="Rectangle_2184" data-name="Rectangle 2184" width="24" height="24" fill="none" />
                                        <path id="Path_41136" data-name="Path 41136" d="M17,12a5,5,0,1,0,5,5A5,5,0,0,0,17,12Zm1.65,7.35L16.5,17.2V14h1v2.79l1.85,1.85ZM18,3H14.82A2.988,2.988,0,0,0,9.18,3H6A2.006,2.006,0,0,0,4,5V20a2.006,2.006,0,0,0,2,2h6.11a6.743,6.743,0,0,1-1.42-2H6V5H8V8h8V5h2v5.08a7.03,7.03,0,0,1,2,.6V5A2.006,2.006,0,0,0,18,3ZM12,5a1,1,0,1,1,1-1A1,1,0,0,1,12,5Z" fill="#6b6c6f" />
                                    </svg>
                                </div>
                                <div className="checklistTitle">Your checklist</div>
                            </div>
                        </div>
                        <div className="checklist_wrapper">
                        {this.state.checklistData && this.state.checklistData.length > 0 && (
                                this.state.checklistData.map((item: any) => {
                                    return (
                                        <MovingNextCard
                                            className="dashboardChecklistCard dashboard-pods"
                                            title={item.title}
                                            subTitle={item.subTitle}
                                            icon={item.icon}
                                            buttonText={item.buttonText}
                                            buttonColor={
                                               slug && slug?.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#002E60'
                                            }
                                            buttonBackGroundColor={item.primary_color ? item.primary_color : 'rgb(206, 32, 47)'}
                                            boxShadow="none"
                                            buttonShadow='0 1px 1px #161D251A'
                                            color={slug?.primary_font_color ? slug.primary_font_color : '#FFF'}
                                            onClick={() => {
                                                this.serviceCardHandler(item.url, item.title, item.buttonText);
                                            }}
                                        />
                                    );
                                })
                        )}
                        </div>
                    </div>
                </div>

                <div className="dashboardBottom">
                    <div className="dashboardBlocks blogboxes dashboard-blogs-section"> {/* dashboard-blogs-section class is used for fetching element, DO NOT REMOVE/CHANGE IT */}
                        <div className="section_title">
                            <div className="blocksTitleDiv">
                                <img src={BlogIcon} className="blocksIconGrey" />
                                <div className="blocksTitle">{`Blogs`}</div>
                            </div>
                            {/* <div className="right_sectiontitle">
                                <a href="#" className="right_sectiontitlelink">View More</a>
                            </div> */}
                        </div>
                        <div className="BlogsCardDiv">
                            <div className="blogCard-div1">
                                <BlogCardRightImage
                                    icon={Blog2}
                                    title="A Guide to Home Owners Insurance and Why You Need It"
                                    subTitle="Now that you’ve purchased your home you may be asking yourself how to keep it protected? Sure..."
                                    viewLink="http://blog.moveeasy.com/index.php/a-guide-to-home-owners-insurance-and-why-you-need-it/"
                                />
                                {/* <BlogCardRightImage
                                        height={'100%'}
                                        icon={MaskGroup}
                                        class={'imageChanges'}
                                        imageHeight={'100%'}
                                        title="Your First Apartment Checklist -- Everything You Need To Buy"
                                        subTitle="While you dream up how to decorate your new home, we're here to bring you the ultimate first apartment checklist."
                                        viewLink="http://blog.moveeasy.com/index.php/tips-for-deep-cleaning-your-bedroom/"
                                    /> */}
                            </div>
                            <div className="blogCard-div2">
                                <BlogCardTopImage
                                    icon={Blog3}
                                    title="100 Things to Do When You Move Into a New Home"
                                    subTitle="Your move has finally been completed and now you’re in your new home, but now what..."
                                    viewLink="http://blog.moveeasy.com/index.php/100-things-to-do-when-you-move-into-a-new-home/"
                                />
                                {/* <BlogCardTopImage
                                        icon={Image}
                                        title="Home Essentials Checklist: What To Buy Before Moving In"
                                        subTitle="
                                        Stocking up on home essentials before your move will make your life"
                                        viewLink="https://www.bhg.com/home-improvement/advice/15-tips-for-a-successful-remodeling-project/?slide=slide_d5b427b7-325a-45ee-81c1-fd586952a27f#slide_d5b427b7-325a-45ee-81c1-fd586952a27f"
                                    /> */}
                            </div>
                            <div className="blogCard-div2">
                                <BlogCardTopImage
                                    icon={Blog1}
                                    title="How To Determine The Best Cable & Internet Provider"
                                    subTitle="Your cable & internet connection is not something you typically think about, until it stops..."
                                    viewLink="http://blog.moveeasy.com/index.php/how-to-determine-your-internet-provider/"
                                />
                                {/* <BlogCardTopImage
                                        icon={ultimate_Image}
                                        title="The Ultimate Home Seller Checklist"
                                        subTitle="Stocking up on home essentials before your move will make your life"
                                        viewLink="https://www.nytimes.com/guides/realestate/home-maintenance-checklist"
                                    /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        dashboardAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => {
    return {
    checkListDashboardState: state.checklistDashboard
}
};
DashboardPODS.contextType = UIContext;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPODS);
