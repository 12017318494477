import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={37.902} height={20} {...props}>
            <defs>
                <style>
                    {/* {".prefix__b{fill:#bebebe}"} */}
                    {`.carShippingSvgprefix__b{fill:${
                        window.location.pathname.includes('autoease') && localStorage.getItem('isDouglasElliman')
                            ? `#009cbd`
                            : window.location.pathname.includes('autoease') && slug && slug.primary_color
                            ? `${slug.primary_color}`
                            : '#6b6c6f'
                    }}.carShippingSvgprefix__c{fill:${
                        window.location.pathname.includes('autoease') && localStorage.getItem('isDouglasElliman')
                            ? `#009cbd`
                            : window.location.pathname.includes('autoease') && slug && slug.primary_color
                            ? `${slug.primary_color}`
                            : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="carShippingSvgprefix__b"
                data-name="Path 51335"
                d="M36.902 12.761a10.492 10.492 0 0 0-10.464-9.994h-1.134a1.628 1.628 0 0 0-1.627 1.627v8.246H1.627A1.628 1.628 0 0 0 0 14.268v.108a1.628 1.628 0 0 0 1.627 1.627h1.857a3.137 3.137 0 1 0 6.154.856 3.163 3.163 0 0 0-.127-.856h18.18a3.14 3.14 0 1 0 6.16.856 3.163 3.163 0 0 0-.127-.856h2.55a1.628 1.628 0 0 0 1.627-1.627v-.108a1.618 1.618 0 0 0-.999-1.507Zm-28.463 4.1a1.935 1.935 0 1 1-3.665-.856H8.24a2.023 2.023 0 0 1 .199.855Zm24.207 0a1.935 1.935 0 1 1-3.665-.856h3.466a1.937 1.937 0 0 1 .199.855Zm4.057-2.483a.419.419 0 0 1-.422.422H1.621a.419.419 0 0 1-.422-.422v-.108a.419.419 0 0 1 .422-.422H24.87V4.394a.419.419 0 0 1 .422-.422h1.145a9.282 9.282 0 0 1 9.271 9.275v.591l.585.018a.421.421 0 0 1 .41.422Z"
            />
            <path
                className="carShippingSvgprefix__c"
                data-name="Path 51336"
                d="M21.911 6.932A7.348 7.348 0 0 0 14.58 0h-2.265a7.454 7.454 0 0 0-5.527 3.436h-.542A3.9 3.9 0 0 0 2.37 6.92a1.24 1.24 0 0 0-.729 1.127v.078A1.237 1.237 0 0 0 2.88 9.361h1.139a2.1 2.1 0 0 0-.06.476 2.279 2.279 0 1 0 4.557 0 2.1 2.1 0 0 0-.06-.476h6.854a2.1 2.1 0 0 0-.06.476 2.279 2.279 0 1 0 4.557 0 2.1 2.1 0 0 0-.06-.476h1.621a1.237 1.237 0 0 0 1.236-1.236v-.078a1.2 1.2 0 0 0-.693-1.115ZM11.363 1.278v2.164H8.08a6.281 6.281 0 0 1 3.283-2.164Zm-5.124 9.783a1.225 1.225 0 0 1-1.224-1.224 1.292 1.292 0 0 1 .1-.476H7.36a1.18 1.18 0 0 1 .1.476 1.216 1.216 0 0 1-1.221 1.224Zm11.3 0a1.225 1.225 0 0 1-1.224-1.224 1.292 1.292 0 0 1 .1-.476h2.248a1.18 1.18 0 0 1 .1.476 1.216 1.216 0 0 1-1.228 1.224Zm4.02-2.936a.178.178 0 0 1-.181.181H2.87a.178.178 0 0 1-.181-.181v-.078a.178.178 0 0 1 .181-.181h.53v-.53a2.85 2.85 0 0 1 2.845-2.845h6.166V1.23a.181.181 0 0 1 .181-.181h1.988a6.3 6.3 0 0 1 6.287 6.287v.512l.512.012a.176.176 0 0 1 .175.181Z"
            />
        </svg>
    );
}

export default SvgComponent;
