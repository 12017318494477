import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Login from '../pages/login/Login';
import ResetPassword from '../pages/login/ResetPassword';
import SetPassword from '../pages/login/SetPassword';
import Home from '../pages/HomePage';
import NotFound from '../components/NotFound';
import BadRequest from '../components/BadRequest';
import ServerError from '../components/ServerError';
import Dashboard from '../pages/Dashboard/Dashboard';
// const Dashboard = React.lazy(() => import('../pages/Dashboard/Dashboard'));
import Mortgage from '../pages/Mortgage/Mortgage';
import InternetLanding from '../pages/InternetLanding/InternetlandingPage';
import InsuranceLandingPage from '../pages/HomeInsuranceLanding/HomeInsuranceLandingPage';
import InternetAndCable from '../pages/InternetAndCable/ICmain';
import ICSummary from '../pages/InternetAndCable/Summary';
import ICRecommendation from '../pages/InternetAndCable/RecommendedPage';
import ICSteps from '../pages/InternetAndCable/steps';
import ICAddress from '../pages/InternetAndCable/address';
import ICBuyNow from '../pages/InternetAndCable/Order';
import HomeInsurance from '../pages/HomeInsurance/HomeInsurance';
import HISteps from '../pages/HomeInsurance/steps';
import HIPlans from '../pages/HomeInsurance/PlanPage';
import HIOrder from '../pages/HomeInsurance/OrderPage';
import HISummary from '../pages/HomeInsurance/Summary';
import Schedule from '../components/HomeInsurance/ScheduleACall';
import InsuranceThankyou from '../components/HomeInsurance/ThankYouCard';
import MovingService from '../pages/Moving/Moving';
import MovingSteps from '../pages/Moving/MovingSteps';
import MovingRecommended from '../pages/Moving/MovingRecommended';
import AlmostDone from '../pages/Moving/AlmostDone';
import MovingReserveStorageFacility from '../pages/Moving/ReverseStorageFacility';
import MovingSingleStorageFacility from '../pages/Moving/StorageCardDetails';
import ReserveOrderPlacing from '../pages/Moving/OrderPlace';
import Utility from '../pages/Utility/Utility';
import UtilityOld from '../pages/Utility/utilityOld';
import UtilitySteps from '../pages/Utility/utilitySteps';
import UtilityPowerSaving from '../pages/Utility/utilityPowerSaving';
import UtilityPowerSavingPODS from '../pages/Utility/utilityPowerSavingPODS';
import UtilityConfirmation from '../pages/Utility/confirmationPage';
import UtilityConfirmationPODS from '../pages/Utility/confirmationPagePODS';
import UtilitySaving from '../pages/Utility/utilitySavings';
import MovingThankYou from '../pages/Moving/MovingThankYou';
import Title from '../pages/Title/title';
import DrivingLicenceMain from '../pages/DriverLicence/DrivingLicenceMain';
import VehicleRegistration from '../pages/DriverLicence/VehicleRegistration';
import ReserveStorageThankYou from '../pages/Moving/ReserveStorageThankYou';
import Checklist from '../pages/Checklist/checklistMain';
import ChecklistOverdue from '../pages/Checklist/checklistOverdue';
import ChecklistCompleted from '../pages/Checklist/checklistCompleted';
import signUpFirst from '../pages/login/signUp/signUpFirst';
import signUpSecond from '../pages/login/signUp/signUpSecond';
import TrustedPros from '../pages/TrustedPros/TrustedPros';
import TrustedProsSteps from '../pages/TrustedPros/TrustedProsSteps';
import TrustedProsAlmostDone from '../pages/TrustedPros/TrustedProsAlmostDone';
import TrustedProsOrderConfirm from '../pages/TrustedPros/TrustedProsOrderConfirm';
import TrustedProsScheduledServices from '../pages/TrustedPros/TrustedProsScheduledServices';
import UpdateAddress from '../pages/UpdateAddress/updateAddress';
import SecurityLanding from '../pages/HomeSecurityLanding/HomeSecurityLanding';
import TrustedProsRecommended from '../pages/TrustedPros/TrustedProsRecommended';
import TrustedProsRequestService from '../pages/TrustedPros/TrustedProsCarpenter';
import HomeSecurityMain from '../pages/HomeSecurity/HomeSecurityMain';
import HomeSecurityMainPODS from '../pages/HomeSecurityPODS/HomeSecurityMain';
import HomeSecuritySteps from '../pages/HomeSecurity/Steps';
import HomeSecurityRecommendation from '../pages/HomeSecurity/Recommendation';
import HomeSecurityRecommendationPODS from '../pages/HomeSecurityPODS/Recommendation';
import HomeSecuritySummary from '../pages/HomeSecurity/Summary';
// import Homewarranty from '../pages/HomeWarranty/HomeWarrantyMain';
import AutoShipping from '../pages/Moving/AutoShipping/AutoShippingMain';
import AutoShippingAlmostDone from '../pages/Moving/AutoShipping/AutoShippingAlmostDone';
import AutoShippingThankYou from '../pages/Moving/AutoShipping/AutoShippingThankYou';
import CustomPage from '../pages/CustomPage/CustomPage';
import PreMoveSignup from '../pages/PreMove/Signup/SignupMain';
import ServiceRequirement from '../pages/PreMove/ServiceRequirement';
import LaCarteServices from '../pages/PreMove/LaCarteServices';
import ScheduleACall from '../pages/PreMove/Signup/ScheduleACall';
import DIYRecommended from '../pages/Moving/DIY/DIYRecommended';
import StandAloneTrustedPros from '../pages/TrustedPros/StandAloneTrustedPros';
import StandAloneRecommendedPage from '../pages/TrustedPros/StandAlonePageRecommendation';
import StandAloneThankYou from '../pages/TrustedPros/StandAloneThankYou';
import './routes.scss';
import HomeWarantyMain from '../pages/HomeWaranty/HomeWarantyMain';
import HomeWarantySteps from '../pages/HomeWaranty/HomeWarantySteps';
import HomeWarantyStep4 from '../pages/HomeWaranty/HomeWarrantyStep4';
import HomeWarantyThanku from '../pages/HomeWaranty/HomeWarantyThanku';
import HomeWarantyHome from '../pages/HomeWaranty/HomeWarantyHome';
import SavingsMainPage from '../pages/SavingsPage/Saving';
import SavingsSummary from '../pages/SavingsPage/SavingsSummary';
import NextMove from '../pages/NextMove/NextMove';
import NextMoveFinal from '../pages/NextMove/finalPageNextMove';
import Documentry from '../pages/Documentry/documentry';
import Documents from '../pages/Documentry/Documents';
import DocumentPreview from '../pages/Documentry/documentPreview';
import DashboardHomeOwner from '../pages/DashboardHomeOwner/dashboardHomeOwner';
import AwesomeSavings from '../pages/SavingsPage/AwesomeSavings';
import ConfirmSavings from '../pages/SavingsPage/SavingsConfirmation';
import EnergyMainPage from '../pages/Energy/EnergyMainPage';
import EnergyMainPagePODS from '../pages/Energy/EnergyMainPagePODS';
import UtilityFormSubscription from '../pages/DashboardHomeOwner/HomeOwnerDashboardUtilityForm';
import ProtectionFormSubscription from '../pages/DashboardHomeOwner/HomeOwnerDashboardProtectionForm';
import DashboardPODS from '../pages/Dashboard/DashboardPODS';
import { UIContext } from '../UIContext';
import CheckIfQualify from '../components/atoms/HomeSecurity/CheckIfQualify';
import InternetAndCableWiget from '../pages/InternetAndCableWiget';
import UtilityStepsPODS from '../pages/Utility/utilityStepsPODS';
import DrivingLicenceGetStart from '../pages/DriverLicence/DrivingLicenceGetStart';
import DrivingLicenceMainPods from '../pages/DriverLicence/DrivingLicenceMainPods';
import VehicleRegistrationPods from '../pages/DriverLicence/VehicleRegistrationPods';
import PodsFaqPage from '../pages/PodsFaq/PodsFaqPage';
import utilitySavingsPODS from '../pages/Utility/utilitySavingsPODS';
import InternetAndCableGetStart from '../pages/InternetAndCableWiget/InternetAndCableGetStart';
import gtag from '../utils/gtag';

function getClassName(appType: string) {
    if (appType == 'WITHOUT_AUTH') {
        return 'mainRoutes pods-main-routes';
    }
    if (
        window.location.pathname === '/signUp/next' ||
        window.location.pathname === '/signUp' ||
        window.location.pathname === '/signUp/' ||
        window.location.pathname === '/login' ||
        window.location.pathname === '/premovesignup' ||
        window.location.pathname === '/servicerequirement' ||
        window.location.pathname === '/scheduleacall' ||
        window.location.pathname === '/laCarte' ||
        window.location.pathname === '/standalonepage' ||
        window.location.pathname === '/standalonepagerecommendations' ||
        window.location.pathname === '/standalonefinal' ||
        window.location.pathname.includes('/oboardprocess/ghost-login/') ||
        window.location.pathname.includes('/dashboard/nextmove') ||
        window.location.pathname.includes('/dashboard/savings-flow') ||
        window.location.pathname.includes('/dashboard/savings-flow/awesomeSaving') ||
        window.location.pathname.includes('/dashboard/savings-flow/confirmSaving') ||
        window.location.pathname.includes('/dashboard/nextMoveFinal') ||
        window.location.pathname === '/'
    ) {
        return 'mainRoutesLanding';
    } else if (
        window.location.pathname.includes('landing') ||
        window.location.pathname.includes('Landing') ||
        window.location.pathname.includes('/bad-request') ||
        window.location.pathname.includes('/server-error') ||
        window.location.pathname.includes('/dashboard/my-documents/preview') ||
        window.location.pathname === '/*'
    ) {
        return 'landingPageRoutes';
    } else if (
        window.location.pathname === '/login' ||
        window.location.pathname.includes('/resetpassword') ||
        window.location.pathname.includes('/setpassword') ||
        window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
        window.location.pathname === '/dashboard/subscription_details/home_protection'
    ) {
        return 'loginPage';
    } else {
        return 'mainRoutes';
    }
}

const Routes: React.FC = () => {
    const domain = window.location.hostname;
    let name: any = domain.split('.');
    const { appType: appType } = useContext(UIContext);
    localStorage.setItem('appType', appType);

    const location = useLocation()
    useEffect(() => {
        gtag('event', 'page_view', { page_path: location.pathname });
    },[location])
    
    return (
        <div
            className={getClassName(appType)}
        >
            <Switch>
                <Route path="/" exact component={appType == 'WITHOUT_AUTH' ? DashboardPODS : Dashboard} />
                {appType !== 'WITHOUT_AUTH' && [
                    <Route exact path="/signUp" component={signUpFirst} />,
                    <Route exact path="/signUp/" component={signUpFirst} />,
                    <Route
                        exact
                        path="/oboardprocess/ghost-login/:hashcode"
                        component={
                            localStorage.getItem('token') &&
                            localStorage.getItem('isHomeOwner') &&
                            localStorage.getItem('isHomeOwner') == 'true'
                                ? DashboardHomeOwner
                                : localStorage.getItem('token') && !localStorage.getItem('isHomeOwner')
                                ? Dashboard
                                : signUpFirst
                        }
                    />,
                    <Route exact path="/signUp/next" component={signUpSecond} />,
                    <Route exact path="/login" component={Login} />,
                    <Route exact path="/resetpassword" component={ResetPassword} />,
                    <Route exact path="/setpassword/:hashcode" component={SetPassword} />,
                    <Route exact path="/resetpassword/:id" component={ResetPassword} />,
                    <Route exact path="/schedule-a-call" component={Schedule} />,
                    <Route exact path="/home" component={Home} />
                ]}

                <Route path="/dashboard" exact component={appType == 'WITHOUT_AUTH' ? DashboardPODS : Dashboard} />

                {appType !== 'WITHOUT_AUTH' && [
                        <Route path="/dashboard/mortgage" exact component={Mortgage} />,
                        <Route path="/dashboard/title" exact component={Title} />,
                        <Route path="/dashboard/internet/summary" exact={true} component={ICSummary} />,
                        <Route path="/dashboard/internet/steps" exact={true} component={ICSteps} />,
                        <Route path="/dashboard/internet/location" exact={true} component={ICAddress} />,
                        <Route path="/dashboard/internet/order" exact={true} component={ICBuyNow} />,
                        <Route path="/dashboard/internet/recommendations" exact={true} component={ICRecommendation} />
                    ]
                }

                <Route path="/dashboard/internet" exact={true} component={appType == 'WITHOUT_AUTH' ? InternetAndCableWiget : InternetAndCable} />
                <Route path="/dashboard/internet/internetgetstart" exact={true} component={InternetAndCableGetStart} />
                {/* <Route path="/dashboard/internet/:hashcode" exact={true} component={InternetAndCable} /> */}
                {appType !== 'WITHOUT_AUTH' && [
                        <Route path="/landing/internet/summary" exact={true} component={ICSummary} />,
                        <Route path="/landing/internet/steps" exact={true} component={ICSteps} />,
                        <Route path="/landing/internet/location" exact={true} component={ICAddress} />,
                        <Route path="/landing/internet/order" exact={true} component={ICBuyNow} />,
                        <Route path="/landing/internet/recommendations" exact={true} component={ICRecommendation} />
                    ]
                }
                <Route path="/dashboard/home-insurance/steps" exact={true} component={HISteps} />
                <Route path="/dashboard/home-insurance/plans" exact={true} component={HIPlans} />
                <Route path="/dashboard/home-insurance/order" exact={true} component={HIOrder} />
                <Route path="/dashboard/home-insurance/summary" exact={true} component={HISummary} />
                <Route path="/dashboard/home-insurance" exact={true} component={HomeInsurance} />
                {appType !== 'WITHOUT_AUTH' && [
                        <Route path="/landing/insurance/steps" exact={true} component={HISteps} />,
                        <Route path="/landing/insurance/order" exact={true} component={HIOrder} />,
                        <Route path="/landing/insurance/summary" exact={true} component={HISummary} />,
                        <Route path="/landing/insurance/plans" exact={true} component={HIPlans} />,

                        <Route path="/dashboard/movers" exact={true} component={MovingService} />,
                        <Route path="/dashboard/movers/steps" exact={true} component={MovingSteps} />,
                        <Route path="/dashboard/movers/recommended" exact={true} component={MovingRecommended} />,
                        <Route path="/dashboard/movers/quote" exact={true} component={AlmostDone} />,
                        <Route exact path="/dashboard/movers/thankyou" component={MovingThankYou} />,

                        <Route path="/dashboard/storage" exact={true} component={MovingReserveStorageFacility} />,
                        <Route exact path="/dashboard/storage/id=:id" component={MovingSingleStorageFacility} />,
                        <Route path="/dashboard/movers/reserve_order_place" exact={true} component={ReserveOrderPlacing} />,
                        <Route
                            path="/dashboard/movers/reserve_storage_thankyou"
                            exact={true}
                            component={ReserveStorageThankYou}
                        />,
                        <Route path="/dashboard/movers/diyrecommended" exact={true} component={DIYRecommended} />,

                        <Route exact path="/dashboard/autoease" component={AutoShipping} />,
                        <Route exact path="/dashboard/autoease/order_placing" component={AutoShippingAlmostDone} />,
                        <Route exact path="/dashboard/autoease/thank_you" component={AutoShippingThankYou} />
                    ]
                }
                
                <Route path="/dashboard/utilities" exact={true} component={Utility} />
                <Route path="/dashboard/utilities/old" exact={true} component={UtilityOld} />
                <Route path="/dashboard/energy-savings/energySteps" exact component={appType == 'WITHOUT_AUTH' ? UtilityStepsPODS : UtilitySteps} />,

                { appType !== 'WITHOUT_AUTH' ? [
                   <Route path="/dashboard/utilities/utilitiesSavings" exact={true} component={UtilitySaving} />,
                   <Route path="/dashboard/utilities/utilitiesConfirmation" exact={true} component= {UtilityConfirmation} />,
                   <Route path="/dashboard/utilities/utilitiesPowerSaving" exact={true} component={UtilityPowerSaving}  />
                ] : [
                    <Route path="/dashboard/energy-savings/utilitiesSavings" exact={true} component={utilitySavingsPODS} />,
                    <Route path="/dashboard/energy-savings/utilitiesConfirmation" exact={true} component= {UtilityConfirmationPODS} />,
                    <Route path="/dashboard/energy-savings/utilitiesPowerSaving" exact={true} component={UtilityPowerSavingPODS}  />
                ]}
                
                <Route path="/dashboard/checklist" exact={true} component={Checklist} />
                <Route path="/dashboard/checklist/overdue" exact={true} component={ChecklistOverdue} />
                <Route path="/dashboard/checklist/completed" exact={true} component={ChecklistCompleted} />
                <Route
                    exact
                    path="/"
                    component={
                        localStorage.getItem('token') &&
                        !name[0].includes('internet') &&
                        !name[0].includes('insurance') &&
                        !name[0].includes('security') &&
                        !name[0].includes('internet-demo') &&
                        !name[0].includes('insurance-demo') &&
                        !name[0].includes('security-demo') &&
                        localStorage.getItem('isHomeOwner') &&
                        localStorage.getItem('isHomeOwner') == 'true'
                            ? DashboardHomeOwner
                            : localStorage.getItem('token') &&
                              !name[0].includes('internet') &&
                              !name[0].includes('insurance') &&
                              !name[0].includes('security') &&
                              !name[0].includes('internet-demo') &&
                              !name[0].includes('insurance-demo') &&
                              !name[0].includes('security-demo') &&
                              !localStorage.getItem('isHomeOwner')
                            ? Dashboard
                            : name[0].includes('internet') || name[0].includes('internet-demo')
                            ? InternetLanding
                            : name[0].includes('insurance') || name[0].includes('insurance-demo')
                            ? InsuranceLandingPage
                            : name[0].includes('security') || name[0].includes('security-demo')
                            ? SecurityLanding
                            : signUpFirst
                    }
                />
                <Route exact path="/internetLanding" component={InternetLanding} />
                <Route exact path="/insuranceLandingPage" component={InsuranceLandingPage} />
                <Route exact path="/securityLanding" component={SecurityLanding} />
                <Route path="/bad-request" component={BadRequest} />
                <Route path="/server-error" component={ServerError} />
                <Route exact path="/insurance-thankyou" component={InsuranceThankyou} />
                {/* <Route path="/dashboard/home-insurance/:hashcode" exact={true} component={HomeInsurance} /> */}

                <Route exact path="/dashboard/driverlicense/vehicleregistration" component={ appType == 'WITHOUT_AUTH' ? VehicleRegistrationPods : VehicleRegistration} />
                <Route exact path="/dashboard/driverlicense" component={appType == 'WITHOUT_AUTH' ? DrivingLicenceMainPods : DrivingLicenceMain} />
                <Route exact path="/dashboard/driverlicensegetstart" component={DrivingLicenceGetStart} />
                {/* <Route exact path="/dashboard/driverlicense/:hashcode" component={DrivingLicenceMain} /> */}

                <Route exact path="/dashboard/ourvendors" component={TrustedPros} />
                <Route exact path="/dashboard/ourvendors/recommended" component={TrustedProsRecommended} />
                <Route exact path="/dashboard/ourvendors/steps" component={TrustedProsSteps} />
                <Route exact path="/dashboard/ourvendors/order" component={TrustedProsAlmostDone} />
                <Route exact path="/dashboard/ourvendors/order_confirm" component={TrustedProsOrderConfirm} />
                <Route exact path="/dashboard/ourvendors/scheduled_services" component={TrustedProsScheduledServices} />
                <Route exact path="/dashboard/ourvendors/request_service" component={TrustedProsRequestService} />
                {/* <Route exact path="/dashboard/ourvendors/:hashcode" component={TrustedPros} /> */}
                <Route exact path="/standalonepage" component={StandAloneTrustedPros} />
                <Route exact path="/standalonepagerecommendations" component={StandAloneRecommendedPage} />
                <Route exact path="/standalonefinal" component={StandAloneThankYou} />

                {appType == 'WITHOUT_AUTH' ? [
                    <Route exact path="/dashboard/home-security" component={HomeSecurityMainPODS} />,
                    <Route exact path="/dashboard/home-security/steps" component={HomeSecuritySteps} />,
                    <Route exact path="/landing/home-security/steps" component={HomeSecuritySteps} />,
                    <Route exact path="/dashboard/home-security/recommendations" component={HomeSecurityRecommendationPODS} />,
                    <Route exact path="/dashboard/home-security/summary" component={HomeSecuritySummary} />,
                    <Route exact path="/landing/home-security/summary" component={HomeSecuritySummary} />,
                    <Route exact path="/dashboard/home-security/checkifqualify" component={CheckIfQualify} />,
                    <Route exact path="/dashboard/pods-faq" component={PodsFaqPage} />,
                ] : [
                    <Route exact path="/dashboard/home-security" component={HomeSecurityMain} />,
                    <Route exact path="/dashboard/home-security/steps" component={HomeSecuritySteps} />,
                    <Route exact path="/landing/home-security/steps" component={HomeSecuritySteps} />,
                    <Route exact path="/dashboard/home-security/recommendations" component={HomeSecurityRecommendation} />,
                    <Route exact path="/landing/home-security/recommendations" component={HomeSecurityRecommendation} />,
                    <Route exact path="/dashboard/home-security/summary" component={HomeSecuritySummary} />,
                    <Route exact path="/landing/home-security/summary" component={HomeSecuritySummary} />,
                    <Route exact path="/dashboard/home-security/checkifqualify" component={CheckIfQualify} />,
                    <Route exact path="/dashboard/home-warranty" component={HomeWarantyMain} />,
                    <Route exact path="/dashboard/home-warranty/steps" component={HomeWarantySteps} />,
                    <Route exact path="/dashboard/home-warranty/step4" component={HomeWarantyStep4} />,
                    <Route exact path="/dashboard/home-warranty/thanku" component={HomeWarantyThanku} />,
                    <Route exact path="/dashboard/home-warranty/home" component={HomeWarantyHome} />,
    
                ]}
                
                {/* <Route exact path="/dashboard/home-security/:hashcode" component={HomeSecurityMain} /> */}

                {/* <Route exact path="/dashboard/warranty" component={Homewarranty} /> */}
                <Route exact path="/dashboard/address" component={UpdateAddress} />
                {/* <Route exact path="/dashboard/address/:hashcode" component={UpdateAddress} /> */}


                <Route exact path="/dashboard/savings-flow" component={SavingsMainPage} />
                <Route exact path="/dashboard/savings-flow/awesomeSaving" component={AwesomeSavings} />
                <Route exact path="/dashboard/savings-flow/confirmSaving" component={ConfirmSavings} />

                <Route exact path="/dashboard/savings/summary" component={SavingsSummary} />
                <Route path="/dashboard/nextmove" component={NextMove} />
                <Route exact path="/dashboard/nextMoveFinal" component={NextMoveFinal} />
                <Route exact path="/dashboard/my-documents" component={Documentry} />
                <Route exact path="/dashboard/my-documents/documents" component={Documents} />
                <Route path="/dashboard/my-documents/preview" component={DocumentPreview} />

                {/* <Route path="/dashboard/energy-savings" component={EnergyMainPage} /> */}
                <Route path="/dashboard/energy-savings" component={appType == 'WITHOUT_AUTH' ? EnergyMainPagePODS : EnergyMainPage}  />
                <Route path="/dashboard/subscription_details/home_utilities" component={UtilityFormSubscription} />
                <Route path="/dashboard/subscription_details/home_protection" component={ProtectionFormSubscription} />

                <Route path="/dashboardhomeowner" component={DashboardHomeOwner} />
                { appType !== "WITHOUT_AUTH" && <Route key={window.location.pathname} path="/dashboard/*" component={CustomPage} />}
                <Route exact path="/premovesignup" component={PreMoveSignup} />
                <Route exact path="/servicerequirement" component={ServiceRequirement} />
                <Route exact path="/laCarte" component={LaCarteServices} />
                <Route exact path="/scheduleacall" component={ScheduleACall} />
                {
                    appType === "WITHOUT_AUTH"  ? <Redirect path='/*' to={"/dashboard"} ></Redirect> : <Route path='/*' component={NotFound}></Route>
                }                
                
            </Switch>
        </div>
    );
};

export default Routes;
