import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromLanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={53.604}
            height={71}
            viewBox="0 0 53.604 71"
        >
            <defs>
                <style>
                    {`.webStepprefix__a{fill:${
                      props.fromLanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-66 -5)">
                <path
                    className="webStepprefix__a"
                    d="M91.499 56.765h3.5a1.839 1.839 0 100-3.677h-3.5a1.839 1.839 0 000 3.677zm0-1.98h3.5a.141.141 0 110 .283h-3.5a.141.141 0 010-.283zM102.967 58.921a2.021 2.021 0 102.021-2.021 2.021 2.021 0 00-2.021 2.021zm2.021-.324a.324.324 0 11-.324.324.324.324 0 01.324-.324zM98.538 60.942a2.021 2.021 0 10-2.021-2.021 2.021 2.021 0 002.021 2.021zm0-2.345a.324.324 0 11-.324.324.324.324 0 01.324-.324zM101.768 60.124a2.021 2.021 0 102.021 2.021 2.021 2.021 0 00-2.021-2.021zm0 2.345a.324.324 0 11.324-.324.324.324 0 01-.324.324zM101.767 57.719a2.021 2.021 0 10-2.021-2.021 2.021 2.021 0 002.021 2.021zm0-2.345a.324.324 0 11-.324.324.324.324 0 01.324-.325zM82.124 55.547v1.362h-1.468a.839.839 0 00-.8.888v2.25a.78.78 0 00.8.819h1.468v1.43a.781.781 0 00.782.833h2.253a.782.782 0 00.783-.833v-1.43h1.466a.781.781 0 00.8-.819v-2.253a.871.871 0 00-.223-.62.81.81 0 00-.574-.269h-1.466v-1.358a.842.842 0 00-.784-.9h-2.253a.842.842 0 00-.784.9zm1.7 2.25v-1.454h.424v1.454a.874.874 0 00.914.809h1.349v.566h-1.349a.932.932 0 00-.914.878v1.385h-.424v-1.388a.933.933 0 00-.915-.878h-1.348v-.566h1.348a.876.876 0 00.915-.809z"
                />
                <path
                    className="webStepprefix__a"
                    d="M71.177 48.845h21.13v1.7h-8.326a8.361 8.361 0 00-8.187 9.772 8.45 8.45 0 00.549 1.872l-1.143 7.72a5.26 5.26 0 009.816 3.3l4.351-7.946a8.175 8.175 0 001.416-1.525 2.46 2.46 0 012.017-1.033h.158a2.457 2.457 0 012.022 1.025 8.214 8.214 0 001.42 1.535l4.3 8.005a5.28 5.28 0 004.622 2.73 5.212 5.212 0 001.531-.231 5.46 5.46 0 003.708-6.007v-.028l-1.128-7.618a8.357 8.357 0 00-7.665-11.573H94v-1.7h20.423a5.176 5.176 0 005.176-5.176v-33.49A5.177 5.177 0 00114.426 5H71.177A5.177 5.177 0 0066 10.178v33.49a5.176 5.176 0 005.177 5.177zm12.35 23.55a3.562 3.562 0 01-6.648-2.233l.853-5.762a8.373 8.373 0 001.458 1.312 8.268 8.268 0 004.787 1.522 8.382 8.382 0 002.6-.419zm22.827 1.751a3.573 3.573 0 01-4.167-1.688L99.152 66.8a8.277 8.277 0 002.6.424h.006a8.247 8.247 0 005.889-2.461c.136-.137.264-.278.389-.422l.834 5.629a3.784 3.784 0 01-2.521 4.178zm-4.591-21.906a6.648 6.648 0 010 13.295 6.6 6.6 0 01-5.392-2.778 4.161 4.161 0 00-3.4-1.748H92.8a4.157 4.157 0 00-3.409 1.752 6.634 6.634 0 11-5.412-10.521zM84.811 6.7h29.7a3.529 3.529 0 013.532 3.48v1.895H84.811zM67.7 10.177A3.529 3.529 0 0171.23 6.7h11.884v5.375H67.7zm0 3.592h50.351V43.7a3.5 3.5 0 01-3.532 3.451H71.23A3.5 3.5 0 0167.7 43.7z"
                />
                <path
                    className="webStepprefix__a"
                    d="M71.918 8.333a1.085 1.085 0 101.085 1.085 1.085 1.085 0 00-1.085-1.085z"
                />
                <circle
                    className="webStepprefix__a"
                    cx={1.085}
                    cy={1.085}
                    r={1.085}
                    transform="translate(74.375 8.333)"
                />
                <path
                    className="webStepprefix__a"
                    d="M79.002 8.333a1.085 1.085 0 101.085 1.085 1.085 1.085 0 00-1.085-1.085zM70.385 18.29a.849.849 0 00.849-.849v-.707a.85.85 0 10-1.7 0v.707a.849.849 0 00.849.849zM70.385 30.458a.849.849 0 00.849-.849v-9.476a.85.85 0 10-1.7 0v9.476a.849.849 0 00.849.849zM79.153 23.424v13.37a2.145 2.145 0 00-1.4 2.027 2.171 2.171 0 003.714 1.542l9.224 3.559a2.182 2.182 0 004.361 0l9.155-3.553a2.172 2.172 0 102.381-3.49V23.508a2.145 2.145 0 001.4-2.027 2.17 2.17 0 00-3.811-1.435l-9.133-2.952a2.184 2.184 0 00-4.348 0l-9.079 2.936a2.172 2.172 0 10-2.464 3.4zm12.871 18.4a2.3 2.3 0 00-.76.512l-4.614-1.771v-7.3l5.374 2.066zm0-8.314l-5.374-2.066v-6.45l4.014 1.548a2.212 2.212 0 001.36 1.972zm-3.716-9.716l4.955-1.725 4.319 1.666-3.156 1.227a2.181 2.181 0 00-3.109 0zm4.564 20.533a.489.489 0 11.489-.489.489.489 0 01-.489.489zm0-17.342a.489.489 0 11.489-.489.489.489 0 01-.489.489zm6.223 13.568l-4.615 1.782a2.293 2.293 0 00-.759-.512v-6.492l5.375-2.066zm0-9.11l-5.375 2.066v-5a2.212 2.212 0 001.36-1.973l4.014-1.551zm5.8 5.464a2.173 2.173 0 00-1.246 1.859l-2.856 1.129v-7.29l4.1-1.632zm.935-15.916a.489.489 0 11-.489.489.489.489 0 01.489-.489zm0 17.426a.489.489 0 11-.489.489.489.489 0 01.489-.489zm-1.6-15.447a1.884 1.884 0 00.662.509v5.673l-4.1 1.632v-6.456zm-.976-1.441l-3.324 1.292-4.19-1.616 3.136-1.092zM92.87 16.811a.489.489 0 11-.489.489.489.489 0 01.491-.488zm-1.668 1.9a2.18 2.18 0 003.336 0l1.662.537-2.909 1.013-3.139-1.21zm-3.615 1.169l3.225 1.244-4.947 1.722-3.385-1.314zm-6.737 3.514a5.374 5.374 0 00.6-.444l3.5 1.379v6.454l-4.1-1.632zm0 7.577l4.1 1.632v7.306L82.1 38.79a2.229 2.229 0 00-1.251-1.968zm-.935-10.065a.489.489 0 11-.489.489.489.489 0 01.489-.486zm0 17.426a.489.489 0 11-.489.489.489.489 0 01.489-.487z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
