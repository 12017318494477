import React, { Component } from 'react';
import './scheduledServicesCard.scss';
import elecT from '../../Assets/images/elecT.svg';
import history from '../../routes/History'

interface cardProps {
serviceName?:any
vendorlist? :any
// history ? : any
}

interface initialState{
    moreClicked:boolean;
}

let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
export class ScheduleServiceMainCard extends Component<cardProps,initialState> {

    // state:initialState={
    //     moreClicked:false
    // }

    cardHandler=()=>{
       history.push({
            pathname:'/dashboard/ourvendors/scheduled_services',
            state:{
                vendorlist:this.props.vendorlist
            }
        })
    }
    render() {
        return ( 
            <div className="main-schedule-card">
            {this.props.vendorlist && this.props.vendorlist.length ? (
                <div className="scheduledServicesCard" onClick={()=>{
                    this.cardHandler()
                }}>
                    <div className="scheduledServicesCardInner">
                        <img src={elecT} />
                        <div className="textInnerSchedule">{this.props.serviceName}</div>
                    </div>
                    <div className="textInnerSchedule1">Vendors Selected</div>
                   {
                       this.props.vendorlist && this.props.vendorlist.length ?
                           <div className="scheduledServicesCardInner2">
                         <div className="box-box">
                         <div className="boxDivSchedule" style={{ backgroundColor: '#FFF0C8' }}>
                         {this.props.vendorlist[0].vendor.name.charAt(0)+this.props.vendorlist[0].vendor.name.charAt(this.props.vendorlist[0].vendor.name.indexOf(' ')+1)}
                         </div>
                           
                           </div>
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div className="textInnerSchedule1">{this.props.vendorlist[0].vendor.name}</div>
                           <div className="dateAndTimeDiv">Date & Time</div>
                           <div className="dateAndTimeContentDiv">{this.props.vendorlist[0].date_str}, {this.props.vendorlist[0].time_str}</div>
                        </div>
                       </div>
    
                       : null
                    
                   }
    
                  {
                    this.props.vendorlist.length-1  ? <div className="more" onClick={()=>{
                    this.cardHandler()
                    }} style={{color: isDouglasElliman ? "#009cbd" : "#006dff"}}>+{this.props.vendorlist.length-1} More</div> : null
                  }
                   
                </div>
            ) : null }
            </div>
        );
    }
}

export default ScheduleServiceMainCard;
