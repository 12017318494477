import React, { Component } from 'react';
import PlanCard from '../../components/HomeInsurance/insurancePlanCard';
import CallQuotesCard from '../../components/HomeInsurance/callToQuotesCard';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import CoverageDetails from './CoverageDetails';
import PropertyDetailsCard from '../../components/HomeInsurance/PropertyDetailsCard';
import OwnerDetailsCard from '../../components/HomeInsurance/HomeOwnerDetailsCard';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import * as SignupAction from '../../store/login/signup/actions';
import './HomeInsurance.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Footer from './Footer';
import GirlSvg from '../../Assets/images/InsuranceImages/MaskGroup11';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
// import { FormControlLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import "./HomeInsurance.scss"
import {
    CallQouteCardSeleton,
    PlanCardSeleton,
} from '../../components/skeletonLoaders/InsuranceSkeleton/insuranceSeleton';
import { scheduleSegment } from '../../utils/utlis';
import { UIContext } from '../../UIContext';
import { trustedFormUrl } from '../../config';
import AnalyticsService from '../../utils/analytics-service';

var intervalVar: any;
type initialProps = {
    totalSteps: number;
    activeStep: number;
    commonAction?: any;
    commonState?: any;
    insuranceAction?: any;
    insuranceState?: any;
    history?: any;
    signupAction?: any;
    signupState?: any;
};

type initialState = {
    sort: any;
    planData: any;
    quoteData: any;
    showCoverage: boolean;
    showModal?: boolean;
    property: any;
    insurance_detail: any;
    count: number;
    viewCoverageData: any;
    AnchorEl?: any;
    calendlyOpen: any;
    date: any;
    time: any;
    firstName: any;
    lastName: any;
    email: any;
    phone: any;
    zip: any;
    checked: any;
    submitClick: any;
    isDouglasElliman: boolean;
    hashcode: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    concierge_name: string;
    appointment_type: any;
    moveId: any;
    callMoveApi: boolean;
    currentItem: any;
    loading: boolean;
    isAnalitycsTriggerredOnce: any;
    phone_number: string;
    isFormStarted: boolean;
};

// let plansList: any = [];

function getAge(dateString: any) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export class PlanPage extends Component<initialProps, initialState> {
    appContext = this.context
    state: initialState = {
        loading: false,
        sort: { value: 'LH', label: 'Low to High' },
        currentItem: null,
        property: {
            address: '',
            sqFootage: 0,
            yearBuilt: 0,
            noOfStories: '',
            roofType: '',
            heater: '',
            pool: '',
        },
        insurance_detail: {
            name: '',
            age: 0,
            gender: '',
            maritalStatus: '',
        },
        viewCoverageData: null,
        planData: [],
        quoteData: [],
        showCoverage: false,
        count: 0,
        AnchorEl: null,
        calendlyOpen: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zip: '',
        checked: false,
        submitClick: false,
        isDouglasElliman: false,
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        concierge_name: '',
        appointment_type: '',
        moveId: '',
        callMoveApi: true,
        isAnalitycsTriggerredOnce: {
            date: false,
            time: false,
            firstName: false,
            lastName: false
        },
        phone_number: "(844) 664-0597",
        isFormStarted: false
    };

    trustedUrl: string | null | undefined = null

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        this.setState({
            showModal: true,
        });
        setTimeout(() => {
            this.setState({ showModal: false });
        }, 5000);
        
        const token = this.props.history.location.state?.token
        if (token) {
            const trusted_url = this.props.history.location.state?.trustedUrl
            this.props.insuranceAction.insuranceQuote({ token, data: { trusted_url } });
            this.props.insuranceAction.insuranceQuoteList({ token });
            this.props.commonAction.moveGet({ token });
            intervalVar = setInterval(() => {
                if (this.state.count < 9) {
                    this.props.insuranceAction.insuranceQuoteList({ token });
                    this.setState({
                        count: this.state.count + 1,
                    });
                }
            }, 10000);
        }

        if(!document.getElementById("trustedFormScriptTag")){
            const field = 'xxCertUrl';
            const provideReferrer: string = 'false';
            const invertFieldSensitivity = false;
            const tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.id = "trustedFormScriptTag"
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                `://${trustedFormUrl}/trustedform.js?provide_referrer=` +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date().getTime() +
                Math.random() +
                '&invert_field_sensitivity=' +
                invertFieldSensitivity;
            const s = document.getElementsByTagName('script')[0];

            s && s.parentNode && s.parentNode.insertBefore(tf, s);
        }

        AnalyticsService.bannerViewed({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            bannerTitle: 'You could qualify for additional discounts'
        })
    }

    componentWillUnmount() {
        document.getElementById("trustedFormScriptTag")?.remove()
        clearInterval(intervalVar);
    }

    componentDidUpdate(prevProps: initialProps) {

        const prev = prevProps && prevProps.insuranceState;
        const cur = this.props && this.props.insuranceState;
        if (prev.quoteList !== cur.quoteList && cur.quoteList.length > 0) {
            let plansList: any = cur && cur.quoteList;
            let planData: any = [],
                quoteData: any = [];
            plansList.map((el: any) => {
                if (el && el.data && el.data.status === 'error') {
                    quoteData.push({
                        cardImage: el && el.image_url,
                        mobileNumber: el && el.phone_number ==="844-232-2700" ? this.state.phone_number : el.phone_number,
                        allData: el,
                    });
                } else if (el && el.data && el.data.status === 'succeeded') {
                    planData.push({
                        cardImage: el && el.image_url,
                        currency: '$',
                        amount: (parseFloat(el && el.data && el.data.quote && el.data.quote.term_premium) / 12)
                            .toFixed(2)
                            .toString(),
                        yearlyAmount: el && el.data && el.data.quote && el.data.quote.term_premium,
                        planRate: 'month',
                        planValidFor:
                            el && el.data && el.data.quote && el.data.quote.term
                                ? `for ${el.data.quote.term} months`
                                : '',
                        allData: el,
                    });
                }
            });

            if (this.state.sort.value === 'LH') {
                planData = this.sortByKey(planData, 'yearlyAmount');
            } else {
                planData = this.sortByKey(planData, 'yearlyAmount').reverse();
            }

            this.setState({ planData, quoteData });
        }
        
        if (
            prev.appointment !== cur.appointment &&
            cur.appointment &&
            cur.appointment[0] &&
            cur.appointment.status === 200
        ) {

            if(this.appContext?.appType == 'WITHOUT_AUTH'){
                this.setState({loading: false}, () => {
                    this.props.history.push({
                        pathname: '/dashboard/home-insurance/order',
                        state: { data: this.state.currentItem, type: 'success' },
                    });
                })
            }else{
                this.props.history.push({
                    pathname: '/dashboard/home-insurance/summary'
                });
            }
        }
    }

    sortByKey = (array: any, key: any) => {
        return array.sort(function (a: any, b: any) {
            var x = parseFloat(a[key]);
            var y = parseFloat(b[key]);

            return x < y ? -1 : x > y ? 1 : 0;
        });
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].property
        ) {
            currentState.firstName =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.first_name;
            // currentState.lastName =
            //     nextProps.commonState.move[0] &&
            //     nextProps.commonState.move[0].user_ref &&
            //     nextProps.commonState.move[0].user_ref.last_name;
            currentState.email =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.email;
            currentState.phone =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.phone;
            currentState.zip =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].destination &&
                nextProps.commonState.move[0].destination.zip_code;
            currentState.hashcode = nextProps.commonState.move[0] && nextProps.commonState.move[0].hash_code;
            let property = {
                address: nextProps.commonState.move[0].property.address,
                sqFootage: nextProps.commonState.move[0].property.total_area_sq_ft,
                yearBuilt: nextProps.commonState.move[0].property.year_built,
                noOfStories: `${nextProps.commonState.move[0].property.stories} floors`,
                roofType: nextProps.commonState.move[0].property.roof_material_type
                    ? nextProps.commonState.move[0].property.roof_material_type
                    : 'null',
                heater: nextProps.commonState.move[0].property.heating_type,
                pool: nextProps.commonState.move[0].property.pool_type,
            };

            let insurance_detail = {
                name: `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`,
                age: getAge(nextProps.commonState.move[0].insurance_detail.date_of_birth),
                gender: nextProps.commonState.move[0].insurance_detail.gender,
                maritalStatus: nextProps.commonState.move[0].insurance_detail.marital_status,
            };

            currentState.property = property;
            currentState.insurance_detail = insurance_detail;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            nextProps.commonState.appointmentDetailsArrived = false;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }
        return currentState;
    }
    handleLTH = () => {
        let planData;
        if (this.state.planData) {
            planData = this.sortByKey(this.state.planData, 'yearlyAmount');
        }
        this.setState({
            sort: { value: 'LH', label: 'Low to High' },
            planData,
        });

        this.handleClose();

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'Low to High'
        });
    };

    handleHTL = () => {
        let planData;
        if (this.state.planData) {
            planData = this.sortByKey(this.state.planData, 'yearlyAmount').reverse();
        }
        this.setState({
            sort: { value: 'HL', label: 'High to Low' },
            planData,
        });

        this.handleClose();

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'High to Low'
        });
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };
    // sortByHandler = (e: any) => {
    //     let planData;
    //     if (e.target.value === 'LH' && this.state.planData) {
    //         planData = this.sortByKey(this.state.planData, 'yearlyAmount');
    //     } else {
    //         planData = this.sortByKey(this.state.planData, 'yearlyAmount').reverse();
    //     }
    //     this.setState({
    //         sort: { value: e.target.value, label: e.target.label },
    //         planData,
    //     });
    // };
    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        if (this.appContext?.appType!="WITHOUT_AUTH") {
            scheduleSegment(
                'Schedule Call Button Clicked',
                'Insurance',
                brokerage,
                brokerage_key,
                agent_key,
                agent_name,
                'Home Insurance',
                'Schedule A Call',
                false,
                ``,
                true,
                'You could qualify for additional discounts',
                move_id,
            );

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    
            analytics.track(
                'Schedule Call Started',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    concierge_name:
                        this.props.commonState.move[0] &&
                        this.props.commonState.move[0].assigned_concierge &&
                        this.props.commonState.move[0].assigned_concierge.full_name,
    
                    appointment_type: 'FULL_MOVE',
                },
                { user_id: move_id },
            );

        } else {
            AnalyticsService.scheduleCallStarted({
                page: 'Home Insurance',
                concierge_name: 
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].assigned_concierge &&
                this.props.commonState.move[0].assigned_concierge.full_name,
                appointment_type: 'FULL_MOVE'
            });
        }
    };

    handleBtnClick = (analyticsData: any) => {
        if (this.appContext.appType !== 'WITHOUT_AUTH') return;

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            ...analyticsData
        });
    }

    selectPlan = (item: any) => {
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding'
        ) {
            this.props.history.push({
                pathname: '/landing/insurance/order',
                state: { data: item, type: 'success', fromLanding: 'fromLanding' },
            });

            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Card Selection Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );

            analytics.track(
                'Product Details Viewed',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );
        } else {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Card Selection Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );

            analytics.track(
                'Product Details Viewed',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/dashboard/home-insurance/order',
                state: { data: item, type: 'success' },
            });
        }
    };

    viewCoverage = (e: any, data: any) => {
        e.preventDefault();
        this.setState({ showCoverage: true, viewCoverageData: data });
    };

    closeModal = () => {
        this.setState({ calendlyOpen: false, showCoverage: false, viewCoverageData: null });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name && this.appContext?.appType != "WITHOUT_AUTH") {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Cancel',
                    category_name: 'Home Insurance',
                    card: false,
                    card_title: '',
                    popup: true,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        } else {
            AnalyticsService.buttonClicked({
                page: 'Home Insurance',
                category_name: 'Home Insurance Schedule Call',
                button_copy: 'Cancel',
                popup: true,
                form_button: true,
                popup_title: 'Schedule a Call Back'
            });
        }
    };

    onCall = (item: any) => {
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding'
        ) {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    button_copy: 'Call to Quote',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                    banner: false,
                    banner_title: '',
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/landing/insurance/order',
                state: { data: item, type: 'error', fromLanding: 'fromLanding' },
            });
        } else {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                    banner: false,
                    banner_title: '',
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/dashboard/home-insurance/order',
                state: { data: item, type: 'error' },
            });
        }
    };

    about = () => { };
    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });

        this.inputAnalyticsTracking('Select date', 'date');
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });

       this.inputAnalyticsTracking('Select time', 'time');
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });

       this.inputAnalyticsTracking('First Name', 'firstName');
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });

        this.inputAnalyticsTracking('Last Name', 'lastName');
    };

    inputAnalyticsTracking = (fieldLabel: string, fieldName: string) => {
        if (!this.state.isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
                page: 'Home Insurance',
                category_name: 'Home Insurance Schedule Call',
                button_copy: 'Submit',
                form_button: true,
                form_pre_filled: true,
                form_field_name: fieldLabel
            }
    
            this.state.isFormStarted ? AnalyticsService.formFieldInput(trackProperties) : AnalyticsService.formFieldStarted(trackProperties);
            this.setState({ 
                isFormStarted: true, 
                isAnalitycsTriggerredOnce: {
                    ...this.state.isAnalitycsTriggerredOnce,
                    [fieldName]: true
                }
             });
        } else {
            return
        }
    }

    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    submitHandler = () => {
        this.setState({ submitClick: true });
        const { firstName, lastName, email, zip, phone, date, time, checked } = this.state;
        
        if (firstName && email && zip && phone && date && time && checked) {
            this.setState({loading: true})
            this.props.insuranceAction.insuranceAppointment({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                zip_code: zip,
                appointment_datetime: `${new Date(date).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`,
                trusted_url: this.trustedUrl
            });

            AnalyticsService.formCompleted({
                page: 'Home Insurance',
                category_name: 'Home Insurance Schedule Call',
                button_copy: 'Submit',
                form_name: 'Schedule a Call Back'
            })
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name && this.appContext?.appType != "WITHOUT_AUTH") {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Submit',
                    category_name: 'Home Insurance',
                    card: false,
                    card_title: '',
                    popup: true,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        } else {
            AnalyticsService.buttonClicked({
                page: 'Home Insurance',
                category_name: 'Home Insurance Schedule Call',
                button_copy: 'Submit',
                popup: true,
                form_button: true,
                popup_title: 'Schedule a Call Back'
            });
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        const { isDouglasElliman } = this.state;
        return (
            <div className='HomeInsurance'>
                {this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.fromLanding &&
                    this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="insuranceSteps">
                    <div
                    //  style={{ minHeight: '120vh', overflow: 'auto' }}
                    >
                        <div className="main-head-insurance">
                            <h2 className="heading-insurance-regular" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Insurance</h2>
                            <div className="path-insurance">
                                <p
                                    className="Para-insurance"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.state &&
                                            this.props.history.location.state.fromLanding &&
                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? this.props.history.push('/')
                                            : localStorage.getItem('isHomeOwner') &&
                                                localStorage.getItem('isHomeOwner') == 'true'
                                                ? this.props.history.push('/dashboardhomeOwner')
                                                : this.props.history.push('/dashboard');
                                    }}
                                >
                                    {this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.state &&
                                        this.props.history.location.state.fromLanding &&
                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? 'HomePage'
                                        : 'Dashboard'}{' '}
                                    {'>'}
                                </p>{' '}
                                <p style={{ marginLeft: '5px' }}>Insurance</p>
                            </div>
                        </div>
                        <div className="steps1Insurance">
                            <div className="PlanMainPage">
                                {
                                    // this.state.showModal && (
                                    //     <Modal
                                    //         isShowModal={this.state.showModal}
                                    //         onCloseModal={() => this.setState({ showModal: false })}
                                    //         showClose={false}
                                    //         style={{
                                    //             position: 'absolute',
                                    //             width: '100vw',
                                    //             height: '100vh',
                                    //             backgroundColor: '#ffffff',
                                    //             left: '0px',
                                    //             top: '0px',
                                    //         }}
                                    //     >
                                    //         <div
                                    //             style={{
                                    //                 display: 'flex',
                                    //                 flexDirection: 'column',
                                    //                 justifyContent: 'center',
                                    //                 alignItems: 'center',
                                    //                 height: '100%',
                                    //             }}
                                    //         >
                                    //             <div className="modalHead">Searching for the best rates for you</div>
                                    //             <div className="main-divv">
                                    //                 <div className="modalData">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal">
                                    //                         Fetching best quotes from 40+ leading insurance
                                    //                     </div>
                                    //                 </div>
                                    //                 <div className="modalData1">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal1">
                                    //                         Quotes available for 14061 Old Wye Mills Road, Wye Mills
                                    //                     </div>
                                    //                 </div>
                                    //                 <div className="modalData2">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal2">Personalizing your quote right now</div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </Modal>
                                    // )
                                }
                                <div className="cardsDiv1">
                                <div className="insurancetitle" style={{marginTop: '10px'}}>Coverage is quoted by InsuraMatch, a licensed insurance agency.</div>
                                    <div className="headerDiv" style={{marginTop: '10px'}}>
                                        <div className="insurancetitle">
                                            Here are some estimated quotes
                                            <div className="titleCount">{` (${this.state.planData.length})`}</div>
                                        </div>
                                        <div className="sortByDiv">
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    className="avg-customer-review"
                                                    onClick={this.handleMenu}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Sort by price: <span>{this.state.sort && this.state.sort.label}</span>
                                                </div>
                                                <ExpandMoreIcon />
                                            </div>
                                            <Menu
                                                className="InsuranceDrop"
                                                id="simple-menu"
                                                anchorEl={this.state.AnchorEl}
                                                keepMounted
                                                open={Boolean(this.state.AnchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                                <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                            </Menu>

                                            {/* <div className="sortByText">Sort by price:</div>
                                    <FormControl>
                                        <Select
                                            native
                                            value={this.state.sort.label}
                                            onChange={(e: any) => this.sortByHandler(e)}
                                        >
                                            <option label="Low to High" value="LH">
                                                Low to High
                                            </option>
                                            <option label="High to Low" value="HL">
                                                High to Low
                                            </option>
                                        </Select>
                                    </FormControl> */}
                                        </div>
                                    </div>
                                    <div
                                        className="discountDiv"
                                        style={{
                                            background: '#273E59',
                                            padding: isDouglasElliman ? '10px 30px' : '0px 30px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <div className="discountContent">
                                            <div className="discountTitle">
                                                You could qualify for additional discounts
                                            </div>
                                            <div className="discountText">
                                                Click to schedule a call and speak with a licensed insurance specialist.
                                            </div>
                                            <div>
                                                <Button
                                                    className="btnOrder9"
                                                    backgroundColor="#fff"
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : `0 0 3px 0 ${slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : window.location.pathname.includes('landing')
                                                                    ? '#30AB86'
                                                                    : '#273E59'
                                                            }`
                                                    }
                                                    border={
                                                        isDouglasElliman
                                                            ? '1px solid #100B28'
                                                            : `1px solid ${slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : '#273E59'
                                                            }`
                                                    }
                                                    width="140px"
                                                    height="40px"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : window.location.pathname.includes('landing')
                                                                    ? '#30AB86'
                                                                    : '#273E59'
                                                    }
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="14px"
                                                    margin="0 5px 0 0"
                                                    onClick={() => {
                                                        this.handleBtnClick({
                                                            button_copy: 'Schedule A Call',
                                                            banner: true,
                                                            banner_title: 'You could qualify for additional discounts'
                                                        })

                                                        this.calendlyHandle()
                                                    }}
                                                >
                                                    Schedule a Call
                                                </Button>
                                            </div>
                                        </div>
                                        {!isDouglasElliman && (
                                            <div className="discountImgDiv">
                                                {/* <img src={GirlSvg} alt="" /> */}
                                                <GirlSvg />
                                            </div>
                                        )}
                                    </div>

                                    <div className="only-mobile-edit-button">
                                        <div
                                            onClick={() =>
                                                window.location.pathname.includes('landing')
                                                    ? this.props.history.push({
                                                        pathname: '/landing/insurance/steps',
                                                        state: {
                                                            edit: true,
                                                            fromLanding: 'fromLanding',
                                                        },
                                                    })
                                                    : this.props.history.push({
                                                        pathname: '/dashboard/home-insurance/steps',
                                                        state: { edit: true },
                                                    })
                                            }
                                            style={{
                                                color: isDouglasElliman ? '#009CBD' : '#0066F5',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                margin: '35px 0px 20px 0px',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <EditIcon style={{ color: isDouglasElliman ? '#009CBD' : '#0066F5' }} />
                                            <span>Edit Property Details</span>
                                        </div>
                                    </div>

                                    {this.state.planData.length > 0 ? (
                                        this.state.planData.map((item: any) => {
                                            return (
                                                <div className="planCardDiv">
                                                    <PlanCard
                                                        width="100%"
                                                        className="plancardInsurancePage"
                                                        // height="300px"
                                                        cardImage={item.cardImage}
                                                        currency={item.currency}
                                                        amount={item.amount}
                                                        yearlyAmount={item.yearlyAmount}
                                                        planRate={item.planRate}
                                                        planValidFor={item.planValidFor}
                                                        selectPlan={() => { 
                                                            if(this.appContext.appType == 'WITHOUT_AUTH'){
                                                                this.handleBtnClick({
                                                                    button_copy: 'Confirm Plan',
                                                                    card: true,
                                                                    card_title: item.allData?.carrier_name,
                                                                    card_type: 'Insurance Plan Card',
                                                                })

                                                                this.setState({currentItem: item.allData}, () => {
                                                                    this.calendlyHandle()
                                                                })
                                                            } else
                                                                this.selectPlan(item.allData)
                                                        }}
                                                        viewCoverage={(e: any) => this.viewCoverage(e, item.allData)}
                                                        isDouglasElliman={isDouglasElliman}
                                                        page="Home Insurance"
                                                        category="Home Insurance Plan Card"
                                                        brandName={item.allData?.carrier_name || ''}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <PlanCardSeleton />
                                        // <div
                                        //     style={{
                                        //         marginTop: '20px',
                                        //         display: 'flex',
                                        //         justifyContent: 'center',
                                        //         alignItems: 'center',
                                        //     }}
                                        // >
                                        //     <CircularProgress />
                                        // </div>
                                    )}

                                    <div className="quoteDiv">
                                        <div className="headerDiv">
                                            <div className="insurancetitle">
                                                These quotes are not available online, but may be available when you
                                                call
                                                <div className="titleCount">{` (${this.state.quoteData.length})`}</div>
                                            </div>
                                            {/* <div className="sortByDiv">
                                                <div className="sortByText">
                                                    Why can’t I see the insurance quotes?
                                                    <InfoIcon style={{ color: '#6B6C6F', marginLeft: '5px' }} /> 
                                                </div>
                                            </div> */}
                                        </div>

                                        {this.state.quoteData.length > 0 ? (
                                            this.state.quoteData.map((item: any) => {
                                               
                                                return (
                                                    <div className="planCardDiv">
                                                        <CallQuotesCard
                                                            width="100%"
                                                            // height="300px"
                                                            className="callquoteInsurance"
                                                            cardImage={item.cardImage}
                                                            mobileNumber={item.mobileNumber}
                                                            onCall={() => {
                                                                this.handleBtnClick({
                                                                    button_copy: 'Call to Quote',
                                                                    card: true,
                                                                    card_title: item.allData?.carrier_name,
                                                                    card_type: 'Insurance Quote Card',
                                                                })

                                                                this.setState({currentItem:null}, () => {
                                                                    this.calendlyHandle()
                                                                })
                                                            }}
                                                            about={this.about}
                                                            isDouglasElliman={isDouglasElliman}
                                                            page="Home Insurance"
                                                            brandName={item.allData?.carrier_name || ''}
                                                            category='Home Insurance Page Plan Card'
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <CallQouteCardSeleton />
                                            // <div
                                            //     style={{
                                            //         marginTop: '20px',
                                            //         display: 'flex',
                                            //         justifyContent: 'center',
                                            //         alignItems: 'center',
                                            //     }}
                                            // >
                                            //     <CircularProgress />
                                            // </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mobileDetailsDiv">
                                    <div style={{ margin: '20px 0px' }}>
                                        <OwnerDetailsCard
                                            name={this.state.insurance_detail.name}
                                            age={this.state.insurance_detail.age}
                                            gender={this.state.insurance_detail.gender}
                                            maritalStatus={this.state.insurance_detail.maritalStatus}
                                        />
                                    </div>
                                </div>
                                <div className="detailsDiv">
                                    <div className="propertyDetails">
                                        <PropertyDetailsCard
                                            address={this.state.property.address}
                                            sqFootage={this.state.property.sqFootage}
                                            yearBuilt={this.state.property.yearBuilt}
                                            noOfStories={this.state.property.noOfStories}
                                            roofType={this.state.property.roofType}
                                            heater={this.state.property.heater}
                                            pool={this.state.property.pool}
                                            history={this.props.history}
                                            isDouglasElliman={isDouglasElliman}
                                            slug={slug}
                                        />
                                    </div>
                                    <div>
                                        <OwnerDetailsCard
                                            name={this.state.insurance_detail.name}
                                            age={this.state.insurance_detail.age}
                                            gender={this.state.insurance_detail.gender}
                                            maritalStatus={this.state.insurance_detail.maritalStatus}
                                            slug={slug}
                                        />
                                    </div>
                                </div>

                                {this.state.showCoverage ? (
                                    <Modal
                                        isShowModal={this.state.showCoverage}
                                        onCloseModal={this.closeModal}
                                        showClose={false}
                                        className="insuranceRecommendationMainPageModal"
                                    >
                                        <CoverageDetails onBack={this.closeModal} data={this.state.viewCoverageData} />
                                    </Modal>
                                ) : null}
                            </div>
                            <div>
                                <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left', padding: '0 10px' }}>
                                    *The insurance quote(s) displayed above are provided by licensed insurance agencies
                                    or carriers and are preliminary estimates only and are subject to change based on
                                    additional information we may receive later in the quoting process, the coverages,
                                    deductibles and payment options you choose along with other discounts, features or
                                    savings that may apply.
                                </div>
                            </div>
                            <div style={{
                                    textAlign: 'left',
                                    color: '#6b6c6f',
                                    fontSize: '12px',
                                    padding: '0px 10px',
                                    margin: '20px auto 0',
                                    maxWidth: '1447px',
                                    width: '100%'
                            }}>
                                *PODS is not a licensed insurance agency or insurance company.  All information provided on this website regarding insurance is for information purposes only and no solicitation or offer of insurance is being made by or on behalf of PODS. Insurance information, quotes and products are offered through InsuraMatch, LLC., a licensed insurance agency (CA: InsuraMatch Insurance Agency, LLC #0L09834, NY: InsuraMatch Agency # 1335192, TX:  InsuraMatch, LLC # 2138210), and the insurance companies that partner with InsuraMatch.  LiveEasy may receive compensation from InsuraMatch for allowing InsuraMatch to provide insurance through this website.  Neither LiveEasy nor PODS endorse any particular insurance carrier or policy that may appear on this website and are not involved in the sale, solicitation or negotiation of insurance.  Use of this website is completely optional and provided to you as a convenience to obtain insurance quotes. Not all insurance options that may be available to you are listed on this website.
                            </div>
                        </div>
                    </div>
                    {this.state.calendlyOpen && (
                        <Modal
                            isShowModal={this.state.calendlyOpen}
                            onCloseModal={() => this.setState({ calendlyOpen: false })}
                            showClose={false}
                            // className="scheduleCallInsuranceMain"
                            className="modalClasses"
                        >
                            <div className='HomeInsurance'>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon
                                        onClick={() => this.setState({ calendlyOpen: false })}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="line12"></div>
                                <form>
                                    <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                        <div style={{ fontSize: '16px', color: '#333333', fontWeight: 'bolder' }}>
                                            Call Schedule Details
                                        </div>
                                        <div className="ModalTimeDate">
                                            <div>
                                                {/* <TextField
                                                id="date"
                                                label="Select Date"
                                                defaultValue={Date.now}
                                                value={this.state.date}
                                                onChange={(e: any) => this.dateHandler(e)}
                                                type="date"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disablePast
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date"
                                                        inputVariant="filled"
                                                        label="Select date"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        value={this.state.date ? new Date(this.state.date) : null}
                                                        onChange={(e: any) => this.dateHandler(e)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        PopoverProps={{
                                                            style: { zIndex: 1000000 },
                                                        }}
                                                        autoOk={true}
                                                        error={
                                                            (this.state.submitClick && !this.state.date) ||
                                                            (this.state.date !== '' && !Date.parse(this.state.date))
                                                        }
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                    {(this.state.submitClick && !this.state.date && 'Please select date') ||
                                                        (this.state.date !== '' &&
                                                            !Date.parse(this.state.date) &&
                                                            'Invalid Date')}
                                                </div>
                                            </div>
                                            <div>
                                                {/* <TextField
                                                id="time"
                                                label="Select Time"
                                                value={this.state.time}
                                                defaultValue="12:00"
                                                onChange={(e: any) => this.timeHandler(e)}
                                                type="time"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        ampm={true}
                                                        variant="inline"
                                                        margin="normal"
                                                        id="time"
                                                        inputVariant="filled"
                                                        label="Select time"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        value={this.state.time ? this.state.time : null}
                                                        onChange={(e: any) => this.timeHandler(e)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                        keyboardIcon={<AccessAlarmIcon />}
                                                        autoOk={true}
                                                        PopoverProps={{
                                                            style: { zIndex: 1000000 },
                                                        }}
                                                        // InputProps={{
                                                        //     onFocus: () => {
                                                        //         this.setState({ isOpen1: true });
                                                        //     },
                                                        // }}
                                                        error={
                                                            (this.state.submitClick && !this.state.time) ||
                                                            (this.state.time !== '' && !Date.parse(this.state.time))
                                                        }
                                                    // open={this.state.isOpen1}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                    {(this.state.submitClick && !this.state.time && 'Please select time') ||
                                                        (this.state.time !== '' &&
                                                            !Date.parse(this.state.time) &&
                                                            'Invalid Date')}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                // marginTop: '10px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="peronalDetailsInsurance">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.submitClick && this.state.firstName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName ? this.state.lastName : this.props.commonState?.move[0]?.user_ref?.last_name}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            // error={this.state.submitClick && this.state.lastName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                // onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                // onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Zip code"
                                                value={this.state.zip}
                                                // onChange={(e: any) => this.zipHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                        </div>
                                        <div className={isDouglasElliman ? 'checkboxMain' : 'checkboxnoDoug'}>

                                            <div style={{ display: 'flex', marginTop: '20px', textAlign: 'justify' }}>
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox"
                                                    checked={this.state.checked}
                                                    onChange={this.handleChange}
                                                    name="checkedG"
                                                />
                                                <div style={{ fontSize: 12, color: '#666' }}>
                                                    By clicking the “submit” button below, you are providing your signed
                                                    written consent to receive calls or texts from InsuraMatch and its
                                                    agents to market insurance products at the number (wireless or
                                                    otherwise) you provided above, even if the number is on a federal, state
                                                    or local do not call list. As part of that consent, you understand and
                                                    agree that (1) those calls may be auto-dialed and use artificial or
                                                    pre-recorded voice messages or SMS communications, and (2) your
                                                    agreement to receive these calls is not required to purchase any goods
                                                    or services, and (3) InsuraMatch will not sell your information to third
                                                    parties without your consent, and (4) InsuraMatch may make additional
                                                    attempts to call/message you if we are unable to reach you at the
                                                    scheduled time. You may revoke your consent by contacting us at
                                                    <a className={
                                                        this.state.isDouglasElliman
                                                            ? 'redirectInsuranceD'
                                                            : 'redirectInsurance'
                                                    }
                                                        href='tel:844-406-1890'> 844-406-1890</a>. You agree to InsuraMatch's&nbsp;
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/terms-and-conditions',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Terms & Conditions,
                                                    </span>{' '}
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/compensation-and-fcra-information-disclosure',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Compensation and Information Disclosure
                                                    </span>{' '}
                                                    and{' '}
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/privacy-policy',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </span>{' '}
                                                    and authorize InsuraMatch and the companies it represents to obtain consumer
                                                    reports on the persons listed in your application.
                                                </div>
                                            </div>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {this.state.submitClick && !this.state.checked && 'Please accept'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line12"></div>
                                    <div className="InsuranceOrderPageButton">
                                        <Button
                                            className="btnOrder"
                                            backgroundColor="#FFF"
                                            boxShadow={`0 0 3px 0 ${isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                                }`}
                                            width="130px"
                                            height="50px"
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273e59'
                                            }
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 10px 0 0"
                                            onClick={() => this.closeModal()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btnOrder"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : window.location.pathname.includes('landing')
                                                            ? '#30AB86'
                                                            : '#273e59'
                                            }
                                            // boxShadow="0 0 3px 0 #273E59"
                                            width="130px"
                                            height="50px"
                                            // color="#FFFFFF"
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            onClick={() => this.submitHandler()}
                                            loading={this.state.loading}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    <input type="hidden" name="xxCertUrl" ref={(input) => { this.trustedUrl = input?.value }} />
                                </form>
                            </div>
                            {/* <div className="scheduleCallBack" onClick={() => this.setState({ calendlyOpen: false })}>
                                <ArrowBackIcon />
                            </div>
                            <InlineWidget
                                styles={{ height: '100%' }}
                                url={
                                    moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url
                                }
                                prefill={{
                                    email:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.email,
                                    firstName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.first_name,
                                    lastName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.last_name,
                                }}
                            /> */}
                            {/* <div className="iFrame" >
                     { moveData && moveData.assigned_concierge
                             && moveData.assigned_concierge.nylas_full_service_scheduler_url ?   
                               <iframe src={`${moveData && moveData.assigned_concierge
                             && moveData.assigned_concierge.nylas_full_service_scheduler_url}?utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas&name=${moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.first_name.concat(moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.last_name) }&email=${moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.email}&phone=${moveData &&
                                    moveData.user_ref &&
                                    moveData.user_ref.phone}&hash_code=${moveData &&
                                        moveData.hash_code}&interested_services=home-insurance`}
                                allow="encrypted-media"
                                // position="relative"
                                className="iframe"
                                //  referrerPolicy="same-origin"
                                //  sandbox="allow-scripts"
                                id="myFrame"



                            ></iframe> : <div>Nylas Url Not Found</div>


                                    }
                        </div> */}
                        </Modal>
                    )}
                    {this.appContext?.appType!="WITHOUT_AUTH" &&
                    <Footer history={this.props.history} /> }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    insuranceState: state.insurance,
    signupState: state.signup,
});
PlanPage.contextType = UIContext
export default connect(mapStateToProps, mapDispatchToProps)(PlanPage);
