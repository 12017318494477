import React, { Component } from 'react';
import './homeSecurity.scss';
import Footer from './Footer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import Loc from '@material-ui/icons/Room';
import GirlSvg from '../../Assets/images/HomeSecurity/girl';
import Button from '../../components/atoms/Button';
// import Concierge from '../../Assets/images/HomeSecurity/Concierge.svg';
import RecommendationCardsAll from '../../components/atoms/HomeSecurity/RecommendationCardsAll';
// import ViewDetailsPage from "./viewDeatils";
import Modal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import * as SignupAction from '../../store/login/signup/actions';
import History from '../../routes/History';
// import Skeleton from 'react-loading-skeleton';
// import { scheduleSegment } from '../../utils/utlis';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    signupAction?: any;
    signupState?: any;
    landing?: any;
};

type initialState = {
    showModal: boolean;
    calendlyOpen2: boolean;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isDataArrived: boolean;
    isSubmitClicked: boolean;
    calendlyOpen: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    reschedule_link: string;
    nylas_full_service_scheduler_url: string;
    appointmentDetailsArrived: boolean;
    openForm: boolean;
    custom_schedule_call_info_enabled: any;
    full_name: string;
    isDouglasElliman: boolean;
    hashcode: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    brokerage_key: any;
    agent_key: any;
    concierge_ref: any;
};

class Recommendation extends Component<initialProps, initialState> {
    state: initialState = {
        calendlyOpen: false,
        showModal: false,
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        calendlyOpen2: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isDataArrived: true,
        isSubmitClicked: false,
        reschedule_link: '',
        nylas_full_service_scheduler_url: '',
        appointmentDetailsArrived: true,
        openForm: false,
        custom_schedule_call_info_enabled: false,
        full_name: '',
        isDouglasElliman: false,
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        brokerage_key: '',
        agent_key: '',
        concierge_ref: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        window.scrollTo(0, 0);
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            this.setState({
                address: data && data.destination_full_address,
                moveId: data && data.id,
                hashcode: data && data.hash_code,
                firstName: data && data.user_ref && data.user_ref.first_name,
                lastName: data && data.user_ref && data.user_ref.last_name,
                email: data && data.user_ref && data.user_ref.email,
                phone: data && data.user_ref && data.user_ref.phone,
                full_name: `${data && data.user_ref && data.user_ref.first_name} ${data &&
                    data.user_ref &&
                    data.user_ref.last_name}`,
                // custom_schedule_call_info: data.realtor && data.realtor.custom_schedule_call_info,
            });
            let slug: any = localStorage.getItem('slug');
            if (slug) {
                slug = JSON.parse(slug);
                let brokerage: any;
                brokerage = slug.display_name;
                let brokerage_key: any = slug.brokerage_key;
                let agent_key: any = slug.realtor_key;

                let agent_name: any = slug.contact_name;
                let move_id: any = localStorage.getItem('moveKey');
                if (
                    slug &&
                    slug.enabled_features &&
                    slug.enabled_features.includes('Customize Concierge Contact Options')
                ) {
                    this.setState({ custom_schedule_call_info_enabled: true });
                }
                let windownew: any = window;
                let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                analytics.track(
                    `Banner Viewed`,
                    {
                        page: 'Security Recommendations',
                        brokerage_name: brokerage,
                        brokerage_key: brokerage_key,
                        agent_key: agent_key,
                        agent_name: agent_name,
                        category_name: 'Home Security',
                        banner: true,
                        banner_title: 'Lower your home insurance by setting up home security.',
                        brand_name: '',
                    },
                    { user_id: move_id },
                );

                analytics.track(
                    `Banner Viewed`,
                    {
                        page: 'Security Recommendations',
                        brokerage_name: brokerage,
                        brokerage_key: brokerage_key,
                        agent_key: agent_key,
                        agent_name: agent_name,
                        category_name: 'Home Security',
                        banner: true,
                        banner_title:
                            'Ask your concierge about how MoveEasy can customize the best deals for your family.',
                        brand_name: '',
                    },
                    { user_id: move_id },
                );
            }
        }
        //  else {
        this.props.commonAction.moveGet();
        // }

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding' &&
            this.props.history.location.state.data
        ) {
            const data = this.props.history.location.state.data;
            const dest: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                moveId: data && data.id,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.full_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.full_name;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.concierge_ref =
                nextProps.commonState.move[0] && nextProps.commonState.move[0].concierge_reference_field;
            // currentState.custom_schedule_call_info =
            //     nextProps.commonState.move[0].realtor &&
            //     nextProps.commonState.move[0].realtor.custom_schedule_call_info;
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.appointmentDetails &&
            nextProps.commonState.appointmentDetails.reschedule_link &&
            currentState.appointmentDetailsArrived
        ) {
            currentState.appointmentDetailsArrived = false;
            currentState.reschedule_link = nextProps.commonState.appointmentDetails.reschedule_link;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
            // let windownew: any = window;
            // let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.openForm = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                address: cur.move[0] && cur.move[0].destination_full_address,
                moveId: cur.move[0] && cur.move[0].id,
            });
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }
    }

    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });
        // let hashcode: any = this.state && this.state.hashcode ? this.state.hashcode : '';
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            'Schedule Call Button Clicked',
            {
                brokerage_name: this.state.brokerage_name,
                brokerage_key: this.state.brokerage_key,
                agent_name: this.state.agent_name,
                agent_key: this.state.agent_key,
                page: 'Security Recommendations',
                category_name: 'Home Security',
                button_copy: 'Schedule A Call',
                card: false,
                card_title: '',
                banner: true,
                banner_title: 'Lower your home insurance by setting up home security.',
            },
            { user_id: this.state.moveId },
        );
    };
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        this.props.commonAction.appointmentGet(payload);
        this.setState({
            appointmentDetailsArrived: true,
        });
    };

    calendlyHandle2 = () => {
        this.setState({ calendlyOpen: true });
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            'Schedule Call Button Clicked',
            {
                brokerage_name: this.state.brokerage_name,
                brokerage_key: this.state.brokerage_key,
                agent_name: this.state.agent_name,
                agent_key: this.state.agent_key,
                page: 'Security Recommendations',
                category_name: 'Home Security',
                button_copy: 'Schedule A Call',
                card: false,
                card_title: '',
                banner: true,
                banner_title: 'Ask your concierge about how MoveEasy can customize the best deals for your family.',
            },
            { user_id: this.state.moveId },
        );
    };

    closeCalendlyModal2 = async () => {
        await this.setState({ calendlyOpen2: false, openForm: false });
    };
    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    onCloseModal = () => {
        this.setState({});
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
        });
    };

    closeCalendlyModal = async () => {
        if (this.state.openForm) {
            if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
                let payload = {
                    move_id: this.state.moveId,
                    body: {
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone,
                    },
                };

                this.props.commonAction.scheduleCallPost(payload);
            }
            this.setState({ openForm: false });
        } else {
            await this.setState({ isSubmitClicked: true });
            if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
                await this.setState({ calendlyOpen2: false, isSubmitClicked: false });
                const domain = window.location.hostname;
                let name = domain.split('.');
                const payload = {
                    destination_detail: this.state.address,
                    destination_home: '1',
                    moving_date: new Date().toISOString().split('T')[0],
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    type: 'security',
                    user_ref: {
                        email: this.state.email,
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        // password: password,
                        phone: this.state.phone,
                        site_name:
                            name[0] == 'security'
                                ? 'dtc-marketing'
                                : name[0] == 'security-demo'
                                ? 'apps-demo'
                                : 'moveeasy-demo',
                    },
                };
                this.props.signupAction.landingMovePost(payload);
            }
        }
    };

    closeModal = () => {
        this.setState({ calendlyOpen2: false, isSubmitClicked: false, openForm: false });
    };
    formHandler = () => {
        this.setState({
            openForm: true,
        });
    };

    onCheckIfQualifyHandler = () => {
        History.push({
            pathname: '/dashboard/home-security/checkifqualify',
            state: {
                firstName: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.first_name || '',
                lastName: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.last_name || '',
                email: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.email || '',
                phone: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.phone || '',
                id: null,
            }
        })
    }

    render() {
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="hsPage">
                    {this.props.landing ? null : (
                        <div className="hs-main-head">
                            <div>
                                <h2 className="hs-heading">Home Security</h2>
                                <div className="hs-path">
                                    <p
                                        className="hs-Para"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            this.props.history.push('/');
                                        }}
                                    >
                                        {this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.state &&
                                        this.props.history.location.state.fromLanding &&
                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? ' Home'
                                            : 'Dashboard'}{' '}
                                        {'>'}
                                    </p>{' '}
                                    <p style={{ marginLeft: '5px' }}>Home Security</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="hs-recommendation">
                        {this.state.showModal && (
                            <Modal
                                isShowModal={this.state.showModal}
                                onCloseModal={() => this.setState({ showModal: false })}
                                showClose={false}
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#ffffff',
                                    left: '0px',
                                    top: '0px',
                                }}
                            >
                                <div className="hs-initalModal">
                                    <div className="hs-modalHead">Searching for the best rates for you</div>
                                    {/* <div className="modalSubHead">
                                    We are analysing your answer and what people similar to you bought
                                </div> */}
                                    <div className="hs-modal-main-div">
                                        <div className="hsmodalData">
                                            <CheckCircleIcon />
                                            <div className="hstextModal">
                                                Fetching best quotes from 40+ home security
                                            </div>
                                        </div>
                                        <div className="hsmodalData1">
                                            <CheckCircleIcon />
                                            <div className="hstextModal1">Available in {this.state.address}</div>
                                        </div>
                                        <div className="hsmodalData2">
                                            <CheckCircleIcon />
                                            <div className="hstextModal2">Personalizing your quote right now</div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <div className="hs-topDiv"></div>
                        {this.props.landing ? null : (
                            <div className="hs-secondDiv">
                                <div
                                    className="hs-blackCard"
                                    style={{
                                        background:
                                            slug && slug.color_shade1
                                                ? slug.color_shade1
                                                : this.props.history &&
                                                  this.props.history.location &&
                                                  this.props.history.location.state &&
                                                  this.props.history.location.state.fromLanding &&
                                                  this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? '#30ab86'
                                                : '#08192F',
                                    }}
                                >
                                    <div className="hs-backCard-content">
                                        <div className="hs-title">
                                            Lower your home insurance by setting up home security.
                                        </div>
                                        <div className="hs-subTitle">
                                            Schedule a call to see if you are eligible for discounts
                                        </div>
                                        <Button
                                                className="btnHS"
                                                border={isDouglasElliman ? '1px solid  #100B28' : 'none'}
                                                backgroundColor="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : this.props.history &&
                                                                    this.props.history.location &&
                                                                    this.props.history.location.state &&
                                                                    this.props.history.location.state.fromLanding &&
                                                                    this.props.history.location.state.fromLanding ==
                                                                        'fromLanding'
                                                                  ? '#30ab86'
                                                                  : '#273E59'
                                                          }`
                                                }
                                                width="140px"
                                                height="40px"
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : this.props.history &&
                                                          this.props.history.location &&
                                                          this.props.history.location.state &&
                                                          this.props.history.location.state.fromLanding &&
                                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '#30ab86'
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                onClick={() => this.onCheckIfQualifyHandler()}
                                            >
                                                Get Started
                                            </Button>
                                        {/* {this.state.custom_schedule_call_info_enabled ? (
                                            <Button
                                                className="btnHS"
                                                border={isDouglasElliman ? '1px solid  #100B28' : 'none'}
                                                backgroundColor="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : this.props.history &&
                                                                    this.props.history.location &&
                                                                    this.props.history.location.state &&
                                                                    this.props.history.location.state.fromLanding &&
                                                                    this.props.history.location.state.fromLanding ==
                                                                        'fromLanding'
                                                                  ? '#30ab86'
                                                                  : '#273E59'
                                                          }`
                                                }
                                                width="140px"
                                                height="40px"
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : this.props.history &&
                                                          this.props.history.location &&
                                                          this.props.history.location.state &&
                                                          this.props.history.location.state.fromLanding &&
                                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '#30ab86'
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                onClick={() => this.formHandler()}
                                            >
                                                Contact Us
                                            </Button>
                                        ) : (
                                            <div>
                                                {this.state.reschedule_link ||
                                                this.state.nylas_full_service_scheduler_url ? (
                                                    <Button
                                                        className="btnHS"
                                                        backgroundColor="#FFFFFF"
                                                        border={isDouglasElliman ? '1px solid  #100B28' : 'none'}
                                                        boxShadow={
                                                            isDouglasElliman
                                                                ? 'none'
                                                                : `0 0 3px 0 ${
                                                                      slug && slug.primary_color
                                                                          ? slug.primary_color
                                                                          : this.props.history &&
                                                                            this.props.history.location &&
                                                                            this.props.history.location.state &&
                                                                            this.props.history.location.state
                                                                                .fromLanding &&
                                                                            this.props.history.location.state
                                                                                .fromLanding == 'fromLanding'
                                                                          ? '#30ab86'
                                                                          : '#273E59'
                                                                  }`
                                                        }
                                                        width="140px"
                                                        height="40px"
                                                        color={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : this.props.history &&
                                                                  this.props.history.location &&
                                                                  this.props.history.location.state &&
                                                                  this.props.history.location.state.fromLanding &&
                                                                  this.props.history.location.state.fromLanding ==
                                                                      'fromLanding'
                                                                ? '#30ab86'
                                                                : '#273E59'
                                                        }
                                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                        fontSize="16px"
                                                        onClick={() =>
                                                            this.props &&
                                                            this.props.history &&
                                                            this.props.history.location &&
                                                            this.props.history.location.state &&
                                                            this.props.history.location.state.fromLanding &&
                                                            this.props.history.location.state.fromLanding ==
                                                                'fromLanding'
                                                                ? this.setState({
                                                                      calendlyOpen2: true,
                                                                  })
                                                                : this.calendlyHandle()
                                                        }
                                                    >
                                                        {this.state.reschedule_link ? 'Reschedule' : 'Schedule A Call'}
                                                    </Button>
                                                ) : (
                                                    <Skeleton height="40px" width="100px" />
                                                )}
                                            </div>
                                        )} */}
                                    </div>
                                    {!isDouglasElliman && (
                                        <div className="hs-girl">
                                            <GirlSvg />
                                        </div>
                                    )}
                                </div>
                                {/* <div className="hs-offerCard">
                                    <div style={{ display: 'flex' }}>
                                        <div className="hs-offerContent">
                                            <div className="hs-offerTitle">
                                                Ask your {this.state.concierge_ref} about how MoveEasy can customize the
                                                best deals for your family.
                                            </div>
                                            <div className="hs-offerSubTitle">
                                                Learn more by selecting your right deals below
                                            </div>
                                            {this.state.custom_schedule_call_info_enabled ? (
                                                <Button
                                                    className="btnHS"
                                                    border={isDouglasElliman ? '1px solid  #100B28' : 'none'}
                                                    backgroundColor="#FFFFFF"
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : `0 0 3px 0 ${
                                                                  slug && slug.primary_color
                                                                      ? slug.primary_color
                                                                      : this.props.history &&
                                                                        this.props.history.location &&
                                                                        this.props.history.location.state &&
                                                                        this.props.history.location.state.fromLanding &&
                                                                        this.props.history.location.state.fromLanding ==
                                                                            'fromLanding'
                                                                      ? '#30ab86'
                                                                      : '#273E59'
                                                              }`
                                                    }
                                                    width="140px"
                                                    height="40px"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : this.props.history &&
                                                              this.props.history.location &&
                                                              this.props.history.location.state &&
                                                              this.props.history.location.state.fromLanding &&
                                                              this.props.history.location.state.fromLanding ==
                                                                  'fromLanding'
                                                            ? '#30ab86'
                                                            : '#273E59'
                                                    }
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="16px"
                                                    onClick={() => this.formHandler()}
                                                >
                                                    Contact Us
                                                </Button>
                                            ) : (
                                                <div>
                                                    {this.state.nylas_full_service_scheduler_url ||
                                                    this.state.reschedule_link ? (
                                                        <div
                                                            className="hs-viewDetails"
                                                            style={{
                                                                color: `${
                                                                    isDouglasElliman
                                                                        ? '#100B28'
                                                                        : slug && slug.primary_color
                                                                        ? slug.primary_color
                                                                        : this.props.history &&
                                                                          this.props.history.location &&
                                                                          this.props.history.location.state &&
                                                                          this.props.history.location.state
                                                                              .fromLanding &&
                                                                          this.props.history.location.state
                                                                              .fromLanding == 'fromLanding'
                                                                        ? '#30ab86'
                                                                        : '#006dff'
                                                                }`,
                                                                borderRadius: `${isDouglasElliman ? '0px' : '5px'}`,
                                                                border: `${
                                                                    isDouglasElliman
                                                                        ? '1px solid #100B28'
                                                                        : slug && slug.primary_color
                                                                        ? `1px solid ${slug.primary_color}`
                                                                        : this.props.history &&
                                                                          this.props.history.location &&
                                                                          this.props.history.location.state &&
                                                                          this.props.history.location.state
                                                                              .fromLanding &&
                                                                          this.props.history.location.state
                                                                              .fromLanding == 'fromLanding'
                                                                        ? '1px solid #30ab86'
                                                                        : '1px solid #006dff'
                                                                }`,
                                                            }}
                                                            onClick={() =>
                                                                this.props &&
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? this.setState({
                                                                          calendlyOpen2: true,
                                                                      })
                                                                    : this.calendlyHandle2()
                                                            }
                                                        >
                                                            {this.state.reschedule_link
                                                                ? 'Reschedule'
                                                                : 'Schedule A Call'}
                                                        </div>
                                                    ) : (
                                                        <Skeleton height="40px" width="100px" />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {!isDouglasElliman && (
                                        <div className="hs-offerTagImg1">
                                            <img src={Concierge} />
                                        </div>
                                    )}
                                </div> */}
                            </div>
                        )}

                        {/* } */}

                        <div className="recommendation-cards-all">
                            <div className="top-home-security-plans">
                                <div className="plans-top">Top Home Security Plans(4)</div>
                            </div>
                            <RecommendationCardsAll
                                isDouglasElliman={isDouglasElliman}
                                history={this.props.history}
                                securityType={
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.securityType
                                }
                                securityInstallation={
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.securityInstallation
                                }
                                fromLanding={
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.fromLanding &&
                                    this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? 'fromLanding'
                                        : ''
                                }
                                data={
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.data
                                        ? this.props.history.location.state.data
                                        : ''
                                }
                                landing={this.props.landing}
                            />
                        </div>

                        {this.state.calendlyOpen2 ||
                            (this.state.openForm && (
                                <Modal
                                    isShowModal={this.state.calendlyOpen2 || this.state.openForm}
                                    onCloseModal={this.closeCalendlyModal2}
                                    // onCloseModal={() => {
                                    //     this.setState({ calendlyOpen: false })
                                    //     // this.props.history.push({
                                    //     //     pathname:'dashboard/home-security/summary'
                                    //     // })
                                    // }}
                                    showClose={false}
                                    className="modalClassesSecurity"
                                >
                                    <div style={{ height: '100%' }}>
                                        <div className="CloseIcon">
                                            <div
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '20px',
                                                    fontWeight: 800,
                                                    textAlign: 'left',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                Schedule a Call Back
                                            </div>
                                            <CloseIcon
                                                onClick={() => this.closeCalendlyModal2()}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <div className="line12"></div>
                                        <div
                                            style={{ padding: '10px 20px 10px 20px' }}
                                            className="scheduleInsuranceCall"
                                        >
                                            {/* <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Call Schedule Details
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disablePast
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date"
                                                            className="date11"
                                                            value={this.state.date ? new Date(this.state.date) : null}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            autoOk={true}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.date) ||
                                                                (this.state.date !== '' && !Date.parse(this.state.date))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.date &&
                                                            'Please select date') ||
                                                            (this.state.date !== '' &&
                                                                !Date.parse(this.state.date) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            disableToolbar
                                                            ampm={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="time"
                                                            inputVariant="filled"
                                                            label="Select time"
                                                            className="date11"
                                                            value={this.state.time ? this.state.time : null}
                                                            onChange={(e: any) => this.timeHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            keyboardIcon={<AccessAlarmIcon />}
                                                            autoOk={true}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen1: true });
                                                            //     },
                                                            // }}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.time) ||
                                                                (this.state.time !== '' && !Date.parse(this.state.time))
                                                            }
                                                            // open={this.state.isOpen1}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.time &&
                                                            'Please select time') ||
                                                            (this.state.time !== '' &&
                                                                !Date.parse(this.state.time) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div
                                                style={{
                                                    fontSize: '16px',
                                                    // marginTop: '10px',
                                                    color: '#333333',
                                                    fontWeight: 'bolder',
                                                }}
                                            >
                                                Personal Details
                                            </div>
                                            <div className="peronalDetailsInsurance">
                                                <div className="flex-div">
                                                    <div className="div-1-name">
                                                        <TextField
                                                            id="time"
                                                            label="First Name"
                                                            value={this.state.firstName}
                                                            onChange={(e: any) => this.firstNameHandler(e)}
                                                            type="text"
                                                            variant="filled"
                                                            className={
                                                                isDouglasElliman ? 'date11 dateNoDoug' : 'date11'
                                                            }
                                                            error={
                                                                this.state.isSubmitClicked &&
                                                                this.state.firstName === ''
                                                            }
                                                        />
                                                        <div className="error">
                                                            {this.state.isSubmitClicked && this.state.firstName === ''
                                                                ? 'Please enter first name'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="div-2-name">
                                                        <TextField
                                                            id="time"
                                                            label="Last Name"
                                                            value={this.state.lastName}
                                                            onChange={(e: any) => this.lastNameHandler(e)}
                                                            type="text"
                                                            variant="filled"
                                                            className={
                                                                isDouglasElliman ? 'date11 dateNoDoug' : 'date11'
                                                            }
                                                            error={
                                                                this.state.isSubmitClicked && this.state.lastName === ''
                                                            }
                                                        />
                                                        <div className="error">
                                                            {this.state.isSubmitClicked && this.state.lastName === ''
                                                                ? 'Please enter last name'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <TextField
                                                    id="time"
                                                    label="Email"
                                                    value={this.state.email}
                                                    onChange={(e: any) => this.emailHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    error={this.state.isSubmitClicked && this.state.email === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.email === ''
                                                        ? 'Please enter email'
                                                        : this.state.isSubmitClicked &&
                                                          !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                        ? 'Please enter valid email'
                                                        : ''}
                                                </div>
                                                <TextField
                                                    id="time"
                                                    label="Phone Number"
                                                    value={this.state.phone}
                                                    onChange={(e: any) => this.phoneHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    error={this.state.isSubmitClicked && this.state.phone === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.phone === ''
                                                        ? 'Please enter phone number'
                                                        : this.state.isSubmitClicked && this.state.phone.length < 12
                                                        ? 'Please enter valid phone number'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line12"></div>
                                        <div className="InsuranceOrderPageButton">
                                            <Button
                                                className="btnOrder"
                                                backgroundColor="#FFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              this.props.history &&
                                                              this.props.history.location &&
                                                              this.props.history.location.state &&
                                                              this.props.history.location.state.fromLanding &&
                                                              this.props.history.location.state.fromLanding ==
                                                                  'fromLanding'
                                                                  ? '#30ab86'
                                                                  : '#273e59'
                                                          }`
                                                }
                                                width="130px"
                                                height="50px"
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : this.props.history &&
                                                          this.props.history.location &&
                                                          this.props.history.location.state &&
                                                          this.props.history.location.state.fromLanding &&
                                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '#30ab86'
                                                        : '#273e59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                margin="0 10px 0 0"
                                                border={`1px solid ${
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273e59'
                                                }`}
                                                onClick={() => this.closeCalendlyModal2()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btnOrder"
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : this.props.landing
                                                        ? '#30AB86'
                                                        : '#273e59'
                                                }
                                                // boxShadow="0 0 3px 0 #273E59"
                                                width="130px"
                                                height="50px"
                                                // color="#FFFFFF"
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                margin="0 5px 0 0"
                                                onClick={() => this.closeCalendlyModal()}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            ))}

                        {this.state.calendlyOpen && (
                            <Modal
                                isShowModal={this.state.calendlyOpen}
                                onCloseModal={this.nylasModalClosed}
                                showClose={false}
                                className="scheduleCallMoving"
                            >
                                <div
                                    className="scheduleCallBackMoving"
                                    onClick={() => this.setState({ calendlyOpen: false })}
                                >
                                    <ArrowBackIcon />
                                </div>
                                {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}

                                {this.state.nylas_full_service_scheduler_url || this.state.reschedule_link ? (
                                    <div className="iFrame">
                                        <iframe
                                            src={`${
                                                this.state.reschedule_link
                                                    ? this.state.reschedule_link
                                                    : this.state.nylas_full_service_scheduler_url
                                            }&utm_source=platform&utm_medium=web&utm_content=security-request&utm_campaign=security-platform
`}
                                            allow="encrypted-media"
                                            // position="relative"
                                            className="iframe"
                                            //  referrerPolicy="same-origin"
                                            //  sandbox="allow-scripts"
                                            id="myFrame"
                                        ></iframe>
                                    </div>
                                ) : null}
                            </Modal>
                        )}

                        {/* <Modal
                    isShowModal={true} 
                    showClose={false}
                    onCloseModal={this.onCloseModal}
                    className="securityRecommendationMainPageModal"
                >
                    <ViewDetailsPage onBack={this.onCloseModal} />
                </Modal> */}
                    </div>
                    {this.props.landing ? null : <Footer history={this.props.history} />}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(Recommendation);
