import React, { Component } from 'react';
import ButtonNew from '../../components/atoms/Button';
import '../../styles/landingPageHeaderInsurance.css';
import AllState from '../../Assets/images/InsuranceImages/allstate.png';
import hippo from '../../Assets/images/InsuranceImages/hippo.png';
import safeco from '../../Assets/images/InsuranceImages/safeco.png';
import traveller from '../../Assets/images/InsuranceImages/traveller.png';
import martford from '../../Assets/images/InsuranceImages/martford.png';
import progressive from '../../Assets/images/InsuranceImages/progressive1.png';
import metlife from '../../Assets/images/InsuranceImages/metlife1.png';
import sillwater from '../../Assets/images/InsuranceImages/Stillwater.jpg';
import ecompass from '../../Assets/images/InsuranceImages/encompass.gif';
import usa from '../../Assets/images/InsuranceImages/usaa.jpg';
import kemper from '../../Assets/images/InsuranceImages/kemper.jpg';
import insurance from '../../Assets/images/InsuranceImages/Insurance.svg';
import test_avatar from '../../Assets/images/test_avatar.png';
import review from '../../Assets/images/review.svg';
import HowItWorksCard from '../InternetLanding/howItWorksCard';
import Card from '../../components/atoms/Card';
import SimpleAccordion from '../../components/atoms/FAQ';
import TextField from '@material-ui/core/TextField';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { list } from '../../components/atoms/USAlist';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../components/atoms/Button';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CarouselCard from '../../components/atoms/CarouselCard';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIcon from '../../Assets/images/ArrowForwardIcon.svg';
import ArrowBackIcon from '../../Assets/images/ArrowBackIcon.svg';
// import SelectInput from '../../components/atoms/SelectInput';
// import TabComponent from './tabComponent';
import InternetLandingFooter from '../InternetLanding/InternetLandingFooter';
import moveEasy from '../../Assets/images/move_easy.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import { scroller } from 'react-scroll';
import InsuranceBanner from '../../Assets/images/InsuranceImages/insurance-banner.svg';
import costEffectiveImg from '../../Assets/images/InsuranceImages/Insurance_25.svg';
import bestImg from '../../Assets/images/InsuranceImages/best.svg';
import customerFirst from '../../Assets/images/InsuranceImages/customerFirst.svg';
import turnImg from '../../Assets/images/InsuranceImages/turn.svg';
import premimum from '../../Assets/images/InsuranceImages/premium.svg';
import handshakeImg from '../../Assets/images/InsuranceImages/handshake.svg';
import tailoredImg from '../../Assets/images/InsuranceImages/tailored.svg';
import productImg from '../../Assets/images/InsuranceImages/product.png';
import selectedPlanImg from '../../Assets/images/InsuranceImages/selectPlan.png';
import scheduleImg from '../../Assets/images/InsuranceImages/callshedule.png';
import customerServiceImg from '../../Assets/images/InsuranceCall.png';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
import HowItWorksMobile from './howItWorksMobile';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import LinearProgress from '@material-ui/core/LinearProgress';

import '../../styles/InsuranceLandingPage.css';

// const Text = [' Save 20%!']

type initialProps = {
    street?: string;
    state?: any;
    city?: string;
    zipcode?: string;
    commonAction?: any;
    commonState?: any;
    history?: any;
};

type initialState = {
    progress1: boolean;
    progress2: boolean;
    progress3: boolean;
    active: number;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    checkedG: boolean;
    counter: number;
    serialOne: boolean;
    serialTwo: boolean;
    serialThree: boolean;
    clicked: number;
    navLinks: any;
    activeTab: string;
    inactiveTab: string;
    counter2: number;
    address: string;
    moveId: any;
    isDataArrived: boolean;
    progressVal: number;
    streetError: string;
    cityError: string;
    stateError: string;
    zipcodeError: string;
    hostname: string;
    progressCard1:any
    progressCard2:any
    progressCard3:any
};

export class LandingPageInsurance extends Component<initialProps, initialState> {
    state: initialState = {
        moveId: '',
        isDataArrived: true,
        street: '',
        city: '',
        state: '',
        zipcode: '',
        checkedG: false,
        counter: 0,
        counter2: 0,
        address: '',
        serialOne: true,
        serialTwo: false,
        serialThree: false,
        clicked: 1,
        active: 0,
        progress1: false,
        progress2: false,
        progress3: false,
        progressVal: 0,
        streetError: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        navLinks: [
            {
                main: 'Home',
                subLinks: [
                    {
                        // link: '/home',
                        heading: 'Home',
                        exact: true,
                        class: 'mortgage',
                        className: 'dynamicHeaderImageDivInsurance',
                    },
                ],
            },
            {
                main: 'How it Works',
                subLinks: [
                    {
                        // link: '/how-it-works',
                        // link: '/home',
                        heading: 'How it Works',
                        exact: true,
                        class: 'how-it-works',
                        className: 'how-it-works-wrapper-insurance',
                    },
                ],
            },
            {
                main: 'Support',
                subLinks: [
                    {
                        // link: '/support',
                        // link: '/home',
                        heading: 'Support',
                        exact: true,
                        class: 'support',
                        className: 'call-wrapper-internet',
                    },
                ],
            },
            {
                main: 'Core Features',
                subLinks: [
                    {
                        // link: '/how-we-compare',
                        // link: '/home',
                        heading: 'Core Features',
                        exact: true,
                        class: 'how-we-compare',
                        className: 'compare-section',
                    },
                ],
            },
            {
                main: 'Reviews',
                subLinks: [
                    {
                        // link: '/reviews',
                        // link: '/home',
                        heading: 'Reviews',
                        exact: true,
                        class: 'reviews',
                        className: 'carousel',
                    },
                ],
            },
            {
                main: 'FAQ',
                subLinks: [
                    {
                        // link: '/faq',
                        // link: '/home',
                        heading: 'FAQ',
                        exact: true,
                        class: 'faq',
                        className: 'questions-internet',
                    },
                ],
            },
        ],
        activeTab: 'nav_selected',
        inactiveTab: 'nav_unselected',
        hostname: '',
        progressCard1:0,
        progressCard2:0,
        progressCard3:0
    };

    componentDidMount() {
        const domain = window.location.hostname;
        let name = domain.split('.');
        if (name[0]) {
            this.setState({ hostname: name[0] });
        }
        if (!name[0].includes('insurance') && !name[0].includes('insurance-demo')) {
            this.props.commonAction.moveGet();
        }

        setInterval(() => this.handleTextChange(), 3000);
        setInterval(() => this.handleCardChange(), 3000);

        setInterval(() => this.handleProgressChange(), 500);
        setInterval(() => this.handleProgressChange2(), 500);
        setInterval(() => this.handleProgressChange3(), 500);
    }

    handleProgressChange=()=>{
        if(this.state.progressCard1>=75){
            this.setState({progressCard1:100})
        }
       else if(this.state.progressCard1<75){
        this.setState({
            progressCard1:this.state.progressCard1+15
        })
       }
    }
    handleProgressChange2=()=>{
        if(this.state.progressCard2>=75){
            this.setState({progressCard2:100})
        }
       else if(this.state.progressCard2<75){
        this.setState({
            progressCard2:this.state.progressCard2+15
        })
       }
    }
    handleProgressChange3=()=>{
        if(this.state.progressCard3>=75){
            this.setState({progressCard3:100})
        }
       else if(this.state.progressCard3<75){
        this.setState({
            progressCard3:this.state.progressCard3+15
        })
       }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length &&
            currentState.isDataArrived
        ) {
            nextProps.commonState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.zipcode = item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.city = item.destination && item.destination.city_name ? item.destination.city_name : '';
                currentState.state = item.destination && item.destination.state_code ? item.destination.state_code : '';
                currentState.street = item.destination && item.destination_street ? item.destination_street : '';
                currentState.moveId = item && item.id ? item.id : '';
            });
        }
        return currentState;
    }

    componentWillUnmount() {
        clearInterval();
    }
    handleCardChange = () => {
        let { counter2 } = this.state;
        if (counter2 == 0) {
            this.handleSerialOne();
            this.setState({
                counter2: counter2 + 1,
                progress1: true,
                progress2: false,
                progress3: false,
                progressCard1:0
            });
        }
        if (counter2 == 1) {
            this.handleSerialTwo();
            this.setState({
                counter2: counter2 + 1,
                progress1: false,
                progress2: true,
                progress3: false,
                progressCard2:0
            });
        }
        if (counter2 == 2) {
            this.handleSerialThree();
            this.setState({
                counter2: 0,
                progress1: false,
                progress2: false,
                progress3: true,
                progressCard3:0
            });
        }
    };

    // streetHandler = (e: any) => {
    //     this.setState({
    //         street: e.target.value,
    //     });
    // };
    cityHandler = (e: any) => {
        this.setState({
            city: e.target.value,
        });
    };
    stateHandler = (e: any) => {
        this.setState({
            state: e.target.value,
        });
    };
    zipcodeHandler = (e: any) => {
        this.setState({
            zipcode: e.target.value,
        });
    };

    validateFields = () => {
        let validity: Boolean = true;

        if (this.state.street === '') {
            this.setState({ streetError: 'Enter street Name' });
            validity = false;
        } else {
            this.setState({ streetError: '' });
        }

        if (this.state.state === '') {
            this.setState({ stateError: 'Enter State Name' });
            validity = false;
        } else {
            this.setState({ stateError: '' });
        }

        if (this.state.city === '') {
            this.setState({ cityError: 'Enter City Name' });
            validity = false;
        } else {
            this.setState({ cityError: '' });
        }

        if (this.state.zipcode === '') {
            this.setState({ zipcodeError: 'Enter Zip Code' });
            validity = false;
        } else {
            this.setState({ zipcodeError: '' });
        }

        return validity;
    };

    tabChangeHandler = (fromQuote: boolean) => {
        if (fromQuote == true) {
            if (this.validateFields()) {
                const { street, state, zipcode, city } = this.state;
                if (street && state && zipcode && city) {
                    if (!this.state.hostname.includes('insurance') && !this.state.hostname.includes('insurance-demo')) {
                        this.props.commonAction.moveAddressUpdate({
                            destination_detail: `${street}, ${city}, ${state}, ${zipcode}`,
                            move_id: this.state.moveId,
                        });
                        this.props.history.push({
                            pathname: '/landing/insurance/steps',
                            state: {
                                fromLanding: 'fromLanding',
                            },
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/landing/insurance/steps',
                            state: {
                                fromLanding: 'fromLanding',
                                data: {
                                    destination: {
                                        lookup_name: city,
                                        zip_code: zipcode,
                                    },
                                    destination_street: street,
                                    destination_full_address: this.state.address,
                                },
                            },
                        });
                    }
                }
            }
        } else {
            this.props.history.push({
                pathname: '/landing/insurance/steps',
                state: {
                    fromLanding: 'fromLanding',
                },
            });
        }
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkedG: event.target.checked });
    };

    handleTextChange = () => {
        // let { counter } = this.state;
        // let myContainer = document.getElementById('change-text') as HTMLInputElement;
        // myContainer.innerHTML = Text[counter];
        // if (counter == 0) {
        //     this.setState({
        //         counter: counter + 1,
        //     });
        // } else {
        //     this.setState({
        //         counter: counter - 1,
        //     });
        // }
    };

    handleSerialOne = () => {
        this.setState({
            serialOne: true,
            serialTwo: false,
            serialThree: false,
            clicked: 1,
           
        });
    };

    handleSerialTwo = () => {
        this.setState({
            serialOne: false,
            serialTwo: true,
            serialThree: false,
            clicked: 2,
           
        });
    };

    handleSerialThree = () => {
        this.setState({
            serialOne: false,
            serialTwo: false,
            serialThree: true,
           
            clicked: 3,
        });
    };
    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: state,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    render() {
        const { navLinks } = this.state;
        const { serialOne, serialTwo, serialThree, clicked } = this.state;
        const quesAns: any = [
            {
                ques: 'How much does the platform cost after the trial period?',
                ans: 'Not Available',
            },
            {
                ques: 'Is your Human Concierge included in the overall service?',
                ans:
                    'Yes. Its all included. Your clients can talk to a live human being who will help them switch all their utilities in just one call at no extra charge. This includes electric, gas, water, internet, cable, TV & security, moving, home services etc.',
            },
            {
                ques: 'How does my client access this service?',
                ans: 'Not Available',
            },
            {
                ques: 'Do you sell our clients contact information?',
                ans: 'Not Available',
            },
            {
                ques: 'Does your utility and DMV list cover my city and state?',
                ans: 'Not Available',
            },
        ];

        let carouselItems: any = [
            [
                {
                    avatar: test_avatar,
                    name: 'Jessica Mules',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 2',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 3',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 4',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
            [
                {
                    avatar: test_avatar,
                    name: 'Person 5',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 6',
                    title: 'Customer',
                    rate: 4,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 7',
                    title: 'Customer',
                    rate: 3,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 8',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
        ];

        let carouselItemsMedia: any = [];

        carouselItems.map((item: any) => {
            item.map((item2: any) => {
                carouselItemsMedia.push(item2);
            });
        });

        return (
            <div style={{ fontFamily: 'Lato !important' }} className="main-insurance">
                <div className="landingHeader-insurance">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="logo-container-insurance">
                            <img src={moveEasy} />
                        </div>
                        <div className="navLinkFlexDiv-insurance">
                            <nav>
                                <ul style={{ display: 'flex', flexDirection: 'row', margin: '0 11px' }}>
                                    {navLinks.map((row: any, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {row.subLinks.map((sublink: any) => {
                                                    return (
                                                        <li
                                                            className="insurance-li"
                                                            style={{ listStyleType: 'none' }}
                                                            key={sublink.heading}
                                                            onClick={() => {
                                                                scroller.scrollTo(sublink.className, {
                                                                    duration: 800,
                                                                    delay: 0,
                                                                    smooth: 'easeInOutQuart',
                                                                });
                                                            }}
                                                        >
                                                            <div className={`${sublink.class} insurance-header-links`}>
                                                                <span style={{ color: '#707070' }}>
                                                                    {sublink.heading}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </nav>
                            <div style={{ display: 'flex' }}>
                                <span className="phone-insurance">
                                    <span className="phone-icon-insurance MuiSvgIcon-root">
                                        {' '}
                                        <PhoneIcon />
                                    </span>
                                    <a href={`tel: (810) 525-8185`}>
                                        (810) 525-8185
                                            </a>
                                </span>
                                <span>
                                    <ButtonNew
                                        className="landingHeaderButton-insurance"
                                        // onClick={() => {
                                        //     scroller.scrollTo('address-wrapper-insurance', {
                                        //         duration: 800,
                                        //         delay: 0,
                                        //         smooth: 'easeInOutQuart',
                                        //     });
                                        // }}
                                        onClick={() => this.tabChangeHandler(false)}
                                    >
                                        Get Started
                                    </ButtonNew>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-image dynamicHeaderImageDivInsurance">
                    <img src={InsuranceBanner} width="100%" />
                </div>
                <div className="insuranceLanding-main-page">
                    <div className="text-insurance">
                        <div className="you-deserve-text-insurance">Get Home Insurance Policy</div>
                        <div className="Head-space">
                            <span className="you-deserve-text-insurance">and</span>&nbsp;&nbsp;&nbsp;
                            <span className="wfh-insurance" id="change-text">
                                {'Save 20%'}
                            </span>
                        </div>
                        <p className="header-desc-insurance">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s..
                        </p>
                        <ButtonNew
                            backgroundColor="#30AB86"
                            className="landingGetQuoteButton-insurance"
                            onClick={() => {
                                // scroller.scrollTo('address-wrapper-insurance', {
                                //     duration: 800,
                                //     delay: 0,
                                //     smooth: 'easeInOutQuart',
                                // });
                                this.tabChangeHandler(false);
                            }}
                        >
                            Get a Quote
                        </ButtonNew>
                    </div>
                </div>

                <div className="worlds-insurance">
                    <div className="worlds-heading-insurance">Top home insurance providers</div>
                    <div className="worlds-para-insurance">
                        MoveEasy compares over 100 insurance companies to find you the best value.
                    </div>

                    <div className="main-div">
                        <div className="div-1-insurance">
                            <img src={AllState} className="provider2-image" />
                            <img src={hippo} className="provider2-image" />
                            <img src={safeco} className="provider2-image" />
                            <img src={traveller} className="provider2-image" />
                            <img src={martford} className="provider2-image" />
                        </div>

                        <div className="div-2-insurance">
                            <img src={progressive} className="provider2-image" />
                            <img src={metlife} className="provider2-image" />
                            <img src={sillwater} className="provider2-image" />
                            <img src={ecompass} className="provider2-image" />
                            <img src={usa} className="provider2-image" />
                            <img src={kemper} className="provider2-image" />
                        </div>
                    </div>
                </div>

                <div className="mobile-div">
                    {' '}
                    <ButtonNew
                        className="random-button"
                        onClick={() => {
                            // scroller.scrollTo('address-wrapper-insurance', {
                            //     duration: 800,
                            //     delay: 0,
                            //     smooth: 'easeInOutQuart',
                            // });
                            this.tabChangeHandler(false);
                        }}
                    >
                        Get Started
                    </ButtonNew>
                </div>
                <div className="collection-insurance">
                    <Card
                        // width="27%"
                        // height="300px"
                        className="collectionCard-insurance"
                        imgClassname="collectionCardImg-insurance"
                        cardImage={handshakeImg}
                        cardTitle="Reliable partner"
                        cardContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor, malesuada mi in, laoreet mi."
                    />

                    <Card
                        // width="27%"
                        // height="300px"
                        className="collectionCard-insurance"
                        imgClassname="collectionCardImg-insurance"
                        cardImage={tailoredImg}
                        cardTitle="Tailored Plans"
                        cardContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor, malesuada mi in, laoreet mi."
                    />

                    <Card
                        className="collectionCard-insurance"
                        imgClassname="collectionCardImg-insurance"
                        // width="27%"
                        // height="300px"
                        cardImage={bestImg}
                        cardTitle="Best-in-class services"
                        cardContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor, malesuada mi in, laoreet mi."
                    />
                </div>

                <div className="how-it-works-wrapper-insurance">
                    <div className="worlds-heading-insurance">How It Works</div>
                    <div className="worlds-para-insurance">
                        MoveEasy powers millions of websites across hundreds of industries for people just like you.
                    </div>
                    <div className="card-img-wrapper-insurance" style={{ width: '100%' }}>
                        <div
                            style={{
                                paddingTop: '50px',
                                // display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}
                            className="how-it-works-card-div-insurance"
                        >
                            <div
                            //  onClick={() => this.handleSerialOne()} 
                             className="progressBarFlexDiv-insurance">
                                <HowItWorksCard
                                    headingClass="insurance-card-heading"
                                    descClass="insurance-card-description"
                                    clicked={clicked}
                                    serial={1}
                                    heading="Confirm your home address"
                                    desc="We’ve found the top home insurance providers, their best offers and most popular plans just for you."
                                />
                                <div style={{ height: '3px' }}>
                                    {this.state.progress1 ? (
                                        <LinearProgress
                                            className="linearProgressLanding-insurance"
                                            variant="determinate"
                                            value={this.state.progressCard1}
                                            style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div 
                            // onClick={() => this.handleSerialTwo()} 
                            className="progressBarFlexDiv-insurance">
                                <HowItWorksCard
                                    headingClass="insurance-card-heading"
                                    descClass="insurance-card-description"
                                    clicked={clicked}
                                    serial={2}
                                    heading="Select estimated insurance plan"
                                    desc="Search providers, compare plans and order service all on our secure site."
                                />
                                <div style={{ height: '3px' }}>
                                    {this.state.progress2 ? (
                                        <LinearProgress
                                            className="linearProgressLanding-insurance"
                                            variant="determinate"
                                            value={this.state.progressCard2}
                                            style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div 
                            // onClick={() => this.handleSerialThree()}
                             className="progressBarFlexDiv-insurance">
                                <HowItWorksCard
                                    headingClass="insurance-card-heading"
                                    descClass="insurance-card-description"
                                    clicked={clicked}
                                    serial={3}
                                    heading="Set up a call with our concierge. Either way, we’re here."
                                    desc="Use our patented technology to search providers, compare plans and order service all on our secure site."
                                />
                                <div style={{ height: '3px' }}>
                                    {this.state.progress3 ? (
                                        <LinearProgress
                                            className="linearProgressLanding-insurance"
                                            variant="determinate"
                                            value={this.state.progressCard3}
                                            style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="width-2">
                            {serialOne ? (
                                <div className="side-image">
                                    <img src={productImg} className="moving-auto-image" />
                                </div>
                            ) : serialTwo ? (
                                <div className="side-image">
                                    <img src={selectedPlanImg} className="moving-auto-image" />
                                </div>
                            ) : serialThree ? (
                                <div className="side-image">
                                    <img src={scheduleImg} className="moving-auto-image" />
                                </div>
                            ) : (
                                <div className="side-image">
                                    <img src={productImg} className="moving-auto-image" />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="how-it-works-tab-mobile-insurance">
                        <HowItWorksMobile />
                    </div>
                </div>

                {/* Give us a call */}
                <div className="call-wrapper-internet card-img-wrapper-internet">
                    <div className="imgDiv">
                        <img src={customerServiceImg} />
                    </div>
                    <div className="scheduleCallDiv">
                        <div className="worlds-heading-internet">Give us a call.</div>
                        <div className="worlds-heading-internet">We'll get you taken care of.</div>
                        <div className="card-desc-internet">
                            MoveEasy powers millions of websites across hundreds of industries for people just like you.
                        </div>
                        <div className="call-now-internet">Call now: (810) 525 - 8185</div>
                        <div className="week-time-div-internet">
                            <div className="week-time-internet">
                                <div className="day-internet">Monday - Friday</div>
                                <div className="time-internet">8:00am to 10:00pm EST</div>
                            </div>

                            <div className="week-time-internet">
                                <div className="day-internet">Saturday</div>
                                <div className="time-internet">9:00am to 7:00pm EST</div>
                            </div>

                            <div className="week-time-internet">
                                <div className="day-internet">Sunday</div>
                                <div className="time-internet">10:00am to 7:00pm EST</div>
                            </div>
                            <div className="scheduleCallButtonDiv">
                                <ButtonNew
                                    className="scheduleCallButton"
                                    // backgroundColor="#fff"
                                    // color="#333"
                                    // border="1px solid #30AB86"
                                    // boxShadow="none"
                                    // fontSize="14px"
                                    // fontWeight="bold"
                                >
                                    Schedule a Call
                                </ButtonNew>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="compare-section">
                    <div className="core-features-heading">Core Features</div>
                    <div className="core-features-para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor, malesuada mi
                        in, laoreet mi.
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TabComponent />
                    </div> */}
                    <div className="core-features">
                        <div className="core-feature-upper-div">
                            <div className="sub-card">
                                <div>
                                    <img src={insurance} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Better Coverage</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                            <div className="sub-card">
                                <div>
                                    <img src={costEffectiveImg} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Cost Effective</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                            <div className="sub-card">
                                <div>
                                    <img src={bestImg} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Best Practices</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                        </div>
                        <div className="core-feature-bottom-div">
                            <div className="sub-card">
                                <div>
                                    <img src={customerFirst} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Customer First</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                            <div className="sub-card">
                                <div>
                                    <img src={turnImg} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Flexible Plans</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                            <div className="sub-card">
                                <div>
                                    <img src={premimum} className="core-image" />
                                </div>
                                <p className="core-heading-insurance">Premium Service</p>
                                <p className="core-subheading-insurance">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                    malesuada mi in, laoreet mi.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel" style={{ marginTop: '-5px' }}>
                    <div className="heading2-insurance">We Care About Our Custemer’s Experience Too</div>
                    <div className="review">
                        <img src={review} className="review-image" />
                    </div>
                    <div className="carousel-div-insurance">
                        <Carousel
                      autoPlay={false}
                            NextIcon={<img src={ArrowForwardIcon} className="arrowIconInsurance" />}
                            PrevIcon={<img src={ArrowBackIcon} className="arrowIconInsurance" />}
                            indicators={false}
                            navButtonsWrapperProps={{
                                // Move the buttons to the bottom. Unsetting top here to override default style.
                                style: {
                                    bottom: 0,
                                    top: 'unset',
                                },
                                className: 'arrow-wrapper',
                            }}
                            navButtonsProps={{
                                // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                style: {
                                    backgroundColor: 'transparent',
                                    borderRadius: 0,
                                    marginTop: '100%',
                                },
                                className: 'arrows',
                            }}
                            navButtonsAlwaysVisible={true}
                       
                            animation="slide"
                        >
                            {carouselItems.map((item: any) => {
                                return (
                                    <div className="carousel-inner-insurance">
                                        {item.map((item2: any) => {
                                            return (
                                                <CarouselCard
                                                    avatar={item2.avatar}
                                                    name={item2.name}
                                                    title={item2.title}
                                                    rate={item2.rate}
                                                    desc={item2.desc}
                                                    className="card-insurance"
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>

                <div className="carousel-2" style={{ marginTop: '-5px' }}>
                    <div className="heading2-insurance">We Care About Our Custemer’s Experience Too</div>
                    <div className="review">
                        <img src={review} className="review-image" />
                    </div>
                    <div className="carousel-div-insurance">
                        <Carousel
                        autoPlay={false}
                            next={(active: any) => {
                                this.setState({ active: active });
                            }}
                            indicators={false}
                            navButtonsAlwaysVisible={false}
                            animation="slide"
                        >
                            {carouselItemsMedia.map((item2: any) => {
                                return (
                                    <CarouselCard
                                        avatar={item2.avatar}
                                        name={item2.name}
                                        title={item2.title}
                                        rate={item2.rate}
                                        desc={item2.desc}
                                        className="card-insurance"
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="slider">
                        {' '}
                        <Slider
                            min={0}
                            max={carouselItemsMedia.length}
                            step={this.state.active}
                            value={this.state.active}
                        />
                    </div>
                </div>

                <div className="questions-internet">
                    <div className="frequently-internet">Frequently Asked Questions</div>
                    {quesAns.map((item: any) => {
                        return (
                            <div className="accordion-internet">
                                <SimpleAccordion question={item.ques} answer={item.ans} />
                            </div>
                        );
                    })}
                </div>

                <div className="address-wrapper-insurance">
                    <div className="address">
                        <div className="heading-insurance">Get A Free Quote And Protect Your Awesome Family</div>
                        <div className="boxCard-insurance">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="Para1">Why do we ask for your address? </div>
                                <div className="iIcon-div">
                                    <img src={iIcon} width="15px" height="15px" />
                                </div>
                            </div>
                            {/* <div style={{ color: '#0066F5', textDecoration: 'underline', cursor: 'pointer',display:"flex",flexDirection:"row",marginBottom:"5px" }}>
                            <div ><GpsFixedIcon fontSize="small" /></div>
                            <div style={{marginLeft:"5px",fontSize:"14px"}}> Use my current location</div>
                        </div> */}
                            <div className="inputs-insurance">
                                <AutoComplete
                                    error={Boolean(this.state.streetError)}
                                    className="input1-insuranceLanding"
                                    value={this.state.street}
                                    onChange={e => {
                                        this.setState({ street: e });
                                    }}
                                    onSelect={this.handleSelect}
                                    placeholder="Street"

                                    // inputTitle="To"
                                />
                                {this.state.streetError ? (
                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                        {''}
                                        <small>{this.state.streetError}</small>
                                    </div>
                                ) : null}
                                <div className="innerInputs-insurance">
                                    <div className="div-input">
                                        {' '}
                                        <TextField
                                            error={Boolean(this.state.cityError)}
                                            className="input2-insuranceLanding"
                                            label="City"
                                            id="outlined-start-adornment"
                                            onChange={this.cityHandler}
                                            variant="outlined"
                                            value={this.state.city}
                                        />
                                        {this.state.cityError ? (
                                            <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                {''}
                                                <small>{this.state.cityError}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="div-input">
                                        <TextField
                                            error={Boolean(this.state.stateError)}
                                            id="outlined-select-currency"
                                            select
                                            className="input3-insuranceLanding"
                                            label="State"
                                            value={this.state.state}
                                            onChange={(e: any) => this.stateHandler(e)}
                                            // helperText="Please select your currency"
                                            variant="outlined"
                                            // disabled={this.state.state!==""}
                                        >
                                            {list.map((option: any) => (
                                                <MenuItem key={option.name} value={option.name}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.state.stateError ? (
                                            <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                {''}
                                                <small>{this.state.stateError}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="div-input">
                                        <TextField
                                            error={Boolean(this.state.zipcodeError)}
                                            className="input4-insuranceLanding"
                                            label="Zip code"
                                            id="outlined-start-adornment"
                                            onChange={this.zipcodeHandler}
                                            variant="outlined"
                                            value={this.state.zipcode}
                                        />
                                        {this.state.zipcodeError ? (
                                            <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                {''}
                                                <small>{this.state.zipcodeError}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {/* <FormControlLabel
                            label="I live in an apartment"
                            control={
                                <Checkbox
                                    color="primary"
                                    className="checkbox"
                                    checked={this.state.checkedG}
                                    onChange={this.handleChange}
                                    name="checkedG"
                                />
                            }
                        /> */}
                            <div className="last-button">
                                <Button
                                    marginTop="10px"
                                    width="120px"
                                    height="45px"
                                    fontSize="14px"
                                    onClick={() => this.tabChangeHandler(true)}
                                    backgroundColor="#30AB86"
                                >
                                    Get A Quote
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <InternetLandingFooter />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageInsurance);
