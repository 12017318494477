export const UPDATE_MOVE_PROPERTY_INFO = 'UPDATE_MOVE_PROPERTY_INFO';
export const UPDATE_MOVE_PROPERTY_INFO_SUCCESS = 'UPDATE_MOVE_PROPERTY_INFO_SUCCESS';
export const UPDATE_MOVE_PROPERTY_INFO_ERROR = 'UPDATE_MOVE_PROPERTY_INFO_ERROR';

export const UPDATE_MOVE_PERSONAL_INFO = 'UPDATE_MOVE_PERSONAL_INFO';
export const UPDATE_MOVE_PERSONAL_INFO_SUCCESS = 'UPDATE_MOVE_PERSONAL_INFO_SUCCESS';
export const UPDATE_MOVE_PERSONAL_INFO_ERROR = 'UPDATE_MOVE_PERSONAL_INFO_ERROR';

export const INSURANCE_QUOTE = 'INSURANCE_QUOTE';
export const INSURANCE_QUOTE_SUCCESS = 'INSURANCE_QUOTE_SUCCESS';
export const INSURANCE_QUOTE_ERROR = 'INSURANCE_QUOTE_ERROR';

export const INSURANCE_QUOTE_LIST = 'INSURANCE_QUOTE_LIST';
export const INSURANCE_QUOTE_LIST_SUCCESS = 'INSURANCE_QUOTE_LIST_SUCCESS';
export const INSURANCE_QUOTE_LIST_ERROR = 'INSURANCE_QUOTE_LIST_ERROR';

export const INSURANCE_APPOINTMENTS = 'INSURANCE_APPOINTMENTS';
export const INSURANCE_APPOINTMENTS_SUCCESS = 'INSURANCE_APPOINTMENTS_SUCCESS';
export const INSURANCE_APPOINTMENTS_ERROR = 'INSURANCE_APPOINTMENTS_ERROR';

export const INSURANCE_APPOINTMENTS_GET = 'INSURANCE_APPOINTMENTS_GET';
export const INSURANCE_APPOINTMENTS_GET_SUCCESS = 'INSURANCE_APPOINTMENTS_GET_SUCCESS';
export const INSURANCE_APPOINTMENTS_GET_ERROR = 'INSURANCE_APPOINTMENTS_GET_ERROR';
