import {
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_UPDATE,
    SIGNUP_UPDATE_ERROR,
    SIGNUP_UPDATE_SUCCESS,
    LANDING_MOVE_POST,
    LANDING_MOVE_POST_ERROR,
    LANDING_MOVE_POST_SUCCESS,
    LANDING_MOVE_POST_FOR_SECURITY,
    LANDING_MOVE_POST_FOR_SECURITY_ERROR,
    LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
    POST_GHOST_LOGIN,
    POST_GHOST_LOGIN_ERROR,
    POST_GHOST_LOGIN_SUCCESS,
    LANDING_MOVE_GET,
    LANDING_MOVE_GET_SUCCESS,
    LANDING_MOVE_GET_ERROR,
    LANDING_MOVE_RESET,
} from './actionTypes';
import {
    Signup,
    SignupRequest,
    SignupFailure,
    SignupFailurePayload,
    SignupSuccess,
    SignupSuccessPayload,
    SignupUpdate,
    SignupUpdateRequest,
    SignupUpdateFailure,
    SignupUpdateFailurePayload,
    SignupUpdateSuccess,
    SignupUpdateSuccessPayload,
    LandingMovePost,
    // LandingMovePostRequest,
    LandingMovePostFailure,
    LandingMovePostFailurePayload,
    LandingMovePostSuccess,
    LandingMovePostSuccessPayload,
    LandingMovePostForSecurity,
    LandingMovePostForSecurityRequest,
    LandingMovePostForSecurityFailure,
    LandingMovePostForSecurityFailurePayload,
    LandingMovePostForSecuritySuccess,
    LandingMovePostForSecuritySuccessPayload,
    GhostSignup,
    GhostSignupFailure,
    GhostSignupSuccess,
    GhostSignupFailurePayload,
    GhostSignupRequest,
    GhostSignupSuccessPayload,
    LandingMoveGet,
    LandingMoveGetSuccess,
    LandingMoveGetSuccessPayload,
    LandingMoveGetFailurePayload,
    LandingMoveGetFailure,
    LandingMoveReset,
    // LandingMoveGetRequest,
} from './types';

// SIGNUP
export const signup = (payload: SignupRequest): Signup => {
    return {
        type: SIGNUP,
        payload,
    };
};

export const signupSuccess = (payload: SignupSuccessPayload): SignupSuccess => {
    return {
        type: SIGNUP_SUCCESS,
        payload,
    };
};

export const signupFailure = (payload: SignupFailurePayload): SignupFailure => {
    return {
        type: SIGNUP_ERROR,
        payload,
    };
};

export const signupUpdate = (payload: SignupUpdateRequest): SignupUpdate => {
    return {
        type: SIGNUP_UPDATE,
        payload,
    };
};

export const signupUpdateSuccess = (payload: SignupUpdateSuccessPayload): SignupUpdateSuccess => {
    return {
        type: SIGNUP_UPDATE_SUCCESS,
        payload,
    };
};

export const signupUpdateFailure = (payload: SignupUpdateFailurePayload): SignupUpdateFailure => {
    return {
        type: SIGNUP_UPDATE_ERROR,
        payload,
    };
};

// Landing move
export const landingMovePost = (payload: any): LandingMovePost => {
    return {
        type: LANDING_MOVE_POST,
        payload,
    };
};

export const landingMovePostSuccess = (payload: LandingMovePostSuccessPayload): LandingMovePostSuccess => {
    return {
        type: LANDING_MOVE_POST_SUCCESS,
        payload,
    };
};

export const landingMovePostFailure = (payload: LandingMovePostFailurePayload): LandingMovePostFailure => {
    return {
        type: LANDING_MOVE_POST_ERROR,
        payload,
    };
};

export const landingMoveGet = (payload: any): LandingMoveGet => {
    return {
        type: LANDING_MOVE_GET,
        payload,
    };
};

export const landingMoveReset = (): LandingMoveReset => {
    return {
        type: LANDING_MOVE_RESET
    };
};

export const landingMoveGetSuccess = (payload: LandingMoveGetSuccessPayload): LandingMoveGetSuccess => {
    return {
        type: LANDING_MOVE_GET_SUCCESS,
        payload,
    };
};

export const landingMoveGetFailure = (payload: LandingMoveGetFailurePayload): LandingMoveGetFailure => {
    return {
        type: LANDING_MOVE_GET_ERROR,
        payload,
    };
};

// Landing move for security recommendation
export const landingMovePostForSecurity = (payload: LandingMovePostForSecurityRequest): LandingMovePostForSecurity => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY,
        payload,
    };
};

export const landingMovePostForSecuritySuccess = (
    payload: LandingMovePostForSecuritySuccessPayload,
): LandingMovePostForSecuritySuccess => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
        payload,
    };
};

export const landingMovePostForSecurityFailure = (
    payload: LandingMovePostForSecurityFailurePayload,
): LandingMovePostForSecurityFailure => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY_ERROR,
        payload,
    };
};

//ghost
export const ghostLogin = (payload: GhostSignupRequest): GhostSignup => {
    return {
        type: POST_GHOST_LOGIN,
        payload,
    };
};

export const ghostLoginSuccess = (payload: GhostSignupSuccessPayload): GhostSignupSuccess => {
    return {
        type: POST_GHOST_LOGIN_SUCCESS,
        payload,
    };
};

export const ghostLoginFailure = (payload: GhostSignupFailurePayload): GhostSignupFailure => {
    return {
        type: POST_GHOST_LOGIN_ERROR,
        payload,
    };
};
