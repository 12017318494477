import React from 'react';
import AnalyticsService from '../../utils/analytics-service';
import './podsFooter.scss';

const PodsFooter = () => {
    
    const handleLinkClicked = (link: string, name: string) => {
        AnalyticsService.buttonClicked({
            category_name: `Footer ${name} Link Clicked`,
            button_copy: name,
            page: 'Footer'
        });
        link && window.open(link, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="pods-footer">
            <div>
                <div className="pods-copyright-text">
                    <div>
                        Copyright © {new Date().getFullYear()} LiveEasy. All rights reserved.
                    </div>
                    <div className="pods-teram-privacy">
                        <span>
                            <a onClick={() => handleLinkClicked('https://www.moveeasy.com/terms-conditions', 'Terms & Conditions')}>
                                Terms & Conditions
                            </a>
                        </span>
                        <span>|</span>
                        <span className="pods-privacy">
                            <a onClick={() => handleLinkClicked('https://www.moveeasy.com/privacy-policy', 'Privacy')}>
                                Privacy
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <div className="pods-disclaimer-text">
                    PODS Enterprises, LLC (PODS) Disclaimer: This Site is operated by <a href='https://www.moveeasy.com/' target='_blank'> LiveEasy</a>. <a href='https://www.moveeasy.com/' target='_blank'> LiveEasy</a> is independently owned and operated and has no affiliation with PODS. Any transaction that you enter into with a vendor, merchant or other party listed on or linked from this Site is solely between you and that vendor, merchant or other party, and not with PODS. PODS does not warrant any offerings of any of these third parties, the content of their respective web sites (including this Site), and any product descriptions or any other content of any of these third parties. PODS does not assume any responsibility or liability for the actions, product, and content of all these third parties. You should carefully review their respective privacy statements and other conditions of use.
                </div>
            </div>
        </div>
    );
};

export default PodsFooter;
