import React, { Component } from 'react';

import './HomeWarrantyStep4.scss';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
import HomeWarrantyCard from './HomeWarrantyCard';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonNew from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
// import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';
import HomeWarantyViewDetails from '../../components/HomeWaranty/HomeWarantyViewDetails';
import WarrantySkeleton from '../../components/skeletonLoaders/warrantySkeleton/warrantySkeleton';
import { InputLabel } from '@material-ui/core';
import SelectInput from '../../components/atoms/SelectInput';
import NoPlanImage from '../../Assets/images/HomeWarranty/noPlanImage.svg';
import { popUpSegment } from '../../utils/utlis';

type initialProps = {
    history?: any;
    commonState?: any;
    commonAction?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
};
type initialState = {
    slug: any;
    homeWarrantyCardData: any;
    viewClicked: boolean;
    isDataArrived: boolean;
    new_size: string;
    year: string;
    typeOfHome: string;
    viewDetailsData: any;
    viewCardTitle: string;
    sellerCoverage: string;
    warrantyTermLength: string;
    planClicked: any;
    planSelected: any;
    viewCheckList: boolean;
    checkListData: any;
    termLengthOptions: any;
    termLengthOldConstruction: any;
    termLength: any;
    is_new_construction: boolean;
    showNoPlansPage: boolean;
    getPlan: boolean;
};
export class HomeWarrantyStep4 extends Component<initialProps, initialState> {
    state: initialState = {
        slug: null,
        viewCardTitle: '',
        homeWarrantyCardData: [],
        viewDetailsData: [],
        viewClicked: false,
        isDataArrived: true,
        new_size: '',
        year: '',
        typeOfHome: '',
        sellerCoverage: '',
        warrantyTermLength: '',
        planClicked: null,
        planSelected: null,
        viewCheckList: false,
        checkListData: [],
        is_new_construction: false,
        termLengthOptions: [
            { value: '1', label: '1' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
        ],
        termLengthOldConstruction: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
        ],
        termLength: { value: '1', label: '1' },
        showNoPlansPage: false,
        getPlan: true,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        if (
            this.props &&
            this.props.homeWarrantyState &&
            this.props.homeWarrantyState.getHomeWarrantyDetails &&
            this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
        ) {
            this.setState({
                warrantyTermLength:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                        : '',
                typeOfHome:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
                        : '',
                is_new_construction:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.is_new_construction
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.is_new_construction
                        : false,
                termLength: {
                    value:
                        this.props.homeWarrantyState.getHomeWarrantyDetails &&
                        this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            : '1',
                    label:
                        this.props.homeWarrantyState.getHomeWarrantyDetails &&
                        this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            : '1',
                },
            });
        } else {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        // if (
        //     nextProps &&
        //     nextProps.homeWarrantyState &&
        //     nextProps.homeWarrantyState.getHomeWarrantyDetails &&
        //     nextProps.homeWarrantyState.isGetHomeWarrantyDetails
        // ) {
        //     nextProps.homeWarrantyState.isGetHomeWarrantyDetails = false;
        //     currentState.warrantyTermLength =
        //         nextProps.homeWarrantyState.getHomeWarrantyDetails &&
        //         nextProps.homeWarrantyState.getHomeWarrantyDetails.term_length
        //             ? nextProps.homeWarrantyState.getHomeWarrantyDetails.term_length
        //             : '';
        //     currentState.typeOfHome =
        //         nextProps.homeWarrantyState.getHomeWarrantyDetails &&
        //         nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
        //             ? nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
        //             : '';
        // }

        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            // nextProps.commonState.move.map((item: any) => {
            currentState.isDataArrived = false;
            currentState.new_size =
                nextProps.commonState.move[0].property && nextProps.commonState.move[0].property.total_area_sq_ft;
            currentState.year =
                nextProps.commonState.move[0].property && nextProps.commonState.move[0].property.year_built;
            // currentState.typeOfHome =
            //     nextProps.commonState.move[0].property && nextProps.commonState.move[0].property.residence_type;
            // });
        }
        if (
            nextProps &&
            nextProps.homeWarrantyState &&
            nextProps.homeWarrantyState.getProducts &&
            nextProps.homeWarrantyState.isGetProducts
        ) {
            nextProps.homeWarrantyState.isGetProducts = false;
            let products: any = [];
            let viewCardTitle: any = [];
            nextProps.homeWarrantyState.getProducts.map(async (item: any) => {
                products.push({ ...item, isSelected: false });
                viewCardTitle.push(item.description);
            });
            // currentState.viewCardTitle = viewCardTitle;
            currentState.homeWarrantyCardData = products;
        }
        if (nextProps && nextProps.homeWarrantyState && nextProps.homeWarrantyState.error === 'api failed') {
            currentState.showNoPlansPage = true;
            currentState.homeWarrantyCardData = [];
        }

        // if (
        //     nextProps &&
        //     nextProps.homeWarrantyState &&
        //     nextProps.homeWarrantyState.getProductDetails &&
        //     nextProps.homeWarrantyState.isGetProductDetails
        // ) {
        //     nextProps.homeWarrantyState.isGetProductsDetails = false;
        //     let viewDetailsData: any = currentState.viewDetailsData;
        //     viewDetailsData.push(nextProps.homeWarrantyState.getProductDetails);
        //     currentState.viewDetailsData = viewDetailsData;
        //     currentState.viewClicked = true;
        // }

        if (currentState.typeOfHome && currentState.new_size && currentState.year && currentState.getPlan) {
            nextProps.homeWarrantyAction.getProducts({
                year_built: currentState.year,
                total_area: currentState.new_size ? parseInt(currentState.new_size) : 0,
                residence_type: currentState.typeOfHome,
            });
            currentState.getPlan = false;
        }
        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        if (
            prevProps.homeWarrantyState.updateHomeWarantyDetailsInfo !=
                this.props.homeWarrantyState.updateHomeWarantyDetailsInfo &&
            this.props.homeWarrantyState.updateHomeWarantyDetailsInfo &&
            this.props.homeWarrantyState.updateHomeWarantyDetailsInfo.message
        ) {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
            this.props.homeWarrantyAction.getProducts({
                year_built: this.state.year,
                total_area: this.state.new_size,
                residence_type: this.state.typeOfHome,
            });
        }

        if (
            prevProps.homeWarrantyState.getHomeWarrantyDetails != this.props.homeWarrantyState.getHomeWarrantyDetails &&
            this.props.homeWarrantyState.getHomeWarrantyDetails
        ) {
            //    nextProps.homeWarrantyState.isGetHomeWarrantyDetails = false;
            this.setState({
                warrantyTermLength:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                        : '',
                typeOfHome:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
                        : '',
                is_new_construction:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.is_new_construction
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.is_new_construction
                        : false,
                termLength: {
                    value:
                        this.props.homeWarrantyState.getHomeWarrantyDetails &&
                        this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            : '',
                    label:
                        this.props.homeWarrantyState.getHomeWarrantyDetails &&
                        this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            ? this.props.homeWarrantyState.getHomeWarrantyDetails.term_length
                            : '',
                },
            });
            // if (
            //     this.props.homeWarrantyState.getHomeWarrantyDetails &&
            //     this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type
            // ) {
            //     this.props.homeWarrantyAction.getProducts({
            //         year_built: this.state.year,
            //         total_area: this.state.new_size,
            //         residence_type: this.props.homeWarrantyState.getHomeWarrantyDetails.residence_type,
            //     });
            // }
        }
    }

    //    viewDetails = (item: any) => {
    //        this.setState({
    //            // viewClicked: true,
    //            viewCardTitle: item.description && item.description.split(':')[0],
    //            planClicked: item,
    //        });
    //        let payload: any = {
    //            product_id: item.id,
    //            residence_type: item.typeOfResidence,
    //        };
    //        this.props.homeWarrantyAction.getProductDetails(payload);
    //    };

    handleSelectPlanData = (index: number) => {
        let planSelected: any = [];
        let tempObject: any = this.state.homeWarrantyCardData[index];
        planSelected = tempObject;
        let optionalCoverage = tempObject.coverages && tempObject.coverages.optionalCoverages;
        this.setState({
            viewCheckList: true,
            checkListData: optionalCoverage,
            planSelected: planSelected,
        });

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Home Warranty',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Select Plan',
                    card: true,
                    card_title: planSelected.description,
                    card_type: 'Start page',
                    category_name: 'Home Warranty',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );

            popUpSegment(
                'Popup Viewed',
                'Home Warranty',
                brokerage,
                brokerage_key,
                agent_key,
                agent_name,
                "Select Optional Coverage (Add On's)",
                move_id,
            );
        }
    };

    proceed = () => {
        this.props.history.push({
            pathname: '/dashboard/home-warranty/thanku',
            state: {
                planSelected: this.state.planSelected,
                optionalCoverages: this.state.checkListData,
            },
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Thanku Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Proceed',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: true,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    skip = () => {
        let optionalCoverages: any = this.state.checkListData;
        optionalCoverages.map((item: any) => {
            item.isSelected = false;
        });
        this.props.history.push({
            pathname: '/dashboard/home-warranty/thanku',
            state: {
                planSelected: this.state.planSelected,
                optionalCoverages: optionalCoverages,
            },
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Thanku Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Skip',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: true,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    selectOptionalCoverage = (e: any, index: number) => {
        let checkListData: any = this.state.checkListData;
        let tempData: any = checkListData[index];
        tempData.isSelected = e.target.checked;
        checkListData[index] = tempData;
        this.setState({
            checkListData: checkListData,
        });
    };

    onClickCheckBox = (item: any) => {
        let cardData = this.state.homeWarrantyCardData;
        cardData.map((item1: any) => {
            if (item1.id === item.id) {
                item1.isSelected = !item1.isSelected;
            }
            return item1;
        });
        this.setState({ homeWarrantyCardData: cardData });
    };

    optionalAdd = (index: number) => {
        let data: any = this.state.viewDetailsData;
        let optionalData: any = data[0] && data[0].optionalCoverages;
        let temporaryData = optionalData[index];
        temporaryData.isChecked = temporaryData.isChecked ? false : true;
        optionalData[index] = temporaryData;
        data[0].optionalCoverages = optionalData;
        this.setState({ viewDetailsData: data });
    };

    ontermLengthSelectHandler = async (e: any, name: string) => {
        // const steps = { ...this.state, [name]: e.value };
        await this.setState(() => ({ ...this.state, [name]: e.value, homeWarrantyCardData: [] }));
        this.setState({
            termLength: e.value,
        });
        
        const payload = {
            term_length: e.value,
        };
        await this.props.homeWarrantyAction.updateHomeWarrantyDetailsInfo(payload);
    };

    validating = () => {
        var d = new Date();
        var n = d.getFullYear();
        return n;
    };
    // scroll = () => {
    //     const section = document.querySelector('#contact-us');
    //     section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // };
    myRef: any = React.createRef();
    myRefTop: any = React.createRef();

    handleBottom = () => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    handleTop = () => {
        // window.scrollTo(0, this.myRefTop.current.offsetTop);
        this.myRefTop.current.scrollIntoView({ behavior: 'smooth' });
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                <div className="warranty-main-page">
                    <div className="main-head-waranty-main">
                        <h2 className="heading" ref={this.myRefTop}>
                            Home Warranty
                        </h2>
                        <div className="pathWaranty">
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (
                                        localStorage.getItem('isHomeOwner') &&
                                        localStorage.getItem('isHomeOwner') == 'true'
                                    ) {
                                        this.props.history.push('/dashboardhomeOwner');
                                    } else {
                                        this.props.history.push('/dashboard');
                                    }
                                }}
                            >
                                Dashboard {'>'}
                            </p>{' '}
                            <p style={{ marginLeft: '5px' }}>Home Warranty</p>
                        </div>
                    </div>
                    {this.state.homeWarrantyCardData.length ? (
                        <div className="seeDetailedComparison" onClick={this.handleBottom}>
                            See Detailed Comparison
                        </div>
                    ) : null}
                    <div className="homewarrantymainpagediv">
                        <div className={isDouglasElliman ? 'dropBorderD' : 'dropBorder'}>
                            <div className="questionDiv">
                                <div className="addressFlexDivIconInput">
                                    <div className="warantySelectFilter">
                                        <div className="inputLabel">
                                            <InputLabel>Select Term Length</InputLabel>
                                        </div>
                                        <SelectInput
                                            // error={this.state.termLengthError != '' ? true : false}
                                            className="react-select alert-select"
                                            onChange={(e: any) => this.ontermLengthSelectHandler(e, 'termLength')}
                                            options={
                                                // parseInt(this.state.year) === this.validating()
                                                this.state.is_new_construction
                                                    ? this.state.termLengthOptions
                                                    : // : parseInt(this.state.year) < this.validating()
                                                      this.state.termLengthOldConstruction
                                                // : null
                                            }
                                            placeholder={this.state.termLength ? this.state.termLength : 'Term Length'}
                                            value={this.state.termLength ? this.state.termLength : ''}
                                            // label="Term Length"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="questionDiv">
                            <div className="question">Do you want to include seller's coverage?</div>
                            <div className="radioButtons">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="homeSeller"
                                        value={this.state.sellerCoverage}
                                        onChange={(e: any) => {
                                            this.setState({
                                                sellerCoverage: e.target.value,
                                            });
                                        }}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="no" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="questionDiv">
                            <div className="question">Warranty term length?</div>
                            <div className="radioButtons">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="homeSeller"
                                        value={this.state.warrantyTermLength}
                                        onChange={(e: any) => {
                                            this.setState({
                                                warrantyTermLength: e.target.value,
                                            });
                                        }}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="no" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="questionDiv">
                            <div className="question">Do you want to include A/C coverage?</div>
                            <div className="radioButtons">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="homeSeller" defaultValue="buyer" name="radio-buttons-group">
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="no" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div> */}

                            {/* {this.state.homeWarrantyCardData.length ? (
                            <div className="cardsDiv">
                                {this.state.homeWarrantyCardData &&
                                    this.state.homeWarrantyCardData.map((item: any) => {
                                        return (
                                            <HomeWarrantyCard
                                                title={
                                                    item.description && item.description.split(': ')[0]
                                                        ? item.description.split(': ')[0]
                                                        : item.description
                                                }
                                                name={
                                                    item.description && item.description.split(': ')[1]
                                                        ? item.description.split(': ')[1]
                                                        : item.description
                                                }
                                                planRate={item.basePriceWithoutTax}
                                                // isSelected={item.isSelected}
                                                viewDetails={() => this.viewDetails(item)}
                                                // onClickCheckBox={() => this.onClickCheckBox(item)}
                                            />
                                        );
                                    })}
                            </div>
                        ) : (
                            <div className="cardsDiv">
                                <WarrantySkeleton />
                                <WarrantySkeleton />
                                <WarrantySkeleton />
                            </div>
                        )} */}
                            {this.state.homeWarrantyCardData.length ? (
                                <div className="cardsDiv">
                                    {this.state.homeWarrantyCardData &&
                                        this.state.homeWarrantyCardData.map((item: any, index: number) => {
                                            return (
                                                <HomeWarrantyCard
                                                    title={item.description}
                                                    name={
                                                        item.description && item.description.split(': ')[1]
                                                            ? item.description.split(': ')[1]
                                                            : item.description
                                                    }
                                                    planRate={item.basePriceWithoutTax}
                                                    // isSelected={item.isSelected}
                                                    viewDetails={() => {}}
                                                    handleSelectPlanData={() => this.handleSelectPlanData(index)}
                                                    //    recommended={
                                                    //        item.description.includes('ShieldPlus') ? true : false
                                                    //    }
                                                    // itemValues={[
                                                    //     'Basic',
                                                    //     'Appliances',
                                                    //     'Additional Home Items',
                                                    //     'Special Members Offers',
                                                    // ]}
                                                />
                                            );
                                        })}
                                </div>
                            ) : this.state.showNoPlansPage ? (
                                <div className="noPlanMainDiv">
                                    {isDouglasElliman ? null : (
                                        <div className="noPlansImageDiv">
                                            <img src={NoPlanImage} className="noPlanImage" />
                                        </div>
                                    )}
                                    <div className="noPlansHeading">Sorry, no plans found!</div>
                                    <div className="noPlansSubHeading">
                                        Try selecting another term length and search again
                                    </div>
                                </div>
                            ) : (
                                <div className="cardsDiv">
                                    <WarrantySkeleton />
                                    <WarrantySkeleton />
                                    <WarrantySkeleton />
                                </div>
                            )}

                            {this.state.homeWarrantyCardData && this.state.homeWarrantyCardData.length ? (
                                <div className=" detailedPlanData">
                                    <div className="detailedHead" ref={this.myRef}>
                                        <div className="">Compare Plans, Side by Side</div>
                                        <div className="seeDetailedComparison" onClick={this.handleTop}>
                                            Go back top
                                        </div>
                                    </div>

                                    <div className="">
                                        {' '}
                                        <HomeWarantyViewDetails
                                            title={this.state.viewCardTitle}
                                            data={this.state.homeWarrantyCardData}
                                            onOptionalDataClick={this.optionalAdd}
                                            handleSelectPlanData={this.handleSelectPlanData}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {this.state.viewClicked ? (
                    <Modal
                        isShowModal={this.state.viewClicked}
                        onCloseModal={() => {
                            this.setState({ viewClicked: false });
                        }}
                        showClose={false}
                        className="viewDetailsModalWaranty"
                    >
                        <div className="modalTitleDiv">
                            <div className="modaltitleWaranty">
                                <ArrowBackIcon
                                    className="backIcon"
                                    onClick={() => this.setState({ viewClicked: false })}
                                />
                                Compare Coverage and Add-Ons
                            </div>
                            <div className="closeTextIconDivWaranty">
                                <ButtonNew
                                    className="submitButton"
                                    border={
                                        isDouglasElliman
                                            ? '1px solid #100B28'
                                            : slug && slug.primary_color
                                            ? `1px solid ${slug.primary_color}`
                                            : '1px solid #273E59'
                                    }
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    backgroundColor="#ffff"
                                    onClick={this.handleSelectPlanData}
                                >
                                    Select Plan
                                </ButtonNew>
                            </div>
                        </div>

                        <div
                            className={
                                isDouglasElliman ? 'homeWarantyCampModal homeWarantyCampModalD' : 'homeWarantyCampModal'
                            }
                        >
                            {/* <HomeWarantyViewDetails
                                title={this.state.viewCardTitle}
                                data={this.state.viewDetailsData && this.state.viewDetailsData[0]}
                                onOptionalDataClick={this.optionalAdd}
                            /> */}
                        </div>
                    </Modal>
                ) : null}
                {this.state.viewCheckList ? (
                    <Modal
                        isShowModal={this.state.viewCheckList}
                        onCloseModal={() => {
                            this.setState({ viewCheckList: false });
                        }}
                        showClose={true}
                        className="viewCheckListModal"
                    >
                        <div className="modalCheckTitleDiv">Select Optional Coverage (Add on's)</div>
                        {this.state.checkListData &&
                            this.state.checkListData.map((item: any, index: number) => {
                                return (
                                    <div className={index % 2 === 0 ? 'checkListGrey' : 'checkListWhite'}>
                                        <div className="checkListHead">
                                            {' '}
                                            <span className="checkListCheckbox">
                                                <Checkbox
                                                    checked={item.isSelected}
                                                    onChange={(e: any) => this.selectOptionalCoverage(e, index)}
                                                />
                                            </span>
                                            {item.description}
                                        </div>
                                        <div className="checkListPrice">${item.coveragePrice}</div>
                                    </div>
                                );
                            })}
                        <div className="checkListButton">
                            <ButtonNew
                                border={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `1px solid ${slug.primary_color}`
                                        : '1px solid #273E59'
                                }
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273E59'
                                }
                                backgroundColor="#ffff"
                                className="btnWaranty3"
                                onClick={this.skip}
                            >
                                Skip
                            </ButtonNew>
                            <ButtonNew
                                // onClick={() => {
                                //     this.props.history.push('/dashboard/home-warranty/thanku');
                                // }}
                                border={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `1px solid ${slug.primary_color}`
                                        : '1px solid #273E59'
                                }
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273E59'
                                }
                                backgroundColor="#ffff"
                                className="btnWaranty3"
                                onClick={this.proceed}
                            >
                                Proceed
                            </ButtonNew>
                        </div>
                    </Modal>
                ) : null}
                {/* <div className="moving-Footer">
                    <Footer />
                </div> */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarrantyStep4);
