import React from 'react';
import { connect } from 'react-redux';
import './standAloneTrustedPros.scss';
import { bindActionCreators } from 'redux';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import TrustedProsIconCard from '../../components/atoms/trustedProsIconCard';
// import arrowIcon from '../../Assets/images/TrustedPros/arrow_back_black_24dp.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as CommonAction from '../../store/common/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectInput from '../../components/atoms/SelectInput';
import serverAddress from '../../config';

type InitialProps = {
    trustedProsAction: any;
    trustedProsState: any;
    // onClickPopular ? :any
    // goBackHandler ? : any
    // vendorsOptions ? : any
    commonAction: any;
    commonState: any;
    history?: any;
    location?: any;
};
type InitialState = {
    slug: any;
    whatYouNeedHelp: any;
    address: string;
    isMoveDataArrived: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    moveId: any;
    popularService: any;
    loader: boolean;
    vendorsOptions: any;
    clickedId: any;
    clicked: boolean;
    clickedIndex: number;
    office: any;
    officeOptions: any;
    site_name: any;
};

class TrustedProsStandAlone extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: { primary_color: '' },
        whatYouNeedHelp: [],
        address: '',
        isMoveDataArrived: true,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        popularService: [],
        loader: true,
        vendorsOptions: [],
        clicked: false,
        clickedId: '',
        clickedIndex: 0,
        office: '',
        officeOptions: [],
        site_name: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        const domain = window.location.hostname;
        let name = domain.split('.');
        if (name[0]) {
            this.setState({ site_name: name[0] });
        }

        this.props.trustedProsAction.standAloneVendors({
            site_name: serverAddress.includes('conciergeapidemo') ? 'moveeasy-demo' : name[0],
        });
        this.props.trustedProsAction.standAloneOffices({
            site_name: serverAddress.includes('conciergeapidemo') ? 'moveeasy-demo' : name[0],
        });
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.zipcode = nextProps.commonState.move[0].destination.zip_code;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.city = nextProps.commonState.move[0].destination.city_name;
            currentState.address = nextProps.commonState.move[0].destination_full_address;
            currentState.state = {
                name: nextProps.commonState.move[0].destination.state_code,
                label: nextProps.commonState.move[0].destination.state_code,
            };
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.standAloneVendors &&
            nextProps.trustedProsState.standAloneVendors.length &&
            nextProps.trustedProsState.standAloneVendorsDataArrived
        ) {
            nextProps.trustedProsState.standAloneVendorsDataArrived = false;
            let data: any = [];

            nextProps.trustedProsState.standAloneVendors.map((item: any) => {
                data.push(item);
            });

            currentState.popularService = data;
            currentState.loader = false;
            currentState.vendorsOptions = nextProps.trustedProsState.standAloneVendors;
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.standAloneOffices &&
            nextProps.trustedProsState.standAloneOffices.length &&
            nextProps.trustedProsState.standAloneOfficesDataArrived
        ) {
            nextProps.trustedProsState.standAloneOfficesDataArrived = false;
            let data: any = [];

            nextProps.trustedProsState.standAloneOffices.map((item: any) => {
                data.push({
                    value: item.name,
                    label: item.label,
                });
            });

            currentState.officeOptions = data;
        }

        return currentState;
    }

    onSelectHandler = (e: any) => {
        this.setState({
            office: e,
        });
    };

    onClickPopularServices = (id: number, name: any, item: any) => {
        const payload = {
            category: id,
            site_name: serverAddress.includes('conciergeapidemo') ? 'moveeasy-demo' : this.state.site_name,
        };
        this.props.trustedProsAction.postStandAloneImpression(payload);
        this.props.history.push({
            pathname: '/standalonepagerecommendations',
            state: {
                selectedService: item,
                service_name: name,
                id: id,
                site_name: this.state.site_name,
            },
        });
    };

    //     onSelectVendors = async (e: any, ...params:any) => {
    //         console.log(params)
    //         // await this.setState({ whatYouNeedHelp: e.target.value });
    //         if(e.target.value.split(',')) {
    //             await this.setState({ whatYouNeedHelp: e.target.value.split(',') });
    //         } else {
    //             await this.setState({ whatYouNeedHelp: e.target.value });
    //         }

    //         let data: any = [];
    //         data.push(this.props.vendorsOptions.find((item: any) => item.name.includes(this.state.whatYouNeedHelp)));
    // console.log(data)
    //         // this.setState({ selectedVendorService: data });
    //     };

    handleChangeWhatYouNeedHelp = (e: any) => {
        if (e.target.value.split(',')) {
            this.setState({ whatYouNeedHelp: e.target.value.split(',') });
        } else {
            this.setState({ whatYouNeedHelp: e.target.value });
        }

        if (!e.target.value) {
            this.setState({ whatYouNeedHelp: [] });
        }
    };

    onChangeVendors = (_e: any, value: string[] | null) => {
        this.setState({ whatYouNeedHelp: value });
    };

    render() {
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
                : [];
        return (
            <div className="trustedPros-main-page-2">
                <div className="trustedPros-OrderConfirmPage-2">
                    <div className="main-head-2 ">
                        <h2 className="heading-standAlone">Choose Your Home Pros</h2>
                        <div className="path">
                            <p className="Para-standAlone">
                                Explore the different services and trusted pros for your home improvement project
                            </p>
                        </div>
                        <div className="search-address-div">
                            <div className="helpInputAllService-2">
                                <Autocomplete
                                    id="free-solo-demo"
                                    // freeSolo
                                    multiple
                                    //  onSelect={this.onSelectVendors}
                                    onChange={(e: any, value: string[] | null) => this.onChangeVendors(e, value)}
                                    options={this.state.vendorsOptions}
                                    getOptionLabel={(option: any) => option.name}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            className="trustedProsInput"
                                            label="Searching All Services"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                            variant="outlined"
                                            value={this.state.whatYouNeedHelp}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={this.state.whatYouNeedHelp.length ? '' : 'I need help with'}
                                        />
                                    )}
                                />
                            </div>

                            <div className="selectInput-div-2">
                                <SelectInput
                                    className="standalone"
                                    onChange={(e: any) => this.onSelectHandler(e)}
                                    options={this.state.officeOptions}
                                    placeholder={this.state.office ? this.state.office : 'Select Office'}
                                    value={this.state.office}
                                    label="Select Office"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="MostPopularServiceDiv-2">
                    <div
                        className="PopularCardsDiv-2"
                        style={{ background: this.state.popularService.length ? '#f5faf8' : '#fff' }}
                    >
                        {this.state.loader == true ? (
                            <div style={{ margin: '0 auto' }}>
                                {' '}
                                <CircularProgress />{' '}
                            </div>
                        ) : null}
                        {this.state.whatYouNeedHelp.length == 0
                            ? this.state.popularService.map((item: any) => {
                                  return item.name ? (
                                      <TrustedProsIconCard
                                          className="popularCard"
                                          icon={item.image ? item.image : ''}
                                          title={item.name ? item.name : ''}
                                          onClick={() => {
                                              this.onClickPopularServices(item.id, item.name, item);
                                          }}
                                      />
                                  ) : (
                                      item
                                  );
                              })
                            : this.state.whatYouNeedHelp.map((item: any) => {
                                  // this.state.whatYouNeedHelp.length && this.state.whatYouNeedHelp.filter((whatYouNeedHelp :any) => {
                                  // if(whatYouNeedHelp.name.match(item.name)){
                                  // console.log(whatYouNeedHelp, item)
                                  return (
                                      // <div>hh</div>
                                      <TrustedProsIconCard
                                          className="popularCard"
                                          icon={item.image ? item.image : ''}
                                          title={item.name ? item.name : ''}
                                          onClick={() => {
                                              this.onClickPopularServices(item.id, item.name, item);
                                          }}
                                      />
                                  );
                                  //    }
                                  //  })
                              })}
                    </div>
                    <div className="mortgage-last-div">
                        <div className="mortgage-pleasenote">Please Note:</div>
                        <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                            {displayData && displayData[0] && displayData[0].disclaimer ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: displayData && displayData[0] && displayData[0].disclaimer,
                                    }}
                                />
                            ) : (
                                'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    trustedProsState: state.trustedPros,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsStandAlone);
