import * as React from 'react';
import { navigationIconColorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 52.801}
            height={props.height ? props.height : 29.47}
            viewBox="0 0 52.801 29.47"
        >
            <defs>
                <style>
                    {/* {".prefix__c{fill:#6b6c6f}"} */}
                    {`.homeSecuritySvgprefix__c{
                        fill:${ navigationIconColorSelector(props.active, "a", "stroke")} !important
                    }.homeSecuritySvgprefix__b{
                        fill:${navigationIconColorSelector(props.active, "a", "fill")} !important
                    }`}
                </style>
            </defs>
            <path
                d="M33.932 6.585l2.8 2.036.3.219v19.989h-21.26V8.939l-.047-.064 10.676-7.778zm0 0"
                // fill="#bebebe"
                className="homeSecuritySvgprefix__b"
            />
            <path d="M37.029 2.691v6.154l-.3-.219-2.8-2.036V.645zm0 0" fill="#fff" />
            <path
                className="homeSecuritySvgprefix__c"
                d="M43.665 24a.748.748 0 001.058 0 10.664 10.664 0 000-15.069.748.748 0 10-1.059 1.058 9.167 9.167 0 010 12.954.748.748 0 00.001 1.057zm0 0"
            />
            <path
                className="homeSecuritySvgprefix__c"
                d="M47.172 5.423a.748.748 0 000 1.058 14.127 14.127 0 010 19.968.748.748 0 101.058 1.058 15.623 15.623 0 000-22.083.748.748 0 00-1.058-.001zm0 0M9.135 8.93a.748.748 0 00-1.058 0 10.664 10.664 0 000 15.069.748.748 0 001.059-1.058 9.168 9.168 0 010-12.955.748.748 0 00-.001-1.056zm0 0"
            />
            <path
                className="homeSecuritySvgprefix__c"
                d="M5.101 27.725a.748.748 0 00.529-1.277 14.127 14.127 0 010-19.968.75.75 0 10-1.06-1.06 15.623 15.623 0 000 22.083.745.745 0 00.531.222zm0 0M33.628.076a.642.642 0 00-.338.565v4.684L26.778.584a.642.642 0 00-.755 0l-15.03 10.942a.641.641 0 10.755 1.037l3.383-2.463v18.728a.641.641 0 00.641.641h21.257a.641.641 0 00.641-.641V10.101l3.383 2.463a.641.641 0 00.755-1.037L37.67 8.515V2.691a.641.641 0 00-.287-.534l-3.1-2.05a.642.642 0 00-.655-.031zm2.76 28.111H16.413V9.166L26.4 1.894l9.987 7.272zm0-25.151V7.58l-1.815-1.321V1.835zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
