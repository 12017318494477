import React from 'react';
import moveEasy from '../../Assets/images/moveEasy.png';
import './NavbarLanding.css';



const NavbarLanding = () => {
  

    return (
        <div >
             <div className="landingNavbar">
                        <div className="logo-container-insurance">
                           <img src={moveEasy} />
                        </div>
                        </div>
           
        </div>
    );
};

export default NavbarLanding;
