import React, { Component } from 'react';
import './utility.scss';
import './utilityPowerSaving.scss';
import SuitablePlansCards from '../../components/Utility/SuitablePlansCard';
// import constilation from '../../Assets/images/utilityImages/constilation.png';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PowerKioskCard from '../../components/Utility/PowerKioskCard';
import MonthlyUsageCard from '../../components/Utility/MonthlyUsageCard';
import FiltersUtility from '../../components/Utility/utilityFilter';
import * as EnergyAction from '../../store/energy/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../components/atoms/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '../../components/atoms/Button';
import { PowerKioskCardSkeleton } from '../../components/skeletonLoaders/EnergySkeleton/PowerKioskCardSkeleton';
// import { SuitablePlanCardSkeleton } from '../../components/skeletonLoaders/EnergySkeleton/SuitablePlanCardSkeleton';
import NoPlanImage from '../../Assets/images/HomeWarranty/noPlanImage.svg';
import { powerkiosImageUrl } from '../../config';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
};
type initialState = {
    slug: any;
    sort: any;
    AnchorEl?: any;
    planDataPowerKiosk: any;
    time: any;
    suppliers: any;
    serviceTypeId: any;
    selectedCompany: any;
    usage: number;
    viewDetailsData: any;
    viewDetailsModal: boolean;
    accessToken: string;
    serviceTypeName: string;
    viewFilterModal: boolean;
    showNoPlans: boolean;
};

export class utilityPowerSaving extends Component<initialProps, initialState> {
    state: initialState = {
        slug: { primary_color: '' },
        sort: { value: 'LH', label: 'Low to High' },
        AnchorEl: null,
        planDataPowerKiosk: [],
        time: [
            { name: 'One to two years ', value: false },
            { name: 'Two years or more', value: false },
        ],
        suppliers: [
            // { name: 'Major Energy', value: false },
            // { name: 'Verde Energy USA ', value: false },
            // { name: '  Public Power', value: false },
            // { name: 'Constellation', value: false },
        ],
        serviceTypeId: '',
        selectedCompany: [],
        usage: 120,
        viewDetailsData: null,
        viewDetailsModal: false,
        accessToken: '',
        serviceTypeName: '',
        viewFilterModal: false,
        showNoPlans: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        this.props.energyAction.getAccessToken();

        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            if (this.props.history.location.state.selectedPlan) {
                let plan: any = this.state.planDataPowerKiosk;
                plan &&
                    plan.map((item: any) => {
                        if (item.planId === this.props.history.location.state.selectedPlan.planId) {
                            item.isSelected = true;
                        }
                    });
                this.setState({ planDataPowerKiosk: plan });
            }
            this.setState({
                serviceTypeId:
                    this.props.history.location.state && this.props.history.location.state.serviceTypeId
                        ? this.props.history.location.state.serviceTypeId
                        : '',
                serviceTypeName:
                    this.props.history.location.state && this.props.history.location.state.serviceTypeName
                        ? this.props.history.location.state.serviceTypeName
                        : '',
                selectedCompany:
                    this.props.history.location.state && this.props.history.location.state.selectedCompany
                        ? this.props.history.location.state.selectedCompany
                        : [],
            });
            let payload: any = {
                service_type_id:
                    this.props.history.location.state && this.props.history.location.state.serviceTypeId
                        ? this.props.history.location.state.serviceTypeId
                        : '',
                state_id:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0] &&
                    this.props.history.location.state.selectedCompany[0].stateId
                        ? this.props.history.location.state.selectedCompany[0].stateId
                        : '',
                utility_id:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0] &&
                    this.props.history.location.state.selectedCompany[0].utilityId
                        ? this.props.history.location.state.selectedCompany[0].utilityId
                        : '',
                usage: this.state.usage,
                zone:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0] &&
                    this.props.history.location.state.selectedCompany[0].zone
                        ? this.props.history.location.state.selectedCompany[0].zone
                        : '',
            };
            this.props.energyAction.getEnergyPlan(payload);
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getAccessToken != curEnergy.getAccessToken &&
            curEnergy.getAccessToken &&
            curEnergy.getAccessToken.token &&
            curEnergy.isGetAccessToken
        ) {
            curEnergy.isGetAccessToken = false;
            this.setState({ accessToken: curEnergy.getAccessToken.token });
        }
        if (
            prevEnergy.getEnergyPlan != curEnergy.getEnergyPlan &&
            curEnergy.getEnergyPlan.rates &&
            curEnergy.getEnergyPlan.rates.message &&
            curEnergy.isGetEnergyPlan
        ) {
            curEnergy.isGetEnergyPlan = false;
            let planCardData: any = [];
            let suppliers: any = [];
            curEnergy.getEnergyPlan.rates &&
                curEnergy.getEnergyPlan.rates.message &&
                curEnergy.getEnergyPlan.rates.message.map(async (item: any, index: number) => {
                    suppliers.push(item.name);
                    planCardData.push({
                        ...item,
                        indexId: index + 1,
                        isSelected: false,
                        isViewMore: false,
                        logo: `${powerkiosImageUrl}${item.logo}?access_token=${this.state.accessToken}`,
                    });

                    return planCardData;
                });
            suppliers = new Set(suppliers);
            let supp: any = [];
            suppliers.forEach((item: any) => {
                supp.push({ name: item, value: false });
            });

            this.setState({ planDataPowerKiosk: planCardData, suppliers: supp, showNoPlans: true });
        }
    }

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };
    handleHTL = () => {};
    handleLTH = () => {};
    timeChangeHandler = (index: any) => {
        let time: any = this.state.time;
        let tempObject = time[index];
        tempObject.value = !tempObject.value;
        time[index] = tempObject;
        time.map((item: any) => {
            if (item.name !== tempObject.name) {
                item.value = false;
            }
        });

        this.setState({
            time: time,
        });
    };
    suppliersChangeHandler = (index: any) => {
        let suppliers: any = this.state.suppliers;
        let tempObject = suppliers[index];
        tempObject.value = !tempObject.value;
        suppliers[index] = tempObject;

        suppliers.map((item: any) => {
            if (item.name !== tempObject.name) {
                item.value = false;
            }
        });

        this.setState({
            suppliers: suppliers,
        });
    };

    selectPlanHandler = (item: any) => {
        let plans = this.state.planDataPowerKiosk;
        plans &&
            plans.map((plan: any) => {
                if (item.planId == plan.planId && item.indexId == plan.indexId) {
                    plan.isSelected = !plan.isSelected;
                }
            });

        this.setState({ planDataPowerKiosk: plans });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Card Selection Clicked',
                {
                    page: 'Your Moving Checklist',
                    category_name: 'Utilites',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card: true,
                    card_title: item && item.name,
                    card_type: 'Plans are optimized based on average usage',
                },
                { user_id: move_id },
            );
        }
        this.props.history.push({
            pathname: '/dashboard/utilities/utilitiesSavings',
            state: {
                ...this.props.history.location.state,
                serviceTypeId: this.state.serviceTypeId,
                serviceTypeName: this.state.serviceTypeName,
                selectedCompany: this.state.selectedCompany,
                usage: this.state.usage,
                selectedPlan: item,
            },
        });
    };

    viewDetailsHandler = (item: any) => {
        this.setState({ viewDetailsModal: true, viewDetailsData: item });
    };

    viewMoreClick = (item: any) => {
        let plans = this.state.planDataPowerKiosk;
        plans &&
            plans.map((plan: any) => {
                if (item.planId == plan.planId && item.indexId == plan.indexId) {
                    plan.isViewMore = !plan.isViewMore;
                }
            });

        this.setState({ planDataPowerKiosk: plans });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        let planDataPowerKioskinner: any = [];
        let filterPresent: boolean = false;
        this.state.time.map((item2: any) => {
            if (item2.value) {
                filterPresent = true;
                this.state.planDataPowerKiosk.map((item: any) => {
                    if (item2.name == 'One to two years' && item.term < 24) {
                        planDataPowerKioskinner.push(item);
                    } else if (item2.name == 'Two years or more' && item.term >= 24) {
                        planDataPowerKioskinner.push(item);
                    }
                });
            }
        });
        let supplierName: string = '';
        let time: string = '';
        // this.state.planDataPowerKiosk.map((item: any) => {
        this.state.suppliers.map((item2: any) => {
            if (item2.value) {
                filterPresent = true;
                supplierName = item2.name;

                // if (item.name == item2.name) {
                //     planDataPowerKioskinner.push(item);
                // }
            }
        });
        this.state.time.map((item2: any) => {
            if (item2.value) {
                filterPresent = true;
                time = item2.name;
                // if (item2.name == 'One to two years' && item.term < 24) {
                //     planDataPowerKioskinner.push(item);
                // } else if (item2.name == 'Two years or more' && item.term >= 24) {
                //     planDataPowerKioskinner.push(item);
                // }
            }
        });
        // });
        if (supplierName != '' && time != '') {
            this.state.planDataPowerKiosk.map((item: any) => {
                if (item.name == supplierName) {
                    if (time == 'One to two years' && item.term < 24) {
                        planDataPowerKioskinner.push(item);
                    } else if (time == 'Two years or more' && item.term >= 24) {
                        planDataPowerKioskinner.push(item);
                    }
                }
            });
        }

        if (supplierName != '' && time == '') {
            this.state.planDataPowerKiosk.map((item: any) => {
                if (item.name == supplierName) {
                    planDataPowerKioskinner.push(item);
                }
            });
        }
        if (supplierName == '' && time != '') {
            this.state.planDataPowerKiosk.map((item: any) => {
                if (time == 'One to two years' && item.term < 24) {
                    planDataPowerKioskinner.push(item);
                } else if (time == 'Two years or more' && item.term >= 24) {
                    planDataPowerKioskinner.push(item);
                }
            });
        }
        let planDataPowerKiosk: any = this.state.planDataPowerKiosk;

        if (filterPresent) {
            planDataPowerKiosk = planDataPowerKioskinner;
        }

        let topData: any = [];
        let bottomData: any = [];

        topData = planDataPowerKiosk && planDataPowerKiosk.slice(0, 3);
        bottomData = planDataPowerKiosk && planDataPowerKiosk.slice(3);

        return (
            <div>
                <div className="utilitymain-head">
                    <div>
                        <h2 className="heading">Power Saving</h2>
                        <div className="path">
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (
                                        localStorage.getItem('isHomeOwner') &&
                                        localStorage.getItem('isHomeOwner') == 'true'
                                    ) {
                                        this.props.history.push('/dashboardhomeOwner');
                                    } else {
                                        this.props.history.push('/dashboard');
                                    }
                                }}
                            >
                                Dashboard {'>'}
                            </p>
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.props.history.push('/dashboard/utilities');
                                }}
                            >
                                {/* Utility Providers {'>'} */}
                                Utility Providers {'>'}
                            </p>{' '}
                            <p style={{ marginLeft: '5px' }}>Power Saving</p>
                        </div>
                        <div className="steps1UtilityPowerSaving">
                            <div className="steps1UtilityPowerSavingMain">
                                <div className="utilityPowerSavingLeft">
                                    <div className="utilityPowerSavingLeftHead">
                                        <div className="utilityPowerSavingHead">
                                            Plans are optimized based on average usage
                                        </div>

                                        <div
                                            className="mobileFilterOpeningModal"
                                            onClick={() => {
                                                this.setState({ viewFilterModal: true });
                                            }}
                                        >
                                            Filters
                                        </div>
                                    </div>
                                    <div className="">
                                        <MonthlyUsageCard
                                            monthlyAverage={1024}
                                            kwhCost={13.37}
                                            monthlyBill={2433.86}
                                            address={
                                                this.state.selectedCompany &&
                                                this.state.selectedCompany[0] &&
                                                this.state.selectedCompany[0].utility_name
                                            }
                                            editButtonClick={() => {
                                                this.props.history.push({
                                                    pathname: '/dashboard/energy-savings/energySteps',
                                                    state: {
                                                        ...this.props.history.location.state,
                                                        serviceTypeId: this.state.serviceTypeId,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>

                                    {this.state.planDataPowerKiosk && this.state.planDataPowerKiosk.length ? (
                                        <div className="powerKiosCards">
                                            {topData &&
                                                topData.map((item: any) => {
                                                    return (
                                                        <PowerKioskCard
                                                            disclaimerPath={item.disclaimerPath}
                                                            isViewMore={item.isViewMore}
                                                            viewMoreClick={() => this.viewMoreClick(item)}
                                                            logo={item.logo}
                                                            title={item.name}
                                                            perKwhValue={item.displayRate}
                                                            contract={item.term}
                                                            savings={item.savings}
                                                            cancelationFee={item.cancellationPenaltyDesc}
                                                            viewDetails={() => this.viewDetailsHandler(item)}
                                                            handleSelectPlanPower={() => this.selectPlanHandler(item)}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    ) : this.state.planDataPowerKiosk &&
                                      this.state.planDataPowerKiosk.length == 0 &&
                                      this.state.showNoPlans ? (
                                        <div className="noPlanMainDiv">
                                            <div className="noPlansImageDiv">
                                                <img src={NoPlanImage} className="noPlanImage" />
                                            </div>
                                            <div className="noPlansHeading">Sorry, no plans/suppliers found!</div>
                                        </div>
                                    ) : (
                                        <div className="powerKiosCards">
                                            <PowerKioskCardSkeleton />
                                            <PowerKioskCardSkeleton />
                                            <PowerKioskCardSkeleton />
                                        </div>
                                    )}
                                    {bottomData.length ? (
                                        <div className="otherElectricityPlans">
                                            <div className="utilityPowerSavingHead">
                                                Other suitable electricity plans{' '}
                                                <span className="planDataLengthPwer">({bottomData.length})</span>
                                            </div>
                                            <div className="sortByDiv">
                                                <div style={{ display: 'flex' }}>
                                                    <div
                                                        className="avg-customer-review"
                                                        onClick={this.handleMenu}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        Sort by: <span>{this.state.sort && this.state.sort.label}</span>
                                                    </div>
                                                    <ExpandMoreIcon />
                                                </div>
                                                <Menu
                                                    className="InsuranceDrop"
                                                    id="simple-menu"
                                                    anchorEl={this.state.AnchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.AnchorEl)}
                                                    onClose={this.handleClose}
                                                >
                                                    <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                                    <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                                </Menu>
                                            </div>
                                        </div>
                                    ) : null}

                                    {this.state.planDataPowerKiosk && this.state.planDataPowerKiosk.length ? (
                                        <div className="planDataPowerKiosk">
                                            {bottomData.map((item: any) => {
                                                return (
                                                    <SuitablePlansCards
                                                        disclaimerPath={item.disclaimerPath}
                                                        isViewMore={item.isViewMore}
                                                        viewMoreClick={() => this.viewMoreClick(item)}
                                                        logo={item.logo}
                                                        onSelectClick={() => this.selectPlanHandler(item)}
                                                        KwhValue={item.displayRate}
                                                        contractTermValue={item.term}
                                                        cancelationFee={item.cancellationPenaltyDesc}
                                                        viewDetails={() => this.viewDetailsHandler(item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div>
                                            {/* <SuitablePlanCardSkeleton />
                                            <SuitablePlanCardSkeleton /> */}
                                        </div>
                                    )}
                                </div>
                                <div className="utilityPowerSavingRight">
                                    <div className="utilityPowerSavingHead marginFilter">Filter</div>
                                    <div className="">
                                        {' '}
                                        <FiltersUtility
                                            time={this.state.time}
                                            suppliers={this.state.suppliers}
                                            changeTime={this.timeChangeHandler}
                                            changeSupplier={this.suppliersChangeHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.viewDetailsModal ? (
                    <Modal
                        isShowModal={this.state.viewDetailsModal}
                        onCloseModal={() => {
                            this.setState({ viewDetailsModal: false });
                        }}
                        showClose={false}
                        className="viewPlanDetailsEnergyModal"
                    >
                        <div className="modalmaindiv">
                            <div className="backButtonDiv">
                                <ArrowBackIcon
                                    className="backicon"
                                    onClick={() => this.setState({ viewDetailsModal: false })}
                                />{' '}
                                Back
                            </div>
                            <div className="flexDivModal">
                                <div className="image">
                                    <img src={this.state.viewDetailsData.logo} />
                                </div>
                                <div className="kwhHour">
                                    <div className="topKwhHourdiv">
                                        <span style={{ fontWeight: 'bold' }}>Assuming</span>{' '}
                                        <span style={{ color: '#006DFF' }}>1,204</span> kWh/monthly
                                    </div>
                                    <div className="middleKwhHourdiv">{this.state.viewDetailsData.displayRate}</div>
                                    <div className="bottomKwhHourdiv">per kWh</div>
                                </div>
                            </div>
                            <div className="aboutDiv">
                                <div className="aboutTitleDiv">
                                    About The Plan <span className="recommended">Recommended</span>
                                </div>

                                <div className="aboutData">
                                    100% Renewable energy with no cancellation fee, no monthly service fee, and a fixed
                                    rate for 24 months. Don’t wait - take advantage and sign up today! This 24-month
                                    electricity plan from Major Energy is available to residential customers of Con
                                    Edison in New York.
                                </div>
                            </div>

                            <div className="aboutDiv">
                                <div className="aboutTitleDiv">Plan Pricing</div>
                                <div
                                    className="planMonthlyrateFlexDiv"
                                    style={{
                                        borderBottom: '1px solid #E2E2E2',
                                        paddingBottom: '15px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <div className="planMonthlyrateDiv">Monthly kWh</div>
                                    <div className="planMonthlyrateDiv">500 kWh</div>
                                    <div className="planMonthlyrateDiv">1000 kWh</div>
                                    <div className="planMonthlyrateDiv">2000 kWh</div>
                                </div>
                                <div className="planMonthlyrateFlexDiv">
                                    <div className="planMonthlyrateDiv">Rate per kWh</div>
                                    <div className="planMonthlyrateDiv">8.99¢</div>
                                    <div className="planMonthlyrateDiv">8.99¢</div>
                                    <div className="planMonthlyrateDiv">8.99¢</div>
                                </div>
                            </div>

                            <div className="planFlexDiv">
                                <div className="planflexDivInside">
                                    <div className="title">Per kWh</div>
                                    <div className="data">{this.state.viewDetailsData.displayRate}</div>
                                </div>
                                <div className="planflexDivInside">
                                    <div className="title">Terms</div>
                                    <div className="data">{`${this.state.viewDetailsData.term} Month Fixed`}</div>
                                </div>
                                <div className="planflexDivInside">
                                    <div className="title">Cancellation Fee</div>
                                    <div className="data">{this.state.viewDetailsData.cancellationPenaltyDesc}</div>
                                </div>
                                <div className="planflexDivInside">
                                    <div className="title">Distributor</div>
                                    <div className="data">
                                        {this.state.selectedCompany &&
                                            this.state.selectedCompany[0] &&
                                            this.state.selectedCompany[0].utility_name}
                                    </div>
                                </div>
                                <div className="planflexDivInside">
                                    <div className="title">Plan Type</div>
                                    <div className="data">{this.state.viewDetailsData.billingMethod}</div>
                                </div>
                                <div className="planflexDivInside">
                                    <div className="title">Plan Docs</div>
                                    <div
                                        className="data"
                                        onClick={() => window.open(this.state.viewDetailsData.tosPath, '_blank')}
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Consumer Bill of Rights Terms & Conditions
                                    </div>
                                </div>
                            </div>

                            <div className="buttonDivUtilityPlan">
                                <Button
                                    className="submitButtonFilterUtility"
                                    onClick={() => this.selectPlanHandler(this.state.viewDetailsData)}
                                >
                                    Select Plan
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
                {this.state.viewFilterModal ? (
                    <Modal
                        isShowModal={this.state.viewFilterModal}
                        onCloseModal={() => {
                            this.setState({ viewFilterModal: false });
                        }}
                        showClose={false}
                        className="viewFiltersUtilityModal"
                    >
                        <div className="modalmaindivFilter">
                            <div className="backButtonFilterDiv">
                                <ArrowBackIcon
                                    className="backicon"
                                    onClick={() => this.setState({ viewFilterModal: false })}
                                />{' '}
                                Filters
                            </div>
                            <div className="filterMobileModalUtility">
                                <FiltersUtility
                                    time={this.state.time}
                                    suppliers={this.state.suppliers}
                                    changeTime={(item: any) => {
                                        this.timeChangeHandler(item);
                                    }}
                                    changeSupplier={(item: any) => {
                                        this.suppliersChangeHandler(item);
                                    }}
                                />
                            </div>
                            <Button
                                // color={'#fff'}
                                // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                className="submitButtonFilterUtility"
                                // onClick={() => console.log()}
                            >
                                Apply Filters
                            </Button>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(utilityPowerSaving);
