import React, { useEffect, useState } from 'react'
import './podsFaqPage.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import AnalyticsService from '../../utils/analytics-service';

const PodsFaqPage = (props: any) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [slug, setSlug] = useState<any>();

    useEffect(() => {
        let getSlug: any = localStorage.getItem('slug')
        setSlug(getSlug ? JSON.parse(getSlug) : '');
        AnalyticsService.pageViewed({ page: "FAQ" });

        return () => { }
    }, [])


    const handleChange =
        (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
           
            isExpanded && AnalyticsService.cardViewed({
                page: 'FAQ',
                category_name: 'FAQ',
                cardTitle: questions[panel]
            })

            setExpanded(isExpanded ? panel : false);
        };

    function createData(provider: any, customeServies: any, preInstall: any) {
        return { provider, customeServies, preInstall };
    }

    const rows = [
        createData('ATT', '800-288-2020', ''),
        createData('CenturyLink', '800-244-1111', ''),
        createData('Comcast/Xfinity', '800-266-2278', '877-561-9475'),
        createData('Dish Network', '800-333-3474', ''),
        createData('Cox Communication', '800-234-3993', ''),
        createData('Verizon ', '800-837-4966', ''),
        createData('Frontier', '800-921-8101', '877-814-8979'),
        createData('Spectrum', '800-236-1704', ''),
        createData('Mediacom', '888-500-4847', ''),
        createData('Optimum', '800-972-5757', ''),
    ];

    const questions: any = {
        panel1: 'What is PODS Move in Services?',
        panel2: 'Why use PODS Move In Services?',
        panel3: 'What can I do on the PODS Move In Services platform?',
        panel4: 'Why get Insurance through PODS Move In Services?',
        panel5: 'Why get Internet and TV through PODS Move In Services?',
        panel6: 'What if I need to make changes or cancel an existing order I already placed through PODS Move In Services?',
        panel7: "What makes PODS Move In Services' Home Security and Automation offer Unique?"
    }

    return (
        <div>
            <div className="main-head-faq">
                <h2 className="heading-faq-regular" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>FAQ</h2>
                <div className="path-faq">
                    <p
                        className="Para-faq"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (
                                localStorage.getItem('isHomeOwner') &&
                                localStorage.getItem('isHomeOwner') == 'true'
                            ) {
                                props.history.push('/dashboardhomeOwner');
                            } else {
                                props.history.push('/dashboard');
                            }
                        }}
                    >
                        Dashboard {'>'}
                    </p>{' '}
                    <p style={{ marginLeft: '5px' }}>FAQ</p>
                </div>
            </div>
            <div className='faq-section'>
                <div className='faq-header'>
                    <span style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>Frequently Asked Questions</span>
                </div>
                <div className='accordion-section'>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel1' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel1' && 'accordion-title-color'}`}>{questions.panel1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='accordion-text'>
                                PODs Move In Services is a complimentary all-in-one moving and home dashboard to help you get settled into your new home. From a moving checklist to setting up your utilities, internet and cable, protecting your home with home security and with home insurance, and much more, you'll be able to find what you need to have everything ready for the day you move into your new home.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel2' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel2' && 'accordion-title-color'}`}>{questions.panel2}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='accordion-text'>
                                It's a free self-service platform to help manage all your moving needs to help you get settled into your new home all in one place. You'll get to explore our exclusive deals to compare pricing and get quotes for different services.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel3' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel3' && 'accordion-title-color'}`}>{questions.panel3}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='accordion-text'>
                                <ul>
                                    <li>View your moving checklist</li>
                                    <li>Get home insurance quotes</li>
                                    <li>Set up your internet and cable</li>
                                    <li>Set up your utilities</li>
                                    <li>Explore home security providers</li>
                                    <li>Find energy-saving plans and options</li>
                                    <li>Protect your home and appliances with a home warranty</li>
                                    <li>Update driver's license and vehicle registration</li>
                                    <li>and more coming soon...</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className='accordion-wrap' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel4' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel4' && 'accordion-title-color'}`}>{questions.panel4}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='accordion-details'>
                            <Typography className='accordion-text'>
                                Our preferred insurance provider InsuraMatch is a national, independent insurance agency where you'll find a different kind of insurance shopping experience - one that's consultative and focused on finding you the right insurance, not just selling.
                            </Typography>
                            <Typography className='accordion-text'>
                                Here, there's no sales pressure. Instead we seek to build relationships with our customers in order to help you better protect your home, car, and more. Whether you start your quote with us online or over the phone, our expert insurance advisors will compare rates from our 50+ insurance company partners and review them with you to help you find the right coverage, policy, and price - your match.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel5' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel5' && 'accordion-title-color'}`}>{questions.panel5}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='accordion-text'>
                                Our team has access to every major provider across the nation, and our unbiased approach is focused on making sure you get the best deal and internet and TV package that meets your needs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel6' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel6bh-content"
                            id="panel6bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel6' && 'accordion-title-color'}`}>{questions.panel6}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='accordion-details'>
                            <Typography className='accordion-text'>
                                After an order has been placed you will need to work with the provider directly that you set up your services. You should have received a confirmation email from them. You can also find their customer service information below:
                            </Typography>

                            <TableContainer component={Paper} className='accordion-table'>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Provider</TableCell>
                                            <TableCell >Customer Service</TableCell>
                                            <TableCell >Pre-Install</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.provider}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.provider}
                                                </TableCell>
                                                <TableCell >{row.customeServies}</TableCell>
                                                <TableCell >{row.preInstall}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion-wrap' expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={expanded === 'panel7' ? <RemoveIcon style={{ color: "#0066F5" }} /> : <AddIcon />}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                        >

                            <Typography className={`accordion-title ${expanded === 'panel7' && 'accordion-title-color'}`}>{questions.panel7}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='accordion-text'>
                                We have negotiated exclusive offers with the top brands in the industry like ADT and Vivint to make sure our clients get a security offering like no other. We go above the traditional basic security to not only make your home completely safe but also smart, with cameras, thermostats, automatic door-locks and more, and our goal is to cover the costs of the majority if not ALL of your equipment.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>


                </div>
            </div>
        </div>
    )
}

export default PodsFaqPage