import React from 'react';
import Select from 'react-select';
import '../../styles/reactSelect.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { InputLabel } from '@material-ui/core';

interface ISelectInputProps {
    className?: string;
    onChange?: any;
    placeholder?: string;
    value?: any;
    disabled?: any;
    options?: any;
    label?: string;
    formatOptionLabel?: any;
    error?: boolean;
    required?: boolean;
    defaultValue?: any;
    isLoading?: boolean;
}

const SelectInput = (props: ISelectInputProps) => {
    // const customStyles = {
    //     control: () => ({
    //         border: props.error ? '1px solid red' : '1px solid #6b6c6f',
    //         borderRadius:'5px'

    //       })
    // }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div style={{ width: '100%' }}>
            {props.label ? (
                <InputLabel
                    htmlFor="bootstrap-input"
                    className={'text-black ml-2 mb-2 mt-3'}
                    style={{ fontFamily: 'Poppins', color: '#162855' }}
                >
                    {props.required ? `${props.label} *` : props.label}
                </InputLabel>
            ) : null}
            <Select
                IconComponent={<KeyboardArrowDownIcon />}
                classNamePrefix="select-new"
                // menuIsOpen
                className={
                    props.className
                        ? props.className
                        : isDouglasElliman
                        ? 'react-select projec treact-selectD'
                        : 'react-select project'
                }
                onChange={props.onChange}
                options={props.options}
                label={props.label}
                placeholder={props.placeholder}
                value={props.value}
                isDisabled={props.disabled}
                formatOptionLabel={props.formatOptionLabel ? props.formatOptionLabel : null}
                defaultValue={props.defaultValue}
                isLoading={props.isLoading}
                //    styles={
                //       customStyles
                //    }
            />
        </div>
    );
};

export default SelectInput;
