import React, { Component } from 'react';
import './checklistCompleted.scss';
import { AddTasks } from '../../components/atoms/checklistComponents/addTasks';
import CompletedCards from '../../components/atoms/checklistComponents/ChecklistCompletedCard';
import Modals from './checklistModal';
import WeekSwitch from '../../components/atoms/checklistComponents/WeekSwitch';
// import Calender from '../../components/atoms/checklistComponents/CalenderSchedular';
import * as ChecklistAction from '../../store/checklist/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@material-ui/core';
import * as CommonAction from '../../store/common/actions';

type initialProps = {
    history?: any;
    checklistAction?: any;
    checklistState?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    showTasks: boolean;
    task: any;
    showAll: string;
    AnchorEl?: any;
    sort: any;
    history?: any;
    index: number;
    checklistKeys: any;
    movingDate: any;
};
var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export class checklistCompleted extends Component<initialProps, initialState> {
    state: initialState = {
        showTasks: false,
        task: '',
        showAll: 'Show All',
        AnchorEl: null,
        sort: { value: 'Weekly', label: 'Weekly' },
        index: -1,
        checklistKeys: [],
        movingDate: '',
    };

    componentDidMount() {
        // this.props.commonAction.moveGet();
        this.props.checklistAction.getChecklist();
    }

    static getDerivedStateFromProps(nextProps: any, currentState: any) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].moving_date
        ) {
            currentState.movingDate = nextProps.commonState.move[0].moving_date;
        }
        if (nextProps && nextProps.checklistState) {
            if (
                nextProps.checklistState.checklistTask &&
                nextProps.checklistState.isAdded &&
                Object.keys(nextProps.checklistState.checklistTask).length > 0 &&
                currentState.showTasks
            ) {
                nextProps.checklistState.isAdded = false;
                currentState.task = '';
                currentState.showTasks = false;

                nextProps.checklistAction.getChecklist();
            }
            if (!nextProps.checklistState.isLoading && nextProps.checklistState.checklist) {
                let data = Object.keys(nextProps.checklistState.checklist);
                currentState.checklistKeys = data;
            }
        }
        if (currentState.index < 0 && currentState.checklistKeys.length > 0) {
            let month = new Date().toLocaleString('default', { month: 'long' });
            currentState.checklistKeys.length > 0 &&
                currentState.checklistKeys.find((el: any, index: number) => {
                    if (el.includes(month) && currentState.index < 0) {
                        currentState.index = index;
                    }
                });

            if (currentState.index < 0) currentState.index = 0;
        }

        return currentState;
    }

    handleShowTasks = () => {
        this.setState({
            showTasks: !this.state.showTasks,
        });
    };

    handleTasksChange = (e: any) => {
        this.setState({
            task: e.target.value,
        });
    };

    onSelectHandler = (e: any) => {
        this.setState({
            showAll: e.value,
        });
    };

    handleWeekly = () => {
        this.setState({
            sort: { value: 'Weekly', label: 'Weekly' },
        });

        this.handleClose();
    };

    handleMonthly = () => {
        this.setState({
            sort: { value: 'Monthly', label: 'Monthly' },
        });

        this.handleClose();
    };

    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    handlePrevWeek = () => {
        let { index } = this.state;
        if (index <= 0) {
            this.setState({
                index: 0,
            });
        } else {
            this.setState({
                index: index - 1,
            });
        }
    };

    handleNextWeek = () => {
        let { index, checklistKeys } = this.state;
        let len = checklistKeys.length;
        if (index >= len - 1) {
            this.setState({
                index: len - 1,
            });
        } else {
            this.setState({
                index: index + 1,
            });
        }
    };

    cardClicked = () => {};
    saveHandler = () => {
        if (this.state.task && this.state.movingDate && this.state.checklistKeys) {
            let year = new Date().getFullYear();
            let date = parseInt(this.state.checklistKeys[this.state.index].match(/\d+/)[0]);
            let month = months.indexOf(this.state.checklistKeys[this.state.index].split(' ')[0]);
            let date1: any = new Date(this.state.movingDate);
            let date2: any = new Date(year, month, date);
            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Days: any = Difference_In_Time / (1000 * 3600 * 24);

            const payload = {
                name: this.state.task,
                days_to_move: parseInt(Days),
            };
            this.props.checklistAction.addTask(payload);
        }
    };
    closeHandler = () => {
        this.setState({ showTasks: false });
    };
    // handleModal = (data: any) => {
    //     console.log(data);
    // };
    render() {
        const { showTasks, task, index, checklistKeys } = this.state;
        // let month = checklistKeys.length > 0 && checklistKeys[index].split(' ')[0];

        let cardsArray: any = [];
        if (
            this.props.checklistState &&
            this.props.checklistState.checklist &&
            Object.keys(this.props.checklistState.checklist).length > 0
        ) {
            for (const [key, value] of Object.entries(this.props.checklistState.checklist)) {
                if (key === checklistKeys[index]) {
                    cardsArray = value;
                }
            }
            cardsArray =
                this.state.showAll === 'Show All'
                    ? cardsArray.filter((item: any) => {
                          return item.is_completed;
                      })
                    : cardsArray.filter((item: any) => {
                          if (
                              item.associated_items &&
                              item.associated_items.length > 0 &&
                              item.associated_items[0].name === this.state.showAll
                          )
                              return item.is_completed;
                      });
        }
        return (
            <div>
                <div className="ChecklistMain">
                    <h2 className="Checklistheading">Your Moving Checklist</h2>
                    <div className="Checklistpath">
                        <p
                            className="ChecklistPara"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Checklist</p>
                    </div>
                </div>
                <div className="ChecklistCompletedContent">
                    {checklistKeys && checklistKeys.length > 0 ? (
                        <WeekSwitch
                            onSelectHandler={this.onSelectHandler}
                            showAll={this.state.showAll}
                            handlePrevWeek={this.handlePrevWeek}
                            handleNextWeek={this.handleNextWeek}
                            handleMenu={(e: any) => this.handleMenu(e)}
                            handleClose={this.handleClose}
                            AnchorEl={this.state.AnchorEl}
                            sort={this.state.sort}
                            // startMonth={this.state.startMonth}
                            // endMonth={this.state.endMonth}
                            // startDt={this.state.startDt}
                            // endDt={this.state.endDt}
                            handleWeekly={this.handleWeekly}
                            handleMonthly={this.handleMonthly}
                            checklistKeys={checklistKeys}
                            index={index}
                        />
                    ) : (
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}

                    <div className="checklistTabdiv">
                        <div className="checklistTabInnerDiv">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist' ||
                                        this.props.history.location.pathname === `/dashboard/checklist/`)
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist');
                                }}
                            >
                                Essentials And Overdue
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist/overdue' ||
                                        this.props.history.location.pathname === '/dashboard/checklist/overdue/')
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist/overdue');
                                }}
                            >
                                Overdue
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist/completed' ||
                                        this.props.history.location.pathname === '/dashboard/checklist/completed/')
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist/completed');
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        <div className="checklistAddTask" onClick={this.handleShowTasks}>
                            + Add Task
                        </div>
                    </div>
                    <div className="checklistAddTaskMobile" onClick={this.handleShowTasks}>
                        + Add Task
                    </div>
                    {showTasks && (
                        <AddTasks
                            handleTasksChange={this.handleTasksChange}
                            value={task}
                            onSave={this.saveHandler}
                            onClose={this.closeHandler}
                        />
                    )}
                    <div className="calenderChecklist">
                        {/* {this.state.sort.value === 'Weekly' ? ( */}
                        {cardsArray && cardsArray.length > 0 ? (
                            <div className="checklistMainCards">
                                {cardsArray.map((item: any) => {
                                    let blueCardData = '';
                                    if (item.must_do && item.is_overdue) {
                                        blueCardData = 'Essential and Overdue';
                                    } else if (!item.must_do && item.is_overdue) {
                                        blueCardData = 'Overdue';
                                    } else {
                                        blueCardData = 'none';
                                    }
                                    return (
                                        <CompletedCards
                                            mainData={item.name}
                                            paraData={item.description ? item.description : ''}
                                            blueCard={blueCardData}
                                            isQuote={true}
                                            onClick={() => this.cardClicked()}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="dataAvailable">No task available</div>
                        )}
                        {/* ) : (
                            <Calender
                                data={[
                                    {
                                        title: 'Prepare 2015 Marketing Plan',
                                        startDate: new Date(2021, 5, 14, 13, 0),
                                        endDate: new Date(2021, 5, 16, 13, 30),
                                        priority: 2,
                                    },
                                    {
                                        title: 'Brochure Design Review',
                                        startDate: new Date(2021, 5, 14, 14, 10),
                                        endDate: new Date(2021, 5, 15, 15, 30),
                                        priority: 1,
                                    },
                                    {
                                        title: 'Website Re-Design Plan',
                                        startDate: new Date(2021, 5, 29, 9, 30),
                                        endDate: new Date(2021, 5, 29, 11, 30),
                                        priority: 3,
                                    },
                                ]}
                                handleModal={(data: any) => this.handleModal(data)}
                            />
                        )} */}
                    </div>
                </div>
                <Modals calender={false} />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        checklistAction: bindActionCreators(ChecklistAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    checklistState: state.checklist,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(checklistCompleted);
