import {
    GET_CHECKLIST,
    GET_CHECKLIST_ERROR,
    GET_CHECKLIST_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE,
    UPDATE_SERVICE_PERCENTAGE_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE_ERROR,
    GET_PODS_CHECKLIST,
    GET_PODS_CHECKLIST_SUCCESS,
    GET_PODS_CHECKLIST_ERROR

} from './actionTypes';

import { CheckListActions, CheckListDashboardState } from './types';

const initialState: CheckListDashboardState = {
    checklist: [],
    updateServicePercentage: [],
    podsChecklist: []
};

export default (state = initialState, action: CheckListActions) => {
    switch (action.type) {
        case GET_CHECKLIST:
            return {
                ...state,
                checklist: [],
                error: null,
            };
        case GET_CHECKLIST_SUCCESS:
            return {
                ...state,
                checklist: action.payload.checklist,
                error: null,
            };
        case GET_CHECKLIST_ERROR:
            return {
                ...state,
                checklist: [],
                error: action.payload.error,
            };

        case UPDATE_SERVICE_PERCENTAGE:
            return {
                ...state,
                updateServicePercentage: [],
                error: null,
            };
        case UPDATE_SERVICE_PERCENTAGE_SUCCESS:
            return {
                ...state,
                updateServicePercentage: action.payload.updateServicePercentage,
                error: null,
            };
        case UPDATE_SERVICE_PERCENTAGE_ERROR:
            return {
                ...state,
                updateServicePercentage: [],
                error: action.payload.error,
            };

        case GET_PODS_CHECKLIST:
            return {
                ...state,
                podsChecklist: [],
                podsError: null,
            };
        case GET_PODS_CHECKLIST_SUCCESS:
            return {
                ...state,
                podsChecklist: action.payload.podsChecklist,
                podsError: null,
            };
        case GET_PODS_CHECKLIST_ERROR:
            return {
                ...state,
                podsChecklist: [],
                podsError: action.payload.podsError,
            };

        default:
            return {
                ...state,
            };
    }
};
