import React from 'react';
import '../../styles/quoteCard.scss';
import ButtonNew from '../atoms/Button';
import deleteIcon from '../../Assets/images/MovingImages/delete-icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface getQuoteCardProps {
    cardImage?: any;
    width?: string;
    className?: string;
    text1?: string;
    text2?: string;
    selectedMovers?: number;
    totalMover?: number;
    selectedMover?: boolean;
    features?: any;
    onClickedHandle?: any;
    deleteClicked?: any;
    clearClicked?: any;
    addMore?: boolean;
    noQuote?: boolean;
    addMovers?: any;
    vendors?: any;
    imageBackGround?: any;
    logoInitials?: any;
}

const GetAQuoteCard = (props: getQuoteCardProps) => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    const [value, setValue] = React.useState(true);

    const handleValue = () => {
        setValue(!value);
    };
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div className="quote-main-div">
            <div className="quoteDivCard">
                {props.features.length > 0 ? (
                    <div>
                        <div className="quote-card-top">
                            <div className={props.className ? props.className : 'selected-movers-count'}>
                                {props.vendors ? 'Selected Vendors' : 'Selected Movers'}({props.selectedMovers}
                                {props.vendors ? '' : '/'}
                                {props.totalMover})
                                <div className="mobile-div-only">
                                    <ExpandMoreIcon onClick={handleValue} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            {props.addMore === true ? (
                                <div
                                    className="clear-text"
                                    style={{ color: isDouglasElliman ? '#009cbd' : '#006dff', cursor: 'pointer' }}
                                    onClick={props.addMovers}
                                >
                                    {props.vendors ? 'Add Vendors' : '+Add Movers'}
                                </div>
                            ) : (
                                <div
                                    className="clear-text"
                                    onClick={props.clearClicked}
                                    style={{ color: isDouglasElliman ? '#009cbd' : '#006dff', cursor: 'pointer' }}
                                >
                                    clear all
                                </div>
                            )}
                        </div>
                        <div className="data-selected-div">
                            {props.features.map((item: any) => {
                                return (
                                    <div
                                        className={
                                            value == true ? 'selected-detail' : 'selected-detail mobile-selected-detail'
                                        }
                                    >
                                        <div>
                                            {props.vendors ? null : (
                                                <div className="image-div-quote">
                                                    <img
                                                        src={item.icon}
                                                        alt=""
                                                        width="100%"
                                                        style={{
                                                            maxHeight: '100%',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div style={{ display: 'flex' }}>
                                                {props.vendors ? (
                                                    <div
                                                        className="image-trusted-quote"
                                                        style={{
                                                            backgroundColor: props.imageBackGround
                                                                ? props.imageBackGround
                                                                : '',
                                                        }}
                                                    >
                                                        <div className="logo-initials-quote">{item.logoInitials}</div>
                                                    </div>
                                                ) : null}
                                                <div className="mover-name">{item.name}</div>
                                            </div>
                                            {props.vendors ? null : (
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                                >
                                                    <div style={{ marginRight: '5px' }}>
                                                        <img src={item.locationIcon} />
                                                    </div>
                                                    <div className="location-text">{item.locationText}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div onClick={item.deleteClicked} style={{ cursor: 'pointer' }}>
                                            <img src={deleteIcon} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div style={{ padding: '50px 0' }}>
                        <div className="add-arrow-img">
                            <img src={props.cardImage} />
                        </div>
                        <div className="quote-text">
                            <div style={{ fontSize: '14px' }}>
                                {props.vendors ? 'Select Multiple Vendors' : 'Select multiple movers'}
                            </div>
                            <div style={{ fontSize: '12px' }}>
                                {[props.vendors ? 'to your project' : 'to get a quote']}
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={value == true ? 'clear-text-mobile' : 'clear-text-mobile-2'}
                    style={{ cursor: 'pointer' }}
                    onClick={props.addMovers}
                >
                    +Add Movers
                </div>
            </div>

            {props.noQuote === true ? null : (
                <div className="quote-button-main">
                    {props.features.length > 0 ? (
                        <ButtonNew
                            className="quote-button"
                            width="90%"
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100b28'
                                    : props.selectedMover
                                    ? slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#273E59'
                                    : '#BEBEBE'
                            }
                            onClick={props.onClickedHandle}
                            margin="40px 0 0 0"
                        >
                            {props.vendors ? 'Send Request' : 'Continue'}
                        </ButtonNew>
                    ) : (
                        <ButtonNew
                            className="quote-button"
                            width="90%"
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100b28'
                                    : props.selectedMover
                                    ? slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#273E59'
                                    : '#BEBEBE'
                            }
                            disabled
                        >
                            {props.vendors ? 'Send Request' : 'Continue'}
                        </ButtonNew>
                    )}
                </div>
            )}
        </div>
    );
};

export default GetAQuoteCard;
