import React, { Component } from 'react';
import './recommendationCardsAll.scss';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as SecurityAction from '../../store/security/actions';
import Modal from '../../components/atoms/Modal';
import * as SignupAction from '../../store/login/signup/actions';
import ViewDetailsPage from './viewDeatils'
import { getCardsDatas } from '../../components/atoms/HomeSecurity/CardsDatas';
import AnalyticsService from '../../utils/analytics-service';
import _ from 'lodash';

type initialProps = {
    adtRecommended?: boolean;
    vivintRecommended?: boolean;
    alderRecommended?: boolean;
    simpliRecommended?: boolean;
    commonAction?: any;
    commonState?: any;
    history?: any;
    securityType?: any;
    securityInstallation?: any;
    fromLanding?: any;
    data?: any;
    securityAction?: any;
    securityState: any;
    signupAction?: any;
    signupState?: any;
    landing?: any;
    isDouglasElliman?: boolean;
};

type initialState = {
    calendlyOpen: boolean;
    cardsContentNew: any;
    isViewDetails: boolean;
    viewDetailsData: any;
    selectedService: any;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isDataArrived: boolean;
    isSubmitClicked: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    appType: any;
};
export class RecommendationCardsAll extends Component<initialProps, initialState> {
    state: initialState = {
        appType: this.context?.appType,
        calendlyOpen: false,
        cardsContentNew: [],
        isViewDetails: false,
        viewDetailsData: {},
        selectedService: [],
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isDataArrived: true,
        isSubmitClicked: false,
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
    };

    fetchCardsData = () => {
        const cardsDatas = getCardsDatas();
        this.setState({ cardsContentNew: cardsDatas });
    }

    componentDidMount(){
        this.fetchCardsData()
    }

    componentDidUpdate() {
        if (_.size(this.state.cardsContentNew) === 0 && localStorage.getItem('slug')) {
            this.fetchCardsData()
        }
    }
    closeCalendlyModal = async () => {
    }
    closeModal = () => {
        
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };

    onCloseModal = () => {
        this.setState({ isViewDetails: false });
    };

    viewDetailsClick = (item: any) => {
        this.setState({ isViewDetails: true, viewDetailsData: item });

        AnalyticsService.buttonClicked({
            page: 'Home Security',
            category_name: 'Home Security Recommendations',
            button_copy: 'View Details',
            card: true,
            card_title: item.title,
            card_type: 'Security Plan Card'
        })
    };

    onCheckIfQualifyHandler = (id: number, title: string) => {
        this.props.history.push({
            pathname: '/dashboard/home-security/checkifqualify',
            state: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                id: id,
            }
        })

        AnalyticsService.buttonClicked({
            page: 'Home Security',
            category_name: 'Home Security Recommendations',
            button_copy: 'Check if I qualify',
            card: true,
            card_title: title,
            card_type: 'Security Plan Card'
        })
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
                
        return (
            <div className="main-div-recommendation">
                <div className="card-1-recommendation">
                    <div className="recommended-section"></div>
                    <div className="logo-section"></div>
                    <div className="brand-title"></div>
                    <div className="monthly-fees">Monthly fee</div>
                    <div className="upfront-cost-1">Upfront Cost</div>
                    <div className="installation-cost-1">Installation Cost</div>
                    <div className="installation-type-1">Installation Type</div>
                    <div className="exclusive-deals">
                        <div className="exclusive-deals-1">Exclusive Deals</div>
                    </div>
                    <div className="top-features">
                        <div className="top-features-1">Top Features</div>
                    </div>
                    <div className="conditions">
                        <div className="conditions-1">Conditions</div>
                    </div>
                    <div className="call"></div>
                    <div className="view-details"></div>
                </div>
                <div className="main-cards-security">
                    {this.state.cardsContentNew.map((item: any) => {
                        return (
                            <div
                                className={`card-2-recommendation ${item.recommended && 'add-more-margin'}`}
                                style={{ boxShadow: item.recommended ? '0px 0px 3px #bebebe' : '' }}
                            >
                                <div className="recommended-section">
                                    {item.recommended ? (
                                        <div
                                            className={
                                                this.props.isDouglasElliman ? 'recommended addColor' : 'recommended'
                                            }
                                        >
                                            Recommended
                                        </div>
                                    ) : null}
                                </div>
                                <div className="logo-section">
                                    <div className="logo-card">
                                        <img src={item.cardLogo} style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                                <div className="brand-title">{item.title}</div>
                                <div className="monthly-fees">${item.monthlyfees}</div>
                                <div className="upfront-cost">{item.upfrontCost}</div>
                                <div className="installation-cost">
                                    {item.installationCost != 'NA' ? (
                                        // <del className="cross">
                                        <span className="waived">{item.installationCost}</span>
                                    ) : (
                                        // </del>
                                        'NA'
                                    )}{' '}
                                    {item.waivedOff == true ? 'Waived Off' : ''}{' '}
                                </div>
                                <div className="installation-type">{item.installationType}</div>
                                <div className="exclusive-deals">
                                    <div className="tag-special">
                                        <div className="tag-div">
                                            <LocalOfferIcon
                                                style={{
                                                    color: this.props.isDouglasElliman
                                                        ? '#009CBD'
                                                        : item.recommended
                                                            ? '#006DFF'
                                                            : '#333',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                color: this.props.isDouglasElliman
                                                    ? '#009CBD'
                                                    : item.recommended
                                                        ? '#006DFF'
                                                        : '#333',
                                                fontWeight: 'bold',
                                                margin: '0 5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.special}
                                        </div>
                                    </div>

                                    <div className="price">
                                        {' '}
                                        {/* {!item.price.includes('OFF') ? <sup className="dollar">$</sup> : ''} */}
                                        {item.price}
                                    </div>

                                    <div className="speciality">{item.speciality}</div>
                                </div>
                                <div className="top-features">{item.topFeatures}</div>
                                <div className="conditions">
                                    <div className="condition-inner">
                                        <div className="condition">{item.condition}</div>
                                        <div className="info-icon">
                                            <InfoIcon style={{ color: '#6B6C6F', height: '15px', width: '15px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="call">
                                    <Button
                                        onClick={() => this.onCheckIfQualifyHandler(item.id, item.title)}
                                        borderRadius="4px"
                                        backgroundColor={
                                            this.props.isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : this.props.landing
                                                        ? '#30ab86'
                                                        : '#273E59'
                                        }
                                    >
                                        Check if I qualify
                                    </Button>
                                </div>
                                <div className="view-details" onClick={() => this.viewDetailsClick(item)}>
                                    View Details
                                </div>
                            </div>
                        );
                    })}
                </div>


                {this.state.isViewDetails ? (
                    <Modal
                        isShowModal={this.state.isViewDetails}
                        showClose={false}
                        onCloseModal={this.onCloseModal}
                        className="securityRecommendationMainPageModal"
                    >
                        <ViewDetailsPage onBack={this.onCloseModal} data={this.state.viewDetailsData} />
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        securityAction: bindActionCreators(SecurityAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    securityState: state.security,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationCardsAll);
