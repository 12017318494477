import React, { Component } from 'react';
import './HomeWarantyMain.scss';

import WarantyBooked from '../../components/HomeWaranty/HomeWarantyBooked';
// import ButtonNew from '../../components/atoms/Button';
// import learnMore from '../../Assets/images/HomeWarranty/learnmoreWaranty.svg';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';

type initialProps = {
    history?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
};

type initialState = {
    homeWarrantyData: any;
};

export class HomeWarantyHome extends Component<initialProps, initialState> {
    state: initialState = { homeWarrantyData: null };

    componentDidMount() {
        if (
            this.props &&
            this.props.homeWarrantyState &&
            this.props.homeWarrantyState.getHomeWarrantyDetails &&
            this.props.homeWarrantyState.getHomeWarrantyDetails.submission_date != null &&
            this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number != null
        ) {
            // this.props.homeWarrantyAction.getHomeWarrantyDetails();
            this.setState({
                homeWarrantyData: this.props.homeWarrantyState.getHomeWarrantyDetails,
            });
        } else {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        let prevW = prevProps.homeWarrantyState;
        let curW = this.props.homeWarrantyState;

        if (prevW.getHomeWarrantyDetails !== curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails) {
            this.setState({
                homeWarrantyData: curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails,
            });
        }
    }

    onClickKnowMore = () => {
        this.props.history.push({
            pathname: '/dashboard/home-warranty',
            state: {
                from: 'home',
            },
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }

        return (
            <div>
                <div style={{ marginTop: '40px' }}></div>
                <div className="main-head">
                    <h2 className="heading">Home Warranty</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Home Warranty</p>
                    </div>
                </div>
                <div className="main-head">
                    {/* <div
                        className="warant-card-moving"
                        style={{
                            background: slug && slug.color_shade1 ? `${slug.color_shade1}` : '#08192f',
                        }}
                    >
                        <div className="main-card-booked">
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                    Looking for a new Home warranty?
                                </div>
                                <div style={{ fontSize: '16px', margin: '10px 0 20px 0' }}>
                                    Click here to apply for new home warranty
                                </div>
                                <div>
                                    <ButtonNew
                                        backgroundColor="#FFFFFF"
                                        color={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                        fontSize="16px"
                                        width="110px"
                                        fontWeight="bold"
                                        height="40px"
                                        margin="2px 2px 10px 2px"
                                        onClick={this.onClickKnowMore}
                                    >
                                        Learn More
                                    </ButtonNew>
                                </div>
                            </div>
                            <img src={learnMore} className="booked-img" />
                        </div>
                    </div> */}
                    <div className="previously-book-head">Previously Booked</div>
                    <div className="waranty-Booked-Cards">
                        <WarantyBooked
                            title={
                                this.state.homeWarrantyData && this.state.homeWarrantyData.plan_name
                                    ? this.state.homeWarrantyData.plan_name
                                    : ''
                            }
                            editPlan={() => {}}
                            submissionDate={
                                this.state.homeWarrantyData && this.state.homeWarrantyData.submission_date
                                    ? this.state.homeWarrantyData.submission_date
                                    : ''
                            }
                            policyNumber={
                                this.state.homeWarrantyData && this.state.homeWarrantyData.policy_number
                                    ? this.state.homeWarrantyData.policy_number
                                    : ''
                            }
                            effectiveDate={
                                this.state.homeWarrantyData && this.state.homeWarrantyData.effective_date
                                    ? this.state.homeWarrantyData.effective_date
                                    : ''
                            }
                            expirationDate={
                                this.state.homeWarrantyData && this.state.homeWarrantyData.expiry_date
                                    ? this.state.homeWarrantyData.expiry_date
                                    : ''
                            }
                        />
                        {/* <WarantyBooked
                            title={'Complete'}
                            editPlan={() => {
                            }}
                            submissionDate={'12 / 09 / 2021'}
                            policyNumber={'#1234-90'}
                            effectiveDate={'24 Dec 2021'}
                            expirationDate={'23 Dec 2021'}
                        /> */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarantyHome);
