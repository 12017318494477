import React, { Component } from 'react';
import PhoneSvg from '../../Assets/images/local_phone-greypx.svg';
import WifiSvg from '../../Assets/images/wifi-greypx.svg';
import TvSvg from '../../Assets/images/desktop_windows-greypx.svg';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
import './ICmain.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InternetAction from '../../store/internet/actions';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as DashboardAction from '../../store/dashboard/actions';
import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { scheduleSegment, buttonClicked } from '../../utils/utlis';

type initialProps = {
    commonAction?: any;
    commonState?: any;
    internetAction?: any;
    internetState?: any;
    history?: any;
    dashboardAction: any;
    dashboardState: any;
};
type initialState = {
    calendlyOpen: boolean;
    serviceId: any;
    reschedule_link: string;
    isDataArrived: boolean;
    appointment_details: any;
    appointmentTime: any;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info_enabled: any;
    moveId: string;
    hashcode: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
    concierge_ref: any;
};

export class Summary extends Component<initialProps, initialState> {
    state = {
        calendlyOpen: false,
        serviceId: '',
        reschedule_link: '',
        isDataArrived: true,
        appointment_details: '',
        appointmentTime: '',
        phone: '',
        formOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        isSubmitClicked: false,
        custom_schedule_call_info_enabled: false,
        moveId: '',
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
        concierge_ref: '',
    };
    componentDidMount() {
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.internetAction.selectDigitalPlanGet();
        this.props.dashboardAction.checklist();

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            // currentState.custom_schedule_call_info = nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.custom_schedule_call_info
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.concierge_ref =
                nextProps.commonState.move[0] && nextProps.commonState.move[0].concierge_reference_field;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }
    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };

    componentDidUpdate(prevProps: initialProps) {
        if (
            (prevProps && prevProps.dashboardState && prevProps.dashboardState.checklist) !==
                (this.props && this.props.dashboardState && this.props.dashboardState.checklist) &&
            this.props.dashboardState.checklist &&
            this.props.dashboardState.checklist.length > 0
        ) {
            this.props.dashboardState.checklist.map((el: any) => {
                if (el && el.do_it_now && el.do_it_now.new_url.includes('/dashboard/internet')) {
                    this.props.dashboardAction.updateServicePercentage({
                        data: {
                            completed_percentage: 100,
                        },
                        service_id: el.id,
                    });
                    this.setState({ serviceId: el.id });
                }
            });
        }
    }

    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Schedule Call Button Clicked',
            'Internet & Cable Summary',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Internet & Cable',
            'Schedule A Call',
            false,
            ``,
            true,
            'Thank you for your order ',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Internet and Cable',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
        
    };
    nylasModalClosed = () => {
        // console.log("clicked")
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        this.props.commonAction.appointmentGet(payload);
    };
    render() {
        let moveData = this.props.commonState && this.props.commonState.move && this.props.commonState.move[0];
        let select =
            this.props.internetState &&
            this.props.internetState.selectDigitalPlan &&
            this.props.internetState.selectDigitalPlan[0];
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="summayInternet-div">
                    {this.state.formOpen && (
                        <Modal
                            isShowModal={this.state.formOpen}
                            onCloseModal={() => {
                                this.setState({ formOpen: false });
                            }}
                            showClose={false}
                            className="modalClassesSecurity"
                        >
                            <div style={{ height: '100%' }}>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({ formOpen: false });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <div className="peronalDetailsInsurance">
                                        <div className="flex-div">
                                            <div className="div-1-name">
                                                <TextField
                                                    id="time"
                                                    label="First Name"
                                                    value={this.state.firstName}
                                                    onChange={(e: any) => this.firstNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.firstName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.firstName === ''
                                                        ? 'Please enter first name'
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="div-2-name">
                                                <TextField
                                                    id="time"
                                                    label="Last Name"
                                                    value={this.state.lastName}
                                                    onChange={(e: any) => this.lastNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.lastName === ''
                                                        ? 'Please enter last name'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <TextField
                                            id="time"
                                            label="Email"
                                            value={this.state.email}
                                            onChange={(e: any) => this.emailHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.email === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.email === ''
                                                ? 'Please enter email'
                                                : this.state.isSubmitClicked &&
                                                  !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                ? 'Please enter valid email'
                                                : ''}
                                        </div>
                                        <TextField
                                            id="time"
                                            label="Phone Number"
                                            value={this.state.phone}
                                            onChange={(e: any) => this.phoneHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.phone === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.phone === ''
                                                ? 'Please enter phone number'
                                                : this.state.isSubmitClicked && this.state.phone.length < 12
                                                ? 'Please enter valid phone number'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        width="130px"
                                        height="50px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        onClick={() => this.setState({ formOpen: false })}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.closeFormHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div className="summaryInternet">
                        <div
                            className="summaryInternetHead"
                            style={{
                                background: isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : window.location.pathname.includes('landing')
                                    ? '#30AB86'
                                    : '#273e59',
                                color: '#fff',
                            }}
                        >
                            <div className="summaryInternettitle">Thank you for your order </div>
                            <div className="summaryInternetsubtitle">
                                Your {this.state.concierge_ref} will call you on the chosen date and time to finalize
                                your order and schedule your installation
                            </div>
                        </div>
                        <div>
                            <div className="summaryOrderTag">
                                <div className="orderTitleInternet">Order confirmation</div>
                                <div className="orderTitleInternet1">
                                    Ordered on{' '}
                                    {select && select.created
                                        ? `${new Date(select && select.created).getDate()} ${new Date(
                                              select && select.created,
                                          ).toLocaleString('default', { month: 'long' })} ${new Date(
                                              select && select.created,
                                          ).getFullYear()}`
                                        : ''}{' '}
                                    | Order #{select && select.hash_code ? select && select.hash_code : ''}
                                </div>
                            </div>
                            {select ? (
                                <div className="summaryBoxPlanDetail">
                                    {select && select.plan_details && select.plan_details.provider_logo ? (
                                        <img
                                            src={select.plan_details.provider_logo}
                                            className="summaryBoxPlanDetailcompanyImg"
                                            // alt={'company logo'}
                                        />
                                    ) : null}

                                    <div className="summaryBoxPlanDetailContent">
                                        <div className="planDetailsTags">Company</div>
                                        <div className="planDetailsTags1">
                                            {select && select.plan_details && select.plan_details.provider_name}
                                        </div>
                                        <div className="planDetailsTags">Services selected</div>
                                        <div className="planDetailsTags1" style={{ display: 'flex' }}>
                                            {select && select.plan_details && select.plan_details.is_internet ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={WifiSvg} style={{ margin: '0px 0 -6px 0' }} /> Internet
                                                </div>
                                            ) : null}
                                            {select && select.plan_details && select.plan_details.is_tv ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={TvSvg} style={{ margin: '0px 0 -6px 0' }} /> TV
                                                </div>
                                            ) : null}
                                            {select && select.plan_details && select.plan_details.is_phone ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={PhoneSvg} style={{ margin: '0px 0 -6px 0' }} /> Phone
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="planDetailsTags">Contract term</div>
                                        <div className="planDetailsTags1">
                                            {select &&
                                            select.plan_details &&
                                            select.plan_details.service_contract_length
                                                ? select &&
                                                  select.plan_details &&
                                                  select.plan_details.service_contract_length
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                            <div className="summaryBoxPlanDetail1">
                                <div className="priceDetailInternet">Price details</div>
                                <div className="orderSummaryPrice">
                                    <div>Your Plan</div>
                                    <div style={{ textAlign: 'right' }}>
                                        <div style={{ color: '#333' }}>
                                            <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                            {select && select.plan_details && select.plan_details.base_price}/pm
                                        </div>
                                        <div style={{ color: '#6B6C6F', fontSize: '12px' }}>Plus taxes for 12mo</div>
                                    </div>
                                </div>
                                {select && select.plan_details && select.plan_details.setup_cost ? (
                                    <div>
                                        <div className="abcdLine"></div>
                                        <div className="orderSummaryPrice">
                                            <div style={{ fontWeight: 'bold' }}>Total Setup cost</div>
                                            <div style={{ color: isDouglasElliman ? '#009CBD' : '#0066F5' }}>
                                                <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                {select && select.plan_details && select.plan_details.setup_cost}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="summaryBoxPlanDetail1">
                                <div className="summaryBoxPlanConcierge">
                                    <div className="summaryBoxPlanConcierge1" style={{ display: 'flex' }}>
                                        {moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.headshot_logo ? (
                                            <img
                                                src={moveData.assigned_concierge.headshot_logo}
                                                alt={'Assigned Concierge'}
                                                className="summaryBoxPlanConciergeImg"
                                            />
                                        ) : null}
                                        <div className="summaryBoxPlanConciergedetail">
                                            <div>
                                                <div className="orderConciergeName">
                                                    {moveData &&
                                                    moveData.assigned_concierge &&
                                                    moveData.assigned_concierge.full_name ? (
                                                        moveData.assigned_concierge.full_name
                                                    ) : (
                                                        <Skeleton height="30px" width="80px" />
                                                    )}
                                                </div>
                                                {moveData &&
                                                moveData.assigned_concierge &&
                                                moveData.assigned_concierge.full_name ? (
                                                    <div className="orderConciergeNameLabel">
                                                        Dedicated {this.state.concierge_ref}
                                                    </div>
                                                ) : (
                                                    <Skeleton height="40px" width="80px" />
                                                )}
                                            </div>
                                            <div className="summaryBoxPlanConciergeCall">
                                                {moveData &&
                                                moveData.assigned_concierge &&
                                                moveData.assigned_concierge.phone ? (
                                                    <img className="summaryBoxPlanConciergeCallImg" src={PhoneSvg} />
                                                ) : null}
                                                <span className="summaryBoxPlanConciergeCallNumber">
                                                    {moveData &&
                                                        moveData.assigned_concierge &&
                                                        moveData.assigned_concierge.phone}
                                                </span>
                                            </div>
                                        </div>
                                        {this.state.custom_schedule_call_info_enabled ? (
                                            <div style={{ marginLeft: '30px' }}>
                                                <Button
                                                    className="btnsummary"
                                                    backgroundColor="#FFFFFF"
                                                    width="120px"
                                                    height="35px"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : '#273e59'
                                                    }
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="12px"
                                                    fontWeight="600"
                                                    border={
                                                        isDouglasElliman
                                                            ? '1px solid #100B28'
                                                            : `1px solid ${
                                                                  slug && slug.primary_color
                                                                      ? slug.primary_color
                                                                      : '#273e59'
                                                              }`
                                                    }
                                                    onClick={() => this.formHandler()}
                                                >
                                                    Contact Us
                                                </Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {this.state.reschedule_link && this.state.appointmentTime ? (
                                                        <div className="appointment-time-div">
                                                            <div className="details-2"> Appointment Details </div>
                                                            <div className="details-content-2">
                                                                {' '}
                                                                Your Call is scheduled on{' '}
                                                                {`${new Date(this.state.appointmentTime)
                                                                    .toString()
                                                                    .substring(0, 15)},${new Date(
                                                                    this.state.appointmentTime,
                                                                ).toLocaleTimeString('en-US')} `}{' '}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {this.state.reschedule_link && this.state.appointmentTime ? (
                                                    <div className="summaryBoxPlanConciergeScheduleCall">
                                                        <Button
                                                            className="btnsummary"
                                                            backgroundColor="#FFFFFF"
                                                            boxShadow={
                                                                isDouglasElliman
                                                                    ? 'none'
                                                                    : `0 0 3px 0 ${
                                                                          slug && slug.primary_color
                                                                              ? slug.primary_color
                                                                              : '#273E59'
                                                                      }`
                                                            }
                                                            width="100px"
                                                            height="40px"
                                                            color={
                                                                isDouglasElliman
                                                                    ? '#100B28'
                                                                    : slug && slug.primary_color
                                                                    ? slug.primary_color
                                                                    : '#273E59'
                                                            }
                                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                            fontSize="14px"
                                                            fontWeight="600"
                                                            margin="0 5px 0 0"
                                                            onClick={() => this.calendlyHandle()}
                                                        >
                                                            Reschedule
                                                        </Button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="placeOrderBtnInternet">
                            <Button
                                className="btnOrder"
                                // color="#FFFFFF"
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273e59'
                                }
                                width="170px"
                                height="50px"
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                fontSize="16px"
                                margin="0 10px"
                                onClick={() => {
                                    this.props.dashboardAction.updateServicePercentage({
                                        data: {
                                            completed_percentage: 0,
                                        },
                                        service_id: this.state.serviceId,
                                    });
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.fromLanding
                                        ? this.props.history.push('/internetLanding')
                                        : localStorage.getItem('isHomeOwner') &&
                                          localStorage.getItem('isHomeOwner') == 'true'
                                        ? this.props.history.push('/dashboardhomeOwner')
                                        : this.props.history.push({
                                              pathname: '/dashboard',
                                              state: { event: 'start' },
                                          });
                                    let move_id: any = localStorage.getItem('moveKey');
                                    buttonClicked(
                                        'Button Clicked',
                                        'Summary Page',
                                        this.state.brokerage_name,
                                        this.state.brokerage_key,
                                        this.state.agent_name,
                                        this.state.agent_key,
                                        'Internet & Cable',
                                        'Back to Dashboard',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        move_id,
                                    );
                                }}
                            >
                                Back to Dashboard
                            </Button>
                        </div>
                    </div>
                    {this.state.calendlyOpen && (
                        <Modal
                            isShowModal={this.state.calendlyOpen}
                            onCloseModal={this.nylasModalClosed}
                            showClose={false}
                            className="scheduleCallInternet"
                            // style={{
                            //     position: 'absolute',
                            //     width: '700px',
                            //     height: '650px',
                            //     backgroundColor: '#ffffff',
                            //     left: '25%',
                            //     top: '5%',
                            // }}
                        >
                            <div className="scheduleCallBack" onClick={() => this.setState({ calendlyOpen: false })}>
                                <ArrowBackIcon />
                            </div>
                            {/* <InlineWidget
                                styles={{ height: '100%' }}
                                url={
                                    moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url
                                }
                                prefill={{
                                    email:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.email,
                                    firstName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.first_name,
                                    lastName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.last_name,
                                }}
                            /> */}
                            {this.state.reschedule_link ? (
                                <div className="iFrame">
                                    <iframe
                                        src={`${this.state.reschedule_link}&utm_source=platform&utm_medium=web&utm_content=internet-request&utm_campaign=internet-platform`}
                                        allow="encrypted-media"
                                        // position="relative"
                                        className="iframe"
                                        //  referrerPolicy="same-origin"
                                        //  sandbox="allow-scripts"
                                        id="myFrame"
                                    ></iframe>
                                </div>
                            ) : null}
                        </Modal>
                    )}
                </div>

                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <div className="what-next-main-div">
                        <div className="what-next">What's Next?</div>

                        <div className="card-1-next">
                            <div className="card-heading-next">
                                Get Home Insurance Policy and <span className="highlighted">Save 20%</span>
                            </div>
                            <div className="button-next">
                                <Button
                                    onClick={() => window.open('https://insurance.moveeasy.com')}
                                    color="#fff"
                                    backgroundColor="#30AB86"
                                    className="button-next-card"
                                >
                                    Learn More
                                </Button>
                            </div>
                        </div>
                        <div className="card-2-next">
                            <div className="card-heading-next">
                                Protect Your Home With 24/7 <span className="highlighted">Smart Security</span>
                            </div>
                            <div className="button-next">
                                <Button
                                    onClick={() => window.open('https://security.moveeasy.com')}
                                    color="#fff"
                                    backgroundColor="#30AB86"
                                    className="button-next-card"
                                >
                                    Learn More
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        internetAction: bindActionCreators(InternetAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    internetState: state.internet,
    dashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
