import React, { Suspense, Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/Routes';
// import Loader from './components/Loader/Loader';
import history from './routes/History';
import Navbar from './components/Navbar/Navbar';
// import NavbarLanding from './components/Navbar/NavbarLanding';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from './store/common/actions';
import * as Action2 from './store/login/signup/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UIContext } from './UIContext';
import PodsFooter from './pages/Footer/PodsFooter';
import { configureGtag } from './utils/gtag';
import { serviceTitle } from './utils/helpers';

type initialProps = { commonAction?: any; commonState?: any; signupState?: any; signupAction?: any };
const domain = window.location.hostname;
let name = domain.split('.');

type initialState = {
    isDataArrived: boolean;
};

export class App extends Component<initialProps, initialState> {

    uiControl = this.context;
    //console.log('appType', uiControl.appType);
    state: initialState = {
        isDataArrived: true,
    };
    componentDidMount() {
        this.props.commonAction.realtor({ slug: name[0] });

        if (window.location.pathname.includes('oboardprocess/ghost-login/')) {
            // this.setState({
            //     hashCode:window.location.pathname.split('oboardprocess/ghost-login/')[1]
            // })
            if (window.location.pathname.split('oboardprocess/ghost-login/')[1]) {
                const payload = {
                    user_id: window.location.pathname.split('oboardprocess/ghost-login/')[1],
                };
                this.props.signupAction.ghostLogin(payload);
            } else {
                history.push('/signUp');
            }
        }
        let slug: any = localStorage.getItem('slug');
        let pods_moving_date: any = localStorage.getItem('pods_moving_date');
        let pods_dashboard_moving_date: any = localStorage.getItem('pods_dashboard_moving_date');
        if(pods_moving_date){
            let date1: any = new Date(pods_moving_date);
            let date2: any = new Date();
            if (date1 < date2) {
                localStorage.removeItem("pods_moving_date");
            }
        }
        if(pods_dashboard_moving_date){
            let date1: any = new Date(pods_dashboard_moving_date);
            let date2: any = new Date();
            if (date1 < date2) {
                localStorage.removeItem("pods_dashboard_moving_date");
            }
        }
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (
            window.location.hostname.split('.')[0] === 'douglas-elliman' ||
            window.location.hostname.split('.')[0] === 'douglas-elliman-demo' ||
            (slug && slug.brokerage_slug === 'douglas-elliman') ||
            (slug && slug.brokerage_slug === 'douglas-elliman-demo')
        ) {
            localStorage.setItem('isDouglasElliman', 'True');
            // this.props.commonAction.realtor({ slug: 'douglas-elliman' });
        }
        if (window.location.hostname.split('.')[0] === 'sibcycline' || (slug && slug.brokerage_slug === 'sibcycline')) {
            localStorage.setItem('isSibcycline', 'True');
        }
    }

    componentDidUpdate = (prevProps: initialProps) => {
        const prevRealtorConfig = prevProps.commonState?.realtor
        const curRealtorConfig = this.props.commonState?.realtor
        const isRealtorLoadedPrev = prevProps.commonState?.isRealtor
        const isRealtorLoadedCur = this.props.commonState?.isRealtor
        
        if(isRealtorLoadedPrev !== isRealtorLoadedCur && isRealtorLoadedCur){
            let link: any = document.querySelector("link[rel~='icon']");
            let linkApple: any = document.querySelector("link[rel~='apple-touch-icon']");
            let titleElement: any = document.querySelector("title");
            
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            
            if(!linkApple){
                linkApple = document.createElement('link');
                linkApple.rel = 'apple-touch-icon';
                document.getElementsByTagName('head')[0].appendChild(linkApple);
            }

            if(curRealtorConfig?.favicon){
                link.href = curRealtorConfig.favicon;
                linkApple.href = curRealtorConfig.favicon;
            } else {
                link.href = '/favicon.ico';
                linkApple.href = '/favicon.ico';
            }

            if(titleElement){
                titleElement.innerHTML = serviceTitle(curRealtorConfig)
            }

            if(curRealtorConfig?.gtmId && prevRealtorConfig?.gtmId !== curRealtorConfig?.gtmId){
                configureGtag(curRealtorConfig?.gtmId)
            }
        }
    }

    render() {        
        const appType = this.uiControl.appType
        
        if( appType == 'WITHOUT_AUTH' && window.location.pathname.includes("signUp")){
            history.push('/dashboard');
        }
        
        if ( appType !== 'WITHOUT_AUTH' &&
            !localStorage.getItem('token') &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/signUp' &&
            window.location.pathname !== '/signUp/next' &&
            window.location.pathname !== '/login' &&
            !window.location.pathname.includes('/setpassword') &&
            !window.location.pathname.includes('/resetpassword') &&
            !window.location.pathname.includes('/dashboard/internet') &&
            !window.location.pathname.includes('/dashboard/movers') &&
            !window.location.pathname.includes('/dashboard/storage') &&
            !window.location.pathname.includes('/oboardprocess/ghost-login') &&
            !window.location.pathname.includes('/dashboard/autoease') &&
            !window.location.pathname.includes('/dashboard/address') &&
            !window.location.pathname.includes('/dashboard/checklist') &&
            !window.location.pathname.includes('/dashboard/ourvendors') &&
            !window.location.pathname.includes('/dashboard/utilities') &&
            !window.location.pathname.includes('/dashboard/home-insurance') &&
            !window.location.pathname.includes('/dashboard/driverlicense') &&
            !window.location.pathname.includes('/dashboard/home-security') &&
            window.location.pathname !== '/internetLanding' &&
            window.location.pathname !== '/insuranceLandingPage' &&
            window.location.pathname !== '/premovesignup' &&
            window.location.pathname !== '/dashboard/home-warranty' &&
            window.location.pathname !== '/securityLanding' &&
            window.location.pathname.includes('/HomeWaranty/HomeWarantyMain') &&
            name[0] !== 'internet' &&
            name[0] !== 'insurance' &&
            name[0] !== 'security' &&
            name[0] !== 'internet-demo' &&
            name[0] !== 'insurance-demo' &&
            name[0] !== 'security-demo' &&
            window.location.pathname !== '/standalonepage' &&
            window.location.pathname !== '/standalonepagerecommendations' &&
            window.location.pathname !== '/standalonefinal'
        ) {
            history.push('/signUp');
        }
        
        let showNav: boolean = false;        
        if (
            (history.location.pathname === '/' &&
                name[0] !== 'internet' &&
                name[0] !== 'insurance' &&
                name[0] !== 'security' &&
                name[0] !== 'internet-demo' &&
                name[0] !== 'insurance-demo' &&
                name[0] !== 'security-demo') ||
            history.location.pathname == '/dashboard' ||
            history.location.pathname.includes('/oboardprocess/ghost-login') ||
            history.location.pathname === '/signUp' ||
            window.location.pathname === '/login' ||
            history.location.pathname === '/signUp/' ||
            history.location.pathname === '/signUp/next' ||
            history.location.pathname === '/dashboard/mortgage' ||
            history.location.pathname.includes('/dashboard/mortgage/') ||
            history.location.pathname === '/dashboard/title' ||
            history.location.pathname.includes('/dashboard/title/') ||
            history.location.pathname === '/dashboard/home-insurance' ||
            history.location.pathname.includes('/dashboard/home-insurance/') ||
            history.location.pathname === '/dashboard/home-insurance/steps' ||
            history.location.pathname === '/dashboard/home-insurance/plans' ||
            history.location.pathname === '/dashboard/home-insurance/order' ||
            history.location.pathname === '/dashboard/home-insurance/summary' ||
            history.location.pathname === '/dashboard/movers' ||
            history.location.pathname.includes('/dashboard/movers/') ||
            history.location.pathname === '/dashboard/movers/steps' ||
            history.location.pathname === '/dashboard/movers/recommended' ||
            history.location.pathname === '/dashboard/movers/quote' ||
            history.location.pathname === '/dashboard/storage' ||
            history.location.pathname.includes('/dashboard/storage/') ||
            history.location.pathname === '/dashboard/storage/:id' ||
            history.location.pathname === '/dashboard/movers/reserve_order_place' ||
            history.location.pathname === '/dashboard/movers/thankyou' ||
            history.location.pathname === '/dashboard/movers/reserve_storage_thankyou' ||
            history.location.pathname === '/dashboard/utilities' ||
            history.location.pathname.includes('/dashboard/utilities/') ||
            history.location.pathname === '/dashboard/utilities/old' ||
            history.location.pathname === '/dashboard/address' ||
            history.location.pathname.includes('/dashboard/address/') ||
            history.location.pathname === '/dashboard/internet' ||
            history.location.pathname.includes('/dashboard/internet/') ||
            history.location.pathname === '/dashboard/internet/summary' ||
            history.location.pathname === '/dashboard/internet/recommendations' ||
            history.location.pathname === '/dashboard/internet/steps' ||
            history.location.pathname === '/dashboard/internet/order' ||
            history.location.pathname === '/dashboard/internet/location' ||
            history.location.pathname === '/dashboard/driverlicense' ||
            history.location.pathname.includes('/dashboard/driverlicense/') ||
            history.location.pathname === '/dashboard/driverlicense/vehicleregistration' ||
            history.location.pathname === '/dashboard/home-security' ||
            history.location.pathname.includes('/dashboard/home-security/') ||
            history.location.pathname === '/dashboard/home-security/steps' ||
            history.location.pathname === '/dashboard/home-security/recommendations' ||
            history.location.pathname === '/dashboard/home-security/checkifqualify' ||
            history.location.pathname === '/dashboard/home-security/order' ||
            history.location.pathname === '/dashboard/home-security/summary' ||
            history.location.pathname === '/dashboard/checklist' ||
            history.location.pathname.includes('/dashboard/checklist/') ||
            history.location.pathname === '/dashboard/checklist/overdue' ||
            history.location.pathname === '/dashboard/checklist/completed' ||
            // history.location.pathname === '/dashboard/warranty' ||
            // history.location.pathname.includes('/dashboard/warranty/') ||
            history.location.pathname.includes('/dashboard/ourvendors') ||
            history.location.pathname.includes('/dashboard/ourvendors/') ||
            history.location.pathname.includes('/dashboard/autoease') ||
            history.location.pathname.match('/dashboard/*') ||
            history.location.pathname.includes('/setpassword') ||
            history.location.pathname.includes('/resetpassword') ||
            history.location.pathname.includes('/dashboard/savings-flow') ||
            history.location.pathname.includes('/dashboard/savings-flow/confirmSaving') ||
            history.location.pathname === '/servicerequirement' ||
            history.location.pathname === '/laCarte' ||
            history.location.pathname === '/scheduleacall' ||
            window.location.pathname === '/premovesignup' ||
            window.location.pathname === '/standalonepage' ||
            window.location.pathname === '/standalonepagerecommendations' ||
            window.location.pathname === '/standalonefinal' ||
            window.location.pathname === '/dashboard/home-warranty' ||
            window.location.pathname === '/dashboard/home-warranty/steps' ||
            window.location.pathname.includes('/dashboard/home-warranty/steps') ||
            window.location.pathname === '/dashboard/home-warranty/step4' ||
            window.location.pathname.includes('/dashboard/home-warranty/step4') ||
            window.location.pathname === '/dashboard/home-warranty/thanku' ||
            window.location.pathname.includes('/dashboard/home-warranty/thanku') ||
            window.location.pathname.includes('/dashboard/home-warranty') ||
            window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
            window.location.pathname === '/dashboard/subscription_details/home_protection'
        ) {
            showNav = true;
        }

        if (window.location.pathname === '/dashboard/my-documents/preview') {
            showNav = false;
        }
        if ( appType == 'WITHOUT_AUTH' ){
            showNav = true;
        }
        return (
            <Suspense
                fallback={
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                }
            >
                <div
                    className="AppMainPage"
                    style={{
                        paddingBottom:
                            history.location.pathname === '/' ||
                            history.location.pathname === '/signUp' ||
                            history.location.pathname === '/signUp/next' ||
                            history.location.pathname === '/login' ||
                            history.location.pathname.includes('/setpassword') ||
                            history.location.pathname.includes('/dashboard/home-warranty') ||
                            history.location.pathname.includes('/resetpassword')
                                ? '0px'
                                : '0px',
                        marginBottom:
                            history.location.pathname === '/' ||
                            history.location.pathname === '/signUp' ||
                            history.location.pathname === '/signUp/next' ||
                            history.location.pathname === '/login' ||
                            history.location.pathname.includes('/setpassword') ||
                            history.location.pathname.includes('/resetpassword') ||
                            history.location.pathname.includes('/bad-request') ||
                            history.location.pathname.includes('/server-error') ||
                            history.location.pathname.includes('/dashboard/internet') ||
                            history.location.pathname.includes('/dashboard/home-warranty') ||
                            history.location.pathname.includes('/dashboard/movers') ||
                            history.location.pathname.includes('/dashboard/home-security') ||
                            history.location.pathname.includes('/*') ||
                            (localStorage.getItem('isDouglasElliman') &&
                                localStorage.getItem('isDouglasElliman') == 'True') ||
                            history.location.pathname.includes('/dashboard/nextmove') ||
                            history.location.pathname.includes('/*') ||
                            history.location.pathname.includes('/dashboard/savings-flow') ||
                            history.location.pathname.includes('/dashboardhomeowner') ||
                            history.location.pathname.includes('/dashboard/my-documents/preview') ||
                            history.location.pathname.includes('/dashboard/my-documents') ||
                            history.location.pathname.includes('/dashboard/savings-flow/awesomeSaving') ||
                            history.location.pathname.includes('/dashboard/savings-flow/confirmSaving') ||
                            window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
                            window.location.pathname === '/dashboard/subscription_details/home_protection'
                                ? '0px'
                                :  appType !== 'WITHOUT_AUTH' ? '230px' : '0px'
                    }}
                >
                    <ReactNotification />
                    <Router>
                        {showNav ? <Navbar history={history} /> : null}
                        {/* {showNavLanding ? <NavbarLanding   /> : null} */}
                        <Routes />
                    </Router>
                </div>
                {appType === 'WITHOUT_AUTH' && <PodsFooter />}
            </Suspense>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        signupAction: bindActionCreators(Action2, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});
App.contextType = UIContext
export default connect(mapStateToProps, mapDispatchToProps)(App);
