import adt from '../../../Assets/images/HomeSecurity/adt.png';
import vivint from '../../../Assets/images/HomeSecurity/vivint.png';
import alder from '../../../Assets/images/HomeSecurity/alder.png';
import simpliSafe from '../../../Assets/images/HomeSecurity/simpliSafe.png';

const formatCurrency = ({ locale = 'en-US', value = 0 }) => {
    return new Intl.NumberFormat(locale).format(Math.round(value));
};

export const getCardsDatas = () => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);

        return [
            {
                id: 1,
                recommended: true,
                cardLogo: adt,
                title: 'ADT',
                monthlyfees: '47.99 - 67.99',
                upfrontCost: '$99 -$599',
                installationCost: '$99',
                waivedOff: true,
                installationType: 'Professional',
                special: 'MOST POPULAR',
                price: `Up to $${formatCurrency({ value: 1700 })}`,
                speciality: 'in Security Equipment including a Free Camera, Garage Controller and more*',
                topFeatures: 'Highest Rated System - Pro Installation - Best Rated 24/7 Monitoring',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                id: 2,
                recommended: true,
                cardLogo: alder,
                title: 'Alder',
                monthlyfees: '39.99 - 49.99',
                upfrontCost: '$49.99 activation',
                installationCost: 'NA',
                waivedOff: false,
                installationType: 'DIY',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: `$${formatCurrency({ value: 1000 })}`,
                speciality: 'in Security Equipment + free indoor camera*',
                topFeatures: '2 Way Voice + Lifetime Warranty on All Equipment',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                id: 3,
                recommended: false,
                cardLogo: vivint,
                title: 'Vivint',
                monthlyfees: '19.99 - 79.99',
                upfrontCost: '$25 activation',
                installationCost: '$100',
                waivedOff: true,
                installationType: 'Professional',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: '50% Off',
                speciality: 'of Monthly monitoring for life + $500 off equipment*',
                topFeatures: 'Best Home Automation. Professional Install. 2 Way Voice. Compatable with Google + Alexa',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                id: 4,
                recommended: false,
                cardLogo: simpliSafe,
                title: 'Simplisafe',
                monthlyfees: '9.99 - 24.99',
                upfrontCost: '$229 - $599',
                installationCost: 'NA',
                waivedOff: false,
                installationType: 'DIY',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: '35% OFF',
                speciality: 'of Security Equipments + 2 Months Free Monitoring*',
                topFeatures: 'Easy DIY Installation. Cellular Backup. Compatable with Alexa',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
        ];
    } else {
        return [];
    }
};
