import { all, call, put, takeLatest } from 'redux-saga/effects';
import { dmvFailure, dmvSuccess } from './actions';
import { DMV_DETAILS } from './actionTypes';
import apiJunction from '../../utils/api';
import { uiConfig } from '../../config';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* dmvPods(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `/api/pods/dmv-list/?state=${action.payload.state}`,
        });
        if (response.data && response.status === 200) {
            yield put(dmvSuccess({ dmv: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(dmvFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* dmv(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/concierge/dmv-detail/?state=${action.payload.state}`,
        });
        if (response.data && response.status === 200) {
            yield put(dmvSuccess({ dmv: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(dmvFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

export default function* dmvSaga() {
    let isPODS = false
    if(uiConfig.appType == "WITHOUT_AUTH"){
        isPODS = true
    }
    yield all([
        takeLatest(DMV_DETAILS, isPODS ? dmvPods : dmv)
    ]);
}
