import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../atoms/Button';
import '../../styles/insuranceCallToQuotesCard.scss';
import AnalyticsService from '../../utils/analytics-service';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    boxShadow?: any;
    onCall?: any;
    mobileNumber?: string;
    href?: string;
    onClick?: any;
    about?: any;
    isDouglasElliman:boolean;
    page?: string;
    brandName?: string;
    category?: string;
}

const CallToQuotesCard = (props: cardProps) => {

    const { page, brandName, category } = props;
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
            },
        },
    }));

    useEffect(() => {
        AnalyticsService.cardViewed({
            page,
            category_name: category || '',
            cardTitle: brandName || '',
            brandName: brandName || '',
            eventName: 'Product Card Viewed'
        })
      return () => {
      }
    }, [])

    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <div
            style={{
                width: props.width ? props.width : '200px',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 10px #9C9DA1',
                border: props.border ? props.border : null,
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '',
                padding: props.cardPadding ? props.cardPadding : '15px',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : classes.root}
        >
            <div className="insuranceCallCardMain">
                <div className="cardLogo">
                    <div className="LogoDiv">
                        <img
                            width={props.imgWidth ? props.imgWidth : '80px'}
                            height={props.imgHeight ? props.imgHeight : '35px'}
                            src={props.cardImage}
                            style={{
                                marginRight: 'auto',
                            }}
                            alt=""
                        />
                    </div>
                    {/* <div className="viewCoverage">
                  <a href="" onClick={props.about}>About</a>
                </div>  */}
                </div>
                <div className="callAquoteCardButtonMobile">
                    <div className="callFlexDiv">
                        <div className="ButtonInsuranceCard">
                            <Button
                                backgroundColor={
                                    props.isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273E59'
                                }
                                onClick={props.onCall}
                                width="100%"
                                className='call-toquote-btn'
                            >
                                Call to Quote
                            </Button>
                        </div>
                        <div className="orText">or</div>
                        <div className="mobileText">Call {props.mobileNumber}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallToQuotesCard;
