import React, { Component } from 'react';
import './utility.scss';
import Button from '../../components/atoms/Button';
import RoomIcon from '@material-ui/icons/Room';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import * as UtilityAction from '../../store/utility/actions';
import UtilityNewCard from '../../components/atoms/utilityNewCards';
import Gas from '../../Assets/images/utilityImages/gas1';
import Electricity from '../../Assets/images/utilityImages/electricity1';
import Image from '../../Assets/images/utilityImages/mainImage';
import Trash from '../../Assets/images/utilityImages/trash1';
import Water from '../../Assets/images/utilityImages/water1';
import * as Action from '../../store/dashboard/actions';
import Modal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Footer from './Footer';
import Skeleton from 'react-loading-skeleton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { scheduleSegment } from '../../utils/utlis';
// import ElectricityGasMain from '../../components/Utility/ElectricityGasMainCard';

// import FireTopIcon from '../../Assets/images/utilityImages/fireTopIcon.svg';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    UtilityAction?: any;
    UtilityState?: any;
    CheckListDashboardState: any;
};

type initialState = {
    address: string;
    street: string | null | undefined;
    city: string | null | undefined;
    state: any;
    zipcode: string | null | undefined;
    moveId: any;
    destZip: any;
    id: any;
    serviceId: string;
    isDataArrived: boolean;
    calendlyOpen: boolean;
    reschedule_link: string;
    nylas_full_service_scheduler_url: string;
    appointmentDetailsArrived: boolean;
    isDataArrived2: boolean;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info_enabled: any;
    isDouglasElliman: boolean;
    hashcode: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    concierge_name: string;
    appointment_type: any;
    brokerage_key: any;
    agent_key: any;
};

export class UtilityOld extends Component<initialProps, initialState> {
    state = {
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        destZip: '',
        id: '',
        isDataArrived: true,
        serviceId: '',
        calendlyOpen: false,
        reschedule_link: '',
        nylas_full_service_scheduler_url: '',
        appointmentDetailsArrived: true,
        isDataArrived2: true,
        phone: '',
        formOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        isSubmitClicked: false,
        custom_schedule_call_info_enabled: false,
        isDouglasElliman: false,
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        concierge_name: '',
        appointment_type: '',
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const dest: any = data && data.source;
            const source: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.source_street,
                address: data && data.source_full_address,
                moveId: data && data.id,
                id: dest.id,
                hashcode: data && data.hash_code,
            });

            this.setState({
                destZip: source && source.zip_code,
            });
            const payload = {
                source_zip: dest && dest.zip_code,
                dest_zip: source && source.zip_code,
            };
            this.props.UtilityAction.getUtility(payload);
        } else {
            this.props.commonAction.moveGet();
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
        window.scrollTo(0, 0);
        if (slug) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');
            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                `Banner Viewed`,
                {
                    page: 'Utility Providers',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    category_name: 'Utility Providers',
                    banner: true,
                    banner_title: 'You qualify for savings on utilities',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest: any = cur.move[0] && cur.move[0].source;
            const source: any = cur.move[0] && cur.move[0].destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: cur.move[0] && cur.move[0].source_street,
                address: cur.move[0] && cur.move[0].source_full_address,
                moveId: cur.move[0] && cur.move[0].id,
                id: dest.id,
                hashcode: cur.move[0].hash_code,
            });

            this.setState({
                destZip: source && source.zip_code,
            });

            const payload = {
                source_zip: dest && dest.zip_code,
                dest_zip: source && source.zip_code,
            };
            this.props.UtilityAction.getUtility(payload);
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/utilities')) {
                    currentState.serviceId = item.id;
                    nextProps.dashboardAction.updateServicePercentage({
                        data: {
                            completed_percentage: 50,
                        },
                        service_id: item.id,
                    });
                }
            });
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            // currentState.custom_schedule_call_info = nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.custom_schedule_call_info
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.appointmentDetails &&
            nextProps.commonState.appointmentDetails.reschedule_link &&
            currentState.appointmentDetailsArrived
        ) {
            currentState.appointmentDetailsArrived = false;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.concierge_name =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.concierge_name;
            currentState.appointment_type =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.appointment_type;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }

    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };

    handleStep1 = () => {};
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        this.props.commonAction.appointmentGet(payload);
        this.setState({
            appointmentDetailsArrived: true,
        });
    };
    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };
    calendlyHandle = () => {
        if (this.state.serviceId) {
            this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 100,
                },
                service_id: this.state.serviceId,
            });
        }
        this.setState({ calendlyOpen: true });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Schedule Call Button Clicked',
            'Utility Providers',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Utility Providers',
            'Schedule A Call',
            false,
            '',
            true,
            'You Qualify for Savings on Utilities',
            move_id,
        );
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let object: any = {};
        if (
            this.props.UtilityState &&
            this.props.UtilityState.utility &&
            this.props.UtilityState.utility.destination &&
            this.props.UtilityState.utility.source &&
            this.props.UtilityState.utility.destination.length > 0 &&
            this.props.UtilityState.utility.source.length > 0
        ) {
            // object = this.props.UtilityState.utility.source[0];
            object = this.props.UtilityState.utility.source.find((item: any) => {
                return item.city.id === this.state.id;
            });
        }
        const { isDouglasElliman } = this.state;
        return (
            <div>
                {isDouglasElliman && (
                    <div>
                        <TemplateUpper
                            pageImage="utility"
                            onClick={
                                this.state.custom_schedule_call_info_enabled
                                    ? () => this.formHandler()
                                    : this.state.nylas_full_service_scheduler_url || this.state.reschedule_link
                                    ? () => this.calendlyHandle()
                                    : () => null
                            }
                            headerText={`You qualify for`}
                            subheaderText={'You could save money by switching to an alternate provider.'}
                            secondLine={'Schedule a call to learn more.'}
                            highlightedText={'saving on utilities'}
                            buttonText={
                                this.state.custom_schedule_call_info_enabled
                                    ? 'Contact Us'
                                    : this.state.nylas_full_service_scheduler_url || this.state.reschedule_link
                                    ? this.state.reschedule_link
                                        ? 'Reschedule'
                                        : 'Schedule A Call'
                                    : ''
                            }
                        />
                    </div>
                )}
                {this.state.formOpen && (
                    <Modal
                        isShowModal={this.state.formOpen}
                        onCloseModal={() => {
                            this.setState({ formOpen: false });
                        }}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="line12"></div>
                            <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                              !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                            ? 'Please enter valid email'
                                            : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                            ? 'Please enter valid phone number'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={`0 0 3px 0 ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    width="130px"
                                    height="50px"
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    borderRadius="5px"
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    onClick={() => this.setState({ formOpen: false })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius="5px"
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeFormHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className="utilitymain-head">
                    {!isDouglasElliman && (
                        <div>
                            <h2 className="heading">Utility Providers</h2>
                            <div className="path">
                                <p
                                    className="Para"
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard {'> '}
                                </p>
                                <p style={{ marginLeft: '5px' }}>Utility Providers</p>
                            </div>
                        </div>
                    )}

                    <div className={isDouglasElliman ? 'utility UtilityMainRemoveDouglas' : 'utility'}>
                        {!isDouglasElliman && (
                            <div
                                className="utilityBlackCard"
                                style={{ background: slug && slug.primary_color ? slug.primary_color : '#002e62' }}
                            >
                                <div className="utilitymainCard">
                                    <div className="utilityhead">You Qualify for Savings on Utilities</div>
                                    <div className="utilitypara111">
                                        You could save money by switching to an alternate provider. Schdule a call with
                                        your concierge to learn more.
                                    </div>
                                    {this.state.custom_schedule_call_info_enabled ? (
                                        <div>
                                            <Button
                                                className="footerbtn"
                                                backgroundColor="#FFFFFF"
                                                width="120px"
                                                height="35px"
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273e59'
                                                }
                                                borderRadius="5px"
                                                fontSize="12px"
                                                fontWeight="600"
                                                border={`1px solid ${
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273e59'
                                                }`}
                                                onClick={() => this.formHandler()}
                                            >
                                                Contact Us
                                            </Button>
                                        </div>
                                    ) : this.state.nylas_full_service_scheduler_url || this.state.reschedule_link ? (
                                        <Button
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `0 0 3px 0 ${slug.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            width="150px"
                                            height="40px"
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius="5px"
                                            fontSize="14px"
                                            onClick={() => this.calendlyHandle()}
                                            // onClick={() => this.props.tabChangeHandler('steps')}
                                        >
                                            {this.state.reschedule_link ? 'Reschedule' : 'Schedule A Call'}
                                        </Button>
                                    ) : (
                                        <Skeleton height="40px" width="100px" />
                                    )}
                                </div>
                                <div className="utilityimage1">
                                    {/* <img src={image} alt="image.svg" /> */}
                                    <Image />
                                </div>
                            </div>
                        )}

                        {/* <div className="utilityHeadMainTop"> */}
                        {/* <div className="utilityheadingCongo">Congratulations! You have qualified these savings</div> */}

                        {/* <div className="utilityfirstDiv">
                                <div className="utilitynewAddressText">Your new moving address</div>
                                <div
                                    className={
                                        isDouglasElliman
                                            ? 'utilitysecondDiv disbledAutocomplete utilitysecondDivAddColor'
                                            : 'utilitysecondDiv  disbledAutocomplete'
                                    }
                                >
                                    <RoomIcon
                                        className={
                                            isDouglasElliman
                                                ? 'utilitylocationIcon changeColorUtilityLocationIcon'
                                                : 'utilitylocationIcon'
                                        }
                                    />
                                    <AutoComplete
                                        value={this.state.address ? this.state.address : ''}
                                        onChange={e => {
                                            this.setState({ address: e });
                                        }}
                                        className="utility"
                                        onSelect={this.handleSelect}
                                        placeholder="Location"
                                        // inputTitle="To"
                                        edit={true}
                                    />
                                </div>
                            </div> */}
                        {/* </div> */}
                        {/* <div className="utilityMainCardsFlow">
                            <ElectricityGasMain GasElect="Gas" icon={FireTopIcon} handleNext={this.handleStep1()} />
                        </div> */}

                        <div className="utilitytab-div">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/utilities' ||
                                        this.props.history.location.pathname === `/dashboard/utilities/`)
                                        ? isDouglasElliman
                                            ? 'utilitytab-active changeColorUtility'
                                            : 'utilitytab-active'
                                        : 'utilitytab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/utilities');
                                }}
                            >
                                New Utility Providers
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/utilities/old' ||
                                        this.props.history.location.pathname === `/dashboard/utilities/old/`)
                                        ? isDouglasElliman
                                            ? 'utilitytab-active changeColorUtility'
                                            : 'utilitytab-active'
                                        : 'utilitytab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/utilities/old');
                                }}
                            >
                                Old Utility Providers
                            </div>
                        </div>

                        <div className="utilitycontentDiv">
                            <div className="utilityinnerContent">
                                <div className="utilityfirstDiv">
                                    <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Your old address</div>
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'utilitysecondDiv disbledAutocomplete utilitysecondDivAddColor'
                                                : 'utilitysecondDiv  disbledAutocomplete'
                                        }
                                    >
                                        <RoomIcon
                                            className={
                                                isDouglasElliman
                                                    ? 'utilitylocationIcon changeColorUtilityLocationIcon'
                                                    : 'utilitylocationIcon'
                                            }
                                        />
                                        <AutoComplete
                                            value={this.state.address ? this.state.address : ''}
                                            onChange={e => {
                                                this.setState({ address: e });
                                            }}
                                            className="utility"
                                            onSelect={this.handleSelect}
                                            placeholder="Location"
                                            disabled={true}
                                            // inputTitle="To"
                                            edit={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {object && Object.keys(object).length > 0 ? (
                            <div className="utilityCards">
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={<Electricity />}
                                    cardTitle={'Electricity'}
                                    cardSubTitle={
                                        object && object.electricity_provider_name
                                            ? object.electricity_provider_name
                                            : 'null'
                                    }
                                    phone={
                                        object && object.electricity_provider_phone
                                            ? object.electricity_provider_phone
                                            : 'null'
                                    }
                                    url={
                                        object && object.electricity_provider_website
                                            ? object.electricity_provider_website
                                            : 'null'
                                    }
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={<Gas />}
                                    cardTitle={'Gas'}
                                    cardSubTitle={
                                        object && object.gas_provider_name ? object.gas_provider_name : 'null'
                                    }
                                    phone={object && object.gas_provider_phone ? object.gas_provider_phone : 'null'}
                                    url={object && object.gas_provider_website ? object.gas_provider_website : 'null'}
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={<Trash />}
                                    cardTitle={'Trash'}
                                    cardSubTitle={
                                        object && object.trash_provider_name ? object.trash_provider_name : 'null'
                                    }
                                    phone={object && object.trash_provider_phone ? object.trash_provider_phone : 'null'}
                                    url={
                                        object && object.trash_provider_website ? object.trash_provider_website : 'null'
                                    }
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={<Water />}
                                    cardTitle={'Water'}
                                    cardSubTitle={
                                        object && object.water_provider_name ? object.water_provider_name : 'null'
                                    }
                                    phone={object && object.water_provider_phone ? object.water_provider_phone : 'null'}
                                    url={
                                        object && object.water_provider_website ? object.water_provider_website : 'null'
                                    }
                                />
                            </div>
                        ) : (
                            <div className="utilityCards">
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={null}
                                    cardTitle={''}
                                    cardSubTitle={''}
                                    phone={''}
                                    url={''}
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={null}
                                    cardTitle={''}
                                    cardSubTitle={''}
                                    phone={''}
                                    url={''}
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={null}
                                    cardTitle={''}
                                    cardSubTitle={''}
                                    phone={''}
                                    url={''}
                                />
                                <UtilityNewCard
                                    width="30%"
                                    cardMargin="0px 10px 20px 0px"
                                    cardImage={null}
                                    cardTitle={''}
                                    cardSubTitle={''}
                                    phone={''}
                                    url={''}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={() => this.nylasModalClosed()}
                        showClose={false}
                        className="scheduleCallutility"
                    >
                        <div className="scheduleCallBack" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}
                        {this.state.nylas_full_service_scheduler_url || this.state.reschedule_link ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.reschedule_link
                                            ? this.state.reschedule_link
                                            : this.state.nylas_full_service_scheduler_url
                                    }&utm_source=platform&utm_medium=web&utm_content=utility-request&utm_campaign=utility-platform
                                   `}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        UtilityAction: bindActionCreators(UtilityAction, dispatch),
        dashboardAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    UtilityState: state.utility,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(UtilityOld);
