import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={51.799} height={48.099} viewBox="0 0 51.799 48.099">
            <defs>
                <style>
                    {slug && slug.primary_color && slug.color_shade4
                        ? props.isDouglasElliman
                            ? `.garageprefix__a{fill:#fff}.garageprefix__b{fill:#ffffff}.garageprefix__c{fill:#333333}`
                            : `.garageprefix__a{fill:#fff}.garageprefix__b{fill:${slug.color_shade4}}.garageprefix__c{fill:${slug.primary_color}}`
                        : '.garageprefix__a{fill:#fff}.garageprefix__b{fill:#5b7088}.garageprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <path
                className="garageprefix__a"
                d="M28.887 29.057v6.973H22.91v-6.754a3.122 3.122 0 012.988-3.207 2.984 2.984 0 012.989 2.988z"
            />
            <path
                className="garageprefix__a"
                d="M41.624 35.759v.315H17.575l5.235-5.235a7.453 7.453 0 015.236-2.165h5.735a5.383 5.383 0 013.385 1.184 1.4 1.4 0 01.259.2l3.108 3.071a3.679 3.679 0 011.091 2.63z"
            />
            <path
                className="garageprefix__b"
                d="M41.624 36.074v7.4l-1.849 1.85h-27.75l-.314-.943-.074-.24-1.462-4.367 2.072-2.072a5.577 5.577 0 013.922-1.628z"
            />
            <path className="garageprefix__a" d="M.925 13.875h3.7v33.299h-3.7zM47.174 13.875h3.7v33.299h-3.7z" />
            <path className="garageprefix__b" d="M.925.925h49.949v12.95H.925z" />
            <g>
                <circle
                    className="garageprefix__c"
                    cx={2.733}
                    cy={2.733}
                    r={2.733}
                    transform="translate(17.154 42.633)"
                />
                <circle
                    className="garageprefix__c"
                    cx={2.733}
                    cy={2.733}
                    r={2.733}
                    transform="translate(31.365 42.633)"
                />
                <path
                    className="garageprefix__c"
                    d="M28.674 44.399h-3.7a.925.925 0 100 1.85h3.7a.925.925 0 000-1.85z"
                />
                <path
                    className="garageprefix__c"
                    d="M42.55 35.756a4.591 4.591 0 00-1.354-3.269l-2.846-2.838a6.433 6.433 0 00-4.575-1.9H28.04a8.383 8.383 0 00-5.89 2.438l-4.958 4.962h-1.018a6.433 6.433 0 00-4.579 1.9l-2.075 2.07a.929.929 0 00-.222.947l1.85 5.55a.925.925 0 00.877.633h1.85a.925.925 0 000-1.85h-1.184l-1.458-4.376 1.67-1.674a4.6 4.6 0 013.271-1.354h24.525v6.092l-1.58 1.578a.926.926 0 001.31 1.31l1.85-1.85a.932.932 0 00.27-.655zm-5.5-4.8l2.842 2.842a2.8 2.8 0 01.738 1.354h-9.18v-5.553h2.325a4.6 4.6 0 013.275 1.35zm-13.584.542a6.527 6.527 0 014.579-1.9h1.56v5.551h-9.798z"
                />
                <path
                    className="garageprefix__c"
                    d="M28.674 38.849h-1.85a.925.925 0 100 1.85h1.85a.925.925 0 100-1.85z"
                />
                <path
                    className="garageprefix__c"
                    d="M9.25 46.249h-3.7V25.9h40.7v20.35h-3.7v1.85h8.325a.925.925 0 00.925-.925V.925A.925.925 0 0050.874 0H.925A.925.925 0 000 .925v46.249a.925.925 0 00.925.925H9.25zM1.85 1.85h48.1v11.1H1.85zm3.7 16.65h40.7v1.85H5.55zm40.7-1.85H5.55V14.8h40.7zM1.85 14.8H3.7v31.449H1.85zm3.7 9.25V22.2h40.7v1.85zm44.4 22.2H48.1V14.8h1.85z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
