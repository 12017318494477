import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getUtilitySuccess, getUtilityFailure } from './actions';
import { GET_UTILITY_DATA } from './actionTypes';
import apiJunction from '../../utils/api';
import { uiConfig } from '../../config';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* getUtility(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/concierge/utilities/?source_zip=${action.payload.source_zip}&dst_zip=${action.payload.dest_zip}`,
        });
        if (response.data && response.status === 200) {
            yield put(getUtilitySuccess({ utility: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getUtilityFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getUtilityPODS(action: any) {
    try {
        const {state, city, zipcode } = action.payload

        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `api/pods/utilities-list/?address_state=${state}&address_city=${city}&address_zip=${zipcode}&slug=${uiConfig.domain}`
        });
        if (response.data && response.status === 200) {
            yield put(getUtilitySuccess({ utility: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getUtilityFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* internetSaga() {
    let isPODS = false
    if(uiConfig.appType == "WITHOUT_AUTH"){
        isPODS = true
    }
    yield all([takeLatest(GET_UTILITY_DATA, isPODS ? getUtilityPODS : getUtility )]);
}
