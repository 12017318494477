import React, { Component } from 'react';
import './utility.scss';
import RoomIcon from '@material-ui/icons/Room';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import InfoIcon from '@material-ui/icons/Info';
import UtilityNewCard from '../../components/atoms/utilityNewCards';
import Gas from '../../Assets/images/utilityImages/gas';
import Electricity from '../../Assets/images/utilityImages/electricity';
import Trash from '../../Assets/images/utilityImages/trash';
import Water from '../../Assets/images/utilityImages/water';
// import ReactTooltip from 'react-tooltip';
import UtilityNotFound from './UtilityNotFound';
import localStorage from '../../utils/localStorage';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history?: any;
    UtilityState?: any;
    onAddressChange?: any;
};

type initialState = {
    addressData: any;
    isDouglasElliman: boolean;
    isAnalitycsTriggerredOnce: any;
};

export class UtilityPODS extends Component<initialProps, initialState> {
    uiContext = this.context
    state = {
        addressData: {
            street: '',
            city: '',
            apt: '',
            zipcode: '',
            address: '',
        },
        addressInput: "",
        isDouglasElliman: false,
        isAnalitycsTriggerredOnce: {
            address: false
        }
    };

    componentDidMount() {
        const addressObjStr = localStorage.getItem('pods_destination_address'); 
        if(addressObjStr){
            const addressObj = JSON.parse(addressObjStr)
            this.setState({ addressData: addressObj })
        }

        AnalyticsService.pageViewed({ page: 'Utility Providers' });
    }
    componentDidUpdate(){
        
    }

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                const addressString = results[0].formatted_address;
                let addressObj = {
                    street: `${streetNumber} ${route}`,
                    city,
                    zipcode: zip,
                    address: addressString,
                    state
                };
                localStorage.setItem('pods_destination_address', addressObj);
                this.setState({
                    addressData: addressObj,
                });
                this.props.onAddressChange();
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        addressData: addressString,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };


    onChangeHandlerAddress = async (e: any, name: string) => {
        if (!this.state.isAnalitycsTriggerredOnce.address) {
            AnalyticsService.formFieldInput({ 
                page: "Utility Providers",
                hasMinimalFields: true,
                category_name: 'Utility Providers',
                form_field_name: 'Your address'
            });
        }
        
        this.setState({
            isAnalitycsTriggerredOnce: {
                address: true
            }
        })

        let addressData: any;
        if (name === 'address') {
            addressData = { ...this.state.addressData, [name]: e };
        } else {
            addressData = { ...this.state.addressData, [name]: e.target.value };
        }
        await this.setState(() => ({ addressData }));
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let object: any = {};
        if (
            this.props.UtilityState &&
            this.props.UtilityState.utility &&
            this.props.UtilityState.utility.length > 0
        ) {
            try{
                let utilityObject = this.props.UtilityState.utility[0];
                delete utilityObject["city"]
                const keys = Object.keys(utilityObject)
                for(let i=0; i<keys.length; i++){
                    if(keys[i].includes("provider_name") && utilityObject[keys[i]] != "Not Found"){
                        object[keys[i]] = utilityObject[keys[i]]
                    }
                    if(keys[i].includes("provider_phone") && utilityObject[keys[i]] != "Not Found"){
                        object[keys[i]] = utilityObject[keys[i]]
                    }
                    if(keys[i].includes("provider_website") && utilityObject[keys[i]] != "Not Found"){
                        object[keys[i]] = utilityObject[keys[i]]
                    }
                }
            } catch(_e){
                console.error("Something went wrong in UtilityPODS.tsx")
                object = {}
            }
        }
        const { isDouglasElliman } = this.state;
        return (
            <div>
                <div className="utilitymain-head">
                    {!isDouglasElliman && (
                        <div>
                            <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Utility Providers</h2>
                            <div className="path">
                                <p
                                    className="Para"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard {'>'}
                                </p>{' '}
                                <p style={{ marginLeft: '5px' }}>Utility Providers</p>
                            </div>
                        </div>
                    )}
                    <div className={'utility'}>
                    <div className="utilityfirstDiv">
                        <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Your address</div>
                            <div
                                className={'utilitysecondDiv '}
                            >
                                <RoomIcon
                                    className={'utilitylocationIcon'}
                                />
                                <AutoComplete
                                    value={this.state.addressData?.address}
                                    onChange={e => this.onChangeHandlerAddress(e, 'address')}
                                    onSelect={this.handleSelect}
                                    className="utility"
                                    edit={true}
                                />
                            </div>
                    </div>
                    {object && Object.keys(object).length > 0 ? (
                        <div className="utilitycontentDiv">
                            
                            <div className="utilityheading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>
                                Utility providers in your area
                            </div>
                            <div className="utilitysubheading">
                                Here are your utility providers for your future home.
                            </div>
                            <div className="utilityinnerContent">
                                {/* <div className="utilityfirstDiv">
                                    <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Your new moving address</div>
                                        <div
                                            className={'utilitysecondDiv '}
                                        >
                                            <RoomIcon
                                                className={'utilitylocationIcon'}
                                            />
                                            <AutoComplete
                                                value={this.state.addressData && this.state.addressData.street}
                                                onChange={e => this.onChangeHandlerAddress(e, 'street')}
                                                onSelect={this.handleSelect}
                                                placeholder="Enter address"
                                                className="input"
                                            />
                                        </div>
                                </div> */}
                                {/* <div className="iconInfo">
                                    Why can’t you order for trash and water?{' '}
                                    <ReactTooltip place="top" type="dark" effect="float" multiline={true} />{' '}
                                    <InfoIcon
                                        style={{ color: '#6B6C6F', marginRight: '10px' }}
                                        data-tip="Trash and water utilities are best <br/> managed directly as there are many <br /> variations across the country to set <br /> up trash and water utilities."
                                    />
                                </div> */}
                            </div>

                            
                                <div className="utilityCards">
                                    <UtilityNewCard
                                        height="auto"
                                        cardMargin="0px 10px 20px 0px"
                                        cardImage={
                                            <Electricity
                                                height="50px"
                                                width="50px"
                                            />
                                        }
                                        cardTitle={'Electricity'}
                                        cardSubTitle={
                                            object && object.electricity_provider_name
                                                ? object.electricity_provider_name
                                                : 'null'
                                        }
                                        phone={
                                            object && object.electricity_provider_phone
                                                ? object.electricity_provider_phone
                                                : 'null'
                                        }
                                        url={
                                            object && object.electricity_provider_website
                                                ? object.electricity_provider_website
                                                : 'null'
                                        }
                                        showPlaceOrder={false}
                                        showDoIt={false}
                                        isDouglasElliman={isDouglasElliman}
                                    />
                                    <UtilityNewCard
                                        height="auto"
                                        cardMargin="0px 10px 20px 0px"
                                        cardImage={
                                            <Gas
                                                height="50px"
                                                width="50px"
                                            />
                                        }
                                        cardTitle={'Gas'}
                                        cardSubTitle={
                                            object && object.gas_provider_name ? object.gas_provider_name : 'null'
                                        }
                                        phone={object && object.gas_provider_phone ? object.gas_provider_phone : 'null'}
                                        url={
                                            object && object.gas_provider_website ? object.gas_provider_website : 'null'
                                        }
                                        showPlaceOrder={false}
                                        showDoIt={false}
                                        isDouglasElliman={isDouglasElliman}
                                    />
                                    <UtilityNewCard
                                        height="auto"
                                        cardMargin="0px 10px 20px 0px"
                                        cardImage={
                                            <Trash
                                                height="50px"
                                                width="50px"
                                            />
                                        }
                                        cardTitle={'Trash'}
                                        cardSubTitle={
                                            object && object.trash_provider_name ? object.trash_provider_name : 'null'
                                        }
                                        phone={
                                            object && object.trash_provider_phone ? object.trash_provider_phone : 'null'
                                        }
                                        url={
                                            object && object.trash_provider_website
                                                ? object.trash_provider_website
                                                : 'null'
                                        }
                                        showDoIt={false}
                                        isDouglasElliman={isDouglasElliman}
                                    />
                                    <UtilityNewCard
                                        height="auto"
                                        cardMargin="0px 10px 20px 0px"
                                        cardImage={
                                            <Water
                                                height="50px"
                                                width="50px"
                                            />
                                        }
                                        cardTitle={'Water'}
                                        cardSubTitle={
                                            object && object.water_provider_name ? object.water_provider_name : 'null'
                                        }
                                        phone={
                                            object && object.water_provider_phone ? object.water_provider_phone : 'null'
                                        }
                                        url={
                                            object && object.water_provider_website
                                                ? object.water_provider_website
                                                : 'null'
                                        }
                                        showDoIt={false}
                                        isDouglasElliman={isDouglasElliman}
                                    />
                                </div>
                        </div>) :
                        (
                            <UtilityNotFound onAddressChange={this.props.onAddressChange} slug={slug}/>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default UtilityPODS;
