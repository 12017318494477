import React from 'react';
import ButtonNew from '../atoms/Button';
import './homeServiceCard.scss';

type initialProps = {
    image?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    onButtonClick?: any;
    history?: any;
};

const HomeServiceCard = (props: initialProps) => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div className="homeServiceCardMain">
            <div className="image">
                <img src={props.image} className="imageHomeService" />
            </div>
            <div className="homeServiceCardInnerDiv">
                <div className="titleHomeService">{props.title}</div>
                <div className="descriptionHomeService">{props.description}</div>
                <div className="buttonDivHomeService">
                    <ButtonNew
                        backgroundColor="#ffffff"
                        color={
                            isDouglasElliman
                                ? '#100B28'
                                : slug && slug.primary_color
                                ? slug && slug.primary_color
                                : '#273E59'
                        }
                        border={`1px solid ${
                            isDouglasElliman
                                ? '#100B28'
                                : slug && slug.primary_color
                                ? slug && slug.primary_color
                                : '#273E59'
                        }`}
                        className="buttonHomeService"
                        onClick={props.onButtonClick}
                    >
                        {props.buttonText}
                    </ButtonNew>
                </div>
            </div>
        </div>
    );
};

export default HomeServiceCard;
