import * as React from 'react';
import { colorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={52.979} height={52.978} viewBox="0 0 52.979 52.978" {...props}>
            <defs>
                <style>{`.InsuranceMainStraisprefix__b{fill:${ colorSelector(props.fromLanding || window.location.pathname.includes('landing'), "b") }}`}</style>
            </defs>
            <path
                d="M51.654 3.973v47.681H1.325v-7.947h7.947V35.76h7.947v-7.947h7.947v-7.947h7.947v-7.947h7.947V3.973z"
                fill={ colorSelector(props.fromLanding || window.location.pathname.includes('landing'))}
            />
            <path
                className="InsuranceMainStraisprefix__b"
                d="M1.325 26.489a1.32 1.32 0 00.935-.387L23.841 4.521v4.75a1.325 1.325 0 102.649 0V1.324a1.291 1.291 0 00-.1-.506 1.32 1.32 0 00-1.221-.819h-7.947a1.325 1.325 0 100 2.649h4.749L.39 24.226a1.324 1.324 0 00.938 2.262zM51.655 2.649h-10.6a1.325 1.325 0 00-1.324 1.324v6.622h-6.622a1.325 1.325 0 00-1.324 1.324v6.622h-6.622a1.325 1.325 0 00-1.324 1.324v6.622h-6.622a1.325 1.325 0 00-1.324 1.324v6.622H9.271a1.325 1.325 0 00-1.324 1.324v6.622H1.325a1.325 1.325 0 00-1.324 1.328v7.947a1.325 1.325 0 001.324 1.324h50.33a1.325 1.325 0 001.324-1.324V3.973a1.325 1.325 0 00-1.324-1.324zM50.331 50.33H2.65v-5.3h6.622a1.325 1.325 0 001.324-1.324v-6.622h6.622a1.325 1.325 0 001.324-1.324v-6.622h6.622a1.325 1.325 0 001.324-1.324v-6.622h6.622a1.325 1.325 0 001.324-1.324v-6.619h6.622a1.325 1.325 0 001.324-1.324V5.303h7.947z"
            />
        </svg>
    );
}

export default SvgComponent;
