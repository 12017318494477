import React, { Component } from 'react';
import './MovingThankYou.scss';
import ThankYouMoverCard from '../../components/atoms/ThankYouMoverCard';
import MovingNextCard from '../../components/atoms/MovingNextCard';
import MovingCar from '../../Assets/images/Moving/MovingCar.svg';
import MovingStorage from '../../Assets/images/Moving/MovingStorage.svg';
import './Moving.scss';
import editIcon from '../../Assets/images/Moving/editIcon.svg';
import ButtonNew from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MoverAction from '../../store/move/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import { CircularProgress } from '@material-ui/core';
import movingD from '../../Assets/images/DouglasImages/dashboardIcons/movingDouglus.svg';
import storageD from '../../Assets/images/DouglasImages/dashboardIcons/storageD.png';
import { buttonClicked } from '../../utils/utlis';

type initialProps = {
    checkedItems?: any;
    history?: any;
    location?: any;
    moverAction?: any;
    moverState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    serviceId?: any;
    isDataArrived2?: boolean;
};

class MovingThankYou extends Component<initialProps, initialState> {
    state: initialState = {
        serviceId: '',
        isDataArrived2: true,
    };

    componentDidMount() {
        this.props.moverAction.movers();
        this.props.dashboardAction.checklist();

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Moving Services',
                    category_name: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.serviceId = item.id;
                }
            });
        }

        return currentState;
    }

    backToDashboard = () => {
        this.props.history.push({
            pathname: '/dashboard',
            state: {
                event: 'start',
            },
        });

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Back to Dashboard',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Moving Services',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={isDouglasElliman ? 'moving-thankyou-main moving-main-pageDouglas' : 'moving-thankyou-main'}>
                <div>
                    <h2 className="heading">Moving Services</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p
                            className="Para"
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/movers');
                            }}
                        >
                            Moving Services {'> '}
                        </p>
                        <p
                            className="Para"
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/movers/quote');
                            }}
                        >
                            Order Placing {'> '}
                        </p>
                        <p>Order Summary</p>
                    </div>
                </div>
                <div
                    className="edit-mobile"
                    onClick={() => {
                        this.props.history.push({
                            pathname: '/dashboard/movers/quote',
                            state: {
                                quote: true,
                                checkedItems:
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.checkedItems &&
                                    this.props.location.state.checkedItems.length &&
                                    this.props.location.state.checkedItems,
                            },
                        });
                        window.scrollTo(0, 0);
                    }}
                >
                    <span>
                        <img src={editIcon} />
                    </span>
                    Edit Answers
                </div>
                <div className="tab-div-moving thankyou-tab">
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            (this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                ? 'tab-active'
                                : 'tab-text'
                        }
                    >
                        Moving
                    </div>
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            this.props.history.location.pathname === '/dashboard/autoease'
                                ? 'tab-active'
                                : 'tab-text'
                        }
                        onClick={() => {
                            if (this.props && this.props.history) {
                                this.props.history.push('/dashboard/autoease');
                            }
                        }}
                    >
                        Auto Shipping
                    </div>
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            this.props.history.location.pathname === '/dashboard/storage'
                                ? 'tab-active'
                                : 'tab-text'
                        }
                        onClick={() => {
                            if (this.props && this.props.history) {
                                this.props.history.push('/dashboard/storage');
                            }
                        }}
                    >
                        Reserve a Storage Unit
                    </div>
                </div>
                <div className="main-heading-thankyou">Awesome! That's one thing off your plate.</div>
                <div className="sub-heading-thankyou">
                    Representatives from the moving companies you selected will call you in 24-48 hours
                </div>
                <div className="wrapper">
                    <div className="wrapper-2">
                        <div className="selected-heading">Selected Movers</div>
                        {this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.checkedItems &&
                        this.props.location.state.checkedItems.length ? (
                            <div className="selected-movers-cards">
                                {this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.checkedItems &&
                                    this.props.location.state.checkedItems.length &&
                                    this.props.location.state.checkedItems.map((item: any) => {
                                        return (
                                            <div style={{ marginBottom: '20px' }}>
                                                <ThankYouMoverCard
                                                    icon={item.van_line_logo}
                                                    title="Move Company"
                                                    subTitle={item.name}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div
                                style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                        <div className="what-next">What's Next?</div>

                        <div style={{ marginTop: '20px' }}>
                            <MovingNextCard
                                title="Set up your Car Shipping"
                                subTitle="Its looks like you are travelling interstate get your shipping done"
                                icon={isDouglasElliman ? movingD : MovingCar}
                                buttonText="Get Started"
                                buttonColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_font_color
                                        ? slug.primary_font_color
                                        : '#FFFFFF'
                                }
                                height="140px"
                                padding="0px 0px 15px 0px"
                                buttonBackGroundColor="#fff"
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : slug && slug.primary_color
                                        ? `0 0 3px 0 ${slug.primary_color}`
                                        : '0 0 3px 0 #273E59'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/autoease');
                                    window.scrollTo(0, 0);

                                    let slug: any = localStorage.getItem('slug');
                                    slug = JSON.parse(slug);

                                    let brokerage: any;
                                    brokerage = slug.display_name;
                                    let brokerage_key: any = slug.brokerage_key;
                                    let agent_key: any = slug.realtor_key;

                                    let agent_name: any = slug.contact_name;
                                    let move_id: any = localStorage.getItem('moveKey');
                                    buttonClicked(
                                        'Button Clicked',
                                        'Moving Services Thankyou',
                                        brokerage,
                                        brokerage_key,
                                        agent_name,
                                        agent_key,
                                        'Moving Service',
                                        'Get Started',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        move_id,
                                    );
                                }}
                            />
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <MovingNextCard
                                title="Get Storage Facility"
                                subTitle="Best quotes on storage facility guaranteed"
                                icon={isDouglasElliman ? storageD : MovingStorage}
                                buttonText="Learn More"
                                buttonColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#002E60'
                                }
                                height="140px"
                                padding="0px 0px 15px 0px"
                                buttonBackGroundColor="#fff"
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : slug && slug.primary_color
                                        ? `0 0 3px 0 ${slug.primary_color}`
                                        : '0 0 3px 0 #273E59'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/storage');
                                    let slug: any = localStorage.getItem('slug');
                                    slug = JSON.parse(slug);

                                    let brokerage: any;
                                    brokerage = slug.display_name;
                                    let brokerage_key: any = slug.brokerage_key;
                                    let agent_key: any = slug.realtor_key;

                                    let agent_name: any = slug.contact_name;
                                    let move_id: any = localStorage.getItem('moveKey');
                                    buttonClicked(
                                        'Button Clicked',
                                        'Moving Services Thankyou',
                                        brokerage,
                                        brokerage_key,
                                        agent_name,
                                        agent_key,
                                        'Moving Service',
                                        'Learn More',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        move_id,
                                    );
                                }}
                            />
                        </div>
                        <div className="btn-wrapper">
                            <ButtonNew
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273E59'
                                }
                                // color="#FFFFFF"
                                fontSize="16px"
                                onClick={() => {
                                    this.backToDashboard();
                                }}
                            >
                                Back to Dashboard
                            </ButtonNew>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moverAction: bindActionCreators(MoverAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moverState: state.move,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(MovingThankYou);
