import React from 'react';
import PolicyDetailsCard from '../HomeInsurance/PolicyDetailsCard';
import QuoteDetailsCard from '../HomeInsurance/QuoteDetailsCard';
import ButtonNew from '../atoms/Button';
import calendar from '../../Assets/images/InsuranceImages/calendar.svg';
import history from '../../routes/History';
import '../../styles/thankYou.css';

interface ThankYouCardProps {
    callScheduleDetails?: string;
}

const ThankYouCard = (props: ThankYouCardProps) => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div>
            <div className="thankyou-card">
                <div className="thankyou-card-header">
                    <div className="thankyou-card-heading">Thank you for your interest in our home policy</div>
                    <div className="thankyou-sub-heading">
                        Your concierge will call you on the invite to help you with this
                    </div>
                </div>

                <div className="thankyou-card-body">
                    <div className="thankyou-call-details-card">
                        <div>
                            <div className="thankyou-call-schedule-details-text">Call schedule details</div>
                            <div className="thankyou-calendar-wrapper">
                                <img src={calendar} width="18px" height="20px" />
                                <div className="thankyou-card-call-details">{props.callScheduleDetails}</div>
                            </div>
                        </div>

                        <ButtonNew
                            color={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug && slug.primary_color
                                    : '#273E59'
                            }
                            border={`1px solid ${
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug && slug.primary_color
                                    : '#273E59'
                            }`}
                            backgroundColor="#fff"
                        >
                            Reschedule
                        </ButtonNew>
                    </div>
                    <div style={{ paddingTop: '13px' }}>
                        <PolicyDetailsCard premiumMonthly="28" premiumYearly="336" logo="" width="100%" />
                    </div>

                    <div style={{ paddingTop: '13px' }}>
                        <QuoteDetailsCard
                            deductible={1000}
                            dwellingCoverage={150000}
                            liabilityCoverage={500000}
                            medicalCoverage={5000}
                            personalProperty={75000}
                            lossOfUse={45000}
                            width="100%"
                        />
                    </div>
                    <div className="btn-wrapper">
                        <ButtonNew
                            // backgroundColor="#273E59"
                            // color="#FFFFFF"
                            fontSize="16px"
                            onClick={() => history.push('/dashboard')}
                        >
                            Back to Dashboard
                        </ButtonNew>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYouCard;
