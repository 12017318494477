import React from 'react';
import '../../styles/propertyDetailsCard.css';

interface homeOwnerDetailsCardProps {
    name?: string;
    age?: number;
    gender?: string;
    maritalStatus?: string;
    slug?: any;
}

const HomeOwnerDetailsCard = (props: homeOwnerDetailsCardProps) => {
    return (
        <div>
            <div className="card-heading-insurance" style={props.slug?.custom_primary_font ? { fontFamily: props.slug.custom_primary_font } : {}}>Homeowner Details</div>
            <div className="propertycard">
                <div className="home-card-row">
                    <div className="home-card-column" style={{ marginRight: '10px' }}>
                        <div className="text">Name</div>
                        <div className="value" style={{ textTransform: 'capitalize' }}>
                            {props.name}
                        </div>
                    </div>

                    <div className="home-card-column">
                        <div className="text">Age</div>
                        <div className="value">{props.age}</div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="home-card-row">
                    <div className="home-card-column" style={{ marginRight: '10px' }}>
                        <div className="text">Gender</div>
                        <div className="value">{props.gender}</div>
                    </div>

                    <div className="home-card-column">
                        <div className="text">Marital Status</div>
                        <div className="value">{props.maritalStatus}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeOwnerDetailsCard;
