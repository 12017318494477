export const GET_CHECKLIST = 'GET_CHECKLIST';
export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';
export const GET_CHECKLIST_ERROR = 'GET_CHECKLIST_ERROR';

export const UPDATE_SERVICE_PERCENTAGE = 'UPDATE_SERVICE_PERCENTAGE';
export const UPDATE_SERVICE_PERCENTAGE_SUCCESS = 'UPDATE_SERVICE_PERCENTAGE_SUCCESS';
export const UPDATE_SERVICE_PERCENTAGE_ERROR = 'UPDATE_SERVICE_PERCENTAGE_ERROR';

// for PODS
export const GET_PODS_CHECKLIST = 'GET_PODS_CHECKLIST';
export const GET_PODS_CHECKLIST_SUCCESS = 'GET_PODS_CHECKLIST_SUCCESS';
export const GET_PODS_CHECKLIST_ERROR = 'GET_PODS_CHECKLIST_ERROR';