import * as React from 'react';
import { colorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={55.376} height={55} viewBox="0 0 55.376 55" {...props}>
            <defs>
                <style>{`.InsuraceMainPoolprefix__b{fill:${ 
                    colorSelector(props.fromLanding || window.location.pathname.includes('landing'), "b")
                }}`}</style>
            </defs>
            <path
                fill={ colorSelector(props.fromLanding || window.location.pathname.includes('landing')) }
                d="M.787 10h54v26h-54z"
            />
            <path
                className="InsuraceMainPoolprefix__b"
                d="M.989 11.866h20.766V34.61a.989.989 0 101.978 0v-4.944h15.822v4.944a.989.989 0 001.978 0V11.866h12.855a.989.989 0 000-1.978H41.532V4.944a4.944 4.944 0 10-9.889 0v4.944h-7.911V4.944a4.944 4.944 0 10-9.889 0v4.944H.989a.989.989 0 100 1.978zm38.565 9.889H23.732V17.8h15.822zm-15.822 5.933v-3.955h15.822v3.955zm9.889-22.744a2.967 2.967 0 015.933 0v4.944h-5.933zm5.933 6.922v3.955H23.732v-3.955zM15.822 4.944a2.967 2.967 0 015.933 0v4.944h-5.933zM54.387 41.156a4.36 4.36 0 01-3.184-1.371 6.327 6.327 0 00-8.908 0 4.375 4.375 0 01-6.36 0 6.315 6.315 0 00-8.9 0 4.363 4.363 0 01-6.35 0 6.315 6.315 0 00-8.9 0 4.366 4.366 0 01-6.352 0 6.231 6.231 0 00-4.448-1.833.989.989 0 100 1.978 4.345 4.345 0 013.176 1.371 6.315 6.315 0 008.9 0 4.363 4.363 0 016.35 0 6.315 6.315 0 008.9 0 4.366 4.366 0 016.352 0 6.319 6.319 0 008.9 0 4.384 4.384 0 016.368 0 6.243 6.243 0 004.454 1.833.989.989 0 000-1.978z"
            />
            <path
                className="InsuraceMainPoolprefix__b"
                d="M54.387 47.089a4.36 4.36 0 01-3.184-1.371 6.327 6.327 0 00-8.908 0 4.375 4.375 0 01-6.36 0 6.315 6.315 0 00-8.9 0 4.363 4.363 0 01-6.35 0 6.315 6.315 0 00-8.9 0 4.366 4.366 0 01-6.352 0 6.231 6.231 0 00-4.448-1.833.989.989 0 100 1.978 4.345 4.345 0 013.176 1.371 6.315 6.315 0 008.9 0 4.363 4.363 0 016.35 0 6.315 6.315 0 008.9 0 4.366 4.366 0 016.352 0 6.319 6.319 0 008.9 0 4.384 4.384 0 016.368 0 6.243 6.243 0 004.454 1.833.989.989 0 100-1.978z"
            />
            <path
                className="InsuraceMainPoolprefix__b"
                d="M54.387 53.022a4.36 4.36 0 01-3.184-1.371 6.327 6.327 0 00-8.908 0 4.375 4.375 0 01-6.36 0 6.315 6.315 0 00-8.9 0 4.363 4.363 0 01-6.35 0 6.315 6.315 0 00-8.9 0 4.366 4.366 0 01-6.352 0 6.231 6.231 0 00-4.448-1.833.989.989 0 100 1.978 4.345 4.345 0 013.176 1.371 6.315 6.315 0 008.9 0 4.363 4.363 0 016.35 0 6.315 6.315 0 008.9 0 4.366 4.366 0 016.352 0 6.319 6.319 0 008.9 0 4.384 4.384 0 016.368 0A6.243 6.243 0 0054.385 55a.989.989 0 100-1.978z"
            />
        </svg>
    );
}

export default SvgComponent;
