import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import Button from './atoms/Button';
import history from '../routes/History';
import Modal from './atoms/Modal';
// import { InlineWidget } from 'react-calendly';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../store/common/actions';
import '../styles/extraPages.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import notFoundError from '../Assets/images/notFoundErrorImage.svg';
import notFoundErrorMobile from '../Assets/images/notFoundErrorBackground.svg';
import { scheduleSegment, buttonClicked } from '../utils/utlis';

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
};
type initialState = {
    calendlyOpen: boolean;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    full_name: string;
    phone: string;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info_enabled: boolean;
    moveId: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
};
export class NotFound extends Component<initialProps, initialState> {
    state = {
        calendlyOpen: false,
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        full_name: '',
        phone: '',
        formOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        isSubmitClicked: false,
        custom_schedule_call_info_enabled: false,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
    };
    componentDidMount() {
        this.props.commonAction.moveGet();

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
    }
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.moveDataArrived
        ) {
            nextProps.commonState.moveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.full_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.full_name;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            // currentState.custom_schedule_call_info =
            //     nextProps.commonState.move[0].realtor &&
            //     nextProps.commonState.move[0].realtor.custom_schedule_call_info;
        }
        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
        }

        return currentState;
    }
    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Schedule Call Button Clicked',
            'Not Found',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Not Found',
            'Schedule A Call',
            false,
            ``,
            true,
            'SORRY, We couldn’t FIND THIS PAGE',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'NOT FOUND',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'NotFound',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Reschedule',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    calendlyHandleCancel = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: false,
            cancelClicked: true,
        });
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'NotFound',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Cancel',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        if (this.state.cancelClicked) {
            let countNew = 0;
            setInterval(() => {
                if (countNew < 5) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 5000);
        } else {
            this.props.commonAction.appointmentGet(payload);
        }
    };

    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'NotFound',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Submit',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    formHandler = () => {
        this.setState({
            formOpen: true,
        });
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'NotFound Page',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Contact Us',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    render() {
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className="serverError">
                <div className="ExtraPagesMainDiv badRequestAlign">
                    {this.state.formOpen && (
                        <Modal
                            isShowModal={this.state.formOpen}
                            onCloseModal={() => {
                                this.setState({ formOpen: false });
                            }}
                            showClose={false}
                            className="modalClassesSecurity"
                        >
                            <div style={{ height: '100%' }}>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({ formOpen: false });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <div className="peronalDetailsInsurance">
                                        <div className="flex-div">
                                            <div className="div-1-name">
                                                <TextField
                                                    id="time"
                                                    label="First Name"
                                                    value={this.state.firstName}
                                                    onChange={(e: any) => this.firstNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.firstName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.firstName === ''
                                                        ? 'Please enter first name'
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="div-2-name">
                                                <TextField
                                                    id="time"
                                                    label="Last Name"
                                                    value={this.state.lastName}
                                                    onChange={(e: any) => this.lastNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.lastName === ''
                                                        ? 'Please enter last name'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <TextField
                                            id="time"
                                            label="Email"
                                            value={this.state.email}
                                            onChange={(e: any) => this.emailHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.email === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.email === ''
                                                ? 'Please enter email'
                                                : this.state.isSubmitClicked &&
                                                  !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                ? 'Please enter valid email'
                                                : ''}
                                        </div>
                                        <TextField
                                            id="time"
                                            label="Phone Number"
                                            value={this.state.phone}
                                            onChange={(e: any) => this.phoneHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.phone === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.phone === ''
                                                ? 'Please enter phone number'
                                                : this.state.isSubmitClicked && this.state.phone.length < 12
                                                ? 'Please enter valid phone number'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={`0 0 3px 0 ${
                                            slug && slug.primary_color ? slug.primary_color : '#273e59'
                                        }`}
                                        width="130px"
                                        height="50px"
                                        color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                                        borderRadius="5px"
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        border={`1px solid ${
                                            slug && slug.primary_color ? slug.primary_color : '#273e59'
                                        }`}
                                        onClick={() => {
                                            this.setState({ formOpen: false });
                                            let move_id: any = localStorage.getItem('moveKey');
                                            buttonClicked(
                                                'Button Clicked',
                                                'NotFound Page',
                                                this.state.brokerage_name,
                                                this.state.brokerage_key,
                                                this.state.agent_name,
                                                this.state.agent_key,
                                                '',
                                                'Cancel',
                                                false,
                                                '',
                                                false,
                                                '',
                                                '',
                                                false,
                                                '',
                                                false,
                                                '',
                                                '',
                                                move_id,
                                            );
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius="5px"
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.closeFormHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div>
                        <div className="ErrorTitle">SORRY, WE couldn’t FIND THIS PAGE</div>
                        <div className="ErrorSubText">
                            The page you were looking might have been removed, renamed, or did not exist in the first
                            place.
                        </div>
                        <div className="fourHunderedImagesCheck">
                            {' '}
                            <img style={{ marginTop: '40px' }} src={notFoundError} />
                        </div>
                        <div className="mobilefourHunderedImages">
                            {' '}
                            <img style={{ marginTop: '40px' }} src={notFoundErrorMobile} />
                        </div>
                        {/* <div
                        className="ErrorNumber"
                        style={{ color: slug && slug.color_shade1 ? slug.color_shade1 : '#08192f' }}
                    >
                        404
                    </div> */}

                        <div className="ErrorBtn">
                            {this.state.custom_schedule_call_info_enabled ? (
                                <div style={{ textAlign: 'center' }}>
                                    <div className="footerQuestion">
                                        {' '}
                                        Questions? Talk to our experts and get help now.
                                    </div>
                                    <Button
                                        className="footerbtn"
                                        backgroundColor="#FFFFFF"
                                        width="120px"
                                        height="35px"
                                        color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                                        borderRadius="5px"
                                        fontSize="12px"
                                        fontWeight="600"
                                        border={`1px solid ${
                                            slug && slug.primary_color ? slug.primary_color : '#273e59'
                                        }`}
                                        onClick={() => this.formHandler()}
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                            ) : (
                                // </div>
                                // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ textAlign: 'center' }}>
                                    {this.state.reschedule_link && this.state.appointmentTime ? (
                                        <div className="appointment-time-div-2">
                                            <div className="details-2"> Appointment Details </div>
                                            <div className="details-content-2">
                                                {' '}
                                                Your Call is scheduled on{' '}
                                                {`${new Date(this.state.appointmentTime)
                                                    .toString()
                                                    .substring(0, 15)},${new Date(
                                                    this.state.appointmentTime,
                                                ).toLocaleTimeString('en-US')} `}{' '}
                                            </div>
                                        </div>
                                    ) : null
                                    // <div className="footerQuestion">
                                    //     {' '}
                                    //     Questions? Talk to our experts and get help now.
                                    // </div>
                                    }

                                    {this.state.reschedule_link ? (
                                        <div>
                                            <Button
                                                className="footerbtn"
                                                color="#FFFFFF"
                                                width="120px"
                                                height="35px"
                                                backgroundColor={
                                                    slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                }
                                                borderRadius="5px"
                                                fontSize="12px"
                                                fontWeight="600"
                                                border={`1px solid ${
                                                    slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                }`}
                                                onClick={() => this.calendlyHandleReschedule()}
                                            >
                                                Reschedule
                                            </Button>

                                            <Button
                                                className="footerbtn"
                                                backgroundColor="#FFFFFF"
                                                width="120px"
                                                height="35px"
                                                color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                                                borderRadius="5px"
                                                fontSize="12px"
                                                fontWeight="600"
                                                border={`1px solid ${
                                                    slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                }`}
                                                onClick={() => this.calendlyHandleCancel()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            className="buttonErrorRequest"
                                            // backgroundColor="#FFFFFF"
                                            backgroundColor={
                                                slug && slug.primary_color ? slug.primary_color : '#273e59'
                                            }
                                            color="#fff"
                                            padding="14px 0px"
                                            // width="120px"
                                            // height="35px"
                                            // color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                                            borderRadius="5px"
                                            fontSize="12px"
                                            fontWeight="600"
                                            border={`1px solid ${
                                                slug && slug.primary_color ? slug.primary_color : '#273e59'
                                            }`}
                                            onClick={() => this.calendlyHandle()}
                                        >
                                            Schedule a Call
                                        </Button>
                                    )}
                                </div>
                            )}

                            <Button
                                className="buttonErrorRequest"
                                backgroundColor="#FFFFFF"
                                color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                                borderRadius="5px"
                                fontSize="12px"
                                // fontSize="16px"
                                border={`1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`}
                                onClick={() => {
                                    history.push('/dashboard');
                                    let move_id: any = localStorage.getItem('moveKey');
                                    buttonClicked(
                                        'Button Clicked',
                                        'NotFound Page',
                                        this.state.brokerage_name,
                                        this.state.brokerage_key,
                                        this.state.agent_name,
                                        this.state.agent_key,
                                        '',
                                        'Back to Dashboard',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        move_id,
                                    );
                                }}
                            >
                                Back to Dashboard
                            </Button>
                        </div>
                    </div>
                    {this.state.calendlyOpen && (
                        <Modal
                            isShowModal={this.state.calendlyOpen}
                            onCloseModal={this.nylasModalClosed}
                            showClose={false}
                            className="scheduleCallExtra"
                        >
                            <div className="scheduleCallBack" onClick={() => this.setState({ calendlyOpen: false })}>
                                <ArrowBackIcon />
                            </div>
                            {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}
                            {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                            (this.state.rescheduleClicked && this.state.reschedule_link) ||
                            (this.state.cancelClicked && this.state.cancel_link) ? (
                                <div className="iFrame">
                                    <iframe
                                        src={`${
                                            this.state.scheduleClicked
                                                ? this.state.nylas_full_service_scheduler_url
                                                : this.state.rescheduleClicked
                                                ? this.state.reschedule_link
                                                : this.state.cancelClicked
                                                ? this.state.cancel_link
                                                : null
                                        }&utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas
                                      `}
                                        allow="encrypted-media"
                                        // position="relative"
                                        className="iframe"
                                        //  referrerPolicy="same-origin"
                                        //  sandbox="allow-scripts"
                                        id="myFrame"
                                    ></iframe>
                                </div>
                            ) : null}
                        </Modal>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
