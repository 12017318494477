/**
 * Checkes if given element is in current viewport
 * @returns boolean 
 */
export const isElementOnViewPort = (elementClass: string): boolean => {
    const elementNode = document.querySelectorAll(`.${elementClass}`);
    let status = false;
    
    elementNode?.forEach((element: any) => {
         const distance = element.getBoundingClientRect();
         if (
             distance.top >= 0 &&
             distance.left >= 0 &&
             distance.bottom <= (window.innerHeight) &&
             distance.right <= (window.innerWidth)
         ) {
           status = true;
         }
     });

     return status;
};

/**
 * Capitalize first letter of given string
 * @returns string by capitalize first letter 
 */
export const capitalizeFirstLetter = (string: string) => {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
}

/**
 * Prepare Title based on configuration
 * @param realtorObj Realtor Object from API
 * @param defaultTitle default title
 * @returns title from backend (API)
 */
export const serviceTitle = (realtorObj: any, defaultTitle: string = "MoveEasy") => {
  return realtorObj?.display_name || defaultTitle
}