import React from 'react';
import { makeStyles } from '@material-ui/core';
import '../../styles/MovingStorageFacility.scss';
import Rating from '@material-ui/lab/Rating';
import Button from './Button';
import Skeleton from 'react-loading-skeleton';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    boxShadow?: any;
    handlePriceDetails?: any;
    cardTitel?: string;
    rate?: number;
    ratingIcon?: any;
    verifiedReview?: any;
    cardSubTitle?: string;
    viewDetailsLink?: any;
}

const MovingStorageFacilityCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #e4e6e8',
                '& $checkroot': {
                    color: '#333333 !important',
                    // -webkit-filter: "grayscale(100%) contrast(200%)",
                },
            },
        },
    }));
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div
            style={{
                width: props.width ? props.width : '200px',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 10px #e4e6e8',
                border: props.border ? props.border : null,
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '20px 0px',
                padding: props.cardPadding ? props.cardPadding : '15px',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : classes.root}
        >
            <div className={isDouglasElliman ? 'facility-card-main facility-card-mainDouglas' : 'facility-card-main'}>
                <div className="facilityIconDiv">
                    {props.cardImage ? <img src={props.cardImage} /> : <Skeleton height="100px" width="200px" />}
                </div>
                <div className="facility-content-div">
                    <div className="facilityContent">
                        <div className="movers-heading">{props.cardTitle ? props.cardTitle : <Skeleton />}</div>
                        <div className="facilitySubHeading">
                            {props.cardSubTitle ? props.cardSubTitle : <Skeleton />}
                        </div>
                        <div className="moving-main-div">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {props.rate ? (
                                    <div className={isDouglasElliman ? 'rating-button' : 'rating-buttonDoug'}>
                                        <img style={{ marginRight: '5px' }} src={props.ratingIcon} />
                                        {props.rate}
                                    </div>
                                ) : (
                                    <Skeleton height="20px" width="50px" />
                                )}
                            </div>
                            {props.rate ? (
                                <div className="rating-section">
                                    <Rating name="read-only" value={props.rate} readOnly />
                                </div>
                            ) : (
                                <Skeleton height="20px" width="100px" />
                            )}
                            {/* <div className="verified-review">{props.verifiedReview}</div> */}
                        </div>
                        <div className="viewFacilityDetails" style={{color: isDouglasElliman ? "#009cbd": "#006dff"  }} onClick={props.viewDetailsLink}>
                            View details
                        </div>
                    </div>
                    <div className="facilityButtonDiv">
                        {props.viewDetailsLink ? (
                            <Button
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59'
                                }
                                color={'#FFFFFF'}
                                onClick={props.viewDetailsLink}
                                className="viewAllPriceButton"
                            >
                                View Pricing
                            </Button>
                        ) : (
                            <Skeleton height="50px" width="100px" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovingStorageFacilityCard;
