import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const DLMoving = (props: any) => {
    const history = useHistory()
    const [dlMovingState, setDlMovingState] = useState(localStorage.getItem('DLStatetype') === 'mis' ? 'mis' : 'mos');

    const onChangeMovingRadion = (value: string) => {
        setDlMovingState(value);
        if (value === 'mis' && window.location.pathname.includes('vehicleregistration')) {
            history.push('/dashboard/driverlicense');
            localStorage.setItem('DLStatetype', 'mis');
        }
    }

    useEffect(() => {
        props.onChange(dlMovingState)
    }, [dlMovingState])

    return (
        <div className='select-dl-state-moving'>
            <div className='dl-text'>
                Please select one:
            </div>
            <div className='dl-radio-gruops'>
                <div className={`dl-radio ${dlMovingState === 'mos' ? 'active-dl' : ''}`} onClick={(() => { onChangeMovingRadion('mos') })}>
                    <input type='radio'
                        id="mos"
                        className='radio-btn'
                        name='dl-radio'
                        checked={dlMovingState === 'mos' ? true : false}
                        value={"mos"}
                    />
                    <label htmlFor='mos' className={`radio-label ${dlMovingState === 'mos' ? 'active-text' : ''}`} > Moving out of state </label>
                </div>
                <div className={`dl-radio ${dlMovingState === 'mis' ? 'active-dl' : ''}`} onClick={(() => { onChangeMovingRadion('mis') })} >
                    <input type='radio'
                        id='mis'
                        className='radio-btn'
                        name='dl-radio'
                        checked={dlMovingState === "mis" ? true : false}
                        value={"mis"}
                    />
                    <label htmlFor='mis' className={`radio-label ${dlMovingState === 'mis' ? 'active-text' : ''}`}> Moving in state </label>
                </div>
            </div>
        </div>
    )
}

export default DLMoving