import {
    UPDATE_MOVE_PERSONAL_INFO,
    UPDATE_MOVE_PERSONAL_INFO_ERROR,
    UPDATE_MOVE_PERSONAL_INFO_SUCCESS,
    UPDATE_MOVE_PROPERTY_INFO,
    UPDATE_MOVE_PROPERTY_INFO_ERROR,
    UPDATE_MOVE_PROPERTY_INFO_SUCCESS,
    INSURANCE_APPOINTMENTS,
    INSURANCE_APPOINTMENTS_ERROR,
    INSURANCE_APPOINTMENTS_SUCCESS,
    INSURANCE_APPOINTMENTS_GET,
    INSURANCE_APPOINTMENTS_GET_ERROR,
    INSURANCE_APPOINTMENTS_GET_SUCCESS,
    INSURANCE_QUOTE,
    INSURANCE_QUOTE_ERROR,
    INSURANCE_QUOTE_SUCCESS,
    INSURANCE_QUOTE_LIST,
    INSURANCE_QUOTE_LIST_ERROR,
    INSURANCE_QUOTE_LIST_SUCCESS,
} from './actionTypes';

import { InsuranceActions, InsuranceState } from './types';

const initialState: InsuranceState = {
    quote: [],
    errorQuote: null,
    quoteList: [],
    errorQuoteList: null,
    updatePersonal: [],
    errorUpdatePersonal: null,
    updateProperty: [],
    errorUpdateProperty: null,
    appointment: [],
    errorAppointment: null,
    appointmentGet: [],
    errorAppointmentGet: null,
};

export default (state = initialState, action: InsuranceActions) => {
    switch (action.type) {
        case INSURANCE_QUOTE:
            return {
                ...state,
                quote: [],
                errprQuote: null,
            };
        case INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quote: action.payload.quote,
                errprQuote: null,
            };
        case INSURANCE_QUOTE_ERROR:
            return {
                ...state,
                quote: [],
                errorQuote: action.payload.errorQuote,
            };

        case INSURANCE_QUOTE_LIST:
            return {
                ...state,
                quoteList: [],
                errorQuoteList: null,
            };
        case INSURANCE_QUOTE_LIST_SUCCESS:
            return {
                ...state,
                quoteList: action.payload.quoteList,
                errorQuoteList: null,
            };
        case INSURANCE_QUOTE_LIST_ERROR:
            return {
                ...state,
                quoteList: [],
                errorQuoteList: action.payload.errorQuoteList,
            };

        case INSURANCE_APPOINTMENTS:
            return {
                ...state,
                appointment: [],
                errorAppointment: null,
            };
        case INSURANCE_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointment: action.payload.appointment,
                errorAppointment: null,
            };
        case INSURANCE_APPOINTMENTS_ERROR:
            return {
                ...state,
                appointment: [],
                errorAppointment: action.payload.errorAppointment,
            };

        case INSURANCE_APPOINTMENTS_GET:
            return {
                ...state,
                appointment: [],
                errorAppointment: null,
            };
        case INSURANCE_APPOINTMENTS_GET_SUCCESS:
            return {
                ...state,
                appointment: action.payload.appointmentGet,
                errorAppointment: null,
            };
        case INSURANCE_APPOINTMENTS_GET_ERROR:
            return {
                ...state,
                appointment: [],
                errorAppointment: action.payload.errorAppointmentGet,
            };

        case UPDATE_MOVE_PERSONAL_INFO:
            return {
                ...state,
                updatePersonal: [],
                errorUpdatePersonal: null,
            };
        case UPDATE_MOVE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                updatePersonal: action.payload.updatePersonal,
                errorUpdatePersonal: null,
            };
        case UPDATE_MOVE_PERSONAL_INFO_ERROR:
            return {
                ...state,
                updatePersonal: [],
                errorUpdatePersonal: action.payload.errorUpdatePersonal,
            };

        case UPDATE_MOVE_PROPERTY_INFO:
            return {
                ...state,
                updateProperty: [],
                errorUpdateProperty: null,
                updatePropertyStatus: 'started'
            };
        case UPDATE_MOVE_PROPERTY_INFO_SUCCESS:
            return {
                ...state,
                updateProperty: action.payload.updateProperty,
                errorUpdateProperty: null,
                updatePropertyStatus: 'success'
            };
        case UPDATE_MOVE_PROPERTY_INFO_ERROR:
            return {
                ...state,
                updateProperty: [],
                errorUpdateProperty: action.payload.errorUpdateProperty,
                updatePropertyStatus: 'error'
            };
        default:
            return {
                ...state,
            };
    }
};
