import {
    GET_CHECKLIST_BY_MOVE_ID,
    GET_CHECKLIST_BY_MOVE_ID_ERROR,
    GET_CHECKLIST_BY_MOVE_ID_SUCCESS,
    POST_SUBSCRIPTION_DETAILS,
    POST_SUBSCRIPTION_DETAILS_ERROR,
    POST_SUBSCRIPTION_DETAILS_SUCCESS,
    GET_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_DETAILS_ERROR,
    GET_SUBSCRIPTION_DETAILS_SUCCESS,
    UPDATE_SUBSCRIPTION_DETAILS,
    UPDATE_SUBSCRIPTION_DETAILS_ERROR,
    UPDATE_SUBSCRIPTION_DETAILS_SUCCESS,
} from './actionTypes';

import { HomeOwnerDashboardActions, HomeOwnerDashboardState } from './types';

const initialState: HomeOwnerDashboardState = {
    checklistByMoveId: [],
    isChecklistByMoveId: false,
    postSubscriptionDetails: [],
    isPostSubscriptionDetails: false,
    getSubscriptionDetails: [],
    isGetSubscriptionDetails: false,
    updateSubscriptionDetails: [],
    isUpdateSubscriptionDetails: false,
};

export default (state = initialState, action: HomeOwnerDashboardActions) => {
    switch (action.type) {
        case GET_CHECKLIST_BY_MOVE_ID:
            return {
                ...state,
                checklistByMoveId: [],
                isChecklistByMoveId: false,
                error: null,
            };
        case GET_CHECKLIST_BY_MOVE_ID_SUCCESS:
            return {
                ...state,
                checklistByMoveId: action.payload.checklistByMoveId,
                isChecklistByMoveId: true,
                error: null,
            };
        case GET_CHECKLIST_BY_MOVE_ID_ERROR:
            return {
                ...state,
                checklistByMoveId: [],
                isChecklistByMoveId: false,
                error: action.payload.error,
            };
        case POST_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                postSubscriptionDetails: [],
                isPostSubscriptionDetails: false,
                error: null,
            };
        case POST_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                postSubscriptionDetails: action.payload.postSubscriptionDetails,
                isPostSubscriptionDetails: true,
                error: null,
            };
        case POST_SUBSCRIPTION_DETAILS_ERROR:
            return {
                ...state,
                postSubscriptionDetails: [],
                isPostSubscriptionDetails: false,
                error: action.payload.error,
            };
        case GET_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                getSubscriptionDetails: [],
                isGetSubscriptionDetails: false,
                error: null,
            };
        case GET_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                getSubscriptionDetails: action.payload.getSubscriptionDetails,
                isGetSubscriptionDetails: true,
                error: null,
            };
        case GET_SUBSCRIPTION_DETAILS_ERROR:
            return {
                ...state,
                getSubscriptionDetails: [],
                isGetSubscriptionDetails: false,
                error: action.payload.error,
            };
        case UPDATE_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                updateSubscriptionDetails: [],
                isUpdateSubscriptionDetails: false,
                error: null,
            };
        case UPDATE_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                updateSubscriptionDetails: action.payload.updateSubscriptionDetails,
                isUpdateSubscriptionDetails: true,
                error: null,
            };
        case UPDATE_SUBSCRIPTION_DETAILS_ERROR:
            return {
                ...state,
                updateSubscriptionDetails: [],
                isUpdateSubscriptionDetails: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
