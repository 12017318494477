import React, { Component } from 'react';
import './driving.scss';
import Button from '../../components/atoms/Button';
import MobileSvg from '../../Assets/images/dmvImages/smartphone_black_24dp.svg';
import EmailSvg from '../../Assets/images/dmvImages/mail_black_24dp.svg';
import DescritionSvg from '../../Assets/images/dmvImages/description_black_24dp.svg';
import RedSvg from '../../Assets/images/dmvImages/redCal.svg';
import BlueSvg from '../../Assets/images/dmvImages/BlueSvg1.svg';
import YellowSvg from '../../Assets/images/dmvImages/yellowCal.svg';
import SavingSvg from '../../Assets/images/dmvImages/savings_white_24dp.svg';
import DummySvg from '../../Assets/images/dmvImages/licenseimg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as DMVAction from '../../store/dmv/actions';
import DownloadSvg from '../../Assets/images/dmvImages/downloadIcon.svg';
import DownloadSvgblue from '../../Assets/images/dmvImages/blueDownload.svg';
// import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { buttonClicked } from '../../utils/utlis';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    dmvAction?: any;
    dmvState?: any;
    location?: any;
};

type initialState = {
    state: string;
    withInState: boolean;
    dayLimit: number;
    count: number;
    link: string;
    nearDmv: string;
    changeAddress: string;
    mail: string;
    mailForm: string;
    person: string;
    personForm: string;
    hashCode: any;
    isHashcodeDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
};

export class DrivingLicenceMain extends Component<initialProps, initialState> {
    state = {
        state: '',
        withInState: false,
        dayLimit: 30,
        count: -1,
        link: '',
        nearDmv: '',
        changeAddress: '',
        mail: '',
        mailForm: '',
        person: '',
        personForm: '',
        hashCode: '',
        isHashcodeDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
    };
    async componentDidMount() {
        window.scrollTo(0, 0);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const dest = data && data.destination;
            const source = data && data.source;
            this.setState({ state: dest.state_code });
            this.props.dmvAction.dmv({ state: dest.state_code });
            if (dest.state_code === source.state_code) {
                this.setState({ withInState: true, dayLimit: 10 });
            }
        } else {
            this.props.commonAction.moveGet();
        }

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Driver’s License',
                    category_name: 'Driver’s License',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashcodeDataArrived
        ) {
            currentState.isHashcodeDataArrived = false;

            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.commonAction.moveGet();
            } else {
                localStorage.clear();
                history.push({
                    pathname: `/signUp`,
                    search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                });
            }
        }

        return currentState;
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const dmvP = prevProps && prevProps.dmvState;
        const dmvC = this.props && this.props.dmvState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest = cur.move[0] && cur.move[0].destination;
            const source = cur.move[0] && cur.move[0].source;
            this.setState({ state: dest.state_code });
            this.props.dmvAction.dmv({ state: dest.state_code });
            if (dest.state_code === source.state_code) {
                this.setState({ withInState: true, dayLimit: 10 });
            }
        }
        if (dmvP.dmv !== dmvC.dmv && dmvC.dmv && dmvC.dmv[0] && dmvC.dmv.length > 0) {
            if (!this.state.withInState && dmvC.dmv[0] && dmvC.dmv[0].state_regular_passenger_licenses) {
                dmvC.dmv[0].state_regular_passenger_licenses.map((el: any) => {
                    if (el && el.procedure_name === 'Timeframe') {
                        let num =
                            el && el.details.match(/\d+/) && el.details.match(/\d+/)[0]
                                ? parseInt(el && el.details.match(/\d+/)[0])
                                : 0;
                        // let calender=
                        var result: any = new Date(
                            this.props.commonState &&
                                this.props.commonState.move &&
                                this.props.commonState.move[0] &&
                                this.props.commonState.move[0].moving_date,
                        );
                        result.setDate(result.getDate() + num);

                        let date2: any = new Date();
                        var Difference_In_Time = result.getTime() - date2.getTime();
                        var Days: any = Difference_In_Time / (1000 * 3600 * 24);
                        // let d: any = new Date(result) - curDate;

                        // d = new Date(d);

                        this.setState({ count: parseInt(Days) });
                    }
                    if (el && el.procedure_name === 'Driver Handbook') {
                        this.setState({ link: el && el.details });
                    }
                    if (el && el.procedure_name === 'To be applied in person') {
                        this.setState({ nearDmv: el && el.details });
                    }
                });
            }
            if (this.state.withInState && dmvC.dmv[0] && dmvC.dmv[0].state_change_of_addresses) {
                dmvC.dmv[0].state_change_of_addresses.map((el: any) => {
                    if (el && el.procedure_name === 'Timeframe') {
                        let num =
                            el && el.details.match(/\d+/) && el.details.match(/\d+/)[0]
                                ? parseInt(el.details.match(/\d+/)[0])
                                : 0;
                        var result: any = new Date(
                            this.props.commonState &&
                                this.props.commonState.move &&
                                this.props.commonState.move[0] &&
                                this.props.commonState.move[0].moving_date,
                        );
                        result.setDate(result.getDate() + num);

                        let date2: any = new Date();
                        var Difference_In_Time = result.getTime() - date2.getTime();
                        var Days: any = Difference_In_Time / (1000 * 3600 * 24);
                        this.setState({ count: parseInt(Days) });
                    }
                    if (el && el.procedure_name === 'Change of address online') {
                        this.setState({ changeAddress: el.details });
                    }
                    if (el && el.procedure_name === 'Change of address by mail') {
                        this.setState({ mail: el.details, mailForm: el.forms });
                    }
                    if (el && el.procedure_name === 'Change of address in person') {
                        this.setState({ person: el.details, personForm: el.forms });
                    }
                });
            }
        }
    }
    // handleChange = (type: string) => {
    //     let point = this.state.points;
    //     type === 'p1'
    //         ? (point.p1 = !point.p1)
    //         : type === 'p2'
    //         ? (point.p2 = !point.p2)
    //         : type === 'p3'
    //         ? (point.p3 = !point.p3)
    //         : (point = point);
    //     this.setState({ points: point });
    // };
    downloadFile = () => {
        window.location.href = this.state.link;
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const data = this.props.dmvState && this.props.dmvState.dmv && this.props.dmvState.dmv[0];
        let docs: any = [];

        data &&
            data.state_regular_passenger_licenses.map((el: any) => {
                if (el.procedure_name === 'Documents required') {
                    docs = el.details.split(',');
                }
            });
        let checkListArea = (
            <div className="checklistarea-dmv">
                <div className="dmv-right-1">
                    <div className="dmv-right-11">
                        <img src={DescritionSvg} style={{ marginRight: '5px', marginLeft: '-3px' }} />
                        Documents Required
                    </div>
                    <div className={this.state.isDouglasElliman ? 'dmv-right-12 dmv-right-12Doug' : 'dmv-right-12'}>
                        <FormGroup>
                            {docs.length > 0 ? (
                                docs.map((doc: string) => {
                                    if (doc !== '') {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        // onChange={() => this.handleChange('p1')}
                                                        disabled
                                                        name="p1"
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{
                                                            color: true ? '#2270EE' : '#B3B3B3',
                                                        }}
                                                    />
                                                }
                                                label={doc}
                                            />
                                        );
                                    }
                                })
                            ) : (
                                <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                                    <Skeleton style={{ marginBottom: '20px' }} count={4} />
                                    {/* <Skeleton style={{ marginBottom: '20px' }} />
                                    <Skeleton style={{ marginBottom: '20px' }} />
                                    <Skeleton /> */}
                                </SkeletonTheme>

                                // <div
                                //     style={{
                                //         marginTop: '20px',
                                //         display: 'flex',
                                //         justifyContent: 'center',
                                //         alignItems: 'center',
                                //     }}
                                // >
                                //     <CircularProgress />
                                // </div>
                            )}
                        </FormGroup>
                    </div>
                </div>
                <div className="dmv-right-2">
                    <div style={{ fontSize: '14px' }}>Send info via</div>
                    <div className="dmv-right-2-img">
                        <div className="right-img" style={{ marginRight: '10px' }}>
                            <img src={MobileSvg} />
                        </div>
                        <div className="right-img">
                            <img src={EmailSvg} />
                        </div>
                    </div>
                </div>
            </div>
        );
        let icon = BlueSvg;
        if (this.state.dayLimit === 30) {
            this.state.count > 15
                ? (icon = BlueSvg)
                : this.state.count <= 15 && this.state.count > 5
                ? (icon = YellowSvg)
                : (icon = RedSvg);
        }
        if (this.state.dayLimit === 10) {
            this.state.count > 5
                ? (icon = BlueSvg)
                : this.state.count <= 5 && this.state.count > 3
                ? (icon = YellowSvg)
                : (icon = RedSvg);
        }
        let classes: any = [];
        data &&
            data.state_regular_passenger_licenses.map((el: any) => {
                if (el && el.procedure_name === 'Cost') {
                    classes.push(el);
                }
            });
        const { isDouglasElliman } = this.state;
        return (
            <div>
                {isDouglasElliman && (
                    <div>
                        <TemplateUpper
                            pageImage="dmv"
                            headerText={`Updating Your`}
                            subheaderText={'Manage updating your driver’s license in minutes!'}
                            highlightedText={"Driver's License"}
                        />
                    </div>
                )}
                <div className="DrivingLicenceMain">
                    {!isDouglasElliman && (
                        <div className="main-head-dmv">
                            <h2 className="heading-dmv">Driver’s License</h2>
                            <div className="path-dmv">
                                <p
                                    className="Para-dmv"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard {'>'}
                                </p>{' '}
                                <p style={{ marginLeft: '5px' }}>Driver’s License</p>
                            </div>
                        </div>
                    )}
                    {!this.state.withInState && (
                        <div className={isDouglasElliman ? 'dmvtab-div dmvMainRemoveDouglas' : 'dmvtab-div'}>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/driverlicense' ||
                                        this.props.history.location.pathname === `/dashboard/driverlicense/`)
                                        ? isDouglasElliman
                                            ? 'dmvtab-active changeColordmv'
                                            : 'dmvtab-active'
                                        : 'dmvtab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/driverlicense');
                                }}
                            >
                                Driver License
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname ===
                                        '/dashboard/driverlicense/vehicleregistration' ||
                                        this.props.history.location.pathname ===
                                            `/dashboard/driverlicense/vehicleregistration/`)
                                        ? isDouglasElliman
                                            ? 'dmvtab-active changeColordmv'
                                            : 'dmvtab-active'
                                        : 'dmvtab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/driverlicense/vehicleregistration');
                                }}
                            >
                                Vehicle Registration
                            </div>
                        </div>
                    )}
                    {/* {data ? ( */}
                    <div>
                        <div
                            className={
                                isDouglasElliman ? 'dmv-content-div dmvMainContentRemoveDouglas' : 'dmv-content-div'
                            }
                        >
                            <div className="dmv-content-left">
                                <div className="dmv-left-1">
                                    {this.state.count >= 0 && (
                                        <div
                                            className="calender-dmv"
                                            style={{
                                                background: `url(${icon}) no-repeat center center`,
                                                color:
                                                    (this.state.dayLimit === 30 &&
                                                        this.state.count <= 15 &&
                                                        this.state.count > 5) ||
                                                    (this.state.dayLimit === 10 &&
                                                        this.state.count <= 5 &&
                                                        this.state.count > 3)
                                                        ? '#333'
                                                        : '#fff',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <div style={{ fontSize: '42px', fontWeight: 800 }}>{this.state.count}</div>
                                            <div style={{ fontSize: '16px', fontWeight: 500 }}>Days Left</div>
                                        </div>
                                    )}
                                    <div className="detail">
                                        <div className="title-dmv">{this.state.state} Driver’s License</div>
                                        <div className="subText-dmv ">
                                            After the end of {this.state.count >= 0 ? this.state.count : ''} days, your
                                            license expires and there might be a penalty to renew it again. We suggest
                                            you to use the following ways to apply
                                        </div>
                                    </div>
                                </div>
                                <div className="dmv-left-11-checklist">{checkListArea}</div>
                                {this.state.withInState ? (
                                    <div>
                                        <div className="dmv-left-2">
                                            <div>
                                                <div className="title-dmv1">Online</div>
                                                <div className="subText-dmv1">To change your address online</div>
                                            </div>
                                            <div>
                                                <Button
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : slug && slug.primary_color
                                                            ? `0 0 3px 0 ${slug.primary_color}`
                                                            : '0 0 3px 0 #273E59'
                                                    }
                                                    border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : '#273E59'
                                                    }
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    backgroundColor="#FFFFFF"
                                                    width="210px"
                                                    height="50px"
                                                    fontSize="16px"
                                                    onClick={() => {
                                                        window.open(this.state.changeAddress, '_blank');
                                                        let slug: any = localStorage.getItem('slug');
                                                        if (slug) {
                                                            slug = JSON.parse(slug);
                                                        }
                                                        if (slug && slug.display_name) {
                                                            let brokerage: any;

                                                            brokerage = slug.display_name;
                                                            let brokerage_key: any = slug.brokerage_key;
                                                            let agent_key: any = slug.realtor_key;

                                                            let agent_name: any = slug.contact_name;
                                                            let move_id: any = localStorage.getItem('moveKey');
                                                            buttonClicked(
                                                                'Button Clicked',
                                                                'Drivers License',
                                                                brokerage,
                                                                brokerage_key,
                                                                agent_name,
                                                                agent_key,
                                                                'Drivers Licence',
                                                                'Change Address Online',
                                                                false,
                                                                '',
                                                                false,
                                                                '',
                                                                '',
                                                                false,
                                                                '',
                                                                false,
                                                                '',
                                                                '',
                                                                move_id,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Change Address Online
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="dmv-left-22">
                                            <div className="dmv-left-221">
                                                <div>
                                                    <div className="title-dmv1">In Person</div>
                                                    <div className="subText-dmv1">
                                                        You will have to register in person at your nearest DMV location
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button
                                                        boxShadow={
                                                            isDouglasElliman
                                                                ? 'none'
                                                                : slug && slug.primary_color
                                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                                : '0 0 3px 0 #273E59'
                                                        }
                                                        border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                        color={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : '#273E59'
                                                        }
                                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                        backgroundColor="#FFFFFF"
                                                        width="210px"
                                                        height="50px"
                                                        fontSize="16px"
                                                        onClick={() => {
                                                            window.open(this.state.person, '_blank');
                                                            let slug: any = localStorage.getItem('slug');
                                                            if (slug) {
                                                                slug = JSON.parse(slug);
                                                            }
                                                            if (slug && slug.display_name) {
                                                                let brokerage: any;

                                                                brokerage = slug.display_name;
                                                                let brokerage_key: any = slug.brokerage_key;
                                                                let agent_key: any = slug.realtor_key;

                                                                let agent_name: any = slug.contact_name;
                                                                let move_id: any = localStorage.getItem('moveKey');
                                                                buttonClicked(
                                                                    'Button Clicked',
                                                                    'Drivers License',
                                                                    brokerage,
                                                                    brokerage_key,
                                                                    agent_name,
                                                                    agent_key,
                                                                    'Drivers Licence',
                                                                    'Find Your Nearest DMV',
                                                                    false,
                                                                    '',
                                                                    false,
                                                                    '',
                                                                    '',
                                                                    false,
                                                                    '',
                                                                    false,
                                                                    '',
                                                                    '',
                                                                    move_id,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Find Your Nearest DMV
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="dmv-left-222">
                                                <div className="subText-dmv1-red">Form Required*</div>{' '}
                                                <Link to={this.state.personForm} target="_blank" download="form14.pdf">
                                                    <div
                                                        className={
                                                            isDouglasElliman
                                                                ? 'downloadForm downloadFormDoug'
                                                                : 'downloadForm'
                                                        }
                                                        // onClick={() => window.open(this.state.personForm, '_blank')}
                                                    >
                                                        <div>
                                                            {isDouglasElliman ? (
                                                                <img src={DownloadSvg} style={{ marginRight: '5px' }} />
                                                            ) : (
                                                                <img
                                                                    src={DownloadSvgblue}
                                                                    style={{ marginRight: '5px' }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>Download Form 14</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dmv-left-22">
                                            <div className="dmv-left-221">
                                                <div>
                                                    <div className="title-dmv1">By Mail</div>
                                                    <div className="subText-dmv1">Mail your request to</div>
                                                </div>
                                                <div className="addressText">{this.state.mail}</div>
                                            </div>
                                            <div className="dmv-left-222">
                                                <div className="subText-dmv1-red">Form Required*</div>{' '}
                                                <Link to={this.state.mailForm} target="_blank" download="form14.pdf">
                                                    <div
                                                        className={
                                                            isDouglasElliman
                                                                ? 'downloadForm downloadFormDoug'
                                                                : 'downloadForm'
                                                        }
                                                        // onClick={() => window.open(this.state.mailForm, '_blank')}
                                                    >
                                                        <div>
                                                            {isDouglasElliman ? (
                                                                <img src={DownloadSvg} style={{ marginRight: '5px' }} />
                                                            ) : (
                                                                <img
                                                                    src={DownloadSvgblue}
                                                                    style={{ marginRight: '5px' }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>Download Form 14</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="dmv-left-2">
                                        <div>
                                            <div className="title-dmv1">Register in person</div>
                                            <div className="subText-dmv1">
                                                You will have to register in person at your nearest DMV location
                                            </div>
                                        </div>
                                        <div>
                                            <Button
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor="#FFFFFF"
                                                width="210px"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={() => {
                                                    window.open(this.state.nearDmv, '_blank');

                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }
                                                    if (slug && slug.display_name) {
                                                        let brokerage: any;

                                                        brokerage = slug.display_name;
                                                        let brokerage_key: any = slug.brokerage_key;
                                                        let agent_key: any = slug.realtor_key;

                                                        let agent_name: any = slug.contact_name;
                                                        let move_id: any = localStorage.getItem('moveKey');

                                                        buttonClicked(
                                                            'Button Clicked',
                                                            'Drivers License',
                                                            brokerage,
                                                            brokerage_key,
                                                            agent_name,
                                                            agent_key,
                                                            'Drivers Licence',
                                                            'Find your nearest DMV',
                                                            false,
                                                            '',
                                                            false,
                                                            '',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            '',
                                                            '',
                                                            move_id,
                                                        );
                                                    }
                                                }}
                                            >
                                                Find your nearest DMV
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                <div className="dmv-left-3">
                                    <div
                                        className="dmv-left-3-head"
                                        style={{
                                            background: isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59',
                                        }}
                                    >
                                        <img src={SavingSvg} style={{ marginRight: '10px' }} />
                                        Cost Estimate
                                    </div>

                                    <div className="dmv-left-3-body">
                                        {classes.length > 0 ? (
                                            classes.map((cl: any) => {
                                                return (
                                                    <div className="dmv-left-3-body1">
                                                        <div className="body-class">
                                                            {cl && cl.details}
                                                            {/* {cl &&
                                                                cl.split(':') &&
                                                                cl.split(':').length > 0 &&
                                                                cl.split(':')[0]} */}
                                                        </div>
                                                        <div
                                                            className="body-amount"
                                                            style={{ fontFamily: 'Poppins, sans-serif' }}
                                                        >
                                                            {cl && cl.forms}
                                                            {/* {cl &&
                                                                cl.split(':') &&
                                                                cl.split(':')[1] &&
                                                                cl.split(':')[1].split('.')[0]} */}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                                                <Skeleton style={{ marginBottom: '20px', height: '30px' }} count={2} />
                                                {/* <Skeleton style={{ height: '30px' }} /> */}
                                            </SkeletonTheme>
                                            // <div
                                            //     style={{
                                            //         marginTop: '20px',
                                            //         display: 'flex',
                                            //         justifyContent: 'center',
                                            //         alignItems: 'center',
                                            //     }}
                                            // >
                                            //     <CircularProgress />
                                            // </div>
                                            // <div className="dataAvailable">No task available</div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="dmv-left-4"
                                    style={{
                                        background: '#100B28',
                                        padding: isDouglasElliman ? '10px 15px 10px 20px' : '0px 15px 0px 20px',
                                    }}
                                >
                                    <div className="dmv-left-41">
                                        <div style={{ fontWeight: 600, marginBottom: '10px' }}>
                                            Driving exams are waived if you have a current out-of-state driver license
                                        </div>
                                        <div style={{ marginBottom: '10px' }}>
                                            You may find the answer to your question in the driver's handbook
                                        </div>
                                        <Link to={this.state.link} target="_blank" download="handbook.pdf">
                                            <Button
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor="#FFFFFF"
                                                width="190px"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={() => {
                                                    window.open(this.state.link, '_blank');
                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }
                                                    if (slug && slug.display_name) {
                                                        let brokerage: any;

                                                        brokerage = slug.display_name;
                                                        let brokerage_key: any = slug.brokerage_key;
                                                        let agent_key: any = slug.realtor_key;

                                                        let agent_name: any = slug.contact_name;
                                                        let move_id: any = localStorage.getItem('moveKey');

                                                        buttonClicked(
                                                            'Button Clicked',
                                                            'Drivers License',
                                                            brokerage,
                                                            brokerage_key,
                                                            agent_name,
                                                            agent_key,
                                                            'Drivers Licence',
                                                            'Download Handbook',
                                                            false,
                                                            '',
                                                            false,
                                                            '',
                                                            '',
                                                            false,
                                                            '',
                                                            false,
                                                            '',
                                                            '',
                                                            move_id,
                                                        );
                                                    }
                                                }}
                                            >
                                                Download Handbook
                                            </Button>
                                        </Link>
                                    </div>
                                    {!isDouglasElliman && <DummySvg />}
                                </div>
                            </div>
                            <div className="dmv-content-right">{checkListArea}</div>
                        </div>
                    </div>
                    {/* ) : (
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                )} */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        dmvAction: bindActionCreators(DMVAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    dmvState: state.dmv,
});

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLicenceMain);
