import React, { Component } from 'react';
import Button from '../atoms/Button';
import './TemplateUpper.scss';

type initialProps = {
    onClick?: any;
    onClickWhite?: any;
    headerText: string;
    subheaderText?: string;
    highlightedText?: string;
    buttonText?: string;
    buttonText1?: string;
    secondLine?: string;
    htmlContent?: any;
    isHighlightedFirst?: boolean;
    pageImage: string;
    isCoverImage?: boolean;
};
export class TemplateUpper extends Component<initialProps> {
    render() {
        let classForImage: string = 'imageTemplate';
        const { pageImage, isCoverImage } = this.props;
        if (pageImage === 'checklist') {
            classForImage = 'checklistImage';
        } else if (pageImage === 'custom') {
            classForImage = 'customImage';
        } else if (pageImage === 'homeWarranty') {
            classForImage = 'homeWarrantyImage';
        } else if (pageImage === 'dmv') {
            classForImage = 'dmvImage';
        } else if (pageImage === 'insurance') {
            classForImage = 'insuranceImage';
        } else if (pageImage === 'homeSecurity') {
            classForImage = 'homeSecurityImage';
        } else if (pageImage === 'internet') {
            classForImage = 'internetImage';
        } else if (pageImage === 'mortgage') {
            classForImage = 'mortgageImage';
        } else if (pageImage === 'moving') {
            classForImage = 'movingImage';
        } else if (pageImage === 'title') {
            classForImage = 'titleImage';
        } else if (pageImage === 'trustedPros') {
            classForImage = 'trustedProsImage';
        } else if (pageImage === 'updateAddress') {
            classForImage = 'updateAddressImage';
        } else if (pageImage === 'utility') {
            classForImage = 'utilityImage';
        }

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                className={isCoverImage ? 'TemplateUpperComponent addTemplateUpperComponent' : 'TemplateUpperComponent'}
            >
                <div
                    className={
                        isCoverImage
                            ? `template-main-div ${classForImage} addtemplate-main-div`
                            : `template-main-div ${classForImage}`
                    }
                >
                    <div
                        className={
                            this.props.buttonText && this.props.subheaderText
                                ? 'borderDiv'
                                : this.props.htmlContent
                                ? 'borderDiv1 adjustTopTemplateUpper'
                                : 'borderDiv1'
                        }
                        style={{ background: '#100B28' }}
                    >
                        <div className="template-main-div1">
                            {this.props.isHighlightedFirst ? (
                                <div className="HeaderText">
                                    {this.props.highlightedText && (
                                        <span className="highlightedText">{this.props.highlightedText}</span>
                                    )}{' '}
                                    {this.props.headerText}
                                </div>
                            ) : (
                                <div className="HeaderText">
                                    {this.props.headerText}{' '}
                                    {this.props.highlightedText && (
                                        <span className="highlightedText">{this.props.highlightedText}</span>
                                    )}
                                </div>
                            )}
                            {this.props.subheaderText && (
                                <div className={this.props.secondLine ? 'subheaderTextsecondLine' : 'subheaderText'}>
                                    {this.props.subheaderText}
                                </div>
                            )}
                            {this.props.secondLine && <div className="subheaderText">{this.props.secondLine}</div>}
                            {this.props.htmlContent ? this.props.htmlContent : null}
                            <div className={'buttonDual'}>
                                {this.props.buttonText1 && (
                                    <div>
                                        <Button
                                            className="btnIC"
                                            backgroundColor="#FFFFFF"
                                            boxShadow={'none'}
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                            }
                                            border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                            }`}
                                            width="180px"
                                            onClick={() => this.props.onClickWhite()}
                                        >
                                            {this.props.buttonText1}
                                        </Button>
                                    </div>
                                )}
                                {this.props.buttonText && (
                                    <div>
                                        <Button
                                            className="btnIC"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                            }
                                            color="#ffffff"
                                            width={this.props.buttonText1 ? '180px' : '140px'}
                                            onClick={() => this.props.onClick()}
                                        >
                                            {this.props.buttonText}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateUpper;
