import React, { Component } from 'react';
import './utilitySavings.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import FinalPlan from '../../components/Utility/FinalPlan';
// import constilation from '../../Assets/images/utilityImages/constilation.png';
import Button from '../../components/atoms/Button';
import ElectricityGasMain from '../../components/Utility/ElectricityGasMainCard';
import FireTopIcon from '../../Assets/images/utilityImages/fireTopIcon.svg';
import * as EnergyAction from '../../store/energy/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { powerkiosImageUrl } from '../../config';
import { buttonClicked } from '../../utils/utlis';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
};
type initialState = {
    slug: any;
    congratulations: boolean;
    summaryData: any;
    accessToken: any;
};

export class confirmationPageUtility extends Component<initialProps, initialState> {
    state: initialState = {
        slug: { primary_color: '' },
        congratulations: false,
        summaryData: [],
        accessToken: '',
    };

    componentDidMount() {
        this.props.energyAction.getAccessToken();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        this.props.energyAction.getEnergySummary();
    }

    componentDidUpdate(prevProps: initialProps) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getAccessToken != curEnergy.getAccessToken &&
            curEnergy.getAccessToken &&
            curEnergy.getAccessToken.token &&
            curEnergy.isGetAccessToken
        ) {
            curEnergy.isGetAccessToken = false;
            this.setState({ accessToken: curEnergy.getAccessToken.token });
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ summaryData: curEnergy.getEnergySummary });
        }
    }
    handleFinalCongo = () => {
        this.setState({
            congratulations: true,
        });
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className="utility-Savings-main-head">
                {this.state.congratulations ? null : (
                    <div className="progressBarFinal">
                        <Box>
                            <LinearProgress variant="determinate" value={100} />
                        </Box>
                    </div>
                )}

                <div className="utilityConfirmationPage">
                    <div className="utilityConfirmationSubPage">
                        <div className="utilityConfirmationInnerContent">
                            {this.state.congratulations ? null : (
                                <div className="arrowButtonWidth">
                                    <ArrowBackIcon onClick={() => this.props.history.push('/dashboard/utilities')} />
                                </div>
                            )}
                            {this.state.congratulations ? (
                                <div>
                                    <div className="congoHeadUtility">
                                        Congratulations, your contract has been submitted
                                    </div>
                                    <div className="congoSubHeadUtility">
                                        You will receive an email or text to complete setup
                                    </div>
                                </div>
                            ) : null}

                            {this.state.congratulations ? (
                                <div className="finalConfirmationCardContent">
                                    <div className="finalPageCardUtility">
                                        <div className="selectedPlanFinal">
                                            <div className="selectedPlanHead">Selected plan details</div>
                                        </div>
                                        {this.state.summaryData &&
                                            this.state.summaryData.map((item: any) => {
                                                return item.contract_payload ? (
                                                    <FinalPlan
                                                        logo={
                                                            item.contract_payload &&
                                                            item.contract_payload.supplier &&
                                                            item.contract_payload.supplier.logo
                                                                ? `${powerkiosImageUrl}${item.contract_payload.supplier.logo}?access_token=${this.state.accessToken}`
                                                                : ''
                                                        }
                                                        kwh={item.contract_payload.displayRate}
                                                        contract={item.contract_payload.term}
                                                    />
                                                ) : null;
                                            })}
                                    </div>
                                    {this.state.summaryData &&
                                        this.state.summaryData.map((item: any) => {
                                            return (
                                                <div className="congratulationDetailsCard">
                                                    <div className="quoteDetailsCongo">Quote details</div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Service Type</div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.service_type}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Contract Number</div>
                                                        <div className="congoDetailsUtilityright">
                                                            #
                                                            {item.contract_payload && item.contract_payload.contractNum}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">
                                                            Service Start Date
                                                        </div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.contract_payload && item.contract_payload.switchDate
                                                                ? `${moment(item.contract_payload.switchDate).format(
                                                                      'MMM',
                                                                  )} ${moment(item.contract_payload.switchDate).format(
                                                                      'DD',
                                                                  )}, ${moment(item.contract_payload.switchDate).format(
                                                                      'YYYY',
                                                                  )}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Expiration Date</div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.contract_payload &&
                                                            item.contract_payload.expirationDate
                                                                ? `${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('MMM')} ${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('DD')}, ${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('YYYY')}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Move Easy Number</div>
                                                        <div className="congoDetailsUtilityright">
                                                            #
                                                            {item.contract_payload && item.contract_payload.contractNum}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Cancellation Fee</div>
                                                        <div className="congoDetailsUtilityright">$0</div>
                                                    </div>
                                                    <div className="endDisclaimerUtility">
                                                        *The utility quote(s) displayed above are provided by licensed
                                                        utility carriers and are preliminary estimates only and are
                                                        subject to change based on additional information we may receive
                                                        later in the quoting process, the coverages, deductibles and
                                                        payment options you choose along with other discounts, features
                                                        or savings that may apply.
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    <div className="whatsNextCongo">What’s Next?</div>
                                    <div className="electrincityGasCardCongo">
                                        <ElectricityGasMain
                                            GasElect={'Gas Savings'}
                                            progress={this.state.summaryData.length >= 2 ? 100 : 50}
                                            icon={FireTopIcon}
                                            handleNext={() => {
                                                this.props.history.push({
                                                    pathname: '/dashboard/utilities',
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="backToHomeUtility">
                                        <Button
                                            // backgroundColor={
                                            //     this.state.slug && this.state.slug.primary_color
                                            //         ? this.state.slug.primary_color
                                            //         : '#273E59'
                                            // }
                                            // color={'#fff'}
                                            onClick={() => {
                                                if (
                                                    localStorage.getItem('isHomeOwner') &&
                                                    localStorage.getItem('isHomeOwner') == 'true'
                                                ) {
                                                    this.props.history.push('/dashboardhomeOwner');
                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }

                                                    let brokerage: any;
                                                    brokerage = slug.display_name;
                                                    let brokerage_key: any = slug.brokerage_key;
                                                    let agent_key: any = slug.realtor_key;
                                                    let agent_name: any = slug.contact_name;
                                                    let move_id: any = localStorage.getItem('moveKey');

                                                    buttonClicked(
                                                        'Button Clicked',
                                                        'Confirmation Page',
                                                        brokerage,
                                                        brokerage_key,
                                                        agent_name,
                                                        agent_key,
                                                        'Utility Providers',
                                                        'Back to Dashboard',
                                                        false,
                                                        '',
                                                        false,
                                                        '',
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        '',
                                                        '',
                                                        move_id,
                                                    );
                                                } else {
                                                    this.props.history.push('/dashboard');
                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }

                                                    let brokerage: any;
                                                    brokerage = slug.display_name;
                                                    let brokerage_key: any = slug.brokerage_key;
                                                    let agent_key: any = slug.realtor_key;
                                                    let agent_name: any = slug.contact_name;
                                                    let move_id: any = localStorage.getItem('moveKey');

                                                    buttonClicked(
                                                        'Button Clicked',
                                                        'Confirmation Page',
                                                        brokerage,
                                                        brokerage_key,
                                                        agent_name,
                                                        agent_key,
                                                        'Utility Providers',
                                                        'Back to Dashboard',
                                                        false,
                                                        '',
                                                        false,
                                                        '',
                                                        '',
                                                        false,
                                                        '',
                                                        false,
                                                        '',
                                                        '',
                                                        move_id,
                                                    );
                                                }
                                            }}
                                        >
                                            Back to Dashboard
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="finalConfirmationCardContent">
                                    <div className="confirmationTopCardUtility" style={{ backgroundColor: '#273E59' }}>
                                        <div className="confirmationHeadingUtility">Confirmation</div>
                                        <div className="confirmationbottomUtility">
                                            Your contract has been submitted.
                                        </div>
                                    </div>
                                    <div className="finalPageCardUtility">
                                        <div className="selectedPlanFinal">
                                            <div className="selectedPlanHead">Selected plan details</div>
                                        </div>
                                        {this.state.summaryData &&
                                            this.state.summaryData.map((item: any) => {
                                                let displayRate: any =
                                                    item.contract_payload && item.contract_payload.displayRate;
                                                if (displayRate.includes('$')) {
                                                    displayRate = displayRate.replace('$', '');
                                                    displayRate = parseFloat(displayRate) * 100;
                                                    displayRate = displayRate.toFixed(2);
                                                    displayRate = `${displayRate}¢`;
                                                }
                                                return item.contract_payload ? (
                                                    <FinalPlan
                                                        logo={
                                                            item.contract_payload &&
                                                            item.contract_payload.supplier &&
                                                            item.contract_payload.supplier.logo
                                                                ? `${powerkiosImageUrl}${item.contract_payload.supplier.logo}?access_token=${this.state.accessToken}`
                                                                : ''
                                                        }
                                                        supplier={item.supplier_name}
                                                        kwh={displayRate}
                                                        contract={item.contract_payload.term}
                                                    />
                                                ) : null;
                                            })}
                                    </div>
                                    <div className="confirmationBottomUtility" onClick={this.handleFinalCongo}>
                                        <div className="finalStepAsAProcess">Complete Setup</div>
                                        <div className="sentCallOnNumber">
                                            You will receive a notification via email or text. To complete set up,
                                            follow the prompts
                                        </div>
                                    </div>
                                    <div className="backToHomeUtility">
                                        <Button
                                            backgroundColor={'#fff'}
                                            border={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? `1px solid ${this.state.slug.primary_color} `
                                                    : '1px solid #273E59'
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: '/dashboard/energy-savings/energySteps',
                                                });

                                                let slug: any = localStorage.getItem('slug');
                                                if (slug) {
                                                    slug = JSON.parse(slug);
                                                }

                                                let brokerage: any;
                                                brokerage = slug.display_name;
                                                let brokerage_key: any = slug.brokerage_key;
                                                let agent_key: any = slug.realtor_key;
                                                let agent_name: any = slug.contact_name;
                                                let move_id: any = localStorage.getItem('moveKey');

                                                buttonClicked(
                                                    'Button Clicked',
                                                    'Confirmation Page',
                                                    brokerage,
                                                    brokerage_key,
                                                    agent_name,
                                                    agent_key,
                                                    'Utility Providers',
                                                    'Back to Home',
                                                    false,
                                                    '',
                                                    false,
                                                    '',
                                                    '',
                                                    false,
                                                    '',
                                                    false,
                                                    '',
                                                    '',
                                                    move_id,
                                                );
                                            }}
                                        >
                                            Back to Home
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(confirmationPageUtility);
