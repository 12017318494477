import {
    GET_PRODUCTS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_ERROR,
    GET_PRODUCT_DETAILS_SUCCESS,
    POST_ORDER_WARRANTY,
    POST_ORDER_WARRANTY_ERROR,
    POST_ORDER_WARRANTY_SUCCESS,
    GET_HOMEWARRANTY_DETAILS,
    GET_HOMEWARRANTY_DETAILS_SUCCESS,
    GET_HOMEWARRANTY_DETAILS_ERROR,
    UPDATE_HOMEWARRANTY_DETAILS,
    UPDATE_HOMEWARRANTY_DETAILS_SUCCESS,
    UPDATE_HOMEWARRANTY_DETAILS_ERROR,
    POST_HOMEWARRANTY_DETAILS,
    POST_HOMEWARRANTY_DETAILS_SUCCESS,
    POST_HOMEWARRANTY_DETAILS_ERROR,
    GET_PLAN_TAX,
    GET_PLAN_TAX_SUCCESS,
    GET_PLAN_TAX_ERROR,
} from './actionTypes';
import * as types from './types';

export const postOrderWarranty = (payload: types.PostOrderWarrantyRequest): types.PostOrderWarranty => ({
    type: POST_ORDER_WARRANTY,
    payload,
});
export const postOrderWarrantySuccess = (
    payload: types.PostOrderWarrantySuccessPayload,
): types.PostOrderWarrantySuccess => ({
    type: POST_ORDER_WARRANTY_SUCCESS,
    payload,
});
export const postOrderWarrantyFailure = (
    payload: types.PostOrderWarrantyFailurePayload,
): types.PostOrderWarrantyFailure => ({
    type: POST_ORDER_WARRANTY_ERROR,
    payload,
});

export const getProducts = (payload: types.GetProductsRequest): types.GetProducts => ({
    type: GET_PRODUCTS,
    payload,
});
export const getProductsSuccess = (payload: types.GetProductsSuccessPayload): types.GetProductsSuccess => ({
    type: GET_PRODUCTS_SUCCESS,
    payload,
});
export const getProductsFailure = (payload: types.GetProductsFailurePayload): types.GetProductsFailure => ({
    type: GET_PRODUCTS_ERROR,
    payload,
});

export const getProductDetails = (payload: types.GetProductDetailsRequest): types.GetProductDetails => ({
    type: GET_PRODUCT_DETAILS,
    payload,
});
export const getProductDetailsSuccess = (
    payload: types.GetProductDetailsSuccessPayload,
): types.GetProductDetailsSuccess => ({
    type: GET_PRODUCT_DETAILS_SUCCESS,
    payload,
});
export const getProductDetailsFailure = (
    payload: types.GetProductDetailsFailurePayload,
): types.GetProductDetailsFailure => ({
    type: GET_PRODUCT_DETAILS_ERROR,
    payload,
});

export const getHomeWarrantyDetails = (payload: types.GetHomeWarrantyDetailsRequest): types.GetHomeWarrantyDetails => ({
    type: GET_HOMEWARRANTY_DETAILS,
    payload,
});
export const getHomeWarrantyDetailsSuccess = (
    payload: types.GetHomeWarrantyDetailsSuccessPayload,
): types.GetHomeWarrantyDetailsSuccess => ({
    type: GET_HOMEWARRANTY_DETAILS_SUCCESS,
    payload,
});
export const getHomeWarrantyDetailsFailure = (
    payload: types.GetHomeWarrantyDetailsFailurePayload,
): types.GetHomeWarrantyDetailsFailure => ({
    type: GET_HOMEWARRANTY_DETAILS_ERROR,
    payload,
});

// update homewaranty details

export const updateHomeWarrantyDetailsInfo = (
    payload: types.UpdateHomeWarrantyDetailsInfoRequest,
): types.UpdateHomeWarrantyDetailsInfo => {
    return {
        type: UPDATE_HOMEWARRANTY_DETAILS,
        payload,
    };
};

export const updateHomeWarrantyDetailsInfoSuccess = (
    payload: types.UpdateHomeWarrantyDetailsInfoSuccessPayload,
): types.UpdateHomeWarrantyDetailsInfoSuccess => {
    return {
        type: UPDATE_HOMEWARRANTY_DETAILS_SUCCESS,
        payload,
    };
};

export const updateHomeWarrantyDetailsInfoFailure = (
    payload: types.UpdateHomeWarrantyDetailsInfoFailurePayload,
): types.UpdateHomeWarrantyDetailsInfoFailure => {
    return {
        type: UPDATE_HOMEWARRANTY_DETAILS_ERROR,
        payload,
    };
};

export const postHomeWarrantyDetails = (
    payload: types.PostHomeWarrantyDetailsRequest,
): types.PostHomeWarrantyDetails => ({
    type: POST_HOMEWARRANTY_DETAILS,
    payload,
});
export const postHomeWarrantyDetailsSuccess = (
    payload: types.PostHomeWarrantyDetailsSuccessPayload,
): types.PostHomeWarrantyDetailsSuccess => ({
    type: POST_HOMEWARRANTY_DETAILS_SUCCESS,
    payload,
});
export const postHomeWarrantyDetailsFailure = (
    payload: types.PostHomeWarrantyDetailsFailurePayload,
): types.PostHomeWarrantyDetailsFailure => ({
    type: POST_HOMEWARRANTY_DETAILS_ERROR,
    payload,
});

export const getPlanTax = (payload: types.GetPlanTaxRequest): types.GetPlanTax => ({
    type: GET_PLAN_TAX,
    payload,
});
export const getPlanTaxSuccess = (payload: types.GetPlanTaxSuccessPayload): types.GetPlanTaxSuccess => ({
    type: GET_PLAN_TAX_SUCCESS,
    payload,
});
export const getPlanTaxFailure = (payload: types.GetPlanTaxFailurePayload): types.GetPlanTaxFailure => ({
    type: GET_PLAN_TAX_ERROR,
    payload,
});
