import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromLanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={88.275}
            height={83.105}
            viewBox="0 0 88.275 83.105"
        >
            <defs>
                <style>
                    {`.downloadStepprefix__a{fill:${
                      props.fromLanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="downloadStepprefix__a"
                d="M74.356 41.872V28.224a9.4 9.4 0 00-9.39-9.388h-.417V18.6a9.4 9.4 0 00-9.389-9.39H40.153a.6.6 0 01-.448-.2l-5.446-5.954A9.409 9.409 0 0027.329 0H9.388A9.4 9.4 0 000 9.389v52.4a9.4 9.4 0 009.388 9.388h38.5a21.272 21.272 0 1026.468-29.306zM9.388 68.394a6.612 6.612 0 01-6.6-6.605v-52.4a6.613 6.613 0 016.6-6.605h17.941A6.623 6.623 0 0132.2 4.933l5.443 5.954a3.393 3.393 0 002.505 1.105h15.013a6.614 6.614 0 016.605 6.608v.237H26.223a9.4 9.4 0 00-9.388 9.388v33.564a6.613 6.613 0 01-6.607 6.6zm7.507 0a9.355 9.355 0 002.724-6.6v-33.57a6.612 6.612 0 016.605-6.6h38.742a6.613 6.613 0 016.607 6.6v12.833a21.274 21.274 0 00-24.809 27.336zM67 80.323a18.49 18.49 0 1118.49-18.49A18.489 18.489 0 0167 80.323zm0 0"
            />
            <path
                className="downloadStepprefix__a"
                d="M73.011 62.751l-4.619 4.3v-13.63a1.392 1.392 0 10-2.783 0v13.63l-4.619-4.3a1.393 1.393 0 10-1.9 2.038l7.907 7.358 7.907-7.358a1.393 1.393 0 10-1.9-2.038zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
