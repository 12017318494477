import * as React from 'react';
import { navigationIconColorSelector } from '../../_fill_calculator';
// import { SVGProps } from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.636" height="30.635" viewBox="0 0 30.636 30.635" {...props}>
            <defs>
                <style>
                    {`.energyprefix__a{fill:#fff}.energySvgprefix__b{
                        fill:${navigationIconColorSelector(props.active, "a", "fill")} !important;
                        stroke:${navigationIconColorSelector(props.active, "b", "stroke")} !important;
                    }`}
                </style>
            </defs>
            <path
                data-name="Path 51373"
                d="m30.581 18.941-1.611 4.168-2.489-.073a12.326 12.326 0 0 1-2.929 3.075l.183 2.489-4.1 1.793-1.72-1.784a13.746 13.746 0 0 1-4.246.109l-1.611 1.867-4.173-1.611.073-2.489a12.327 12.327 0 0 1-3.075-2.929l-2.489.183-1.794-4.1 1.793-1.72a13.746 13.746 0 0 1-.11-4.246L.418 12.059l1.611-4.173 2.489.073a12.327 12.327 0 0 1 2.929-3.075l-.183-2.489 4.1-1.793 1.72 1.793a13.73 13.73 0 0 1 4.246-.11L18.941.418l4.173 1.611-.073 2.489a12.326 12.326 0 0 1 3.075 2.929l2.489-.183 1.794 4.1-1.791 1.72a13.746 13.746 0 0 1 .11 4.246Zm-4.6-3.441a10.485 10.485 0 1 0-10.482 10.486A10.485 10.485 0 0 0 25.985 15.5Z"
                // fill="#bebebe"
                className="energySvgprefix__b"
            />
            <path
                data-name="Path 51374"
                d="M15.5 5.456A10.045 10.045 0 1 1 5.455 15.501 10.045 10.045 0 0 1 15.5 5.456Z"
                fill="#fff"
            />
            <path
                data-name="Path 51375"
                d="m18.291 13.877-4 7.663.237-5.169h-1.819l1.047-6.911h4.008l-1.69 4.417Z"
                // fill="#bebebe"
                className="energySvgprefix__b"
            />
            <g data-name="Group 40272" fill="#707070">
                <path
                    data-name="Path 51376"
                    d="M15.499 5.037A10.463 10.463 0 1 0 25.962 15.5 10.475 10.475 0 0 0 15.499 5.037Zm0 20.191a9.728 9.728 0 1 1 9.728-9.728 9.739 9.739 0 0 1-9.728 9.728Z"
                />
                <path
                    data-name="Path 51377"
                    d="M29.151 17.178a14.139 14.139 0 0 0-.1-3.965l1.651-1.583a.383.383 0 0 0 .086-.43l-1.803-4.11a.383.383 0 0 0-.379-.228l-2.286.168a12.7 12.7 0 0 0-2.873-2.74l.067-2.285a.383.383 0 0 0-.245-.368L19.085.026a.383.383 0 0 0-.428.107L17.178 1.85a14.127 14.127 0 0 0-3.965.1L11.63.3a.383.383 0 0 0-.43-.086l-4.11 1.8a.383.383 0 0 0-.228.379L7.03 4.68a12.694 12.694 0 0 0-2.737 2.874L2.009 7.49a.386.386 0 0 0-.368.245L.026 11.917a.383.383 0 0 0 .107.428l1.717 1.482a14.138 14.138 0 0 0 .1 3.964L.3 19.374a.383.383 0 0 0-.086.43l1.8 4.11a.381.381 0 0 0 .379.228l2.286-.168a12.7 12.7 0 0 0 2.874 2.737l-.068 2.279a.383.383 0 0 0 .245.369l4.183 1.615a.383.383 0 0 0 .428-.107l1.481-1.717a14.128 14.128 0 0 0 3.964-.1l1.583 1.65a.383.383 0 0 0 .43.086l4.11-1.8a.383.383 0 0 0 .228-.379l-.168-2.287a12.693 12.693 0 0 0 2.737-2.874l2.285.067a.381.381 0 0 0 .368-.245l1.615-4.178a.383.383 0 0 0-.107-.428Zm-.408 5.563-2.224-.065a.381.381 0 0 0-.33.17 11.938 11.938 0 0 1-2.845 2.987.383.383 0 0 0-.154.336l.164 2.225-3.617 1.582-1.539-1.6a.383.383 0 0 0-.345-.112 13.383 13.383 0 0 1-4.138.107.383.383 0 0 0-.339.13l-1.438 1.667-3.679-1.42.065-2.224a.383.383 0 0 0-.17-.33 11.94 11.94 0 0 1-2.987-2.844.381.381 0 0 0-.336-.154l-2.224.164-1.583-3.617 1.6-1.539a.383.383 0 0 0 .112-.345 13.377 13.377 0 0 1-.107-4.138.383.383 0 0 0-.13-.339L.831 11.943l1.42-3.678 2.224.065a.386.386 0 0 0 .33-.17A11.941 11.941 0 0 1 7.65 5.173a.383.383 0 0 0 .154-.336L7.64 2.612l3.617-1.582 1.539 1.6a.383.383 0 0 0 .345.112 13.365 13.365 0 0 1 4.138-.107.383.383 0 0 0 .339-.13L19.056.838l3.679 1.42-.065 2.224a.383.383 0 0 0 .17.33 11.938 11.938 0 0 1 2.987 2.845.384.384 0 0 0 .336.154l2.224-.164 1.583 3.617-1.605 1.539a.383.383 0 0 0-.112.345 13.38 13.38 0 0 1 .107 4.138.383.383 0 0 0 .129.339l1.667 1.439Z"
                />
                <path
                    data-name="Path 51378"
                    d="M18.29 13.458h-1.608l1.472-3.847a.419.419 0 0 0-.391-.568h-4.008a.419.419 0 0 0-.414.356l-1.051 6.912a.419.419 0 0 0 .414.481h1.386l-.216 4.732a.419.419 0 0 0 .789.213l4-7.663a.419.419 0 0 0-.371-.612Zm-3.495 6.21.15-3.278a.419.419 0 0 0-.418-.438h-1.332l.92-6.074h3.04l-1.472 3.849a.419.419 0 0 0 .391.568H17.6Z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
