import * as React from 'react';

type initialProps = {
    isActive?: boolean;
    className?: any;
};
// function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
function SvgComponent(props: initialProps) {
    return (
        <svg
            {...props}
            className={props.className ? props.className : null}
            xmlns="http://www.w3.org/2000/svg"
            width={32.372}
            // height={9.099}
            viewBox="0 0 32.372 9.099"
        >
            <g data-name="Group 1184" fill="#fff">
                <path
                    data-name="Path 2607"
                    d="M31.466 4.55A3.643 3.643 0 1 1 27.823.907a3.643 3.643 0 0 1 3.643 3.643Zm0 0"
                />
                <path
                    data-name="Path 2609"
                    d="M8.192 4.55A3.643 3.643 0 1 1 4.549.907 3.643 3.643 0 0 1 8.192 4.55Zm0 0"
                />
            </g>
            <path
                data-name="Path 2610"
                d="M27.823 0a4.55 4.55 0 1 0 4.55 4.551 4.55 4.55 0 0 0-4.55-4.55Zm0 7.285a2.736 2.736 0 1 1 2.736-2.734 2.736 2.736 0 0 1-2.736 2.735Zm0 0"
                fill={props.isActive ? '#009CBD' : '#100b28'}
            />
            <path
                data-name="Path 2611"
                d="M16.186 0a4.55 4.55 0 1 0 4.55 4.551 4.55 4.55 0 0 0-4.55-4.55Zm0 7.285a2.736 2.736 0 1 1 2.735-2.734 2.736 2.736 0 0 1-2.735 2.735Zm0 0"
                fill={props.isActive ? '#009CBD' : '#100b28'}
            />
            <path
                data-name="Path 2612"
                d="M4.55 0A4.55 4.55 0 1 0 9.1 4.551 4.55 4.55 0 0 0 4.55 0Zm0 7.285a2.736 2.736 0 1 1 2.735-2.734A2.736 2.736 0 0 1 4.55 7.286Zm0 0"
                fill={props.isActive ? '#009CBD' : '#100b28'}
            />
        </svg>
    );
}

export default SvgComponent;
