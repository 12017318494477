import * as React from 'react';
import { colorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={58.406} height={58.41} viewBox="0 0 58.406 58.41" {...props}>
            <defs>
                <style>{`.insuranceMainHomeprefix__c{fill:${ 
                    colorSelector(props.fromLanding || window.location.pathname.includes('landing'), "b")
                }}`}</style>
            </defs>
            <path
                d="M45.023 14.238V35.29a4.821 4.821 0 01-2.336 4.137 5.1 5.1 0 01-2.531.73h-21.9a4.882 4.882 0 01-4.867-4.868V14.217l15.819-13z"
                fill={ colorSelector(props.fromLanding || window.location.pathname.includes('landing')) }
            />
            <path
                d="M35.288 30.421v9.733H23.119v-9.735a4.7 4.7 0 01.365-1.825 2.46 2.46 0 01.17-.365 4.809 4.809 0 014.332-2.677h2.433a4.882 4.882 0 014.869 4.869z"
                fill="#fff"
            />
            <path
                className="insuranceMainHomeprefix__c"
                d="M9.29 19.193l19.913-16.4 19.915 16.4a1.2 1.2 0 00.772.277 1.216 1.216 0 00.771-2.156L29.977.278a1.218 1.218 0 00-1.548 0L7.742 17.314a1.217 1.217 0 001.548 1.879z"
            />
            <path
                className="insuranceMainHomeprefix__c"
                d="M13.384 19.47a1.217 1.217 0 00-1.217 1.217v14.6a6.093 6.093 0 006.084 6.084h21.9a6.091 6.091 0 006.084-6.084v-14.6a1.217 1.217 0 00-2.434 0v14.6a3.655 3.655 0 01-3.651 3.651h-3.651V30.42a6.091 6.091 0 00-6.084-6.084h-2.434a6.093 6.093 0 00-6.084 6.084v8.518h-3.651a3.655 3.655 0 01-3.651-3.651v-14.6a1.217 1.217 0 00-1.211-1.217zm10.952 10.952a3.655 3.655 0 013.651-3.651h2.434a3.655 3.655 0 013.651 3.651v8.518h-9.735zM58.05 51.464l-4.868-4.868a1.218 1.218 0 10-1.721 1.723l2.792 2.789h-50.1l2.789-2.789a1.218 1.218 0 10-1.721-1.723L.356 51.464a1.221 1.221 0 000 1.723l4.865 4.868a1.218 1.218 0 001.721-1.723l-2.789-2.789h50.1l-2.792 2.789a1.218 1.218 0 001.721 1.723l4.868-4.868a1.221 1.221 0 000-1.723z"
            />
        </svg>
    );
}

export default SvgComponent;
