import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    postNextMoveFailure,
    postNextMoveSuccess,
    sendRealtorNotificationFailure,
    sendRealtorNotificationSuccess,
    getNextMoveFailure,
    getNextMoveSuccess,
    updateNextMoveFailure,
    updateNextMoveSuccess,
} from './actions';
import { POST_NEXTMOVE, SEND_REALTOR_NOTIFICATION, GET_NEXTMOVE, UPDATE_NEXTMOVE } from './actionTypes';
import apiJunction from '../../utils/api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* postNextMove(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/create-next-move/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(postNextMoveSuccess({ postNextMove: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postNextMoveFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}
function* updateNextMove(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/move/move-savings-data/${action.payload.serviceId}/?move_id=${action.payload.moveId}`,
            body: action.payload.body,
        });
        if (response.data) {
            yield put(updateNextMoveSuccess({ updateNextMove: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateNextMoveFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* sendRealtorNotification(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/${action.payload.moveId}/send-realtor-notification/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(sendRealtorNotificationSuccess({ sendRealtorNotification: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(sendRealtorNotificationFailure({ error: (e as Error).message }));
        store.addNotification({
            title: 'Failure',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });

        // history.push('/server-error');
    }
}

function* getNextMove(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-savings-data/?move_id=${action.payload.moveId}`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getNextMoveSuccess({ getNextMove: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getNextMoveFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}
export default function* documentrySaga() {
    yield all([takeLatest(POST_NEXTMOVE, postNextMove)]);
    yield all([takeLatest(SEND_REALTOR_NOTIFICATION, sendRealtorNotification)]);
    yield all([takeLatest(GET_NEXTMOVE, getNextMove)]);
    yield all([takeLatest(UPDATE_NEXTMOVE, updateNextMove)]);
}
