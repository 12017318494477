import React, { useEffect, useRef, useState } from 'react';
import './ICmain.scss';
import PreLoaderImage from '../../Assets/images/SignUp/preloader.gif';
import AnalyticsService from '../../utils/analytics-service';

declare global {
    interface Window {
        initializeInline?: any;
        mfInitialized?: boolean;
    }
}

const InternetAndCableWiget = (props: any) => {
    const { history } = props;
    const ref = useRef<any>(null);
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState({ state: '', city: '', zipcode: '', street: '' })
    const [slug, setSlug] = useState<any>();
    useEffect(() => {

        const addressObj = localStorage.getItem('pods_destination_address');
        let { state: state, city: city, zipcode: zipcode, street: street } = addressObj
            ? JSON.parse(addressObj)
            : { state: '', city: '', zipcode: '', street: '' };
        setAddress({ state, city, zipcode, street })
        if (!addressObj) {
            history.push('/dashboard/internet/internetgetstart');
        }

        AnalyticsService.pageViewed({ page: "Internet & Cable" });
    }, [])

    useEffect(() => {
        let getSlug: any = localStorage.getItem('slug');
        if (getSlug) {
            setSlug(JSON.parse(getSlug));
        }
        return () => { }
    }, [])

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://static.myfinance.com/widget/inlineMedia_core.js';
        script.async = true;
        script.setAttribute('id', 'myFinance-widget-script');
        script.onload = function () {
            if (window.initializeInline) {
                window.initializeInline({ loadInlineCss: true });
                var checkExist = setInterval(function () {
                    if (document.getElementById('MyFiAd')) {
                        setLoading(false);
                        //Remove all connect banner
                        try {
                            let allConnectBanner = document.getElementsByClassName("flex justify-between mb-16") as HTMLCollection
                            if (allConnectBanner.length > 0) {
                                const elem: any = allConnectBanner[0]
                                elem.style.visibility = 'hidden';
                            }
                        } catch (_E) {
                            console.error("Error while setting visibility in banner")
                        }
                        clearInterval(checkExist);
                    }
                }, 3000); // Check if loaded for every 3 sec
            }
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            if (window.mfInitialized) {
                // Reset widget on unmount
                window.mfInitialized = false;
            }
        };
    }, []);

    return (
        <div>
            <div>
                <div style={{ marginTop: '40px' }}></div>
                <div className="main-head">
                    <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>Internet And Cable</h2>
                    <div className="path">
                        <p className="Para" style={{ cursor: 'pointer' }}>
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Internet And Cable</p>
                    </div>
                </div>
                <div className="InternetcoverDiv">
                    <div className="landingPage">
                        {loading && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: '60vh',
                                }}
                            >
                                <img style={{ maxHeight: '300px' }} src={PreLoaderImage} />
                                <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                                    Hang On! We Are Setting Up Your Internet And Cable...
                                </div>
                            </div>
                        )}
                        <div
                            ref={ref}
                            className="myFinance-widget"
                            data-creative-set-id="8812a047-0f6e-4f96-89b2-e8083771c69f"
                            data-campaign="chameleon-move-easy-full-page"
                            data-street={address.street}
                            data-city={address.city}
                            data-state={address.state}
                            data-zip={address.zipcode}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InternetAndCableWiget;
