import {
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_UPDATE,
    SIGNUP_UPDATE_ERROR,
    SIGNUP_UPDATE_SUCCESS,
    LANDING_MOVE_POST,
    LANDING_MOVE_POST_ERROR,
    LANDING_MOVE_POST_SUCCESS,
    LANDING_MOVE_POST_FOR_SECURITY,
    LANDING_MOVE_POST_FOR_SECURITY_ERROR,
    LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
    POST_GHOST_LOGIN,
    POST_GHOST_LOGIN_SUCCESS,
    POST_GHOST_LOGIN_ERROR,
    LANDING_MOVE_GET,
    LANDING_MOVE_GET_SUCCESS,
    LANDING_MOVE_GET_ERROR,
    LANDING_MOVE_RESET,
} from './actionTypes';

import { SignupActions, SignupState } from './types';

const initialState: SignupState = {
    signup: [],
    error: null,
    signupUpdate: [],
    landingMovePost: null,
    landingMoveGet: null,
    status:null,
    signUpUpdated: false,
    landingMovePosted: false,
    landingtokenSet: false,
    ghostSignUp: [],
};

export default (state = initialState, action: SignupActions) => {
    switch (action.type) {
        case SIGNUP:
            return {
                ...state,
                signup: [],
                error: null,
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signup: action.payload.signup,
                error: null,
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                signup: [],
                error: action.payload.error,
            };

        case SIGNUP_UPDATE:
            return {
                ...state,
                signupUpdate: [],
                error: null,
            };
        case SIGNUP_UPDATE_SUCCESS:
            return {
                ...state,
                signupUpdate: action.payload.signupUpdate,
                signUpUpdated: true,
                error: null,
            };
        case SIGNUP_UPDATE_ERROR:
            return {
                ...state,
                signupUpdate: [],
                error: action.payload.error,
            };

        case LANDING_MOVE_POST:
            return {
                ...state,
                landingMovePost: null,
                landingMoveGet: null,
                error: null,
                landingMovePosted: false,
                landingtokenSet: false,
                landingMovePostStatus: "started"
            };
        case LANDING_MOVE_POST_SUCCESS:
            return {
                ...state,
                landingMovePost: action.payload.landingMovePost,
                landingMoveGet: null,
                error: null,
                landingMovePosted: true,
                landingtokenSet: true,
                landingMovePostStatus: "success"
            };
        case LANDING_MOVE_POST_ERROR:
            return {
                ...state,
                landingMovePost: null,
                error: action.payload.error,
                landingMovePosted: false,
                landingtokenSet: false,
                landingMovePostStatus: "error"
            };
        case LANDING_MOVE_GET:
            return {
                ...state,
                landingMoveGet: null,
                error: null,
                success: false,
                landingMoveGetStatus: "started"
            };
        case LANDING_MOVE_GET_SUCCESS:
            return {
                ...state,
                landingMoveGet: action.payload.landingMoveGet,
                landingMovePost: null,
                error: null,
                success: true,
                landingMoveGetStatus: "success"
            };
        case LANDING_MOVE_RESET:
            return {
                ...state,
                landingMoveGet: null,
                landingMovePost: null,
                error: null,
                success: false,
                landingMoveGetStatus: null,
                landingMovePostStatus: null
            };
        case LANDING_MOVE_GET_ERROR:
            return {
                ...state,
                landingMoveGet: null,
                error: action.payload.error,
                success: false,
                landingMoveGetStatus: "error"
            };
        case LANDING_MOVE_POST_FOR_SECURITY:
            return {
                ...state,
                landingMovePost: [],
                error: null,
                landingtokenSet: false,
            };
        case LANDING_MOVE_POST_FOR_SECURITY_SUCCESS:
            return {
                ...state,
                landingMovePost: action.payload.landingMovePost,
                error: null,
                landingtokenSet: true,
            };
        case LANDING_MOVE_POST_FOR_SECURITY_ERROR:
            return {
                ...state,
                landingMovePost: [],
                error: action.payload.error,
                landingtokenSet: false,
            };
        case POST_GHOST_LOGIN:
            return {
                ...state,
                ghostSignUp: [],
                error: null,
            };
        case POST_GHOST_LOGIN_SUCCESS:
            return {
                ...state,
                ghostSignUp: action.payload.ghostSignUp,
                error: null,
            };
        case POST_GHOST_LOGIN_ERROR:
            return {
                ...state,
                ghostSignUp: [],
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
