import React from 'react';
// import './AwesomeSavings.scss';
import './SavingsConfirmation.scss';

// import Box from '@material-ui/core/Box';
// import LinearProgress from '@material-ui/core/LinearProgress';
import savingImage from '../../Assets/images/homeownerSavings/confirmSavings.svg';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import Modal from '../../components/atoms/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import saving from '../../Assets/images/PreMove/mobilesavings.svg';
import { scheduleSegment } from '../../utils/utlis';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    handleBack?: any;
};

type initialState = {
    activeStep: number;
    calendlyOpen: boolean;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    isDataArrived: boolean;
    logo: any;
    full_name: string;
    phone: string;
    phone2: string;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info: any;
    custom_schedule_call_info_enabled: any;
    moveId: string;
    hashcode: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
};

class ConfirmSavings extends React.Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 5,
        calendlyOpen: false,
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        isDataArrived: true,
        logo: '',
        full_name: '',
        phone: '',
        phone2: '',
        formOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        isSubmitClicked: false,
        custom_schedule_call_info_enabled: false,
        custom_schedule_call_info: '',
        moveId: '',
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
    };
    componentDidMount() {
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
    }
    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Schedule Call Button Clicked',
            'Awesome Saving',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Savings',
            'Schedule A Call',
            true,
            `Schedule A Call with ${this.props.commonState &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].assigned_concierge &&
                this.props.commonState.move[0].assigned_concierge.full_name}`,
            false,
            ' ',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Savings Confirmation',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };
    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };
    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };
    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            this.state.phone.length >= 12
        ) {
            //   this.setState({
            //      formOpen:false
            //   })
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            JSON.stringify(nextProps.commonState.appointmentDetails) !==
                JSON.stringify(currentState.appointment_details) &&
            nextProps.commonState.appointmentDetailsArrived
        ) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.logo =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    ? nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    : '';
            currentState.full_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.full_name;
            currentState.phone2 =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.phone;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.custom_schedule_call_info =
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.custom_schedule_call_info;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        // if(this.state.cancelClicked){
        //     let countNew=0
        //     setInterval(() => {
        //         if (countNew < 5) {
        //             this.props.commonAction.appointmentGet(payload)
        //                 countNew=countNew + 1
        //         }
        //     }, 5000);

        // }else{
        this.props.commonAction.appointmentGet(payload);
        setTimeout(() => {
            this.props.commonAction.moveGet();
        }, 2000);
        // }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let time = '';
        let hours =
            this.state.appointmentTime != null && this.state.appointmentTime != ''
                ? this.state.appointmentTime.toString().split(':')[0]
                : '';
        let minutes =
            this.state.appointmentTime != null && this.state.appointmentTime != ''
                ? this.state.appointmentTime.toString().split(':')[1]
                : '';
        let AmOrPm = parseInt(hours) >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        time = `${hours}:${minutes} ${AmOrPm}`;
        return (
            <div className="confirmSavingsPage1">
                <div className="progressBarFinalSavings">
                    {/* <Box>
                        <LinearProgress variant="determinate" value={((this.state.activeStep + 1) / 7) * 100} />
                    </Box> */}
                </div>
                <div className="confirmSavingsWhiteBackground1">
                    <div className="mainConfirmRight">
                        <img src={savingImage} />
                    </div>
                    <div className="mainSave">
                        <img src={saving} />
                    </div>
                    <div className="mainConfirmLeftSavings">
                        <div className="mainHeadConfirm">
                            It looks like you’ve recently moved into your home and are currently getting the best
                            eligible deals.
                        </div>
                        <div className="subHeadConfirm">
                            If you’d like to make any changes to your services or explore your other options, please
                            visit your Dashboard or set up a call with your Concierge.
                        </div>

                        <div className="appointment-time-div">
                            <div className="details"> Appointment Details </div>
                            {this.state.appointmentTime ? (
                                <div className="details-content">
                                    {' '}
                                    Your Call is scheduled on {moment(this.state.appointmentTime).format(
                                        'MM-DD-YYYY',
                                    )}, {time}
                                </div>
                            ) : null}
                        </div>
                        <div className="buttonMovedToNextStage">
                            {this.state.custom_schedule_call_info_enabled ? (
                                <Button
                                    onClick={() => this.formHandler()}

                                    // onClick={() => {
                                    //     this.props.history.push('/dashboard/savings/summary');
                                    // }}
                                >
                                    Contact Us
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => this.calendlyHandle()}

                                    // onClick={() => {
                                    //     this.props.history.push('/dashboard/savings/summary');
                                    // }}
                                >
                                    Schedule A Call
                                </Button>
                            )}
                            <div className="dashboardGoBack" onClick={this.props.handleBack}>
                                Go to Dashboard
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.formOpen && (
                    <Modal
                        isShowModal={this.state.formOpen}
                        onCloseModal={() => {
                            this.setState({ formOpen: false });
                        }}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="line12"></div>
                            <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                              !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                            ? 'Please enter valid email'
                                            : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                            ? 'Please enter valid phone number'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={`0 0 3px 0 ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    width="130px"
                                    height="50px"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    onClick={() => this.setState({ formOpen: false })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeFormHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="scheduleCallMoving"
                    >
                        <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}
                        {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                        (this.state.rescheduleClicked && this.state.reschedule_link) ||
                        (this.state.cancelClicked && this.state.cancel_link) ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.scheduleClicked
                                            ? this.state.nylas_full_service_scheduler_url
                                            : this.state.rescheduleClicked
                                            ? this.state.reschedule_link
                                            : this.state.cancelClicked
                                            ? this.state.cancel_link
                                            : null
                                    }&utm_source=platform&utm_medium=web&utm_content=footer&utm_campaign=moving-platform
`}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSavings);
