import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OldZip from '../../Assets/images/Moving/OldZip';
import HomeSize from '../../Assets/images/Moving/HomeSize';
import HomeType from '../../Assets/images/Moving/HomeType';
import Stories from '../../Assets/images/Moving/Stories';
import Garage from '../../Assets/images/Moving/Garage';
import StoriesUp from '../../Assets/images/Moving/straisup';
import MovingTruck from '../../Assets/images/Moving/MovingTruck';

import Cargo from '../../Assets/images/MovingImages/pods';
import FullMove from '../../Assets/images/MovingImages/serviceMore';
import Footer from './Footer';
import SelectInput from '../../components/atoms/SelectInput';
import MovingStepperCard from '../../components/atoms/MovingStepperCard';
import './Moving.scss';
import { InputLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MoveAction from '../../store/common/actions';
import * as MoverAction from '../../store/move/actions';
import * as Action from '../../store/dashboard/actions';
import Linear from '../../components/atoms/linearProgress';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../utils/utlis';

type initialProps = {
    skip: any;
    moveAction?: any;
    moveState?: any;
    moverAction?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    moverState?: any;
    history?: any;
    commonState: any;
};

type initialState = {
    activeStep: number;
    skippedStep: number;
    step1: {
        oldZip: string;
        oldState: string;
        oldCity: string;
        size: string;
        typeOfHome: string;
        noOfStories: any;
        garage: any;
        basement: any;
    };
    step2: {
        newZip: string;
        newCity: string;
        newState: string;
        newAddress: string;
        new_size: string;
        new_typeOfHome: string;
        new_noOfStories: any;
        new_garage: any;
        new_basement: any;
    };
    step3: {
        checkbox1: boolean;
        checkbox2: boolean;
        checkbox3: boolean;
        recommended1: boolean;
        recommended2: boolean;
        recommended3: boolean;
    };
    homeOptions: any;
    storiesOptions: any;
    garageOptions: any;
    basementOptions: any;
    isDataArrived: boolean;
    isDataArrived2: boolean;
    moveDataArrived: boolean;
    moveId: any;
    serviceId: any;
    slug: any;
    oldZipError: string;
    sizeError: string;
    typeOfHomeError: string;
    noOfStoriesError: string;
    garageError: string;
    basementError: string;
    newZipError: string;
    new_sizeError: string;
    new_typeOfHomeError: string;
    new_noOfStoriesError: string;
    new_garageError: string;
    new_basementError: string;
    isDouglasElliman: boolean;
    brokerage_name: string;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

export class MovingSteps extends Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 0,
        skippedStep: 0,
        step1: {
            oldZip: '',
            oldCity: '',
            oldState: '',
            size: '',
            typeOfHome: '',
            noOfStories: '',
            garage: '',
            basement: '',
        },
        step2: {
            newZip: '',
            newCity: '',
            newState: '',
            newAddress: '',
            new_size: '',
            new_typeOfHome: '',
            new_noOfStories: '',
            new_garage: '',
            new_basement: '',
        },
        step3: {
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            recommended1: false,
            recommended2: false,
            recommended3: false,
        },
        oldZipError: '',
        sizeError: '',
        typeOfHomeError: '',
        noOfStoriesError: '',
        garageError: '',
        basementError: '',
        newZipError: '',
        new_sizeError: '',
        new_typeOfHomeError: '',
        new_noOfStoriesError: '',
        new_garageError: '',
        new_basementError: '',
        homeOptions: [
            { value: 'Small Studio', label: 'Small Studio' },
            { value: 'Large Studio', label: 'Large Studio' },
            { value: '1 Bedroom', label: '1 Bedroom' },
            { value: '2 Bedrooms - small', label: '2 Bedrooms - small' },
            { value: '2 Bedrooms - large', label: '2 Bedrooms - large' },
            { value: '3 Bedroom apt or house', label: '3 Bedroom apt or house' },
            { value: '3 Bedroom house', label: '3 Bedroom house' },
            { value: '4 Bedrooms - small', label: '4 Bedrooms - small' },
            { value: '4 Bedrooms - large', label: '4 Bedrooms - large' },
            { value: '5 Bedrooms plus', label: '5 Bedrooms plus' },
        ],
        storiesOptions: [
            { value: '1', label: 'One' },
            { value: '2', label: 'Two' },
            { value: '3', label: 'Three' },
            { value: '4', label: 'Four' },
        ],
        garageOptions: [
            { value: '0', label: 'No Garage' },
            { value: '1', label: '1 Car Garage' },
            { value: '2', label: '2 Car Garage' },
            { value: '3', label: '3 Car Garage' },
            { value: '4', label: '4+ Car Garage' },
        ],
        basementOptions: [
            { value: 'N/A', label: 'No Basement/Attic' },
            { value: 'Basement', label: 'Basement' },
            {
                value: 'Attic Space',
                label: 'Attic Space',
            },
            {
                value: 'Basement & Attic',
                label: 'Basement & Attic',
            },
        ],
        isDataArrived: true,
        isDataArrived2: true,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        prefilled: false,
        serviceId: '',
        slug: { primary_color: '' },
        isDouglasElliman: false,
        moveDataArrived: true,
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props.moveState &&
                this.props.moveState.move &&
                this.props.moveState.move[0] &&
                this.props.moveState.move[0].user_ref
            )
        ) {
            this.props.moveAction.moveGet();
        }
        this.props.dashboardAction.checklist();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            slug: slug,
            brokerage_name: slug.display_name,
            brokerage_id: slug.brokerage_link,
            agent_id: slug.id,
            agent_name: slug.contact_name,
        });
        if (
            this.state.step1.oldZip &&
            this.state.step1.oldCity &&
            this.state.step1.oldState &&
            this.state.step1.size &&
            this.state.step1.noOfStories &&
            this.state.step1.typeOfHome &&
            this.state.step1.garage &&
            this.state.step1.basement &&
            this.state.step2.newZip &&
            this.state.step2.newCity &&
            this.state.step2.newState &&
            this.state.step2.newAddress &&
            this.state.step2.new_size &&
            this.state.step2.new_noOfStories &&
            this.state.step2.new_typeOfHome &&
            this.state.step2.new_garage &&
            this.state.step2.new_basement
        ) {
            this.setState({
                prefilled: true,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.move &&
            nextProps.moveState.move[0] &&
            currentState.isDataArrived
        ) {
            nextProps.moveState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.brokerage_key =
                    nextProps.moveState.move[0] &&
                    nextProps.moveState.move[0].realtor &&
                    nextProps.moveState.move[0].realtor.brokerage_key;
                currentState.agent_key =
                    nextProps.moveState.move[0] &&
                    nextProps.moveState.move[0].realtor &&
                    nextProps.moveState.move[0].realtor.realtor_key;

                currentState.moveId = item.id ? item.id : '';
                currentState.step1.oldZip = item.source && item.source.zip_code ? item.source.zip_code : '';
                currentState.step1.oldCity = item.source && item.source.city_name ? item.source.city_name : '';
                currentState.step1.oldState = item.source && item.source.state_code ? item.source.state_code : '';
                currentState.step1.size = item && item.move_sq_foot ? item.move_sq_foot : '';
                currentState.step1.noOfStories = {
                    value: item && item.move_no_of_stories ? item.move_no_of_stories.toString() : '',
                    label: item && item.move_no_of_stories ? item.move_no_of_stories.toString() : '',
                };
                currentState.step1.typeOfHome =
                    item && item.move_size && item.move_size != 'Default' ? item.move_size : '';
                currentState.step1.garage = {
                    value:
                        item && (item.move_no_of_garage || item.move_no_of_garage === 0)
                            ? item.move_no_of_garage.toString()
                            : '',
                    label:
                        item && (item.move_no_of_garage || item.move_no_of_garage === 0)
                            ? item.move_no_of_garage === 0
                                ? 'No Garage'
                                : item.move_no_of_garage === 1
                                ? '1 Car Garage'
                                : item.move_no_of_garage === 2
                                ? '2 Car Garage'
                                : item.move_no_of_garage === 3
                                ? '3 Car Garage'
                                : item.move_no_of_garage === 4
                                ? '4+ Car Garage'
                                : item.move_no_of_garage.toString()
                            : '',
                };

                currentState.step1.basement = {
                    value: item && item.move_basement ? item.move_basement.toString() : '',
                    label:
                        item && item.move_basement
                            ? item.move_basement.toString() === 'N/A'
                                ? 'No Basement/Attic'
                                : item.move_basement.toString()
                            : '',
                };

                currentState.step2.newZip =
                    item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.step2.newCity =
                    item.destination && item.destination.city_name ? item.destination.city_name : '';
                currentState.step2.newState =
                    item.destination && item.destination.state_code ? item.destination.state_code : '';
                currentState.step2.newAddress =
                    item.destination && item.destination_full_address ? item.destination_full_address : '';
                currentState.step2.new_size = item && item.destination_sq_foot ? item.destination_sq_foot : '';
                currentState.step2.new_noOfStories = {
                    value: item && item.destination_no_of_stories ? item.destination_no_of_stories.toString() : '',
                    label: item && item.destination_no_of_stories ? item.destination_no_of_stories.toString() : '',
                };
                currentState.step2.new_typeOfHome =
                    item && item.destination_move_size && item.destination_move_size != 'Default'
                        ? item.destination_move_size
                        : '';
                currentState.step2.new_garage = {
                    value:
                        item && (item.destination_no_of_garage || item.destination_no_of_garage === 0)
                            ? item.destination_no_of_garage.toString()
                            : '',
                    label:
                        item && (item.destination_no_of_garage || item.destination_no_of_garage === 0)
                            ? item.destination_no_of_garage === 0
                                ? 'No Garage'
                                : item.destination_no_of_garage === 1
                                ? '1 Car Garage'
                                : item.destination_no_of_garage === 2
                                ? '2 Car Garage'
                                : item.destination_no_of_garage === 3
                                ? '3 Car Garage'
                                : item.destination_no_of_garage === 4
                                ? '4+ Car Garage'
                                : item.destination_no_of_garage.toString()
                            : '',
                };

                currentState.step2.new_basement = {
                    value: item && item.destination_basement ? item.destination_basement.toString() : '',
                    label:
                        item && item.destination_basement
                            ? item.destination_basement.toString() === 'N/A'
                                ? 'No Basement/Attic'
                                : item.destination_basement.toString()
                            : '',
                };
            });
        }

        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.serviceId = item.id;
                }
            });
        }
        return currentState;
    }

    getSteps = () => {
        return ['', '', ''];
    };

    onChangeHandlerStep1 = async (e: any, name: string) => {
        //    const step1 = { ...this.state.step1, [name]: e.target.value };
        let step1: any;
        if (name === 'size') {
            let size: any = this.formatSizeInput(e.target.value);
            step1 = { ...this.state.step1, [name]: size };
        } else {
            step1 = { ...this.state.step1, [name]: e.target.value };
        }
        await this.setState(() => ({ step1 }));
    };
    onSelectHandlerStep1 = async (e: any, name: string) => {
        const steps = { ...this.state.step1, [name]: e };
        await this.setState(() => ({ step1: steps }));
    };

    onHomeSelectHandlerStep1 = async (e: any, name: string) => {
        const steps = { ...this.state.step1, [name]: e.value };
        await this.setState(() => ({ step1: steps }));
    };

    onChangeHandlerStep2 = async (e: any, name: string) => {
        const step2 = { ...this.state.step2, [name]: e.target.value };
        await this.setState(() => ({ step2 }));
    };

    onSelectHandlerStep2 = async (e: any, name: string) => {
        const steps = { ...this.state.step2, [name]: e };
        await this.setState(() => ({ step2: steps }));
    };

    formatSizeInput = (size: string) => {
        let sqsize = size;
        sqsize = sqsize.replace(/\D/g, '');
        sqsize = sqsize.substring(0, 10);
        return sqsize;
    };

    onHomeSelectHandlerStep2 = async (e: any, name: string) => {
        const steps = { ...this.state.step2, [name]: e.value };
        await this.setState(() => ({ step2: steps }));
    };

    onCheckHandlerStep3 = async (name: string, value: boolean) => {
        const steps = { ...this.state.step3, [name]: !value };
        await this.setState(() => ({ step3: steps }));
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const step3 = { ...this.state.step3, checkedG: event.target.checked };
        this.setState(() => ({ step3 }));
    };

    getStepContent = (step: number) => {
        const { isDouglasElliman } = this.state;
        switch (step) {
            case 0:
                return (
                    <div>
                        <div className="movingAddressCard">
                            <div
                                className={
                                    this.state.step1.typeOfHome.split(' ')[1] !== 'Studio' &&
                                    this.state.step1.typeOfHome !== ''
                                        ? 'movingBoxCard opened'
                                        : 'movingBoxCard'
                                }
                            >
                                <div className="heading" style={{ fontWeight: 700 }}>
                                    Tell us a little about the home you are moving from
                                </div>
                                <p style={{ textAlign: 'center' }}>
                                    This will help us recommend the right moving options for you
                                </p>
                                <div className="addressFlexDivNew">
                                    <div className={isDouglasElliman ? 'movingBox douglasInput' : 'movingBox'}>
                                        <div className="inputs">
                                            <div className="innerInputs">
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <OldZip
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel className="inputLable">Moving From</InputLabel>
                                                        </div>
                                                        <TextField
                                                            className="input1"
                                                            error={Boolean(this.state.oldZipError)}
                                                            label="Old Zip Code"
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) =>
                                                                this.onChangeHandlerStep1(e, 'oldZip')
                                                            }
                                                            variant="filled"
                                                            value={this.state.step1.oldZip}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">{`${this.state.step1.oldCity}, ${this.state.step1.oldState}`}</InputAdornment>
                                                                ),
                                                            }}
                                                            disabled={this.state.step1.oldZip !== ''}
                                                        />
                                                        <div
                                                            className="errorMoving"
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                        >
                                                            {this.state.step1.oldZip == '' ? (
                                                                <small>{this.state.oldZipError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <HomeSize
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel className="inputLable">
                                                                Size of your home
                                                            </InputLabel>
                                                        </div>
                                                        <TextField
                                                            className="input1"
                                                            error={Boolean(this.state.sizeError)}
                                                            label="Square FT"
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) => this.onChangeHandlerStep1(e, 'size')}
                                                            variant="filled"
                                                            value={this.state.step1.size}
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step1.size == '' ||
                                                            parseInt(this.state.step1.size) <= 0 ? (
                                                                <small>{this.state.sizeError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="innerInputs">
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <HomeType
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel>Type Of Home</InputLabel>
                                                        </div>
                                                        <SelectInput
                                                            error={this.state.typeOfHomeError != '' ? true : false}
                                                            // id="demo-simple-select-error"
                                                            className="react-select alert-select movingDropdownSteps"
                                                            // error={Boolean(this.state.typeOfHomeError)}
                                                            onChange={(e: any) =>
                                                                this.onHomeSelectHandlerStep1(e, 'typeOfHome')
                                                            }
                                                            options={this.state.homeOptions}
                                                            placeholder={
                                                                this.state.step1.typeOfHome
                                                                    ? this.state.step1.typeOfHome
                                                                    : 'Select Type Of Home'
                                                            }
                                                            value={
                                                                this.state.step1.typeOfHome
                                                                    ? this.state.step1.typeOfHome
                                                                    : ''
                                                            }
                                                            // label="Type Of Home"
                                                        />
                                                        <div
                                                            //  style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step1.typeOfHome == '' ? (
                                                                <small>{this.state.typeOfHomeError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <StoriesUp
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel>Number Of Stories</InputLabel>
                                                        </div>
                                                        <SelectInput
                                                            error={this.state.noOfStoriesError != '' ? true : false}
                                                            className="react-select alert-select"
                                                            onChange={(e: any) =>
                                                                this.onSelectHandlerStep1(e, 'noOfStories')
                                                            }
                                                            options={this.state.storiesOptions}
                                                            placeholder={
                                                                this.state.step1.noOfStories &&
                                                                this.state.step1.noOfStories.label
                                                                    ? this.state.step1.noOfStories.label
                                                                    : 'Select Number Of Stories'
                                                            }
                                                            value={
                                                                this.state.step1.noOfStories
                                                                    ? this.state.step1.noOfStories.value
                                                                    : ''
                                                            }
                                                            // label="Number Of Stories"
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step1.noOfStories == '' ||
                                                            this.state.step1.noOfStories.value == '' ? (
                                                                <small>{this.state.noOfStoriesError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.step1.typeOfHome.includes('Bedroom') &&
                                            this.state.step1.typeOfHome !== '' ? (
                                                <div className="innerInputs">
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <Garage
                                                                className="icons"
                                                                isDouglasElliman={this.state.isDouglasElliman}
                                                            />
                                                        </div>
                                                        <div className="moveSelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel>Garage</InputLabel>
                                                            </div>
                                                            <SelectInput
                                                                error={this.state.garageError != '' ? true : false}
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep1(e, 'garage')
                                                                }
                                                                options={this.state.garageOptions}
                                                                placeholder={
                                                                    this.state.step1.garage &&
                                                                    this.state.step1.garage.label
                                                                        ? this.state.step1.garage.label
                                                                        : 'Select Garage'
                                                                }
                                                                value={
                                                                    this.state.step1.garage
                                                                        ? this.state.step1.garage.value
                                                                        : ''
                                                                }
                                                                // label="Garage"
                                                            />
                                                            <div
                                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                                className="errorMoving"
                                                            >
                                                                {this.state.step1.garage == '' ||
                                                                this.state.step1.garage.value == '' ? (
                                                                    <small>{this.state.garageError}</small>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <Stories
                                                                className="icons"
                                                                isDouglasElliman={this.state.isDouglasElliman}
                                                            />
                                                        </div>
                                                        <div className="moveSelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel>Basement</InputLabel>
                                                            </div>
                                                            <SelectInput
                                                                error={this.state.basementError != '' ? true : false}
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep1(e, 'basement')
                                                                }
                                                                options={this.state.basementOptions}
                                                                placeholder={
                                                                    this.state.step1.basement &&
                                                                    this.state.step1.basement.label
                                                                        ? this.state.step1.basement.label
                                                                        : 'Select Basement'
                                                                }
                                                                value={
                                                                    this.state.step1.basement
                                                                        ? this.state.step1.basement.value
                                                                        : ''
                                                                }
                                                                // label="Basement"
                                                            />
                                                            <div
                                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                                className="errorMoving"
                                                            >
                                                                {this.state.step1.basement == '' ||
                                                                this.state.step1.basement.value == '' ? (
                                                                    <small>{this.state.basementError}</small>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="movingAddressCard">
                            <div
                                className={
                                    this.state.step2.new_typeOfHome.split(' ')[1] !== 'Studio' &&
                                    this.state.step2.new_typeOfHome !== ''
                                        ? 'movingBoxCardNew openedCard'
                                        : 'movingBoxCardNew'
                                }
                            >
                                <div className="heading" style={{ fontWeight: 700 }}>
                                    Now, tell us a bit about your new home
                                </div>
                                <p className="paragraph">This helps us understand how to best plan your move</p>
                                <div className="addressFlexDivNew">
                                    <div className={isDouglasElliman ? 'movingBox douglasInput' : 'movingBox'}>
                                        <div className="inputs">
                                            <div className="innerInputs">
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <OldZip
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel className="inputLable">Moving To</InputLabel>
                                                        </div>
                                                        <TextField
                                                            className="input1"
                                                            error={Boolean(this.state.newZipError)}
                                                            label="New Zip Code"
                                                            id="reddit-input outlined-start-adornment"
                                                            onChange={(e: any) =>
                                                                this.onChangeHandlerStep2(e, 'newZip')
                                                            }
                                                            variant="filled"
                                                            value={this.state.step2.newZip}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">{`${this.state.step2.newCity}, ${this.state.step2.newState}`}</InputAdornment>
                                                                ),
                                                            }}
                                                            disabled={this.state.step2.newZip !== ''}
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step2.newZip == '' ? (
                                                                <small>{this.state.newZipError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <HomeSize
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel className="inputLable">
                                                                Size of your home
                                                            </InputLabel>
                                                        </div>
                                                        <TextField
                                                            className="input1"
                                                            error={Boolean(this.state.new_sizeError)}
                                                            label="Square FT"
                                                            id="reddit-input"
                                                            // id="outlined-start-adornment"
                                                            onChange={(e: any) =>
                                                                this.onChangeHandlerStep2(e, 'new_size')
                                                            }
                                                            variant="filled"
                                                            value={this.state.step2.new_size}
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step2.new_size == '' ||
                                                            parseInt(this.state.step2.new_size) <= 0 ? (
                                                                <small>{this.state.new_sizeError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="innerInputs">
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <HomeType
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel>Type Of Home</InputLabel>
                                                        </div>
                                                        <SelectInput
                                                            error={this.state.new_typeOfHomeError != '' ? true : false}
                                                            className="react-select alert-select"
                                                            onChange={(e: any) =>
                                                                this.onHomeSelectHandlerStep2(e, 'new_typeOfHome')
                                                            }
                                                            options={this.state.homeOptions}
                                                            placeholder={
                                                                this.state.step2.new_typeOfHome
                                                                    ? this.state.step2.new_typeOfHome
                                                                    : 'Select Type Of Home'
                                                            }
                                                            value={
                                                                this.state.step2.new_typeOfHome
                                                                    ? this.state.step2.new_typeOfHome
                                                                    : ''
                                                            }
                                                            // label="Type Of Home"
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step2.new_typeOfHome == '' ? (
                                                                <small>{this.state.new_typeOfHomeError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDivIconInput">
                                                    <div className="iconDiv">
                                                        <StoriesUp
                                                            className="icons"
                                                            isDouglasElliman={this.state.isDouglasElliman}
                                                        />
                                                    </div>
                                                    <div className="moveSelectFilter">
                                                        <div className="inputLabel">
                                                            <InputLabel>Number Of Stories</InputLabel>
                                                        </div>
                                                        <SelectInput
                                                            error={this.state.new_noOfStoriesError != '' ? true : false}
                                                            className="react-select alert-select"
                                                            onChange={(e: any) =>
                                                                this.onSelectHandlerStep2(e, 'new_noOfStories')
                                                            }
                                                            options={this.state.storiesOptions}
                                                            placeholder={
                                                                this.state.step2.new_noOfStories &&
                                                                this.state.step2.new_noOfStories.label
                                                                    ? this.state.step2.new_noOfStories.label
                                                                    : 'Select Number Of Stories'
                                                            }
                                                            value={
                                                                this.state.step2.new_noOfStories
                                                                    ? this.state.step2.new_noOfStories.value
                                                                    : ''
                                                            }
                                                            // label="Number Of Stories"
                                                        />
                                                        <div
                                                            // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                            className="errorMoving"
                                                        >
                                                            {this.state.step2.new_noOfStories == '' ||
                                                            this.state.step2.new_noOfStories.value == '' ? (
                                                                <small>{this.state.new_noOfStoriesError}</small>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.step2.new_typeOfHome.includes('Bedroom') &&
                                            this.state.step2.new_typeOfHome !== '' ? (
                                                <div className="innerInputs">
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <Garage
                                                                className="icons"
                                                                isDouglasElliman={this.state.isDouglasElliman}
                                                            />
                                                        </div>
                                                        <div className="moveSelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel>Garage</InputLabel>
                                                            </div>
                                                            <SelectInput
                                                                error={this.state.new_garageError != '' ? true : false}
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep2(e, 'new_garage')
                                                                }
                                                                options={this.state.garageOptions}
                                                                placeholder={
                                                                    this.state.step2.new_garage &&
                                                                    this.state.step2.new_garage.label
                                                                        ? this.state.step2.new_garage.label
                                                                        : 'Select Garage'
                                                                }
                                                                value={
                                                                    this.state.step2.new_garage
                                                                        ? this.state.step2.new_garage.value
                                                                        : ''
                                                                }
                                                                // label="Garage"
                                                            />

                                                            <div
                                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                                className="errorMoving"
                                                            >
                                                                {this.state.step2.new_garage == '' ||
                                                                this.state.step2.new_garage.value == '' ? (
                                                                    <small>{this.state.new_garageError}</small>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <Stories
                                                                className="icons"
                                                                isDouglasElliman={this.state.isDouglasElliman}
                                                            />
                                                        </div>
                                                        <div className="moveSelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel>Basement</InputLabel>
                                                            </div>
                                                            <SelectInput
                                                                error={
                                                                    this.state.new_basementError != '' ? true : false
                                                                }
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep2(e, 'new_basement')
                                                                }
                                                                options={this.state.basementOptions}
                                                                placeholder={
                                                                    this.state.step2.new_basement &&
                                                                    this.state.step2.new_basement.label
                                                                        ? this.state.step2.new_basement.label
                                                                        : 'Select Basement'
                                                                }
                                                                value={
                                                                    this.state.step2.new_basement
                                                                        ? this.state.step2.new_basement.value
                                                                        : ''
                                                                }
                                                                // label="Basement"
                                                            />

                                                            <div
                                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                                className="errorMoving"
                                                            >
                                                                {this.state.step2.new_basement == '' ||
                                                                this.state.step2.new_basement.value == '' ? (
                                                                    <small>{this.state.new_basementError}</small>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="movingAddressCard">
                            <div className="movingBoxCard">
                                <div className="headingCards">
                                    Based on your move details, we recommend the following options
                                </div>
                                <div className="cards-para"> Select any or all of the following options</div>
                                <div className="addressFlexDivNew">
                                    <div className="movingBox">
                                        <div className="inputs">
                                            <div className="innerInputsCards">
                                                <div className="addressFlexDivNew">
                                                    <MovingStepperCard
                                                        isDouglasElliman={isDouglasElliman}
                                                        recommended={this.state.step3.recommended1}
                                                        checked={this.state.step3.checkbox1}
                                                        handleChange={() =>
                                                            this.onCheckHandlerStep3(
                                                                'checkbox1',
                                                                this.state.step3.checkbox1,
                                                            )
                                                        }
                                                        iconHeight="60px"
                                                        icon={<MovingTruck checked={this.state.step3.checkbox1} />}
                                                        label={'DIY'}
                                                        heading="Do it yourself moving"
                                                        points={[
                                                            'Recommended for small or mid sized, local moves',
                                                            'Requires a good amount of effort',
                                                            'You pack, load and move',
                                                            'Budget pricing',
                                                        ]}
                                                    />
                                                </div>
                                                <div className="addressFlexDivNew">
                                                    <MovingStepperCard
                                                        isDouglasElliman={isDouglasElliman}
                                                        recommended={this.state.step3.recommended2}
                                                        checked={this.state.step3.checkbox2}
                                                        handleChange={() =>
                                                            this.onCheckHandlerStep3(
                                                                'checkbox2',
                                                                this.state.step3.checkbox2,
                                                            )
                                                        }
                                                        icon={<Cargo checked={this.state.step3.checkbox2} />}
                                                        label={'PODS'}
                                                        heading="PODS Portable storage containers"
                                                        points={[
                                                            'Recommended for mid sized, long distance or local moves',
                                                            'Best hybrid option between DIY & full service',
                                                            'You pack and load but they move',
                                                            'Mid range pricing',
                                                        ]}
                                                    />
                                                </div>
                                                <div className="addressFlexDivNew">
                                                    <MovingStepperCard
                                                        isDouglasElliman={isDouglasElliman}
                                                        recommended={this.state.step3.recommended3}
                                                        checked={this.state.step3.checkbox3}
                                                        handleChange={() =>
                                                            this.onCheckHandlerStep3(
                                                                'checkbox3',
                                                                this.state.step3.checkbox3,
                                                            )
                                                        }
                                                        icon={<FullMove checked={this.state.step3.checkbox3} />}
                                                        iconWidth="100px"
                                                        iconHeight="52px"
                                                        label={'Full Service Move'}
                                                        heading=" Let movers handle everything"
                                                        points={[
                                                            'Recommended for large local moves or any long distance moves',
                                                            'White Glove Service - requires minimal effort',
                                                            'Movers do everything',
                                                            'Premium pricing',
                                                        ]}
                                                    />
                                                </div>
                                                <div className="addressFlexDivNew"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return 'step not found';
        }
    };

    validateStep1 = () => {
        let isValid: boolean = true;
        if (this.state.step1.oldZip == '') {
            isValid = false;
            this.setState({ oldZipError: 'Please Provide zip code' });
        }
        if (this.state.step1.size == '') {
            isValid = false;
            this.setState({ sizeError: 'Please Provide Size Of Home' });
        }
        if (parseInt(this.state.step1.size) <= 0) {
            isValid = false;
            this.setState({ sizeError: 'Size Should Be Greater Than 0' });
        }
        if (this.state.step1.typeOfHome == '') {
            isValid = false;
            this.setState({ typeOfHomeError: 'Please Provide Type Of Home' });
        }

        if (this.state.step1.noOfStories.value == '') {
            isValid = false;
            this.setState({ noOfStoriesError: 'Please Provide Number Of Stories' });
        }

        if (this.state.step1.typeOfHome.split(' ')[1] !== 'Studio' && this.state.step1.typeOfHome !== '') {
            if (!this.state.step1.garage.value) {
                isValid = false;
                this.setState({ garageError: 'Please Provide Garage' });
            }
            if (!this.state.step1.basement.value) {
                isValid = false;
                this.setState({ basementError: 'Please Provide Basement' });
            }
        }

        return isValid;
    };

    validateStep2 = () => {
        let isValidStep2: boolean = true;
        if (this.state.step2.newZip === '') {
            isValidStep2 = false;
            this.setState({ newZipError: 'Please Provide zip code' });
        }
        if (this.state.step2.new_size === '') {
            isValidStep2 = false;
            this.setState({ new_sizeError: 'Please Provide size of home' });
        }
        if (this.state.step2.new_typeOfHome === '') {
            isValidStep2 = false;
            this.setState({ new_typeOfHomeError: 'Please Provide Type Of Home' });
        }

        if (this.state.step2.new_noOfStories.value === '') {
            isValidStep2 = false;
            this.setState({ new_noOfStoriesError: 'Please Provide Number Of Stories' });
        }

        if (this.state.step2.new_typeOfHome.split(' ')[1] !== 'Studio' && this.state.step2.new_typeOfHome !== '') {
            if (!this.state.step2.new_garage.value) {
                isValidStep2 = false;
                this.setState({ new_garageError: 'Please Provide Garage' });
            }
            if (!this.state.step2.new_basement.value) {
                isValidStep2 = false;
                this.setState({ new_basementError: 'Please Provide Basement' });
            }
        }

        if (parseInt(this.state.step2.new_size) <= 0) {
            isValidStep2 = false;
            this.setState({ new_sizeError: 'Size Should Be Greater Than 0' });
        }

        return isValidStep2;
    };

    handleStep1 = async () => {
        if (this.validateStep1()) {
            const payload = {
                move_id: this.state.moveId,
                data: {
                    move_size: this.state.step1.typeOfHome,
                    move_sq_foot: parseInt(this.state.step1.size),
                    move_no_of_stories: parseInt(this.state.step1.noOfStories.value),
                    move_no_of_garage: parseInt(this.state.step1.garage.value),
                    move_basement: this.state.step1.basement.value,
                },
            };
            await this.props.moverAction.updateMoveSourceHomeInfo(payload);
            this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 20,
                },
                service_id: this.state.serviceId,
            });
            this.setState({
                activeStep: 1,
            });
            window.scrollTo(0, 0);
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Moving Services',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                'Moving Services',
                'Confirm Current Address',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                this.state.activeStep,
                '',
                move_id,
            );

            formStartedSegment(
                'Form Started',
                'Moving Services',
                'Moving Services',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                '',
                'Confirm Current Address',
                true,
                this.state.prefilled,
                1,
                move_id,
            );
        }
    };
    handleStep2 = async () => {
        if (this.validateStep2()) {
            const payload = {
                move_id: this.state.moveId,
                data: {
                    destination_move_size: this.state.step2.new_typeOfHome,
                    destination_sq_foot: parseInt(this.state.step2.new_size),
                    destination_no_of_stories: parseInt(this.state.step2.new_noOfStories.value),
                    destination_no_of_garage: parseInt(this.state.step2.new_garage.value),
                    destination_basement: this.state.step2.new_basement.value,
                },
            };
            await this.props.moverAction.updateMoveDestinationHomeInfo(payload);
            this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 40,
                },
                service_id: this.state.serviceId,
            });
            this.setState({
                activeStep: 2,
            });
            if (
                this.state.step2.new_typeOfHome.split(' ')[1] === 'Studio' ||
                this.state.step2.new_typeOfHome.split(' ')[0] === '1'
            ) {
                const step3 = {
                    ...this.state.step3,
                    checkbox1: true,
                    checkbox2: true,
                    checkbox3: false,
                    recommended1: true,
                    recommended2: true,
                    recommended3: false,
                };
                this.setState(() => ({ step3 }));
            }
            if (
                this.state.step2.new_typeOfHome.split(' ')[0] === '2' ||
                this.state.step2.new_typeOfHome.split(' ')[0] === '3'
            ) {
                const step3 = {
                    ...this.state.step3,
                    checkbox1: false,
                    checkbox2: true,
                    checkbox3: true,
                    recommended1: false,
                    recommended2: true,
                    recommended3: true,
                };
                this.setState(() => ({ step3 }));
            }
            if (parseInt(this.state.step2.new_typeOfHome.split(' ')[0]) > 3) {
                const step3 = {
                    ...this.state.step3,
                    checkbox1: false,
                    checkbox2: false,
                    checkbox3: true,
                    recommended1: false,
                    recommended2: false,
                    recommended3: true,
                };
                this.setState(() => ({ step3 }));
            }
            window.scrollTo(0, 0);
        }
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Moving Services',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Moving Services',
            'Confirm Current Address',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            2,
            '',
            move_id,
        );
    };

    handleNext = async () => {
        if (this.state.activeStep == 2) {
            if (
                (this.state.step3.checkbox1 && !this.state.step3.checkbox2 && !this.state.step3.checkbox3) ||
                (this.state.step3.checkbox1 && this.state.step3.checkbox2 && !this.state.step3.checkbox3)
            ) {
                this.props.history.push({
                    pathname: '/dashboard/movers/diyrecommended',
                    state: { pods: this.state.step3.checkbox2 },
                });
                let move_id: any = localStorage.getItem('moveKey');
                buttonClicked(
                    'Button Clicked',
                    'Moving Services',
                    this.state.brokerage_name,
                    this.state.brokerage_key,
                    this.state.agent_name,
                    this.state.agent_key,
                    'Moving Services',
                    'Review Your Quotes',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    true,
                    this.state.activeStep,
                    '',
                    move_id,
                );
            } else {
                this.props.history.push({
                    pathname: '/dashboard/movers/recommended',
                    state: {
                        diy: this.state.step3.checkbox1,
                        pods: this.state.step3.checkbox2,
                    },
                });
                this.props.dashboardAction.updateServicePercentage({
                    data: {
                        completed_percentage: 60,
                    },
                    service_id: this.state.serviceId,
                });
            }
        }
        let move_id: any = localStorage.getItem('moveKey');
        formCompletedSegment(
            'Form Completed',
            'Moving Services Page',
            'Moving Services',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Review Your Quotes',
            true,
            this.state.prefilled,
            'Moving Services Form',
            3,
            move_id,
        );
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Moving Services',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Moving Services',
            'Previous',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            this.state.activeStep,
            '',
            move_id,
        );
    };

    handleSkip = () => {
        this.props.skip(this.state.activeStep, this.getSteps().length);
    };
    render() {
        const steps = this.getSteps();
        const { isDouglasElliman } = this.state;
        return (
            <div>
                <div className="moving-main-page">
                    <div className="main-head-2">
                        <h2 className="heading-moving">Moving Services</h2>
                        <div className="path">
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (
                                        localStorage.getItem('isHomeOwner') &&
                                        localStorage.getItem('isHomeOwner') == 'true'
                                    ) {
                                        this.props.history.push('/dashboardhomeOwner');
                                    } else {
                                        this.props.history.push('/dashboard');
                                    }
                                }}
                            >
                                Dashboard {'>'}
                            </p>{' '}
                            <p style={{ marginLeft: '5px' }} className="para-para">
                                Moving Services
                            </p>
                        </div>
                    </div>
                    <div className="coverDivMove-2">
                        <div className={isDouglasElliman ? 'landingPageMove movingStepsDouglas' : 'landingPageMove'}>
                            <Typography>{this.getStepContent(this.state.activeStep)}</Typography>
                            <div className="Para11">
                                STEP {this.state.activeStep + 1} OF {steps.length}
                            </div>
                            <div
                                style={{ marginBottom: '15px' }}
                                className={isDouglasElliman ? 'progressStepD' : 'progressStep'}
                            >
                                <Linear value={((this.state.activeStep + 1) / 3) * 100} />
                            </div>
                            <Stepper activeStep={this.state.activeStep}>
                                {steps.map((label: any) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: { optional?: React.ReactNode } = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}></StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className="buttons-moving">
                                <div></div>
                                {/* {this.state.activeStep === steps.length - 1 ? (
                                    <div></div>
                                ) : (
                                    <div className="skipStyle" onClick={this.handleSkip}>
                                        Skip and show me all offers in my area
                                    </div>
                                )} */}

                                <div>
                                    {this.state.activeStep === 0 ? null : (
                                        <Button
                                            className="btnMoving"
                                            disabled={this.state.activeStep === 0}
                                            onClick={this.handleBack}
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          this.state.slug && this.state.slug.primary_color
                                                              ? this.state.slug.primary_color
                                                              : '#273E59'
                                                      }`
                                            }
                                            border={
                                                isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : `1px solid ${
                                                          this.state.slug && this.state.slug.primary_color
                                                              ? this.state.slug.primary_color
                                                              : window.location.pathname.includes('landing')
                                                              ? '#30AB86'
                                                              : '#273E59'
                                                      }`
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 15px 0 0"
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    {this.state.activeStep == 0 ? (
                                        <Button
                                            className="btnMoving3"
                                            onClick={this.handleStep1}
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            // disabled={!this.state.step3.checkbox2 && !this.state.step3.checkbox3}
                                        >
                                            Confirm Current Address
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btnMoving2"
                                            onClick={
                                                this.state.activeStep === 1
                                                    ? this.handleStep2
                                                    : this.state.activeStep === 2
                                                    ? this.handleNext
                                                    : ''
                                            }
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            disabled={
                                                this.state.activeStep === 2 &&
                                                !(
                                                    this.state.step3.checkbox2 ||
                                                    this.state.step3.checkbox3 ||
                                                    this.state.step3.checkbox1
                                                )
                                            }
                                        >
                                            {this.state.activeStep === 1
                                                ? 'Confirm New Address'
                                                : this.state.activeStep === 2
                                                ? 'Review Your Quotes'
                                                : ''}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </div>
                <div className="moving-Footer">
                    <Footer />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moveAction: bindActionCreators(MoveAction, dispatch),
        moverAction: bindActionCreators(MoverAction, dispatch),
        dashboardAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moveState: state.common,
    moverState: state.move,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(MovingSteps);
