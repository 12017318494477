import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={55} height={55} {...props}>
            <defs>
                <style>
                    {slug && slug.primary_color
                        ? props.isDouglasElliman
                            ? `.agentemailprefix__c{fill:#100b28}`
                            : `.agentemailprefix__c{fill:${slug.primary_color}}`
                        : '.agentemailprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <g>
                <path
                    d="M13.021 6.5h29a12 12 0 0 1 12 12v12h-53v-12a12 12 0 0 1 12-12Z"
                    fill={
                        slug && slug.color_shade4 ? (props.isDouglasElliman ? '#ffffff' : slug.color_shade4) : '#5b7088'
                    }
                />
                <path
                    d="M54.5 19.36A12.636 12.636 0 0 0 41.878 6.738h-9.806V1.176a.675.675 0 0 0-.675-.675h-7.794a.675.675 0 0 0-.675.675v5.563h-9.806A12.636 12.636 0 0 0 .5 19.36v22.519A12.636 12.636 0 0 0 13.121 54.5h28.757A12.636 12.636 0 0 0 54.5 41.879Zm-30.9-5.422h7.794a.675.675 0 0 0 .675-.675v-1.238h.673a2.328 2.328 0 0 1 0 4.655H22.254a2.328 2.328 0 1 1 0-4.655h.673v1.238a.675.675 0 0 0 .675.675Zm.675-12.088h6.444v10.738h-6.444ZM53.15 41.88a11.285 11.285 0 0 1-11.272 11.272H13.121A11.285 11.285 0 0 1 1.849 41.88V19.361A11.285 11.285 0 0 1 13.121 8.089h9.806v2.587h-.673a3.678 3.678 0 1 0 0 7.355h10.491a3.678 3.678 0 1 0 0-7.355h-.673V8.089h9.806A11.285 11.285 0 0 1 53.15 19.361Z"
                    stroke="agentemailprefix__c"
                    fill="agentemailprefix__c"
                    className="agentemailprefix__c"
                />
                <path
                    className="agentemailprefix__c"
                    d="M9.246 13.865H6.731a.675.675 0 1 0 0 1.35h2.515a.675.675 0 0 0 0-1.35Z"
                />
                <path d="M16.378 14.541a.675.675 0 0 0-.675-.675h-2.515a.675.675 0 0 0 0 1.35h2.515a.676.676 0 0 0 .675-.675Z" />
                <path
                    className="agentemailprefix__c"
                    d="M41.81 13.865h-2.515a.675.675 0 0 0 0 1.35h2.515a.675.675 0 0 0 0-1.35Z"
                />
                <path
                    className="agentemailprefix__c"
                    d="M48.268 13.865h-2.515a.675.675 0 0 0 0 1.35h2.515a.675.675 0 0 0 0-1.35Z"
                />
                <g stroke="agentemailprefix__c">
                    <path
                        className="agentemailprefix__c"
                        d="M26.689 43.923a7.588 7.588 0 0 1-3.118-.648 7.263 7.263 0 0 1-2.429-1.767 8.967 8.967 0 0 1 0-11.592 7.263 7.263 0 0 1 2.432-1.768 7.823 7.823 0 0 1 6.236 0 7.263 7.263 0 0 1 2.429 1.767 8.575 8.575 0 0 1 2.125 5.8h-.559c0-4.362-3.058-7.653-7.113-7.653s-7.113 3.291-7.113 7.653 3.058 7.653 7.113 7.653a7.56 7.56 0 0 0 3.464-.827l.253.5a8.13 8.13 0 0 1-3.718.888Z"
                    />
                    <path
                        className="agentemailprefix__c"
                        d="M26.69 39.91a3.373 3.373 0 0 1-2.719-1.252 5.085 5.085 0 0 1 0-5.893 3.58 3.58 0 0 1 5.438 0 5.085 5.085 0 0 1 0 5.893 3.373 3.373 0 0 1-2.719 1.252Zm0-7.837c-2.142 0-3.1 1.827-3.1 3.639s.959 3.639 3.1 3.639 3.1-1.827 3.1-3.639-.959-3.636-3.1-3.636Z"
                    />
                    <path
                        className="agentemailprefix__c"
                        d="M32.075 39.908a2.288 2.288 0 0 1-2.285-2.285v-1.915h.559v1.913a1.727 1.727 0 1 0 3.453 0v-1.913h.559v1.913a2.287 2.287 0 0 1-2.285 2.285Z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
