import React, { Component } from 'react';
import './recommendationCardsAll.scss';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../../store/common/actions';
import * as SecurityAction from '../../../store/security/actions';
import Modal from '../Modal';
// import { InlineWidget } from 'react-calendly';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewDetailsPage from '../../../pages/HomeSecurity/viewDeatils';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import * as SignupAction from '../../../store/login/signup/actions';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { scheduleSegment, buttonClicked } from '../../../utils/utlis';
import { getCardsDatas } from './CardsDatas';

type initialProps = {
    adtRecommended?: boolean;
    vivintRecommended?: boolean;
    alderRecommended?: boolean;
    simpliRecommended?: boolean;
    commonAction?: any;
    commonState?: any;
    history?: any;
    securityType?: any;
    securityInstallation?: any;
    fromLanding?: any;
    data?: any;
    securityAction?: any;
    securityState: any;
    signupAction?: any;
    signupState?: any;
    landing?: any;
    isDouglasElliman?: boolean;
};

type initialState = {
    calendlyOpen: boolean;
    cardsContentNew: any;
    isViewDetails: boolean;
    viewDetailsData: any;
    selectedService: any;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isDataArrived: boolean;
    isSubmitClicked: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    appType: any;
};
export class RecommendationCardsAll extends Component<initialProps, initialState> {
    state: initialState = {
        appType: this.context?.appType,
        calendlyOpen: false,
        cardsContentNew: [],
        isViewDetails: false,
        viewDetailsData: {},
        selectedService: [],
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isDataArrived: true,
        isSubmitClicked: false,
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
    };

    componentDidMount() {
        this.props.securityAction.offers();
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        let cardsData: any = [];
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        
        cardsData = getCardsDatas()
       
        this.setState({ cardsContentNew: cardsData });
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        let cardValues: any = [];
        cardsData.map((item: any) => {
            cardValues.push(item.title);
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        analytics.track(
            `Product Table Viewed`,
            {
                page: 'Security Recommendations',
                brokerage_name: this.state.brokerage_name,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: this.state.agent_name,
                category_name: 'Home Security',
                card: false,
                card_title: '',
                product_name: cardValues,
                brand_name: cardValues,
            },
            { user_id: move_id },
        );
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
        }

        if (nextProps && nextProps.securityState && nextProps.securityState.securityDataPosted) {
            nextProps.securityState.securityDataPosted = false;
            if (nextProps.landing && currentState.appType != "WITHOUT_AUTH") {
                
                nextProps.history.push({
                    pathname: '/landing/home-security/summary',
                    state: {
                        securityType: nextProps.securityType ? nextProps.securityType : '',
                        securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                        selectedService: currentState.selectedService,
                        fromLanding: 'fromLanding',
                        // data: nextProps.history.location.state.data,
                        date: currentState.date,
                        time: currentState.time,
                        firstName: currentState.firstName,
                        lastName: currentState.lastName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            } else {
                nextProps.history.push({
                    pathname: '/dashboard/home-security/summary',
                    state: {
                        securityType: nextProps.securityType ? nextProps.securityType : '',
                        securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                        selectedService: currentState.selectedService,
                        fromLanding: '',
                        date: currentState.date,
                        time: currentState.time,
                        firstName: currentState.firstName,
                        lastName: currentState.lastName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            }
        }

        if (nextProps && nextProps.signupState && nextProps.signupState.landingtokenSet) {
            nextProps.signupState.landingtokenSet = false;
            const payload2 = {
                provider_name: currentState.selectedService[0] && currentState.selectedService[0].title,
                plan_name: currentState.selectedService[0] && currentState.selectedService[0].title,
                price: currentState.selectedService[0] && currentState.selectedService[0].monthlyfees,
                plan_details: {
                    securityType: nextProps.securityType ? nextProps.securityType : '',
                    securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                    installationCost:
                        currentState.selectedService[0] && currentState.selectedService[0].installationCost,
                    installationType:
                        currentState.selectedService[0] && currentState.selectedService[0].installationType,
                    monthlyfees: currentState.selectedService[0] && currentState.selectedService[0].monthlyfees,
                    topFeatures: currentState.selectedService[0] && currentState.selectedService[0].topFeatures,
                },
            };

            nextProps.securityAction.postSecurity(payload2);
        }

        return currentState;
    }
    calendlyHandle = (item: any) => {
        let selectedItem = [];
        selectedItem.push(item);
        this.setState({ calendlyOpen: true, selectedService: selectedItem });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        scheduleSegment(
            'Schedule Call Button Clicked',
            'Security Recommendations',
            brokerage,
            brokerage_key,
            agent_key,
            agent_name,
            'Home Security',
            'Schedule A Call',
            false,
            ``,
            false,
            ' ',
            move_id,
        );
    };
    closeCalendlyModal = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            await this.setState({ calendlyOpen: false });
            if (this.props.landing) {
                const domain = window.location.hostname;
                let name = domain.split('.');
                const payload = {
                    // destination_detail: this.state.address ? this.state.address : '',
                    destination_home: '1',
                    moving_date: new Date().toISOString().split('T')[0],
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    type: 'security',
                    user_ref: {
                        email: this.state.email,
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        // password: password,
                        phone: this.state.phone,
                        site_name:
                            name[0] == 'security'
                                ? 'dtc-marketing'
                                : name[0] == 'security-demo'
                                    ? 'apps-demo'
                                    : 'moveeasy-demo',
                    },
                };
                this.props.signupAction.landingMovePostForSecurity(payload);
                // this.props.history.push({
                //     pathname: '/landing/home-security/summary',
                //     state: {
                //         securityType: this.props.securityType,
                //         securityInstallation: this.props.securityInstallation,
                //         selectedService: this.state.selectedService,
                //         fromLanding: 'fromLanding',
                //         data: this.props.history.location.state.data,
                //         date: this.state.date,
                //         time: this.state.time,
                //         firstName: this.state.firstName,
                //         lastName: this.state.lastName,
                //         email: this.state.email,
                //         phone: this.state.phone,
                //     },
                // });
            } else {
                const payload2 = {
                    provider_name: this.state.selectedService[0] && this.state.selectedService[0].title,
                    plan_name: this.state.selectedService[0] && this.state.selectedService[0].title,
                    price: this.state.selectedService[0] && this.state.selectedService[0].monthlyfees,
                    plan_details: {
                        securityType: this.props.securityType ? this.props.securityType : '',
                        securityInstallation: this.props.securityInstallation ? this.props.securityInstallation : '',
                        installationCost:
                            this.state.selectedService[0] && this.state.selectedService[0].installationCost,
                        installationType:
                            this.state.selectedService[0] && this.state.selectedService[0].installationType,
                        monthlyfees: this.state.selectedService[0] && this.state.selectedService[0].monthlyfees,
                        topFeatures: this.state.selectedService[0] && this.state.selectedService[0].topFeatures,
                    },
                };

                this.props.securityAction.postSecurity(payload2);
            }
        }
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Security Recommendations',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Home Security',
            'Submit',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };
    closeModal = () => {
        this.setState({ calendlyOpen: false, isSubmitClicked: false });
        let brokerage: any;

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Security Recommendations',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Home Security',
            'Cancel',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };

    onCloseModal = () => {
        this.setState({ isViewDetails: false });
    };

    viewDetailsClick = (item: any) => {
        this.setState({ isViewDetails: true, viewDetailsData: item });
    };

    onCheckIfQualifyHandler = (id: number) => {
        this.props.history.push({
            pathname: '/dashboard/home-security/checkifqualify',
            state: {
                firstName: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.first_name || '',
                lastName: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.last_name || '',
                email: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.email || '',
                phone: this.props.commonState.move[0]?.user_ref && this.props.commonState.move[0]?.user_ref.phone || '',
                id: id,
            }
        })
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="main-div-recommendation">
                <div className="card-1-recommendation">
                    <div className="recommended-section"></div>
                    <div className="logo-section"></div>
                    <div className="brand-title"></div>
                    <div className="monthly-fees">Monthly fee</div>
                    <div className="upfront-cost-1">Upfront Cost</div>
                    <div className="installation-cost-1">Installation Cost</div>
                    <div className="installation-type-1">Installation Type</div>
                    <div className="exclusive-deals">
                        <div className="exclusive-deals-1">Exclusive Deals</div>
                    </div>
                    <div className="top-features">
                        <div className="top-features-1">Top Features</div>
                    </div>
                    <div className="conditions">
                        <div className="conditions-1">Conditions</div>
                    </div>
                    <div className="call"></div>
                    <div className="view-details"></div>
                </div>
                <div className="main-cards-security">
                    {this.state.cardsContentNew.map((item: any) => {
                        return (
                            <div
                                className="card-2-recommendation"
                                style={{ boxShadow: item.recommended ? '0px 0px 3px #bebebe' : '' }}
                            >
                                <div className="recommended-section">
                                    {item.recommended ? (
                                        <div
                                            className={
                                                this.props.isDouglasElliman ? 'recommended addColor' : 'recommended'
                                            }
                                        >
                                            Recommended
                                        </div>
                                    ) : null}
                                </div>
                                <div className="logo-section">
                                    <div className="logo-card">
                                        <img src={item.cardLogo} style={{ width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                                <div className="brand-title">{item.title}</div>
                                <div className="monthly-fees">${item.monthlyfees}</div>
                                <div className="upfront-cost">{item.upfrontCost}</div>
                                <div className="installation-cost">
                                    {item.installationCost != 'NA' ? (
                                        // <del className="cross">
                                        <span className="waived">{item.installationCost}</span>
                                    ) : (
                                        // </del>
                                        'NA'
                                    )}{' '}
                                    {item.waivedOff == true ? 'Waived Off' : ''}{' '}
                                </div>
                                <div className="installation-type">{item.installationType}</div>
                                <div className="exclusive-deals">
                                    <div className="tag-special">
                                        <div className="tag-div">
                                            <LocalOfferIcon
                                                style={{
                                                    color: this.props.isDouglasElliman
                                                        ? '#009CBD'
                                                        : item.recommended
                                                            ? '#006DFF'
                                                            : '#333',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                color: this.props.isDouglasElliman
                                                    ? '#009CBD'
                                                    : item.recommended
                                                        ? '#006DFF'
                                                        : '#333',
                                                fontWeight: 'bold',
                                                margin: '0 5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.special}
                                        </div>
                                    </div>

                                    <div className="price">
                                        {' '}
                                        {/* {!item.price.includes('OFF') ? <sup className="dollar">$</sup> : ''} */}
                                        {item.price}
                                    </div>

                                    <div className="speciality">{item.speciality}</div>
                                </div>
                                <div className="top-features">{item.topFeatures}</div>
                                <div className="conditions">
                                    <div className="condition-inner">
                                        <div className="condition">{item.condition}</div>
                                        <div className="info-icon">
                                            <InfoIcon style={{ color: '#6B6C6F', height: '15px', width: '15px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="call">
                                    <Button
                                        onClick={() => this.onCheckIfQualifyHandler(item.id)}
                                        borderRadius="4px"
                                        backgroundColor={
                                            this.props.isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : this.props.landing
                                                        ? '#30ab86'
                                                        : '#273E59'
                                        }
                                    >
                                        Check if I qualify
                                    </Button>
                                </div>
                                <div className="view-details" onClick={() => this.viewDetailsClick(item)}>
                                    View Details
                                </div>
                            </div>
                        );
                    })}
                </div>

                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.closeCalendlyModal}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="line12"></div>
                            <div
                                style={{ padding: '10px 20px 10px 20px' }}
                                className={
                                    this.props.isDouglasElliman
                                        ? 'scheduleInsuranceCall inputRecommendationHomeSecurity'
                                        : 'scheduleInsuranceCall '
                                }
                            >
                                {/* <div
                                    style={{
                                        fontSize: '16px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Call Schedule Details
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '49%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Select date"
                                                    className="date11"
                                                    value={this.state.date ? new Date(this.state.date) : null}
                                                    onChange={(e: any) => this.dateHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    autoOk={true}
                                                    error={
                                                        (this.state.isSubmitClicked && !this.state.date) ||
                                                        (this.state.date !== '' && !Date.parse(this.state.date))
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {(this.state.isSubmitClicked &&
                                                    !this.state.date &&
                                                    'Please select date') ||
                                                    (this.state.date !== '' &&
                                                        !Date.parse(this.state.date) &&
                                                        'Invalid Date')}
                                            </div>
                                        </div>
                                        <div style={{ width: '49%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    ampm={true}
                                                    variant="inline"
                                                    margin="normal"
                                                    id="time"
                                                    inputVariant="filled"
                                                    label="Select time"
                                                    className="date11"
                                                    value={this.state.time ? this.state.time : null}
                                                    onChange={(e: any) => this.timeHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    keyboardIcon={<AccessAlarmIcon />}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    // InputProps={{
                                                    //     onFocus: () => {
                                                    //         this.setState({ isOpen1: true });
                                                    //     },
                                                    // }}
                                                    error={
                                                        (this.state.isSubmitClicked && !this.state.time) ||
                                                        (this.state.time !== '' && !Date.parse(this.state.time))
                                                    }
                                                    // open={this.state.isOpen1}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {(this.state.isSubmitClicked &&
                                                    !this.state.time &&
                                                    'Please select time') ||
                                                    (this.state.time !== '' &&
                                                        !Date.parse(this.state.time) &&
                                                        'Invalid Date')}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                                !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                ? 'Please enter valid email'
                                                : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                                ? 'Please enter valid phone number'
                                                : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={
                                        this.props.isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.props.landing
                                                    ? '#30ab86'
                                                    : '#273e59'
                                            }`
                                    }
                                    width="130px"
                                    height="50px"
                                    color={
                                        this.props.isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.props.landing
                                                    ? '#30ab86'
                                                    : '#273e59'
                                    }
                                    border={
                                        this.props.isDouglasElliman
                                            ? '1px solid #100B28'
                                            : slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.props.landing
                                                    ? '#30ab86'
                                                    : '#273e59'
                                    }
                                    borderRadius={this.props.isDouglasElliman ? '0px' : '5px'}
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    onClick={() => this.closeModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        this.props.isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                    ? '#30AB86'
                                                    : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius={this.props.isDouglasElliman ? '0px' : '5px'}
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeCalendlyModal()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}

                {this.state.isViewDetails ? (
                    <Modal
                        isShowModal={this.state.isViewDetails}
                        showClose={false}
                        onCloseModal={this.onCloseModal}
                        className="securityRecommendationMainPageModal"
                    >
                        <ViewDetailsPage onBack={this.onCloseModal} data={this.state.viewDetailsData} />
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        securityAction: bindActionCreators(SecurityAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    securityState: state.security,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationCardsAll);
