import React, { Component } from 'react';
import './dashboardCss/moveFrommoveToCard2.scss';
import bellIcon from '../../Assets/images/DashBoard/bellIcon.png';
import downArrow from '../../Assets/images/DashBoard/arrowdown.svg';
import downArrowblue from '../../Assets/images/DashBoard/downblue.svg';
// import moveTruck from '../../Assets/images/DashBoard/moveTruck.svg';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import { store } from 'react-notifications-component';
import history from '../../routes/History';
import MobileDateCard from '../../components/dashboardComponents/MobileDateCard';
import Modal from '../../components/atoms/Modal';
import Button from '../../components/atoms/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
interface initialProps {
    history?: any;
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    commonState?: any;
    commonAction?: any;
    toAddress?: string;
    toPin?: string;
    fromAddress?: string;
    fromPin?: string;
    movingDate?: any;
    isDouglasElliman?: boolean;
    fullName?: string;
}

type initialState = {
    fromPin?: string;
    toPin?: string;
    fromAddress?: string;
    street?: any;
    street2?: any;
    toAddress?: string;
    dateNumber?: any;
    date?: any;
    clicked1?: boolean;
    clicked2?: boolean;
    clicked3?: boolean;
    clicked1M?: boolean;
    clicked2M?: boolean;
    clicked3M?: boolean;
    isDataArrived: boolean;
    moveId?: string;
    // isOpen? :boolean;
    anchorEl?: any;
    anchorEl1?: any;
    openModal: boolean;
    fullName: string;
    slug: any;
};
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
class MoveFromMoveToCard2 extends Component<initialProps, initialState> {
    state: initialState = {
        fullName: this.props.fullName ? this.props.fullName : '',
        fromPin: this.props.fromPin ? this.props.fromPin : '',
        toPin: this.props.toPin ? this.props.toPin : '',
        fromAddress: this.props.fromAddress ? this.props.fromAddress : '',
        toAddress: this.props.toAddress ? this.props.toAddress : '',
        date: this.props.movingDate
            ? `${moment(this.props.movingDate)
                  .utcOffset(0, true)
                  .format('DD')} ${moment(this.props.movingDate)
                  .utcOffset(0, true)
                  .format('MMM')}, ${moment(this.props.movingDate)
                  .utcOffset(0, true)
                  .format('YYYY')}`
            : '',
        clicked1: false,
        clicked2: false,
        clicked3: false,
        clicked1M: false,
        clicked2M: false,
        clicked3M: false,
        street: '',
        street2: '',
        dateNumber: this.props.movingDate
            ? moment(this.props.movingDate)
                  .utcOffset(0, true)
                  .format('DD')
            : '',
        isDataArrived: true,
        moveId: '',
        // isOpen:false,
        anchorEl: null,
        anchorEl1: null,
        openModal: false,
        slug: null,
    };

    componentDidMount() {
        // this.props.commonAction.moveGet();
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                moveId: cur.move[0].id,
                toAddress: cur.move[0].destination && cur.move[0].destination.lookup_name,
                toPin: cur.move[0].destination && cur.move[0].destination.zip_code,
                fromAddress: cur.move[0].source && cur.move[0].source.lookup_name,
                fromPin: cur.move[0].source && cur.move[0].source.zip_code,
                date:
                    // days[
                    moment(cur.move[0].moving_date)
                        .utcOffset(0, true)
                        .format('dddd'),
                // ]
                dateNumber: `${moment(cur.move[0].moving_date)
                    .utcOffset(0, true)
                    .format('DD')} ${
                    // monthNames[new Date(cur.move[0].moving_date).getMonth()]
                    moment(cur.move[0].moving_date)
                        .utcOffset(0, true)
                        .format('MMM')
                }, ${moment(cur.move[0].moving_date)
                    .utcOffset(0, true)
                    .format('YYYY')}`,
                street: `${cur.move[0].source_full_address}`,
                street2: ` ${cur.move[0].destination_full_address}`,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            // console.log("xyz",nextProps.commonState.move[0])
            let moveData = nextProps.commonState.move[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address
            ) {
                currentState.moveId = moveData.id;
                currentState.toAddress = moveData.destination && moveData.destination.lookup_name;
                currentState.toPin = moveData.destination && moveData.destination.zip_code;
                currentState.fromAddress = moveData.source && moveData.source.lookup_name;
                currentState.fromPin = moveData.source && moveData.source.zip_code;
                currentState.date = days[new Date(moveData.moving_date).getDay()];
                currentState.dateNumber = `${new Date(moveData.moving_date).getDate()} ${
                    monthNames[new Date(moveData.moving_date).getMonth()]
                }, ${new Date(moveData.moving_date).getFullYear()}`;
                currentState.street = `${moveData.source_full_address}`;
                currentState.street2 = `${moveData.destination_full_address}`;
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp?hash_code=${moveData.hash_code}`,
                    });
                }, 2000);
            }
        }

        return currentState;
    }

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street: `${streetNumber} ${route}, ${city}, ${state}, ${zip} `,
                        fromAddress: `${streetNumber} ${route} ${city} ${state} `,
                        fromPin: zip,
                        clicked1M: false,
                    });
                }
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        source_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClick1 = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl1: event.currentTarget });
    };

    handleSelect2 = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street2: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        toAddress: `${streetNumber} ${route} ${city} ${state}`,
                        toPin: zip,
                        clicked2: false,
                        clicked2M: false,
                    });
                }
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    dateHandler = (e: any) => {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let now = new Date(e);
        const dateNumber = now.getDate();
        const month = now.getMonth();
        const year = now.getFullYear();

        this.setState({
            date: days[now.getDay()],
            dateNumber: `${dateNumber} ${monthNames[month]}, ${
                year
                // .toString().substring(2)
            }`,
            clicked3: false,
            clicked3M: false,
        });
        let monthnum: number = new Date(e).getMonth() + 1;

        this.props.commonAction.moveAddressUpdate({
            // moving_date: e.toISOString().split('T')[0],
            moving_date: `${new Date(e).getFullYear()}-${monthnum}-${new Date(e).getDate()}`,
            move_id: this.state.moveId,
        });
        const isHomeOwner = localStorage.getItem('isHomeOwner');
        this.props.commonAction.header({
            partner_slug: slug && slug.slug,
            fetchMoveService: isHomeOwner ? 1 : 0,
        });
    };

    handleClickAway = () => {
        this.setState({
            clicked1: false,
            clicked2: false,
        });
    };

    openModalMobile = () => {
        this.setState({
            openModal: true,
        });
    };
    render() {
        // const isOpen=Boolean(this.state.anchorEl)
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        return (
            <div>
                <div className={isDouglasElliman ? 'webShowDouglas webShowDouglasD' : 'webShowDouglas'}>
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <div
                            style={{
                                width: this.props.width ? this.props.width : 'auto',
                                height: this.props.height ? this.props.height : '90px',
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                borderRadius: this.props.borderRadius ? this.props.borderRadius : '3px',
                                boxShadow: this.props.boxShadow ? this.props.boxShadow : null,
                                border: this.props.border ? this.props.border : '1px solid #DADCE0',
                                opacity: '1',
                                margin: this.props.cardMargin ? this.props.cardMargin : '',
                                padding: this.props.cardPadding ? this.props.cardPadding : '5px',
                            }}
                            onClick={this.props.onClick}
                            className={
                                this.props.isDouglasElliman
                                    ? 'card-height-width-2Douglas'
                                    : this.props.className
                                    ? `${this.props.className} `
                                    : ` card-height-width-2`
                            }
                        >
                            <div className="welcomeTextDiv">
                                {isDouglasElliman ? (
                                    <div className="welomeText1">
                                        Welcome aboard,
                                        <div className="nameWelcome">
                                            {isDouglasElliman
                                                ? `${
                                                      this.props.commonState &&
                                                      this.props.commonState.move &&
                                                      this.props.commonState.move[0] &&
                                                      this.props.commonState.move[0].user_ref &&
                                                      this.props.commonState.move[0].user_ref.first_name
                                                          ? this.props.commonState.move[0].user_ref.first_name
                                                          : ''
                                                  } ${
                                                      this.props.commonState &&
                                                      this.props.commonState.move &&
                                                      this.props.commonState.move[0] &&
                                                      this.props.commonState.move[0].user_ref &&
                                                      this.props.commonState.move[0].user_ref.last_name
                                                          ? this.props.commonState.move[0].user_ref.last_name
                                                          : ''
                                                  }`
                                                : slug && slug.display_name
                                                ? slug.display_name
                                                : ''}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="welomeText">{`Welcome to ${
                                        slug && slug.display_name ? slug.display_name : ''
                                    } Dashboard`}</div>
                                )}
                                {!isDouglasElliman && <img src={bellIcon} />}
                            </div>
                            {!isDouglasElliman && (
                                <div className="nameDiv">
                                    {this.props.fullName ? this.props.fullName : <Skeleton />}
                                </div>
                            )}
                            <div className={isDouglasElliman ? 'morningText1' : 'morningText'}>
                                {isDouglasElliman
                                    ? 'Good Morning! What a lovely day for moving.'
                                    : 'Your one stop shop for all your moving and home management priorities.'}
                            </div>
                            <div
                                className="cardContent"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: 20,
                                }}
                            >
                                <div className="card-section-1">
                                    <div
                                        className={isDouglasElliman ? 'card-section-1InnerD' : ''}
                                        style={{ display: 'flex', margin: '10px' }}
                                    >
                                        <div
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                            onClick={() => {
                                                this.setState({ clicked1: true, clicked2: false, clicked3: false });
                                            }}
                                        >
                                            <div className="section-1-heading">
                                                MOVE FROM
                                                {this.state.clicked1 == false ? (
                                                    <div>
                                                        <div className="section-1-number">{this.state.fromPin}</div>
                                                        <div className="section-1-content">{this.state.street}</div>
                                                    </div>
                                                ) : (
                                                    <div className="overflow-auto">
                                                        <AutoComplete
                                                            className="input1-moveCard"
                                                            value={this.state.street}
                                                            onChange={e => {
                                                                this.setState({ street: e });
                                                            }}
                                                            onSelect={this.handleSelect}
                                                            placeholder={this.state.street == '' ? 'From' : ''}

                                                            // inputTitle="To"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="truck-div">
                            <img src={moveTruck} className="truck" />
                        </div> */}
                                    </div>
                                </div>

                                <div
                                    className="card-section-2"
                                    onClick={() => {
                                        this.setState({ clicked2: true, clicked1: false, clicked3: false });
                                    }}
                                >
                                    <div
                                        className={isDouglasElliman ? 'card-section-2InnerD' : ''}
                                        style={{ display: 'flex', margin: '10px' }}
                                    >
                                        <div className="section-2-heading">
                                            MOVE TO
                                            {this.state.clicked2 == false ? (
                                                <div>
                                                    <div className="section-2-number">{this.state.toPin}</div>
                                                    <div className="section-2-content">{this.state.street2}</div>
                                                </div>
                                            ) : (
                                                <div className="overflow-auto">
                                                    <AutoComplete
                                                        //  className="input1-insuranceLanding"
                                                        className="input2-jammer"
                                                        value={this.state.street2}
                                                        onChange={e => {
                                                            this.setState({ street2: e });
                                                        }}
                                                        onSelect={this.handleSelect2}
                                                        placeholder={this.state.street2 == '' ? 'To' : ''}

                                                        // inputTitle="To"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {this.state.clicked3 == false ? (
                                    <div
                                        className="card-section-3"
                                        onClick={e => {
                                            this.setState({ clicked3: true, clicked1: false, clicked2: false });
                                            this.handleClick(e);
                                        }}
                                    >
                                        <div className="section-3-heading">
                                            DATE{' '}
                                            <span>
                                                {isDouglasElliman ? (
                                                    <img
                                                        src={downArrow}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrowblue}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                )}
                                            </span>
                                        </div>

                                        <div>
                                            <div className="section-3-number">{this.state.dateNumber}</div>
                                            <div className="section-3-content">{this.state.date}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-section-3">
                                        <div className="section-3-heading">
                                            DATE{' '}
                                            <span>
                                                {isDouglasElliman ? (
                                                    <img
                                                        src={downArrow}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrowblue}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                        <div className="date-picker-div-move">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    // label="Date picker inline"
                                                    className="date12"
                                                    value={this.state.date}
                                                    onChange={(e: any) => {
                                                        this.dateHandler(e);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: e => {
                                                            this.handleClick(e);
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            this.setState({ clicked3: false, anchorEl: null });
                                                        },
                                                        anchorEl: this.state.anchorEl,
                                                    }}
                                                    InputProps={{
                                                        onFocus: e => {
                                                            this.handleClick(e);
                                                        },
                                                    }}
                                                    open={Boolean(this.state.anchorEl)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="cardContent cardContentDDashboard">
                                <MobileDateCard width="100%" />
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
                {isDouglasElliman && (
                    <div className="mobileShowDouglas">
                        <div
                            style={{
                                width: this.props.width ? this.props.width : 'auto',
                                height: this.props.height ? this.props.height : '90px',
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                borderRadius: this.props.borderRadius ? this.props.borderRadius : '3px',
                                boxShadow: this.props.boxShadow ? this.props.boxShadow : null,
                                border: this.props.border ? this.props.border : '1px solid #DADCE0',
                                opacity: '1',
                                margin: this.props.cardMargin ? this.props.cardMargin : '',
                                padding: this.props.cardPadding ? this.props.cardPadding : '5px',
                            }}
                            onClick={this.openModalMobile}
                            className={
                                this.props.isDouglasElliman
                                    ? 'card-height-width-2Douglas'
                                    : this.props.className
                                    ? `${this.props.className} `
                                    : ` card-height-width-2`
                            }
                        >
                            <div className="welcomeTextDiv">
                                {isDouglasElliman ? (
                                    <div className="welomeText1">
                                        Welcome aboard,
                                        <div className="nameWelcome">
                                            {isDouglasElliman
                                                ? `${
                                                      this.props.commonState &&
                                                      this.props.commonState.move &&
                                                      this.props.commonState.move[0] &&
                                                      this.props.commonState.move[0].user_ref &&
                                                      this.props.commonState.move[0].user_ref.first_name
                                                          ? this.props.commonState.move[0].user_ref.first_name
                                                          : ''
                                                  } ${
                                                      this.props.commonState &&
                                                      this.props.commonState.move &&
                                                      this.props.commonState.move[0] &&
                                                      this.props.commonState.move[0].user_ref &&
                                                      this.props.commonState.move[0].user_ref.last_name
                                                          ? this.props.commonState.move[0].user_ref.last_name
                                                          : ''
                                                  }`
                                                : slug && slug.display_name
                                                ? slug.display_name
                                                : ''}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="welomeText">{`Welcome to ${
                                        slug && slug.display_name ? slug.display_name : ''
                                    } Dashboard`}</div>
                                )}
                                {!isDouglasElliman && <img src={bellIcon} />}
                            </div>
                            {!isDouglasElliman && (
                                <div className="nameDiv">
                                    {this.props.fullName ? this.props.fullName : <Skeleton />}
                                </div>
                            )}
                            {/* <div className={isDouglasElliman ? 'morningText1' : 'morningText'}>
                                {isDouglasElliman
                                    ? 'Good Morning! What a lovely day for moving.'
                                    : 'Your one stop shop for all your moving and home management priorities.'}
                            </div> */}
                            <div
                                className="cardContent"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: 20,
                                }}
                            >
                                <div className="card-section-1">
                                    <div
                                        className={isDouglasElliman ? 'card-section-1InnerD' : ''}
                                        style={{ display: 'flex', margin: '10px' }}
                                    >
                                        <div
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                            onClick={() => {
                                                this.setState({ clicked1M: true, clicked2M: false, clicked3M: false });
                                            }}
                                        >
                                            <div className="section-1-heading">
                                                MOVE FROM
                                                {this.state.clicked1M == false ? (
                                                    <div>
                                                        <div className="section-1-number">{this.state.fromPin}</div>
                                                        <div className="section-1-content">{this.state.street}</div>
                                                    </div>
                                                ) : (
                                                    <div className="overflow-auto">
                                                        <AutoComplete
                                                            className="input1-moveCard"
                                                            value={this.state.street}
                                                            onChange={e => {
                                                                this.setState({ street: e });
                                                            }}
                                                            onSelect={this.handleSelect}
                                                            placeholder={this.state.street == '' ? 'From' : ''}

                                                            // inputTitle="To"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="truck-div">
                            <img src={moveTruck} className="truck" />
                        </div> */}
                                    </div>
                                </div>

                                <div
                                    className="card-section-2"
                                    onClick={() => {
                                        this.setState({ clicked2M: true, clicked1M: false, clicked3M: false });
                                    }}
                                >
                                    <div
                                        className={isDouglasElliman ? 'card-section-2InnerD' : ''}
                                        style={{ display: 'flex', margin: '10px' }}
                                    >
                                        <div className="section-2-heading">
                                            MOVE TO
                                            {this.state.clicked2M == false ? (
                                                <div>
                                                    <div className="section-2-number">{this.state.toPin}</div>
                                                    <div className="section-2-content">{this.state.street2}</div>
                                                </div>
                                            ) : (
                                                <div className="overflow-auto">
                                                    <AutoComplete
                                                        //  className="input1-insuranceLanding"
                                                        value={this.state.street2}
                                                        onChange={e => {
                                                            this.setState({ street2: e });
                                                        }}
                                                        onSelect={this.handleSelect2}
                                                        placeholder={this.state.street2 == '' ? 'To' : ''}

                                                        // inputTitle="To"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {this.state.clicked3M == false ? (
                                    <div
                                        className="card-section-3"
                                        onClick={() => {
                                            this.setState({ clicked3M: true, clicked1M: false, clicked2M: false });
                                            // this.handleClick(e);
                                        }}
                                    >
                                        <div className="section-3-heading">
                                            DATE{' '}
                                            <span>
                                                {isDouglasElliman ? (
                                                    <img
                                                        src={downArrow}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrowblue}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                )}
                                            </span>
                                        </div>

                                        <div>
                                            <div className="section-3-number">{this.state.dateNumber}</div>
                                            <div className="section-3-content">{this.state.date}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-section-3">
                                        <div className="section-3-heading">
                                            DATE{' '}
                                            <span>
                                                {isDouglasElliman ? (
                                                    <img
                                                        src={downArrow}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={downArrowblue}
                                                        width="10px"
                                                        height="10px"
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                        <div className="date-picker-div-move">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    // label="Date picker inline"
                                                    className="date12"
                                                    value={this.state.date}
                                                    onChange={() => {
                                                        // this.dateHandler(e);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: e => {
                                                            this.handleClick1(e);
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            this.setState({ clicked3M: false, anchorEl1: null });
                                                        },
                                                        anchorEl: this.state.anchorEl1,
                                                    }}
                                                    InputProps={{
                                                        onFocus: e => {
                                                            this.handleClick1(e);
                                                        },
                                                    }}
                                                    open={Boolean(this.state.anchorEl1)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="cardContent cardContentDDashboard">
                                <MobileDateCard width="100%" />
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    isShowModal={this.state.openModal}
                    onCloseModal={() =>
                        this.setState({
                            openModal: false,
                            clicked1M: false,
                            clicked2M: false,
                            clicked3M: false,
                        })
                    }
                    showClose={false}
                    className={
                        this.props.isDouglasElliman
                            ? 'openModalDashboard openModalDashboardDouglas'
                            : 'openModalDashboard'
                    }
                >
                    <div>
                        <div className="backarrowDiv">
                            <ArrowBackIcon
                                onClick={() =>
                                    this.setState({
                                        openModal: false,
                                        clicked1M: false,
                                        clicked2M: false,
                                        clicked3M: false,
                                    })
                                }
                            />
                            <div className="textBackD">Moving details</div>
                        </div>
                        <div>
                            {this.state.clicked1M == false ? (
                                <div
                                    className="inputDivDMove"
                                    onClick={() => {
                                        this.setState({ clicked2M: false, clicked1M: true, clicked3M: false });
                                    }}
                                >
                                    <div className="upperTextD">MOVE FROM</div>

                                    <div>
                                        <div className="section-1-number">{this.state.fromPin}</div>
                                        <div className="section-1-content">{this.state.street}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="inputDivDMove">
                                    <div className="upperTextD">MOVE FROM</div>

                                    <AutoComplete
                                        className="input1-moveCard"
                                        value={this.state.street}
                                        onChange={e => {
                                            this.setState({ street: e });
                                        }}
                                        onSelect={this.handleSelect}
                                        placeholder={this.state.street == '' ? 'From' : ''}

                                        // inputTitle="To"
                                    />
                                </div>
                            )}

                            {this.state.clicked2M == false ? (
                                <div
                                    className="inputDivDMove"
                                    onClick={() => {
                                        this.setState({ clicked2M: true, clicked1M: false, clicked3M: false });
                                    }}
                                >
                                    <div className="upperTextD">MOVE TO</div>

                                    <div>
                                        <div className="section-2-number">{this.state.toPin}</div>
                                        <div className="section-2-content">{this.state.street2}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="inputDivDMove">
                                    <div className="upperTextD">MOVE TO</div>

                                    <AutoComplete
                                        //  className="input1-insuranceLanding"
                                        value={this.state.street2}
                                        onChange={e => {
                                            this.setState({ street2: e });
                                        }}
                                        onSelect={this.handleSelect2}
                                        placeholder={this.state.street2 == '' ? 'To' : ''}

                                        // inputTitle="To"
                                    />
                                </div>
                            )}

                            {this.state.clicked3M == false ? (
                                <div
                                    className="inputDivDMove"
                                    onClick={e => {
                                        this.setState({ clicked3M: true, clicked1M: false, clicked2M: false });
                                        this.handleClick1(e);
                                    }}
                                >
                                    <div className="section-3-heading">
                                        DATE{' '}
                                        <span>
                                            {isDouglasElliman ? (
                                                <img
                                                    src={downArrow}
                                                    width="10px"
                                                    height="10px"
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={downArrowblue}
                                                    width="10px"
                                                    height="10px"
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            )}
                                        </span>
                                    </div>

                                    <div>
                                        <div className="section-3-number">{this.state.dateNumber}</div>
                                        <div className="section-3-content">{this.state.date}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="inputDivDMove">
                                    <div className="section-3-heading">
                                        DATE{' '}
                                        <span>
                                            {isDouglasElliman ? (
                                                <img
                                                    src={downArrow}
                                                    width="10px"
                                                    height="10px"
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={downArrowblue}
                                                    width="10px"
                                                    height="10px"
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <div className="date-picker-div-move">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disablePast
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                // label="Date picker inline"
                                                className="date12"
                                                value={this.state.date}
                                                onChange={(e: any) => {
                                                    this.dateHandler(e);
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                    onFocus: e => {
                                                        this.handleClick1(e);
                                                    },
                                                }}
                                                autoOk={true}
                                                PopoverProps={{
                                                    disableRestoreFocus: true,
                                                    onClose: () => {
                                                        this.setState({ clicked3M: false, anchorEl1: null });
                                                    },
                                                    anchorEl: this.state.anchorEl1,
                                                }}
                                                InputProps={{
                                                    onFocus: e => {
                                                        this.handleClick1(e);
                                                    },
                                                }}
                                                open={Boolean(this.state.anchorEl1)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ButtonConfirmDouglas">
                        <Button
                            // backgroundColor="#100B28"
                            // color="#ffffff"
                            width={'100%'}
                            onClick={() =>
                                this.setState({
                                    openModal: false,
                                    clicked1M: false,
                                    clicked2M: false,
                                    clicked3M: false,
                                })
                            }
                        >
                            Confirm
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveFromMoveToCard2);
