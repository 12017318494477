import React, { Component } from 'react';

import './FinalPlan.scss';

type initialProps = {
    logo: any;
    kwh: string;
    contract: string;
    handleChangePlan?: any;
    supplier?: any;
};
type initialState = {};
export class finalPlan extends Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        return (
            <div className="main-final-utility">
                {/* <div className="selectedPlanFinal">
                    <div className="selectedPlanHead">Selected plan details</div>
                    <div className="changeFinalPlan" onClick={this.props.handleChangePlan}>
                        Change Plan
                    </div>
                </div> */}
                <div className="borderFinal"></div>
                <div className="lineFinal">
                    <div className="logoFinal">
                        {this.props.logo && this.props.logo != '' ? (
                            <img src={this.props.logo} />
                        ) : (
                            <div>{this.props.supplier}</div>
                        )}
                    </div>

                    <div className="kwhContent">
                        <div className="kwhFinalValue">{this.props.kwh}</div>
                        <div className="kwhFinalUnit">per Kwh</div>
                    </div>
                    <div className="kwhContent">
                        <div className="kwhFinalValue">{this.props.contract}Months</div>
                        <div className="kwhFinalUnit">Contract Term</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default finalPlan;
