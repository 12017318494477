const serverAddress =
    process.env.REACT_APP_MOVEEASY_ENV === 'development'
        ? 'https://conciergeapi3.moveeasy.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'production'
        ? 'https://conciergeapi.moveeasy.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'preprod'
        ? 'https://conciergepreprod.moveeasy.com'
        : 'https://conciergeapi3.moveeasy.com';
        
export const powerkiosImageUrl =
    process.env.REACT_APP_MOVEEASY_ENV === 'development'
        ? 'https://apitest.powerkiosk.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'production'
        ? 'https://api.powerkiosk.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'preprod'
        ? 'https://conciergepreprod.moveeasy.com'
        : 'https://apitest.powerkiosk.com';
        
export default serverAddress;

console.log(`${process.env.REACT_APP_MOVEEASY_ENV} ${process.env.REACT_APP_VERSION}`)

export const trustedFormUrl = 'cert.trustedform.com'

//App Configuration
const domain = window.location.hostname;
let appNameFromDoamin = domain.split('.');

let mainDomain = domain
if( appNameFromDoamin.length == 2) {
  mainDomain = domain
} else if(appNameFromDoamin.length > 2){
  mainDomain = `${appNameFromDoamin[appNameFromDoamin.length-2]}.${appNameFromDoamin[appNameFromDoamin.length-1]}`
}

export const uiConfig = {
  domain: appNameFromDoamin[0], //subdomain
  mainDomain: `https://${mainDomain}`,
  appType: process.env.REACT_APP_APP_TYPE || 'WITH_AUTH',
  dashboard: { home_services: true },
  colors: {
    icon:{
        stroke: "#1c3947",
        fill: "#d3e6e4"
    },
    button : {
      bg : '#CE202F',
      text: '#FFF'
    },
    input:{
      label: '#6B6C6F',
      value: '#333333'
    },
    heading: '#333333',
    subHeading: '#333333',
    bottomText: '#6B6C6F;'
  },
  button:{
    borderRadius: 4
  }
};