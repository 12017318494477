import * as React from 'react';
import { navigationIconColorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="30.636" height="30.635" viewBox="0 0 30.636 30.635">
            <defs>
                <style>
                    {`.dashboardfix__a{
                        fill:#fff
                    }.dashboardfix__b{
                        fill:${navigationIconColorSelector( props.active, "a", "fill")}
                    }`}
                </style>
            </defs>
            <g id="dashboard" transform="translate(0.6 0.6)">
                <path className='dashboardfix__b' id="Path_41963" data-name="Path 41963" d="M11.345,0h-9.2A2.149,2.149,0,0,0,0,2.146V7.665A2.149,2.149,0,0,0,2.146,9.812h9.2a2.149,2.149,0,0,0,2.146-2.146V2.146A2.149,2.149,0,0,0,11.345,0Zm0,0" fill="#d3e6e4" stroke="#1c3947" stroke-width="1.2" />
                <path id="Path_41964" data-name="Path 41964" d="M11.345,213.332h-9.2A2.149,2.149,0,0,0,0,215.479v12.878A2.149,2.149,0,0,0,2.146,230.5h9.2a2.149,2.149,0,0,0,2.146-2.146V215.479A2.149,2.149,0,0,0,11.345,213.332Zm0,0" transform="translate(0 -201.067)" fill="#fff" stroke="#1c3947" stroke-width="1.2" />
                <path className='dashboardfix__b' id="Path_41965" data-name="Path 41965" d="M288.677,341.332h-9.2a2.149,2.149,0,0,0-2.147,2.147V349a2.149,2.149,0,0,0,2.147,2.146h9.2A2.149,2.149,0,0,0,290.823,349v-5.519A2.149,2.149,0,0,0,288.677,341.332Zm0,0" transform="translate(-261.388 -321.708)" fill="#d3e6e4" stroke="#1c3947" stroke-width="1.2" />
                <path id="Path_41966" data-name="Path 41966" d="M288.677,0h-9.2a2.149,2.149,0,0,0-2.147,2.146V15.024a2.149,2.149,0,0,0,2.147,2.146h9.2a2.149,2.149,0,0,0,2.146-2.146V2.146A2.149,2.149,0,0,0,288.677,0Zm0,0" transform="translate(-261.388)" fill="#fff" stroke="#1c3947" stroke-width="1.2" />
            </g>
        </svg>
    );
}

export default SvgComponent;
