import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as Action from '../../store/nextMove/actions';
import * as CommonAction from '../../store/common/actions';
import './HomeOwnerDashboardUtilityForm.scss';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectInput from '../../components/atoms/SelectInput';
import WifiIcon from '@material-ui/icons/Wifi';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { InputLabel, FormControlLabel, RadioGroup, FormControl, Radio } from '@material-ui/core';
import Button from '../../components/atoms/Button';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as ServiceActions from '../../store/savings/actions';
import * as UtilityAction from '../../store/utility/actions';
import * as EnergyAction from '../../store/energy/actions';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import internetSubscriptionImage from '../../Assets/images/homeOwnerDashboard/internetSubscription.svg';
import energySubscriptionImage from '../../Assets/images/homeOwnerDashboard/energySubscription.svg';
import solarSubscriptionImage from '../../Assets/images/homeOwnerDashboard/solarSubscription.svg';

type initialProps = {
    // closeModal?: any;
    history?: any;
    homeUtilityServiceList?: any;
    moveId?: any;
    zip_code?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    serviceAction?: any;
    serviceState?: any;
    UtilityAction?: any;
    UtilityState?: any;
    energyAction?: any;
    energyState?: any;
    getSubscriptionDetails?: any;
    nextMoveState?: any;
    nextMoveAction: any;
    commonAction?: any;
    commonState?: any;
};
type initialState = {
    internetProviderFormDetails: any;
    // internetAmount: string;
    // internetProvider: any;
    // isInternet: boolean;
    // isTV: boolean;
    // isPhone: boolean;
    // internetAccountNumber: string;
    internetProviderOptions: any;
    energyAmount: string;
    energyProvider: any;
    energyProviderOptions: any;
    energyService: any;
    energyServiceOptions: any;
    energyExpirationDate: any;
    energyOtherProvider: string;
    // radioChange: any;
    energyIsAlternateProvider: string;
    solarInstallationDone: string;
    solarIntrestedInLearning: string;
    electricityAmount: string;
    electricityProvider: any;
    otherElectricityProvider: any;
    electricityProviderOptions: any;
    gasAmount: string;
    gasProvider: any;
    otherGasProvider: any;
    gasProviderOptions: any;
    utilityAccountNumber: string;
    activeStep: number;
    totalStep: number;
    size: number;
    moveId: string;
    continueClicked: boolean;
    continueClickedEnergy: boolean;
    isAddAnotherInternetProviderClicked: boolean;
    isInternetPost: boolean;
    electricityProviderId: any;
    gasProviderId: any;
    continueClickedSolar: boolean;
    continueClickedUtility: boolean;
    showInternet: boolean;
    showEnergy: boolean;
    showSolar: boolean;
    showUtilities: boolean;
    isGetValueMapped: boolean;
    energyId: string;
    solarId: string;
    utilitiesId: string;
    internetId: string;
    isDataArrived2: boolean;
    deregulatedState: any;
    deregulated: boolean;
    getDigitalProviders: boolean;
    internetClicked: boolean;
    energyClicked: boolean;
    solarClicked: boolean;
    utilitiesClicked: boolean;
};

class HomeOwnerDashboardUtilityForm extends React.Component<initialProps, initialState> {
    state: initialState = {
        internetProviderFormDetails: [
            {
                id: '',
                formId: 1,
                internetAmount: '',
                internetProvider: null,
                // onSelect: null,
                isInternet: false,
                isTV: false,
                isPhone: false,
                internetAccountNumber: '',
                otherProvider: '',
            },
        ],
        internetProviderOptions: [],
        // onChange: null,
        energyAmount: '',
        energyProvider: null,
        energyProviderOptions: [],
        energyService: null,
        energyServiceOptions: [],
        energyExpirationDate: null,
        energyOtherProvider: '',
        // radioChange: null,
        energyIsAlternateProvider: '',
        solarInstallationDone: '',
        solarIntrestedInLearning: '',
        electricityAmount: '',
        electricityProvider: null,
        otherElectricityProvider: '',
        electricityProviderOptions: [],
        gasAmount: '',
        gasProvider: null,
        otherGasProvider: '',
        gasProviderOptions: [],
        utilityAccountNumber: '',
        activeStep: 1,
        totalStep: 4,
        size: 0,
        moveId: '',
        continueClicked: false,
        continueClickedEnergy: false,
        isAddAnotherInternetProviderClicked: false,
        isInternetPost: false,
        electricityProviderId: '',
        gasProviderId: '',
        continueClickedSolar: false,
        continueClickedUtility: false,
        showInternet: false,
        showEnergy: false,
        showSolar: false,
        showUtilities: false,
        isGetValueMapped: false,
        energyId: '',
        solarId: '',
        utilitiesId: '',
        internetId: '',
        isDataArrived2: true,
        deregulatedState: [],
        deregulated: false,
        getDigitalProviders: false,
        internetClicked: false,
        energyClicked: false,
        solarClicked: false,
        utilitiesClicked: false,
    };

    componentDidMount() {
        this.setState({ size: window.screen.width });
        let showInternet: boolean = false;
        let showEnergy: boolean = false;
        let showSolar: boolean = false;
        let showUtilities: boolean = false;

        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeUtilityServiceList &&
            this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                if (item.slug === 'internet_cable') {
                    showInternet = true;
                }
                if (item.slug === 'energy-savings') {
                    showEnergy = true;
                }
                if (item.slug === 'solar') {
                    showSolar = true;
                }
                if (item.slug === 'utility_providers') {
                    showUtilities = true;
                }
            });
        this.setState({
            showInternet: showInternet,
            showEnergy: showEnergy,
            showSolar: showSolar,
            showUtilities: showUtilities,
        });
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.moveId
        ) {
            this.setState({ moveId: this.props.history.location.state.moveId });
            let payload2 = {
                moveId: this.props.history.location.state.moveId,
            };
            this.props.nextMoveAction.getNextMove(payload2);
        }
        if (showInternet) {
            this.props.serviceAction.getDigitalServiceProvider();
        }
        if (showUtilities) {
            const payload = {
                source_zip:
                    this.props &&
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.zip_code,
                // source_zip: '32720',
                dest_zip:
                    this.props &&
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.zip_code,
            };
            this.props.UtilityAction.getUtility(payload);
        }
        if (showEnergy) {
            this.props.energyAction.getEnergyType();
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            currentState.deregulatedState =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.energy_options_deregulated &&
                nextProps.commonState.move[0].regulated_details.energy_options_deregulated.length
                    ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated
                    : [];
            currentState.deregulated =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.is_deregulated
                    ? nextProps.commonState.move[0].regulated_details.is_deregulated
                    : false;
            currentState.moveId = nextProps.commonState.move[0].id;
        }
        return currentState;
    }

    componentDidUpdate = (prevProps: initialProps) => {
        if (
            prevProps.serviceState.getDigitalServiceProvider !== this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.isGetDigitalServiceProvider
        ) {
            let digitalProvider: any = [];

            this.props.serviceState.getDigitalServiceProvider.map((item: any) => {
                digitalProvider.push({ label: item.name, value: item.name });
            });
            digitalProvider.push({ label: 'Other', value: 'other' });

            this.setState({
                internetProviderOptions: digitalProvider,
                getDigitalProviders: true,
            });
        }
        if (
            prevProps.homeOwnerDashboardState.postSubscriptionDetails !=
                this.props.homeOwnerDashboardState.postSubscriptionDetails &&
            this.props.homeOwnerDashboardState.postSubscriptionDetails &&
            this.props.homeOwnerDashboardState.isPostSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.isPostSubscriptionDetails = false;
            {
                this.setState({
                    continueClicked: false,
                    continueClickedEnergy: false,
                    continueClickedSolar: false,
                    continueClickedUtility: false,
                    internetClicked: false,
                    energyClicked: false,
                    solarClicked: false,
                    utilitiesClicked: false,
                });
            }
            // this.props.closeModal();
            if (this.state.activeStep == 1) {
                if (
                    (this.state.showEnergy && this.state.showSolar) ||
                    (this.state.showEnergy && !this.state.showSolar)
                ) {
                    this.setState({ activeStep: 2 });
                } else if (!this.state.showEnergy && this.state.showSolar) {
                    this.setState({ activeStep: 3 });
                } else if (!this.state.showEnergy && !this.state.showSolar) {
                    this.setState({ activeStep: 4 });
                }
                if (!this.state.showEnergy && !this.state.showSolar && !this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 2) {
                if (this.state.showSolar) {
                    this.setState({ activeStep: 3 });
                } else {
                    this.setState({ activeStep: 4 });
                }
                if (!this.state.showSolar && !this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 3) {
                this.setState({ activeStep: 4 });
                if (!this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 4) {
                this.props.history.push('/dashboardhomeowner');
            }
            if (this.state.isAddAnotherInternetProviderClicked) {
                let internetProviderFormDetails: any = this.state.internetProviderFormDetails;
                internetProviderFormDetails.push({
                    formId: internetProviderFormDetails.length + 1,
                    internetAmount: '',
                    internetProvider: null,
                    // onSelect: null,
                    isInternet: false,
                    isTV: false,
                    isPhone: false,
                    internetAccountNumber: '',
                    otherProvider: '',
                });
                this.setState({
                    internetProviderFormDetails: internetProviderFormDetails,
                    isAddAnotherInternetProviderClicked: false,
                });
            }
            this.setState({ isInternetPost: true });
        } else if (
            prevProps.homeOwnerDashboardState.error != this.props.homeOwnerDashboardState.error &&
            this.props.homeOwnerDashboardState.error &&
            this.props.homeOwnerDashboardState.error != null &&
            this.props.homeOwnerDashboardState.error != ''
        ) {
            this.setState({
                internetClicked: false,
                energyClicked: false,
                solarClicked: false,
                utilitiesClicked: false,
            });
        }
        if (
            prevProps.UtilityState.utility != this.props.UtilityState.utility &&
            this.props.UtilityState.utility.source &&
            this.props.UtilityState.utility.source[0]
        ) {
            let electricityProviderOptions: any = [];
            let gasProviderOptions: any = [];
            electricityProviderOptions.push({
                label:
                    this.props.UtilityState.utility.source[0].electricity_provider_name !== null &&
                    this.props.UtilityState.utility.source[0].electricity_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].electricity_provider_name
                        : 'Other',
                value:
                    this.props.UtilityState.utility.source[0].electricity_provider_name !== null &&
                    this.props.UtilityState.utility.source[0].electricity_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].electricity_provider_name
                        : 'Other',
            });
            gasProviderOptions.push({
                label:
                    this.props.UtilityState.utility.source[0].gas_provider_name != null &&
                    this.props.UtilityState.utility.source[0].gas_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].gas_provider_name
                        : 'Other',
                value:
                    this.props.UtilityState.utility.source[0].gas_provider_name != null &&
                    this.props.UtilityState.utility.source[0].gas_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].gas_provider_name
                        : 'Other',
            });
            this.setState({
                electricityProviderOptions: electricityProviderOptions,
                gasProviderOptions: gasProviderOptions,
            });
        }
        if (
            prevProps.energyState.getEnergyType != this.props.energyState.getEnergyType &&
            this.props.energyState.getEnergyType.services &&
            this.props.energyState.isGetEnergyType
        ) {
            this.props.energyState.isGetEnergyType = false;
            let energyType: any = [];
            this.props.energyState.getEnergyType.services &&
                this.props.energyState.getEnergyType.services.map((item: any) => {
                    energyType.push({ label: item.name, value: item.name, item: item });
                    return energyType;
                });
            this.setState({ energyServiceOptions: energyType });
        }
        if (
            prevProps.energyState.getCompany != this.props.energyState.getCompany &&
            this.props.energyState.getCompany.utilities &&
            this.props.energyState.isGetCompany
        ) {
            this.props.energyState.isGetCompany = false;
            let options: any = [];

            this.props.energyState.getCompany.utilities &&
                this.props.energyState.getCompany.utilities.map((item: any) => {
                    options.push({ label: item.utility_name, value: item.utility_name });
                    return options;
                });
            options.push({ label: 'Other', value: 'Other' });
            this.setState({ energyProviderOptions: options });
        }

        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.getSubscriptionDetails &&
            this.props.history.location.state.getSubscriptionDetails.length &&
            !this.state.isGetValueMapped &&
            this.state.getDigitalProviders
            // &&
            // this.state.internetProviderOptions.length &&
            // this.state.energyServiceOptions.length
        ) {
            let internetData: any = [];
            this.props.history.location.state.getSubscriptionDetails.map((item: any) => {
                if (item.service && item.service.slug == 'internet_cable') {
                    let options: any = this.state.internetProviderOptions;
                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'other', label: 'Other' };
                    }
                    internetData.push({
                        id: item.id,
                        formId: internetData.length + 1,
                        internetAmount: item.price,
                        internetProvider: provider,
                        // onSelect: null,
                        isInternet: item.services_brought.includes('Internet') ? true : false,
                        isTV: item.services_brought.includes('TV') ? true : false,
                        isPhone: item.services_brought.includes('Phone') ? true : false,
                        internetAccountNumber: item.account_number,
                        otherProvider: item.provider,
                    });
                    this.setState({ internetId: item.id });
                }
                if (item.service && item.service.slug == 'energy-savings') {
                    let serviceOptions: any = this.state.energyServiceOptions;
                    let service: any = null;
                    serviceOptions.map((item1: any) => {
                        if (item1.value == item.services_brought) {
                            service = item1;
                        }
                    });
                    let options: any = this.state.energyProviderOptions;
                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'Other', label: 'Other' };
                    }
                    this.setState({
                        energyId: item.id,
                        energyProvider: provider,
                        energyOtherProvider: item.provider,
                        energyAmount: item.price,
                        energyIsAlternateProvider: 'yes',
                        energyExpirationDate: item.expiration_date,
                        energyService: service,
                    });
                }
                if (item.service && item.service.slug == 'solar') {
                    this.setState({
                        solarId: item.id,
                        solarInstallationDone: item.is_installed,
                        solarIntrestedInLearning: item.is_interested,
                    });
                }
                if (item.service && item.service.slug == 'utility_providers') {
                    if (item.services_brought == 'Electricity') {
                        let electricityoptions: any = this.state.electricityProviderOptions;
                        let electricityprovider: any = null;
                        electricityoptions.map((item1: any) => {
                            if (item1.value == item.provider) {
                                electricityprovider = item1;
                            }
                        });
                        if (electricityprovider == null) {
                            electricityprovider = { value: 'Other', label: 'Other' };
                        }
                        this.setState({
                            utilitiesId: item.id,
                            electricityAmount: item.amount,
                            electricityProvider: electricityprovider,
                            otherElectricityProvider: item.provider,
                            utilityAccountNumber: item.account_number,
                        });
                    }
                    if (item.services_brought == 'Natural Gas') {
                        let gasoptions: any = this.state.gasProviderOptions;
                        let gasprovider: any = null;
                        gasoptions.map((item1: any) => {
                            if (item1.value == item.provider) {
                                gasprovider = item1;
                            }
                        });
                        if (gasprovider == null) {
                            gasprovider = { value: 'Other', label: 'Other' };
                        }
                        this.setState({
                            utilitiesId: item.id,
                            gasAmount: item.amount,
                            gasProvider: gasprovider,
                            otherGasProvider: item.provider,
                            utilityAccountNumber: item.account_number,
                        });
                    }
                }
            });
            this.setState({ isGetValueMapped: true, internetProviderFormDetails: internetData });
        }
        if (
            prevProps.homeOwnerDashboardState.updateSubscriptionDetails !=
                this.props.homeOwnerDashboardState.updateSubscriptionDetails &&
            this.props.homeOwnerDashboardState.updateSubscriptionDetails &&
            this.props.homeOwnerDashboardState.isUpdateSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.isUpdateSubscriptionDetails = false;
            {
                this.setState({
                    continueClicked: false,
                    continueClickedEnergy: false,
                    continueClickedSolar: false,
                    continueClickedUtility: false,
                });
            }
            if (this.state.activeStep == 1) {
                if (
                    (this.state.showEnergy && this.state.showSolar) ||
                    (this.state.showEnergy && !this.state.showSolar)
                ) {
                    this.setState({ activeStep: 2 });
                } else if (!this.state.showEnergy && this.state.showSolar) {
                    this.setState({ activeStep: 3 });
                } else if (!this.state.showEnergy && !this.state.showSolar) {
                    this.setState({ activeStep: 4 });
                }
                if (!this.state.showEnergy && !this.state.showSolar && !this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 2) {
                if (this.state.showSolar) {
                    this.setState({ activeStep: 3 });
                } else {
                    this.setState({ activeStep: 4 });
                }
                if (!this.state.showSolar && !this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 3) {
                this.setState({ activeStep: 4 });
                if (!this.state.showUtilities) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 4) {
                this.props.history.push('/dashboardhomeowner');
            }
            if (this.state.isAddAnotherInternetProviderClicked) {
                let internetProviderFormDetails: any = this.state.internetProviderFormDetails;
                internetProviderFormDetails.push({
                    formId: internetProviderFormDetails.length + 1,
                    internetAmount: '',
                    internetProvider: null,
                    // onSelect: null,
                    isInternet: false,
                    isTV: false,
                    isPhone: false,
                    internetAccountNumber: '',
                    otherProvider: '',
                });
                this.setState({
                    internetProviderFormDetails: internetProviderFormDetails,
                    isAddAnotherInternetProviderClicked: false,
                });
            }
            this.setState({ isInternetPost: true });
        }

        if (
            prevProps.nextMoveState != this.props.nextMoveState &&
            this.props.nextMoveState.getNextMove &&
            this.props.nextMoveState.isGetNextMove
        ) {
            this.props.nextMoveState.isGetNextMove = false;
            let internetData: any = [];
            this.props.nextMoveState.getNextMove.results &&
                this.props.nextMoveState.getNextMove.results.map((item: any) => {
                    if (item.service === 'Internet and Cable') {
                        let options: any = this.state.internetProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'other', label: 'Other' };
                        }
                        internetData.push({
                            // id: item.id,
                            formId: internetData.length + 1,
                            internetAmount: item.price,
                            internetProvider: provider,
                            // onSelect: null,
                            isInternet: item.services_brought.includes('internet') ? true : false,
                            isTV: item.services_brought.includes('tv') ? true : false,
                            isPhone: item.services_brought.includes('phone') ? true : false,
                            internetAccountNumber: item.account_number,
                            otherProvider: item.provider,
                        });
                        this.setState({ internetId: item.id });
                    } else if (item.service === 'Utilities') {
                        if (item.services_brought == 'Electricity') {
                            let electricityoptions: any = this.state.electricityProviderOptions;
                            let electricityprovider: any = null;
                            electricityoptions.map((item1: any) => {
                                if (item1.value == item.provider) {
                                    electricityprovider = item1;
                                }
                            });
                            if (electricityprovider == null) {
                                electricityprovider = { value: 'Other', label: 'Other' };
                            }
                            this.setState({
                                // utilitiesId: item.id,
                                electricityAmount: item.amount,
                                electricityProvider: electricityprovider,
                                otherElectricityProvider: item.provider,
                                utilityAccountNumber: item.account_number,
                            });
                        }
                        if (item.services_brought == 'Natural Gas') {
                            let gasoptions: any = this.state.gasProviderOptions;
                            let gasprovider: any = null;
                            gasoptions.map((item1: any) => {
                                if (item1.value == item.provider) {
                                    gasprovider = item1;
                                }
                            });
                            if (gasprovider == null) {
                                gasprovider = { value: 'Other', label: 'Other' };
                            }
                            this.setState({
                                // utilitiesId: item.id,
                                gasAmount: item.amount,
                                gasProvider: gasprovider,
                                otherGasProvider: item.provider,
                                utilityAccountNumber: item.account_number,
                            });
                        }
                    }
                });
            if (internetData && internetData.length && this.state.internetId == '') {
                this.setState({ internetProviderFormDetails: internetData });
            }
        }
    };

    onChange = (e: any, name: string) => {
        if (name == 'energyAmount' || name == 'electricityAmount' || name == 'gasAmount') {
            this.setState({ ...this.state, [name]: e.target.value.replace(/[^\d.]/g, '') });
        } else if (name == 'utilityAccountNumber') {
            this.setState({ ...this.state, [name]: e.target.value.replace(/\D/g, '') });
        } else {
            this.setState({ ...this.state, [name]: e.target.value });
        }
    };
    onSelect = (e: any, name: string) => {
        if (name == 'energyService') {
            this.props.energyAction.getCompany({
                service_type_id: e.item.serviceTypeId,
            });
        }
        this.setState({ ...this.state, [name]: e });
    };
    onChangeInternet = (e: any, name: string, index: number) => {
        let internetProviderFormDetails = this.state.internetProviderFormDetails;
        let tempData = internetProviderFormDetails[index];
        if (name == 'internetProvider') {
            tempData[name] = e;
        } else if (name == 'internetAccountNumber') {
            tempData[name] = e.target.value.replace(/\D/g, '');
        } else if (name == 'internetAmount') {
            tempData[name] = e.target.value.replace(/[^\d.]/g, '');
        } else {
            tempData[name] = e.target.value;
        }
        internetProviderFormDetails[index] = tempData;
        this.setState({ internetProviderFormDetails: internetProviderFormDetails });
    };
    radioChange = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e.target.value });
    };

    setSize = () => {
        this.setState({ size: window.screen.width });
    };
    addAnotherInternetForm = () => {
        // if (this.state.isInternetPost) {
        let internetProviderFormDetails: any = this.state.internetProviderFormDetails;
        internetProviderFormDetails.push({
            id: '',
            formId: internetProviderFormDetails.length + 1,
            internetAmount: '',
            internetProvider: null,
            // onSelect: null,
            isInternet: false,
            isTV: false,
            isPhone: false,
            internetAccountNumber: '',
            otherProvider: '',
        });
        // this.setState({
        //     internetProviderFormDetails: internetProviderFormDetails,
        //     isAddAnotherInternetProviderClicked: false,
        //     isInternetPost: false,
        // });
        // } else {
        //     this.setState({
        //         isInternetPost: false,
        //     });
        //     this.internetPostSubcriptionDetails();
        // }
        this.setState({
            isAddAnotherInternetProviderClicked: true,
            internetProviderFormDetails: internetProviderFormDetails,
        });
    };
    internetService = (name: string, index: number) => {
        let internetProviderFormDetails = this.state.internetProviderFormDetails;
        let tempData = internetProviderFormDetails[index];
        tempData[name] = !tempData[name];
        internetProviderFormDetails[index] = tempData;
        this.setState({ internetProviderFormDetails: internetProviderFormDetails });
    };
    internetPostSubcriptionDetails = async () => {
        this.setState({ continueClicked: true });
        let payload: any = [];
        await this.state.internetProviderFormDetails.map((item: any) => {
            let payloadData: any = item;
            let serviceBrought: string = '';
            if (payloadData.isInternet && serviceBrought == '') {
                serviceBrought = serviceBrought + 'Internet';
            }
            if (payloadData.isTV && serviceBrought !== '') {
                serviceBrought = serviceBrought + ', TV';
            } else if (payloadData.isTV && serviceBrought == '') {
                serviceBrought = serviceBrought + 'TV';
            }
            if (payloadData.isPhone && serviceBrought !== '') {
                serviceBrought = serviceBrought + ', Phone';
            } else if (payloadData.isPhone && serviceBrought == '') {
                serviceBrought = serviceBrought + 'Phone';
            }
            let serviceId: any = '';
            this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.homeUtilityServiceList &&
                this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                    if (item.slug === 'internet_cable') {
                        serviceId = item.id;
                    }
                });
            if (
                payloadData &&
                payloadData.internetAccountNumber &&
                payloadData.internetAccountNumber.length >= 4 &&
                payloadData.internetAmount &&
                (payloadData.isInternet || payloadData.isTV || payloadData.isPhone) &&
                serviceId &&
                payloadData.internetProvider &&
                payloadData.internetProvider.value &&
                ((payloadData.internetProvider.value == 'other' && payloadData.otherProvider) ||
                    payloadData.internetProvider.value != 'other')
            ) {
                if (payloadData.id) {
                    payload.push({
                        id: payloadData.id,
                        service_id: serviceId,
                        provider:
                            payloadData.internetProvider &&
                            payloadData.internetProvider.value &&
                            payloadData.internetProvider.value != 'other'
                                ? payloadData.internetProvider.value
                                : payloadData.internetProvider &&
                                  payloadData.internetProvider.value &&
                                  payloadData.internetProvider.value == 'other'
                                ? payloadData.otherProvider
                                : '',
                        services_brought: serviceBrought,
                        price: payloadData.internetAmount,
                        account_number: payloadData.internetAccountNumber,
                        move: this.state.moveId,
                    });
                } else {
                    payload.push({
                        service_id: serviceId,
                        provider:
                            payloadData.internetProvider &&
                            payloadData.internetProvider.value &&
                            payloadData.internetProvider.value != 'other'
                                ? payloadData.internetProvider.value
                                : payloadData.internetProvider &&
                                  payloadData.internetProvider.value &&
                                  payloadData.internetProvider.value == 'other'
                                ? payloadData.otherProvider
                                : '',
                        services_brought: serviceBrought,
                        price: payloadData.internetAmount,
                        account_number: payloadData.internetAccountNumber,
                        move: this.state.moveId,
                    });
                }
            }
            return payload;
        });
        if (payload.length) {
            if (this.state.internetId) {
                let payload2: any = {
                    id: this.state.internetId,
                    body: payload,
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload2);
                this.setState({ internetClicked: true });
            } else {
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.setState({ internetClicked: true });
            }
        }
    };

    enrgyPostSubcriptionDetails = () => {
        this.setState({ continueClickedEnergy: true });
        if (this.state.energyIsAlternateProvider && this.state.energyIsAlternateProvider == 'yes') {
            let serviceId: any = '';
            this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.homeUtilityServiceList &&
                this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                    if (item.slug === 'energy-savings') {
                        serviceId = item.id;
                    }
                });
            if (
                serviceId &&
                this.state.energyAmount &&
                this.state.energyExpirationDate &&
                this.state.energyService &&
                this.state.energyService.value &&
                this.state.energyProvider &&
                this.state.energyProvider.value &&
                ((this.state.energyProvider.value == 'Other' && this.state.energyOtherProvider) ||
                    this.state.energyProvider.value != 'Other')
            ) {
                if (this.state.energyId) {
                    let payload = {
                        id: this.state.energyId,
                        body: {
                            id: this.state.energyId,
                            service_id: serviceId,
                            provider:
                                this.state.energyProvider &&
                                this.state.energyProvider.value &&
                                this.state.energyProvider.value != 'Other'
                                    ? this.state.energyProvider.value
                                    : this.state.energyProvider &&
                                      this.state.energyProvider.value &&
                                      this.state.energyProvider.value == 'Other'
                                    ? this.state.energyOtherProvider
                                    : '',
                            price: this.state.energyAmount,
                            expiration_date: moment(this.state.energyExpirationDate).format('YYYY-MM-DD'),
                            move: this.state.moveId,
                            services_brought: this.state.energyService && this.state.energyService.value,
                        },
                    };
                    this.props.dashboardAction.updateSubscriptionDetails(payload);
                    this.setState({ energyClicked: true });
                } else {
                    let payload = {
                        service_id: serviceId,
                        provider:
                            this.state.energyProvider &&
                            this.state.energyProvider.value &&
                            this.state.energyProvider.value != 'Other'
                                ? this.state.energyProvider.value
                                : this.state.energyProvider &&
                                  this.state.energyProvider.value &&
                                  this.state.energyProvider.value == 'Other'
                                ? this.state.energyOtherProvider
                                : '',
                        price: this.state.energyAmount,
                        expiration_date: moment(this.state.energyExpirationDate).format('YYYY-MM-DD'),
                        move: this.state.moveId,
                        services_brought: this.state.energyService && this.state.energyService.value,
                    };
                    this.props.dashboardAction.postSubscriptionDetails(payload);
                    this.setState({ energyClicked: true });
                }
            }
        } else if (this.state.energyIsAlternateProvider && this.state.energyIsAlternateProvider == 'no') {
            if (this.state.showSolar) {
                this.setState({ activeStep: 3 });
            } else if (!this.state.showSolar && this.state.showUtilities) {
                this.setState({ activeStep: 4 });
            } else if (!this.state.showSolar && !this.state.showUtilities) {
                this.props.history.push('/dashboardhomeowner');
                // this.props.closeModal();
            }
        }
    };

    solarPostSubcriptionDetails = () => {
        this.setState({ continueClickedSolar: true });
        let serviceId: any = '';
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeUtilityServiceList &&
            this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                if (item.slug === 'solar') {
                    serviceId = item.id;
                }
            });
        if (serviceId && this.state.solarInstallationDone && this.state.solarIntrestedInLearning) {
            if (this.state.solarId) {
                let payload = {
                    id: this.state.solarId,
                    body: {
                        id: this.state.solarId,
                        service_id: serviceId,
                        move: this.state.moveId,
                        is_installed:
                            this.state.solarInstallationDone && this.state.solarInstallationDone == 'yes'
                                ? true
                                : false,
                        is_interested:
                            this.state.solarIntrestedInLearning && this.state.solarIntrestedInLearning == 'yes'
                                ? true
                                : false,
                    },
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                this.setState({ solarClicked: true });
            } else {
                let payload = {
                    service_id: serviceId,
                    move: this.state.moveId,
                    is_installed:
                        this.state.solarInstallationDone && this.state.solarInstallationDone == 'yes' ? true : false,
                    is_interested:
                        this.state.solarIntrestedInLearning && this.state.solarIntrestedInLearning == 'yes'
                            ? true
                            : false,
                };
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.setState({ solarClicked: true });
            }
        }
    };

    utilitiesPostSubcriptionDetails = () => {
        this.setState({ continueClickedUtility: true });

        let serviceId: any = '';
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeUtilityServiceList &&
            this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                if (item.slug === 'utility_providers') {
                    serviceId = item.id;
                }
            });
        if (
            this.state.utilityAccountNumber &&
            this.state.electricityAmount &&
            this.state.gasAmount &&
            serviceId &&
            this.state.electricityProvider &&
            this.state.electricityProvider.value &&
            this.state.gasProvider &&
            this.state.gasProvider.value &&
            ((this.state.electricityProvider.value == 'Other' && this.state.otherElectricityProvider) ||
                this.state.electricityProvider.value != 'Other') &&
            ((this.state.gasProvider.value == 'Other' && this.state.otherGasProvider) ||
                (this.state.gasProvider.value != 'Other' && this.state.otherGasProvider == ''))
        ) {
            if (this.state.utilitiesId) {
                let payload = {
                    id: this.state.utilitiesId,
                    body: {
                        id: this.state.utilitiesId,
                        service_id: serviceId,
                        provider:
                            this.state.electricityProvider &&
                            this.state.electricityProvider.value &&
                            this.state.electricityProvider.value != 'Other'
                                ? this.state.electricityProvider.value
                                : this.state.electricityProvider &&
                                  this.state.electricityProvider.value &&
                                  this.state.electricityProvider.value == 'Other'
                                ? this.state.otherElectricityProvider
                                : '',
                        price: this.state.electricityAmount,
                        account_number: this.state.utilityAccountNumber,
                        move: this.state.moveId,
                    },
                };
                let payload2 = {
                    id: this.state.utilitiesId,
                    body: {
                        id: this.state.utilitiesId,
                        service_id: serviceId,
                        provider:
                            this.state.gasProvider &&
                            this.state.gasProvider.value &&
                            this.state.gasProvider.value != 'Other'
                                ? this.state.gasProvider.value
                                : this.state.gasProvider &&
                                  this.state.gasProvider.value &&
                                  this.state.gasProvider.value == 'Other'
                                ? this.state.otherGasProvider
                                : '',
                        price: this.state.gasAmount,
                        account_number: this.state.utilityAccountNumber,
                        move: this.state.moveId,
                    },
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                this.props.dashboardAction.updateSubscriptionDetails(payload2);
                this.setState({ utilitiesClicked: true });
            } else {
                let payload = {
                    service_id: serviceId,
                    provider:
                        this.state.electricityProvider &&
                        this.state.electricityProvider.value &&
                        this.state.electricityProvider.value != 'Other'
                            ? this.state.electricityProvider.value
                            : this.state.electricityProvider &&
                              this.state.electricityProvider.value &&
                              this.state.electricityProvider.value == 'Other'
                            ? this.state.otherElectricityProvider
                            : '',
                    price: this.state.electricityAmount,
                    account_number: this.state.utilityAccountNumber,
                    move: this.state.moveId,
                };
                let payload2 = {
                    service_id: serviceId,
                    provider:
                        this.state.gasProvider &&
                        this.state.gasProvider.value &&
                        this.state.gasProvider.value != 'Other'
                            ? this.state.gasProvider.value
                            : this.state.gasProvider &&
                              this.state.gasProvider.value &&
                              this.state.gasProvider.value == 'Other'
                            ? this.state.otherGasProvider
                            : '',
                    price: this.state.gasAmount,
                    account_number: this.state.utilityAccountNumber,
                    move: this.state.moveId,
                };
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.props.dashboardAction.postSubscriptionDetails(payload2);
                this.setState({ utilitiesClicked: true });
            }
        }
    };

    onSkip = () => {
        if (this.state.activeStep == 1) {
            if ((this.state.showEnergy && this.state.showSolar) || (this.state.showEnergy && !this.state.showSolar)) {
                this.setState({ activeStep: 2 });
            } else if (!this.state.showEnergy && this.state.showSolar) {
                this.setState({ activeStep: 3 });
            } else if (!this.state.showEnergy && !this.state.showSolar) {
                this.setState({ activeStep: 4 });
            }
            if (!this.state.showEnergy && !this.state.showSolar && !this.state.showUtilities) {
                this.props.history.push('/dashboardhomeowner');
            }
        }
        if (this.state.activeStep == 2) {
            if (this.state.showSolar) {
                this.setState({ activeStep: 3 });
            } else {
                this.setState({ activeStep: 4 });
            }
            if (!this.state.showSolar && !this.state.showUtilities) {
                this.props.history.push('/dashboardhomeowner');
            }
        }
        if (this.state.activeStep == 3) {
            this.setState({ activeStep: 4 });
            if (!this.state.showUtilities) {
                this.props.history.push('/dashboardhomeowner');
            }
        }
        if (this.state.activeStep == 4) {
            this.props.history.push('/dashboardhomeowner');
        }
    };
    render() {
        window.onresize = this.setSize;

        let energyServiceOptions: any = [];
        this.state.energyServiceOptions.map((item: any) => {
            if (this.state.deregulatedState.includes(item.label)) {
                energyServiceOptions.push(item);
            }
        });
        if (energyServiceOptions && energyServiceOptions.length) {
            this.state.energyServiceOptions = energyServiceOptions;
        }

        let showInternet: boolean = false;
        let showEnergy: boolean = false;
        let showSolar: boolean = false;
        let showUtilities: boolean = false;
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeUtilityServiceList &&
            this.props.history.location.state.homeUtilityServiceList.map((item: any) => {
                if (item.slug === 'internet_cable') {
                    showInternet = true;
                }
                if (item.slug === 'energy-savings') {
                    showEnergy = true;
                }
                if (item.slug === 'solar') {
                    showSolar = true;
                }
                if (item.slug === 'utility_providers') {
                    showUtilities = true;
                }
            });
        return (
            <div className="modalMainFlexDiv">
                <div className="modalMainLeftDiv">
                    <div className="imageDiv">
                        <img
                            src={
                                this.state.activeStep === 1
                                    ? internetSubscriptionImage
                                    : this.state.activeStep == 2
                                    ? energySubscriptionImage
                                    : this.state.activeStep == 3
                                    ? solarSubscriptionImage
                                    : this.state.activeStep == 4
                                    ? energySubscriptionImage
                                    : ''
                            }
                        />
                    </div>
                    <div className="leftTitle">
                        {this.state.activeStep === 1
                            ? 'Internet & Cable'
                            : this.state.activeStep == 2
                            ? 'Energy Savings'
                            : this.state.activeStep == 3
                            ? 'Solar'
                            : this.state.activeStep == 4
                            ? 'Utility Provider'
                            : ''}
                    </div>
                    <div className="leftDescription">
                        {this.state.activeStep === 1
                            ? 'Our team can compare options to get you better deals on internet & cable in your area'
                            : this.state.activeStep == 2
                            ? '“If you are in a deregulated state, you may quality for savings in electricity and gas. Our team can help you through the process.'
                            : this.state.activeStep == 3
                            ? 'Not only do we have 1700 worth free equipment. On top of that, you may qualify savings on other aspects for your home.'
                            : this.state.activeStep == 4
                            ? 'You are in deregulated state, so you may qualify for lot of savings on Electricity & Gas. Our team can help you out end to end on that aspect.'
                            : ''}
                    </div>
                </div>
                <div className="HomeOwnerDashboardModalMainDiv">
                    <div style={{ width: '100%' }}>
                        <ArrowBackIcon
                            onClick={() => {
                                // this.props.closeModal
                                this.props.history.push('/dashboardhomeowner');
                            }}
                        />
                    </div>
                    <div className="HomeOwnerDashboardModalTitle">
                        {this.state.size > 600
                            ? 'Fill your subscription details for home utility'
                            : 'Home Utilities Subscription Details'}
                    </div>

                    {/* {window.screen.width > 600 ? null : ( */}
                    {this.state.showInternet ? (
                        <div className="stepperHeaderMainFlexDiv">
                            <div className="stepperHeaderMainFlexDiv">
                                {this.state.activeStep > 1 ? (
                                    <CheckCircleIcon className="checkIconForm" />
                                ) : (
                                    <div
                                        className="circleNumber"
                                        style={{ background: this.state.activeStep === 1 ? '#006DFF' : '#C6C6C6' }}
                                    >
                                        1
                                    </div>
                                )}

                                <div className="stepperHeaderSecondFlexDiv">
                                    <div
                                        className="steppertitle"
                                        style={{ color: this.state.activeStep >= 1 ? '#333333' : '#6B6C6F' }}
                                    >
                                        Internet & Cable
                                    </div>
                                    <div className="steppersubtitle">
                                        {this.state.activeStep > 1
                                            ? 'Service Provider Selected'
                                            : 'Select Service Provider'}
                                    </div>
                                </div>
                            </div>
                            {/* <div>{this.state.activeStep === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                        </div>
                    ) : null}
                    {/* )} */}

                    {/* internetForm */}
                    {this.state.activeStep === 1 && showInternet ? (
                        <div className="HomeOwnerDashboardformDiv">
                            <div className="formTitleFlexDiv">
                                <div className="titleServiceName">Internet & Cable</div>
                                {/* <div className="imnotsure">I am not sure</div> */}
                            </div>
                            {this.state.internetProviderFormDetails.map((form: any, index: number) => {
                                return (
                                    <div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <SelectInput
                                                        label={'Which provider are you using?'}
                                                        value={form.internetProvider && form.internetProvider.value}
                                                        onChange={(e: any) =>
                                                            this.onChangeInternet(e, 'internetProvider', index)
                                                        }
                                                        placeholder={
                                                            form.internetProvider && form.internetProvider.label
                                                                ? form.internetProvider.label
                                                                : 'Select internet provider'
                                                        }
                                                        options={this.state.internetProviderOptions}
                                                        // inputLa
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClicked && form.internetProvider == null
                                                            ? 'Select Internet Provider'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {form.internetProvider &&
                                            form.internetProvider.value &&
                                            form.internetProvider.value == 'other' ? (
                                                <div className="inputFlexDiv2">
                                                    <div className="inputDiv1">
                                                        <CustomizedInput
                                                            labelName="Enter provider name"
                                                            value={form.otherProvider}
                                                            onChange={(e: any) =>
                                                                this.onChangeInternet(e, 'otherProvider', index)
                                                            }
                                                            placeholder=""
                                                        />
                                                        <div className="error">
                                                            {this.state.continueClicked && !form.otherProvider
                                                                ? 'Enter Internet Provider'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputDiv2">
                                                <InputLabel
                                                    htmlFor="bootstrap-input"
                                                    className={'text-black ml-2 mb-2'}
                                                    style={{ fontFamily: 'Poppins', color: '#162855' }}
                                                >
                                                    What services do you have with them?
                                                </InputLabel>
                                                <div className="inputFlexDiv1">
                                                    <div
                                                        className="internetServiceCardOuterDiv"
                                                        style={{
                                                            border: form.isInternet
                                                                ? '1px solid #273E59'
                                                                : '1px solid #BEBEBE',
                                                            background: form.isInternet ? '#273E59' : '#ffffff',
                                                            color: form.isInternet ? '#ffffff' : '#333333',
                                                        }}
                                                    >
                                                        <div
                                                            className="internetServiceCard"
                                                            onClick={() => {
                                                                this.internetService('isInternet', index);
                                                                // this.setState({ isInternet: !form.isInternet });
                                                            }}
                                                        >
                                                            <WifiIcon className="internetIcon" />
                                                            Internet
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="internetServiceCardOuterDiv"
                                                        style={{
                                                            border: form.isTV
                                                                ? '1px solid #273E59'
                                                                : '1px solid #BEBEBE',
                                                            background: form.isTV ? '#273E59' : '#ffffff',
                                                            color: form.isTV ? '#ffffff' : '#333333',
                                                        }}
                                                    >
                                                        <div
                                                            className="internetServiceCard"
                                                            onClick={() => {
                                                                this.internetService('isTV', index);
                                                                // this.setState({ isTV: !form.isTV });
                                                            }}
                                                        >
                                                            <LaptopWindowsIcon className="internetIcon" />
                                                            TV
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="internetServiceCardOuterDiv"
                                                        style={{
                                                            border: form.isPhone
                                                                ? '1px solid #273E59'
                                                                : '1px solid #BEBEBE',
                                                            background: form.isPhone ? '#273E59' : '#ffffff',
                                                            color: form.isPhone ? '#ffffff' : '#333333',
                                                        }}
                                                    >
                                                        <div
                                                            className="internetServiceCard"
                                                            onClick={() => {
                                                                this.internetService('isPhone', index);
                                                                // this.setState({ isPhone: !form.isPhone });
                                                            }}
                                                        >
                                                            <PhoneInTalkIcon className="internetIcon" />
                                                            Phone
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="error">
                                                    {this.state.continueClicked &&
                                                    !form.isInternet &&
                                                    !form.isTV &&
                                                    !form.isPhone
                                                        ? 'Select Internet Service'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="How much are you paying?"
                                                        value={form.internetAmount}
                                                        onChange={(e: any) =>
                                                            this.onChangeInternet(e, 'internetAmount', index)
                                                        }
                                                        placeholder=""
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">$</InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClicked && !form.internetAmount
                                                            ? 'Enter Amount'
                                                            : this.state.continueClicked &&
                                                              form.internetAmount &&
                                                              parseInt(form.internetAmount) <= 0
                                                            ? 'Amount must be greater than 0'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="inputsupportText">/month</div>
                                            </div>
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="Your Account Number"
                                                        value={form.internetAccountNumber}
                                                        onChange={(e: any) =>
                                                            this.onChangeInternet(e, 'internetAccountNumber', index)
                                                        }
                                                        placeholder=""
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        // }}
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClicked && !form.internetAccountNumber
                                                            ? 'Enter Account Number'
                                                            : this.state.continueClicked &&
                                                              form.internetAccountNumber.length < 4
                                                            ? 'Account number should be atleast 4 digits'
                                                            : this.state.continueClicked &&
                                                              form.internetAccountNumber &&
                                                              parseInt(form.internetAccountNumber) <= 0
                                                            ? 'invalid account number'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="formTitleFlexDiv">
                                <div>
                                    {this.state.internetProviderFormDetails.length < 3 ? (
                                        <div className="imnotsure" onClick={this.addAnotherInternetForm}>
                                            Add another provider
                                        </div>
                                    ) : (
                                        <div className="imnotsure" onClick={this.addAnotherInternetForm}></div>
                                    )}
                                </div>
                                <div>
                                    {this.state.internetId ? null : (
                                        <span className="skipButton" onClick={this.onSkip}>
                                            Skip
                                        </span>
                                    )}
                                </div>
                            </div>
                            {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    onClick={() => {
                                        this.internetPostSubcriptionDetails();
                                    }}
                                >
                                    Next
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                            <Button
                                className="DashboardUtilitiesSubscriptionFormButton"
                                onClick={() => {
                                    this.internetPostSubcriptionDetails();
                                }}
                                disabled={this.state.internetClicked}
                            >
                                Next
                            </Button>
                        </div>
                    ) : null}

                    {this.state.showEnergy ? <div className="lineDiv"></div> : null}

                    {/* {window.screen.width > 600 ? null : ( */}
                    {this.state.showEnergy ? (
                        <div className="stepperHeaderMainFlexDiv">
                            <div className="stepperHeaderMainFlexDiv">
                                {this.state.activeStep > 2 ? (
                                    <CheckCircleIcon className="checkIconForm" />
                                ) : (
                                    <div
                                        className="circleNumber"
                                        style={{ background: this.state.activeStep === 2 ? '#006DFF' : '#C6C6C6' }}
                                    >
                                        {this.state.showInternet ? '2' : !this.state.showInternet ? '1' : '1'}
                                    </div>
                                )}
                                <div className="stepperHeaderSecondFlexDiv">
                                    <div
                                        className="steppertitle"
                                        style={{ color: this.state.activeStep >= 2 ? '#333333' : '#6B6C6F' }}
                                    >
                                        Energy Savings
                                    </div>
                                    <div className="steppersubtitle">
                                        {this.state.activeStep > 2
                                            ? 'Service Provider Selected'
                                            : 'Select Service Provider'}
                                    </div>
                                </div>
                            </div>
                            {/* <div>{this.state.activeStep === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                        </div>
                    ) : null}
                    {/* )} */}

                    {/* energyForm */}
                    {this.state.activeStep === 2 && showEnergy ? (
                        <div className="HomeOwnerDashboardformDiv">
                            <div className="formTitleFlexDiv">
                                <div className="titleServiceName">Energy Savings</div>
                                {/* <div className="imnotsure">I am not sure</div> */}
                            </div>
                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv">
                                        <InputLabel
                                            htmlFor="bootstrap-input"
                                            className={'text-black ml-2 mb-2'}
                                            style={{ fontFamily: 'Poppins', color: '#162855' }}
                                        >
                                            Are you using an alternate energy provider
                                        </InputLabel>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="gender"
                                                name="row-radio-buttons-group"
                                                value={this.state.energyIsAlternateProvider}
                                                onChange={(e: any) => this.radioChange(e, 'energyIsAlternateProvider')}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            {this.state.energyIsAlternateProvider == 'yes' ? (
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <SelectInput
                                                label={'What service are you using?'}
                                                value={this.state.energyService && this.state.energyService.value}
                                                onChange={(e: any) => this.onSelect(e, 'energyService')}
                                                placeholder={
                                                    this.state.energyService && this.state.energyService.label
                                                        ? this.state.energyService.label
                                                        : 'Select energy service'
                                                }
                                                options={this.state.energyServiceOptions}
                                                // inputLa
                                            />
                                            <div className="error">
                                                {this.state.continueClickedEnergy &&
                                                this.state.energyService == null &&
                                                this.state.energyService !== ''
                                                    ? 'Select Energy Service'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <SelectInput
                                                label={'What provider are you using?'}
                                                value={this.state.energyProvider && this.state.energyProvider.value}
                                                onChange={(e: any) => this.onSelect(e, 'energyProvider')}
                                                placeholder={
                                                    this.state.energyProvider && this.state.energyProvider.label
                                                        ? this.state.energyProvider.label
                                                        : 'Select energy provider'
                                                }
                                                options={this.state.energyProviderOptions}
                                                // inputLa
                                            />
                                            <div className="error">
                                                {this.state.continueClickedEnergy &&
                                                this.state.energyProvider == null &&
                                                this.state.energyProvider !== ''
                                                    ? 'Select Energy Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {this.state.energyIsAlternateProvider == 'yes' ? (
                                this.state.energyProvider &&
                                this.state.energyProvider.value &&
                                this.state.energyProvider.value == 'Other' ? (
                                    <div className="inputFlexDiv1">
                                        <div className="inputFlexDiv2">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="Enter provider name"
                                                    value={this.state.energyOtherProvider}
                                                    onChange={(e: any) => this.onChange(e, 'energyOtherProvider')}
                                                    placeholder=""
                                                />
                                                <div className="error">
                                                    {this.state.continueClickedEnergy && !this.state.energyOtherProvider
                                                        ? 'Enter Energy Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            ) : null}

                            {this.state.energyIsAlternateProvider == 'yes' ? (
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="How much are you paying?"
                                                value={this.state.energyAmount}
                                                onChange={(e: any) => this.onChange(e, 'energyAmount')}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedEnergy && !this.state.energyAmount
                                                    ? 'Enter Amount'
                                                    : this.state.continueClickedEnergy &&
                                                      this.state.energyAmount &&
                                                      parseInt(this.state.energyAmount) <= 0
                                                    ? 'Amount must be greater than 0'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="inputsupportText">/month</div>
                                    </div>
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <InputLabel
                                                htmlFor="bootstrap-input"
                                                className={'text-black ml-2 mb-2 mt-3'}
                                                style={{ fontFamily: 'Poppins', color: '#162855' }}
                                            >
                                                Enter Expiration Date
                                            </InputLabel>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    // disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Select date"
                                                    className="date11"
                                                    value={
                                                        this.state.energyExpirationDate
                                                            ? new Date(this.state.energyExpirationDate)
                                                            : null
                                                    }
                                                    onChange={(e: any) => this.setState({ energyExpirationDate: e })}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    autoOk={true}
                                                />
                                            </MuiPickersUtilsProvider>
                                            {/* <CustomizedInput
                                            labelName="Enter expiration date"
                                            value={this.state.energyExpirationDate}
                                            onChange={(e: any) => this.onChange(e, 'energyExpirationDate')}
                                            placeholder="dd/mm/yy"
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            // }}
                                        /> */}
                                            <div className="error">
                                                {this.state.continueClickedEnergy && !this.state.energyExpirationDate
                                                    ? 'Enter Expiration date'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* {this.state.energyIsAlternateProvider == 'yes' ? ( */}
                            <div className="formTitleFlexDiv">
                                <div className="imnotsure"></div>
                                <div>
                                    {this.state.energyId ? null : (
                                        <span className="skipButton" onClick={this.onSkip}>
                                            Skip
                                        </span>
                                    )}
                                    <Button
                                        className="DashboardUtilitiesSubscriptionFormButton"
                                        onClick={() => this.enrgyPostSubcriptionDetails()}
                                        disabled={this.state.energyClicked}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                            {/* ) : null} */}
                            {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    onClick={() =>
                                        // this.setState({ activeStep: 3 })
                                        this.enrgyPostSubcriptionDetails()
                                    }
                                >
                                    Next
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                        </div>
                    ) : null}

                    {this.state.showSolar ? <div className="lineDiv"></div> : null}

                    {/* {window.screen.width > 600 ? null : ( */}
                    {this.state.showSolar ? (
                        <div className="stepperHeaderMainFlexDiv">
                            <div className="stepperHeaderMainFlexDiv">
                                {this.state.activeStep > 3 ? (
                                    <CheckCircleIcon className="checkIconForm" />
                                ) : (
                                    <div
                                        className="circleNumber"
                                        style={{ background: this.state.activeStep === 3 ? '#006DFF' : '#C6C6C6' }}
                                    >
                                        {this.state.showInternet && this.state.showEnergy
                                            ? '3'
                                            : (this.state.showInternet && !this.state.showEnergy) ||
                                              (!this.state.showInternet && this.state.showEnergy)
                                            ? '2'
                                            : !this.state.showInternet && !this.state.showEnergy
                                            ? '1'
                                            : '1'}
                                    </div>
                                )}
                                <div className="stepperHeaderSecondFlexDiv">
                                    <div
                                        className="steppertitle"
                                        style={{ color: this.state.activeStep >= 3 ? '#333333' : '#6B6C6F' }}
                                    >
                                        Solar
                                    </div>
                                    <div className="steppersubtitle">
                                        {this.state.activeStep > 3
                                            ? 'Service Provider Selected'
                                            : 'Select Service Provider'}
                                    </div>
                                </div>
                            </div>
                            {/* <div>{this.state.activeStep === 3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                        </div>
                    ) : null}
                    {/* )} */}

                    {/* solarForm */}
                    {this.state.activeStep === 3 && showSolar ? (
                        <div className="HomeOwnerDashboardformDiv">
                            <div className="formTitleFlexDiv">
                                <div className="titleServiceName">Solar</div>
                                {/* <div className="imnotsure">I am not sure</div> */}
                            </div>
                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv">
                                        <InputLabel
                                            htmlFor="bootstrap-input"
                                            className={'text-black ml-2 mb-2'}
                                            style={{ fontFamily: 'Poppins', color: '#162855' }}
                                        >
                                            Have you installed Solar Panels for your home?
                                        </InputLabel>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="gender"
                                                name="row-radio-buttons-group"
                                                value={this.state.solarInstallationDone}
                                                onChange={(e: any) => this.radioChange(e, 'solarInstallationDone')}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        <div className="error">
                                            {this.state.continueClickedSolar && !this.state.solarInstallationDone
                                                ? 'Select Solar installation option'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv">
                                        <InputLabel
                                            htmlFor="bootstrap-input"
                                            className={'text-black ml-2 mb-2'}
                                            style={{ fontFamily: 'Poppins', color: '#162855' }}
                                        >
                                            Are you interested in learning about Solar Energy?
                                        </InputLabel>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="gender"
                                                name="row-radio-buttons-group"
                                                value={this.state.solarIntrestedInLearning}
                                                onChange={(e: any) => this.radioChange(e, 'solarIntrestedInLearning')}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        <div className="error">
                                            {this.state.continueClickedSolar && !this.state.solarIntrestedInLearning
                                                ? 'Select Solar learning option'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="formTitleFlexDiv">
                                <div className="imnotsure"></div>
                                <div>
                                    {this.state.solarId ? null : (
                                        <span className="skipButton" onClick={this.onSkip}>
                                            Skip
                                        </span>
                                    )}
                                    <Button
                                        className="DashboardUtilitiesSubscriptionFormButton"
                                        onClick={() => this.solarPostSubcriptionDetails()}
                                        disabled={this.state.solarClicked}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                            {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    onClick={
                                        () => this.solarPostSubcriptionDetails()
                                        // this.setState({activeStep: 4 })
                                    }
                                >
                                    Next
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                        </div>
                    ) : null}

                    {this.state.showUtilities ? <div className="lineDiv"></div> : null}
                    {/* {window.screen.width > 600 ? null : ( */}
                    {this.state.showUtilities ? (
                        <div className="stepperHeaderMainFlexDiv">
                            <div className="stepperHeaderMainFlexDiv">
                                {this.state.activeStep > 4 ? (
                                    <CheckCircleIcon className="checkIconForm" />
                                ) : (
                                    <div
                                        className="circleNumber"
                                        style={{ background: this.state.activeStep === 4 ? '#006DFF' : '#C6C6C6' }}
                                    >
                                        {this.state.showInternet &&
                                        this.state.showEnergy &&
                                        this.state.showSolar &&
                                        this.state.showUtilities
                                            ? '4'
                                            : (this.state.showInternet &&
                                                  !this.state.showEnergy &&
                                                  this.state.showSolar &&
                                                  this.state.showUtilities) ||
                                              (this.state.showInternet &&
                                                  this.state.showEnergy &&
                                                  !this.state.showSolar &&
                                                  this.state.showUtilities) ||
                                              (!this.state.showInternet &&
                                                  this.state.showEnergy &&
                                                  this.state.showSolar &&
                                                  this.state.showUtilities)
                                            ? '3'
                                            : (this.state.showInternet &&
                                                  !this.state.showEnergy &&
                                                  !this.state.showSolar &&
                                                  this.state.showUtilities) ||
                                              (!this.state.showInternet &&
                                                  !this.state.showEnergy &&
                                                  this.state.showSolar &&
                                                  this.state.showUtilities)
                                            ? '2'
                                            : !this.state.showInternet &&
                                              !this.state.showEnergy &&
                                              !this.state.showSolar &&
                                              this.state.showUtilities
                                            ? '1'
                                            : '1'}
                                    </div>
                                )}
                                <div className="stepperHeaderSecondFlexDiv">
                                    <div
                                        className="steppertitle"
                                        style={{ color: this.state.activeStep >= 4 ? '#333333' : '#6B6C6F' }}
                                    >
                                        Utility Provider
                                    </div>
                                    <div className="steppersubtitle">
                                        {this.state.activeStep > 4
                                            ? 'Service Provider Selected'
                                            : 'Select Service Provider'}
                                    </div>
                                </div>
                            </div>
                            {/* <div>{this.state.activeStep === 4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                        </div>
                    ) : null}
                    {/* )} */}

                    {/* UtilityForm */}
                    {this.state.activeStep === 4 && showUtilities ? (
                        <div className="HomeOwnerDashboardformDiv">
                            <div className="formTitleFlexDiv">
                                <div className="titleServiceName">Utilities</div>
                                {/* <div className="imnotsure">I am not sure</div> */}
                            </div>
                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <SelectInput
                                            label={'Select Electricity Provider'}
                                            value={
                                                this.state.electricityProvider && this.state.electricityProvider.value
                                            }
                                            onChange={(e: any) => this.onSelect(e, 'electricityProvider')}
                                            placeholder={
                                                this.state.electricityProvider && this.state.electricityProvider.label
                                                    ? this.state.electricityProvider.label
                                                    : 'Select electricity provider'
                                            }
                                            options={this.state.electricityProviderOptions}
                                            // inputLa
                                        />
                                        <div className="error">
                                            {this.state.continueClickedUtility && this.state.electricityProvider == null
                                                ? 'Select Electricity Provider'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <CustomizedInput
                                            labelName="Approximately how much are you paying?"
                                            value={this.state.electricityAmount}
                                            onChange={(e: any) => this.onChange(e, 'electricityAmount')}
                                            placeholder=""
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                        <div className="error">
                                            {this.state.continueClickedUtility && !this.state.electricityAmount
                                                ? 'Enter security amount'
                                                : this.state.continueClickedUtility &&
                                                  this.state.electricityAmount &&
                                                  parseInt(this.state.electricityAmount) <= 0
                                                ? 'Amount must be greater than 0'
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="inputsupportText">/month</div>
                                </div>
                            </div>

                            {this.state.electricityProvider &&
                            this.state.electricityProvider.value &&
                            this.state.electricityProvider.value == 'Other' ? (
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="Enter provider name"
                                                value={this.state.otherElectricityProvider}
                                                onChange={(e: any) => this.onChange(e, 'otherElectricityProvider')}
                                                placeholder=""
                                            />
                                            <div className="error">
                                                {this.state.continueClickedUtility &&
                                                !this.state.otherElectricityProvider
                                                    ? 'Enter Electricity Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <SelectInput
                                            label={'Select Gas Provider'}
                                            value={this.state.gasProvider && this.state.gasProvider.value}
                                            onChange={(e: any) => this.onSelect(e, 'gasProvider')}
                                            placeholder={
                                                this.state.gasProvider && this.state.gasProvider.label
                                                    ? this.state.gasProvider.label
                                                    : 'Select gas provider'
                                            }
                                            options={this.state.gasProviderOptions}
                                            // inputLa
                                        />
                                        <div className="error">
                                            {this.state.continueClickedUtility && this.state.gasProvider == null
                                                ? 'Select Gas Provider'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <CustomizedInput
                                            labelName="Approximately how much are you paying?"
                                            value={this.state.gasAmount}
                                            onChange={(e: any) => this.onChange(e, 'gasAmount')}
                                            placeholder=""
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                        <div className="error">
                                            {this.state.continueClickedUtility && !this.state.gasAmount
                                                ? 'Enter security amount'
                                                : this.state.continueClickedUtility &&
                                                  this.state.gasAmount &&
                                                  parseInt(this.state.gasAmount) <= 0
                                                ? 'Amount must be greater than 0'
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="inputsupportText">/month</div>
                                </div>
                            </div>
                            {this.state.gasProvider &&
                            this.state.gasProvider.value &&
                            this.state.gasProvider.value == 'Other' ? (
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="Enter provider name"
                                                value={this.state.otherGasProvider}
                                                onChange={(e: any) => this.onChange(e, 'otherGasProvider')}
                                                placeholder=""
                                            />
                                            <div className="error">
                                                {this.state.continueClickedUtility && !this.state.otherGasProvider
                                                    ? 'Enter Gas Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="inputFlexDiv1">
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <CustomizedInput
                                            labelName="Your Account Number"
                                            value={this.state.utilityAccountNumber}
                                            onChange={(e: any) => this.onChange(e, 'utilityAccountNumber')}
                                            placeholder=""
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            // }}
                                        />
                                        <div className="error">
                                            {this.state.continueClickedUtility && !this.state.utilityAccountNumber
                                                ? 'Enter security account number'
                                                : this.state.continueClickedUtility &&
                                                  this.state.utilityAccountNumber.length < 4
                                                ? 'Account number should be atleast 4 digits'
                                                : this.state.continueClickedUtility &&
                                                  this.state.utilityAccountNumber &&
                                                  parseInt(this.state.utilityAccountNumber) <= 0
                                                ? 'invalid account number'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="formTitleFlexDiv">
                                <div className="imnotsure"></div>
                                <div>
                                    {this.state.utilitiesId ? null : (
                                        <span className="skipButton" onClick={this.onSkip}>
                                            Skip
                                        </span>
                                    )}
                                    <Button
                                        className="DashboardUtilitiesSubscriptionFormButton"
                                        onClick={() => this.utilitiesPostSubcriptionDetails()}
                                        disabled={this.state.utilitiesClicked}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                            {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    // onClick={() => this.setState({ activeStep: 2 })}
                                    onClick={this.props.closeModal}
                                >
                                    Finish
                                </Button>
                            </div> */}
                        </div>
                    ) : null}

                    <div className="lineDiv"></div>

                    {/* <div className="HomeOwnerDashboardModalButtonDiv">
                    <Button className="HomeOwnerDashboardModalButton" onClick={this.props.closeModal}>
                        Submit
                    </Button>
                </div> */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        UtilityAction: bindActionCreators(UtilityAction, dispatch),
        energyAction: bindActionCreators(EnergyAction, dispatch),
        nextMoveAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    homeOwnerDashboardState: state.homeOwnerdashboard,
    serviceState: state.service,
    UtilityState: state.utility,
    energyState: state.energy,
    nextMoveState: state.nextMove,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOwnerDashboardUtilityForm);
