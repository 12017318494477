import React, { Component } from 'react';
import './standAloneThankYou.scss';
import RequestVendors from '../../Assets/images/TrustedPros/requestVendor.svg';
import Button from '../../components/atoms/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type initialProps = {
    history?: any;
};
type initialState = {
    serviceName: string;
};
export class StandAloneThankYou extends Component<initialProps, initialState> {
    state: initialState = {
        serviceName: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.serviceName
        ) {
            this.setState({
                serviceName: this.props.history.location.state.serviceName,
            });
        }
    }

    handleBackRequest = () => {
        this.props.history.push('/standalonepage');
    };

    render() {
        return (
            <div className="main-stand-alone">
                <div className="arrow-name-wrapper">
                    <div className="back-arrow" style={{ cursor: 'pointer' }}>
                        <ArrowBackIcon
                            onClick={() => {
                                this.props.history.push('/standalonepage');
                            }}
                        />
                    </div>
                    <div className="service-name-div">{this.state.serviceName}</div>
                </div>
                <div className="center-div">
                    <div className="request-image-div">
                        <img src={RequestVendors} />
                    </div>
                    <div className="thank-you-text">Your Appointment is Booked Successfully!</div>
                    <div className="back-to-pros-button">
                        <Button onClick={this.handleBackRequest}>Search Pros</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default StandAloneThankYou;
