import React from 'react';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
// import Rating from '@material-ui/lab/Rating';
// import sideTag from '../../Assets/images/TrustedPros/sideTagNew.svg';
// import bottomLogos from '../../Assets/images/TrustedPros/bottomLogos.png';
import '../../styles/trustedProsStandAlone.scss';
import tick from '../../Assets/images/TrustedPros/tick.svg';
import mail from '../../Assets/images/TrustedPros/message.svg';
// import homeAdvisorImage from '../../Assets/images/TrustedPros/homeAdvisorImage.png'

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    boxShadow?: any;
    checked: boolean;
    handleChange: any;
    rate?: number;
    ratingIcon?: any;
    verifiedReview?: any;
    phoneIcon?: any;
    phoneNumber?: string;
    locationIcon?: any;
    locationtext?: string;
    subHeading?: string;
    logoInitials?: string;
    imageBackGround?: string;
    homeAdvisor?: boolean;
    representation?: any;
    emailIcon?: any;
    email?: any;
    brokerage_image?: any;
}

const TrustedProsStandAloneCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
            checkroot: {},
        },
    }));

    const classes = useStyles();
    let splitArray: any = props.representation?.split(' ') ? props.representation?.split(' ') : props.representation;
    let splitAgent: any = props.homeAdvisor ? props.representation : splitArray[0];
    return (
        <div
            style={{
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                border: props.border ? props.border : props.checked ? '1px solid #002E62' : '',
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '20px 0px',
                padding: props.cardPadding ? props.cardPadding : '0px',
                cursor: 'pointer',
            }}
            onClick={props.handleChange}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} trusted-card-body`}
        >
            <div className="card-main-trusted">
                <div className="checkbox-div">
                    <Checkbox
                        checked={props.checked}
                        color="default"
                        style={{ color: props.checked ? '#273E59' : '#BEBEBE' }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <div className="right-div">
                    <div className="div-1-trusted">
                        <div
                            className="image-trusted"
                            style={{ backgroundColor: props.imageBackGround ? props.imageBackGround : '' }}
                        >
                            <div className="logo-initials">{props.logoInitials}</div>
                        </div>
                        <div className="heading-capsule-reviews-trusted">
                            <div className="heading-trusted">{props.cardTitle}</div>
                            <div className="div-3-trusted">
                                <div className="phone-address-trusted">
                                    {props.phoneNumber ? (
                                        <div style={{ display: 'flex' }} className="phoneAddress">
                                            <img src={props.phoneIcon} />
                                            <p className="bottom-card-detail">{props.phoneNumber}</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {props.locationtext ? (
                                        <div style={{ display: 'flex', marginTop: '0 px' }}>
                                            <img src={props.locationIcon} />
                                            <p className="bottom-card-detail">{props.locationtext}</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {props.email ? (
                                        <div style={{ display: 'flex', marginTop: '0 px' }}>
                                            <div> {props.emailIcon} </div>
                                            <div className="bottom-card-detail">{props.email}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className="capsule-reviews-trusted">
                                {props.rate ? (
                                    <div className="capsule-trusted">
                                        <div className="capsule-trusted-image-div">
                                            {' '}
                                            <img src={props.ratingIcon} />
                                        </div>
                                        <div className="rate-trusted"> {props.rate}</div>
                                    </div>
                                ) : null}
                                {/* <div className="reviews-trusted">Read Reviews</div> */}
                            </div>
                        </div>
                        <div className="verified-trusted-new">
                            {props.representation == 'Agent Preferred' ? (
                                <div className="verified-trusted-new1">
                                    <img src={tick} style={{ paddingRight: '17px', paddingTop: '24px' }} />
                                    <div
                                        className="representation"
                                        style={{ paddingRight: '31px', paddingTop: '24px' }}
                                    >
                                        {props.representation ? `${splitAgent}` : null}
                                        <div></div>
                                        <span style={{ fontWeight: 'bold', color: '#002E62' }}>PREFFERED</span>
                                    </div>
                                </div>
                            ) : props.representation == 'Brokerage Preferred' ? (
                                <div className="verified-trusted-new1">
                                    <div
                                        className="representation"
                                        style={{ paddingRight: '31px', paddingTop: '24px' }}
                                    >
                                        {props.representation ? (
                                            <img src={props.brokerage_image} style={{ width: '100%' }} />
                                        ) : null}
                                        <div></div>
                                        <span style={{ fontWeight: 'bold', color: '#002E62' }}>PREFFERED</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="verified-trusted-new1">
                                    <img src={tick} style={{ paddingRight: '17px', paddingTop: '24px' }} />
                                    <div
                                        className="representation"
                                        style={{ paddingRight: '31px', paddingTop: '24px' }}
                                    >
                                        {props.representation ? `${splitAgent}` : null}
                                        <div></div>
                                        <span style={{ fontWeight: 'bold', color: '#002E62' }}>PREFFERED</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mailStyle">
                        <img src={mail} style={{ paddingRight: '66px' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustedProsStandAloneCard;
