import React, { Component } from 'react';
import './ElectricityGasMainCard.scss';
import ButtonNew from '../atoms/Button';
// import ProgressBar from 'react-bootstrap/esm/ProgressBar.js';
import LinearProgress from '@material-ui/core/LinearProgress';

type initialProps = {
    GasElect: string;
    icon: any;
    handleNext: any;
    progress?: number;
};
type initialState = {};

export class ElectricityGasMain extends Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                className="ElectricityClassMainCard"
                style={{
                    background: slug && slug.primary_color ? slug.primary_color : '#002e62',
                    borderColor: slug && slug.primary_color ? slug.primary_color : '#002e62',
                }}
            >
                <div className="ElectricityGasClass">
                    <div className="ElectricityGasHead">
                        <span className="iconMarginRight">
                            <img src={this.props.icon} />
                        </span>
                        {this.props.GasElect}
                    </div>
                    <div className="ElectricityGasInfo">
                        Save on {`${this.props.GasElect}`} by switching to an alternate provider
                    </div>
                    <div className="ElectricityGasProgressButton">
                        <div className="linearStartingProgress">
                            <LinearProgress variant="determinate" value={this.props.progress} />
                            {this.props.progress}% completed
                            {/* <span className="electricityGasUsed">{this.props.progress}%completed</span> */}
                        </div>
                        <div className="buttonElectric">
                            <ButtonNew
                                backgroundColor="#fff"
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#002e62'
                                }
                                borderRadius="4px"
                                onClick={this.props.handleNext}
                            >
                                Get Started
                            </ButtonNew>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElectricityGasMain;
