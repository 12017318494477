import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import { CircularProgress, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import './utilitySavings.scss';
import Button from '../../components/atoms/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
// import constilation from '../../Assets/images/utilityImages/constilation.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FinalPlan from '../../components/Utility/FinalPlan';
// import Box from '@material-ui/core/Box';
// import LinearProgress from '@material-ui/core/LinearProgress';
import * as EnergyAction from '../../store/energy/actions';
import * as CommonAction from '../../store/common/actions';
import * as SignupAction from '../../store/login/signup/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import SelectInput from '../../components/atoms/SelectInput';
import { uiConfig } from '../../config';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
    commonAction?: any
    signupAction?: any;
    commonState?: any;
    signupState?: any;
};
type initialState = {
    slug: any;
    activeStep: number;
    totalSteps: number;
    name: string;
    validateName: boolean;
    lastName: string;
    validateLastName: boolean;
    phoneNumber: string;
    emailAddress: string;
    validateEmail: boolean;
    uniqueEmailAddress: boolean;
    date: any;
    anchorEl: any;
    anchorEl1: any;
    state: string;
    zipCode: string;
    city: string;
    streetAddress1: string;
    mailingState: string;
    mailingZipCode: string;
    mailingCity: string;
    mailingStreetAddress1: string;
    checkedMailingAddress: boolean;
    accountNumber: string;
    // description: string;
    showMore: boolean;
    checked: boolean;
    serviceTypeId: any;
    selectedCompany: any;
    usage: any;
    selectedPlan: any;
    continueClick: boolean;
    isGetMove: boolean;
    switchTypeOptions: any;
    moveId: string;
    houseOption: string;
    switchType: any;
    moveIntype: any;
    moveIntypeOptions: any;
    supplierId: string;
    serviceTypeName: string;
    accountFormat: any;
    accountFormatLength: any;
    accountError: any;
    showMeterNum: boolean;
    meterNumber: any;
    meterNumberFormat: any;
    showNameKey: boolean;
    nameKey: any;
    nameKeyFormat: any;
    showReferenceNum: boolean;
    referenceNumber: any;
    referenceNumberFormat: any;
    showDepositDisclaimer: boolean;
    depositDisclaimer: any;
    showDob: boolean;
    dob: any;
    showSsn: boolean;
    ssn: any;
    showTaxExemptReasons: boolean;
    taxExemptReasons: any;
    showTaxId: boolean;
    taxId: any;
    taxExemptReasonsOptions: any;
    disclaimer: any;
    loader: boolean;
    cstDateTime: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
    signupState?: any;
    isAnalitycsTriggerredOnce: any;
    isFormStarted: boolean;
};

export class utilitySavings extends Component<initialProps, initialState> {
    state: initialState = {
        slug: { primary_color: '' },
        activeStep: 0,
        totalSteps: 4,
        validateName: true,
        name: '',
        validateLastName: true,
        lastName: '',
        phoneNumber: '',
        validateEmail: true,
        emailAddress: '',
        uniqueEmailAddress: true,
        date: moment().format(),
        anchorEl: null,
        anchorEl1: null,
        state: '',
        zipCode: '',
        city: '',
        streetAddress1: '',
        mailingState: '',
        mailingZipCode: '',
        mailingCity: '',
        mailingStreetAddress1: '',
        checkedMailingAddress: true,
        accountNumber: '',
        // description:
        //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum",
        showMore: false,
        checked: true,
        serviceTypeId: '',
        selectedCompany: '',
        usage: '',
        selectedPlan: null,
        continueClick: false,
        isGetMove: true,
        switchTypeOptions: [],
        moveId: '',
        houseOption: '',
        switchType: null,
        moveIntype: null,
        moveIntypeOptions: [],
        supplierId: '',
        serviceTypeName: '',
        accountFormat: '',
        accountFormatLength: 0,
        accountError: '',
        showMeterNum: false,
        meterNumber: '',
        meterNumberFormat: '',
        showNameKey: false,
        nameKey: '',
        nameKeyFormat: '',
        showReferenceNum: false,
        referenceNumber: '',
        referenceNumberFormat: '',
        showDepositDisclaimer: false,
        depositDisclaimer: '',
        showDob: false,
        dob: null,
        showSsn: false,
        ssn: '',
        showTaxExemptReasons: false,
        taxExemptReasons: null,
        showTaxId: false,
        taxId: '',
        taxExemptReasonsOptions: [
            {
                value: 'PERSONALRESIDENCEORVACATIONHOMEORTENANT',
                label: 'Personal Residence/Vacation home/Tenant',
            },
            { value: 'LANDLORD', label: 'Landlord' },
            { value: 'PARENTGUARDIAN', label: 'Parent/Guardian' },
        ],
        disclaimer: '',
        loader: false,
        cstDateTime: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
        isAnalitycsTriggerredOnce: {
            accountNumber: false,
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
            switchType: false,
            meterNumber: false,
            nameKey: false,
            referenceNumber: false,
            streetAddress1: false,
            city: false,
            state: false,
            zipCode: false,
            mailingStreetAddress1: false,
            mailingState: false,
            mailingZipCode: false
        },
        isFormStarted: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            slug: slug,
            brokerage_name: slug.display_name,
            brokerage_id: slug.brokerage_link,
            agent_id: slug.id,
            agent_name: slug.contact_name,
            activeStep: 0
        });

        let cstTime = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000 + 3600000 * -6);
        this.setState({ cstDateTime: cstTime });
        // let date1 = cstTime.setDate(cstTime.getDate() + 1);
        // // switchDate = new Date(date1).toLocaleString();
        // console.log(moment(date1).format('YYYY-MM-DDTHH:MM:SS'));
        
        let addressData: any = localStorage.getItem('pods_destination_address');
        if (addressData) {
            addressData = JSON.parse(addressData)
        }
        this.setState({ 
            streetAddress1: addressData ? addressData.street : "",
            city: addressData ? addressData.city : "",
            state: addressData ? addressData.state : "",
            zipCode: addressData ? addressData.zipcode : ""  
        });
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            this.setState({
                serviceTypeId:
                    this.props.history.location.state && this.props.history.location.state.serviceTypeId
                        ? this.props.history.location.state.serviceTypeId
                        : '',
                serviceTypeName:
                    this.props.history.location.state && this.props.history.location.state.serviceTypeName
                        ? this.props.history.location.state.serviceTypeName
                        : '',
                selectedCompany:
                    this.props.history.location.state && this.props.history.location.state.selectedCompany
                        ? this.props.history.location.state.selectedCompany
                        : [],
                usage:
                    this.props.history.location.state && this.props.history.location.state.usage
                        ? this.props.history.location.state.usage
                        : [],
                selectedPlan:
                    this.props.history.location.state && this.props.history.location.state.selectedPlan
                        ? this.props.history.location.state.selectedPlan
                        : [],
                accountFormat:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.accountNumFormat
                        ? new RegExp(
                              this.props.history.location.state.selectedCompany[0].utility_detail.accountNumFormat,
                          )
                        : '',
                accountFormatLength:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.accountNumLength
                        ? this.props.history.location.state.selectedCompany[0].utility_detail.accountNumLength
                        : 0,
                accountError:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.accountNumFormatHelp
                        ? this.props.history.location.state.selectedCompany[0].utility_detail.accountNumFormatHelp
                        : '',
                showMeterNum:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.showMeterNum,
                meterNumberFormat:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.meterNumFormat
                        ? new RegExp(this.props.history.location.state.selectedCompany[0].utility_detail.meterNumFormat)
                        : '',
                showNameKey:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.showNameKey,
                nameKeyFormat:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.nameKeyFormat
                        ? new RegExp(this.props.history.location.state.selectedCompany[0].utility_detail.nameKeyFormat)
                        : '',
                showReferenceNum:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.showReferenceNum,
                referenceNumberFormat:
                    this.props.history.location.state.selectedCompany &&
                    this.props.history.location.state.selectedCompany[0].utility_detail &&
                    this.props.history.location.state.selectedCompany[0].utility_detail.referenceNumFormat
                        ? new RegExp(
                              this.props.history.location.state.selectedCompany[0].utility_detail.referenceNumFormat,
                          )
                        : '',
                showDepositDisclaimer:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedPlan &&
                    this.props.history.location.state.selectedPlan.showDepositDisclaimer,
                showDob:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedPlan &&
                    this.props.history.location.state.selectedPlan.showDob,
                showSsn:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedPlan &&
                    this.props.history.location.state.selectedPlan.showSsn,
                showTaxExemptReasons:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedPlan &&
                    this.props.history.location.state.selectedPlan.showTaxExemptReasons,
                showTaxId:
                    this.props.history.location.state &&
                    this.props.history.location.state.selectedPlan &&
                    this.props.history.location.state.selectedPlan.showTaxId,
                taxId: '',
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        // console.log(currentState)
        if (nextProps && nextProps.commonState  && currentState.isGetMove) {
            currentState.isGetMove = false;
            let addressData: any = localStorage.getItem('pods_destination_address');
            nextProps.energyAction.getAccountNumber({
                address: addressData ? addressData: "",
            });
        }

        if (
            nextProps &&
            nextProps.energyState &&
            nextProps.energyState.getSwitchType &&
            nextProps.energyState.isGetSwitchType
        ) {
            nextProps.energyState.isGetSwitchType = false;
            let options: any = [];
            nextProps.energyState.getSwitchType.switch_type &&
                nextProps.energyState.getSwitchType.switch_type.map((item: any) => {
                    options.push({
                        label: item.switchTypeCode,
                        value: item.switchTypeCode,
                        item: item,
                    });
                });
            currentState.switchTypeOptions = options;
        }

        if (
            nextProps &&
            nextProps.energyState &&
            nextProps.energyState.postSubmitContract &&
            nextProps.energyState.isPostSubmitContract
        ) {
            nextProps.energyState.isPostSubmitContract = false;
            currentState.continueClick = false;
            currentState.loader = false;
            nextProps.history.push('/dashboard/energy-savings/utilitiesConfirmation');
        } else if (
            nextProps &&
            nextProps.energyState &&
            nextProps.energyState.postSubmitContractError &&
            nextProps.energyState.postSubmitContractError != ''
        ) {
            currentState.loader = false;
        }
        if (
            nextProps &&
            nextProps.energyState &&
            nextProps.energyState.getDisclaimerDetails &&
            nextProps.energyState.isGetDisclaimerDetails
        ) {
            nextProps.energyState.isGetDisclaimerDetails = false;
            currentState.disclaimer =
                nextProps.energyState.getDisclaimerDetails.message &&
                nextProps.energyState.getDisclaimerDetails.message.acknowledgements &&
                nextProps.energyState.getDisclaimerDetails.message.acknowledgements[0] &&
                nextProps.energyState.getDisclaimerDetails.message.acknowledgements[0].body
                    ? nextProps.energyState.getDisclaimerDetails.message.acknowledgements[0].body
                    : '';
        }

        if (
            nextProps &&
            nextProps.energyState &&
            nextProps.energyState.getAccountNumber &&
            nextProps.energyState.isGetAccountNumber
        ) {
            nextProps.energyState.isGetAccountNumber = false;
            currentState.accountNumber =
                nextProps.energyState.getAccountNumber.message &&
                nextProps.energyState.getAccountNumber.message.accountNumber
                    ? nextProps.energyState.getAccountNumber.message.accountNumber
                    : '';
        }

        if (
            nextProps &&
            nextProps.signupState 
        ) {
            nextProps.energyState.isGetAccountNumber = false;
            currentState.signupState =
                nextProps.signupState
                    ? nextProps.signupState
                    : '';
        }

        return currentState;
    }

    getSteps() {
        return ['Account Information', 'Personal Information', 'Service Address'];
    }
    nameHandler = (e: any) => {
        var regName =/^[a-zA-Z '.-]*$/;
        if(!regName.test(e.target.value)){
            this.setState({
                validateName: false,
            });
        }else{
            // alert('Valid name given.');
            this.setState({
                name: e.target.value,
                continueClick: false,
                validateName: true,
            });
        }

        this.inputAnalyticsTracking('First Name', 'firstName');
    };
    ssnHandler = (e: any) => {
        let ssn = this.formatSSNInput(e.target.value);
        this.setState({
            ssn: ssn,
            continueClick: false,
        });
    };
    taxIdHandler = (e: any) => {
        let taxId = e.target.value.replace(/\D/g, '');
        this.setState({
            taxId: taxId,
            continueClick: false,
        });
    };
    formatSSNInput = (ssn: string) => {
        let ssn_number = ssn;
        ssn_number = ssn_number.replace(/\D/g, '');

        ssn_number = ssn_number.substring(0, 10);
        const size = ssn_number.length;
        if (size < 4) {
            ssn_number = ssn_number;
        } else if (size < 6) {
            ssn_number = ssn_number.substring(0, 3) + '-' + ssn_number.substring(3, 6);
        } else {
            ssn_number =
                ssn_number.substring(0, 3) + '-' + ssn_number.substring(3, 5) + '-' + ssn_number.substring(5, 9);
        }
        return ssn_number;
    };
    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };
    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phoneNumber: phone,
            continueClick: false,
        });

        this.inputAnalyticsTracking('Phone Number', 'phoneNumber');
    };
    emailHandler = (e: any) => {
        this.setState({
            emailAddress: e.target.value,
            continueClick: false,
            uniqueEmailAddress: true,
        });

        this.inputAnalyticsTracking('Email Address', 'email');
    };
    ValidateEmail= (e: any) => {
        var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        if (!expr.test(e.target.value)) {
            this.setState({
                validateEmail: false,
                uniqueEmailAddress: true,
            });
        }else{
            this.setState({
                validateEmail: true,
                uniqueEmailAddress: true,
            });
        }
    }
    lastNameHandler = (e: any) => {
        var regName =/^[a-zA-Z '.-]*$/;
        if(!regName.test(e.target.value)){
            this.setState({
                validateLastName: false,
            });
        }else{
            this.setState({
                lastName: e.target.value,
                continueClick: false,
                validateLastName: true,
            });
        }

        this.inputAnalyticsTracking('Last Name', 'lastName');
    };
    handleHouseChange = (e: any) => {
        this.setState({ houseOption: e.target.value, continueClick: false });
    };
    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget, continueClick: false });
    };
    handleClick1 = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl1: event.currentTarget, continueClick: false });
    };
    handleSwitchType = (e: any) => {
        let moveIntypeOptions: any = [];
        e &&
            e.item &&
            e.item.moveInTypes &&
            e.item.moveInTypes.map((item2: any) => {
                moveIntypeOptions.push({ value: item2.value, label: item2.text, item: item2 });
            });
        this.setState({ switchType: e, continueClick: false, moveIntypeOptions: moveIntypeOptions });

        this.inputAnalyticsTracking('Switch Type', 'switchType');
    };
    handleMoveInType = (e: any) => {
        this.setState({ moveIntype: e, continueClick: false });
    };
    handleTaxExemptReasons = (e: any) => {
        this.setState({ taxExemptReasons: e, continueClick: false });
    };

    inputAnalyticsTracking = (fieldLabel: string, fieldName: string) => {
        if (!this.state.isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
                page: 'Energy Savings',
                category_name: 'Energy Savings Plan Selection Flow',
                button_copy: 'next',
                form_button: true,
                form_pre_filled: false,
                form_field_name: fieldLabel,
                form_step_number: this.state.activeStep + 1,
            }
    
            this.state.isFormStarted ? AnalyticsService.formFieldInput(trackProperties) : AnalyticsService.formFieldStarted(trackProperties);
            this.setState({ 
                isFormStarted: true, 
                isAnalitycsTriggerredOnce: {
                    ...this.state.isAnalitycsTriggerredOnce,
                    [fieldName]: true
                }
             });

        } else {
            return
        }
    }

    handleChange = (e: any, name: string, fieldName: string) => {
        let val: any;
        val = e.target.value;
        if (
            name == 'mailingZipCode' ||
            name === 'accountNumber' ||
            name == 'meterNumber' ||
            name == 'referenceNumber'
        ) {
            val = val.replace(/\D/g, '');
        }
        this.setState({ ...this.state, [name]: val, continueClick: false });
        // console.log(this.state)

        this.inputAnalyticsTracking(fieldName, name)
    };
    
    // handleLastStep = () => {
    //     this.props.history.push('/dashboard/energy-savings/utilitiesConfirmation');
    // };
    handleChangePlan = () => {
        this.props.history.push({
            pathname: '/dashboard/energy-savings/utilitiesPowerSaving',
            state: {
                state: this.props.history.location.state,
            },
        });
    };

    handleNext = () => {
        AnalyticsService.buttonClicked({
            page: 'Energy Savings',
            category_name: 'Energy Savings Plan Selection Flow',
            button_copy:  this.state.activeStep === 2 ? 'Submit' : 'Next',
            form_button: true,
            form_step_number: this.state.activeStep + 1,
        });

        if (this.state.activeStep === 0) {
            this.setState({ continueClick: true });
            if (
                this.state.accountNumber &&
                this.state.accountNumber.match(this.state.accountFormat) &&
                this.state.accountNumber.length === this.state.accountFormatLength
            ) {
                let selectedPlan: any = this.state.selectedPlan;
                let payload = {
                    service_type_id: selectedPlan && selectedPlan.serviceTypeId,
                    state_id: selectedPlan && selectedPlan.stateId,
                    usage: this.props.history.location.state.usage ? this.props.history.location.state.usage : 0,
                    utility_id: selectedPlan && selectedPlan.utilityId,
                    utility_account_number: this.state.accountNumber ? this.state.accountNumber : '',
                    supplier_id: selectedPlan && selectedPlan.supplierId,
                    effective_date: selectedPlan && selectedPlan.effectiveDate,
                };

                this.props.energyAction.getSwitchType(payload);
                this.setState({ activeStep: 1, continueClick: false });
            }
        } else if (this.state.activeStep === 1) {
            this.setState({ continueClick: true });
            if (
                this.state.name &&
                this.state.lastName &&
                this.state.emailAddress &&
                this.state.phoneNumber &&
                this.state.phoneNumber.length === 12 &&
                this.state.switchType &&
                this.state.date &&
                ((this.state.showDob && this.state.dob) || (!this.state.showDob && this.state.dob == null)) &&
                ((this.state.showSsn && this.state.ssn && this.state.ssn.length === 11) ||
                    (!this.state.showSsn && this.state.ssn == '')) &&
                ((this.state.showTaxId && this.state.taxId) || (!this.state.showTaxId && this.state.taxId == '')) &&
                ((this.state.showMeterNum &&
                    this.state.meterNumber &&
                    this.state.meterNumber.match(this.state.meterNumberFormat)) ||
                    (!this.state.showMeterNum && this.state.meterNumber == '')) &&
                ((this.state.showNameKey && this.state.nameKey && this.state.nameKey.match(this.state.nameKeyFormat)) ||
                    (!this.state.showNameKey && this.state.nameKey == '')) &&
                ((this.state.showReferenceNum &&
                    this.state.referenceNumber &&
                    this.state.referenceNumber.match(this.state.referenceNumberFormat)) ||
                    (!this.state.showReferenceNum && this.state.referenceNumber == '')) &&
                ((this.state.showTaxExemptReasons &&
                    this.state.taxExemptReasons &&
                    this.state.taxExemptReasons.value) ||
                    (!this.state.showTaxExemptReasons && this.state.taxExemptReasons == null)) &&
                ((this.state.moveIntypeOptions.length && this.state.moveIntype && this.state.moveIntype.value) ||
                    (this.state.moveIntypeOptions.length == 0 && this.state.moveIntype == null))
            ) {
                let payload: any = {
                    rate_matrix_id: this.state.selectedPlan.rateMatrixId,
                    switch_type_code: this.state.switchType && this.state.switchType.value,
                    email: this.state.emailAddress,
                };
                this.props.energyAction.getDisclaimerDetails(payload);
                let addressData: any = localStorage.getItem('pods_destination_address');
                let moveInData: any = localStorage.getItem('pods_moving_date');
                if (addressData) {
                    addressData = JSON.parse(addressData)
                }
                const signUpPayload = {
                    destination_detail: addressData ? addressData.address : "",
                    destination_home: '1',
                    moving_date: JSON.parse(moveInData),
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    type: 'security',
                    user_ref: {
                        email: this.state.emailAddress,
                        first_name: this.state.name,
                        last_name: this.state.lastName,
                        // password: password,
                        phone: this.state.phoneNumber,
                        site_name: uiConfig.domain,
                    },
                };
                // console.log(signUpPayload)
                this.props.signupAction.landingMovePost(signUpPayload);
                setTimeout(() => {
                    if (this.state.signupState.landingMovePostStatus === "error"){
                        this.setState({uniqueEmailAddress: false})
                    }else{
                        this.setState({ activeStep: 2, continueClick: false });    
                    }
                }, 2000);
            }
        } else if (this.state.activeStep === 2) {
            this.setState({ continueClick: true });
            if (
                this.state.streetAddress1 &&
                this.state.city &&
                this.state.zipCode &&
                this.state.city &&
                this.state.state &&
                this.state.houseOption &&
                this.state.checkedMailingAddress &&
                this.state.checked
            ) {
                let switchDate: any = '';
                if (
                    this.state.moveIntype &&
                    this.state.moveIntype.item &&
                    this.state.moveIntype.item.allowedDates &&
                    this.state.moveIntype.item.allowedDates.length
                ) {
                    switchDate = this.state.date;
                } else if (
                    this.state.moveIntype &&
                    this.state.moveIntype.value &&
                    this.state.moveIntype.value === 'Immediate'
                ) {
                    if (new Date(this.state.cstDateTime).getHours() >= 16) {
                        let date1 = this.state.cstDateTime.setDate(this.state.cstDateTime.getDate() + 1);
                        // switchDate = new Date(date1).toLocaleString();
                        switchDate = moment(date1).format('YYYY-MM-DDTHH:MM:SS');
                    } else {
                        // switchDate = new Date(this.state.cstDateTime).toLocaleString();
                        switchDate = moment(this.state.cstDateTime).format('YYYY-MM-DDTHH:MM:SS');
                    }
                } else {
                    let month = new Date().getMonth() + 2;
                    // switchDate = new Date(month, 1, new Date().getFullYear()).toLocaleString();
                    let date = new Date(month, 1, new Date().getFullYear());
                    switchDate = moment(date).format('YYYY-MM-DDTHH:MM:SS');
                }
                let payload: any = {
                    utility_id: this.state.selectedPlan.utilityId,
                    rate_matrix_id: this.state.selectedPlan.rateMatrixId,
                    service_type_id: this.state.serviceTypeId,
                    effective_date: this.state.selectedPlan.effectiveDate,
                    switch_type_code: this.state.switchType && this.state.switchType.value,
                    switch_date: switchDate,
                    dwelling: this.state.houseOption,
                    move_in_type:
                        this.state.moveIntype && this.state.moveIntype.value
                            ? this.state.moveIntype.value
                            : this.state.switchType &&
                              this.state.switchType.value &&
                              this.state.switchType.value == 'Switching Service'
                            ? 'Standard Switch'
                            : 'Future Start',
                    state_id: this.state.selectedPlan.stateId,
                    is_billing_address_same: this.state.checkedMailingAddress,
                    utility_account_number: this.state.accountNumber,
                    usage: this.state.usage,
                    billing_street: this.state.mailingStreetAddress1,
                    billing_city: this.state.mailingCity,
                    billing_state: this.state.mailingState,
                    billing_zipcode: this.state.mailingZipCode,
                    supplier_id: this.state.selectedPlan.supplierId,
                    supplier_name: this.state.selectedCompany[0].utility_name,
                    is_csr_call: false,
                    service_type: this.state.serviceTypeName,
                };
                if (this.state.showDob) {
                    payload = { ...payload, dob: this.state.dob };
                }
                if (this.state.showSsn) {
                    payload = { ...payload, ssn: this.state.ssn };
                }
                if (this.state.showTaxId) {
                    payload = { ...payload, tax_id: this.state.taxId };
                }
                if (this.state.showTaxExemptReasons) {
                    payload = {
                        ...payload,
                        tax_exempt: this.state.taxExemptReasons && this.state.taxExemptReasons.value,
                    };
                }
                if (this.state.showMeterNum) {
                    payload = {
                        ...payload,
                        utility_meter_num: this.state.meterNumber,
                    };
                }
                if (this.state.showNameKey) {
                    payload = {
                        ...payload,
                        utility_name_key: this.state.nameKey,
                    };
                }
                if (this.state.showReferenceNum) {
                    payload = {
                        ...payload,
                        utility_reference_num: this.state.referenceNumber,
                    };
                }
                this.props.energyAction.postSubmitContract(payload);
                this.setState({ loader: true });
                // this.props.history.push('/dashboard/energy-savings/utilitiesConfirmation');
                AnalyticsService.formCompleted({
                    page: 'Energy Savings',
                    category_name: 'Energy Savings Plan Selection Flow',
                    form_pre_filled: false,
                    form_button: true,
                    button_copy: 'Submit',
                    form_name: 'Great! Savings in less than 5 minutes',
                    form_step_number: 3
                });
            }
        }
    };

    getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="utlitityInpts">
                            <div className="inputUtilityDivFull">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Your Utility Account Number</InputLabel>
                                </div>
                                <div className="inputUtilityFlexDiv">
                                    <TextField
                                        className="input1"
                                        label="Account Number"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'accountNumber', 'Your Utility Account Number')}
                                        variant="filled"
                                        value={this.state.accountNumber}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />

                                    <span className="toolCenter">
                                        <ReactTooltip place="top" type="dark" effect="float" multiline={true} />
                                        <InfoIcon className="iconMarginLeftFinal" data-tip={this.state.accountError} />
                                        <div className="findUrAccount"> Where to find utility account number</div>
                                    </span>
                                </div>
                                <div className="error">
                                    {this.state.continueClick && this.state.accountNumber == ''
                                        ? 'Enter Account number'
                                        : this.state.accountNumber != '' &&
                                          !this.state.accountNumber.match(this.state.accountFormat)
                                        ? 'Invalid account number'
                                        : this.state.accountNumber != '' &&
                                          this.state.accountNumber.match(this.state.accountFormat) &&
                                          (this.state.accountNumber.length < this.state.accountFormatLength ||
                                              this.state.accountNumber.length > this.state.accountFormatLength)
                                        ? `Account number must be of ${this.state.accountFormatLength} digits.`
                                        : this.state.accountNumber != '' &&
                                          this.state.accountNumber.match(this.state.accountFormat) &&
                                          this.state.accountNumber.length === this.state.accountFormatLength
                                        ? ''
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="utlitityInpts">
                            <div className="inputUtilityDiv">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">First Name</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="First Name"
                                    id="outlined-start-adornment"
                                    onChange={this.nameHandler}
                                    variant="filled"
                                    value={this.state.name}
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.name === '' ? 'Enter first name' : !this.state.validateName ? 'Please enter valid first name' :''}
                                </div>
                            </div>
                            <div className="inputUtilityDiv inputMarginSide ">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Last Name</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="Last Name"
                                    id="outlined-start-adornment"
                                    onChange={this.lastNameHandler}
                                    variant="filled"
                                    value={this.state.lastName}
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.lastName === '' ? 'Enter last name' : !this.state.validateLastName ? 'Please enter valid last name' :''}
                                </div>
                            </div>
                        </div>
                        <div className="utlitityInpts">
                            <div className="inputUtilityDiv">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Email Address</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="Email Address"
                                    id="outlined-start-adornment"
                                    onChange={this.emailHandler}
                                    onKeyUp={this.ValidateEmail}
                                    variant="filled"
                                    value={this.state.emailAddress}
                                    type="email"
                                    // disabled
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.emailAddress === '' ? 'Enter email' : !this.state.uniqueEmailAddress ? "Please enter unique email address" : !this.state.validateEmail ? 'Please enter valid email address' :''}
                                </div>
                            </div>
                            <div className="inputUtilityDiv inputMarginSide ">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Phone Number</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="Phone Number"
                                    id="outlined-start-adornment"
                                    onChange={this.phoneHandler}
                                    variant="filled"
                                    value={this.state.phoneNumber}
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.phoneNumber === ''
                                        ? 'Enter phone number'
                                        : this.state.continueClick &&
                                          this.state.phoneNumber != '' &&
                                          !(this.state.phoneNumber.length === 12)
                                        ? 'invalid phone number'
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className="utlitityInpts">
                            <div className="inputUtilityDiv">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Switch Type</InputLabel>
                                </div>
                                <SelectInput
                                    className="react-select alert-select"
                                    onChange={(e: any) => this.handleSwitchType(e)}
                                    options={this.state.switchTypeOptions}
                                    placeholder={
                                        this.state.switchType && this.state.switchType.label
                                            ? this.state.switchType.label
                                            : 'Select Switch Type'
                                    }
                                    // label="Select Switch Type"
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.switchType === null
                                        ? 'Select switch type'
                                        : ''}
                                </div>
                            </div>
                            {this.state.moveIntypeOptions.length ? (
                                <div className="inputUtilityDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Move In Type</InputLabel>
                                    </div>
                                    <SelectInput
                                        className="react-select alert-select"
                                        onChange={(e: any) => this.handleMoveInType(e)}
                                        options={this.state.moveIntypeOptions}
                                        placeholder={
                                            this.state.moveIntype && this.state.moveIntype.label
                                                ? this.state.moveIntype.label
                                                : 'Select Move In Type'
                                        }
                                        // label="Select Switch Type"
                                    />
                                    <div className="error">
                                        {this.state.continueClick &&
                                        this.state.moveIntypeOptions.length &&
                                        this.state.moveIntype === null
                                            ? 'Select move in type'
                                            : ''}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.state.moveIntype &&
                        this.state.moveIntype.item &&
                        this.state.moveIntype.item.allowedDates &&
                        this.state.moveIntype.item.allowedDates.length ? (
                            <div className="utlitityInpts">
                                <div className="inputFullDivUtility">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">
                                            Indicate the date you would like new service to start
                                        </InputLabel>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // disablePast
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            // label="Date picker inline"
                                            className="date-autoshipping"
                                            value={this.state.date}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    date: e,
                                                    anchorEl: null,
                                                });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                onFocus: e => {
                                                    this.handleClick(e);
                                                },
                                            }}
                                            autoOk={true}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    this.setState({ anchorEl: null });
                                                },
                                                anchorEl: this.state.anchorEl,
                                            }}
                                            // InputProps={{
                                            //     onFocus: e => {
                                            //         this.handleClick(e);
                                            //     },
                                            // }}
                                            open={Boolean(this.state.anchorEl)}
                                            minDate={new Date(this.state.moveIntype.item.allowedDates[0])}
                                            maxDate={
                                                new Date(
                                                    this.state.moveIntype.item.allowedDates[
                                                        this.state.moveIntype.item.allowedDates.length - 1
                                                    ],
                                                )
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className="error">
                                        {this.state.continueClick && this.state.date === ''
                                            ? 'Select service start date'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.state.showDob ? (
                            <div className="utlitityInpts">
                                <div className="inputFullDivUtility">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Date Of Birth</InputLabel>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            // label="Date picker inline"
                                            className="date-autoshipping"
                                            value={this.state.dob}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    dob: e,
                                                    anchorEl1: null,
                                                });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                onFocus: e => {
                                                    this.handleClick1(e);
                                                },
                                            }}
                                            maxDate={new Date()}
                                            autoOk={true}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    this.setState({ anchorEl: null });
                                                },
                                                anchorEl: this.state.anchorEl1,
                                            }}
                                            // helperText={this.state.dob === '' ? '' : 'invalid date format'}
                                            // InputProps={
                                            //     {
                                            //         // onFocus: e => {
                                            //         //     this.handleClick(e);
                                            //         // },
                                            //     }
                                            // }
                                            open={Boolean(this.state.anchorEl1)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className="error">
                                        {this.state.continueClick && this.state.dob === '' ? 'Enter Date of birth' : ''}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="utlitityInpts">
                            {this.state.showSsn ? (
                                <div className="inputUtilityDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">SSN Number</InputLabel>
                                    </div>
                                    <TextField
                                        className="input1"
                                        label="SSN Number"
                                        id="outlined-start-adornment"
                                        onChange={this.ssnHandler}
                                        variant="filled"
                                        value={this.state.ssn}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />
                                    <div>
                                        {' '}
                                        Your social security number is not stored in the database. This information is
                                        securely sent to Power Kiosk for the purpose of submitting the contract.
                                    </div>
                                    <div className="error">
                                        {this.state.continueClick && this.state.ssn === ''
                                            ? 'Enter ssn'
                                            : this.state.continueClick &&
                                              this.state.ssn != '' &&
                                              !(this.state.ssn.length === 11)
                                            ? 'invalid ssn number'
                                            : ''}
                                    </div>
                                </div>
                            ) : null}
                            {this.state.showTaxId ? (
                                <div className="inputUtilityDiv inputMarginSide ">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Tax Id</InputLabel>
                                    </div>
                                    <TextField
                                        className="input1"
                                        label="Tax Id"
                                        id="outlined-start-adornment"
                                        onChange={this.taxIdHandler}
                                        variant="filled"
                                        value={this.state.taxId}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />
                                    <div className="error">
                                        {this.state.continueClick && this.state.taxId === '' ? 'Enter Tax Id' : ''}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="utlitityInpts">
                            {this.state.showMeterNum ? (
                                <div className="inputUtilityDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Meter Number</InputLabel>
                                    </div>
                                    <TextField
                                        className="input1"
                                        label="Meter Number"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'meterNumber', 'Meter Number')}
                                        variant="filled"
                                        value={this.state.meterNumber}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />
                                    <div className="error">
                                        {this.state.continueClick && this.state.meterNumber == ''
                                            ? 'Enter meter number'
                                            : this.state.continueClick &&
                                              !this.state.meterNumber.match(this.state.meterNumberFormat)
                                            ? 'Invalid meter number'
                                            : ''}
                                    </div>
                                </div>
                            ) : null}
                            {this.state.showNameKey ? (
                                <div className="inputUtilityDiv inputMarginSide ">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Name Key</InputLabel>
                                    </div>
                                    <TextField
                                        className="input1"
                                        label="Name Key"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'nameKey', 'Name Key')}
                                        variant="filled"
                                        value={this.state.nameKey}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />
                                    <div className="error">
                                        {this.state.continueClick && this.state.nameKey == ''
                                            ? 'Enter name key'
                                            : this.state.continueClick &&
                                              !this.state.nameKey.match(this.state.nameKeyFormat)
                                            ? 'Invalid name key'
                                            : ''}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="utlitityInpts">
                            {this.state.showReferenceNum ? (
                                <div className="inputUtilityDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Reference Number</InputLabel>
                                    </div>
                                    <TextField
                                        className="input1"
                                        label="Reference Number"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'referenceNumber', 'Reference Number')}
                                        variant="filled"
                                        value={this.state.referenceNumber}
                                        // error={this.state.name === ''}
                                        // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                    />
                                    <div className="error">
                                        {this.state.continueClick && this.state.referenceNumber == ''
                                            ? 'Enter reference number'
                                            : this.state.continueClick &&
                                              !this.state.referenceNumber.match(this.state.referenceNumberFormat)
                                            ? 'Invalid reference number'
                                            : ''}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.state.showTaxExemptReasons ? (
                            <div className="utlitityInpts">
                                <div className="inputFullDivUtility">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Tax Exempt Reasons</InputLabel>
                                    </div>
                                    <SelectInput
                                        className="react-select alert-select"
                                        onChange={(e: any) => this.handleTaxExemptReasons(e)}
                                        options={this.state.taxExemptReasonsOptions}
                                        placeholder={
                                            this.state.taxExemptReasons && this.state.taxExemptReasons.label
                                                ? this.state.taxExemptReasons.label
                                                : 'Select tax exempt reasons'
                                        }
                                        // label="Select Switch Type"
                                    />
                                    <div className="error">
                                        {this.state.continueClick && this.state.taxExemptReasons === null
                                            ? 'Select tax exempt reasons'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="utlitityInpts">
                            <div className="inputUtilityDiv">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Street Address</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="Street Address"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handleChange(e, 'streetAddress1', 'Street Address')}
                                    variant="filled"
                                    value={this.state.streetAddress1}
                                    disabled={
                                        this.state.continueClick && this.state.streetAddress1 === '' ? false : true
                                    }
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.streetAddress1 === ''
                                        ? 'Enter Street Address'
                                        : ''}
                                </div>
                            </div>
                            <div className="inputUtilityDiv inputMarginSide ">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">City</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="City"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handleChange(e, 'city', 'City')}
                                    variant="filled"
                                    value={this.state.city}
                                    disabled={this.state.continueClick && this.state.city === ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.city === '' ? 'Enter City' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="utlitityInpts">
                            <div className="inputUtilityDiv">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">State</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="State"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handleChange(e, 'state', 'State')}
                                    variant="filled"
                                    value={this.state.state}
                                    disabled={this.state.continueClick && this.state.state === '' ? false : true}
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.state === '' ? 'Enter State' : ''}
                                </div>
                            </div>
                            <div className="inputUtilityDiv inputMarginSide ">
                                <div className="inputLabel">
                                    <InputLabel className="inputLable">Zip Code</InputLabel>
                                </div>
                                <TextField
                                    className="input1"
                                    label="Zip Code"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handleChange(e, 'zipCode', 'Zip Code')}
                                    variant="filled"
                                    value={this.state.zipCode}
                                    disabled={this.state.continueClick && this.state.zipCode === '' ? false : true}
                                    // error={this.state.name === ''}
                                    // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                />
                                <div className="error">
                                    {this.state.continueClick && this.state.zipCode === '' ? 'Enter Zip code' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="utlitityInptsDiv">
                            <div className="inputLabel" style={{ color: '#333333', marginBottom: '0px', fontFamily: this.state.slug?.custom_primary_font }}>
                                Dwelling type
                            </div>
                            <div>
                                <RadioGroup
                                    aria-label="contact"
                                    name="contact"
                                    className="energyRadio"
                                    value={this.state.houseOption}
                                    onChange={this.handleHouseChange}
                                >
                                    <FormControlLabel
                                        className="energyRadio1"
                                        value="Apartment"
                                        control={<Radio />}
                                        label="Apartment"
                                    />
                                    <FormControlLabel
                                        className="energyRadio1"
                                        value="House"
                                        control={<Radio />}
                                        label="House"
                                    />
                                </RadioGroup>
                            </div>
                            <div className="error">
                                {this.state.continueClick && this.state.houseOption === ''
                                    ? 'Select Dwelling Type'
                                    : ''}
                            </div>
                        </div>
                        <div className="">
                            <Checkbox
                                // className={props.className ? `${props.className} ${classes.checkroot}` : classes.checkroot}
                                checked={this.state.checkedMailingAddress}
                                onChange={(e: any) =>
                                    this.setState({
                                        checkedMailingAddress: e.target.checked,
                                    })
                                }
                                //   icon={<CircleUnchecked />}
                                //   checkedIcon={<CircleCheckedFilled />}
                                style={{ color: '#408867' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            Mailing Address is same as service address
                        </div>
                        {this.state.checkedMailingAddress ? null : (
                            <div>
                                <div className="utlitityInpts">
                                    <div className="inputUtilityDiv">
                                        <div className="inputLabel">
                                            <InputLabel className="inputLable">State</InputLabel>
                                        </div>
                                        <TextField
                                            className="input1"
                                            label="Street Address 1"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChange(e, 'mailingStreetAddress1', 'State')}
                                            variant="filled"
                                            value={this.state.mailingStreetAddress1}
                                            // error={this.state.name === ''}
                                            // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                            !this.state.checkedMailingAddress &&
                                            this.state.mailingStreetAddress1 === ''
                                                ? 'Enter Street address'
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="inputUtilityDiv inputMarginSide ">
                                        <div className="inputLabel">
                                            <InputLabel className="inputLable">City</InputLabel>
                                        </div>
                                        <TextField
                                            className="input1"
                                            label="City"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChange(e, 'mailingCity', 'City')}
                                            variant="filled"
                                            value={this.state.mailingCity}
                                            // error={this.state.name === ''}
                                            // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                            !this.state.checkedMailingAddress &&
                                            this.state.mailingCity === ''
                                                ? 'Enter City'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="utlitityInpts">
                                    <div className="inputUtilityDiv">
                                        <div className="inputLabel">
                                            <InputLabel className="inputLable">State</InputLabel>
                                        </div>
                                        <TextField
                                            className="input1"
                                            label="State"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChange(e, 'mailingState', 'State')}
                                            variant="filled"
                                            value={this.state.mailingState}
                                            // error={this.state.name === ''}
                                            // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                            !this.state.checkedMailingAddress &&
                                            this.state.mailingState === ''
                                                ? 'Enter State'
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="inputUtilityDiv inputMarginSide ">
                                        <div className="inputLabel">
                                            <InputLabel className="inputLable">Zip Code</InputLabel>
                                        </div>
                                        <TextField
                                            className="input1"
                                            label="ZipCode"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChange(e, 'mailingZipCode', 'Zip Code')}
                                            variant="filled"
                                            value={this.state.mailingZipCode}
                                            // error={this.state.name === ''}
                                            // helperText={this.state.name === '' ? 'Please enter name' : ''}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                            !this.state.checkedMailingAddress &&
                                            this.state.mailingZipCode === ''
                                                ? 'Enter Zip code'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.showDepositDisclaimer ? (
                            <div>
                                <div className="disclousreFinal">Deposite Disclaimer</div>
                                <div className="description">{this.state.depositDisclaimer}</div>
                            </div>
                        ) : null}

                        <div className="disclousreFinal" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Disclosures & Authorizations</div>
                        {this.state.showMore ? (
                            <div
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.disclaimer,
                                }}
                            ></div>
                        ) : (
                            <div className="descriptionDiv">
                                <span
                                    className="description"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.disclaimer.substring(0, 200),
                                    }}
                                >
                                    {/* {this.state.description.substring(0, 200)} */}
                                </span>
                                <span className="showMore" onClick={() => this.setState({ showMore: true })}>
                                    ...Show More
                                </span>
                            </div>
                        )}
                        <div className="inputCheckboxFlexDiv">
                            <Checkbox
                                // className={props.className ? `${props.className} ${classes.checkroot}` : classes.checkroot}
                                checked={this.state.checked}
                                onChange={(e: any) => this.setState({ checked: e.target.checked })}
                                style={{ color: '#408867' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            I have read and agreed to the above Disclosures and Authorizations
                        </div>
                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    render() {
        const steps = this.getSteps();
        return (
            <div className="utility-Savings-main-head">
                {/* <div className="progressBarFinal">
                    <Box>
                        <LinearProgress variant="determinate" value={((this.state.activeStep + 1) / 4) * 100} />
                    </Box>
                </div> */}
                {this.state.loader ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '60vh',
                        }}
                    >
                        <CircularProgress />
                        <div style={{ marginTop: '10px' }}>processing your request...please wait</div>
                    </div>
                ) : (
                    <div className="utility-savings-sub-class">
                        <div className="arrowBackIconFinal">
                            <ArrowBackIcon
                                onClick={() =>
                                    this.props.history.push({
                                        pathname: '/dashboard/energy-savings/energySteps',
                                        state: this.props.history.location.state,
                                    })
                                }
                            />
                        </div>
                        <div className="utility-savings-submain">
                            <div className="utility-saving-left-div">
                                <div className="greatSavingsHead" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Great! Savings in less than 5 minutes</div>
                                <div className="stepingStepper">
                                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                        {steps.map((label, index) => (
                                            <Step key={label}>
                                                <StepLabel>
                                                   <div style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}> {label}</div>

                                                    <div className="stepperEditIcon">
                                                        {/* <EditIcon onClick={() => console.log()} /> */}
                                                        {/* {this.state.activeStep === index ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : ( */}
                                                        {this.state.activeStep > index ? (
                                                            <KeyboardArrowDownIcon
                                                                onClick={() => {
                                                                    if (this.state.activeStep > index) {
                                                                        this.setState({ activeStep: index });
                                                                    }
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </StepLabel>

                                                <StepContent
                                                    className={
                                                        (this.state.activeStep > 0 && index === 0) ||
                                                        (this.state.activeStep > 1 && index === 1)
                                                            ? 'stepContent'
                                                            : ''
                                                    }
                                                >
                                                    <div>{this.getStepContent(index)}</div>
                                                    <div>
                                                        <div>
                                                            {/* <Button
                                                // variant="contained"
                                                color="primary"
                                                onClick={this.handleNext}
                                            >
                                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button> */}
                                                        </div>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                            </div>
                            <div className="utility-saving-right-div">
                                <div className="selectedPlanHeadingDiv">
                                    <div className="selectedPlanHeading" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Selected plan details</div>
                                    <div
                                        className="changePlan"
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname: '/dashboard/energy-savings/utilitiesPowerSaving',
                                                state: this.props.history.location.state,
                                            });
                                        }}
                                    >
                                        Change Plan
                                    </div>
                                </div>
                                {this.state.selectedPlan && this.state.selectedPlan != null ? (
                                    <FinalPlan
                                        logo={this.state.selectedPlan.logo}
                                        kwh={this.state.selectedPlan.displayRate}
                                        contract={this.state.selectedPlan.term}
                                        handleChangePlan={this.handleChangePlan}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(utilitySavings);
