import {
    GET_CHECKLIST_BY_MOVE_ID,
    GET_CHECKLIST_BY_MOVE_ID_ERROR,
    GET_CHECKLIST_BY_MOVE_ID_SUCCESS,
    POST_SUBSCRIPTION_DETAILS,
    POST_SUBSCRIPTION_DETAILS_ERROR,
    POST_SUBSCRIPTION_DETAILS_SUCCESS,
    GET_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_DETAILS_ERROR,
    GET_SUBSCRIPTION_DETAILS_SUCCESS,
    UPDATE_SUBSCRIPTION_DETAILS,
    UPDATE_SUBSCRIPTION_DETAILS_ERROR,
    UPDATE_SUBSCRIPTION_DETAILS_SUCCESS,
} from './actionTypes';
import {
    CheckListByMoveId,
    CheckListByMoveIdRequest,
    CheckListByMoveIdFailure,
    CheckListByMoveIdFailurePayload,
    CheckListByMoveIdSuccess,
    CheckListByMoveIdSuccessPayload,
    PostSubscriptionDetails,
    PostSubscriptionDetailsRequest,
    PostSubscriptionDetailsFailure,
    PostSubscriptionDetailsFailurePayload,
    PostSubscriptionDetailsSuccess,
    PostSubscriptionDetailsSuccessPayload,
    GetSubscriptionDetails,
    GetSubscriptionDetailsRequest,
    GetSubscriptionDetailsFailure,
    GetSubscriptionDetailsFailurePayload,
    GetSubscriptionDetailsSuccess,
    GetSubscriptionDetailsSuccessPayload,
    UpdateSubscriptionDetails,
    UpdateSubscriptionDetailsRequest,
    UpdateSubscriptionDetailsFailure,
    UpdateSubscriptionDetailsFailurePayload,
    UpdateSubscriptionDetailsSuccess,
    UpdateSubscriptionDetailsSuccessPayload,
} from './types';

export const checklistByMoveId = (payload: CheckListByMoveIdRequest): CheckListByMoveId => ({
    type: GET_CHECKLIST_BY_MOVE_ID,
    payload,
});

export const checklistByMoveIdSuccess = (payload: CheckListByMoveIdSuccessPayload): CheckListByMoveIdSuccess => ({
    type: GET_CHECKLIST_BY_MOVE_ID_SUCCESS,
    payload,
});

export const checklistByMoveIdFailure = (payload: CheckListByMoveIdFailurePayload): CheckListByMoveIdFailure => ({
    type: GET_CHECKLIST_BY_MOVE_ID_ERROR,
    payload,
});

export const postSubscriptionDetails = (payload: PostSubscriptionDetailsRequest): PostSubscriptionDetails => ({
    type: POST_SUBSCRIPTION_DETAILS,
    payload,
});

export const postSubscriptionDetailsSuccess = (
    payload: PostSubscriptionDetailsSuccessPayload,
): PostSubscriptionDetailsSuccess => ({
    type: POST_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const postSubscriptionDetailsFailure = (
    payload: PostSubscriptionDetailsFailurePayload,
): PostSubscriptionDetailsFailure => ({
    type: POST_SUBSCRIPTION_DETAILS_ERROR,
    payload,
});

export const getSubscriptionDetails = (payload: GetSubscriptionDetailsRequest): GetSubscriptionDetails => ({
    type: GET_SUBSCRIPTION_DETAILS,
    payload,
});

export const getSubscriptionDetailsSuccess = (
    payload: GetSubscriptionDetailsSuccessPayload,
): GetSubscriptionDetailsSuccess => ({
    type: GET_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const getSubscriptionDetailsFailure = (
    payload: GetSubscriptionDetailsFailurePayload,
): GetSubscriptionDetailsFailure => ({
    type: GET_SUBSCRIPTION_DETAILS_ERROR,
    payload,
});

export const updateSubscriptionDetails = (payload: UpdateSubscriptionDetailsRequest): UpdateSubscriptionDetails => ({
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload,
});

export const updateSubscriptionDetailsSuccess = (
    payload: UpdateSubscriptionDetailsSuccessPayload,
): UpdateSubscriptionDetailsSuccess => ({
    type: UPDATE_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const updateSubscriptionDetailsFailure = (
    payload: UpdateSubscriptionDetailsFailurePayload,
): UpdateSubscriptionDetailsFailure => ({
    type: UPDATE_SUBSCRIPTION_DETAILS_ERROR,
    payload,
});
