import * as React from 'react';
import { navigationIconColorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 30.415}
            height={props.height ? props.height : 31.118}
            viewBox="0 0 30.415 31.118"
        >
            <defs>
                <style>
                    {`.Insuranceprefix__a{
                        fill:#fff
                    }.InsuranceSvgSvgprefix__d{
                        fill:${navigationIconColorSelector(props.active, "a", "fill")}
                    }`}
                </style>
            </defs>
            <path
                className="Insuranceprefix__a"
                d="M26.19 19.481l1.108.806.173.126v10.3H15.202V20.467l-.027-.037 6.162-4.486.264.192 2.865 2.086zm0 0"
            />
            <path className="Insuranceprefix__a" d="M27.471 17.419v2.994l-.173-.126-1.108-.806V16.41zm0 0" />
            <path
                d="M24.571 6.335v10.629a7.992 7.992 0 01-.1 1.262l-2.87-2.086V8.254l-9.115-4.432-9.115 4.464v8.541a7.8 7.8 0 003.038 5.746l6.077 4.144 2.717-1.853V28.5l-2.717 1.853-7.837-5.347a10.577 10.577 0 01-4.251-8.042V6.319L12.487.4zm0 0"
                // fill="#bebebe"
                className="InsuranceSvgSvgprefix__d"
            />
            <path
                className="Insuranceprefix__a"
                d="M15.175 20.434l.027.037v4.394l-2.717 1.853-6.077-4.144a7.8 7.8 0 01-3.038-5.746V8.286l9.115-4.464L21.6 8.255v7.89l-.264-.192zm0 0"
            />
            <path
                d="M15.308 13.121l-3.754 3.438-1.868-2.025a.4.4 0 00-.589.543l2.138 2.318a.4.4 0 00.565.024l4.048-3.708a.4.4 0 00-.541-.591zm0 0"
            />
            <path
                d="M30.252 21.94l-2.377-1.73v-2.791a.4.4 0 00-.152-.314l-1.285-1.006a.4.4 0 00-.648.315v2.285l-.894-.651a8.221 8.221 0 00.075-1.08V6.335a.4.4 0 00-.224-.359L12.664.041a.4.4 0 00-.353 0L.225 5.96a.4.4 0 00-.224.359v10.645a11.012 11.012 0 004.426 8.373l7.833 5.343a.4.4 0 00.451 0l2.091-1.426v1.464a.4.4 0 00.4.4h12.269a.4.4 0 00.4-.4v-9.519l1.905 1.387a.4.4 0 10.471-.648zm-3.657-4.7l.48.378v2.013l-.48-.349zm-14.1 12.629l-7.617-5.194a10.141 10.141 0 01-4.076-7.711V6.569L12.487.846 24.17 6.584v10.38c0 .175-.008.353-.022.535l-2.147-1.563V8.255a.4.4 0 00-.225-.36l-9.115-4.433a.4.4 0 00-.351 0L3.195 7.927a.4.4 0 00-.224.359v8.541a8.241 8.241 0 003.212 6.072l6.077 4.144a.4.4 0 00.451 0l2.091-1.426v2.662zm-.06-7.924a.4.4 0 00.472.647l1.895-1.393v3.452l-2.316 1.579-5.851-3.99a7.459 7.459 0 01-2.864-5.416V8.536l8.715-4.268L21.2 8.505v7.067a.4.4 0 00-.1.052zm14.645 8.377H15.603v-9.7l5.734-4.175 5.734 4.175zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
