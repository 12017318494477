import { all, call, put, takeLatest } from 'redux-saga/effects';
// import { store } from 'react-notifications-component';
import {
selectableServicesFailure,
selectableServicesSuccess
} from './actions';
import {
GET_SELECTABLE_SERVICE
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* selectedServices() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `https://conciergeapidemo.moveeasy.com/api/services/selectable-service/`,
        });
        if (response.data && response.status === 200) {
            yield put(selectableServicesSuccess({ selectableServices: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(selectableServicesFailure({ error: e.message }));
        // history.push('/server-error');
    }
}



export default function* moversSaga() {
    yield all([takeLatest(GET_SELECTABLE_SERVICE, selectedServices)]);

}
