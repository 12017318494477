import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromLanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={71}
            height={71}
            viewBox="0 0 71 71"
        >
            <path
                d="M69.855 33.21H59.549v-2.29a1.145 1.145 0 00-1.149-1.146h-1.579l-1.145-2.29h7.308a3.435 3.435 0 003.436-3.435V5.726a3.435 3.435 0 00-3.436-3.436H37.79a3.435 3.435 0 00-3.435 3.435v18.323a3.435 3.435 0 003.435 3.435h7.31l-1.145 2.29h-1.584a1.145 1.145 0 00-1.145 1.145v2.29H29.774v-5.724a1.145 1.145 0 00-1.145-1.145h-1.816l2.626-2.626-1.619-1.619-2.626 2.626v-2.962H22.9v4.581h-1.142a1.145 1.145 0 00-1.145 1.145v5.726H12.6v-3.436a1.145 1.145 0 00-1.145-1.145h-2.29v-9.372a3.435 3.435 0 002.29-3.225 3.379 3.379 0 00-.344-1.472l6.639-6.641 1.718 1.718v5.251a1.145 1.145 0 00.707 1.058 1.13 1.13 0 00.439.087 1.145 1.145 0 00.809-.335l9.161-9.161a1.145 1.145 0 00-.81-1.955h-5.251L20.277.336a1.145 1.145 0 00-1.619 0l-3.435 3.435a1.145 1.145 0 000 1.619l.908.908-6.642 6.642a3.379 3.379 0 00-1.473-.34 3.427 3.427 0 00-1.145 6.66v9.372h-2.29a1.145 1.145 0 00-1.145 1.145v3.433H1.145A1.145 1.145 0 000 34.355v6.871a1.145 1.145 0 001.145 1.145h3.436v27.484A1.145 1.145 0 005.726 71h4.581a1.145 1.145 0 001.145-1.054l2.2-27.576h43.694l2.2 27.576A1.145 1.145 0 0060.694 71h4.581a1.145 1.145 0 001.145-1.145V42.371h3.436A1.145 1.145 0 0071 41.226v-6.871a1.145 1.145 0 00-1.145-1.145zm-48.1-21.087V9.635l2.764-2.764h2.491zm-2.29-9.358l2.961 2.961-1.813 1.816-2.961-2.961zM8.016 14.887a1.145 1.145 0 11-1.145 1.145 1.145 1.145 0 011.145-1.145zM37.79 4.581h25.194a1.145 1.145 0 011.145 1.145v12.6H36.645v-12.6a1.145 1.145 0 011.145-1.145zm-1.145 19.467v-3.435h27.484v3.435a1.145 1.145 0 01-1.145 1.145H37.79a1.145 1.145 0 01-1.145-1.145zm11.015 3.436h5.456l1.145 2.29h-7.747zm-4.143 4.581h13.741v1.145H43.516zM22.9 28.629h4.581v4.581H22.9zm-17.174 2.29h4.581v2.29H5.726zM9.25 68.71H6.871V42.371h4.486zm54.88 0h-2.379l-2.107-26.339h4.486zm4.58-28.629H2.29V35.5h66.42z"
                fill={props.fromLanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'}
            />
        </svg>
    );
}

export default SvgComponent;
