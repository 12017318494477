import React from 'react';
import ButtonNew from '../atoms/Button';
import './upcomingTaskFirstCard.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CircularProgress } from '@material-ui/core';
type initialProps = {
    image?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    taskProgressValue?: any;
    saveMoneyTasks?: any;
    getStarted?: any;
    loader?: boolean;
};

const UpcomingTaskFirstCard = (props: initialProps) => {
    return (
        <div className="UpcomingTaskFirstCardMain">
            <div className="UpcomingTaskFirstCardLeftDiv">
                <div className="UpcomingTaskFirstTitle">{props.title}</div>
                <div className="UpcomingTaskFirstDescription">{props.description}</div>
                <div className="progress">
                    <div className="progressDiv">
                        <div className="progressBarDiv">
                            <LinearProgress
                                className=""
                                variant="determinate"
                                value={props.taskProgressValue ? props.taskProgressValue : 0}
                                style={{
                                    backgroundColor: '#E0E0E0',
                                    borderRadius: '15px',
                                    height: '7px',
                                }}
                            />
                        </div>
                        <div className="completedPercent">{`${
                            props.taskProgressValue ? props.taskProgressValue : '0'
                        }% completed`}</div>
                    </div>
                </div>
                <div className="checkDetailsMainDiv">
                    {props.saveMoneyTasks &&
                        props.saveMoneyTasks.map((item: any) => {
                            return (
                                <div className="checkDetailsDiv">
                                    <CheckCircleIcon className={item.completed ? 'checkIconCompleted' : 'checkIcon'} />
                                    <div className="checkTitleDiv">{item.name}</div>
                                </div>
                            );
                        })}
                </div>
                {props.loader ? (
                    <CircularProgress />
                ) : (
                    <div className="UpcomingTaskFirstButtonDiv">
                        <ButtonNew className="buttonUpcomingTaskFirst" onClick={props.getStarted}>
                            {props.buttonText}
                        </ButtonNew>
                    </div>
                )}
            </div>
            <div className="imageUpcomingTaskFirstDiv">
                <img src={props.image} className="imageUpcomingTaskFirst" />
            </div>
        </div>
    );
};

export default UpcomingTaskFirstCard;
