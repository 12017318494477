import React from 'react';
import { connect } from 'react-redux';
import './TrustedProsOrderConfirm.scss';
import SelectedVendor from '../../components/TrustedProsComponents/SelectedVendor';
import Button from '../../components/atoms/Button';
import phoneImage from '../../Assets/images/TrustedPros/phoneImage.svg';
import Card2Image from '../../Assets/images/TrustedPros/whatsNext2.png';
import GooglePlayImage from '../../Assets/images/TrustedPros/googlePlayImage.svg';
import AppStoreImage from '../../Assets/images/TrustedPros/appStoreImage.svg';
import ButtonNew from '../../components/atoms/Button';
import { bindActionCreators } from 'redux';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import * as CommonAction from '../../store/common/actions';
import { buttonClicked } from '../../utils/utlis';

type InitialProps = {
    history: any;
    location: any;
    trustedProsAction: any;
    trustedProsState: any;
    commonAction: any;
    commonState: any;
};
type InitialState = {
    slug: any;
    androidSlug: any;
    iosSlug: any;
};

class TrustedProsOrderConfirm extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: { primary_color: '' },
        androidSlug: '',
        iosSlug: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let androidSlug: any =
            this.props &&
            this.props.commonState &&
            this.props.commonState.move[0] &&
            this.props.commonState.move[0].realtor &&
            this.props.commonState.move[0].realtor.android_app_url;

        // let androidSlug: any =
        // this.props &&
        // this.props.commonState &&
        // &&
        // this.props.commonState.realtor &&
        // this.props.commonState.realtor.android_app_url;

        // let iosSlug: any =
        //     this.props &&
        //     this.props.commonState &&
        //     this.props.commonState.realtor &&
        //     this.props.commonState.realtor.ios_app_url;

        let iosSlug: any =
            this.props &&
            this.props.commonState &&
            this.props.commonState.move[0] &&
            this.props.commonState.move[0].realtor &&
            this.props.commonState.move[0].realtor.ios_app_url;

        this.setState({ slug: slug, androidSlug: androidSlug, iosSlug: iosSlug });
    }

    backToDashboard = async () => {
        if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
            this.props.history.push('/dashboardhomeOwner');
        } else {
            this.props.history.push('/dashboard');
        }
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        let agent_name: any = brokerageCheck.contact_name;
        buttonClicked(
            'Button Clicked',
            'Trusted Pros',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Order Confirmation Page',
            'Back to Dashboard',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    backVendorsHandler = async () => {
        await this.props.trustedProsAction.getMoveVendors();
        this.props.history.push('/dashboard/ourvendors');
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
                : [];
        return (
            <div>
                <div className="trustedPros-main-page">
                    <div className="trustedPros-OrderConfirmPage">
                        <div className="main-head-2">
                            <h2 className="heading">Trusted Pros</h2>
                            <div className="path">
                                <p
                                    className="Para"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    {' '}
                                    Dashboard {'>'}
                                </p>{' '}
                                <p
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        this.props.history.push('/dashboard/ourvendors');
                                    }}
                                >
                                    {' '}
                                    Trusted Pros
                                </p>
                                <p
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/dashboard/ourvendors/recommended',
                                            state: this.props.history.location.state,
                                        });
                                    }}
                                >
                                    {'>'} Vendors
                                </p>
                                <p
                                    className="para-para"
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/dashboard/ourvendors/order',
                                            state: this.props.history.location.state,
                                        });
                                    }}
                                >
                                    {'>'} Order Placing
                                </p>
                                <p className="para-para">{'>'} Order Summary</p>
                            </div>
                        </div>
                        <div className="trustedPros-orderConfirm-main-div">
                            <div className="orderConfirm-main-title">Done! Request Submitted</div>
                            <div className="orderConfirm-main-para">Your trusted pro will contact you shortly</div>

                            <div className="venderList">
                                <div className="venderListTitle">Selected vendor</div>
                                {this.props.location.state.selectedVendors &&
                                    this.props.location.state.selectedVendors.map((item: any) => {
                                        return (
                                            <SelectedVendor
                                                image={
                                                    item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1)
                                                }
                                                name={item.name}
                                                id={item.id}
                                                fromOrderConfirm={true}
                                                type={item.type_name}
                                            />
                                        );
                                    })}
                                <div className="selectedAddressBox">
                                    <div className="selectedAddressDiv">
                                        <div className="selectedAddressTitle">Selected address</div>
                                        <div className="selectedAddress">{this.props.location.state.address}</div>
                                    </div>
                                    <div className="dateTimeDiv">
                                        <div className="dateDiv">
                                            <div className="selectedAddressTitle">Date Preference</div>
                                            <div className="selectedAddress">{this.props.location.state.date}</div>
                                        </div>
                                        <div className="timeDiv">
                                            <div className="selectedAddressTitle">Time Preference</div>
                                            <div className="selectedAddress">{this.props.location.state.time}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="whatsNextDiv">
                                    <div className="whatsNextTitle">What’s Next?</div>
                                    {this.state.androidSlug == '' && this.state.iosSlug == '' ? null : (
                                        <div className="whatsNextCard1">
                                            <div className="card1ContentDiv">
                                                <div className="card1Title">
                                                    Download the app to manage Your project
                                                </div>
                                                <div className="card1AppDownloadImageDiv">
                                                    {this.state.iosSlug == '' ? null : (
                                                        <div className="card1Image">
                                                            <img src={AppStoreImage} />
                                                        </div>
                                                    )}
                                                    {this.state.androidSlug == '' ? null : (
                                                        <div className="card1Image">
                                                            <img src={GooglePlayImage} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {!isDouglasElliman ? (
                                                <div className="card1ImageDiv">
                                                    <img src={phoneImage} />
                                                </div>
                                            ) : (
                                                <div className="card1ImageDiv">
                                                    <img src={phoneImage} height={100} width={0} />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className="whatsNextCard2"
                                        style={{
                                            backgroundColor: isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : '#273E59',
                                        }}
                                    >
                                        <div className="card2ContentDiv">
                                            <div className="card2Title">Search another New pro for your project</div>
                                            <div className="card1AppDownloadImageDiv">
                                                <div className="card1Image">
                                                    <Button
                                                        className="searchProButton"
                                                        onClick={() => {
                                                            this.backVendorsHandler();
                                                        }}
                                                        backgroundColor={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : this.state.slug && this.state.slug.primary_color
                                                                ? this.state.slug.primary_color
                                                                : '#273E59'
                                                        }
                                                    >
                                                        Search Pro
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        {!isDouglasElliman && (
                                            <div className="card1ImageDiv">
                                                <img src={Card2Image} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="btn-wrapper">
                                        <ButtonNew
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            // color="#FFFFFF"
                                            fontSize="16px"
                                            onClick={() => {
                                                this.backToDashboard();
                                            }}
                                        >
                                            Back to Dashboard
                                        </ButtonNew>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mortgage-last-div">
                    <div className="mortgage-pleasenote">Please Note:</div>
                    <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                        {displayData && displayData[0] && displayData[0].disclaimer ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: displayData && displayData[0] && displayData[0].disclaimer,
                                }}
                            />
                        ) : (
                            'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    trustedProsState: state.trustedPros,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsOrderConfirm);
