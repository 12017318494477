import React, { Component } from 'react';
import './ICmain.scss';
import Button from '../../components/atoms/Button';
import TextField from '@material-ui/core/TextField';
import AutoComplete from '../../components/atoms/autoComplete';
// import Select from 'react-select';
import SelectInput from '../../components/atoms/SelectInput';
import { list } from '../../components/atoms/USAlist';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import Footer from './Footer';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
// import MenuItem from '@material-ui/core/MenuItem';

type initialProps = {
    street?: string;
    state?: string;
    city?: string;
    zipcode?: string;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    dashboardState: any;
    history?: any;
    location?: any;
};

type initialState = {
    address: string;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    moveId: any;
    continueClick: boolean;
    serviceId: any;
    hostname: string;
};

export class address extends Component<initialProps, initialState> {
    state: initialState = {
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        continueClick: false,
        serviceId: '',
        hostname: '',
    };

    componentDidMount() {
        const domain = window.location.hostname;
        if (domain && domain.split('.')[0]) {
            this.setState({ hostname: domain.split('.')[0] });
        }

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const isHomeOwner = localStorage.getItem('isHomeOwner');
        if (
            this.props &&
            this.props.commonState &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            this.props.commonAction.header({
                partner_slug: slug.slug,
                fetchMoveService: isHomeOwner ? 1 : 0,
                moveId: this.props.commonState.move[0].id,
            });
        }
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.data &&
            (domain.split('.')[0].includes('internet') || domain.split('.')[0].includes('internet-demo'))
        ) {
            const data =
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.data;
            const dest: any = data && data.destination;

            this.setState({
                city: dest && dest.lookup_name,
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                state: { name: dest && dest.state_data, label: dest && dest.state_data },
            });
        } else if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.data &&
            !(domain.split('.')[0].includes('internet') || domain.split('.')[0].includes('internet-demo'))
        ) {
            const data = this.props.history.location.state.data;
            const dest: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                moveId: data && data.id,
            });
        } else {
            this.props.commonAction.moveGet();
        }
        this.props.dashboardAction.checklist();
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest: any = cur.move[0] && cur.move[0].destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: cur.move[0] && cur.move[0].destination_street,
                address: cur.move[0] && cur.move[0].destination_full_address,
                moveId: cur.move[0] && cur.move[0].id,
            });
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }
        if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
            if (prev.header !== cur.header && cur.header && cur.header.length > 0) {
                cur.header.map((el: any) => {
                    if (el && el.react_url_route === '/dashboard/internet') {
                        this.setState({ serviceId: el.id });
                    }
                });
            }
        } else {
            if (
                (prevProps && prevProps.dashboardState && prevProps.dashboardState.checklist) !==
                    (this.props && this.props.dashboardState && this.props.dashboardState.checklist) &&
                this.props.dashboardState.checklist &&
                this.props.dashboardState.checklist.length > 0
            ) {
                this.props.dashboardState.checklist.map((el: any) => {
                    if (el && el.do_it_now && el.do_it_now.new_url.includes('/dashboard/internet')) {
                        this.setState({ serviceId: el.id });
                    }
                });
            }
        }
    }

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    streetHandler = (e: any) => {
        this.setState({
            street: e.target.value,
        });
    };
    cityHandler = (e: any) => {
        this.setState({
            city: e.target.value,
        });
    };
    stateHandler = (e: any) => {
        this.setState({
            state: e,
        });
    };

    zipcodeHandler = (e: any) => {
        this.setState({
            zipcode: e.target.value,
        });
    };

    continueHandler = () => {
        this.setState({ continueClick: true });

        const { street, state, zipcode, city, address, moveId, serviceId } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding'
        ) {
            if (street && state && zipcode && city && address) {
                const data = {
                    destination: {
                        lookup_name: city,
                        zip_code: zipcode,
                        state_data: state,
                    },
                    destination_street: street,
                    destination_full_address: this.state.address,
                };

                this.props.history.push({
                    pathname: '/landing/internet/steps',
                    state: {
                        type: 'edit',
                        fromLanding: 'fromLanding',
                        data:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.data
                                ? this.props.history.location.state.data
                                : data,
                    },
                });
            }
        } else {
            if (street && state && zipcode && city && address && moveId) {
                this.props.dashboardAction.updateServicePercentage({
                    data: {
                        completed_percentage: 25,
                    },
                    service_id: serviceId,
                });
                this.props.commonAction.moveAddressUpdate({
                    destination_detail: `${street}, ${city}, ${state && state.label}, ${zipcode}`,
                    move_id: moveId,
                });

                this.props.history.push({ pathname: '/dashboard/internet/steps', state: { type: 'edit' } });
            }
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        // const isHomeOwner = localStorage.getItem('isHomeOwner');
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                {/* <div style={{ marginTop: '40px' }}></div> */}
                <div className="main-head">
                    <h2 className="heading">Internet And Cable</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Internet And Cable</p>
                    </div>
                    <div
                        className={
                            isDouglasElliman ? 'AddressCardInternet AddressCardInternetDouglas' : 'AddressCardInternet'
                        }
                    >
                        <div className={`addressCard Internet ${this.state.state ? 'slectedAdressIC' : ''}`}>
                            <div className="boxCard">
                                <div className="heading">
                                    Let's start by verifying your address to see what's available near you
                                </div>
                                <div className="Para1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>Why do we ask for your address?</div>
                                    <div style={{ marginLeft: '7px', height: '15px' }}>
                                        <img src={iIcon} height="15px" width="15px" />
                                    </div>
                                </div>
                                {/* <p style={{ color: '#0066F5', textDecoration: 'underline', cursor: 'pointer' }}>
                                <GpsFixedIcon fontSize="small" /> Use my current location
                            </p> */}
                                <div className="inputs">
                                    <div className={isDouglasElliman ? 'innerInput1 innerInput1D' : 'innerInput1'}>
                                        <AutoComplete
                                            value={this.state.street}
                                            onChange={e => {
                                                this.setState({ street: e });
                                            }}
                                            onSelect={this.handleSelect}
                                            placeholder="Street"
                                            // inputTitle="To"
                                            error={this.state.continueClick && this.state.street === ''}
                                            helperText={
                                                this.state.continueClick && this.state.street === ''
                                                    ? 'Please enter street'
                                                    : ''
                                            }
                                        />
                                        {/* <div className="error">
                                            {this.state.continueClick &&
                                                this.state.street === '' &&
                                                'Please enter street'}
                                        </div> */}
                                    </div>
                                    <div className="innerInputs">
                                        <div className={isDouglasElliman ? 'input2 input2D' : 'input2'}>
                                            <TextField
                                                className={isDouglasElliman ? 'input2 input2D' : 'input2'}
                                                label="City"
                                                id="outlined-start-adornment"
                                                onChange={this.cityHandler}
                                                variant="filled"
                                                value={this.state.city}
                                                error={this.state.continueClick && this.state.city === ''}
                                                helperText={
                                                    this.state.continueClick && this.state.city === ''
                                                        ? 'Please enter city'
                                                        : ''
                                                }
                                            />
                                            {/* <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.city === '' &&
                                                    'Please enter city'}
                                            </div> */}
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            {this.state.state && <div className="stateTextAddressInternet">State</div>}
                                            <SelectInput
                                                className="react-select input3"
                                                options={list}
                                                placeholder={this.state.state ? this.state.state : 'State'}
                                                value={this.state.state}
                                                onChange={(e: any) => {
                                                    this.stateHandler(e);
                                                }}
                                            />
                                            {/* <TextField
                                                id="outlined-select-currency"
                                                select
                                                className="react-select input3"
                                                label="State"
                                                value={this.state.state && this.state.state.label}
                                                onChange={(e: any) => this.stateHandler(e)}
                                                // helperText="Please select your currency"
                                                variant="filled"
                                                // disabled={this.state.state!==""}
                                            >
                                                {list.map((option: any) => (
                                                    <MenuItem key={option.name} value={option.name}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            {/* <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.state}
                                                onChange={(e: any) => {
                                                    this.stateHandler(e);
                                                }}
                                                className="react-select input3"
                                                MenuProps={{ classes: { paper: 'menuPaper' } }}
                                            >
                                                {list.map((el: any) => (
                                                    <MenuItem value={el.value}>{el.label}</MenuItem>
                                                ))}
                                            </Select> */}
                                            <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.state === {} &&
                                                    'Please select state'}
                                            </div>
                                        </div>
                                        <div className={isDouglasElliman ? 'input4 input4D' : 'input4'}>
                                            <TextField
                                                className={isDouglasElliman ? 'input4 input4D' : 'input4'}
                                                label="Zip code"
                                                id="outlined-start-adornment"
                                                onChange={this.zipcodeHandler}
                                                variant="filled"
                                                value={this.state.zipcode}
                                                error={this.state.continueClick && this.state.zipcode === ''}
                                                helperText={
                                                    this.state.continueClick && this.state.zipcode === ''
                                                        ? 'Please enter  zip code'
                                                        : ''
                                                }
                                            />
                                            {/* <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.zipcode === '' &&
                                                    'Please enter Zip code'}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <FormControlLabel
                                label="I live in an apartment"
                                control={
                                    <Checkbox
                                        color="primary"
                                        className="checkbox"
                                        checked={this.state.checkedG}
                                        onChange={this.handleChange}
                                        name="checkedG"
                                    />
                                }
                            /> */}
                                <div className="addressInternetBtn">
                                    <Button
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.props &&
                                                  this.props.history.location &&
                                                  this.props.history.location.state &&
                                                  this.props.history.location.state.fromLanding &&
                                                  this.props.history.location.state.fromLanding === 'fromLanding'
                                                ? '#30AB86'
                                                : '#273E59'
                                        }
                                        marginTop="30px"
                                        width="100%"
                                        height="50px"
                                        fontSize="16px"
                                        disabled={
                                            this.state.state &&
                                            this.state.zipcode &&
                                            this.state.city &&
                                            this.state.street
                                                ? false
                                                : true
                                        }
                                        onClick={this.continueHandler}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    fromLanding={
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.fromLanding &&
                        this.props.location.state.fromLanding === 'fromLanding'
                    }
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    dashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(address);
