import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 31.801}
            height={props.height ? props.height : 30.4}
            viewBox="0 0 31.801 30.4"
        >
            <defs>
                <style>
                    {/* {".prefix__b{fill:#fff}.prefix__c{fill:#6b6c6f}"} */}
                    {`.TrustedProsSvgprefix__b{fill:#fff}.TrustedProsSvgprefix__c{fill:${
                        window.location.pathname.includes('ourvendors') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}.TrustedProsSvgSvgprefix__d{fill:${
                        window.location.pathname.includes('ourvendors') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}`}
                </style>
            </defs>
            <path
                d="M14.229 17.199l-1.555-1.555-1.917 1.054-2.99 2.99a1.22 1.22 0 000 1.727l.691.691a1.221 1.221 0 001.727 0l2.99-2.99zm17.109-5.5h-4.522v18.237H4.985V11.702H.463L15.901.463l8.812 6.416 1.795 1.307 1.45 1.056zm-7.953 8.1a3.437 3.437 0 00.829-3.52l-1.393 1.394-1.794-.36-.328-1.85 1.353-1.344a3.437 3.437 0 00-4.223 4.682l-5.49 5.49a1.202 1.202 0 101.7 1.7l5.49-5.489a3.437 3.437 0 003.856-.701zm0 0"
                // fill="#bebebe"
                className="TrustedProsSvgSvgprefix__d"
            />
            <path
                className="TrustedProsSvgprefix__b"
                d="M27.972 3.627v5.595l-.014.02-1.45-1.056-1.795-1.307V3.627zm0 0M24.214 16.283a3.437 3.437 0 01-4.684 4.218l-5.49 5.489a1.202 1.202 0 01-1.7-1.7l5.49-5.49a3.437 3.437 0 014.223-4.682L20.7 15.462l.328 1.854 1.794.36zm0 0M14.229 17.2l-1.053 1.917-2.99 2.99a1.221 1.221 0 01-1.727 0l-.691-.691a1.22 1.22 0 010-1.727l2.99-2.99 1.917-1.054zm0 0"
            />
            <path
                className="TrustedProsSvgprefix__c"
                d="M13.583 19.338l.756-1.376.077.077a.463.463 0 00.655-.655l-.963-.963 5.074-5.073a.463.463 0 00-.655-.655l-5.076 5.074-.963-.963a.463.463 0 00-.655.655l.077.077-1.376.756a.475.475 0 00-.1.078l-2.989 2.99a1.683 1.683 0 000 2.383l.691.691a1.687 1.687 0 002.382 0l2.99-2.99a.456.456 0 00.075-.106zm-3.724 2.44a.759.759 0 01-1.073 0l-.691-.691a.756.756 0 010-1.072l2.943-2.943 1.556-.854 1.063 1.063-.854 1.555zm0 0M24.318 15.829a.463.463 0 00-.434.124l-1.218 1.218-1.241-.249-.231-1.3 1.181-1.173a.463.463 0 00-.179-.768 3.878 3.878 0 00-4 .938 3.92 3.92 0 00-.915 4.072l-5.273 5.273a1.666 1.666 0 002.355 2.355l5.273-5.273a3.92 3.92 0 004.072-.915 3.88 3.88 0 00.941-3.994.463.463 0 00-.331-.308zm-1.263 3.644a2.987 2.987 0 01-3.336.6.463.463 0 00-.52.094l-5.489 5.489a.74.74 0 01-1.046-1.045l5.49-5.49a.464.464 0 00.094-.52 2.987 2.987 0 01.6-3.336 2.954 2.954 0 012.095-.872h.158l-.734.729a.463.463 0 00-.13.409l.328 1.854a.463.463 0 00.365.374l1.794.36a.464.464 0 00.419-.127l.778-.778a2.957 2.957 0 01-.863 2.259zm0 0"
            />
            <path
                className="TrustedProsSvgprefix__c"
                d="M31.61 11.328l-3.175-2.312V3.627a.463.463 0 00-.463-.463h-3.259a.463.463 0 00-.463.463v2.342L16.173.089a.463.463 0 00-.545 0L.19 11.328a.463.463 0 00.273.838h4.058v17.77a.463.463 0 00.463.463h21.832a.463.463 0 00.463-.463v-17.77h4.059a.463.463 0 00.273-.838zM25.176 4.09h2.333v4.252l-2.333-1.7zm1.64 7.149a.463.463 0 00-.463.463v17.771H5.447V11.7a.463.463 0 00-.463-.463h-3.1L15.9 1.036l11.784 8.58 2.23 1.623zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
