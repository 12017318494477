import React, { useEffect } from 'react';
import './utilityNotFound.scss';
import UtilityNotFoundImage from '../../Assets/images/utilityImages/utilityNotFoundImage.png'
// import AutoComplete from '../../components/atoms/autoComplete';
// import Button from '../../components/atoms/Button';
// import RoomIcon from '@material-ui/icons/Room';
// import { geocodeByAddress } from 'react-places-autocomplete';


const UtilityNotFound = (props: any) => {
    const { slug } = props;

    // const [addressData, setAddressData] = useState({
    //     street: '',
    //     city: '',
    //     apt: '',
    //     zipcode: '',
    //     address: '',
    // });

    useEffect(() => {
        const addressObjStr = localStorage.getItem('pods_destination_address');
        if (addressObjStr) {
            // const addressObj = JSON.parse(addressObjStr);
            // setAddressData(addressObj);
        }
        return () => { }
    }, [])


    // const onChangeHandlerAddress = async (e: any, name: string) => {
    //     // debugger
    //     let addressDatas: any;
    //     if (name === 'address') {
    //         addressDatas = { ...addressData, [name]: e };
    //     } else {
    //         addressDatas = { ...addressData, [name]: e.target.value };
    //     }
    //     setAddressData(addressDatas)
    // };

    // const handleSelect = (address: any) => {
    //     geocodeByAddress(address)
    //         .then(results => {
    //             let city: any = '',
    //                 state: any = '',
    //                 zip: any = '',
    //                 country: any = '',
    //                 route: any = '',
    //                 streetNumber: any = '';
    //             results &&
    //                 results[0] &&
    //                 results[0].address_components &&
    //                 results[0].address_components.map(el => {
    //                     state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
    //                     zip = el.types.includes('postal_code') ? el.short_name : zip;
    //                     city = el.types.includes('locality')
    //                         ? el.long_name
    //                         : el.types.includes('sublocality')
    //                             ? el.long_name
    //                             : city;
    //                     streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
    //                     route = el.types.includes('route') ? el.short_name : route;
    //                     country = el.types.includes('country') ? el.short_name : country;
    //                 });

    //             const addressString = results[0].formatted_address;
    //             let addressObj: any = {
    //                 street: `${streetNumber} ${route}`,
    //                 city,
    //                 zipcode: zip,
    //                 address: addressString,
    //                 state
    //             };
    //             localStorage.setItem('pods_destination_address', JSON.stringify(addressObj));
    //             onAddressChange();
    //             if (country === 'USA' || country === 'US') {
    //                 setAddressData(addressObj)
    //             }
    //         })
    //         .catch(error => console.error('error', error));
    // };

    return (
        <div>
            {/* <div className="utilityfirstDiv">
                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Your address</div>
                <div
                    className={'utilitysecondDiv'}
                >
                    <RoomIcon
                        className={'utilitylocationIcon'}
                    />
                    <AutoComplete
                        value={addressData && addressData?.address}
                        onChange={e => onChangeHandlerAddress(e, 'address')}
                        onSelect={handleSelect}
                        className="utility"
                        edit={true}
                    />
                </div>
            </div> */}

            <div className='utility-section'>
                <div className='utitlity-image'>
                    <img src={UtilityNotFoundImage} />
                </div>
                <div className='utility-oops-text'>
                    <span style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font } : {}}>Call your local utility providers to establish service</span>
                    <p>Your state or city will have resources to help you determine your address' service providers. <br />Contact your utility providers early to schedule the transfer, cancellation, or set up of your utilities.</p>
                </div>
                {/* <div className='utility-btn'>
                <Button> Submit Request</Button>
            </div> */}

            </div>
        </div >
    )

}

export default UtilityNotFound