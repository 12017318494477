import * as React from 'react';
import { navigationIconColorSelector } from '../../_fill_calculator';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 24.218}
            height={props.height ? props.height : 31}
            viewBox="0 0 24.218 31"
        >
            <defs>
                <style>
                    {/* {".prefix__c{fill:#6b6c6f}"} */}
                    {`.utilitySvgprefix__c{
                        fill:${ navigationIconColorSelector(props.active, "b", "fill") }
                        stroke: ${ navigationIconColorSelector(props.active, "a", "stroke" )} !important;

                    }.utilitySvgprefix__b{
                        fill:${ navigationIconColorSelector(props.active, "a", "fill") }
                    }`}
                </style>
            </defs>
            <path
                d="M23.763 18.891a11.655 11.655 0 01-23.309 0C.454 8.97 12.109.454 12.109.454S23.763 8.97 23.763 18.891zm0 0"
                fill="#fff"
            />
            <path
                d="M15.83 9.787h-5.037l-4.015 7.349 4.56-.007-2.729 6.567 9.012-8.958h-5.085zm0 0"
                // fill="#bebebe"
                className="utilitySvgprefix__b"
            />
            <path
                className="utilitySvgprefix__c"
                d="M20.756 21.605a12.031 12.031 0 00.39-1.279.454.454 0 10-.882-.216 11.191 11.191 0 01-.361 1.183.454.454 0 00.853.313zm0 0M16.744 27.05a12.211 12.211 0 003.513-4.282.454.454 0 10-.815-.4 11.3 11.3 0 01-3.251 3.962.454.454 0 10.552.721zm0 0M8.929 24.018l9.012-8.958a.454.454 0 00-.32-.776h-4.236l2.823-4.245a.454.454 0 00-.378-.706h-5.039a.453.453 0 00-.4.236l-2.118 3.873a.455.455 0 10.8.435l1.989-3.637h3.92l-2.823 4.245a.454.454 0 00.378.706h3.983l-6.62 6.58 1.857-4.469a.454.454 0 00-.419-.628l-3.794.006 1.54-2.819a.455.455 0 00-.8-.435l-1.908 3.492a.454.454 0 00.4.672l3.879-.006-2.467 5.938a.455.455 0 00.741.496zm0 0"
            />
            <path
                className="utilitySvgprefix__c"
                d="M21.764 10.524a.454.454 0 00-.79.448 16.153 16.153 0 012.336 7.92 11.2 11.2 0 01-22.4 0 15.439 15.439 0 011.769-6.854 28.107 28.107 0 013.933-5.774 40.277 40.277 0 015.5-5.239 56.829 56.829 0 018.868 9.944.454.454 0 00.757-.5A60.065 60.065 0 0012.377.087a.454.454 0 00-.536 0 39.468 39.468 0 00-5.9 5.568 29.058 29.058 0 00-4.069 5.967A16.339 16.339 0 000 18.891a12.109 12.109 0 0024.218 0 17.042 17.042 0 00-2.454-8.367zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
