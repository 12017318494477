// import axios from "axios";
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    getEnergyTypeFailure,
    getEnergyTypeSuccess,
    getCompanyFailure,
    getCompanySuccess,
    getEnergyPlanFailure,
    getEnergyPlanSuccess,
    getSwitchTypeFailure,
    getSwitchTypeSuccess,
    postSubmitContractFailure,
    postSubmitContractSuccess,
    getEnergySummaryFailure,
    getEnergySummarySuccess,
    getAccessTokenFailure,
    getAccessTokenSuccess,
    getDisclaimerDetailsFailure,
    getDisclaimerDetailsSuccess,
    getAccountNumberFailure,
    getAccountNumberSuccess,
} from './actions';
import {
    GET_COMPANY,
    GET_ENERGY_TYPE,
    GET_ENERGY_PLAN,
    GET_SWITCH_TYPE,
    POST_SUBMIT_CONTRACT,
    GET_ENERGY_SUMMARY,
    GET_ACCESS_TOKEN,
    GET_DISCLAIMER_DETAILS,
    GET_ACCOUNT_NUMBER,
} from './actionTypes';
// import { ITodo } from "./types";
import apiJunction from '../../utils/api';
import { uiConfig } from '../../config';

// const getenergy = () => axios.get<ITodo[]>("https://jsonplaceholder.typicode.com/energy");

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}
/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/

function* getEnergyType() {
    try {
        let token: any = '';
        if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
            token = localStorage.getItem('moveeasytoken');
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/deregulated-energy/get-list-of-utility-service-types/`,
            token: token,
        });
        yield put(
            getEnergyTypeSuccess({
                getEnergyType: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getEnergyTypeFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getEnergyTypePODS() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `/api/pods/deregulated-energy/get-list-of-utility-service-types/`,
        });
        yield put(
            getEnergyTypeSuccess({
                getEnergyType: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getEnergyTypeFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getCompany(action: any) {
    try {
        let token: any = '';
        if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
            token = localStorage.getItem('moveeasytoken');
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/deregulated-energy/get-utilities-by-zipcode/?service_type_id=${action.payload.service_type_id}`,
            token: token,
        });
        yield put(
            getCompanySuccess({
                getCompany: response.data,
            }),
        );

        if (response && response.data && response.data.utilities && response.data.utilities.length == 0) {
            store.addNotification({
                title: 'Warning',
                message:
                    response.data.message && response.data.message != ''
                        ? response.data.message
                        : `We don't have any providers for this address go back and take the utilities privileges.`,
                type: 'warning',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(
            getCompanyFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getCompanyPODS(action: any) {
    // debugger
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `/api/pods/deregulated-energy/get-utilities-by-zipcode/?service_type_id=${action.payload.service_type_id}&zip_code=${action.payload.zip_code}`,
        });
        yield put(
            getCompanySuccess({
                getCompany: response.data,
            }),
        );

        if (response && response.data && response.data.utilities && response.data.utilities.length == 0) {
            store.addNotification({
                title: 'Warning',
                message:
                    response.data.message && response.data.message != ''
                        ? response.data.message
                        : `We don't have any providers for this address go back and take the utilities privileges.`,
                type: 'warning',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(
            getCompanyFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getEnergyPlan(action: any) {
    try {
        let token: any = '';
        if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
            token = localStorage.getItem('moveeasytoken');
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/deregulated-energy/get-rates/`,
            body: action.payload,
            token: token,
        });
        yield put(
            getEnergyPlanSuccess({
                getEnergyPlan: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getEnergyPlanFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getEnergyPlanPODS(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'post',
            url: `/api/pods/deregulated-energy/get-rates/`,
            body: action.payload,
        });
        yield put(
            getEnergyPlanSuccess({
                getEnergyPlan: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getEnergyPlanFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getSwitchType(action: any) {
    try {
        let token: any = '';
        if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
            token = localStorage.getItem('moveeasytoken');
        }

        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/deregulated-energy/get-switch-types/?service_type_id=${action.payload.service_type_id}&state_id=${action.payload.state_id}&utility_id=${action.payload.utility_id}&supplier_id=${action.payload.supplier_id}&effective_date=${action.payload.effective_date}&utility_account_number=${action.payload.utility_account_number}&usage=${action.payload.usage}`,
            // body: action.payload,
            token: token,
        });
        yield put(
            getSwitchTypeSuccess({
                getSwitchType: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getSwitchTypeFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getSwitchTypePODS(action: any) {
    try {
        let token: any = '';

        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `/api/pods/deregulated-energy/get-switch-types/?service_type_id=${action.payload.service_type_id}&state_id=${action.payload.state_id}&utility_id=${action.payload.utility_id}&supplier_id=${action.payload.supplier_id}&effective_date=${action.payload.effective_date}&utility_account_number=${action.payload.utility_account_number}&usage=${action.payload.usage}`,
            // body: action.payload,
            token: token,
        });
        yield put(
            getSwitchTypeSuccess({
                getSwitchType: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getSwitchTypeFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* postSubmitContract(action: any) {
    try {
        let isPODS: boolean = uiConfig.appType == "WITHOUT_AUTH" ? true : false;
        let token: any = '';
        if(isPODS){
            if (localStorage.getItem('pods-es-token') && localStorage.getItem('pods-es-token') !== null) {
                token = localStorage.getItem('pods-es-token');
            }
        }else{
            if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
                token = localStorage.getItem('moveeasytoken');
            }
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/deregulated-energy/submit-contract/`,
            body: action.payload,
            token: token,
        });
        yield put(
            postSubmitContractSuccess({
                postSubmitContract: response.data,
            }),
        );
    } catch (e) {
        yield put(
            postSubmitContractFailure({
                postSubmitContractError: e.response && e.response.data && e.response.data.message,
            }),
        );
        store.addNotification({
            title: 'Failure',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

function* getEnergySummary() {
    try {
        let isPODS: boolean = uiConfig.appType == "WITHOUT_AUTH" ? true : false;
        let token: any = '';
        if(isPODS){
            if (localStorage.getItem('pods-es-token') && localStorage.getItem('pods-es-token') !== null) {
                token = localStorage.getItem('pods-es-token');
            }
        }else{
            if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
                token = localStorage.getItem('moveeasytoken');
            }
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/deregulated-energy/get-summary-frontend/`,
            token: token,
        });
        yield put(
            getEnergySummarySuccess({
                getEnergySummary: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getEnergySummaryFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getAccessToken() {
    try {
        let token: any = '';
        if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
            token = localStorage.getItem('moveeasytoken');
        }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/deregulated-energy/get-access-token/`,
            // body: action.payload,
            token: token,
        });
        yield put(
            getAccessTokenSuccess({
                getAccessToken: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getAccessTokenFailure({
                error: (e as Error).message,
            }),
        );
    }
}
function* getAccessTokenPODS() {
    try {
        let token: any = '';
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `/api/pods/deregulated-energy/get-access-token/`,
            // body: action.payload,
            token: token,
        });
        yield put(
            getAccessTokenSuccess({
                getAccessToken: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getAccessTokenFailure({
                error: (e as Error).message,
            }),
        );
    }
}
function* getDisclaimerDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/services/deregulated-energy/get-signup-requirements/`,
            body: action.payload,
            // token: token,
        });
        yield put(
            getDisclaimerDetailsSuccess({
                getDisclaimerDetails: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getDisclaimerDetailsFailure({
                error: (e as Error).message,
            }),
        );
    }
}
function* getDisclaimerDetailsPODS(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'post',
            url: `api/pods/deregulated-energy/get-signup-requirements/`,
            body: action.payload,
            // token: token,
        });
        yield put(
            getDisclaimerDetailsSuccess({
                getDisclaimerDetails: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getDisclaimerDetailsFailure({
                error: (e as Error).message,
            }),
        );
    }
}
function* getAccountNumber(action: any) {
    try {
        // let token: any = '';
        // if (localStorage.getItem('moveeasytoken') && localStorage.getItem('moveeasytoken') !== null) {
        //     token = localStorage.getItem('moveeasytoken');
        // }
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/services/deregulated-energy/find-account-number/?address=${action.payload.address}`,
            // body: action.payload,
            // token: token,
        });
        yield put(
            getAccountNumberSuccess({
                getAccountNumber: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getAccountNumberFailure({
                error: (e as Error).message,
            }),
        );
    }
}

function* getAccountNumberPODS(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequestWithoutAuth, {
            method: 'get',
            url: `api/pods/deregulated-energy/find-account-number/?address=${action.payload.address}`,
            // body: action.payload,
            // token: token,
        });
        yield put(
            getAccountNumberSuccess({
                getAccountNumber: response.data,
            }),
        );
    } catch (e) {
        yield put(
            getAccountNumberFailure({
                error: (e as Error).message,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* energySaga() {
    let isPODS: boolean = uiConfig.appType == "WITHOUT_AUTH" ? true : false;
    yield all([
        takeLatest(GET_ENERGY_TYPE,  isPODS ? getEnergyTypePODS : getEnergyType),
        takeLatest(GET_COMPANY, isPODS ? getCompanyPODS : getCompany),
        takeLatest(GET_ENERGY_PLAN, isPODS ? getEnergyPlanPODS : getEnergyPlan),
        takeLatest(GET_SWITCH_TYPE, isPODS ? getSwitchTypePODS : getSwitchType),
        takeLatest(POST_SUBMIT_CONTRACT,  postSubmitContract),
        takeLatest(GET_ENERGY_SUMMARY,  getEnergySummary),
        takeLatest(GET_ACCESS_TOKEN, isPODS? getAccessTokenPODS : getAccessToken),
        takeLatest(GET_DISCLAIMER_DETAILS, isPODS ? getDisclaimerDetailsPODS : getDisclaimerDetails),
        takeLatest(GET_ACCOUNT_NUMBER, isPODS ? getAccountNumberPODS : getAccountNumber),
    ]);
}

export default energySaga;
