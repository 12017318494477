import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    postFoldersFailure,
    postFoldersSuccess,
    getFoldersFailure,
    getFoldersSuccess,
    postDocumentsFailure,
    postDocumentsSuccess,
    getDocumentsFailure,
    getDocumentsSuccess,
    updateDocumentsFailure,
    updateDocumentsSuccess,
    deleteDocumentsFailure,
    deleteDocumentsSuccess,
} from './actions';
import {
    POST_FOLDERS,
    GET_FOLDERS,
    POST_DOCUMENTS,
    GET_DOCUMENTS,
    UPDATE_DOCUMENTS,
    DELETE_DOCUMENTS,
} from './actionTypes';
import apiJunction from '../../utils/api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}
function errorNotification(key: any, message: any) {
    store.addNotification({
        title: `Error ${key} `,
        message: message,

        type: 'danger',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
            onScreen: true,
        },
    });
}
function* postFolders(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-uploads/create-folders/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(postFoldersSuccess({ postFolders: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postFoldersFailure({ error: e && e.response && e.response.data }));
        if (e && e.response && e.response.data) {
            // for (let d of e.response.data) {
            let data = e.response.data;
            for (let key of Object.keys(data)) {
                let array = data[key];
                for (let element of array) {
                    if (typeof element == 'string') {
                        errorNotification(key, element);
                    }
                    if (typeof element == 'object') {
                        for (let key1 of Object.keys(element)) {
                            errorNotification(key1, element[key1][0]);
                        }
                    }
                }
            }
            // }
        }
        // history.push('/server-error');
    }
}

function* getFolders(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-uploads/get-folders/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getFoldersSuccess({ getFolders: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getFoldersFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* postDocuments(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-uploads/`,
            body: action.payload.formData,
        });
        if (response.data) {
            yield put(postDocumentsSuccess({ postDocuments: response.data }));
            store.addNotification({
                title: 'Success',
                message: 'Document Added Successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postDocumentsFailure({ error: (e as Error).message }));
        if (e && e.response && e.response.data) {
            let data = e.response.data;
            for (let key of Object.keys(data)) {
                let array = data[key];
                for (let element of array) {
                    if (typeof element == 'string') {
                        errorNotification(key, element);
                    }
                }
            }
        }
        // history.push('/server-error');
    }
}

function* getDocuments() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-uploads/`,
            // body: action.payload,
        });
        if (response.data) {
            yield put(getDocumentsSuccess({ getDocuments: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getDocumentsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* updateDocuments(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/move/move-uploads/${action.payload.id}/`,
            body: action.payload.formData,
        });
        if (response.data) {
            yield put(updateDocumentsSuccess({ updateDocuments: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateDocumentsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* deleteDocuments(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'delete',
            url: `/api/move/move-uploads/${action.payload.id}/`,
        });
        if (response.status && response.status == 204) {
            yield put(deleteDocumentsSuccess({ deleteDocuments: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(deleteDocumentsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* documentrySaga() {
    yield all([
        takeLatest(POST_FOLDERS, postFolders),
        takeLatest(GET_FOLDERS, getFolders),
        takeLatest(GET_DOCUMENTS, getDocuments),
        takeLatest(POST_DOCUMENTS, postDocuments),
        takeLatest(UPDATE_DOCUMENTS, updateDocuments),
        takeLatest(DELETE_DOCUMENTS, deleteDocuments),
    ]);
}
