import * as React from 'react';
// import { SVGProps } from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={35.708} height={34.251} {...props}>
            <defs>
                <style>
                    {`.mydocumentsprefix__a{fill:#fff}.mydocumentsSvgprefix__b{fill:${
                        window.location.pathname.includes('my-documents') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.mydocumentsSvgprefix__c{fill:${
                        window.location.pathname.includes('my-documents') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#707070 !important'
                    }}`}
                </style>
            </defs>
            <path
                data-name="Path 51282"
                d="m11.324 23.592 1.5-4.524a3.432 3.432 0 0 1 3.34-2.641h15.477a3.433 3.433 0 0 1 3.368 4.1L32.074 30.85a3.433 3.433 0 0 1-3.368 2.767H4.918a4.28 4.28 0 0 0 3.783-2.275 4.333 4.333 0 0 0 .384-1.021l.885-2.661Zm0 0"
                // fill="#bebebe"
                className="mydocumentsSvgprefix__b"
            />
            <g data-name="Group 40209">
                <path
                    data-name="Path 51283"
                    d="M29.757 5.328v11.1H16.168a3.432 3.432 0 0 0-3.34 2.641l-1.5 4.524V2.42a1.785 1.785 0 0 1 1.78-1.781h12.161v4.695h4.413l.038-.036Zm0 0"
                    fill="#fff"
                />
                <path
                    data-name="Path 51284"
                    d="m25.268.639 4.456 4.658-.038.036h-4.418Zm0 0"
                    // fill="#bebebe"
                    className="mydocumentsSvgprefix__b"
                />
                <path
                    data-name="Path 51285"
                    d="M11.324 3.814v19.778L9.968 27.66h-.359a1.784 1.784 0 0 1-1.785-1.785V5.599a1.783 1.783 0 0 1 1.186-1.68 1.753 1.753 0 0 1 .6-.1Zm0 0"
                    fill="#fff"
                />
                <path
                    data-name="Path 51286"
                    d="m9.971 27.661-.885 2.661a4.333 4.333 0 0 1-.384 1.021H6.165a1.786 1.786 0 0 1-1.785-1.785V9.281a1.785 1.785 0 0 1 1.785-1.78h1.662v18.38a1.784 1.784 0 0 0 1.785 1.785Zm0 0"
                    fill="#fff"
                />
            </g>
            <path
                data-name="Path 51287"
                d="M9.008 3.905v.014a1.783 1.783 0 0 0-1.186 1.68v1.9H6.164a1.785 1.785 0 0 0-1.785 1.785v20.274a1.786 1.786 0 0 0 1.785 1.785H8.7a4.283 4.283 0 0 1-8.066-2.012V7.549a3.644 3.644 0 0 1 3.643-3.644Zm0 0"
                // fill="#bebebe"
                className="mydocumentsSvgprefix__b"
            />
            <path
                data-name="Path 51288"
                d="M4.661 34.244v.007h24.046a4.076 4.076 0 0 0 3.985-3.252l2.928-10.3.012-.051a4.067 4.067 0 0 0-3.99-4.855h-1.25V5.328a.633.633 0 0 0-.18-.443L25.727.196C25.714.183 25.7.175 25.7.167l-.024-.018-.031-.024-.018-.017-.041-.022-.012-.008A.635.635 0 0 0 25.268 0h-12.16a2.421 2.421 0 0 0-2.418 2.419v.76H9.612a2.4 2.4 0 0 0-.652.091H4.278A4.283 4.283 0 0 0 0 7.549v21.785a4.917 4.917 0 0 0 4.661 4.91ZM33.8 18.082a2.789 2.789 0 0 1 .588 2.295l-2.928 10.3-.012.051a2.8 2.8 0 0 1-2.745 2.255H8.217a4.919 4.919 0 0 0 1.045-1.344l-.2-.1.2.105a4.972 4.972 0 0 0 .438-1.147l3.731-11.229.015-.054a2.787 2.787 0 0 1 2.722-2.153h15.473a2.789 2.789 0 0 1 2.159 1.021ZM9.109 28.242l-.625 1.88-.015.053a3.657 3.657 0 0 1-.169.533H6.165a1.152 1.152 0 0 1-1.15-1.15V9.281a1.152 1.152 0 0 1 1.15-1.15h1.028v17.745a2.423 2.423 0 0 0 1.916 2.366ZM25.9 2.216l2.371 2.479H25.9Zm-12.8-.947h11.535v4.06a.635.635 0 0 0 .634.634h3.854v9.827H16.168a4.051 4.051 0 0 0-3.951 3.1l-.26.78V2.42a1.151 1.151 0 0 1 1.151-1.151ZM9.6 4.448h1.09v19.04l-1.175 3.533a1.151 1.151 0 0 1-1.053-1.145V5.599a1.152 1.152 0 0 1 .768-1.084 1.115 1.115 0 0 1 .382-.066Zm-8.343 3.1a3.013 3.013 0 0 1 3.021-3.009h3.161a2.412 2.412 0 0 0-.246 1.06v1.263H6.165a2.422 2.422 0 0 0-2.419 2.419v20.276a2.422 2.422 0 0 0 2.419 2.419h1.269a3.649 3.649 0 0 1-6.165-2.643Zm0 0"
                // fill="#707070"
                className="mydocumentsSvgprefix__c"
            />
        </svg>
    );
}

export default SvgComponent;
