import React, { Component } from 'react';
import CheckboxText from '../../components/atoms/CheckboxText';
import './filters.scss';

type initialProps = {
    Services: any;
    Providers: any;
    AverageSpeed: any;
    TvChannels: any;
    MonthlyCost: any;
    changeServices: any;
    changeProviders: any;
    changeAverageSpeed: any;
    changeTvChannels: any;
    changeMonthlyCost: any;
    styles?: any;
};
type initialState = {
    checked: boolean;
    tvindex: any;
};

function dynamicSort(property: string) {
    var sortOrder = 1;

    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function(a: any, b: any) {
        if (sortOrder == -1) {
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    };
}
export class Filters extends Component<initialProps, initialState> {
    state: initialState = {
        checked: false,
        tvindex: null,
    };

    handleServicesChange = (e: any) => {
        if (e.target.name !== 'All Services') {
            let array = this.props.Services.map((item: any) => {
                if (item.name === e.target.name) {
                    let item1 = {
                        name: item.name,
                        value: !item.value,
                        count: item.count,
                    };
                    return item1;
                }
                // return item;
                else {
                    let item1 = {
                        name: item.name,
                        value: false,
                        count: item.count,
                    };
                    return item1;
                }
            });
            let array1 = array.filter((item: any) => item.name !== 'All Services');
            let array2 = array1.filter((item: any) => item.value === false);

            array = array.map((item: any) => {
                let item1;
                if (item.name === 'All Services') {
                    item1 = {
                        name: item.name,
                        value: array2.length > 0 ? false : true,
                        count: item.count,
                    };
                    return item1;
                } else {
                    return item;
                }
            });

            this.props.changeServices(array);
        } else {
            let a = this.props.Services.find((item: any) => item.name === 'All Services');
            if (a) {
                let array = [];
                if (a.value === false) {
                    array = this.props.Services.map((item: any) => {
                        let item1 = {
                            name: item.name,
                            value: true,
                            count: item.count,
                        };
                        return item1;
                    });
                } else {
                    array = this.props.Services.map((item: any) => {
                        let item1 = {
                            name: item.name,
                            value: false,
                            count: item.count,
                        };
                        return item1;
                    });
                }
                this.props.changeServices(array);
            }
        }
    };

    handleProvidersChange = (e: any) => {
        let array = this.props.Providers.map((item: any) => {
            if (item.name === e.target.name) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                };
                return item1;
            } else return item;
        });
        this.props.changeProviders(array);
    };

    handleAverageSpeedChange = (e: any) => {
        let array = this.props.AverageSpeed.map((item: any) => {
            if (item.name === e.target.name) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                };
                return item1;
            } else {
                let item1 = {
                    name: item.name,
                    value: false,
                    count: item.count,
                };
                return item1;
            }
        });
        this.props.changeAverageSpeed(array);
    };

    handleTvChannelsChange = (e: any) => {
        let array = this.props.TvChannels.map((item: any) => {
            if (item.name === e.target.name) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                    prevRange: item.count,
                };
                return item1;
            } else {
                let item1 = {
                    name: item.name,
                    value: false,
                    count: item.count,
                    prevRange: item.count,
                };
                return item1;
            }
        });
        this.props.changeTvChannels(array);
    };

    handleMonthlyCostChange = (e: any) => {
        let array = this.props.MonthlyCost.map((item: any) => {
            if (item.name === e) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                    prevRange: item.prevRange,
                    nextRange: item.nextRange,
                };
                return item1;
            } else {
                let item1 = {
                    name: item.name,
                    value: false,
                    count: item.count,
                    prevRange: item.prevRange,
                    nextRange: item.nextRange,
                };
                return item1;
            }
        });
        this.props.changeMonthlyCost(array);
    };

    clearMonthlyHandler = () => {
        let array = this.props.MonthlyCost.map((item: any) => {
            let item1 = {
                name: item.name,
                value: false,
                count: item.count,
                prevRange: item.prevRange,
                nextRange: item.nextRange,
            };
            return item1;
        });
        this.props.changeMonthlyCost(array);
    };

    clearServicesHandler = () => {
        let array = this.props.Services.map((item: any) => {
            let item1: any;
            if (item.name === 'All Services') {
                item1 = {
                    name: item.name,
                    value: true,
                    count: item.count,
                };
            } else {
                item1 = {
                    name: item.name,
                    value: false,
                    count: item.count,
                };
            }
            return item1;
        });
        this.props.changeServices(array);
    };

    render() {
        let Providers = this.props.Providers.sort(dynamicSort('name'));
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div>
                <div className="headDv">
                    <div className="heads1">Services</div>
                    <div className={isDouglasElliman ? 'link' : 'linkIcnoDoug'} onClick={this.clearServicesHandler}>
                        clear
                    </div>
                </div>

                {this.props.Services.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className="checkbox-item filtersDivab">
                                <CheckboxText
                                    className="counting"
                                    checked={item.value}
                                    onChange={(e: any) => this.handleServicesChange(e)}
                                    label={`${item.name}`}
                                    // disabled={item.name === 'All Services'}
                                    name={item.name}
                                />
                                {/* <div className="countStyle">({item.count})</div> */}
                            </div>
                        </div>
                    );
                })}
                <div className="line1"></div>
                <div className="headsic">Average Speed</div>
                {this.props.AverageSpeed.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className="checkbox-item">
                                <CheckboxText
                                    className="counting"
                                    checked={item.value}
                                    onChange={(e: any) => this.handleAverageSpeedChange(e)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                                {/* <div className="countStyle">({item.count})</div> */}
                            </div>
                        </div>
                    );
                })}
                <div className="line1"></div>
                <div className="headsic">Providers</div>
                {Providers.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className="checkbox-item">
                                <CheckboxText
                                    className="counting"
                                    checked={item.value}
                                    onChange={(e: any) => this.handleProvidersChange(e)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                                {/* <div className="countStyle">({item.count})</div> */}
                            </div>
                        </div>
                    );
                })}
                <div className="line1"></div>
                <div className="headDv">
                    <div className="headsic">Monthly Cost</div>
                    <div className={isDouglasElliman ? 'link' : 'linkIcnoDoug'} onClick={this.clearMonthlyHandler}>
                        clear
                    </div>
                </div>
                <div className="checkbox-item1">
                    {this.props.MonthlyCost.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <div>
                                    <div
                                        className={item.value ? 'blueColor' : 'dummyColor'}
                                        onClick={() => this.handleMonthlyCostChange(item.name)}
                                    >
                                        {item.name}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="line1"></div>
                <div className="headsic">TV Channels</div>
                {this.props.TvChannels.map((item: any, index: number) => {
                    
                    return (
                        <div key={index}>
                            <div className="checkbox-item">
                                <CheckboxText
                                    className="counting"
                                    checked={item.value}
                                    onChange={(e: any) => this.handleTvChannelsChange(e)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Filters;
