import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ScrollRight from '../../Assets/images/TrustedPros/ScrollRight.svg';
import TrustedProsImageHeadingCard from '../../components/atoms/TrustedProsImageHeadingCard';

import '../../styles/howItWorksLanding.scss';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
}));

export default function TrustedProsCategoryTabs(props: any) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue: any) => {
        setValue(parseInt(newValue.currentTarget.id.split('-')[3]));
    };

    let categoriesData: any = [];
    props.tabsHeading.map((data: any) => {
        props.tabsContentData.map((item: any) => {
            if (item.category && item.category[0] && item.category[0] == data.name && value === data.tabId) {
                categoriesData.push(item);
            }
            return categoriesData;
        });
    });
    return (
        <div className={`${classes.root} trustedPros`}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="off"
                aria-label="scrollable prevent tabs example"
                TabIndicatorProps={{ style: { background: '#0066f5' } }}
            >
                {props.tabsHeading
                    ? props.tabsHeading.map((heading: any) => {
                          return <Tab label={heading.name} {...a11yProps(heading.tabId)} />;
                      })
                    : null}
            </Tabs>
            {props.tabsHeading
                ? props.tabsHeading.map((heading: any) => {
                      return (
                          <TabPanel value={value} index={heading.tabId}>
                              <div className="scroll-cardsTrusted" id="container">
                                  {props.loader === true ? (
                                      categoriesData.map((item: any) => {
                                          return (
                                              <div className="scroll-card">
                                                  <TrustedProsImageHeadingCard
                                                      icon={item.react_image ? item.react_image : item.image}
                                                      title={item.name}
                                                      onClick={() =>
                                                          props.onClickPopularServices(item.id, item.name, item)
                                                      }
                                                  />
                                              </div>
                                          );
                                      })
                                  ) : (
                                      <div className="headingCardSkeleton">
                                          <div className="scroll-card">
                                              {' '}
                                              <TrustedProsImageHeadingCard />{' '}
                                          </div>
                                          <div className="scroll-card">
                                              {' '}
                                              <TrustedProsImageHeadingCard />{' '}
                                          </div>
                                          <div className="scroll-card">
                                              {' '}
                                              <TrustedProsImageHeadingCard />{' '}
                                          </div>
                                          <div className="scroll-card">
                                              {' '}
                                              <TrustedProsImageHeadingCard />{' '}
                                          </div>
                                      </div>
                                  )}
                              </div>
                          </TabPanel>
                      );
                  })
                : null}
            {categoriesData.length ? (
                <div className="float-left-div">
                    <img
                        src={ScrollRight}
                        style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
                        onClick={() => {
                            props.scrollHandler2();
                        }}
                    />
                </div>
            ) : null}
            {categoriesData.length ? (
                <div className="float-div">
                    <img
                        src={ScrollRight}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            props.scrollHandler();
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}
