import React, { Component } from 'react';
import Modal from '../../components/atoms/Modal';
import RoomIcon from '@material-ui/icons/Room';
import CreateIcon from '@material-ui/icons/Create';
import Image from '../../Assets/images/ICImages/mainPage';
import LongCard from '../../components/atoms/LongCard';
import AwardIcon from '../../Assets/images/award.svg';
import WifiIcon from '../../Assets/images/wifiblueIcon.svg';
import PhoneIcon from '../../Assets/images/phoneIcon.svg';
import TVIcon from '../../Assets/images/TVIcon.svg';
import Button from '../../components/atoms/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Filters from './Filters';
import PlanCard from '../../components/atoms/PlanCard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/internet/actions';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Footer from './Footer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import CloseIcon from '../../Assets/images/ICImages/close.svg';
import './ICmain.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import PlanCardMobile from '../../components/atoms/PlanCardResponsive';
import FilterIcon from '../../Assets/images/ICImages/filter_alt_black_24dp.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { buttonClicked } from '../../utils/utlis';
// import { PlanCardSeleton } from '../../components/skeletonLoaders/internetSkeleton/internetSkeleton';

type initialProps = {
    tabChangeHandler?: any;
    sendBack?: any;
    address?: string;
    street?: string;
    city?: string;
    state?: any;
    zipcode?: string;
    moveId?: any;
    buyNow?: any;
    editAnswer?: any;
    history?: any;
    location?: any;
    internetAction?: any;
    internetState?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    dashboardState: any;
};

type initialState = {
    showModal: boolean;
    showRecommended: boolean;
    Services: any;
    Providers: any;
    AverageSpeed: any;
    TvChannels: any;
    MonthlyCost: any;
    sort: any;
    isDataArrived: boolean;
    viewDetailModal: boolean;
    viewDetailData: any;
    xfinityModal: boolean;
    xfinityLink: any;
    step1?: any;
    step2?: any;
    step3?: any;
    step4?: any;
    step5?: any;
    totalSteps: number;
    activeStep: number;
    activeViewTab: string;
    address: string | null | undefined;
    street: string | null | undefined;
    city: string | null | undefined;
    state: any;
    zipcode: string | null | undefined;
    moveId: any;
    apiCalled: boolean;
    AnchorEl?: any;
    showFilterModal?: boolean;
    serviceId: any;
    reschedule_link: string;
    appointmentDetailsArrived: boolean;
    custom_schedule_call_info: any;
    slug: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
};

export class RecommendedPage extends Component<initialProps, initialState> {
    state: initialState = {
        showModal: true,
        apiCalled: false,
        activeStep: 0,
        totalSteps: 5,
        showRecommended: true,
        showFilterModal: false,
        step1: {
            internet: false,
            TV: false,
            Phone: false,
        },
        step2: {
            one: false,
            two: false,
            three: false,
            four: false,
        },
        step3: {
            movies: false,
            videoGames: false,
            wfh: false,
            videoCalls: false,
            downloading: false,
            noneOfThese: false,
        },
        step4: {
            entertaiment: false,
            sports: false,
            kids: false,
            films: false,
        },
        step5: {
            localAndLongDistanceCall: false,
            internationalCall: false,
        },
        Services: [
            // { name: 'Phone', value: false, count: 3 },
            // { name: 'Internet', value: false, count: 10 },
            // { name: 'Tv', value: false, count: 9 },
        ],
        Providers: [
            // { name: 'AT&T', value: false, count: 3 },
            // { name: 'Verizon', value: false, count: 3 },
            // { name: 'Spectrum', value: false, count: 10 },
            // { name: 'Xfinity', value: false, count: 9 },
        ],
        AverageSpeed: [
            { name: '1-29Mbps', value: false, count: 10 },
            { name: '30-59Mbps', value: false, count: 6 },
            { name: '60-99Mbps', value: false, count: 7 },
            { name: '100+Mbps', value: false, count: 3 },
        ],
        MonthlyCost: [
            { name: '$0-$24', prevRange: 0, nextRange: 24, value: false, count: 0 },
            { name: '$25-$50', prevRange: 25, nextRange: 50, value: false, count: 0 },
            { name: '$51-$100', prevRange: 51, nextRange: 100, value: false, count: 0 },
            { name: '$100+', prevRange: 100, nextRange: 0, value: false, count: 0 },
        ],
        TvChannels: [
            { name: '10 and up', prevRange: 10, value: false, count: 0 },
            { name: '100+', prevRange: 100, value: false, count: 0 },
            { name: '200+', prevRange: 200, value: false, count: 0 },
        ],
        sort: { value: 'LH', label: 'Low to High' },
        isDataArrived: true,
        viewDetailModal: false,
        viewDetailData: null,
        xfinityModal: false,
        xfinityLink: null,
        activeViewTab: 'providerDetail',
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        AnchorEl: null,
        serviceId: '',
        reschedule_link: '',
        appointmentDetailsArrived: true,
        custom_schedule_call_info: {
            enable: false,
        },
        slug: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const dest: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                moveId: data && data.id,
            });
            this.props.internetAction.internetPlan({
                city: dest && dest.city_name,
                state: dest && dest.state_code,
                zip_code: dest && dest.zip_code,
            });
        }
        // else {
        this.props.commonAction.moveGet();
        // }
        this.props.internetAction.getSteps();
        this.props.dashboardAction.checklist();

        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fromLanding &&
            this.props.location.state.fromLanding === 'fromLanding' &&
            this.props.location.state.data
        ) {
            this.props.internetAction.landingInternetPlan();
            this.setState({
                address: this.props.location.state.data.destination_full_address,
            });
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            this.setState({
                slug: slug,
            });
        }

        if (slug) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');
            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                `Banner Viewed`,
                {
                    page: 'Internet & Cable',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    category_name: 'Internet & Cable',
                    banner: true,
                    banner_title: 'Our Top Recommended Deals',
                    brand_name: '',
                },
                { user_id: move_id },
            );
            analytics.track(
                `Product Card Viewed`,

                {
                    page: 'Internet & Cable',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    category_name: 'Internet & Cable',
                    card: true,
                    card_title: 'AT&T and DIRECTV',
                    product_name: 'AT&T and DIRECTV',
                    brand_name: 'AT&T and DIRECTV',
                },
                { user_id: move_id },
            );
        }
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest: any = cur.move[0] && cur.move[0].destination;
            this.setState({ isDataArrived: true });
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: cur.move[0] && cur.move[0].destination_street,
                address: cur.move[0] && cur.move[0].destination_full_address,
                moveId: cur.move[0] && cur.move[0].id,
            });
            this.props.internetAction.internetPlan({
                city: dest && dest.city_name,
                state: dest && dest.state_code,
                zip_code: dest && dest.zip_code,
            });
            setTimeout(() => {
                if (
                    this.props.internetState &&
                    this.props.internetState.internet &&
                    this.props.internetState.internet.length === 0
                ) {
                    this.props.internetAction.internetPlan({
                        city: dest && dest.city_name,
                        state: dest && dest.state_code,
                        zip_code: dest && dest.zip_code,
                    });
                }
            }, 5000);
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.setState({ isDataArrived: true });
            this.props.commonAction.moveGet();
            this.props.internetAction.internetPlan({
                city: this.state.city,
                state: this.state.state && this.state.state.label,
                zip_code: this.state.zipcode,
            });
        }
        if (
            (prevProps && prevProps.dashboardState && prevProps.dashboardState.checklist) !==
                (this.props && this.props.dashboardState && this.props.dashboardState.checklist) &&
            this.props.dashboardState.checklist &&
            this.props.dashboardState.checklist.length > 0
        ) {
            this.props.dashboardState.checklist.map((el: any) => {
                if (el && el.do_it_now && el.do_it_now.new_url.includes('/dashboard/internet')) {
                    this.setState({ serviceId: el.id });
                }
            });
        }

        const prev2 = prevProps && prevProps.internetState;
        const cur2 = this.props && this.props.internetState;
        if (
            prev2.selectDigitalPlan !== cur2.selectDigitalPlan &&
            cur2.selectDigitalPlan &&
            cur2.selectDigitalPlan.hash_code
        ) {
            if (
                this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.fromLanding &&
                this.props.location.state.fromLanding === 'fromLanding'
            ) {
                this.props.history.push({
                    pathname: '/landing/internet/summary',
                    state: {
                        fromLanding: 'fromLanding',
                    },
                });
            } else {
                this.props.history.push('/dashboard/internet/summary');
            }
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.internetState &&
            nextProps.internetState.internet &&
            nextProps.internetState.internet.filters &&
            Object.keys(nextProps.internetState.internet.filters).length > 0 &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            const filters = nextProps.internetState.internet.filters;
            let service1: any = [];
            let provider1: any = [];
            let speed1: any = [];
            // let a: any;
            for (const [key, value] of Object.entries(filters.service)) {
                if (value !== 0) {
                    if (key !== 'All Services') {
                        service1.push({
                            name: key,
                            value:
                                key === 'Internet'
                                    ? currentState.step1 &&
                                      currentState.step1.internet &&
                                      !currentState.step1.TV &&
                                      !currentState.step1.Phone
                                    : key === 'TV'
                                    ? currentState.step1 &&
                                      currentState.step1.TV &&
                                      !currentState.step1.Phone &&
                                      !currentState.step1.internet
                                    : key === 'Phone'
                                    ? currentState.step1 &&
                                      currentState.step1.Phone &&
                                      !currentState.step1.TV &&
                                      !currentState.step1.internet
                                    : key === 'Internet + Phone'
                                    ? currentState.step1 &&
                                      currentState.step1.Phone &&
                                      currentState.step1.internet &&
                                      !currentState.step1.TV
                                    : key === 'Internet + TV'
                                    ? currentState.step1 &&
                                      currentState.step1.TV &&
                                      currentState.step1.internet &&
                                      !currentState.step1.Phone
                                    : key === 'TV + Phone'
                                    ? currentState.step1 &&
                                      currentState.step1.TV &&
                                      currentState.step1.Phone &&
                                      !currentState.step1.internet
                                    : key === 'Internet + TV + Phone'
                                    ? currentState.step1 &&
                                      currentState.step1.TV &&
                                      currentState.step1.Phone &&
                                      currentState.step1.internet
                                    : false,
                            count: value,
                        });
                    }
                    // else {
                    //     a = {
                    //         name: key,
                    //         value: false,
                    //         count: value,
                    //     };
                    // }
                }
            }
            // service1.push(a);
            for (const [key, value] of Object.entries(filters.provider)) {
                if (value !== 0) {
                    provider1.push({ name: key, value: false, count: value });
                }
            }
            for (const [key, value] of Object.entries(filters.speed)) {
                if (value !== 0) {
                    speed1.push({ name: key, value: false, count: value });
                }
            }

            currentState.Services = service1;
            currentState.Providers = provider1;
            // currentState.AverageSpeed = speed1;
        }

        if (
            nextProps.internetState &&
            nextProps.internetState.internet &&
            // nextProps.internetState.internet.internet &&
            nextProps.internetState.isInternetDataArrived
            // nextProps.internetState.internet.filters &&
            // Object.keys(nextProps.internetState.internet.filters).length > 0
        ) {
            nextProps.internetState.isInternetDataArrived = false;
            currentState.showModal = false;
        }

        if (
            nextProps.internetState &&
            nextProps.internetState.steps &&
            nextProps.internetState.steps &&
            nextProps.internetState.steps[0] &&
            nextProps.internetState.steps[0].filter_values
            // &&currentState.apiCalled
        ) {
            if (nextProps.location && nextProps.location.state && nextProps.location.state.type === 'skip') {
                currentState.activeStep = 0;
            } else {
                currentState.activeStep = nextProps.internetState.steps[0].filter_values.activeStep;
            }

            currentState.step1 = nextProps.internetState.steps[0].filter_values.step1;
            currentState.step2 = nextProps.internetState.steps[0].filter_values.step2;
            currentState.step3 = nextProps.internetState.steps[0].filter_values.step3;
            currentState.step4 = nextProps.internetState.steps[0].filter_values.step4;
            currentState.step5 = nextProps.internetState.steps[0].filter_values.step5;
        }

        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.appointmentDetailsArrived
        ) {
            currentState.appointmentDetailsArrived = false;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
        }

        return currentState;
    }

    changeServices = async (val: any) => {
        await this.setState({
            Services: val,
        });
    };
    changeProviders = (val: any) => {
        this.setState({
            Providers: val,
        });
    };
    changeAverageSpeed = (val: any) => {
        this.setState({
            AverageSpeed: val,
        });
    };
    changeTvChannels = (val: any) => {
        this.setState({
            TvChannels: val,
        });
    };
    changeMonthlyCost = (val: any) => {
        this.setState({
            MonthlyCost: val,
        });
    };
    // sortByHandler = (e: any) => {
    //     this.setState({
    //         sort: { value: e.target.value, label: e.target.label },
    //     });
    // };
    handleLTH = () => {
        this.setState({
            sort: { value: 'LH', label: 'Low to High' },
        });

        this.handleClose();
    };
    handleHTL = () => {
        this.setState({
            sort: { value: 'HL', label: 'High to Low' },
        });

        this.handleClose();
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };
    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };
    scheduleCall = () => {};
    editYourAnswer = () => {
        // this.props.editAnswer();
        // this.props.tabChangeHandler('steps');
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fromLanding &&
            this.props.location.state.fromLanding === 'fromLanding'
        ) {
            this.props.history.push({
                pathname: '/landing/internet/steps',
                state: {
                    type: 'edit',
                    fromLanding: 'fromLanding',
                    data:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.data,
                },
            });
        } else {
            this.props.history.push({ pathname: '/dashboard/internet/steps', state: { type: 'edit' } });
        }
    };
    buyNow = (buyNow: any) => {
        if (buyNow && buyNow.provider_name === 'XFINITY') {
            this.setState({ xfinityModal: true, xfinityLink: buyNow });
        } else {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            buttonClicked(
                'Button Clicked',
                'Internet & Cable',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                'Internet & Cable',
                'Select',
                false,
                '',
                true,
                buyNow && buyNow.provider_name,
                'Plan Card',
                false,
                '',
                false,
                '',
                buyNow && buyNow.provider_name,
                move_id,
            );

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Product Details Viewed',
                {
                    page: 'Internet & Cable',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: buyNow && buyNow.provider_name,
                    card: true,
                    category_name: 'Internet & Cable',
                    product_name: buyNow && buyNow.download_speed,
                    brand_name: buyNow && buyNow.provider_name,
                },
                { user_id: move_id },
            );
            if (buyNow && buyNow.plan_url) {
                this.props.dashboardAction.updateServicePercentage({
                    data: {
                        completed_percentage: 100,
                    },
                    service_id: this.state.serviceId,
                });
                window.open(buyNow && buyNow.plan_url, '_blank');
            } else {
                // await this.setState({ buyNowData: buyNow });
                // this.tabChangeHandler('orderPage');
                this.props.dashboardAction.updateServicePercentage({
                    data: {
                        completed_percentage: 75,
                    },
                    service_id: this.state.serviceId,
                });
                if (
                    this.props &&
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.fromLanding &&
                    this.props.location.state.fromLanding === 'fromLanding'
                ) {
                    this.props.history.push({
                        pathname: '/landing/internet/order',
                        state: {
                            data: buyNow,
                            fromLanding: 'fromLanding',
                            destinationData:
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.state &&
                                this.props.history.location.state.data,
                        },
                    });
                } else {
                    if (
                        this.state.reschedule_link &&
                        !(
                            (this.state.custom_schedule_call_info && this.state.custom_schedule_call_info.enable) ||
                            (this.state.slug && this.state.slug.slug == 'sibcycline')
                        )
                    ) {
                        this.props.internetAction.selectDigitalPlan({
                            provider_name: buyNow && buyNow.provider_name,
                            plan_name: buyNow && buyNow.name,
                            price: buyNow && buyNow.base_price,
                            plan_details: buyNow,
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/dashboard/internet/order',
                            state: { data: buyNow },
                        });
                    }
                }
            }
        }
    };
    viewDetailHandler = (data: any) => {
        // if (data.provider_name !== 'Xfinity') {
        this.setState({
            viewDetailModal: true,
            viewDetailData: data,
            activeViewTab: data && data.offer_description ? 'providerDetail' : 'tc',
        });
        // } else {
        //     this.setState({ xfinityModal: true, xfinityLink: data });
        // }
    };
    activeTabHandler = (tab: string) => {
        this.setState({ activeViewTab: tab });
    };
    clearAll = () => {
        let services = this.state.Services.map((item: any) => {
            if (item.value) {
                let item1: any;
                if (item.name === 'All Services') {
                    item1 = {
                        name: item.name,
                        value: true,
                        count: item.count,
                    };
                } else {
                    item1 = {
                        name: item.name,
                        value: false,
                        count: item.count,
                    };
                }
                return item1;
            } else return item;
        });

        let providers = this.state.Providers.map((item: any) => {
            if (item.value) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                };
                return item1;
            } else return item;
        });

        let averageSpeed = this.state.AverageSpeed.map((item: any) => {
            if (item.value) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                };
                return item1;
            } else return item;
        });

        let tvChannels = this.state.TvChannels.map((item: any) => {
            if (item.value) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                    count: item.count,
                };
                return item1;
            } else return item;
        });

        this.setState({
            Services: services,
            Providers: providers,
            AverageSpeed: averageSpeed,
            TvChannels: tvChannels,
        });
    };
    sortByKey = (array: any, key: any) => {
        return array.slice().sort(function(a: any, b: any) {
            var x = parseFloat(a[key]);
            var y = parseFloat(b[key]);

            return x < y ? -1 : x > y ? 1 : 0;
        });
    };
    handleSelect = (address: any) => {
        let add: string;

        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };
    showfilterModal = () => {
        this.setState({ showFilterModal: true });
    };
    render() {
        // let moveData =
        // this.props && this.props.commonState && this.props.commonState.move && this.props.commonState.move[0];
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { totalSteps, activeStep } = this.state;
        // let {Services,Providers,AvailableSpeed}=this.state
        const { sort, viewDetailData } = this.state;
        let plans: any = [];
        let recommendedData: any = [];
        const { products } = this.props.internetState && this.props.internetState.internet;
        let sortByData: any;
        let array1: any = [];
        let array2: any = [];
        let array3: any = [];
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (products) {
            sortByData = products;

            // service wise filtered data
            if (this.state.Services && this.state.Services.length > 0) {
                let serviceFilters: any = [];
                this.state.Services.map((item: any) => item.value && serviceFilters.push(item.name));
                let b: any = [];
                if (serviceFilters.includes('Internet')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 1 && item.is_tv === 0 && item.is_phone === 0,
                    );
                    b = b.concat(c);
                }
                if (serviceFilters.includes('TV')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 0 && item.is_tv === 1 && item.is_phone === 0,
                    );
                    b = b.concat(c);
                }
                if (serviceFilters.includes('TV + Phone')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 0 && item.is_tv === 1 && item.is_phone === 1,
                    );
                    b = b.concat(c);
                }
                if (serviceFilters.includes('Phone')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 0 && item.is_tv === 0 && item.is_phone === 1,
                    );
                    b = b.concat(c);
                }
                if (serviceFilters.includes('Internet + TV + Phone')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 1 && item.is_tv === 1 && item.is_phone === 1,
                    );
                    b = b.concat(c);
                }
                if (serviceFilters.includes('Internet + Phone')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 1 && item.is_tv === 0 && item.is_phone === 1,
                    );
                    b = b.concat(c);
                }

                if (serviceFilters.includes('Internet + TV')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.is_internet === 1 && item.is_tv === 1 && item.is_phone === 0,
                    );
                    b = b.concat(c);
                }

                if (serviceFilters.length > 0) {
                    sortByData = b;
                }
            }

            // Provider wise filtered data
            let providerFilters: any = [];
            this.state.Providers.map((item: any) => item.value && providerFilters.push(item.name));
            if (this.state.Providers && this.state.Providers.length > 0 && providerFilters.length > 0) {
                let b: any = [];
                let c: any = sortByData.filter((item: any) => providerFilters.includes(item.provider_name));
                b = c;
                sortByData = b;
            }

            //Average speed filtered data
            let speedFilters: any = [];
            this.state.AverageSpeed.map((item: any) => item.value && speedFilters.push(item.name));
            if (this.state.AverageSpeed && this.state.AverageSpeed.length > 0 && speedFilters.length > 0) {
                let b: any = [];
                if (speedFilters.includes('1-29Mbps')) {
                    let c: any = sortByData.filter((item: any) => item.download_speed >= 1 && item.download_speed < 29);
                    b = b.concat(c);
                }

                if (speedFilters.includes('30-59Mbps')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.download_speed >= 30 && item.download_speed < 59,
                    );
                    b = b.concat(c);
                }

                if (speedFilters.includes('60-99Mbps')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.download_speed >= 60 && item.download_speed < 99,
                    );
                    b = b.concat(c);
                }

                if (speedFilters.includes('100+Mbps')) {
                    let c: any = sortByData.filter((item: any) => item.download_speed >= 100);
                    b = b.concat(c);
                }
                sortByData = b;
            }

            //No of channels filtered data
            let channelFilters: any = [];
            this.state.TvChannels.map((item: any) => item.value && channelFilters.push(item.name));
            if (this.state.TvChannels && this.state.TvChannels.length > 0 && channelFilters.length > 0) {
                let b: any = [];
                if (channelFilters.includes('10 and up')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.no_of_channels && item.no_of_channels.toString().match(/\d+/)[0] >= 10,
                        // &&
                        // item.no_of_channels.toString().match(/\d+/)[0] < 100
                    );
                    b = b.concat(c);
                }

                if (channelFilters.includes('100+')) {
                    let c: any = sortByData.filter(
                        (item: any) =>
                            item.no_of_channels &&
                            item.no_of_channels.toString().match(/\d+/)[0] >= 100 &&
                            item.no_of_channels.toString().match(/\d+/)[0] < 200,
                    );
                    b = b.concat(c);
                }

                if (channelFilters.includes('200+')) {
                    let c: any = sortByData.filter(
                        (item: any) => item.no_of_channels && item.no_of_channels.toString().match(/\d+/)[0] >= 200,
                    );
                    b = b.concat(c);
                }
                sortByData = b;
            }

            //Monthly Cost filtered data
            let monthlyCostFilters: any = [];
            this.state.MonthlyCost.map((item: any) => item.value && monthlyCostFilters.push(item.name));
            if (this.state.MonthlyCost && this.state.MonthlyCost.length > 0 && monthlyCostFilters.length > 0) {
                let b: any = [];
                if (monthlyCostFilters.includes('$0-$24')) {
                    let c: any = sortByData.filter((item: any) => item.base_price >= 0 && item.base_price < 24);
                    b = b.concat(c);
                }

                if (monthlyCostFilters.includes('$25-$50')) {
                    let c: any = sortByData.filter((item: any) => item.base_price >= 25 && item.base_price < 50);
                    b = b.concat(c);
                }

                if (monthlyCostFilters.includes('$51-$100')) {
                    let c: any = sortByData.filter((item: any) => item.base_price >= 51 && item.base_price < 100);
                    b = b.concat(c);
                }

                if (monthlyCostFilters.includes('$100+')) {
                    let c: any = sortByData.filter((item: any) => item.base_price >= 100);
                    b = b.concat(c);
                }
                sortByData = b;
            }
            if (sort.value === 'LH') {
                sortByData = this.sortByKey(sortByData, 'base_price');
            }
            if (sort.value === 'HL') {
                sortByData = this.sortByKey(sortByData, 'base_price').reverse();
            }
            if (sortByData.length > 3 && totalSteps === activeStep) {
                let str1: any = '';
                for (const [key, value] of Object.entries(this.state.step3)) {
                    if (value) {
                        str1 = key;
                    }
                }

                if (str1 != '') {
                    let array1: any = [];
                    if (str1 === 'movies') {
                        array1 = sortByData.filter((item: any) => item.download_speed >= 0 && item.download_speed < 25);
                    } else if (str1 === 'wfh') {
                        array1 = sortByData.filter(
                            (item: any) => item.download_speed >= 25 && item.download_speed < 100,
                        );
                    } else if (str1 === 'downloading') {
                        array1 = sortByData.filter(
                            (item: any) => item.download_speed >= 100 && item.download_speed < 200,
                        );
                    } else if (str1 === 'videoCalls') {
                        array1 = sortByData.filter(
                            (item: any) => item.download_speed >= 200 && item.download_speed < 400,
                        );
                    } else if (str1 === 'videoGames') {
                        array1 = sortByData.filter((item: any) => item.download_speed >= 1000);
                    }
                    array1 = this.sortByKey(array1, 'base_price');
                    recommendedData = array1.slice(0, 3);
                } else if (this.state.step1.internet) {
                    let str: string;
                    let num: number = 0;
                    let array: any = [];
                    for (const [key, value] of Object.entries(this.state.step2)) {
                        if (value) {
                            str = key;
                            if (str == 'one') {
                                num = 1;
                            } else if (str == 'two') {
                                num = 2;
                            } else if (str == 'three') {
                                num = 3;
                            } else if (str == 'four') {
                                num = 4;
                            }
                        }
                    }
                    if (num !== 0) {
                        if (num === 1) {
                            array = sortByData.filter(
                                (item: any) => item.download_speed >= 25 && item.download_speed < 100,
                            );
                        } else if (num === 2) {
                            array = sortByData.filter(
                                (item: any) => item.download_speed >= 100 && item.download_speed < 200,
                            );
                        } else if (num === 3) {
                            array = sortByData.filter(
                                (item: any) => item.download_speed >= 200 && item.download_speed < 400,
                            );
                        } else if (num === 4) {
                            array = sortByData.filter((item: any) => item.download_speed >= 400);
                        }
                        array = this.sortByKey(array, 'base_price');
                        recommendedData = array.slice(0, 3);
                    } else {
                        let plans2 = this.sortByKey(sortByData, 'base_price');
                        recommendedData = plans2.slice(0, 3);
                    }
                } else {
                    let plans2 = this.sortByKey(sortByData, 'base_price');
                    recommendedData = plans2.slice(0, 3);
                }
            }
            if (sortByData.length > 0) {
                if (
                    this.props &&
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.type &&
                    this.props.history.location.state.type === 'skip'
                ) {
                    let newData: any = [];
                    let newData1: any = [];
                    let newData2: any = [];
                    let newData4: any = [];
                    sortByData.map((item: any) => {
                        if (item.is_internet && !item.is_tv && !item.is_phone) {
                            if (item.provider_name != 'HughesNet') {
                                newData.push(item);
                                newData = this.sortByKey(newData, 'download_speed');
                            }
                            if (item.provider_name == 'HughesNet') {
                                newData2.push(item);
                                newData2 = this.sortByKey(newData2, 'download_speed');
                            }
                        }

                        newData4 = newData.concat(newData2);

                        return newData4;
                    });
                    sortByData.map((item: any) => {
                        if (!newData4.includes(item)) {
                            newData1.push(item);
                        }
                        return newData1;
                    });
                    sortByData = newData4.concat(newData1);
                    plans = sortByData;
                } else {
                    plans = sortByData;
                }
            }
            // else {
            //     plans = sortByData;
            // }
        }
        if (recommendedData && recommendedData.length === 0 && plans.length >= 3) {
            let recommendedData1 = this.sortByKey(plans, 'base_price');
            recommendedData = recommendedData1.slice(0, 3);
            plans.splice(0, 1);
            plans.splice(1, 1);
            plans.splice(2, 1);
        }

        if (recommendedData.length === 3) {
            if (recommendedData[0].is_internet) {
                array1.push({
                    name: 'Internet',
                    icon: WifiIcon,
                });
            }
            if (recommendedData[0].is_phone) {
                array1.push({
                    name: 'Phone',
                    icon: PhoneIcon,
                });
            }
            if (recommendedData[0].is_tv) {
                array1.push({
                    name: 'TV',
                    icon: TVIcon,
                });
            }

            if (recommendedData[1].is_internet) {
                array2.push({
                    name: 'Internet',
                    icon: WifiIcon,
                });
            }
            if (recommendedData[1].is_phone) {
                array2.push({
                    name: 'Phone',
                    icon: PhoneIcon,
                });
            }
            if (recommendedData[1].is_tv) {
                array2.push({
                    name: 'TV',
                    icon: TVIcon,
                });
            }

            if (recommendedData[2].is_internet) {
                array3.push({
                    name: 'Internet',
                    icon: WifiIcon,
                });
            }
            if (recommendedData[2].is_phone) {
                array3.push({
                    name: 'Phone',
                    icon: PhoneIcon,
                });
            }
            if (recommendedData[2].is_tv) {
                array3.push({
                    name: 'TV',
                    icon: TVIcon,
                });
            }
        }
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="main-head">
                    <h2 className="heading">Internet And Cable</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Internet And Cable</p>
                    </div>
                </div>
                {this.state.showModal ? (
                    <div className="loaderInternetRecomendation">
                        <div className="modalHead">Just a moment we are finding a top picks for you </div>
                        <div className="modalSubHead">
                            We are analyzing your answer and what people similar to you bought
                        </div>
                        <div className="main-divv-internet">
                            <div className="modalData">
                                <CheckCircleIcon />
                                <div className="textModal">
                                    Searching through our all broadband packages for deals that are…
                                </div>
                            </div>
                            <div className="modalData1">
                                <CheckCircleIcon />
                                <div className="textModal1">Available in {this.state.address}</div>
                            </div>
                            <div className="modalData2">
                                <CheckCircleIcon />
                                <div className="textModal2">Good fit for homes with one or more people</div>
                            </div>
                            <div className="modalData3">
                                <CheckCircleIcon />
                                <div className="textModal3">Great for playing games, streaming movies, etc.</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div
                            className={
                                isDouglasElliman
                                    ? 'recommendationStepsInternet recommendationStepsInternetDouglas'
                                    : 'recommendationStepsInternet'
                            }
                            style={{ marginTop: '20px' }}
                        >
                            {this.state.showRecommended && (
                                <div>
                                    {activeStep < totalSteps && activeStep >= 0 ? (
                                        <div>
                                            <div
                                                className="BlackCard1"
                                                style={{
                                                    background: isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.color_shade1
                                                        ? slug.color_shade1
                                                        : this.props &&
                                                          this.props.location &&
                                                          this.props.location.state &&
                                                          this.props.location.state.fromLanding &&
                                                          this.props.location.state.fromLanding === 'fromLanding'
                                                        ? '#30AB86'
                                                        : '#08192f',
                                                }}
                                            >
                                                <div className="mainCard">
                                                    {activeStep === 0 ? (
                                                        <div>
                                                            <div className="head">Get Our Recommended deals</div>
                                                            <div>
                                                                Answer a few questions and see our best recommendation
                                                                for your internet & cable
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="head">
                                                            Complete remaining steps & Get Our Recommended deals
                                                        </div>
                                                    )}
                                                    <div className="internetRecommendationProgressBar">
                                                        <Button
                                                            className="btnICabc"
                                                            backgroundColor="#FFFFFF"
                                                            boxShadow={
                                                                isDouglasElliman
                                                                    ? 'none'
                                                                    : `0 0 3px 0 ${
                                                                          slug && slug.primary_color
                                                                              ? slug.primary_color
                                                                              : '#273E59'
                                                                      }`
                                                            }
                                                            width="139px"
                                                            height="38px"
                                                            color={
                                                                isDouglasElliman
                                                                    ? '#100B28'
                                                                    : slug && slug.primary_color
                                                                    ? slug.primary_color
                                                                    : this.props &&
                                                                      this.props.location &&
                                                                      this.props.location.state &&
                                                                      this.props.location.state.fromLanding &&
                                                                      this.props.location.state.fromLanding ===
                                                                          'fromLanding'
                                                                    ? '#30AB86'
                                                                    : '#273E59'
                                                            }
                                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                            fontSize="14px"
                                                            onClick={() => {
                                                                if (
                                                                    this.props &&
                                                                    this.props.location &&
                                                                    this.props.location.state &&
                                                                    this.props.location.state.fromLanding &&
                                                                    this.props.location.state.fromLanding ===
                                                                        'fromLanding'
                                                                ) {
                                                                    this.props.history.push({
                                                                        pathname: '/landing/internet/steps',
                                                                        state: {
                                                                            type: 'complete',
                                                                            fromLanding: 'fromLanding',
                                                                        },
                                                                    });
                                                                } else {
                                                                    this.props.history.push({
                                                                        pathname: '/dashboard/internet/steps',
                                                                        state: { type: 'complete' },
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {activeStep === 0 ? 'Get started' : 'Complete Now'}
                                                        </Button>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '180px' }} className="progress-margin">
                                                                <LinearProgress
                                                                    className="recommendationInternetProgress"
                                                                    variant="determinate"
                                                                    value={
                                                                        this.props &&
                                                                        this.props.location &&
                                                                        this.props.location.state &&
                                                                        this.props.location.state.fromLanding &&
                                                                        this.props.location.state.fromLanding ===
                                                                            'fromLanding'
                                                                            ? 60
                                                                            : activeStep !== 0 && totalSteps
                                                                            ? (activeStep / totalSteps) * 100
                                                                            : 2
                                                                    }
                                                                    style={{
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '15px',
                                                                        height: '7px',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="progress-percent">
                                                                {this.props &&
                                                                this.props.location &&
                                                                this.props.location.state &&
                                                                this.props.location.state.fromLanding &&
                                                                this.props.location.state.fromLanding === 'fromLanding'
                                                                    ? 60
                                                                    : (activeStep / totalSteps) * 100}
                                                                % Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!isDouglasElliman && (
                                                    <div className="image1">
                                                        {/* <img src={image} alt="image.svg" style={{ height: '173px', width: '457px' }} /> */}
                                                        <Image />
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="filterButtonrecommendation"
                                                style={{
                                                    margin: '10px 0px 0px 0px',
                                                }}
                                                onClick={this.showfilterModal}
                                            >
                                                <div>
                                                    <img src={FilterIcon} alt="" />
                                                </div>
                                                <div>Filter</div>
                                            </div>
                                        </div>
                                    ) : totalSteps === activeStep ? (
                                        <div
                                            className={
                                                isDouglasElliman
                                                    ? 'main-div-internet-main main-div-internet-mainDoug'
                                                    : 'main-div-internet-main'
                                            }
                                        >
                                            <div className="heading1">
                                                <div className="heading111">
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {`${
                                                            this.props.commonState &&
                                                            this.props.commonState.move &&
                                                            this.props.commonState.move[0] &&
                                                            this.props.commonState.move[0].user_ref &&
                                                            (this.props.commonState.move[0].user_ref.first_name ||
                                                                this.props.commonState.move[0].user_ref.last_name)
                                                                ? this.props.commonState.move[0].user_ref.first_name ||
                                                                  this.props.commonState.move[0].user_ref.last_name
                                                                : ''
                                                        },`}
                                                    </span>{' '}
                                                    we fetched {recommendedData.length === 3 ? '3' : '0'} offers for
                                                    your address{' '}
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'flex-start',
                                                    }}
                                                >
                                                    <RoomIcon
                                                        className={
                                                            isDouglasElliman ? 'locationIcon' : 'moveLocationIcon'
                                                        }
                                                    />
                                                    <AutoComplete
                                                        //    className={
                                                        //     isDouglasElliman ? 'editAddressAutoComplete'
                                                        //      }
                                                        value={this.state.address ? this.state.address : ''}
                                                        onChange={e => {
                                                            this.setState({ address: e });
                                                        }}
                                                        onSelect={this.handleSelect}
                                                        placeholder="Location"
                                                        // inputTitle="To"
                                                        edit={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="filterButtonrecommendation" onClick={this.showfilterModal}>
                                                <div>
                                                    <img src={FilterIcon} alt="" />
                                                </div>
                                                <div>Filter</div>
                                            </div>
                                            <div
                                                className="BlackCard"
                                                style={{
                                                    background: isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.color_shade1
                                                        ? slug.color_shade1
                                                        : '#08192f',
                                                }}
                                            >
                                                <div className="mainCard">
                                                    <div className="head">Our Top Recommended Deals</div>
                                                    <div className="para111">
                                                        The recommendations are based on your answers and what people
                                                        similar to you bought.
                                                    </div>
                                                    <div className="editAnswer" onClick={() => this.editYourAnswer()}>
                                                        <CreateIcon height="10px" /> Edit Your Answers
                                                    </div>
                                                </div>
                                                {!isDouglasElliman && (
                                                    <div className="image1">
                                                        {/* <img
                                                src={image}
                                                alt="image.svg"
                                                style={{ height: '173px', width: '457px' }}
                                            /> */}
                                                        <Image />
                                                    </div>
                                                )}
                                            </div>
                                            {recommendedData &&
                                                recommendedData[0] &&
                                                recommendedData[1] &&
                                                recommendedData[2] && (
                                                    <div
                                                        className={
                                                            isDouglasElliman
                                                                ? 'RecommendedCard RecommendedCardDoug'
                                                                : 'RecommendedCard'
                                                        }
                                                    >
                                                        {recommendedData && recommendedData[0] && array1 ? (
                                                            <LongCard
                                                                className="recommendationLongCard1"
                                                                cardMargin="0px 10px 0px 0px"
                                                                width="33%"
                                                                // height="300px"
                                                                cardImage={recommendedData[0].provider_logo}
                                                                bestForImage={AwardIcon}
                                                                features={array1}
                                                                bestForTitle="BEST FOR ENTERTAINMENT"
                                                                cardTitle={
                                                                    recommendedData[0] &&
                                                                    recommendedData[0].provider_name
                                                                }
                                                                availableSpeed={
                                                                    recommendedData[0] &&
                                                                    recommendedData[0].is_internet &&
                                                                    recommendedData[0].download_speed
                                                                        ? `${recommendedData[0].download_speed} Mbps`
                                                                        : '-'
                                                                }
                                                                channels={
                                                                    recommendedData[0] &&
                                                                    recommendedData[0].no_of_channels
                                                                        ? `${recommendedData[0].no_of_channels}`
                                                                        : `${recommendedData[0].no_of_channels}`
                                                                }
                                                                currency="$"
                                                                ammount={
                                                                    recommendedData[0] &&
                                                                    recommendedData[0].base_price_first &&
                                                                    `${recommendedData[0].base_price_first}`
                                                                }
                                                                planValidFor={
                                                                    recommendedData[0].service_contract_length
                                                                        ? `for ${recommendedData[0].service_contract_length}`
                                                                        : ''
                                                                }
                                                                planRate={
                                                                    recommendedData[0] &&
                                                                    recommendedData[0].base_price_second &&
                                                                    `${recommendedData[0].base_price_second}`
                                                                }
                                                                reward="Get $100 Visa gift card"
                                                                scheduleCall={() => this.buyNow(recommendedData[1])}
                                                                viewDetails={
                                                                    !recommendedData[0].offer_description &&
                                                                    !recommendedData[0].disclaimer
                                                                        ? 'NoData'
                                                                        : () =>
                                                                              this.viewDetailHandler(recommendedData[0])
                                                                }
                                                            />
                                                        ) : null}

                                                        {recommendedData && recommendedData[1] && array1 ? (
                                                            <div
                                                                className={
                                                                    isDouglasElliman
                                                                        ? 'recommendationLongSuggested recommendationLongSuggestedDoug'
                                                                        : 'recommendationLongSuggested'
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        isDouglasElliman
                                                                            ? 'recommendedCard recommendedCardDoug'
                                                                            : 'recommendedCard'
                                                                    }
                                                                >
                                                                    Recommended
                                                                </div>

                                                                <LongCard
                                                                    className="recommendationLongCard2"
                                                                    // cardMargin="0px 10px 0px 0px"
                                                                    // height="300px"
                                                                    width="auto"
                                                                    boxShadow="none"
                                                                    cardPadding="5px 15px 15px 15px"
                                                                    cardImage={recommendedData[1].provider_logo}
                                                                    bestForImage={AwardIcon}
                                                                    features={array1}
                                                                    bestForTitle="BEST FOR ENTERTAINMENT"
                                                                    cardTitle={
                                                                        recommendedData[1] &&
                                                                        recommendedData[1].provider_name
                                                                    }
                                                                    availableSpeed={
                                                                        recommendedData[1] &&
                                                                        recommendedData[1].is_internet &&
                                                                        recommendedData[1].download_speed
                                                                            ? `${recommendedData[1].download_speed} Mbps`
                                                                            : '0 Mbps'
                                                                    }
                                                                    channels={
                                                                        recommendedData[1] &&
                                                                        recommendedData[1].no_of_channels
                                                                            ? `${recommendedData[1].no_of_channels}`
                                                                            : `${recommendedData[1].no_of_channels}`
                                                                    }
                                                                    currency="$"
                                                                    ammount={
                                                                        recommendedData[1] &&
                                                                        recommendedData[1].base_price_first &&
                                                                        `${recommendedData[1].base_price_first}`
                                                                    }
                                                                    planValidFor={
                                                                        recommendedData[1].service_contract_length
                                                                            ? `for ${recommendedData[1].service_contract_length}`
                                                                            : ''
                                                                    }
                                                                    planRate={
                                                                        recommendedData[1] &&
                                                                        recommendedData[1].base_price_second &&
                                                                        `${recommendedData[1].base_price_second}`
                                                                    }
                                                                    reward="Get $100 Visa gift card"
                                                                    scheduleCall={() => this.buyNow(recommendedData[1])}
                                                                    viewDetails={
                                                                        !recommendedData[1].offer_description &&
                                                                        !recommendedData[1].disclaimer
                                                                            ? 'NoData'
                                                                            : () =>
                                                                                  this.viewDetailHandler(
                                                                                      recommendedData[1],
                                                                                  )
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    marginTop: '20px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <CircularProgress />
                                                            </div>
                                                        )}
                                                        {recommendedData && recommendedData[2] && array2 ? (
                                                            <LongCard
                                                                className="recommendationLongCard3"
                                                                width="33%"
                                                                // height="300px"
                                                                // cardMargin="0px 0px 0px 10px"
                                                                cardImage={recommendedData[2].provider_logo}
                                                                bestForImage={AwardIcon}
                                                                features={array2}
                                                                bestForTitle="BEST FOR ENTERTAINMENT"
                                                                cardTitle={
                                                                    recommendedData[2] &&
                                                                    recommendedData[2].provider_name
                                                                }
                                                                availableSpeed={
                                                                    recommendedData[2] &&
                                                                    recommendedData[2].is_internet &&
                                                                    recommendedData[2].download_speed
                                                                        ? `${recommendedData[2].download_speed} Mbps`
                                                                        : '0 Mbps'
                                                                }
                                                                channels={
                                                                    recommendedData[2] &&
                                                                    recommendedData[2].no_of_channels
                                                                        ? `${recommendedData[2].no_of_channels}`
                                                                        : `${recommendedData[2].no_of_channels}`
                                                                }
                                                                currency="$"
                                                                ammount={
                                                                    recommendedData[2] &&
                                                                    recommendedData[2].base_price_first &&
                                                                    `${recommendedData[2].base_price_first}`
                                                                }
                                                                planValidFor={
                                                                    recommendedData[2].service_contract_length
                                                                        ? `for ${recommendedData[2].service_contract_length}`
                                                                        : ''
                                                                }
                                                                planRate={
                                                                    recommendedData[2] &&
                                                                    recommendedData[2].base_price_second &&
                                                                    `${recommendedData[2].base_price_second}`
                                                                }
                                                                reward="Get $100 Visa gift card"
                                                                scheduleCall={() => this.buyNow(recommendedData[2])}
                                                                viewDetails={
                                                                    !recommendedData[2].offer_description &&
                                                                    !recommendedData[2].disclaimer
                                                                        ? 'NoData'
                                                                        : () =>
                                                                              this.viewDetailHandler(recommendedData[2])
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                )}
                                        </div>
                                    ) : null}

                                    <div className="filters-div">
                                        <div className="filters">
                                            <div className="filtersHead">
                                                <div className="heading12">Filters</div>
                                                <div
                                                    className={isDouglasElliman ? 'link1' : 'linknoDoug'}
                                                    onClick={this.clearAll}
                                                >
                                                    clear all
                                                </div>
                                            </div>
                                            <div className="dynamicFilters">
                                                <Filters
                                                    Services={this.state.Services}
                                                    Providers={this.state.Providers}
                                                    AverageSpeed={this.state.AverageSpeed}
                                                    TvChannels={this.state.TvChannels}
                                                    MonthlyCost={this.state.MonthlyCost}
                                                    changeServices={(val: any) => this.changeServices(val)}
                                                    changeProviders={(val: any) => this.changeProviders(val)}
                                                    changeAverageSpeed={(val: any) => this.changeAverageSpeed(val)}
                                                    changeTvChannels={(val: any) => this.changeTvChannels(val)}
                                                    changeMonthlyCost={(val: any) => this.changeMonthlyCost(val)}
                                                />
                                            </div>
                                        </div>

                                        <div className="cardsDiv">
                                            <div className="upperheadCards">
                                                {totalSteps === activeStep ? (
                                                    <div className="heading1">
                                                        Other Suitable Plans ({plans.length})
                                                    </div>
                                                ) : (
                                                    <div className="heading1">
                                                        <div className="heading111">
                                                            We have found {plans.length} plans for{' '}
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <RoomIcon
                                                                className={
                                                                    isDouglasElliman
                                                                        ? 'locationIcon'
                                                                        : 'moveLocationIcon'
                                                                }
                                                            />
                                                            {/* <div className="locationIcon">for 14061 Old Wye Mills Road, Wye Mills</div> */}
                                                            <AutoComplete
                                                                value={this.state.address ? this.state.address : ''}
                                                                onChange={e => {
                                                                    this.setState({ address: e });
                                                                }}
                                                                onSelect={this.handleSelect}
                                                                placeholder="Location"
                                                                // inputTitle="To"
                                                                edit={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="sortByInternet">
                                                    <div
                                                        className="avg-customer-review-internet"
                                                        onClick={this.handleMenu}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div>Sort by: {this.state.sort && this.state.sort.label}</div>
                                                        <ExpandMoreIcon />
                                                    </div>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.AnchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.AnchorEl)}
                                                        onClose={this.handleClose}
                                                    >
                                                        <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                                        <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                                    </Menu>
                                                </div>
                                            </div>

                                            {plans.length > 0 ? (
                                                plans.map((el: any) => {
                                                    let array: any = [];
                                                    if (el.is_internet) {
                                                        array.push({
                                                            name: 'Internet',
                                                            icon: WifiIcon,
                                                        });
                                                    }
                                                    if (el.is_phone) {
                                                        array.push({
                                                            name: 'Phone',
                                                            icon: PhoneIcon,
                                                        });
                                                    }
                                                    if (el.is_tv) {
                                                        array.push({
                                                            name: 'TV',
                                                            icon: TVIcon,
                                                        });
                                                    }
                                                    return (
                                                        <div>
                                                            <div className="internet-cards">
                                                                <PlanCard
                                                                    width="100%"
                                                                    // height="300px"
                                                                    cardImage={el && el.provider_logo}
                                                                    features={array}
                                                                    cardTitle={el && el.provider_name}
                                                                    availableSpeed={
                                                                        el && el.is_internet && el.download_speed
                                                                            ? `${el.download_speed} Mbps`
                                                                            : '-'
                                                                    }
                                                                    channels={
                                                                        el && el.no_of_channels
                                                                            ? `${el.no_of_channels}`
                                                                            : `-`
                                                                    }
                                                                    currency="$"
                                                                    ammount={
                                                                        el &&
                                                                        el.base_price_first &&
                                                                        `${el.base_price_first}`
                                                                    }
                                                                    planRate={
                                                                        el &&
                                                                        el.base_price_second &&
                                                                        `${el.base_price_second}`
                                                                    }
                                                                    planValidFor={
                                                                        el.service_contract_length
                                                                            ? `for ${el.service_contract_length}`
                                                                            : ''
                                                                    }
                                                                    contractTerm={
                                                                        el.service_contract_length
                                                                            ? `${el.service_contract_length}`
                                                                            : 'NA'
                                                                    }
                                                                    buyNow={() => this.buyNow(el)}
                                                                    viewDetails={
                                                                        !el.offer_description && !el.disclaimer
                                                                            ? 'NoData'
                                                                            : () => this.viewDetailHandler(el)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="internet-cards-mobile">
                                                                <PlanCardMobile
                                                                    width="100%"
                                                                    // height="300px"
                                                                    cardImage={el && el.provider_logo}
                                                                    features={array}
                                                                    cardTitle={el && el.provider_name}
                                                                    availableSpeed={
                                                                        el && el.is_internet && el.download_speed
                                                                            ? `${el.download_speed} Mbps`
                                                                            : '-'
                                                                    }
                                                                    channels={
                                                                        el && el.no_of_channels
                                                                            ? `${el.no_of_channels}`
                                                                            : `-`
                                                                    }
                                                                    currency="$"
                                                                    ammount={
                                                                        el &&
                                                                        el.base_price_first &&
                                                                        `${el.base_price_first}`
                                                                    }
                                                                    planRate={
                                                                        el &&
                                                                        el.base_price_second &&
                                                                        `${el.base_price_second}`
                                                                    }
                                                                    planValidFor={
                                                                        el.service_contract_length
                                                                            ? `for ${el.service_contract_length}`
                                                                            : ''
                                                                    }
                                                                    contractTerm={
                                                                        el.service_contract_length
                                                                            ? `${el.service_contract_length}`
                                                                            : 'NA'
                                                                    }
                                                                    buyNow={() => this.buyNow(el)}
                                                                    viewDetails={
                                                                        !el.offer_description && !el.disclaimer
                                                                            ? 'NoData'
                                                                            : () => this.viewDetailHandler(el)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div>Oppss No plans available</div>
                                                // <PlanCardSeleton />
                                                // <div
                                                //     style={{
                                                //         marginTop: '20px',
                                                //         display: 'flex',
                                                //         justifyContent: 'center',
                                                //         alignItems: 'center',
                                                //     }}
                                                // >
                                                //     <CircularProgress />
                                                // </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.xfinityModal && (
                                <Modal
                                    isShowModal={this.state.xfinityModal}
                                    onCloseModal={() => this.setState({ xfinityModal: false, xfinityLink: null })}
                                    showClose={false}
                                    className="xfinityModalInternet"
                                >
                                    <div className="xfinityModal">
                                        <img
                                            src={this.state.xfinityLink && this.state.xfinityLink.provider_logo}
                                            alt={'xfinity logo'}
                                            width={'100px'}
                                        />
                                        <div>You will be rerouted to Xfinity website to complete your order</div>
                                        <Button
                                            className="btnIC"
                                            // color="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                      }`
                                            }
                                            width="250px"
                                            height="60px"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            onClick={() => {
                                                window.open(
                                                    this.state.xfinityLink && this.state.xfinityLink.plan_url,
                                                    '_blank',
                                                );
                                                this.props.dashboardAction.updateServicePercentage({
                                                    data: {
                                                        completed_percentage: 100,
                                                    },
                                                    service_id: this.state.serviceId,
                                                });
                                            }}
                                        >
                                            Continue to Xfinity Website
                                        </Button>
                                        <Button
                                            className="btnIC"
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                      }`
                                            }
                                            width="250px"
                                            height="60px"
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            border={
                                                isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : `1px solid ${
                                                          slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                      }`
                                            }
                                            onClick={() => this.setState({ xfinityModal: false, xfinityLink: null })}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Modal>
                            )}
                            {this.state.viewDetailModal && (
                                <Modal
                                    isShowModal={this.state.viewDetailModal}
                                    onCloseModal={() =>
                                        this.setState({
                                            viewDetailModal: false,
                                            viewDetailData: null,
                                            activeViewTab: 'providerDetail',
                                        })
                                    }
                                    showClose={false}
                                    className="recommendationInternetViewDetails"
                                >
                                    <div>
                                        <div className="viewDetailHeader">
                                            <div
                                                style={{ fontSize: '24px', fontWeight: 'bold' }}
                                            >{`${viewDetailData.provider_name}'s details`}</div>
                                            {/* <div
                                    style={{ color: '#6B6C6F', cursor: 'pointer', fontWeight: 500, fontSize: '30px' }}
                                    onClick={() => this.setState({ viewDetailModal: false, viewDetailData: null })}
                                >
                                    x
                                </div> */}
                                            <img
                                                src={CloseIcon}
                                                width="22px"
                                                onClick={() =>
                                                    this.setState({
                                                        viewDetailModal: false,
                                                        viewDetailData: null,
                                                        activeViewTab: 'providerDetail',
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="viewDetailBody">
                                            <div style={{ width: '25%', borderRight: '#00000029 1px solid' }}>
                                                {/* offer_description,disclaimer,disclaimer */}
                                                {viewDetailData && viewDetailData.offer_description && (
                                                    <div
                                                        className={
                                                            this.state.activeViewTab === 'providerDetail'
                                                                ? 'viewDetailTab active'
                                                                : 'viewDetailTab'
                                                        }
                                                        onClick={() => this.activeTabHandler('providerDetail')}
                                                    >
                                                        Providers Details
                                                    </div>
                                                )}
                                                {viewDetailData && viewDetailData.disclaimer && (
                                                    <div
                                                        className={
                                                            this.state.activeViewTab === 'tc'
                                                                ? 'viewDetailTab active'
                                                                : 'viewDetailTab'
                                                        }
                                                        onClick={() => this.activeTabHandler('tc')}
                                                    >
                                                        Terms & Condition
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ width: '75%', padding: '15px', overflow: 'auto' }}>
                                                {this.state.activeViewTab === 'providerDetail' &&
                                                viewDetailData &&
                                                viewDetailData.offer_description ? (
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: 700,
                                                                marginBottom: '5px',
                                                            }}
                                                        >
                                                            About {viewDetailData && viewDetailData.provider_name}
                                                        </div>
                                                        {/* {viewDetailData && viewDetailData.offer_description} */}
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    viewDetailData && viewDetailData.offer_description,
                                                            }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    // <div>{viewDetailData && viewDetailData.disclaimer}</div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: viewDetailData && viewDetailData.disclaimer,
                                                        }}
                                                    ></div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="viewDetailFooter">
                                            <Button
                                                className="btnIC"
                                                backgroundColor="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : '#273E59'
                                                          }`
                                                }
                                                width="140px"
                                                height="50px"
                                                border={
                                                    isDouglasElliman
                                                        ? '1px solid #100B28'
                                                        : `1px solid ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : '#273e59'
                                                          }`
                                                }
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                margin="0 15px 0 0"
                                                onClick={() => {
                                                    this.setState({
                                                        viewDetailModal: false,
                                                        viewDetailData: null,
                                                        activeViewTab: 'providerDetail',
                                                    });
                                                    this.buyNow(viewDetailData);
                                                }}
                                            >
                                                Select
                                            </Button>
                                            <Button
                                                className="btnIC"
                                                // color="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : '#273E59'
                                                          }`
                                                }
                                                width="140px"
                                                height="50px"
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                onClick={() => console.log()}
                                            >
                                                See Plan Online
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            )}
                            {this.state.showFilterModal && (
                                <Modal
                                    isShowModal={this.state.showFilterModal}
                                    onCloseModal={() => this.setState({ showFilterModal: false })}
                                    showClose={false}
                                    className="internetRecommendationfilterModal"
                                >
                                    <div>
                                        <div className="filtersHead">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ArrowBackIcon
                                                    onClick={() => this.setState({ showFilterModal: false })}
                                                />

                                                <div className="headingFilter1">Filters</div>
                                            </div>
                                            <div
                                                className={isDouglasElliman ? 'link1' : 'linknoDoug'}
                                                onClick={this.clearAll}
                                            >
                                                clear all
                                            </div>
                                        </div>
                                        <div className="ModalFilter">
                                            <Filters
                                                Services={this.state.Services}
                                                Providers={this.state.Providers}
                                                AverageSpeed={this.state.AverageSpeed}
                                                TvChannels={this.state.TvChannels}
                                                MonthlyCost={this.state.MonthlyCost}
                                                changeServices={(val: any) => this.changeServices(val)}
                                                changeProviders={(val: any) => this.changeProviders(val)}
                                                changeAverageSpeed={(val: any) => this.changeAverageSpeed(val)}
                                                changeTvChannels={(val: any) => this.changeTvChannels(val)}
                                                changeMonthlyCost={(val: any) => this.changeMonthlyCost(val)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                className="btnIC"
                                                // color="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : `0 0 3px 0 ${
                                                              slug && slug.primary_color
                                                                  ? slug.primary_color
                                                                  : '#273E59'
                                                          }`
                                                }
                                                width="90%"
                                                height="50px"
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="16px"
                                                margin="20px"
                                                onClick={() => this.setState({ showFilterModal: false })}
                                            >
                                                Apply Filters
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            )}
                        </div>
                        <Footer
                            fromLanding={
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.fromLanding &&
                                this.props.location.state.fromLanding === 'fromLanding'
                            }
                        />
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        internetAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    internetState: state.internet,
    commonState: state.common,
    dashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedPage);
