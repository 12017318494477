import React from 'react';
// import history from '../../routes/History';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '../../../components/atoms/Button';
// import GoogleLogin from 'react-google-login';
import './signUpForm.scss';
import { useHistory } from 'react-router';

type initialProps = {
    history?: any;
    fullName?: string;
    email?: string;
    phone?: string;
    handleChangePhone?: any;
    phoneError?: string;
    password?: any;
    handleChangePassword?: any;
    handleChangeEmail?: any;
    handleChangeName?: any;
    showPassword?: any;
    handleClickShowPassword?: any;
    responseGoogle?: any;
    signInClick?: any;
    validate?: any;
    nameError?: string;
    emailError?: string;
    passwordError?: string;
    hashCode?: any;
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_campaign?: string;
};

const SignUpForm = (props: initialProps) => {
    const history = useHistory();

    const getStartedHandler = () => {
        // validate(props);
        // console.log('phone',props)
        if (props.validate()) {
            history.push({
                pathname: '/signUp/next',
                state: {
                    fullName: props.fullName,
                    email: props.email,
                    password: props.password,
                    hashCode: props.hashCode,
                    phone: props.phone,
                    utm_source: props.utm_source,
                    utm_medium: props.utm_medium,
                    utm_content: props.utm_content,
                    utm_campaign: props.utm_campaign,
                },
            });
            window.scrollTo(0, 0);

            let slug: any = localStorage.getItem('slug');
            if (slug) {
                slug = JSON.parse(slug);

                let brokerage: any;
                brokerage = slug.display_name;
                let brokerage_key: any = slug.brokerage_key;
                let agent_key: any = slug.realtor_key;

                let agent_name: any = slug.contact_name;
                let move_id: any = localStorage.getItem('moveKey');

                let windownew: any = window;
                let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                analytics.track(
                    'Signed Up',
                    {
                        brokerage_name: brokerage,
                        brokerage_key: brokerage_key,
                        agent_key: agent_key,
                        agent_name: agent_name,
                        page: 'Dashboard',
                        source: agent_name,
                    },
                    { user_id: move_id },
                );
            }
        }
    };
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    let slug1: any = localStorage.getItem('slug');
    if (slug1) {
        slug1 = JSON.parse(slug1);
    }
    return (
        <div className={isDouglasElliman ? 'signup-form-main-div signup-form-main-divD' : 'signup-form-main-div'}>
            <div className="inputDiv">
                {isDouglasElliman && (
                    <div>
                        <div className="topHeadingForm" style={{ color: '#323744' }}>
                            Let’s Get Started{' '}
                        </div>
                        <div className="subHeadingForm"> With Your Account </div>
                    </div>
                )}
                <TextField
                    className="signUpInput"
                    error={Boolean(props.nameError)}
                    label="Full Name"
                    id="outlined-start-adornment"
                    onChange={(e: any) => props.handleChangeName(e)}
                    variant="filled"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    value={props.fullName}
                />
                {props.nameError !== '' ? (
                    <div className="errorSignup">
                        <small>{props.nameError}</small>
                    </div>
                ) : null}
            </div>

            <div className="inputDiv">
                <TextField
                    className="signUpInput"
                    error={Boolean(props.emailError)}
                    label="Email ID (this will be your username)"
                    id="outlined-start-adornment"
                    onChange={(e: any) => props.handleChangeEmail(e)}
                    variant="filled"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    value={props.email}
                />
                {props.emailError !== '' ? (
                    <div className="errorSignup">
                        <small>{props.emailError}</small>
                    </div>
                ) : null}
            </div>

            <div className="inputDiv">
                <TextField
                    className="signUpInput"
                    error={Boolean(props.phoneError)}
                    label="Phone no"
                    id="outlined-start-adornment"
                    onChange={(e: any) => props.handleChangePhone(e)}
                    variant="filled"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    value={props.phone}
                />
                {props.emailError !== '' ? (
                    <div className="errorSignup">
                        <small>{props.phoneError}</small>
                    </div>
                ) : null}
            </div>

            <div className="inputDiv">
                <TextField
                    className="signUpInput"
                    error={Boolean(props.passwordError)}
                    label="Create Password"
                    id="outlined-start-adornment"
                    onChange={(e: any) => props.handleChangePassword(e)}
                    variant="filled"
                    type={props.showPassword ? 'type' : 'password'}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={props.handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                >
                                    {props.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={props.password}
                />
                {props.passwordError !== '' ? (
                    <div className="errorSignup">
                        <small>{props.passwordError}</small>
                    </div>
                ) : null}
            </div>

            <Button
                color="#fff"
                backgroundColor={
                    isDouglasElliman ? '#100B28' : slug1 && slug1.primary_color ? slug1.primary_color : '#273e59'
                }
                className="signUpButton"
                onClick={getStartedHandler}
            >
                Get Started
            </Button>

            {/* <div className="lineDiv">
                <div className="line"></div>
                Or
                <div className="line"></div>
            </div> */}
            <div>
                {/* <GoogleLogin
                    clientId="213806778009-rcppjlc2avd04okt2jg8tsr1ihiad7qj.apps.googleusercontent.com"
                    buttonText="Continue with Google"
                    onSuccess={props.responseGoogle}
                    onFailure={props.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    className="googleButton"
                /> */}
            </div>

            <div className="signInDiv">
                Already a member?{' '}
                <span
                    className={isDouglasElliman ? 'signInText signInText1' : 'signInText'}
                    onClick={props.signInClick}
                >
                    Sign In
                </span>
            </div>
        </div>
    );
};

export default SignUpForm;
// export default Login;
