import React, { Component } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './SubscriptionGetStartedCard.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import solarIcon from '../../Assets/images/homeOwnerDashboard/solarIcon.png';
import Button from '../../components/atoms/Button';
import energyNotDone from '../../Assets/images/homeOwnerDashboard/energyNotDone.svg';
import internetNotDone from '../../Assets/images/homeOwnerDashboard/internetService.svg';
import solarNotDone from '../../Assets/images/homeOwnerDashboard/solarService.svg';
import insuranceNotDone from '../../Assets/images/homeOwnerDashboard/insuranceService.svg';
import warrantyNotDone from '../../Assets/images/homeOwnerDashboard/warrantyService.svg';
import securityNotDone from '../../Assets/images/homeOwnerDashboard/securityService.svg';
import Skeleton from 'react-loading-skeleton';

type initialProps = {
    heading?: string;
    subHeading?: string;
    services?: any;
    progressValue?: any;
    progressValueData?: any;
    // getStarted?: any;
    completedService?: any;
    onClickService?: any;
};
type initialState = {
    internetopen: boolean;
    energyopen: boolean;
    solaropen: boolean;
    utilitiesopen: boolean;
    insuranceopen: boolean;
    warrantyopen: boolean;
    securityopen: boolean;
};

export class SubscriptionGetStartedCard extends Component<initialProps, initialState> {
    state: initialState = {
        internetopen: false,
        energyopen: false,
        solaropen: false,
        utilitiesopen: false,
        insuranceopen: false,
        warrantyopen: false,
        securityopen: false,
    };
    handleOpen = (name: string) => {
        if (name == 'Internet & Cable') {
            this.setState({
                internetopen: !this.state.internetopen,
            });
        }
        if (name == 'Energy Savings') {
            this.setState({
                energyopen: !this.state.energyopen,
            });
        }
        if (name == 'Solar') {
            this.setState({
                solaropen: !this.state.solaropen,
            });
        }
        if (name == 'Utility Providers') {
            this.setState({
                utilitiesopen: !this.state.utilitiesopen,
            });
        }
        if (name == 'Home Insurance' || name == 'Insurance') {
            this.setState({
                insuranceopen: !this.state.insuranceopen,
            });
        }
        if (name == 'Home Warranty' || name == 'Warranty') {
            this.setState({
                warrantyopen: !this.state.warrantyopen,
            });
        }
        if (name == 'Home Security') {
            this.setState({
                securityopen: !this.state.securityopen,
            });
        }
    };
    render() {
        let internetService: any = [];
        let energyService: any = [];
        let solarService: any = [];
        let utilitiesService: any = [];
        let insuranceService: any = [];
        let warrantyService: any = [];
        let securityService: any = [];
        this.props.completedService &&
            this.props.completedService.map((item: any) => {
                if (item.service && item.service.name && item.service.name == 'Internet & Cable') {
                    internetService.push(item);
                }
                if (item.service && item.service.name && item.service.name == 'Energy Savings') {
                    energyService.push(item);
                }
                if (item.service && item.service.name && item.service.name == 'Solar') {
                    solarService.push(item);
                }
                if (item.service && item.service.name && item.service.name == 'Utility Providers') {
                    utilitiesService.push(item);
                }
                if (
                    item.service &&
                    item.service.name &&
                    (item.service.name == 'Home Insurance' || item.service.name == 'Insurance')
                ) {
                    insuranceService.push(item);
                }
                if (
                    item.service &&
                    item.service.name &&
                    (item.service.name == 'Home Warranty' || item.service.name == 'Warranty')
                ) {
                    warrantyService.push(item);
                }
                if (item.service && item.service.name && item.service.name == 'Home Security') {
                    securityService.push(item);
                }
            });
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="SubscriptionGetStartedCardMain">
                {this.props.progressValue > 0 ? null : (
                    <div className="headingSubscriptionGetStartedCardMain">{this.props.heading}</div>
                )}
                {this.props.progressValue > 0 ? null : (
                    <div className="subHeadingSubscriptionGetStartedCardMain">{this.props.subHeading}</div>
                )}

                {this.props.services.length ? (
                    this.props.services &&
                    this.props.services.map((item: any) => {
                        let open: boolean = false;
                        if (item.name == 'Internet & Cable') {
                            open = this.state.internetopen;
                        }
                        if (item.name == 'Energy Savings') {
                            open = this.state.energyopen;
                        }
                        if (item.name == 'Solar') {
                            open = this.state.solaropen;
                        }
                        if (item.name == 'Utility Providers') {
                            open = this.state.utilitiesopen;
                        }
                        if (item.name == 'Home Insurance' || item.name == 'Insurance') {
                            open = this.state.insuranceopen;
                        }
                        if (item.name == 'Home Warranty' || item.name == 'Warranty') {
                            open = this.state.warrantyopen;
                        }
                        if (item.name == 'Home Security') {
                            open = this.state.securityopen;
                        }
                        return (
                            <div>
                                <div className="servicesSubscriptionGetStartedCardFlexDiv">
                                    <div className="servicesSubscriptionGetStartedCardInner">
                                        <CheckCircleIcon
                                            className={
                                                this.props.progressValue > 0
                                                    ? item.completed
                                                        ? 'greenIcon'
                                                        : 'greyIcon'
                                                    : 'greyIcon'
                                            }
                                        />

                                        <div
                                            className={
                                                this.props.progressValue > 0
                                                    ? // ? item.completed
                                                      'serviceNameSubscriptionGetStartedCard1'
                                                    : 'serviceNameSubscriptionGetStartedCard'
                                                // : 'serviceNameSubscriptionGetStartedCard'
                                            }
                                        >
                                            {item.name}
                                        </div>
                                    </div>
                                    {this.props.progressValue > 0 || this.props.progressValueData > 0 ? (
                                        <div>
                                            <div
                                                className=""
                                                onClick={() => {
                                                    this.handleOpen(item.name);
                                                }}
                                            >
                                                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                {this.props.progressValue > 0 ? (
                                    <div>
                                        {open ? (
                                            <div>
                                                {item.name === 'Internet & Cable' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {internetService &&
                                                                internetService.map((item: any) => {
                                                                    return (
                                                                        <div className="internetOnComplete">
                                                                            <div className="internetTitle">
                                                                                {item.provider}
                                                                            </div>
                                                                            <div className="internetOnCompleteService">
                                                                                <div className="internetOnCompleteContactHead">
                                                                                    Services{' '}
                                                                                </div>

                                                                                <div className="internetOnCompleteContactValue">
                                                                                    $
                                                                                    <span
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {' '}
                                                                                        {item.price}
                                                                                    </span>
                                                                                    /pm
                                                                                </div>
                                                                            </div>
                                                                            <div className="internetOnCompleteSpeed">
                                                                                <div className="internetOnCompleteSpeedHead">
                                                                                    {item.services_brought}
                                                                                    {/* <span
                                                                                        style={{ fontWeight: 'normal' }}
                                                                                    >
                                                                                        {' '}
                                                                                        - 100 Mbps speed{' '}
                                                                                    </span> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="internetOnCompleteContact">
                                                                                <div className="internetOnCompleteContactDetail">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                                {item.contact_number ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Contact no:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            {item.contact_number}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={internetNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     '/dashboard/internet',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {item.name === 'Solar' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {solarService &&
                                                                solarService.map((item: any) => {
                                                                    return (
                                                                        <div className="solarOnComplete">
                                                                            <div className="solorOnCompleteMain">
                                                                                <div className="solorImage">
                                                                                    <img src={solarIcon} />
                                                                                </div>
                                                                                <div className="solarOnCompleteInside">
                                                                                    <div className="solarOnCompletehead">
                                                                                        Your Solar Provider
                                                                                    </div>
                                                                                    {/* {item.contact_number ? ( */}
                                                                                    <div className="solarOnCompletehead">
                                                                                        <span>${item.price}/pm</span>
                                                                                    </div>
                                                                                    {/* ) : null} */}
                                                                                </div>
                                                                            </div>
                                                                            {item.account_number ? (
                                                                                <div className="internetOnCompleteContactDetail solarAccount">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={energyNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push('dashboard/solar');
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {item.name === 'Energy Savings' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {energyService &&
                                                                energyService.map((item: any) => {
                                                                    return (
                                                                        <div className="solarOnComplete">
                                                                            <div className="solorOnCompleteMain">
                                                                                <div className="solorImage">
                                                                                    <img src={solarIcon} />
                                                                                </div>
                                                                                <div className="solarOnCompleteInside">
                                                                                    <div className="solarOnCompletehead">
                                                                                        {item.provider}
                                                                                    </div>
                                                                                    {/* {item.contact_number ? ( */}
                                                                                    <div className="solarOnCompletehead">
                                                                                        <span>${item.price}</span>/pm
                                                                                    </div>
                                                                                    {/* ) : null} */}
                                                                                </div>
                                                                            </div>
                                                                            {item.account_number ? (
                                                                                <div className="internetOnCompleteContactDetail solarAccount">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={energyNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/energy-savings',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {item.name === 'Solar' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {energyService &&
                                                                energyService.map((item: any) => {
                                                                    return (
                                                                        <div className="solarOnComplete">
                                                                            <div className="solorOnCompleteMain">
                                                                                <div className="solorImage">
                                                                                    <img src={solarIcon} />
                                                                                </div>
                                                                                <div className="solarOnCompleteInside">
                                                                                    <div className="solarOnCompletehead">
                                                                                        {item.provider}
                                                                                    </div>
                                                                                    {/* {item.contact_number ? ( */}
                                                                                    <div className="solarOnCompletehead">
                                                                                        <span>${item.price}</span>/pm
                                                                                    </div>
                                                                                    {/* ) : null} */}
                                                                                </div>
                                                                            </div>
                                                                            {item.account_number ? (
                                                                                <div className="internetOnCompleteContactDetail solarAccount">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={solarNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/energy-savings',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                {item.name === 'Utility Providers' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {utilitiesService &&
                                                                utilitiesService.map((item: any) => {
                                                                    return (
                                                                        <div className="solarOnComplete">
                                                                            <div className="solorOnCompleteMain">
                                                                                <div className="solorImage">
                                                                                    <img src={solarIcon} />
                                                                                </div>
                                                                                <div className="solarOnCompleteInside">
                                                                                    <div className="solarOnCompletehead">
                                                                                        {item.provider}
                                                                                    </div>
                                                                                    <div className="solarOnCompletehead">
                                                                                        <span></span>${item.price}/pm
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={energyNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/utilities',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {this.props.progressValue > 0 ? (
                                    <div>
                                        {open ? (
                                            <div>
                                                {(item.name === 'Home Insurance' || item.name === 'Insurance') &&
                                                    (item.completed ? (
                                                        <div>
                                                            {insuranceService &&
                                                                insuranceService.map((item: any) => {
                                                                    return (
                                                                        <div className="insuranceOnComplete">
                                                                            <div className="">{item.provider}</div>
                                                                            <div className="insuranceOnCompleteContent">
                                                                                {item.price ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Price:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            ${item.price}/pm
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                                {item.deductible ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Deductible:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            ${item.deductible}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                                <div className="internetOnCompleteContactDetail">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                                {item.contact_number ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Contact no:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            {item.contact_number}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={insuranceNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        backgroundColor="#fff"
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/insurance',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {(item.name === 'Home Warranty' || item.name === 'Warranty') &&
                                                    (item.completed ? (
                                                        <div>
                                                            {warrantyService &&
                                                                warrantyService.map((item: any) => {
                                                                    return (
                                                                        <div className="insuranceOnComplete">
                                                                            <div className="">{item.provider}</div>
                                                                            <div className="insuranceOnCompleteContent">
                                                                                {item.price ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Price:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            ${item.price}/pm
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                                <div className="internetOnCompleteContactDetail">
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        Account no:
                                                                                    </div>
                                                                                    <div className="internetOnCompleteContactHead">
                                                                                        {item.account_number}
                                                                                    </div>
                                                                                </div>
                                                                                {item.contact_number ? (
                                                                                    <div className="internetOnCompleteContactDetail">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Contact no:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            {item.contact_number}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={warrantyNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        // color="#002E62"
                                                                        backgroundColor="#fff"
                                                                        // border={' 1px solid #002E62'}
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/home-warranty',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                {item.name === 'Home Security' &&
                                                    (item.completed ? (
                                                        <div>
                                                            {securityService &&
                                                                securityService.map((item: any) => {
                                                                    return (
                                                                        <div className="homeOnComplete">
                                                                            <div className="">{item.provider}</div>
                                                                            <div className="homeOnCompleteInner">
                                                                                <div className="homeOnCompleteInnerData">
                                                                                    <div className="internetOnCompleteContactDetail homeSecurityPadding">
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            Account no:
                                                                                        </div>
                                                                                        <div className="internetOnCompleteContactHead">
                                                                                            {item.account_number}
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.contact_number ? (
                                                                                        <div className="internetOnCompleteContactDetail">
                                                                                            <div className="internetOnCompleteContactHead">
                                                                                                Contact no:
                                                                                            </div>
                                                                                            <div className="internetOnCompleteContactHead">
                                                                                                {item.contact_number}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                                <div className="internetOnCompleteContactValue">
                                                                                    $
                                                                                    <span
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {' '}
                                                                                        {item.price}
                                                                                    </span>
                                                                                    /pm
                                                                                </div>
                                                                            </div>

                                                                            <div className="border-line"></div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className="energynotOnComplete">
                                                            <div className="energynotCompleteHead">
                                                                <div className="">
                                                                    <img src={securityNotDone} />
                                                                </div>
                                                                <div className="energyNotComplete">
                                                                    Get accurate estimates on each item for your
                                                                    security equipments for your home.
                                                                </div>
                                                                <div className="">
                                                                    <Button
                                                                        width="160px"
                                                                        height="38px"
                                                                        // color="#002E62"
                                                                        backgroundColor="#fff"
                                                                        // border={' 1px solid #002E62'}
                                                                        color={
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }
                                                                        border={`1px solid ${
                                                                            isDouglasElliman
                                                                                ? '#100B28'
                                                                                : slug && slug.primary_color
                                                                                ? slug && slug.primary_color
                                                                                : '#273E59'
                                                                        }`}
                                                                        borderRadius="4px"
                                                                        onClick={() => {
                                                                            // this.props.history.push(
                                                                            //     'dashboard/security',
                                                                            // );
                                                                            this.props.onClickService();
                                                                        }}
                                                                    >
                                                                        Get Started
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {item.name === 'documentry' &&
                                    (item.completed ? (
                                        <div className="documentOnComplete">
                                            <div className=""></div>
                                        </div>
                                    ) : null)}
                            </div>
                        );
                    })
                ) : (
                    <div className="ServiceSkeletonLoader">
                        <Skeleton height="30px" width="200px" />
                    </div>
                )}
            </div>
        );
    }
}

export default SubscriptionGetStartedCard;
