import React, { Component } from 'react';
import './templateDash.scss';

type initialProps = {
    onClick?: any;
    onClickWhite?: any;
    headerText: string;
    subheaderText?: string;
    highlightedText?: string;
    isHighlightedFirst?: boolean;
    pageImage: string;
    isCoverImage?: boolean;
    isLeft?: boolean;
    isRight?: boolean;
    icon1?: any;
    icon2?: any;
    icon3?: any;
    icon4?: any;
    icon1Text?: any;
    icon2Text?: any;
    icon3Text?: any;
    icon4Text?: any;
    num: number;
};
export class TemplateDash extends Component<initialProps> {
    render() {
        let classForImage: string = 'firstDouglasDashboard';
        const { isCoverImage, num } = this.props;
        if (num === 1) {
            classForImage = 'firstDouglasDashboard';
        } else if (num === 2) {
            classForImage = 'secondDouglasDashboard';
        } else if (num === 3) {
            classForImage = 'thirdDouglasDashboard';
        } else if (num === 4) {
            classForImage = 'fourthDouglasDashboard';
        }
        return (
            <div
                className={
                    isCoverImage
                        ? 'TemplateUpperComponentDashboard addTemplateUpperComponentDashboard'
                        : 'TemplateUpperComponentDashboard'
                }
            >
                <div
                    className={
                        isCoverImage
                            ? this.props.isLeft
                                ? `template-main-div ${classForImage} addtemplate-main-div leftClass`
                                : this.props.isRight
                                ? `template-main-div ${classForImage} addtemplate-main-div rightClass`
                                : `template-main-div ${classForImage} addtemplate-main-div`
                            : this.props.isLeft
                            ? `template-main-div ${classForImage} leftClass`
                            : this.props.isRight
                            ? `template-main-div ${classForImage} rightClass`
                            : `template-main-div ${classForImage}`
                    }
                >
                    <div className={'borderDiv'} style={{ background: '#100B28' }}>
                        <div className="template-main-div1">
                            {this.props.isHighlightedFirst ? (
                                <div className="HeaderText">
                                    {this.props.highlightedText && (
                                        <span className="highlightedText">{this.props.highlightedText}</span>
                                    )}{' '}
                                    {this.props.headerText}
                                </div>
                            ) : (
                                <div className="HeaderText">
                                    {this.props.headerText}{' '}
                                    {this.props.highlightedText && (
                                        <span className="highlightedText">{this.props.highlightedText}</span>
                                    )}
                                </div>
                            )}
                            {this.props.subheaderText && (
                                <div className={'subheaderText'}>{this.props.subheaderText}</div>
                            )}
                            {this.props.icon1 && (
                                <div className="IconsSection">
                                    <div className="iconDivD">
                                        <div className="imageDivD">
                                            <img src={this.props.icon1} />
                                        </div>
                                        <div className="textContentD">{this.props.icon1Text}</div>
                                    </div>
                                    <div className="iconDivD">
                                        <div className="imageDivD">
                                            <img src={this.props.icon2} />
                                        </div>
                                        <div className="textContentD">{this.props.icon2Text}</div>
                                    </div>
                                    <div className="iconDivD">
                                        <div className="imageDivD">
                                            <img src={this.props.icon3} />
                                        </div>
                                        <div className="textContentD">{this.props.icon3Text}</div>
                                    </div>
                                    <div className="iconDivD">
                                        <div className="imageDivD">
                                            <img src={this.props.icon4} />
                                        </div>
                                        <div className="textContentD">{this.props.icon4Text}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateDash;
