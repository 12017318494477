import React, { Component } from 'react';
import './HomeAdvisorCard.scss';

import Button from '../atoms/Button';
import requestSend from '../../Assets/images/TrustedPros/requestSend.svg';
import homeAdvisor from '../../Assets/images/TrustedPros/homeAdvisor.png';

type initialProps = {
    submit?: boolean;
    searchVendor: any;
};

type initialState = {};

export class HomeAdvisorCard extends Component<initialProps, initialState> {
    state: initialState = {};

    componentDidMount() {}
    render() {
        return (
            <div className={this.props.submit ? 'main-div-TrustedPros1 SubmitTrust1' : 'main-div-TrustedPros1'}>
                {this.props.submit ? (
                    <div className="">
                        <div className={this.props.submit ? ' SubmitTrustSucess1' : 'sub-div-trust1'}>
                            <div className="sub-div-trust-img1">
                                <img src={requestSend}></img>
                            </div>
                        </div>
                        <div className="sub-div-Info1">
                            <div className="sub-div-divide1">
                                <div className="sub-div-homePro1">Request Submitted</div>
                                <div className="sub-div-homeProDetail1">
                                    HomeAdvisor does not have recommended pros in your area, but our research team has
                                    received your research request. They will email you list of local trusted
                                    professionals within the next 24-48 hours.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="sub-div-trust1">
                            <div className="sub-div-trust-img12">
                                <img src={homeAdvisor}></img>
                            </div>
                        </div>

                        <div className="sub-div-Info1">
                            <div className="sub-div-divide1">
                                <div className="sub-div-homePro1">Need Home Pros Instantly?</div>
                                <div className="sub-div-homeProDetail1">
                                    Alternatively, you can search for top-rated professionals and get connected
                                    instantly by searching with HomeAdvisor.
                                </div>
                            </div>
                            <div className="sub-div-button-Requested1">
                                <Button className="searchButtonChanges" onClick={this.props.searchVendor}>
                                    Search Vendors
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default HomeAdvisorCard;
