import React, { Component } from 'react';
import './Custom.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import Button from '../../components/atoms/Button';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
// import CompanyLogo from '../../Assets/images/Yourbrokage.png';
// import { ReactComponent as Mortage } from '../../Assets/images/Mortgage&title/mortgagehome.svg';
import MortageHome from '../../Assets/images/Mortgage&title/mortageHome';
// import Mortage from '../../Assets/images/Mortgage&title/mortgagehome.svg';
// import CheckIcon from '@material-ui/icons/Check';
import HolidatyVillage from '../../Assets/images/Mortgage&title/holiday_village_white_24dp.svg';
// import Locationsvg from '../../Assets/images/Mortgage&title/place_black_24dp.svg';
// import Emailsvg from '../../Assets/images/Mortgage&title/alternate_email_black_24dp.svg';
// import Callbacksvg from '../../Assets/images/Mortgage&title/call_black_24dp.svg';
import { scroller } from 'react-scroll';
import Circle from '../../Assets/images/Mortgage&title/check_circle_black_24dp.svg';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
// import history from '../../routes/History';
// import { setTimeout } from 'timers';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../utils/utlis';

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    location?: any;
    match?: any;
};

type initialState = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    continueClick: boolean;
    enquire: boolean;
    option: any;
    id: string | number;
    slug: any;
    pathName: string;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    hashCode: any;
    service_Id: any;
    isHashcodeDataArrived: boolean;
    moveId: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    brand_partner_name: any;
    button_copy: any;
    form_button: any;
    form_pre_filled: boolean;
    form_field_name: any;
    form_step_number: any;
    prefilled: boolean;
};

class CustomPage extends Component<initialProps, initialState> {
    state: initialState = {
        isHashcodeDataArrived: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        continueClick: false,
        option: 'email',
        enquire: false,
        id: '',
        slug: null,
        pathName: '',
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        hashCode: '',
        service_Id: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        brand_partner_name: '',
        button_copy: '',
        form_button: '',
        form_pre_filled: true,
        form_field_name: '',
        form_step_number: '',
        prefilled: false,
        moveId: '',
    };
    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            this.props.commonAction.moveGetWithHashCode(payload);
        }

        // if (!this.state.hashCode && !localStorage.getItem('token')) {
        //     if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
        //         history.push({
        //             pathname: '/signUp',
        //             search: `hash_code=${this.state.hashCode}utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
        //         });
        //     } else {
        //         history.push({
        //             pathname: '/signUp',
        //         });
        //     }
        // }
        let path: any = window.location.pathname.split('/');
        path = path && path[path.length - 1];

        this.setState({ pathName: path });
        window.scrollTo(0, 0);
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: path,
                    category_name: 'Custom Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );

            formStartedSegment(
                'Form Started',
                'Dashboard',
                'custom Services',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                '',
                'Submit',
                true,
                this.state.prefilled,
                1,
                move_id,
            );
        }
        // this.props.commonAction.header({ partner_slug: slug && slug.slug });

        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const user = data && data.user_ref;
            this.setState({
                email: user && user.email,
                firstName: user && user.first_name,
                lastName: user && user.last_name,
                phone: user && user.phone,
            });
            if (user && user.email && user.first_name && user.last_name && user.phone) {
                this.setState({
                    prefilled: true,
                });
            }
        } else {
            // this.props.commonAction.moveGet();
        }
        if (
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.length > 0
        ) {
            this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.map((el: any) => {
                    if (el && el.slug === path) {
                        this.setState({ id: el && el.id });
                    }
                });
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        // console.log('CommonState', this.props.commonState);
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const user = cur.move[0] && cur.move[0].user_ref;
            this.setState({
                email: user && user.email,
                firstName: user && user.first_name,
                lastName: user && user.last_name,
                phone: user && user.phone,
            });

            if (this.props.commonState && this.props.commonState.header && this.props.commonState.header.length === 0) {
                const isHomeOwner = localStorage.getItem('isHomeOwner');
                this.props.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0],
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
            }
            this.props.commonAction.requestServiceGet();
            if (user && user.email && user.first_name && user.last_name && user.phone) {
                this.setState({
                    prefilled: true,
                });
            }
        }

        if (
            prev.requestService !== cur.requestService &&
            cur.requestService &&
            cur.requestService.service_requested === this.state.pathName
        ) {
            this.setState({ enquire: true });
            this.props.commonAction.moveGet();
        }
        if (prev.requestService !== cur.requestService && cur.requestService && cur.requestService.length > 0) {
            cur.requestService.map((el: any) => {
                if (el && el.service_requested === this.state.pathName && el.no_of_request > 0) {
                    this.setState({ enquire: true });
                }
            });
        }

        if (prevProps.match && this.props.match) {
            if (prevProps.match.url !== this.props.match.url) {
                let path: any = this.props.location.pathname.split('/');
                path = path[path.length - 1];

                this.setState({ pathName: path });
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);
                // this.setState({ slug: slug });
                this.props.commonAction.requestServiceGet();
                if (this.props.location && this.props.location.state && this.props.location.state.length > 0) {
                    this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.map((el: any) => {
                            if (el && el.slug === path) {
                                this.setState({ id: el && el.id });
                            }
                        });
                } else if (
                    this.props.commonState &&
                    this.props.commonState.header &&
                    this.props.commonState.header.length === 0
                ) {
                    this.props.commonAction.header({ partner_slug: window.location.hostname.split('.')[0] });
                }
            }
        }

        // if (
        //     prev.move !== cur.move &&
        //     cur.move &&
        //     cur.move[0] &&
        //     cur.move[0].destination &&
        //     cur.move[0].destination.destination_full_address &&
        //     cur.move[0].source &&
        //     cur.move[0].source.source_address_full_detail &&
        //     cur.move[0].user_ref.is_signup_done
        // ) {
        //     // console.log('Prev & cur', prev.move[0], cur.move[0]);
        // } else {
        //     // console.log('Move zero', cur && cur.move[0] && cur.move[0].hash_code);

        //     localStorage.clear();
        //     history.push({
        //         pathname: `/signUp`,
        //         search: `hash_code=${cur && cur.move[0] && cur.move[0].hash_code}&utm_source=${
        //             this.state.utm_source
        //         }&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${
        //             this.state.utm_campaign
        //         }`,
        //     });
        // }
    }

    firstNameHandler = (e: any) => {
        this.setState({ firstName: e.target.value });
    };
    lastNameHandler = (e: any) => {
        this.setState({ lastName: e.target.value });
    };
    emailHandler = (e: any) => {
        this.setState({ email: e.target.value });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({ phone: phonenumber });
    };
    handleRadioChange = (e: any) => {
        this.setState({ option: e.target.value });
    };

    handleInquireAgain = () => {
        this.setState({ enquire: false });

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Inquire Again',
                    category_name: 'custom services',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    submitHandler = () => {
        let path: any = this.props.location.pathname.split('/');
        path = path[path.length - 1];

        this.setState({ pathName: path });

        if (
            this.props &&
            this.props.commonState &&
            this.props.commonState.header &&
            this.props.commonState.header.length > 0
        ) {
            this.props &&
                this.props.commonState &&
                this.props.commonState.header.map((el1: any) => {
                    if (el1 && el1.slug === path) {
                        this.setState({ service_Id: el1 && el1.id });
                    }
                });
        }

        const { email, firstName, lastName, phone, option, id, service_Id } = this.state;

        if (
            email &&
            firstName &&
            lastName &&
            phone &&
            option &&
            (id || service_Id) &&
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)
        ) {
            this.props.commonAction.requestService({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                way_of_contact: option,
                service_date: new Date().toISOString().split('T')[0],
                service_requested: this.state.pathName,
                service_id: id ? id : service_Id,
            });

            let slug: any = localStorage.getItem('slug');
            slug = JSON.parse(slug);
            this.setState({ slug: slug });

            if (slug && slug.display_name) {
                let brokerage: any;

                brokerage = slug.display_name;
                let brokerage_key: any = slug.brokerage_key;
                let agent_key: any = slug.realtor_key;

                let agent_name: any = slug.contact_name;
                let move_id: any = localStorage.getItem('moveKey');

                buttonClicked(
                    'Button Clicked',
                    'Custom Services',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    'Custom Services',
                    'Submit',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    true,
                    1,
                    '',
                    move_id,
                );

                formCompletedSegment(
                    'Form Completed',
                    'Dashboard',
                    'Custom Services',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    '',
                    'Submit',
                    true,
                    this.state.prefilled,
                    'custom Services',
                    1,
                    move_id,
                );
            }
        }
    };

    render() {
        let slug1: any = localStorage.getItem('slug');
        if (slug1) {
            slug1 = JSON.parse(slug1);
        }
        const { isDouglasElliman } = this.state;
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.slug === this.state.pathName)
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.slug === this.state.pathName)
                : [];

        let detailCard = (
            <div>
                <div style={{ fontWeight: 800, color: '#333', fontSize: '22px', margin: '20px 0 10px 0' }}>
                    {displayData && displayData[0] && displayData[0].name}
                </div>
                <div
                    className={isDouglasElliman ? 'paralink paralinkD' : 'paralink'}
                    dangerouslySetInnerHTML={{
                        __html:
                            displayData &&
                            displayData[0] &&
                            displayData[0].office_content_data &&
                            (displayData[0].office_content_data.contact_info ||
                                displayData[0].office_content_data.contact_info == '')
                                ? displayData[0].office_content_data.contact_info
                                : displayData && displayData[0] && displayData[0].contact_info,
                    }}
                />
                {/* <div style={{ fontWeight: 800, color: '#BEBEBE', fontSize: '12px', margin: '0 0 20px 0' }}>
                    License number: {slug && slug.license}
                </div>
                <div className="custom-detail">
                    <div className="custom-detail1">
                        <div style={{ marginRight: '10px' }}>
                            <img src={Callbacksvg} />
                        </div>
                        <div>{slug && slug.phone}</div>
                    </div>
                    <div className="custom-detail2">
                        <div style={{ marginRight: '10px' }}>
                            <img src={Emailsvg} />
                        </div>
                        <div>{slug && slug.email}</div>
                    </div>
                </div>
                <div className="custom-detail3">
                    <div style={{ marginRight: '10px' }}>
                        <img src={Locationsvg} />
                    </div>
                    <div>{slug && slug.address}</div>
                </div> */}
            </div>
        );

        return (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="custom"
                            headerText={
                                displayData &&
                                displayData[0] &&
                                displayData[0].office_content_data &&
                                displayData[0].office_content_data.header
                                    ? displayData[0].office_content_data.header
                                    : displayData &&
                                      displayData[0] &&
                                      displayData[0].header &&
                                      displayData[0].header.substring(0, displayData[0].header.lastIndexOf(' '))
                            }
                            highlightedText={
                                displayData &&
                                displayData[0] &&
                                displayData[0].header &&
                                displayData[0].header.substring(
                                    displayData[0].header.lastIndexOf(' '),
                                    displayData[0].header.length,
                                )
                            }
                            subheaderText={
                                displayData &&
                                displayData[0] &&
                                displayData[0].office_content_data &&
                                displayData[0].office_content_data.sub_header
                                    ? displayData[0].office_content_data.sub_header
                                    : displayData && displayData[0] && displayData[0].sub_header
                            }
                            buttonText={this.state.enquire ? 'Check Status' : 'Inquire Now'}
                            onClick={() => {
                                // displayData && displayData[0] && displayData[0].cta_url
                                // ? window.open(displayData && displayData[0] && displayData[0].cta_url, '_blank'):
                                scroller.scrollTo(this.state.enquire ? 'custom-bottom-div3' : 'custom-bottom-div', {
                                    duration: 800,
                                    delay: 0,
                                    smooth: 'easeInOutQuart',
                                    offset: -180,
                                });
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="main-head-custom">
                            <h2 className="heading-custom">{displayData && displayData[0] && displayData[0].name}</h2>
                            <div className="path-custom">
                                <p
                                    className="Para-custom"
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard{'>'}
                                </p>
                                <p>{displayData && displayData[0] && displayData[0].name}</p>
                            </div>
                        </div>
                        <div className="custom-main-div">
                            <div className="custom-main-div1">
                                <div>
                                    <img
                                        src={
                                            displayData &&
                                            displayData[0] &&
                                            displayData[0].office_content_data &&
                                            (displayData[0].office_content_data.image ||
                                                displayData[0].office_content_data.image == '')
                                                ? displayData[0].office_content_data.image
                                                : displayData && displayData[0] && displayData[0].image
                                        }
                                        style={{ maxWidth: '300px', maxHeight: '150px' }}
                                    />
                                </div>
                                <div
                                    style={{
                                        color: '#333',
                                        fontSize: '32px',
                                        fontWeight: 800,
                                        margin: '20px 0 10px 0',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {displayData &&
                                    displayData[0] &&
                                    displayData[0].office_content_data &&
                                    displayData[0].office_content_data.header
                                        ? displayData[0].office_content_data.header
                                        : displayData && displayData[0] && displayData[0].header}
                                </div>
                                <div
                                    style={{
                                        color: '#333',
                                        fontSize: '16px',
                                        margin: '0 0 20px 0',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {displayData &&
                                    displayData[0] &&
                                    displayData[0].office_content_data &&
                                    displayData[0].office_content_data.sub_header
                                        ? displayData[0].office_content_data.sub_header
                                        : displayData && displayData[0] && displayData[0].sub_header}
                                </div>
                                <div>
                                    <Button
                                        className="btnIC"
                                        backgroundColor="#FFFFFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `0 0 3px 0 ${slug1.primary_color}`
                                                : '0 0 3px 0 #273E59'
                                        }
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `${slug1.primary_color}`
                                                : '#273E59'
                                        }
                                        borderRadius="5px"
                                        border={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `1px solid ${slug1.primary_color}`
                                                : '1px solid #273E59'
                                        }
                                        width="140px"
                                        height="40px"
                                        fontSize="14px"
                                        onClick={() => {
                                            // displayData && displayData[0] && displayData[0].cta_url
                                            // ? window.open(displayData && displayData[0] && displayData[0].cta_url, '_blank'):
                                            scroller.scrollTo(
                                                this.state.enquire ? 'custom-bottom-div3' : 'custom-bottom-div',
                                                {
                                                    duration: 800,
                                                    delay: 0,
                                                    smooth: 'easeInOutQuart',
                                                    offset: -180,
                                                },
                                            );
                                        }}
                                    >
                                        {this.state.enquire ? 'Check Status' : 'Inquire Now'}
                                    </Button>
                                </div>
                            </div>

                            <div className="custom-main-div2" style={{ marginBottom: '-5px' }}>
                                {/* <img src={Mortage} height="300px" /> */}
                                <MortageHome />
                            </div>
                        </div>
                        <div className="custom-mobile-top">
                            <div>
                                <img
                                    src={displayData && displayData[0] && displayData[0].image}
                                    style={{ maxWidth: '250px', maxHeight: '125px' }}
                                />
                            </div>
                            <div>
                                <MortageHome className="custom-home-img" />
                            </div>
                            <div
                                style={{
                                    color: '#333',
                                    fontSize: '22px',
                                    fontWeight: 800,
                                    margin: '20px 0 10px 0',
                                    textAlign: 'center',
                                }}
                            >
                                {displayData &&
                                displayData[0] &&
                                displayData[0].office_content_data &&
                                displayData[0].office_content_data.header
                                    ? displayData[0].office_content_data.header
                                    : displayData && displayData[0] && displayData[0].header}
                            </div>
                            <div style={{ color: '#333', fontSize: '12px', margin: '0 0 30px 0' }}>
                                {displayData &&
                                displayData[0] &&
                                displayData[0].office_content_data &&
                                displayData[0].office_content_data.sub_header
                                    ? displayData[0].office_content_data.sub_header
                                    : displayData && displayData[0] && displayData[0].sub_header}
                            </div>
                            <div className="btncustom">
                                <Button
                                    className="btnIC-1"
                                    backgroundColor="#FFFFFF"
                                    boxShadow="0 0 1px 0 #161D251A"
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug1 && slug1.primary_color
                                            ? `${slug1.primary_color}`
                                            : '#273E59'
                                    }
                                    borderRadius="5px"
                                    border={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug1 && slug1.primary_color
                                            ? `1px solid ${slug1.primary_color}`
                                            : '1px solid #273E59'
                                    }
                                    width="100%"
                                    height="50px"
                                    fontSize="14px"
                                    onClick={() => {
                                        // displayData && displayData[0] && displayData[0].cta_url
                                        //     ? window.open(displayData && displayData[0] && displayData[0].cta_url, '_blank'):
                                        scroller.scrollTo(
                                            this.state.enquire ? 'custom-bottom-div3' : 'custom-bottom-div2',
                                            {
                                                duration: 800,
                                                delay: 0,
                                                smooth: 'easeInOutQuart',
                                                offset: -80,
                                            },
                                        );
                                    }}
                                >
                                    {this.state.enquire ? 'Check Status' : 'Inquire Now'}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={
                        isDouglasElliman ? 'custom-bottom-div custom-bottom-divMainRemoveDouglas' : 'custom-bottom-div'
                    }
                >
                    <div className="custom-bottom-div1">
                        <div style={{ color: '#333', fontSize: '22px', fontWeight: 800, marginBottom: '20px' }}>
                            Looking for a reputable {displayData && displayData[0] && displayData[0].name}? Look no
                            further.
                        </div>
                        <div style={{ color: '#6B6C6F', fontSize: '16px', lineHeight: '30px' }}>
                            <div
                                style={{ marginBottom: '25px' }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        displayData &&
                                        displayData[0] &&
                                        displayData[0].office_content_data &&
                                        (displayData[0].office_content_data.content_text ||
                                            displayData[0].office_content_data.content_text == '')
                                            ? displayData[0].office_content_data.content_text
                                            : displayData && displayData[0] && displayData[0].content_text,
                                }}
                            ></div>
                            {/* <div style={{ marginBottom: '25px' }}>
                                Smiply complete the form and get in touch with our loan specialist.
                            </div>
                            <div style={{ marginBottom: '25px' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in nisi tempor,
                                malesuada mi in, laoreet mi. Donec massa ipsum, laoreet a velit sed, eleifend consequat
                                lectus. Quisque pretium tellus nisl, imperdiet
                            </div>
                            <div style={{ marginBottom: '25px' }}>
                                convallis est lobortis placerat. Cras suscipit ante sed qua m lacinia iaculis.
                                Suspendisse ex erat, egestas id sodales sed, aliquam a arcu. Donec rutrum tincidunt
                                risus ut laoree t. Vivamus fringilla auctor nulla a lacinia. Vestibulum conse ctetur
                                quis enim vel interdum.
                            </div> */}
                        </div>
                    </div>
                    {!this.state.enquire ? (
                        <div className="custom-bottom-div2">
                            <div
                                className="custom-enquireCardTitle"
                                style={{
                                    backgroundColor: isDouglasElliman
                                        ? '#100B28'
                                        : slug1 && slug1.primary_color
                                        ? slug1.primary_color
                                        : '#273e59',
                                }}
                            >
                                <div style={{ marginRight: '15px' }}>
                                    <img src={HolidatyVillage} />
                                </div>
                                <div>Inquire for {displayData && displayData[0] && displayData[0].name}</div>
                            </div>
                            <div
                                className={
                                    isDouglasElliman
                                        ? 'custom-enquireCardBody inputFieldsDouglasEllimanCustom'
                                        : 'custom-enquireCardBody'
                                }
                            >
                                <div className="custom-formRow">
                                    <div className="custom-formRow1">
                                        <TextField
                                            className={isDouglasElliman ? 'custom-input custom-inputD' : 'custom-input'}
                                            label="First Name"
                                            id="outlined-start-adornment"
                                            onChange={this.firstNameHandler}
                                            variant="filled"
                                            value={this.state.firstName}
                                            error={this.state.continueClick && !this.state.firstName}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                                !this.state.firstName &&
                                                'Please enter first name'}
                                        </div>
                                    </div>
                                    <div className="custom-formRow1">
                                        <TextField
                                            className={isDouglasElliman ? 'custom-input custom-inputD' : 'custom-input'}
                                            label="Last Name"
                                            id="outlined-start-adornment"
                                            onChange={this.lastNameHandler}
                                            variant="filled"
                                            value={this.state.lastName}
                                            error={this.state.continueClick && !this.state.lastName}
                                        />
                                        <div className="error">
                                            {this.state.continueClick &&
                                                !this.state.lastName &&
                                                'Please enter last name'}
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-formRow">
                                    <div className="custom-formRow1">
                                        <TextField
                                            className={isDouglasElliman ? 'custom-input custom-inputD' : 'custom-input'}
                                            label="Email"
                                            id="outlined-start-adornment"
                                            onChange={this.emailHandler}
                                            variant="filled"
                                            value={this.state.email}
                                            error={this.state.continueClick && !this.state.email}
                                        />
                                        <div className="error">
                                            {this.state.continueClick && !this.state.email && 'Please enter email'}
                                        </div>
                                    </div>
                                    <div className="custom-formRow1">
                                        <TextField
                                            className={isDouglasElliman ? 'custom-input custom-inputD' : 'custom-input'}
                                            label="Phone"
                                            id="outlined-start-adornment"
                                            onChange={this.phoneHandler}
                                            variant="filled"
                                            value={this.state.phone}
                                            error={
                                                (this.state.continueClick && !this.state.phone) ||
                                                (this.state.continueClick &&
                                                    this.state.phone !== '' &&
                                                    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                        this.state.phone,
                                                    ))
                                            }
                                        />
                                        <div className="error">
                                            {this.state.continueClick && !this.state.phone
                                                ? 'Please enter phone'
                                                : this.state.continueClick &&
                                                  this.state.phone &&
                                                  !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                      this.state.phone,
                                                  )
                                                ? 'Please enter valide phone number'
                                                : ''}
                                        </div>
                                    </div>
                                    {/* <div  className="custom-formRow1">
                                        <TextField
                                            className="custom-input"
                                            label="Phone"
                                            id="outlined-start-adornment"
                                            onChange={this.phoneHandler}
                                            variant="filled"
                                            value={this.state.phone}
                                        />
                                        <div className="error">
                                            {this.state.continueClick && !this.state.phone && 'Please enter phone'}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="custom-radio-text">
                                    Would you prefer for the vendor to contact you by phone or email?
                                </div>
                                <div>
                                    <RadioGroup
                                        aria-label="contact"
                                        name="contact"
                                        className={isDouglasElliman ? 'customRadio customRadioD' : 'customRadio'}
                                        value={this.state.option}
                                        onChange={this.handleRadioChange}
                                    >
                                        <FormControlLabel
                                            className="customRadio1"
                                            value="email"
                                            control={<Radio />}
                                            label="Email"
                                        />
                                        <FormControlLabel
                                            className="customRadio1"
                                            value="phone"
                                            control={<Radio />}
                                            label="Phone"
                                        />
                                    </RadioGroup>
                                </div>
                                <div className="custom-submitbtn">
                                    <Button
                                        className="custom-sub"
                                        // color="#FFFFFF"

                                        border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `${slug1.primary_color}`
                                                : '#273E59'
                                        }
                                        width="160px"
                                        height="50px"
                                        fontSize="16px"
                                        onClick={this.submitHandler}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                <div className="custom-submitbtn1">
                                    <Button
                                        className="custom-sub"
                                        // color="#FFFFFF"
                                        border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `${slug1.primary_color}`
                                                : '#273E59'
                                        }
                                        width="90vw"
                                        height="50px"
                                        fontSize="16px"
                                        onClick={this.submitHandler}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                {detailCard}
                            </div>
                        </div>
                    ) : (
                        <div className="custom-bottom-div3">
                            <div>
                                <div>
                                    <img src={Circle} />
                                </div>
                                <div className="custom-submitted-query">
                                    Your request has been submitted successfully
                                </div>
                                <div className="custom-submitted-query1">
                                    One of our customer service specialists will get in touch with you.
                                </div>
                                <div>
                                    <Button onClick={this.handleInquireAgain}>Inquire Again</Button>
                                </div>
                            </div>
                            {detailCard}
                        </div>
                    )}
                </div>
                <div className="custom-last-div">
                    <div className="custom-pleasenote">Please Note:</div>
                    <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                        {(displayData && displayData[0] && displayData[0].disclaimer) ||
                        (displayData &&
                            displayData[0] &&
                            displayData[0].office_content_data &&
                            (displayData[0].office_content_data.disclaimer ||
                                displayData[0].office_content_data.disclaimer == '')) ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        (displayData &&
                                            displayData[0] &&
                                            displayData[0].office_content_data &&
                                            displayData[0].office_content_data.disclaimer) ||
                                        displayData[0].office_content_data.disclaimer == ''
                                            ? displayData[0].office_content_data.disclaimer
                                            : displayData && displayData[0] && displayData[0].disclaimer,
                                }}
                            />
                        ) : (
                            'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);
