import React from 'react';
import { makeStyles } from '@material-ui/core';
import './dashboardCss/moveFrommoveToCard.scss';
import downArrow from '../../Assets/images/DashBoard/downArrow.svg';
import downArrowblue from '../../Assets/images/DashBoard/downblue.svg';
import moveTruck from '../../Assets/images/DashBoard/moveTruck.svg';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    fromPin?: string;
    toPin?: string;
    fromAddress?: string;
    toAddress?: string;
    dateNumber?: string;
    date?: string;
}

const CommonCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
            },
        },
    }));

    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div
            style={{
                width: props.width ? props.width : '500px',
                height: props.height ? props.height : '90px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : null,
                border: props.border ? props.border : '1px solid #DADCE0',
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '',
                padding: props.cardPadding ? props.cardPadding : '5px',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-height-width`}
        >
            <div
                className="cardContent"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <div className="card-section-1">
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <div className="section-1-heading">MOVE FROM</div>
                            <div className="section-1-number">{props.fromPin}</div>
                            <div className="section-1-content">{props.fromAddress}</div>
                        </div>

                        <div className="truck-div">
                            <img src={moveTruck} className="truck" />
                        </div>
                    </div>
                </div>

                <div className="card-section-2">
                    <div className="section-2-heading">
                        MOVE TO
                        <div className="section-2-number">{props.toPin}</div>
                        <div className="section-2-content">{props.toAddress}</div>
                    </div>
                </div>

                <div className="card-section-3">
                    <div className="section-3-heading">
                        DATE{' '}
                        <span>
                            {isDouglasElliman ? (
                                <img src={downArrow} width="10px" height="10px" style={{ marginLeft: '10px' }} />
                            ) : (
                                <img src={downArrowblue} width="10px" height="10px" style={{ marginLeft: '10px' }} />
                            )}
                        </span>
                    </div>
                    <div className="section-3-number">{props.dateNumber}</div>
                    <div className="section-3-content">{props.date}</div>
                </div>
            </div>
        </div>
    );
};

export default CommonCard;
