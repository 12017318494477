import React, { Component } from 'react';
import './utilitySavings.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FinalPlan from '../../components/Utility/FinalPlan';
import Button from '../../components/atoms/Button';
// import ElectricityGasMain from '../../components/Utility/ElectricityGasMainCard';
// import FireTopIcon from '../../Assets/images/utilityImages/fireTopIcon.svg';
import * as EnergyAction from '../../store/energy/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { powerkiosImageUrl } from '../../config';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
};
type initialState = {
    slug: any;
    congratulations: boolean;
    summaryData: any;
    accessToken: any;
};

export class confirmationPageUtilityPODS extends Component<initialProps, initialState> {
    state: initialState = {
        slug: { primary_color: '' },
        congratulations: false,
        summaryData: [],
        accessToken: '',
    };

    componentDidMount() {
        this.props.energyAction.getAccessToken();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        this.props.energyAction.getEnergySummary();
    }

    componentDidUpdate(prevProps: initialProps) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getAccessToken != curEnergy.getAccessToken &&
            curEnergy.getAccessToken &&
            curEnergy.getAccessToken.token &&
            curEnergy.isGetAccessToken
        ) {
            curEnergy.isGetAccessToken = false;
            this.setState({ accessToken: curEnergy.getAccessToken.token });
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ summaryData: curEnergy.getEnergySummary });
        }
    }
    componentWillUnmount(){
        localStorage.removeItem("pods-es-token");
    }
    handleFinalCongo = () => {
        this.setState({
            congratulations: true,
        });
    };

    handleBack = () => {
        AnalyticsService.buttonClicked({
            page: 'Energy Savings',
            category_name: 'Energy Savings Plan Selection Confirmation',
            button_copy:  'Back To Home'
        });

        this.props.history.push({
            pathname: '/dashboard/energy-savings/energySteps',
        });
    }

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className="utility-Savings-main-head">

                <div className="utilityConfirmationPage">
                    <div className="utilityConfirmationSubPage">
                        <div className="utilityConfirmationInnerContent">
                            {this.state.congratulations ? null : (
                                <div className="arrowButtonWidth">
                                    <ArrowBackIcon onClick={() => this.props.history.push('/dashboard/utilities')} />
                                </div>
                            )}
                            {this.state.congratulations ? (
                                <div>
                                    <div className="congoHeadUtility" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>
                                    Congratulations, your energy savings booking is completed
                                    </div>
                                    <div className="congoSubHeadUtility">
                                    We have sent an email for the confirmation
                                    </div>
                                </div>
                            ) : null}

                            {this.state.congratulations ? (
                                <div className="finalConfirmationCardContent">
                                    <div className="finalPageCardUtility">
                                        <div className="selectedPlanFinal">
                                            <div className="selectedPlanHead" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}} >Selected provider</div>
                                        </div>
                                        {this.state.summaryData &&
                                            this.state.summaryData.map((item: any) => {
                                                return item.contract_payload ? (
                                                    <FinalPlan
                                                        logo={
                                                            item.contract_payload &&
                                                            item.contract_payload.supplier &&
                                                            item.contract_payload.supplier.logo
                                                                ? `${powerkiosImageUrl}${item.contract_payload.supplier.logo}?access_token=${this.state.accessToken}`
                                                                : ''
                                                        }
                                                        kwh={item.contract_payload.displayRate}
                                                        contract={item.contract_payload.term}
                                                    />
                                                ) : null;
                                            })}
                                    </div>
                                    {this.state.summaryData &&
                                        this.state.summaryData.map((item: any) => {
                                            return (
                                                <div className="congratulationDetailsCard">
                                                    <div className="quoteDetailsCongo" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Quote details</div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Service Type</div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.service_type}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Order Number</div>
                                                        <div className="congoDetailsUtilityright">
                                                            #
                                                            {item.contract_payload && item.contract_payload.contractNum}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">
                                                            Service Start Date
                                                        </div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.contract_payload && item.contract_payload.switchDate
                                                                ? `${moment(item.contract_payload.switchDate).format(
                                                                      'MMM',
                                                                  )} ${moment(item.contract_payload.switchDate).format(
                                                                      'DD',
                                                                  )}, ${moment(item.contract_payload.switchDate).format(
                                                                      'YYYY',
                                                                  )}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Expiration Date</div>
                                                        <div className="congoDetailsUtilityright">
                                                            {item.contract_payload &&
                                                            item.contract_payload.expirationDate
                                                                ? `${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('MMM')} ${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('DD')}, ${moment(
                                                                      item.contract_payload.expirationDate,
                                                                  ).format('YYYY')}`
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Move Easy Number</div>
                                                        <div className="congoDetailsUtilityright">
                                                            #
                                                            {item.contract_payload && item.contract_payload.contractNum}
                                                        </div>
                                                    </div>
                                                    <div className="borderLineQuote"></div>
                                                    <div className="congoDetailsUtility">
                                                        <div className="congoDetailsUtilityHead">Cancellation Fee</div>
                                                        <div className="congoDetailsUtilityright">$0</div>
                                                    </div>
                                                    <div className="endDisclaimerUtility">
                                                        *The utility quote(s) displayed above are provided by licensed
                                                        utility carriers and are preliminary estimates only and are
                                                        subject to change based on additional information we may receive
                                                        later in the quoting process, the coverages, deductibles and
                                                        payment options you choose along with other discounts, features
                                                        or savings that may apply.
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {/* <div className="whatsNextCongo">What’s Next?</div>
                                    <div className="electrincityGasCardCongo">
                                        <ElectricityGasMain
                                            GasElect={'Gas Savings'}
                                            progress={this.state.summaryData.length >= 2 ? 100 : 50}
                                            icon={FireTopIcon}
                                            handleNext={() => {
                                                this.props.history.push({
                                                    pathname: '/dashboard/utilities',
                                                });
                                            }}
                                        />
                                    </div> */}
                                    <div className="backToHomeUtility">
                                        <Button
                                            // backgroundColor={
                                            //     this.state.slug && this.state.slug.primary_color
                                            //         ? this.state.slug.primary_color
                                            //         : '#273E59'
                                            // }
                                            // color={'#fff'}
                                            onClick={() => {
                                                if (
                                                    localStorage.getItem('isHomeOwner') &&
                                                    localStorage.getItem('isHomeOwner') == 'true'
                                                ) {
                                                    this.props.history.push('/dashboardhomeOwner');
                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }
                                                } else {
                                                    this.props.history.push('/dashboard');
                                                    let slug: any = localStorage.getItem('slug');
                                                    if (slug) {
                                                        slug = JSON.parse(slug);
                                                    }
                                                }
                                            }}
                                        >
                                            Back to Dashboard
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="finalConfirmationCardContent">
                                    <div className="confirmationTopCardUtility" style={{ backgroundColor: this.state.slug && this.state.slug.primary_color ? this.state.slug.primary_color : '#ce202f' }}>
                                        <div className="confirmationHeadingUtility" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Confirmation</div>
                                        <div className="confirmationbottomUtility">
                                        Your contract has been submitted.
                                        </div>
                                    </div>
                                    <div className="finalPageCardUtility">
                                        <div className="selectedPlanFinal">
                                            <div className="selectedPlanHead" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Selected plan details</div>
                                        </div>
                                        {this.state.summaryData &&
                                            this.state.summaryData.map((item: any) => {
                                                let displayRate: any =
                                                    item.contract_payload && item.contract_payload.displayRate;
                                                if (displayRate.includes('$')) {
                                                    displayRate = displayRate.replace('$', '');
                                                    displayRate = parseFloat(displayRate) * 100;
                                                    displayRate = displayRate.toFixed(2);
                                                    displayRate = `${displayRate}¢`;
                                                }
                                                return item.contract_payload ? (
                                                    <FinalPlan
                                                        logo={
                                                            item.contract_payload &&
                                                            item.contract_payload.supplier &&
                                                            item.contract_payload.supplier.logo
                                                                ? `${powerkiosImageUrl}${item.contract_payload.supplier.logo}?access_token=${this.state.accessToken}`
                                                                : ''
                                                        }
                                                        supplier={item.supplier_name}
                                                        kwh={displayRate}
                                                        contract={item.contract_payload.term}
                                                    />
                                                ) : null;
                                            })}
                                    </div>
                                    <div className="confirmationBottomUtility" onClick={this.handleFinalCongo}>
                                        <div className="finalStepAsAProcess" style={this.state.slug?.custom_primary_font ? { fontFamily: this.state.slug.custom_primary_font }: {}}>Complete Setup</div>
                                        <div className="sentCallOnNumber">
                                            You will receive a notification via email or text. To complete setup, follow the prompts.
                                        </div>
                                    </div>
                                    <div className="backToHomeUtility">
                                        <Button
                                            backgroundColor={this.state.slug && this.state.slug.primary_color
                                                ? `${this.state.slug.primary_color}`
                                                : '#ce202f'}
                                            border={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? `1px solid ${this.state.slug.primary_color} `
                                                    : '1px solid #273E59'
                                            }
                                            color={this.state.slug.primary_font_color ? this.state.slug.primary_font_color : '#FFF'}
                                            // color={
                                            //     isDouglasElliman
                                            //         ? '#100B28'
                                            //         : this.state.slug && this.state.slug.primary_color
                                            //         ? this.state.slug.primary_color
                                            //         : '#273E59'
                                            // }
                                            onClick={this.handleBack}
                                        >
                                            Back to Home
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(confirmationPageUtilityPODS);
