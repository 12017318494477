import React from 'react';
import './TrustedPros.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderImage from '../../Assets/images/TrustedPros/TrustedPros.svg';
import TextField from '@material-ui/core/TextField';
import Button from '../../components/atoms/Button';
// import lastImageTrusted from '../../Assets/images/TrustedPros/AppShot.svg';
// import googlePlayImage from '../../Assets/images/TrustedPros/googlePlayImage.svg';
// import appStoreImage from '../../Assets/images/TrustedPros/appStoreImage.svg';
import TrustedProsIconCard from '../../components/atoms/trustedProsIconCard';
import TrustedProsCategoryTabs from './TrustedProsCategoryTabs';
// import TrustedProsHomeProjectTabs from './TrustedProsHomeProjectTabs';
import PopularCardsIcon from '../../Assets/images/TrustedPros/popularCardImage.png';
import { geocodeByAddress } from 'react-places-autocomplete';
import * as CommonAction from '../../store/common/actions';
import AutoComplete from '../../components/atoms/autoComplete';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import ScheduledServices from '../../components/TrustedProsComponents/ScheduleServiceMainCard';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ViewAllService from './ViewAllService';
import * as DashboardAction from '../../store/dashboard/actions';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { anaySeg, formStartedSegment, buttonClicked } from '../../utils/utlis';

type InitialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    trustedProsAction: any;
    trustedProsState: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    location: any;
};

type InitialState = {
    whatYouNeedHelp: string;
    zipCode: string;
    moveId: string;
    homeProjectsTabs: any;
    popularService: any;
    homeProjectsCardsData: any;
    address: string;
    street: string;
    isDataArrived: boolean;
    isCategoriesArrived: boolean;
    isViewAllService: boolean;
    categories: any;
    categoryData: any;
    isScheduledArrived: boolean;
    categoriesScheduled: any;
    categoriesScheduledItem: any;
    isMoveDataArrived: boolean;
    vendorsOptions: any;
    selectedVendorService: any;
    count: any;
    android_app_url: any;
    ios_app_url: any;
    hashCode: any;
    isHashcodeDataArrived: boolean;
    isDouglasElliman: boolean;
    loader: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    category_name: any;
    brokerage_key: any;
    agent_key: any;
};

class TrustedPros extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        whatYouNeedHelp: '',
        zipCode: '',
        address: '',
        street: '',
        moveId: '',
        isScheduledArrived: true,
        categoriesScheduled: 0,
        isDataArrived: true,
        isCategoriesArrived: true,
        isViewAllService: false,
        isMoveDataArrived: true,
        homeProjectsTabs: [
            {
                id: 1,
                name: 'Painting Services',
                tabId: 0,
            },
            {
                id: 2,
                name: 'Cleaning Services',
                tabId: 1,
            },
            {
                id: 3,
                name: 'Remodeling Services',
                tabId: 2,
            },
            {
                id: 4,
                name: 'HVAC Services',
                tabId: 3,
            },
        ],
        categories: [],
        count: 0,
        categoryData: [],
        popularService: [],
        homeProjectsCardsData: [
            {
                name: 'Interior/Exterior Painting',
                icon: PopularCardsIcon,
            },
            {
                name: 'Paint Touch Ups',
                icon: PopularCardsIcon,
            },
            {
                name: 'Wallpaper Installation/ Removal',
                icon: PopularCardsIcon,
            },
            {
                name: 'Ceiling Texture Repair',
                icon: PopularCardsIcon,
            },
            {
                name: 'Cabinet Refinishing',
                icon: PopularCardsIcon,
            },
            {
                name: 'Interior/Exterior Painting',
                icon: PopularCardsIcon,
            },
            {
                name: 'Interior/Exterior Painting',
                icon: PopularCardsIcon,
            },
        ],
        categoriesScheduledItem: [],
        vendorsOptions: [],
        selectedVendorService: [],
        android_app_url: '',
        ios_app_url: '',
        hashCode: '',
        isHashcodeDataArrived: true,
        isDouglasElliman: false,
        loader: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        category_name: '',
        brokerage_key: '',
        agent_key: '',
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props &&
                this.props.CheckListDashboardState &&
                this.props.CheckListDashboardState.checklist &&
                this.props.CheckListDashboardState.checklist.length
            )
        ) {
            this.props.dashboardAction.checklist();
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.trustedProsAction.categories();
        this.props.trustedProsAction.vendors();
        this.props.trustedProsAction.getMoveVendors();

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            android_app_url: slug && slug.android_app_url ? slug.android_app_url : '',
            ios_app_url: slug && slug.ios_app_url ? slug.ios_app_url : '',
        });

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Trusted Pros',
                    category_name: 'Trusted Pros',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }

        let TrustedProsAddress: any = localStorage.getItem('TrustedProsAddress');
        if (TrustedProsAddress) {
            this.setState({
                address: TrustedProsAddress,
            });
        }

        let CityStateZip: any = localStorage.getItem('CityStateZip');
        if (CityStateZip) {
            this.props.trustedProsAction.vendors({
                address: CityStateZip,
            });
        } else {
            this.props.trustedProsAction.vendors({
                address: '',
            });
        }

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashcodeDataArrived
        ) {
            currentState.isHashcodeDataArrived = false;

            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.dashboardAction.checklist();
                nextProps.commonAction.moveGet();
                nextProps.trustedProsAction.categories();
                nextProps.trustedProsAction.vendors();
                nextProps.trustedProsAction.getMoveVendors();
                const isHomeOwner = localStorage.getItem('isHomeOwner');
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);

                nextProps.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0]
                        ? window.location.hostname.split('.')[0]
                        : slug && slug.slug,
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
                let TrustedProsAddress: any = localStorage.getItem('TrustedProsAddress');
                if (TrustedProsAddress) {
                    currentState.address = TrustedProsAddress;
                }

                let CityStateZip: any = localStorage.getItem('CityStateZip');
                if (CityStateZip) {
                    nextProps.trustedProsAction.vendors({
                        address: CityStateZip,
                    });
                } else {
                    nextProps.trustedProsAction.vendors({
                        address: '',
                    });
                }
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }
        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.vendors &&
            currentState.isDataArrived
        ) {
            // currentState.isDataArrived = false;
            currentState.categoryData = nextProps.trustedProsState.vendors;

            currentState.vendorsOptions = nextProps.trustedProsState.vendors;
            // } )

            if (currentState.categoryData) {
                currentState.loader = true;
            }
            let data: any = [];
            if (currentState.isViewAllService) {
                nextProps.trustedProsState.vendors.map((item: any) => {
                    data.push(item);
                });
            } else {
                nextProps.trustedProsState.vendors.map((item: any, index: number) => {
                    if (index < 12 && index >= 0) {
                        data.push(item);
                    }
                });
            }
            currentState.popularService = data;
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.categories &&
            currentState.isCategoriesArrived
        ) {
            // currentState.isCategoriesArrived = false;
            currentState.categories = [];
            nextProps.trustedProsState.categories.map((category: any, index: number) => {
                let ctg: any = {
                    id: category.id,
                    name: category.name,
                    tabId: index,
                };
                currentState.categories.push(ctg);
            });
        }

        if (
            nextProps.trustedProsState &&
            nextProps.trustedProsState.getMoveVendor &&
            nextProps.trustedProsState.getMoveVendor.length &&
            currentState.isScheduledArrived
        ) {
            currentState.isScheduledArrived = false;
            nextProps.trustedProsAction.getMoveVendors();
            let array: any = [];
            let array2: any = [];
            nextProps.trustedProsState.getMoveVendor.map((item: any) => {
                if (item.vendor) {
                    array.push(item.category);
                    array2.push(item);
                }
            });

            let newArray = array.filter((c: any, index: any) => {
                return array.indexOf(c) === index;
            });
            currentState.categoriesScheduled = newArray;
            currentState.categoriesScheduledItem = array2;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            // currentState.fullName = `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            // currentState.city = nextProps.commonState.move[0].destination.city_name;
            if (!localStorage.getItem('TrustedProsAddress')) {
                currentState.street = nextProps.commonState.move[0].destination_street;
                currentState.zipCode = nextProps.commonState.move[0].destination.zip_code;
                currentState.moveId = nextProps.commonState.move[0].id;
                currentState.address = nextProps.commonState.move[0].destination_full_address;
            }

            // currentState.state = { name: nextProps.commonState.move[0].destination.state_code, label: nextProps.commonState.move[0].destination.state_code };
        }

        return currentState;
    }

    // componentDidUpdate(prevProps: any) {
    //     const prev = prevProps && prevProps.commonState;
    //     const cur = this.props && this.props.commonState;
    //     if (prev.move !== cur.move && cur.move && cur.move[0]) {
    //         console.log("fakse")
    //         const dest: any = cur.move[0] && cur.move[0].destination;
    //         this.setState({

    //             zipCode: dest && dest.zip_code,
    //             street: cur.move[0] && cur.move[0].dest_street,
    //             address: cur.move[0] && cur.move[0].destination_full_address,
    //             moveId: cur.move[0] && cur.move[0].id,
    //         });
    //     }
    //     if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
    //         this.props.commonAction.moveGet();
    //     }
    // }

    goBack = () => {
        this.setState({
            isViewAllService: false,
        });
    };

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address).then(results => {
            // this.setState({ address: results[0].formatted_address });
            let city: any = '',
                state: any = '',
                zip: any = '',
                country: any = '',
                route: any = '',
                streetNumber: any = '';

            results &&
                results[0] &&
                results[0].address_components &&
                results[0].address_components.map(el => {
                    state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                    zip = el.types.includes('postal_code') ? el.short_name : zip;
                    city = el.types.includes('locality')
                        ? el.long_name
                        : el.types.includes('sublocality')
                        ? el.long_name
                        : city;
                    streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                    route = el.types.includes('route') ? el.short_name : route;
                    country = el.types.includes('country') ? el.short_name : country;
                });
            add = results[0].formatted_address;
            if (country === 'USA' || country === 'US') {
                this.setState({
                    address: add,
                    street: `${streetNumber} ${route}`,
                    // city: city,
                    zipCode: zip,
                    // state: { name: state, label: state },
                });
            }
            // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
            // "414 East Erie Street, Chicago, IL 60611"
            if (
                country === 'USA' ||
                country === 'US'
                // state &&
                // city &&
                // zip &&
                // streetNumber &&
                // route &&
                // this.state.moveId
            ) {
                // this.props.commonAction.moveAddressUpdate({
                //     destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                //     move_id: this.state.moveId,
                // });

                localStorage.setItem('TrustedProsAddress', `${streetNumber} ${route}, ${city}, ${state}, ${zip}`);

                localStorage.setItem('CityStateZip', ` ${city}, ${state}, ${zip}`);

                this.props.trustedProsAction.vendors({
                    address: `${city},${state},${zip}`,
                });
            }
        });
    };

    getStarted = () => {
        if (this.state.selectedVendorService.length && this.state.selectedVendorService[0]) {
            this.props.trustedProsAction.impression({
                category: this.state.selectedVendorService[0].id,
            });
            let slug: any = localStorage.getItem('slug');
            slug = JSON.parse(slug);
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            if (slug) {
                // let brokerage: any;

                // brokerage = slug.display_name;
                // let brokerage_id: any = slug.brokerage_link;
                // let agent_id: any = slug.id;

                // let agent_name: any = slug.contact_name;
                // let move_id: any = localStorage.getItem('moveKey');
                buttonClicked(
                    'Button Clicked',
                    'Trusted Pros',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    'Trusted Pros',
                    'Get Started',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    true,
                    1,
                    '',
                    move_id,
                );

                anaySeg(
                    'Trusted Pros Searched',
                    'Our Vendors',
                    // brokerage,
                    // brokerage_id,
                    // agent_id,
                    // agent_name,
                    this.state.selectedVendorService[0] && this.state.selectedVendorService[0].name,
                    // move_id,
                );

                formStartedSegment(
                    'Form Started',
                    'Trusted Pros',
                    'Trusted Pros',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    '',
                    'Next',
                    true,
                    false,
                    1,
                    move_id,
                );
            }
            this.props.trustedProsAction.categoriesQuestions(this.state.selectedVendorService[0].name);
            this.props.history.push({
                pathname: '/dashboard/ourvendors/steps',
                state: {
                    street: this.state.street,
                    zipCode: this.state.zipCode,
                    service_name: this.state.selectedVendorService[0].name,
                    id: this.state.selectedVendorService[0].id,
                    serviceDetails: this.state.selectedVendorService[0],
                },
            });
        }
    };

    handleChangeWhatYouNeedHelp = (e: any) => {
        this.setState({ whatYouNeedHelp: e.target.value });
    };

    handleChangeAddress = (e: any) => {
        this.setState({ address: e });
    };

    scrollHandler = () => {
        const myElement = document.getElementById('container') as HTMLInputElement;
        myElement.scrollLeft += 200;
    };

    scrollHandler2 = () => {
        const myElement = document.getElementById('container') as HTMLInputElement;
        myElement.scrollLeft -= 80;
    };

    scrollLeftProjectCards = () => {
        const myElement = document.getElementById('projectContainer') as HTMLInputElement;
        myElement.scrollLeft -= 80;
    };

    scrollRightProjectCards = () => {
        const myElement = document.getElementById('projectContainer') as HTMLInputElement;
        myElement.scrollLeft += 80;
    };

    onClickPopularServices = (id: number, name: any, item: any) => {
        this.props.trustedProsAction.impression({
            category: id,
        });
        this.props.trustedProsAction.categoriesQuestions(name);
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            `Category Clicked`,
            {
                page: 'Trusted Pros',
                brokerage_name: this.state.brokerage_name,
                brokerage_key: this.state.brokerage_key,
                agent_key: this.state.agent_key,
                agent_name: this.state.agent_name,
                category_name: item && item.name,
            },
            { user_id: this.state.moveId },
        );
        this.props.history.push({
            pathname: '/dashboard/ourvendors/steps',
            state: {
                street: this.state.street,
                zipCode: this.state.zipCode,
                service_name: name,
                id: id,
                serviceDetails: item,
            },
        });
    };

    viewAllSevices = () => {
        this.setState({ isViewAllService: true });
    };

    onSelectVendors = async (e: any) => {
        await this.setState({ whatYouNeedHelp: e.target.value });

        let data: any = [];
        data.push(this.state.vendorsOptions.find((item: any) => item.name.match(this.state.whatYouNeedHelp)));

        this.setState({ selectedVendorService: data });
    };

    render() {
        const { isDouglasElliman } = this.state;
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
                : [];

        return (
            <div className={isDouglasElliman ? 'TrustedCssDouglas' : ''}>
                {isDouglasElliman && (
                    <div>
                        <TemplateUpper
                            pageImage="trustedPros"
                            headerText={`for Your Project`}
                            subheaderText={"Tell us what you need and we'll help you get started."}
                            highlightedText={'The Right Pro'}
                            isHighlightedFirst={true}
                            htmlContent={
                                <div className="trustedProsInputDivSectionAA removeMargintrustedProsInputDivSectionAA">
                                    <div className="helpInput">
                                        <Autocomplete
                                            id="free-solo-demo"
                                            freeSolo
                                            onSelect={(e: any) => this.onSelectVendors(e)}
                                            options={this.state.vendorsOptions}
                                            getOptionLabel={(option: any) => option.name}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    className="trustedProsInput"
                                                    label="What did you need help with"
                                                    id="outlined-start-adornment"
                                                    onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                                    variant="outlined"
                                                    value={this.state.whatYouNeedHelp}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Plumbing, Electrician, Security"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="zipInput">
                                        <AutoComplete
                                            className="trustedProsZipCodeInput"
                                            value={this.state.address}
                                            onChange={e => this.handleChangeAddress(e)}
                                            onSelect={this.handleSelect}
                                            placeholder="Current address"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputTitle="To"
                                        />
                                    </div>
                                    <div className="trustedProsButtonDiv">
                                        <Button
                                            width="100%"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273E59'
                                            }
                                            className={
                                                isDouglasElliman
                                                    ? 'trustedProsGetStartedButton removeBorderRadius'
                                                    : 'trustedProsGetStartedButton'
                                            }
                                            onClick={this.getStarted}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                )}
                {this.state.isViewAllService == false ? (
                    <div
                        className={
                            isDouglasElliman
                                ? 'trustedPros-main-page TrustedProsMainRemoveDouglas'
                                : 'trustedPros-main-page'
                        }
                    >
                        {!isDouglasElliman && (
                            <div className="trustedPros-headersDiv">
                                <div className="trustedProsHeaderContent">
                                    <div className="main-head-2">
                                        <h2 className="heading">Trusted Pros</h2>
                                        <div className="path">
                                            <p
                                                className="Para"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    if (
                                                        localStorage.getItem('isHomeOwner') &&
                                                        localStorage.getItem('isHomeOwner') == 'true'
                                                    ) {
                                                        this.props.history.push('/dashboardhomeOwner');
                                                    } else {
                                                        this.props.history.push('/dashboard');
                                                    }
                                                }}
                                            >
                                                {' '}
                                                Dashboard {'>'}{' '}
                                            </p>{' '}
                                            <p> Trusted Pros</p>
                                        </div>
                                    </div>
                                    <div className="trustedInputTextarea">
                                        <div className="trustedHeaderText">the right pro for your project</div>
                                        <div className="trustedHeaderSubText">
                                            Your home has specific needs, and we're here to help get you started
                                        </div>

                                        <div className="trustedProsInputDivSectionAA">
                                            <div className="helpInput">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    onSelect={(e: any) => this.onSelectVendors(e)}
                                                    options={this.state.vendorsOptions}
                                                    getOptionLabel={(option: any) => option.name}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            className="trustedProsInput"
                                                            label="What did you need help with"
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                                            variant="outlined"
                                                            value={this.state.whatYouNeedHelp}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Plumbing, Electrician, Security"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="zipInput">
                                                <AutoComplete
                                                    className="trustedProsZipCodeInput"
                                                    value={this.state.address}
                                                    onChange={e => this.handleChangeAddress(e)}
                                                    onSelect={this.handleSelect}
                                                    placeholder="Current address"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputTitle="To"
                                                />
                                            </div>
                                            <div className="trustedProsButtonDiv">
                                                <Button
                                                    className={
                                                        isDouglasElliman
                                                            ? 'trustedProsGetStartedButton removeBorderRadius'
                                                            : 'trustedProsGetStartedButton'
                                                    }
                                                    backgroundColor={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : '#273E59'
                                                    }
                                                    onClick={this.getStarted}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="trustedProsHeaderImageDiv">
                                    <img src={HeaderImage} />
                                </div>
                                <div className="trustedInputTextareaMobile">
                                    <div className="trustedHeaderText">the right pro for your project</div>
                                    <div className="trustedHeaderSubText">
                                        Tell us what you need and we’ll help you get it started
                                    </div>

                                    <div className="trustedProsInputDivSectionAA">
                                        <div className="helpInput">
                                            <Autocomplete
                                                id="free-solo-demo"
                                                freeSolo
                                                onSelect={(e: any) => this.onSelectVendors(e)}
                                                options={this.state.vendorsOptions}
                                                getOptionLabel={(option: any) => option.name}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        className="trustedProsInput"
                                                        label="What did you need help with"
                                                        id="outlined-start-adornment"
                                                        onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                                        variant="outlined"
                                                        value={this.state.whatYouNeedHelp}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        placeholder="Plumbing, Electrician, Security"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="zipInput">
                                            <AutoComplete
                                                className="trustedProsZipCodeInput"
                                                value={this.state.address}
                                                onChange={e => this.handleChangeAddress(e)}
                                                onSelect={this.handleSelect}
                                                placeholder="Current address"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputTitle="To"
                                            />
                                        </div>
                                        <div className="trustedProsButtonDiv">
                                            <Button
                                                width="100%"
                                                className={
                                                    isDouglasElliman
                                                        ? 'trustedProsGetStartedButton removeBorderRadius'
                                                        : 'trustedProsGetStartedButton'
                                                }
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                onClick={this.getStarted}
                                            >
                                                Get Started
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.props.trustedProsState &&
                            this.props.trustedProsState.getMoveVendor &&
                            this.props.trustedProsState.getMoveVendor.length > 0 && (
                                <div className="TPScheduledService">
                                    <div className="browseTitleSS">
                                        Scheduled Services ({this.state.categoriesScheduled.length})
                                    </div>
                                    <div className="TPSSCards">
                                        {this.state.categoriesScheduled.length > 0 &&
                                            this.state.categoriesScheduled.map((item: any) => {
                                                return (
                                                    <ScheduledServices
                                                        serviceName={item}
                                                        vendorlist={this.state.categoriesScheduledItem.filter(
                                                            (item2: any) => {
                                                                return item2.category == item && item2.vendor;
                                                            },
                                                        )}
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        <div
                            className={
                                isDouglasElliman
                                    ? 'browseByCategoryDiv browseByCategoryDivDouglas'
                                    : 'browseByCategoryDiv'
                            }
                        >
                            <div className="browseTitle">Browse by Category</div>
                            {this.state.loader && this.state.categoryData && this.state.categories ? (
                                <div>
                                    <TrustedProsCategoryTabs
                                        tabsHeading={this.state.categories}
                                        tabsContentData={this.state.categoryData}
                                        scrollHandler={this.scrollHandler}
                                        scrollHandler2={this.scrollHandler2}
                                        onClickPopularServices={this.onClickPopularServices}
                                        loader={this.state.loader}
                                    />
                                </div>
                            ) : null}
                        </div>

                        <div className="MostPopularServiceDiv">
                            <div className="MostPopularServiceTitleDiv">
                                <div className="MostPopularServiceTitle">Most Popular Services</div>
                                <div
                                    className={
                                        isDouglasElliman
                                            ? 'MostPopularServiceViewAllService MostPopularServiceViewAllServiceDoug'
                                            : 'MostPopularServiceViewAllService'
                                    }
                                    onClick={this.viewAllSevices}
                                >
                                    View All Service
                                </div>
                            </div>

                            <div className="PopularCardsDiv">
                                {this.state.popularService.length ? (
                                    this.state.popularService.map((item: any) => {
                                        return (
                                            <TrustedProsIconCard
                                                className="popularCard"
                                                icon={
                                                    item && item.image
                                                        ? item && item.image
                                                        : item && item.react_image
                                                        ? item && item.react_image
                                                        : ''
                                                }
                                                title={item && item.name ? item && item.name : ''}
                                                onClick={() => {
                                                    this.onClickPopularServices(item.id, item.name, item);
                                                }}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="icon-card-skeleton">
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                        <div className="popularCardSkeleton">
                                            {' '}
                                            <TrustedProsIconCard />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mortgage-last-div">
                            <div className="mortgage-pleasenote">Please Note:</div>
                            <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                                {displayData && displayData[0] && displayData[0].disclaimer ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: displayData && displayData[0] && displayData[0].disclaimer,
                                        }}
                                    />
                                ) : (
                                    'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                                )}
                            </div>
                        </div>
                        {/* 
            <div className="popularHomeServiceDiv">
                <div className="browseTitle">Popular Home Projects</div>
                {this.state.homeProjectsCardsData && this.state.homeProjectsTabs ? (
                    <div>
                        <TrustedProsHomeProjectTabs
                            tabsHeading={this.state.homeProjectsTabs}
                            tabsContentData={this.state.homeProjectsCardsData}
                            scrollHandler={this.scrollHandler}
                            scrollHandler2={this.scrollHandler2}
                            onClickPopularServices={() => console.log()}
                        />
                    </div>
                ) : null}
            </div> */}

                        {/* <div className="trustedPros-last-div">
         <div className="trustedPros-last-div-left">
             <div className="trustedPros-left-content-div">
                 <div className="left-heading-div">Get All Your Project Notifications On Our App</div>
                 <div className="left-sub-heading-div">
                     Maecenas sed ligula ut dui pharetra aliquet. Vestibulum eget orci libero. Sed est sem,
                     mollis nec lectus nec, varius suscipit ligula.
                 </div>
                 <div className="left-buttons-div">
                     <img src={googlePlayImage} className="image-1-left" onClick={
                         ()=>{
                            window.open(
                                this.state.android_app_url,
                                '_blank',
                            )
                         }
                     } />
                     <img src={appStoreImage} className="image-2-left" onClick={
                         ()=>{
                            window.open(
                                this.state.ios_app_url,
                                '_blank',
                            )
                         }
                     }/>
                 </div>
             </div>
         </div>
         <div className="trustedPros-last-div-right">
             <img src={lastImageTrusted} className="lastImage-trustedPros" />
         </div>
         <div className="left-buttons-div-mobile">
             <img src={googlePlayImage} className="image-1-left" />
             <img src={appStoreImage} className="image-2-left" />
         </div>
     </div> */}
                    </div>
                ) : (
                    <div>
                        <ViewAllService
                            popularService={this.state.popularService}
                            onClickPopular={this.onClickPopularServices}
                            goBackHandler={this.goBack}
                            vendorsOptions={this.state.vendorsOptions}
                        />
                        <div className="mortgage-last-div">
                            <div className="mortgage-pleasenote">Please Note:</div>
                            <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                                {displayData && displayData[0] && displayData[0].disclaimer ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: displayData && displayData[0] && displayData[0].disclaimer,
                                        }}
                                    />
                                ) : (
                                    'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    trustedProsState: state.trustedPros,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedPros);
