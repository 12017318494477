import React, { Component } from 'react';
import './AutoShipping.scss';
import Button from '../../../components/atoms/Button';
import SelectInput from '../../../components/atoms/SelectInput';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { InputLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import EditIcon from '@material-ui/icons/Edit';
import AutoComplete from '../../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../Footer';
import * as Action from '../../../store/common/actions';
import * as DashboardAction from '../../../store/dashboard/actions';
import * as MoveAction from '../../../store/move/actions';
import * as AutoShippingAction from '../../../store/autoshipping/actions';
import history from '../../../routes/History';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../../utils/utlis';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    moversAction?: any;
    moversState?: any;
    location?: any;
    autoShippingAction?: any;
    autoShippingState?: any;
};

type initialState = {
    tabName: string;
    tabNameOptions: any;
    activeStep: number;
    totalSteps: number;
    isMovingClicked: boolean;
    isAutoShippingClicked: boolean;
    isReserveStorageClicked: boolean;
    completedMovingPercentage: any;
    isDataArrived2: boolean;
    isDataArrived3: boolean;
    checkedItems: any;
    showPage: boolean;
    serviceId: any;
    pickupZip: string;
    pickupAddress: string;
    destinationZip: string;
    destinationAddress: string;
    pickupError: string;
    destinationError: string;
    transportType: string;
    transportTypeError: string;
    vehicleMakeOptions: any;
    vehicleModelOptions: any;
    vehicleDetails: any;
    vehicleMakeError: string;
    vehicleModelError: string;
    vehicleOperableError: string;
    vehicleYearError: string;
    date: any;
    dateError: string;
    anchorEl: any;
    makesDataArrived: boolean;
    id: any;
    yearOptions: any;
    isMoveDataArrived: boolean;
    hashCode: any;
    isDataArrived: boolean;
    moveDataArrived: boolean;
    isDataArrivedHeader: boolean;
    movingService: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    reserveService: boolean;
    brokerage_name: string;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    moveId: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

class AutoShipping extends Component<initialProps, initialState> {
    state: initialState = {
        tabName: 'landing',
        tabNameOptions: ['landing', 'homeDetails', 'lastPage'],
        activeStep: 0,
        totalSteps: 3,
        isMovingClicked: false,
        isAutoShippingClicked: true,
        isReserveStorageClicked: false,
        completedMovingPercentage: '',
        isDataArrived2: true,
        isDataArrived3: true,
        checkedItems: [],
        showPage: false,
        serviceId: '',
        pickupZip: '',
        pickupAddress: '',
        destinationZip: '',
        destinationAddress: '',
        pickupError: '',
        destinationError: '',
        transportType: '',
        transportTypeError: '',
        vehicleMakeOptions: [{ value: 'Audi', label: 'Audi' }],
        vehicleModelOptions: [{ value: 'Audi', label: 'Audi' }],
        makesDataArrived: true,
        hashCode: '',
        isDataArrived: true,
        moveDataArrived: true,
        vehicleDetails: [
            {
                vehicleId: 1,
                make: '',
                model: '',
                operable: '',
                year: '',
                vehicle_condition: '',
            },
        ],
        vehicleMakeError: '',
        vehicleModelError: '',
        vehicleOperableError: '',
        vehicleYearError: '',
        id: 0,
        yearOptions: [],

        date: null,
        dateError: '',
        anchorEl: null,
        isMoveDataArrived: true,
        isDataArrivedHeader: true,
        movingService: false,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        reserveService: false,
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        moveId: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }

        this.props.autoShippingAction.getMakes();
        let years: any = [];

        for (let i: any = new Date().getFullYear() - 2; i >= 1962; i--) {
            years.push({
                value: i,
                label: i,
            });
        }

        this.setState({
            yearOptions: years,
        });

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Auto Shipping',
                    category_name: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.autoShippingState;
        const cur = this.props && this.props.autoShippingState;
        if (prev.getModels !== cur.getModels && cur.getModels && cur.getModels.length) {
            const models: any = cur.getModels;
            let options: any = [];

            models.map((item: any) => {
                options.push({
                    value: item,
                    label: item,
                });
            });
            this.setState({
                vehicleModelOptions: options,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.commonAction.moveGet();

                nextProps.autoShippingAction.getMakes();
            } else {
                localStorage.clear();
                history.push({
                    pathname: `/signUp`,
                    search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                });
            }
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/autoease')) {
                    currentState.serviceId = item.id;
                }
            });
        }

        if (
            nextProps &&
            nextProps.autoShippingState &&
            nextProps.autoShippingState.getMakes &&
            nextProps.autoShippingState.getMakes.length &&
            currentState.makesDataArrived
        ) {
            currentState.makesDataArrived = false;
            let options: any = [];

            nextProps.autoShippingState.getMakes.map((item: any) => {
                options.push({
                    value: item,
                    label: item,
                });
            });

            currentState.vehicleMakeOptions = options;
        }
        if (nextProps && nextProps.commonState && nextProps.commonState.move && currentState.isMoveDataArrived) {
            nextProps.commonState.move.map((item: any) => {
                currentState.isMoveDataArrived = false;
                // currentState.moveId = item && item.id;
                currentState.destinationZip =
                    item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.destinationAddress =
                    item.destination && item.destination_full_address ? item.destination_full_address : '';
                currentState.pickupZip = item.source && item.source.zip_code ? item.source.zip_code : '';
                currentState.pickupAddress = item.source_full_address ? item.source_full_address : '';
            });
        }

        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.header &&
            nextProps.commonState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let moving: any = [];
            let reserve: any = [];
            moving = nextProps.commonState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'moving service',
            );

            reserve = nextProps.commonState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'reserve storage facility',
            );

            if (reserve.length) {
                currentState.reserveService = true;
            }

            if (moving.length) {
                currentState.movingService = true;
            }
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.move[0] && currentState.moveDataArrived) {
            currentState.moveDataArrived = false;
            let moveData = nextProps.commonState.move[0];

            currentState.moveId = moveData.id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }
        return currentState;
    }

    handleSelectPickup = (address: any) => {
        let add: string;
        geocodeByAddress(address).then(results => {
            // this.setState({ address: results[0].formatted_address });
            let city: any = '',
                state: any = '',
                zip: any = '',
                country: any = '',
                route: any = '',
                streetNumber: any = '';

            results &&
                results[0] &&
                results[0].address_components &&
                results[0].address_components.map(el => {
                    state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                    zip = el.types.includes('postal_code') ? el.short_name : zip;
                    city = el.types.includes('locality')
                        ? el.long_name
                        : el.types.includes('sublocality')
                        ? el.long_name
                        : city;
                    streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                    route = el.types.includes('route') ? el.short_name : route;
                    country = el.types.includes('country') ? el.short_name : country;
                });
            add = results[0].formatted_address;
            if (country === 'USA' || country === 'US') {
                this.setState({
                    pickupAddress: add,
                    // street: `${streetNumber} ${route}`,
                    // city: city,
                    pickupZip: zip,
                    // state: { name: state, label: state },
                });
            }
        });
    };

    handleSelectDestination = (address: any) => {
        let add: string;
        geocodeByAddress(address).then(results => {
            // this.setState({ address: results[0].formatted_address });
            let city: any = '',
                state: any = '',
                zip: any = '',
                country: any = '',
                route: any = '',
                streetNumber: any = '';

            results &&
                results[0] &&
                results[0].address_components &&
                results[0].address_components.map(el => {
                    state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                    zip = el.types.includes('postal_code') ? el.short_name : zip;
                    city = el.types.includes('locality')
                        ? el.long_name
                        : el.types.includes('sublocality')
                        ? el.long_name
                        : city;
                    streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                    route = el.types.includes('route') ? el.short_name : route;
                    country = el.types.includes('country') ? el.short_name : country;
                });
            add = results[0].formatted_address;
            if (country === 'USA' || country === 'US') {
                this.setState({
                    destinationAddress: add,
                    // street: `${streetNumber} ${route}`,
                    // city: city,
                    destinationZip: zip,
                    // state: { name: state, label: state },
                });
            }
        });
    };

    tabChangeHandler = (value: string) => {
        this.setState({
            tabName: value,
        });
    };

    skipHandler = async (activeStep: number, totalSteps: number) => {
        await this.setState({ activeStep: activeStep, totalSteps: totalSteps });
        this.tabChangeHandler('lastPage');
    };

    validateStep1 = () => {
        let isValidStep1: boolean = true;

        if (this.state.pickupAddress === '') {
            isValidStep1 = false;
            this.setState({ pickupError: 'Please enter pickup address' });
        }

        if (this.state.destinationAddress === '') {
            isValidStep1 = false;
            this.setState({ destinationError: 'Please enter destination address' });
        }

        if (this.state.transportType === '') {
            isValidStep1 = false;
            this.setState({ transportTypeError: 'Please choose transport type' });
        }

        return isValidStep1;
    };

    validateStep2 = () => {
        let isValidStep2: boolean = true;
        let vehicleDetails = this.state.vehicleDetails;
        vehicleDetails.forEach((vehicleDetail: any) => {
            if (vehicleDetail.make === '') {
                isValidStep2 = false;
                this.setState({ vehicleMakeError: 'Please select vehicle make' });
            }

            if (vehicleDetail.model === '') {
                isValidStep2 = false;
                this.setState({ vehicleModelError: 'Please select vehicle model' });
            }

            if (vehicleDetail.year == '') {
                isValidStep2 = false;
                this.setState({ vehicleYearError: 'Please select vehicle year' });
            }

            if (vehicleDetail.operable === '') {
                isValidStep2 = false;
                this.setState({ vehicleOperableError: 'Please choose vehicle operable' });
            }
        });

        return isValidStep2;
    };

    validateStep3 = () => {
        let isValidStep3 = true;

        if (this.state.date == null || this.state.date == '') {
            isValidStep3 = false;
            this.setState({ dateError: 'Please enter pickup date' });
        }

        return isValidStep3;
    };

    handleNext = () => {
        if (this.state.activeStep === 0 && this.validateStep1()) {
            this.setState({ activeStep: 1 });
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Auto Shipping',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                'Moving Services',
                'Next',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                this.state.activeStep + 1,
                '',
                move_id,
            );

            formStartedSegment(
                'Form Started',
                'Auto Shipping',
                'Moving Services',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                '',
                'Next',
                true,
                this.state.prefilled,
                this.state.activeStep + 1,
                move_id,
            );
        } else if (this.state.activeStep === 1 && this.validateStep2()) {
            this.setState({ activeStep: 2 });
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Auto Shipping',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                'Moving Services',
                'Next',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                this.state.activeStep + 1,
                '',
                move_id,
            );
        }
        // this.setState({ activeStep: this.state.activeStep + 1 });

        if (this.state.activeStep === 2 && this.validateStep3()) {
            this.props.history.push({
                pathname: '/dashboard/autoease/order_placing',
                state: {
                    vehicleDetails: this.state.vehicleDetails,
                    pickupZip: this.state.pickupZip,
                    pickupAddress: this.state.pickupAddress,
                    destinationZip: this.state.destinationZip,
                    destinationAddress: this.state.destinationAddress,
                    pickupDate: this.state.date,
                    transportType: this.state.transportType,
                },
            });

            this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 50,
                },
                service_id: this.state.serviceId,
            });
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Auto Shipping',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                'Moving Services',
                'Schedule A Call',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                this.state.activeStep + 1,
                '',
                move_id,
            );

            formCompletedSegment(
                'Form Completed',
                'Auto Shipping',
                'Moving Services',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                '',
                'Schedule a call',
                true,
                this.state.prefilled,
                'Auto Shipping Form',
                3,
                move_id,
            );
        }
    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    getSteps() {
        return ['Enter destination', 'Vehicle details', 'Pick Up Date'];
    }

    handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ transportType: (event.target as HTMLInputElement).value });
    };

    onSelectHandler = (e: any, name: string, index: number) => {
        let fields = this.state.vehicleDetails;

        const tempObject = { ...fields[index] };
        if (name === 'operable') {
            tempObject[name] = e.target.value;
            if (e.target.value == 'Yes') {
                tempObject['vehicle_condition'] = 'True';
            } else {
                tempObject['vehicle_condition'] = 'False';
            }
        } else if (name === 'make') {
            tempObject[name] = e.value;
            this.props.autoShippingAction.getModels(e.value);
        } else if (name === 'model') {
            tempObject[name] = e.value;
        } else {
            tempObject[name] = e.value;
        }

        fields[index] = tempObject;

        this.setState({ vehicleDetails: fields });
    };

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleChangeDestinationAddress = (e: any) => {
        this.setState({ destinationAddress: e });
    };

    handleChangePickupAddress = (e: any) => {
        this.setState({ pickupAddress: e });
    };

    handleAddVehicleForm = () => {
        const fields = this.state.vehicleDetails;
        fields.push({
            vehicleId: fields.length + 1,
            make: '',
            model: '',
            operable: '',
        });
        this.setState({ vehicleDetails: fields });
    };

    deleteVehicleForm = (e: any, index: number) => {
        e.preventDefault();
        const vehicleDetails = this.state.vehicleDetails;
        const items = vehicleDetails.filter((_: any, i: any) => i !== index);
        const result = items.map((item: any, i: number) => {
            if (i < index) {
                return item;
            }
            if (i >= index) {
                item.vehicleId = i + 1;
                return item;
            }
            return null;
        });
        this.setState({ vehicleDetails: result });
    };

    vehicleDetailsForms = () => {
        let form =
            this.state.vehicleDetails &&
            this.state.vehicleDetails.map((vehicleDetail: any, i: number) => {
                return (
                    <div
                        key={i}
                        style={{
                            borderBottom: this.state.vehicleDetails.length - 1 === i ? 'none' : '1px solid #C6C6C6',
                        }}
                    >
                        {this.state.vehicleDetails.length > 1 && i > 0 ? (
                            <div className="deleteFormButton">
                                <ClearIcon onClick={(e: any) => this.deleteVehicleForm(e, i)} />
                            </div>
                        ) : null}
                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsNoDoug' : 'autoshippingInpts'
                            }
                        >
                            <div className="inputLabel">
                                <InputLabel className="inputLable">Vehicle Make</InputLabel>
                            </div>
                            <SelectInput
                                className="react-select alert-select"
                                onChange={(e: any) => this.onSelectHandler(e, 'make', i)}
                                options={this.state.vehicleMakeOptions}
                                placeholder={vehicleDetail.make ? vehicleDetail.make : 'Select vehicle make'}
                                value={vehicleDetail.make ? vehicleDetail.make : ''}
                                // label="Vehicle make"
                            />
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {vehicleDetail.make == '' && vehicleDetail.vehicleId === i + 1 ? (
                                    <small>{this.state.vehicleMakeError}</small>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsNoDoug' : 'autoshippingInpts'
                            }
                        >
                            <div className="inputLabel">
                                <InputLabel className="inputLable">Year</InputLabel>
                            </div>
                            <SelectInput
                                className="react-select alert-select"
                                onChange={(e: any) => this.onSelectHandler(e, 'year', i)}
                                options={this.state.yearOptions}
                                placeholder={vehicleDetail.year ? vehicleDetail.year : 'Select vehicle year'}
                                value={vehicleDetail.year ? vehicleDetail.year : ''}
                                // label="Vehicle year"
                            />
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {vehicleDetail.year == '' ? <small>{this.state.vehicleYearError}</small> : ''}
                            </div>
                        </div>

                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsNoDoug' : 'autoshippingInpts'
                            }
                        >
                            <div className="inputLabel">
                                <InputLabel className="inputLable">Vehicle model</InputLabel>
                            </div>
                            <SelectInput
                                className="react-select alert-select"
                                onChange={(e: any) => this.onSelectHandler(e, 'model', i)}
                                options={this.state.vehicleModelOptions}
                                placeholder={vehicleDetail.model ? vehicleDetail.model : 'Select vehicle model'}
                                value={vehicleDetail.model ? vehicleDetail.model : ''}
                                // label="Vehicle model"
                            />
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {vehicleDetail.model == '' ? <small>{this.state.vehicleModelError}</small> : ''}
                            </div>
                        </div>
                        <div className="autoshippingInpts">
                            <div className="inputLabel">
                                <InputLabel className="inputLable">
                                    Is it operable
                                    <ReactTooltip place="top" type="dark" effect="float" multiline={true} />{' '}
                                    <InfoIcon data-tip="An operable vehicle means<br />it can break, roll, steer and<br />start independently." />
                                </InputLabel>
                            </div>
                            <RadioGroup
                                aria-label="operable"
                                name="operable"
                                value={vehicleDetail.operable}
                                onChange={(e: any) => this.onSelectHandler(e, 'operable', i)}
                                className={this.state.isDouglasElliman ? 'checkBtn checkBtnD' : 'checkBtn'}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {vehicleDetail.operable == '' ? <small>{this.state.vehicleOperableError}</small> : ''}
                            </div>
                        </div>
                    </div>
                );
            });
        return form;
    };

    getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <div className="autoshippingStepsContent">
                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsD' : 'autoshippingInpts'
                            }
                        >
                            <div className="inputLabel">
                                <InputLabel className="inputLable">Pick up</InputLabel>
                            </div>
                            <AutoComplete
                                className="autoshippingInput1"
                                error={this.state.pickupAddress == '' ? Boolean(this.state.pickupError) : false}
                                value={this.state.pickupAddress}
                                onChange={e => this.handleChangePickupAddress(e)}
                                onSelect={this.handleSelectPickup}
                                placeholder="Pickup address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputTitle="Pickup Address"
                            />
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {this.state.pickupAddress == '' ? <small>{this.state.pickupError}</small> : ''}
                            </div>
                        </div>
                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsD' : 'autoshippingInpts'
                            }
                        >
                            <div className="inputLabel">
                                <InputLabel className="inputLable">Destination</InputLabel>
                            </div>
                            <AutoComplete
                                className="autoshippingInput1"
                                error={
                                    this.state.destinationAddress == '' ? Boolean(this.state.destinationError) : false
                                }
                                value={this.state.destinationAddress}
                                onChange={e => this.handleChangeDestinationAddress(e)}
                                onSelect={this.handleSelectDestination}
                                placeholder="Destination address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputTitle="Destination Address"
                            />
                            <div
                                className="errorMoving"
                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                            >
                                {this.state.destinationAddress == '' ? (
                                    <small>{this.state.destinationError}</small>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsD' : 'autoshippingInpts'
                            }
                        >
                            <div className="autoshippingButtonDiv">
                                <div className="autoshippingRadioInputDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Transport type</InputLabel>
                                    </div>
                                    <RadioGroup
                                        aria-label="transportType"
                                        name="transportType"
                                        value={this.state.transportType}
                                        onChange={this.handleChangeRadio}
                                        className={this.state.isDouglasElliman ? 'checkBtn checkBtnD' : 'checkBtn'}
                                    >
                                        <FormControlLabel value="Open" control={<Radio />} label="Open" />
                                        <FormControlLabel value="Enclosed" control={<Radio />} label="Enclosed" />
                                    </RadioGroup>
                                    <div
                                        className="errorMoving"
                                        // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                    >
                                        {this.state.transportType == '' ? (
                                            <small>{this.state.transportTypeError}</small>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <Button
                                    // variant="contained"
                                    // color="primary"
                                    className="autoshippingButton step1Button"
                                    onClick={this.handleNext}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="autoshippingStepsContent">
                        {this.vehicleDetailsForms()}
                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsD' : 'autoshippingInpts'
                            }
                        >
                            <div className="autoshippingButtonDiv">
                                <div className="autoshippingRadioInputDiv">
                                    <div
                                        className={
                                            this.state.isDouglasElliman ? 'addVedicleButton' : 'addVedicleButtonM'
                                        }
                                        onClick={this.handleAddVehicleForm}
                                    >
                                        + Add Another Vehicle
                                    </div>
                                </div>
                                <Button
                                    // variant="contained"
                                    // color="primary"
                                    className="autoshippingButton"
                                    onClick={this.handleNext}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="autoshippingStepsContent">
                        <div
                            className={
                                this.state.isDouglasElliman ? 'autoshippingInpts autoInputsD' : 'autoshippingInpts'
                            }
                        >
                            <div className="autoshippingButtonDiv">
                                <div className="datePickerDiv">
                                    <div className="inputLabel">
                                        <InputLabel className="inputLable">Available pickup date</InputLabel>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disablePast
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            // label="Date picker inline"
                                            className="date-autoshipping"
                                            value={this.state.date}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    date: e,
                                                    anchorEl: null,
                                                });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                onFocus: e => {
                                                    this.handleClick(e);
                                                },
                                            }}
                                            autoOk={true}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    this.setState({ anchorEl: null });
                                                },
                                                anchorEl: this.state.anchorEl,
                                            }}
                                            InputProps={{
                                                onFocus: e => {
                                                    this.handleClick(e);
                                                },
                                            }}
                                            // open={Boolean(this.state.anchorEl)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div
                                        className="errorMoving"
                                        // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                    >
                                        {this.state.date == null ? <small>{this.state.dateError}</small> : ''}
                                    </div>
                                </div>
                                <div className="datePickerDiv">
                                    <Button
                                        // variant="contained"
                                        // color="primary"
                                        className="autoshippingButton"
                                        onClick={this.handleNext}
                                    >
                                        Schedule a call
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    editClick = (step: number) => {
        this.setState({ activeStep: step });
    };

    render() {
        const steps = this.getSteps();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.state;
        return (
            <div
                className={
                    isDouglasElliman ? 'autoshipping-main-page autoshipping-main-pageDouglas' : 'autoshipping-main-page'
                }
            >
                <div className="main-head-moving-main">
                    <h2 className="heading">Moving Services</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}{' '}
                        </p>{' '}
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/movers');
                            }}
                        >
                            Moving Services{' '}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>{'>'} Auto shipping</p>
                    </div>
                </div>

                <div className="autoShippingTabs">
                    <div className="tab-div-moving">
                        {this.state.movingService == true ? (
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/movers' ||
                                        this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/movers');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Moving
                            </div>
                        ) : null}
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/autoease' ||
                                    this.props.history.location.pathname === `/dashboard/autoease/`)
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                        >
                            Auto Shipping
                        </div>
                        {this.state.reserveService === true ? (
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/storage' ||
                                        this.props.history.location.pathname === `/dashboard/storage/`)
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/storage');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Reserve a Storage Unit
                            </div>
                        ) : null}
                    </div>
                    <div className="autoshipping-quote-div">
                        <div className="quoteBlackText">Already have a quote?</div>
                        <div className={isDouglasElliman ? 'quoteBlueText' : 'quotedtext'}>Book your order here.</div>
                    </div>
                </div>

                {/* Stepper */}

                <div className="stepperFlexDiv">
                    <div className="stepperDiv">
                        <Stepper activeStep={this.state.activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    {this.state.activeStep > 0 && index === 1 ? (
                                        <div className="mobileDestiDetails">
                                            <div className="destinationText">Destination</div>
                                            <div className="destDetails">{`${this.state.pickupZip}, ${this.state.pickupAddress}`}</div>
                                            <div className="destDetails">{`${this.state.destinationZip}, ${this.state.destinationAddress}`}</div>
                                            <div className="destDetails">{`${this.state.transportType} - Transport type}`}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {this.state.activeStep > 1 && index === 2 ? (
                                        <StepContent>
                                            <div className="mobileDestiDetails">
                                                <div className="destinationText">Vehicle details</div>
                                                {this.state.vehicleDetails.map((vehicleDetails: any) => {
                                                    return (
                                                        <div>
                                                            <div className="destDetails">{`${vehicleDetails.make} - Vehicle make`}</div>
                                                            <div className="destDetails">{`${vehicleDetails.model} - Model`}</div>
                                                            <div className="destDetails">{`${vehicleDetails.operable} - Operable}`}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </StepContent>
                                    ) : (
                                        ''
                                    )}
                                    <StepLabel>
                                        {label}
                                        <div className="stepperEditIcon">
                                            <EditIcon onClick={() => this.editClick(index)} />
                                        </div>
                                    </StepLabel>

                                    <StepContent
                                        className={
                                            (this.state.activeStep > 0 && index === 0) ||
                                            (this.state.activeStep > 1 && index === 1)
                                                ? 'stepContent'
                                                : ''
                                        }
                                    >
                                        <div>{this.getStepContent(index)}</div>
                                        <div>
                                            <div>
                                                {/* <Button
                                                // variant="contained"
                                                color="primary"
                                                onClick={this.handleNext}
                                            >
                                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button> */}
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className="detailsAddress">
                        {this.state.activeStep > 0 ? (
                            <div style={{ borderBottom: '1px solid #C6C6C6' }}>
                                <div className="destinationText">Move details</div>
                                <div className="destDetails">{`From - ${this.state.pickupAddress}`}</div>
                                <div className="destDetails">{`To - ${this.state.destinationAddress}`}</div>
                                <div className="destDetails">{`Transport type - ${this.state.transportType}`}</div>
                            </div>
                        ) : null}

                        {this.state.activeStep > 1 ? (
                            <div className="vehicleDetailsDiv">
                                {/* <hr style={{border: "1px solid #C6C6C6"}}/> */}
                                <div className="destinationText">Vehicle details</div>
                                {this.state.vehicleDetails.map((vehicleDetails: any, index: number) => {
                                    return (
                                        <div
                                            style={{
                                                borderBottom:
                                                    this.state.vehicleDetails.length - 1 === index
                                                        ? 'none'
                                                        : '1px solid #C6C6C6',
                                            }}
                                        >
                                            <div className="destDetails">{`Vehicle make - ${vehicleDetails.make}`}</div>
                                            <div className="destDetails">{`Model - ${vehicleDetails.model}`}</div>
                                            <div className="destDetails">{`Operable - ${vehicleDetails.operable}`}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(MoveAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        commonAction: bindActionCreators(Action, dispatch),
        autoShippingAction: bindActionCreators(AutoShippingAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    commonState: state.common,
    CheckListDashboardState: state.checklistDashboard,
    autoShippingState: state.autoShipping,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoShipping);
