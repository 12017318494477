import React from 'react';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../../store/login/signup/actions';
import * as CommonAction from '../../../store/common/actions';
import { bindActionCreators } from 'redux';
import '../../../styles/signUpSecond.scss';
// import arrowBack from '../../../Assets/images/SignUp/arrowBack.svg';
import AutoComplete from '../../../components/atoms/autoComplete';
import fromHut from '../../../Assets/images/SignUp/fromHut.svg';
import toHut from '../../../Assets/images/SignUp/toHut.svg';
import moveDate from '../../../Assets/images/SignUp/moveDate.svg';
import forwardArrow from '../../../Assets/images/SignUp/arrowForward.svg';
import forwardArrowblue from '../../../Assets/images/SignUp/arrowblue.svg';
import { geocodeByAddress } from 'react-places-autocomplete';
// import TextField from '@material-ui/core/TextField';
import Button from '../../../components/atoms/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Checkbox } from '@material-ui/core';
import SelectInput from '../../../components/atoms/SelectInput';
import moment from 'moment';
// import { AppState } from '../../store/rootReducer';
// import { CircularProgress } from '@material-ui/core';
import PreLoaderImage from '../../../Assets/images/SignUp/preloader.gif';
import history from '../../../routes/History';
import { buttonClicked } from '../../../utils/utlis';

type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {
    street?: any;
    street2?: any;
    source?: any;
    destination?: any;
    date?: any;
    streetError?: any;
    street2Error?: any;
    dateError?: any;
    isDataArrived: boolean;
    moveId: string;
    isOpen?: boolean;
    anchorEl?: any;
    site_name?: any;
    data: any;
    user_ref: any;
    isHashCode: boolean;
    streetSource: string;
    streetDestination: string;
    movingToNewHome: boolean;
    homeOwner: boolean;
    slug: any;
    currentStreet: string;
    currentStreetError: string;
    year: string;
    month: any;
    continueClick: boolean;
    monthOptions: any;
    yearOptions: any;
    termsAndConditions: boolean;
    homeOwnerContinueClicked: boolean;
    showProgressLoader: boolean;
    termsConditionUrl: string;
};
let MonthNum: any = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

class SignUpSecond extends React.Component<initialProps, initialState> {
    state: initialState = {
        street: '',
        street2: '',
        source: '',
        destination: '',
        date: new Date(),
        isDataArrived: true,
        moveId: '',
        isOpen: false,
        anchorEl: null,
        site_name: 'moveeasy-demo',
        data: {},
        user_ref: {},
        isHashCode: false,
        streetSource: '',
        streetDestination: '',
        movingToNewHome:
            localStorage.getItem('isDouglasElliman') || localStorage.getItem('isSibcycline') ? true : false,
        homeOwner: false,
        slug: null,
        currentStreet: '',
        currentStreetError: '',
        year: '',
        month: '',
        continueClick: false,
        monthOptions: [],
        yearOptions: [],
        termsAndConditions: false,
        homeOwnerContinueClicked: false,
        showProgressLoader: false,
        termsConditionUrl: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        if (slug) {
            this.setState({
                site_name: slug.slug,
                slug: slug,
                termsConditionUrl: slug && slug.terms_and_condition_url,
            });
        }

        let currentYear: any = moment().format('YYYY');
        let yearOption: any = [];
        for (let i = currentYear; i >= currentYear - 25; i -= 1) {
            yearOption.push({ label: `${i}`, value: i });
        }

        this.setState({ yearOptions: yearOption });

        let payload: any = {};
        if (window.location.search && window.location.search.split('=')[0] === '?hash_code') {
            payload = {
                hashCode: window.location.search.split('=')[1],
            };
            this.props.commonAction.moveGetWithHashCode(payload);
        } else if (this.props.history.location.state && this.props.history.location.state.hashCode) {
            payload = {
                hashCode: this.props.history.location.state.hashCode,
            };
            this.props.commonAction.moveGetWithHashCode(payload);
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.street = nextProps.commonState.moveWithHashCode[0].source_full_address;
            currentState.source = nextProps.commonState.moveWithHashCode[0].source_full_address;
            currentState.street2 = nextProps.commonState.moveWithHashCode[0].destination_full_address;
            currentState.destination = nextProps.commonState.moveWithHashCode[0].destination_full_address;
            currentState.date = new Date(Date.parse(nextProps.commonState.moveWithHashCode[0].moving_date));
            currentState.moveId = nextProps.commonState.moveWithHashCode[0].id;
            currentState.data = nextProps.commonState.moveWithHashCode[0];
            currentState.user_ref = nextProps.commonState.moveWithHashCode[0].user_ref;
            currentState.streetSource = nextProps.commonState.moveWithHashCode[0].source_street;
            currentState.currentStreet = nextProps.commonState.moveWithHashCode[0].source_full_address;
            currentState.streetDestination = nextProps.commonState.moveWithHashCode[0].destination_street;
            currentState.isHashCode = true;
            currentState.termsAndConditions = nextProps.commonState.moveWithHashCode[0].has_accepted_terms_condition;
            currentState.homeOwner =
                nextProps.commonState.moveWithHashCode[0].status &&
                nextProps.commonState.moveWithHashCode[0].status === 'Home Owner'
                    ? true
                    : false;
            currentState.movingToNewHome =
                nextProps.commonState.moveWithHashCode[0].status &&
                nextProps.commonState.moveWithHashCode[0].status === 'Home Owner'
                    ? false
                    : true;
            currentState.year = nextProps.commonState.moveWithHashCode[0].moving_date
                ? moment(nextProps.commonState.moveWithHashCode[0].moving_date).format('YYYY')
                : '';
            currentState.month = nextProps.commonState.moveWithHashCode[0].moving_date
                ? MonthNum[parseInt(moment(nextProps.commonState.moveWithHashCode[0].moving_date).format('M')) - 1]
                : '';
            localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
        }

        if (nextProps && nextProps.signupState && nextProps.signupState.signUpUpdated) {
            nextProps.signupState.signUpUpdated = false;
            currentState.showProgressLoader = false;
            if (currentState.homeOwner) {
                history.push({
                    pathname: '/dashboardhomeowner',
                });
            } else {
                history.push({
                    pathname: '/dashboard',
                });
            }
        }

        if (
            nextProps &&
            nextProps.signupState &&
            nextProps.signupState.error &&
            nextProps.signupState.error != null &&
            currentState.showProgressLoader
        ) {
            currentState.showProgressLoader = false;
        }
        return currentState;
    }

    getAge = (a: any, b: any) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };

    validateFields = () => {
        let validity: Boolean = true;

        if (this.state.street === '') {
            this.setState({ streetError: 'Please Enter Source' });
            validity = false;
        } else {
            this.setState({ streetError: '' });
        }

        if (this.state.street2 === '') {
            this.setState({ street2Error: 'Please Enter Destination' });
            validity = false;
        } else {
            this.setState({ street2Error: '' });
        }

        if (this.state.date === '') {
            this.setState({ dateError: 'Please Select Date' });
            validity = false;
        } else {
            this.setState({ dateError: '' });
        }

        return validity;
    };
    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                        source: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        streetSource: `${streetNumber} ${route}`,

                        //  ${streetNumber} ${route}, ${city}, ${state} ${zip}
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleSelect2 = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street2: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                        destination: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        streetDestination: `${streetNumber} ${route}`,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleSelectCurrentAddress = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        currentStreet: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    onSelectHandler = async (e: any, name: string) => {
        if (name == 'year') {
            let monthOption: any = [
                { value: 'January', label: 'January' },
                { value: 'February', label: 'February' },
                { value: 'March', label: 'March' },
                { value: 'April', label: 'April' },
                { value: 'May', label: 'May' },
                { value: 'June', label: 'June' },
                { value: 'July', label: 'July' },
                { value: 'August', label: 'August' },
                { value: 'September', label: 'September' },
                { value: 'October', label: 'October' },
                { value: 'November', label: 'November' },
                { value: 'December', label: 'December' },
            ];
            let newMonthOption: any = [];
            if (moment().format('yyyy') === e.value) {
                monthOption.map((item: any, index: number) => {
                    if (index <= moment().month()) {
                        newMonthOption.push(item);
                    }
                });
            } else {
                newMonthOption = monthOption;
            }
            await this.setState({ monthOptions: newMonthOption });
        }
        this.setState({ ...this.state, [name]: e.value });
    };
    dateHandler = (e: any) => {
        this.setState({
            date: e,
            // anchorEl: null,
        });
    };

    proceedButtonHandler = () => {
        let payload: any = {};
        if (this.validateFields()) {
            this.setState({ showProgressLoader: true });
            const email =
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.email;
            const fullName =
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fullName;
            const password =
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.password;
            const phone =
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.phone;

            let monthnum: number = new Date(this.state.date).getMonth() + 1;
            if (this.state.moveId !== '') {
                payload = {
                    moveId: this.state.moveId,
                    data: {
                        // ...this.state.data,
                        destination_full_address: this.state.street2,
                        source_full_addres: this.state.street,
                        destination_detail: this.state.street2,
                        source_street: this.state.streetSource,
                        destination_street: this.state.streetDestination,
                        destination_home: '1',
                        moving_date: `${new Date(this.state.date).getFullYear()}-${monthnum}-${new Date(
                            this.state.date,
                        ).getDate()}`,
                        moving_in_date: null,
                        moving_items_list: ['2'],
                        moving_option_value: ['2'],
                        selected_service_list: ['11'],
                        source_detail: this.state.street,
                        source_home: '1',
                        user_ref: {
                            id: this.state.user_ref.id,
                            email: email,
                            first_name: fullName && fullName.split(' ')[0] ? fullName.split(' ')[0] : '',
                            last_name: fullName && fullName.split(' ')[1] ? fullName.split(' ')[1] : '',
                            password: password,
                            phone: phone,
                            // site_name: this.state.site_name,
                        },
                        site_name: this.state.site_name,
                        has_accepted_terms_condition: this.state.termsAndConditions,
                        utm_source:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_source,
                        utm_medium:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_medium,
                        utm_content:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_content,
                        utm_campaign:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_campaign,
                    },
                };

                this.props.signupAction.signupUpdate(payload);
            } else {
                payload = {
                    destination_detail: this.state.destination,
                    destination_home: '1',
                    moving_date: `${new Date(this.state.date).getFullYear()}-${monthnum}-${new Date(
                        this.state.date,
                    ).getDate()}`,
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    source_detail: this.state.source,
                    source_home: '1',
                    user_ref: {
                        email: email,
                        first_name: fullName && fullName.split(' ')[0] ? fullName.split(' ')[0] : '',
                        last_name: fullName && fullName.split(' ')[1] ? fullName.split(' ')[1] : '',
                        password: password,
                        phone: phone,
                        site_name: this.state.site_name,
                    },
                    secondary_phone: '',
                    secondary_email: '',
                    has_accepted_terms_condition: this.state.termsAndConditions,
                    utm_source:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_source,
                    utm_medium:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_medium,
                    utm_content:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_content,
                    utm_campaign:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_campaign,
                };

                this.props.signupAction.signup(payload);
            }
            window.scrollTo(0, 0);
            let brokerage: any;

            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            buttonClicked(
                'Button Clicked',
                'SignUp Page',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                '',
                'Proceed to Dashboard',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                1,
                '',
                move_id,
            );
        }
    };
    homeOwnerButtonHandler = () => {
        this.setState({ homeOwnerContinueClicked: true });
        let payload: any = {};
        const email =
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.email;
        const fullName =
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fullName;
        const password =
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.password;
        const phone =
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.phone;

        let monthnum: number = new Date(this.state.date).getMonth() + 1;
        if (this.state.currentStreet && this.state.year && this.state.month) {
            this.setState({ showProgressLoader: true });
            if (this.state.moveId !== '') {
                payload = {
                    moveId: this.state.moveId,
                    data: {
                        // ...this.state.data,
                        destination_details: this.state.currentStreet,
                        // source_street: this.state.streetSource,
                        destination_home: '1',
                        moving_date: `${new Date(this.state.date).getFullYear()}-${monthnum}-${new Date(
                            this.state.date,
                        ).getDate()}`,
                        moving_in_date: null,
                        moving_items_list: ['2'],
                        moving_option_value: ['2'],
                        selected_service_list: ['11'],
                        source_detail: this.state.currentStreet,
                        source_home: '1',
                        user_ref: {
                            id: this.state.user_ref.id,
                            email: email,
                            first_name: fullName && fullName.split(' ')[0] ? fullName.split(' ')[0] : '',
                            last_name: fullName && fullName.split(' ')[1] ? fullName.split(' ')[1] : '',
                            password: password,
                            phone: phone,
                        },
                        site_name: this.state.site_name,
                        is_home_owner: this.state.homeOwner,
                        has_accepted_terms_condition: this.state.termsAndConditions,
                        utm_source:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_source,
                        utm_medium:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_medium,
                        utm_content:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_content,
                        utm_campaign:
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.utm_campaign,
                    },
                };
                this.props.signupAction.signupUpdate(payload);
            } else {
                payload = {
                    destination_home: '1',
                    moving_date: `${this.state.year}-${MonthNum.indexOf(this.state.month) + 1}-01`,
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    source_detail: this.state.currentStreet,
                    source_home: '1',
                    destination_detail: this.state.currentStreet,
                    user_ref: {
                        email: email,
                        first_name: fullName && fullName.split(' ')[0] ? fullName.split(' ')[0] : '',
                        last_name: fullName && fullName.split(' ')[1] ? fullName.split(' ')[1] : '',
                        password: password,
                        phone: phone,
                        site_name: this.state.site_name,
                    },
                    secondary_phone: '',
                    secondary_email: '',
                    is_home_owner: this.state.homeOwner,
                    has_accepted_terms_condition: this.state.termsAndConditions,
                    utm_source:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_source,
                    utm_medium:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_medium,
                    utm_content:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_content,
                    utm_campaign:
                        this.props &&
                        this.props.history &&
                        this.props.history.location &&
                        this.props.history.location.state &&
                        this.props.history.location.state.utm_campaign,
                };
                this.props.signupAction.signup(payload);
            }
        }

        window.scrollTo(0, 0);
        let brokerage: any;

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'SignUp Page',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            '',
            'Proceed to Dashboard',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };
    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        let slug1: any = localStorage.getItem('slug');
        if (slug1) {
            slug1 = JSON.parse(slug1);
        }
        this.state.termsConditionUrl = slug1 && slug1.terms_and_condition_url;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let isSibcycline: boolean = localStorage.getItem('isSibcycline') ? true : false;

        return (
            <div>
                {this.state.showProgressLoader ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '60vh',
                        }}
                    >
                        <img style={{ maxHeight: '300px' }} src={PreLoaderImage} />
                        <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                            Hang On! We Are Setting Up Your Dashboard...
                        </div>
                    </div>
                ) : (
                    <div className="main-div-signUp2">
                        {isDouglasElliman ? (
                            <div className="div1-signUp2D">
                                <div className="cardUpper">
                                    <div className="borderDiv" style={{ background: '#100B28' }}>
                                        <div className="template-main-div1">
                                            <div className="HeaderText">
                                                <span className="highlightedText"> Powerful features</span> to give you
                                                a competitive edge on all platforms
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="div1-signUp2"></div>
                        )}
                        <div className="div2-signUp2">
                            {/* <div className="arrow-signUp2" onClick={()=>{
                        this.props.history.push('/signUp')
                    }}>
                      <img src={arrowBack}/>
                    </div> */}
                            <div className="heading-signUp2">
                                <span className="heading-signUp2-awesome">Awesome! </span>Just one more thing
                            </div>
                            <div className="sub-heading-signUp2">
                                Please verify your moving details to help us personalize your account
                            </div>
                            {isDouglasElliman || isSibcycline || this.state.isHashCode ? null : (
                                <div className="checkboxContentFlexDiv">
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'checkboxContentDiv checkboxContentDivDouglas'
                                                : 'checkboxContentDiv'
                                        }
                                        style={{
                                            border:
                                                this.state.movingToNewHome && isDouglasElliman
                                                    ? '1px solid #009cbd'
                                                    : this.state.movingToNewHome && !isDouglasElliman
                                                    ? this.state.slug && this.state.slug.primary_color
                                                        ? `1px solid ${this.state.slug.primary_color}`
                                                        : '1px solid #30AB86'
                                                    : '1px solid #bebebe',
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                movingToNewHome: !this.state.movingToNewHome,
                                                homeOwner: false,
                                            });
                                        }}
                                    >
                                        <Checkbox
                                            checked={this.state.movingToNewHome}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    movingToNewHome: e.target.checked,
                                                    homeOwner: false,
                                                });
                                            }}
                                        />
                                        <div className="checkboxLabel">I am moving to a New Home</div>
                                    </div>
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'checkboxContentDiv checkboxContentDivDouglas'
                                                : 'checkboxContentDiv'
                                        }
                                        style={{
                                            border:
                                                this.state.homeOwner && isDouglasElliman
                                                    ? '1px solid #009cbd'
                                                    : this.state.homeOwner && !isDouglasElliman
                                                    ? this.state.slug && this.state.slug.primary_color
                                                        ? `1px solid ${this.state.slug.primary_color}`
                                                        : '1px solid #30AB86'
                                                    : '1px solid #bebebe',
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                homeOwner: !this.state.homeOwner,
                                                movingToNewHome: false,
                                            });
                                        }}
                                    >
                                        <Checkbox
                                            checked={this.state.homeOwner}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    homeOwner: e.target.checked,
                                                    movingToNewHome: false,
                                                });
                                            }}
                                        />
                                        <div className="checkboxLabel">
                                            I am a homeowner that has already settled in my home
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.movingToNewHome ? (
                                <div className="heading-signUp2">
                                    <span className="heading-signUp2-awesome">Great, </span>let’s get your moving
                                    details
                                </div>
                            ) : this.state.homeOwner ? (
                                <div className="heading-signUp2">
                                    <span className="heading-signUp2-awesome">Great, </span>let’s get your home address
                                </div>
                            ) : null}
                            {this.state.movingToNewHome ? (
                                <div className="inputs-div-signUp">
                                    <div className="firstLineInputs-signUp2">
                                        <div
                                            className={
                                                isDouglasElliman
                                                    ? 'input-div-1-signUp input-div-1-signUpD '
                                                    : 'input-div-1-signUp'
                                            }
                                        >
                                            <div className="move-signUp2">
                                                <div className="image-signUp">
                                                    <img src={fromHut} width="20px" height="20px" />
                                                </div>
                                                <div>Moving From</div>
                                            </div>
                                            <div>
                                                <AutoComplete
                                                    className={
                                                        isDouglasElliman
                                                            ? 'input1-signUp2 douglasInput'
                                                            : 'input1-signUp2'
                                                    }
                                                    error={Boolean(this.state.streetError)}
                                                    value={this.state.street}
                                                    onChange={e => {
                                                        this.setState({ street: e });
                                                    }}
                                                    onSelect={this.handleSelect}
                                                    placeholder="Street Name & Number"

                                                    // inputTitle="To"
                                                />
                                                <div className="errorSignup">
                                                    {''}
                                                    {this.state.street == '' ? (
                                                        <small>{this.state.streetError}</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="center-arrow-signUp">
                                            {isDouglasElliman ? (
                                                <img src={forwardArrow} className="forwardArrow-signUp" />
                                            ) : (
                                                <img src={forwardArrowblue} className="forwardArrow-signUp" />
                                            )}
                                        </div>
                                        <div
                                            className={
                                                isDouglasElliman
                                                    ? 'input-div-2-signUp input-div-2-signUpD '
                                                    : 'input-div-2-signUp'
                                            }
                                        >
                                            <div className="move-signUp2">
                                                <div className="image-signUp">
                                                    <img src={toHut} width="20px" height="20px" />
                                                </div>
                                                <div>Moving To</div>
                                            </div>
                                            <div>
                                                <AutoComplete
                                                    className={
                                                        isDouglasElliman
                                                            ? 'input2-signUp2 douglasInput'
                                                            : 'input2-signUp2'
                                                    }
                                                    error={Boolean(this.state.street2Error)}
                                                    value={this.state.street2}
                                                    onChange={e => {
                                                        this.setState({ street2: e });
                                                    }}
                                                    onSelect={this.handleSelect2}
                                                    placeholder="Street Name & Number"

                                                    // inputTitle="To"
                                                />
                                                <div className="errorSignup">
                                                    {''}
                                                    {this.state.street2 == '' ? (
                                                        <small>{this.state.street2Error}</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="secondLineInput-signUp2">
                                        <div
                                            className={
                                                isDouglasElliman
                                                    ? 'input-div-3-signUp input-div-3-signUpD '
                                                    : 'input-div-3-signUp'
                                            }
                                        >
                                            <div className="move-signUp2">
                                                <div className="image-signUp">
                                                    <img src={moveDate} width="20px" height="20px" />
                                                </div>
                                                <div>Moving Date</div>
                                            </div>
                                            <div
                                                className={
                                                    isDouglasElliman
                                                        ? 'date-picker-div date-pickerDivDoug'
                                                        : 'date-picker-div'
                                                }
                                            >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        className={
                                                            isDouglasElliman
                                                                ? 'date-signUp2  date-signUp2Douglas'
                                                                : 'date-signUp2'
                                                        }
                                                        disablePast={this.state.isHashCode ? false : true}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        // label="Date picker inline"

                                                        value={this.state.date}
                                                        onChange={(e: any) => {
                                                            this.dateHandler(e);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            onFocus: e => {
                                                                this.handleClick(e);
                                                            },
                                                        }}
                                                        autoOk={true}
                                                        PopoverProps={{
                                                            style: { zIndex: 1000000 },
                                                            disableRestoreFocus: true,
                                                            // onClose: () => {
                                                            //     this.setState({ anchorEl: null });
                                                            // },
                                                            // anchorEl: this.state.anchorEl,
                                                        }}
                                                        InputProps={
                                                            {
                                                                //   onFocus: (e) => {
                                                                //       this.handleClick(e)
                                                                //   },
                                                            }
                                                        }
                                                        // open={Boolean(this.state.anchorEl)}
                                                        error={Boolean(this.state.dateError)}
                                                        minDateMessage="Moving Date should not be in the past"
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className="difference-div">
                                                    {this.getAge(new Date(), new Date(this.state.date)) == 0
                                                        ? 'Today'
                                                        : this.getAge(new Date(), new Date(this.state.date)) == 1
                                                        ? 'Tomorrow'
                                                        : `${this.getAge(
                                                              new Date(),
                                                              new Date(this.state.date),
                                                          )} days to go`}
                                                </div>
                                                {this.state.date == '' ? (
                                                    <div className="errorSignup">
                                                        {''}
                                                        <small>{this.state.dateError}</small>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'termsAndConditionDiv termsAndConditionDivDouglas'
                                                : 'termsAndConditionDiv'
                                        }
                                    >
                                        <Checkbox
                                            checked={this.state.termsAndConditions}
                                            onChange={(e: any) =>
                                                this.setState({ termsAndConditions: e.target.checked })
                                            }
                                        />{' '}
                                        By creating an account you are agreeing to our User{' '}
                                        <a
                                            className={
                                                isDouglasElliman
                                                    ? 'termsAndConditionText termsAndConditionTextDouglas'
                                                    : 'termsAndConditionText'
                                            }
                                            href={
                                                this.state.termsConditionUrl
                                                    ? this.state.termsConditionUrl
                                                    : 'https://www.moveeasy.com/terms-conditions/'
                                            }
                                            target="_blank"
                                        >
                                            Terms & Conditions and Privacy Policy.
                                        </a>
                                    </div>
                                    <div className="buttonDiv-signUp2">
                                        <Button
                                            className="proceedToDashBoard"
                                            onClick={this.proceedButtonHandler}
                                            color="#fff"
                                            //  backgroundColor='#273E59'
                                            disabled={!this.state.termsAndConditions}
                                        >
                                            Proceed To Dashboard
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                            {this.state.homeOwner ? (
                                <div className="inputs-div-signUp">
                                    <div className="firstLineInputs-signUp2">
                                        <div className="input-div-1-signUp">
                                            <div className="move-signUp2">
                                                <div className="image-signUp">
                                                    <img src={fromHut} width="20px" height="20px" />
                                                </div>
                                                <div>Current Address</div>
                                            </div>
                                            <div>
                                                <AutoComplete
                                                    className={
                                                        isDouglasElliman
                                                            ? 'input1-signUp2 douglasInput'
                                                            : 'input1-signUp2'
                                                    }
                                                    error={Boolean(this.state.currentStreetError)}
                                                    value={this.state.currentStreet}
                                                    onChange={e => {
                                                        this.setState({ currentStreet: e });
                                                    }}
                                                    onSelect={this.handleSelectCurrentAddress}
                                                    placeholder="Street Name & Number"

                                                    // inputTitle="To"
                                                />
                                                <div className="errorSignup">
                                                    {this.state.homeOwnerContinueClicked &&
                                                    this.state.currentStreet == ''
                                                        ? 'Enter Current Address'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="firstLineInputs-signUp2">
                                        <div className="input-div-1-signUp">
                                            <div className="move-signUp2">
                                                <div className="image-signUp">
                                                    <img src={moveDate} width="20px" height="20px" />
                                                </div>
                                                <div>Moved Year & Month</div>
                                            </div>
                                            <div className="movedYearAndMonthFlexDiv">
                                                <div className="selectDiv">
                                                    <div
                                                        style={{ position: 'relative' }}
                                                        className={`${
                                                            this.state.year ? 'slectedAdressInsurance' : ''
                                                        } ${
                                                            this.state.continueClick && !this.state.year
                                                                ? 'errorInsurance'
                                                                : ''
                                                        }`}
                                                    >
                                                        {this.state.year && (
                                                            <div className="stateTextAddressInsurance">Select Year</div>
                                                        )}
                                                        <SelectInput
                                                            className="react-select alert-select"
                                                            onChange={(e: any) => this.onSelectHandler(e, 'year')}
                                                            options={this.state.yearOptions}
                                                            placeholder={
                                                                this.state.year ? this.state.year : 'Select year'
                                                            }
                                                            value={this.state.year}
                                                            label="Select year"
                                                        />
                                                        <div className="errorSignup">
                                                            {this.state.homeOwnerContinueClicked &&
                                                            this.state.year == ''
                                                                ? 'Select Year'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="selectDiv">
                                                    <div
                                                        style={{ position: 'relative' }}
                                                        className={`${
                                                            this.state.month ? 'slectedAdressInsurance' : ''
                                                        } ${
                                                            this.state.continueClick && !this.state.month
                                                                ? 'errorInsurance'
                                                                : ''
                                                        }`}
                                                    >
                                                        {this.state.month && (
                                                            <div className="stateTextAddressInsurance">
                                                                Select Month
                                                            </div>
                                                        )}
                                                        <SelectInput
                                                            className="react-select alert-select"
                                                            onChange={(e: any) => this.onSelectHandler(e, 'month')}
                                                            options={this.state.monthOptions}
                                                            placeholder={
                                                                this.state.month ? this.state.month : 'Select month'
                                                            }
                                                            value={this.state.month}
                                                            label="Select month"
                                                        />
                                                        <div className="errorSignup">
                                                            {this.state.homeOwnerContinueClicked &&
                                                            this.state.month == ''
                                                                ? 'Select Month'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'termsAndConditionDiv termsAndConditionDivDouglas'
                                                : 'termsAndConditionDiv'
                                        }
                                    >
                                        <Checkbox
                                            checked={this.state.termsAndConditions}
                                            onChange={(e: any) =>
                                                this.setState({ termsAndConditions: e.target.checked })
                                            }
                                        />{' '}
                                        By creating an account you are agreeing to our User{' '}
                                        <a
                                            className={
                                                isDouglasElliman
                                                    ? 'termsAndConditionText termsAndConditionTextDouglas'
                                                    : 'termsAndConditionText'
                                            }
                                            href={
                                                this.state.termsConditionUrl
                                                    ? this.state.termsConditionUrl
                                                    : 'https://www.moveeasy.com/terms-conditions/'
                                            }
                                            target="_blank"
                                        >
                                            Terms & Conditions and Privacy Policy.
                                        </a>
                                    </div>
                                    <div className="buttonDiv-signUp2">
                                        <Button
                                            className="proceedToDashBoard"
                                            onClick={this.homeOwnerButtonHandler}
                                            color="#fff"
                                            //  backgroundColor='#273E59'
                                            disabled={!this.state.termsAndConditions}
                                        >
                                            Proceed To Dashboard
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                            {/* {} */}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpSecond);
