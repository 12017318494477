import React, { Component } from 'react';
import './scheduledServices.scss';
// import Button from '../atoms/Button';
import Rate from '../../Assets/images/TrustedPros/favorite.svg';
import Call from '../../Assets/images/TrustedPros/call.svg';
import Place from '../../Assets/images/TrustedPros/place.svg';
// import Share from '../../Assets/images/TrustedPros/share.svg';

type initialProps = {
    company: string;
    firstChar: any;
    rating: any;
    phone: any;
    address1: string;
    date: any;
    time: any;
    address2: string;
    vendorContactStatus ?: string;
    btnText ?: string;
    bgColor: string;
    requested ? : any
};

class ServiceCard extends Component<initialProps> {
    render() {
        const {
            company,
            firstChar,
            rating,
            phone,
            address1,
            date,
            time,
            address2,
            // vendorContactStatus,
            // btnText,
            bgColor,
        } = this.props;
        return (
            <div className="scheduledServiceCard">
                {/* <div className="scheduledServiceAgentVerified">AGENT VERIFIED</div> */}
                <div className="scheduledServiceShare">
                    {/* <img src={Share} width="24px" height="24px" /> */}
                </div>
                <div className="scheduledServiceCardCol">
                    <div className="scheduledServiceCardLogo">
                        <span className="scheduledServiceChar" style={{ backgroundColor: bgColor }}>
                            {firstChar}
                        </span>
                    </div>

                    <div className="scheduledServiceSecondRow">
                        <div className="scheduledServiceCompany">{company}</div>
                        <div className="scheduledServiceRating">
                           {
                               rating ?  <div className="scheduledServiceRatingWrapper">
                               <div className="scheduledServiceRate">
                                   <img src={Rate} width="19px" height="19px" />
                               </div>
                               <div className="scheduledServiceRate">{rating}</div>
                           </div> : null
                           }
                            {/* <div className="scheduledServiceReadReviews">Read Reviews</div> */}
                        </div>

                        <div className="scheduledServiceContactInfoWrapper">
                           {
                            address1 || phone ?  <div className="scheduledServiceContactInfo">Contact Information</div> : null
                           }
                           {
                               phone ?  <div className="scheduledServiceContactInfoPhoneWrapper">
                               <div>
                                   <img src={Call} width="16px" height="16px" />
                               </div>
                               <div className="scheduledServiceContactInfoPhone">{phone}</div>
                           </div> : null
                           }

                            {
                                address1 ? <div className="scheduledServiceContactInfoPhoneWrapper">
                                <div>
                                    <img src={Place} width="16px" height="16px" />
                                </div>
                                <div className="scheduledServiceContactInfoPhone">{address1}</div>
                            </div> : null
                            }
                        </div>
                    </div>

                    <div className="scheduledServiceThirdRow">
                        <div className="scheduledServiceDtTime">Date & Time</div>
                        <div className="scheduledServiceDtTimeVal">
                            {date}, {time}
                        </div>

                     {
                         address2 ?    <div className="scheduledServiceAddress">
                         <div className="scheduledServiceDtTime">Address</div>
                         <div className="scheduledServiceDtTimeVal">{address2}</div>
                     </div> : null
                     }
                    </div>

                    <div className="scheduledServiceFourthRow">
                        <div className="scheduledServiceStatusWrapper">
                            <div>
                                <div className="scheduledServiceRequested">Requested</div>
                                <div className="scheduledServiceToday">{this.props.requested}</div>
                            </div>
                            <div>
                                {/* <div className="scheduledServiceVendorContacted">Vendor Contacted</div> */}
                                <div className="scheduledServiceStatusContainer">
                                    {/* <div className="scheduledServiceStatus">{vendorContactStatus}</div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="scheduledServiceBtn">
                        <div className="shareIconMobile">
                        {/* <img src={Share} width="24px" height="24px" /> */}
                        </div>
                        {/* <Button
                            backgroundColor="#fff"
                            width="116px"
                            height="40px"
                            color="#002E62"
                            border="1px solid #273E59"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {btnText}
                        </Button> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default ServiceCard;
