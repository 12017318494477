import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CompareComponent from '../InternetLanding/compareComponent'
import ResearchLeftImage from '../../Assets/images/research-left-img.svg';
// import ResearchRightImage from '../../Assets/images/research-right.svg';
import TimeLeftImage from '../../Assets/images/time-left-img.svg';
import TimeRightImage from '../../Assets/images/time-right1.png';
import HelpRightImage from '../../Assets/images/help-right.svg';
import HelpLeftImage from '../../Assets/images/help-left.svg';
import RecommendedLeft from '../../Assets/images/recommended-left.svg';
import RecommendedRight from '../../Assets/images/recommended-right.svg';
import ResearchRightImg from '../../Assets/images/research-left.png';
import SwipeableViews from 'react-swipeable-views';





function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    // flexGrow: 1,
    backgroundColor: "transparent",

  },
}));

export default function TabComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue:any) => {
    // setValue(newValue);
    setValue(parseInt(newValue.currentTarget.id.split("-")[2]));

  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };


  return (
    <div className={`${classes.root} tabHead`}>
     <div color="default">
        <Tabs value={value} 
        variant="scrollable"
        scrollButtons="off"
        TabIndicatorProps={{style: {background:'#1D7E64'}}} 
        onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Research process" {...a11yProps(0)} />
          <Tab label="Time" {...a11yProps(1)} />
          <Tab label="Recommended deals" {...a11yProps(2)} />
          <Tab label="Help" {...a11yProps(3)} />
        </Tabs>
        </div>
        <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
      <TabPanel value={value} index={0}>
      <CompareComponent     width="300px"
                        height="300px"
                        cardImage1={ResearchLeftImage}
                        cardImage2={ResearchRightImg}
                        cardContent1="Search individual provider sites and blog sites."
                        cardContent2="Do all your research on one site"
                        color="#30AB86"
                        border=" 2px solid #30AB86"
                        />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <CompareComponent     width="300px"
                        height="300px"
                        cardImage1={ TimeLeftImage}
                        cardImage2={ TimeRightImage}
                        cardContent1="Visit individual provider sites, entering your location each time to check availability"
                        cardContent2="Enter your location once and compare all of your options at once"
                        color="#30AB86"
                        border=" 2px solid #30AB86"
                        />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <CompareComponent     width="300px"
                        height="300px"
                        cardImage1={RecommendedRight}
                        cardImage2={HelpRightImage}
                        cardContent1="Track offers across individual provider sites"
                        cardContent2="View all current offers to score the best deal"
                        color="#30AB86"
                        border=" 2px solid #30AB86"
                        />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <CompareComponent    
       width="300px"
                        height="300px"
                        cardImage1={RecommendedLeft}
                        cardImage2={HelpLeftImage}
                        cardContent1="Search online for answers or ask around"
                        cardContent2="Call a live expert to chat or browse expert recommendations online"
                        color="#30AB86"
                        border=" 2px solid #30AB86"
                        />
      </TabPanel>
      </SwipeableViews>
    </div>
  );
}
