import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
// import Cancel from "../../Assets/images/closeNew.svg";

type iprops = {
    isShowModal: boolean;
    onCloseModal?: any;
    showClose: boolean;
    children: any;
    style?: any;
    preview?: boolean;
    borderRadius?: string;
    width?: string;
    padding?: string;
    left?: string;
    top?: string;
    className?: any;
    styleIcon?: any;
    icon?: any;
};
// const useStyles = makeStyles(() => ({
//     paper: {
//         position: 'absolute',
//         width: 700,
//         backgroundColor: '#ffffff',
//         borderRadius: '15px',
//         // boxShadow: theme.shadows[2],
//         padding: '45px 50px',
//         left: '30%',
//         top: '10%',
//     },
//     nopadding: {
//         position: 'absolute',
//         width: 700,
//         backgroundColor: '#ffffff',
//         borderRadius: '15px',
//         // boxShadow: theme.shadows[2],
//         padding: '20px',
//         left: '30%',
//         top: '20%',
//     },
// }));

export default function SimpleModal(props: iprops) {
    // const classes = useStyles();
    return (
        <div>
            <Modal
                open={props.isShowModal}
                // onClose={props.onCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onBackdropClick={props.onCloseModal}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableRestoreFocus={true}
                style={{ zIndex: 20000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div
                    // /*style={modalStyle}*/ className={props.preview ? classes.nopadding : classes.paper}
                    style={
                        props.style
                            ? props.style
                            : !props.className
                            ? {
                                  position: 'absolute',
                                  width: props.width ? props.width : 700,
                                  backgroundColor: '#ffffff',
                                  borderRadius: props.borderRadius ? props.borderRadius : '15px',
                                  // boxShadow: theme.shadows[2],
                                  padding: props.padding ? props.padding : '20px',
                                  left: props.left ? props.left : '30%',
                                  top: props.top ? props.top : '20%',
                              }
                            : null
                    }
                    className={props.className ? props.className : null}
                >
                    {props.showClose && (
                        <div
                            // className="close"
                            onClick={props.onCloseModal}
                            style={
                                props.styleIcon
                                    ? props.styleIcon
                                    : {
                                          position: 'absolute',
                                          right: '20px',
                                          top: '25px',
                                          cursor: 'pointer',
                                      }
                            }
                        >
                            {/* <img src={Cancel} width="20px" alt={""} /> */}
                            {props.icon ? (
                                <img src={props.icon} width="20px" alt={''} />
                            ) : (
                                <CloseIcon style={{ color: '#6B6C6F', fontSize: '25px' }} />
                            )}
                        </div>
                    )}
                    {props.children}
                </div>
            </Modal>
        </div>
    );
}
