export const GET_CHECKLIST_BY_MOVE_ID = 'GET_CHECKLIST_BY_MOVE_ID';
export const GET_CHECKLIST_BY_MOVE_ID_SUCCESS = 'GET_CHECKLIST_BY_MOVE_ID_SUCCESS';
export const GET_CHECKLIST_BY_MOVE_ID_ERROR = 'GET_CHECKLIST_BY_MOVE_ID_ERROR';

export const POST_SUBSCRIPTION_DETAILS = 'POST_SUBSCRIPTION_DETAILS';
export const POST_SUBSCRIPTION_DETAILS_SUCCESS = 'POST_SUBSCRIPTION_DETAILS_SUCCESS';
export const POST_SUBSCRIPTION_DETAILS_ERROR = 'POST_SUBSCRIPTION_DETAILS_ERROR';

export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_ERROR = 'GET_SUBSCRIPTION_DETAILS_ERROR';

export const UPDATE_SUBSCRIPTION_DETAILS = 'UPDATE_SUBSCRIPTION_DETAILS';
export const UPDATE_SUBSCRIPTION_DETAILS_SUCCESS = 'UPDATE_SUBSCRIPTION_DETAILS_SUCCESS';
export const UPDATE_SUBSCRIPTION_DETAILS_ERROR = 'UPDATE_SUBSCRIPTION_DETAILS_ERROR';
