import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from './Button';
import '../../styles/planCardResponsive.scss';
import iIcon from '../../Assets/images/Moving/iIcon.svg';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    availableSpeed?: string | null;
    channels?: string | null;
    currency?: string;
    ammount?: string;
    planRate?: string;
    planValidFor?: string;
    boxShadow?: any;
    features?: any;
    buyNow?: any;
    contractTerm?: string;
    viewDetails?: any;
}

const PlanCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 5px #9C9DA1',
            },
        },
    }));

    const classes = useStyles();
    // let array: any = props.features.map((el: any) => el.name);
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    const domain = window.location.hostname;
    let name = domain.split('.');

    let availableSpeed: string = '';
    let str1: any = props && props.availableSpeed && props.availableSpeed.split(' ');
    if (parseInt(str1[0]) > 10000 && parseInt(str1[0]) < 100000) {
        availableSpeed = str1[0].substring(0, 2) + 'Mbps';
    } else if (parseInt(str1[0]) > 100000) {
        availableSpeed = str1[0].substring(0, 3) + 'Mbps';
    } else {
        availableSpeed = str1[0] + 'Mbps';
    }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div
            style={{
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 5px #9C9DA1',
                border: props.border ? props.border : null,
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '',
                padding: props.cardPadding ? props.cardPadding : '0px',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : classes.root}
        >
            <div className="cardMainResponsive" style={{ margin: '0 15px' }}>
                <div className="cardMainResponsive">
                    <div className="LogoDivResponsive">
                        <img
                            width={props.imgWidth ? props.imgWidth : 'auto'}
                            height={props.imgHeight ? props.imgHeight : '35px'}
                            src={props.cardImage}
                            style={{
                                marginRight: 'auto',
                            }}
                            alt=""
                        />
                        <div className="buttonDivResponsive">
                            <Button
                                width="90px"
                                height="40px"
                                fontSize="14px"
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : name[0].includes('internet') ||
                                          name[0].includes('insurance') ||
                                          name[0].includes('security')
                                        ? '#30AB86'
                                        : '#273E59'
                                }
                                onClick={props.buyNow}
                            >
                                Select
                            </Button>
                        </div>
                    </div>

                    <div className="titleDivResponsive">{props.cardTitle}</div>

                    <div className="features">
                        <div className="featuresFlexDivResponsive">
                            {props.features.map((item: any) => {
                                return (
                                    <div
                                        className={
                                            isDouglasElliman ? 'featureDivResponsive' : 'featureDivResponsivenoDoug'
                                        }
                                    >
                                        <img
                                            width={item.width ? item.width : '20px'}
                                            height={item.height ? item.height : '20px'}
                                            src={item.icon}
                                            alt=""
                                            style={{ marginRight: '5px' }}
                                        />
                                        <div style={{ marginLeft: '3px', marginTop: '3px' }}> {item.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flexDivPlanCardResponsive">
                {/* {(array.includes('Internet') || array.includes('Phone')) && ( */}
                <div className="availableSpeedMainDivResponsive">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="availableSpeedTitle">Available Speed</div>
                            <div style={{ marginLeft: '5px' }}>
                                <img src={iIcon} width="15px" height="15px" />
                            </div>
                        </div>
                        <div className="availableSpeedContent">{availableSpeed ? availableSpeed : 'NA'}</div>
                    </div>
                </div>
                {/* )} */}
                {/* {(array.includes('TV') || array.includes('Phone')) && ( */}
                <div className="availableSpeedMainDivResponsive">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="availableSpeedTitle">TV Channels</div>
                            <div style={{ marginLeft: '5px' }}>
                                <img src={iIcon} width="15px" height="15px" />
                            </div>
                        </div>
                        <div className="availableSpeedContent">{props.channels ? props.channels : 'NA'}</div>
                    </div>
                </div>
                {/* )} */}

                <div className="availableSpeedMainDivResponsive">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div className="availableSpeedTitle">Contract Term</div>
                            <div style={{ marginLeft: '5px' }}>
                                <img src={iIcon} width="15px" height="15px" />
                            </div>
                        </div>
                        <div className="availableSpeedContent">{props.contractTerm ? props.contractTerm : 'NA'}</div>
                    </div>
                </div>

                <div className="pricingResponsive">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ color: '#6B6C6F', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>
                            {props.currency ? props.currency : ''}
                        </div>
                        <div
                            style={{
                                color: '#333333',
                                fontSize: '24px',
                                fontWeight: 800,
                                fontFamily: 'Poppins, sans-serif',
                            }}
                        >
                            {props.ammount ? props.ammount : 'NA'}
                        </div>
                        <div style={{ color: '#6B6C6F', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>
                            {props.planRate ? props.planRate : '00'}
                        </div>
                        <div style={{ color: '#6B6C6F', fontSize: '14px', marginTop: '15px' }}>
                            {props.planValidFor ? `/${props.planValidFor}` : ''}
                        </div>
                    </div>
                    {props.viewDetails === 'NoData' ? (
                        <div style={{ color: '#6B6C6F', textDecoration: 'underline', fontSize: '14px', margin: '5px' }}>
                            No Details Available
                        </div>
                    ) : (
                        <div
                            style={{ color: '#009cbd', textDecoration: 'underline', fontSize: '14px', margin: '10px' }}
                            onClick={props.viewDetails}
                        >
                            View Details
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlanCard;
