import React, { Component } from 'react';
import './Moving.scss';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
import Button from '../../components/atoms/Button';
import Play from '../../Assets/images/Moving/playBlue.svg';
import Playblue from '../../Assets/images/Moving/blueplay.svg';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GetAQuoteCard from '../../components/atoms/GetAQuoteCard';
import locationIcon from '../../Assets/images/MovingImages/location-icon.svg';
import addArrowIcon from '../../Assets/images/MovingImages/add-arrow-icon.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonNew from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MoveAction from '../../store/common/actions';
import 'react-datetime/css/react-datetime.css';
import * as Action from '../../store/move/actions';
import './Moving.scss';
import * as DashboardAction from '../../store/dashboard/actions';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

type initialProps = {
    checkedItems?: string[];
    sendRequest?: any;
    moveAction?: any;
    moveState?: any;
    moversAction?: any;
    moversState?: any;
    history?: any;
    location?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    moveFrom: string;
    moveTo: string;
    DateFrom: any;
    DateTo: any;
    name: string;
    phone: string;
    email: string;
    moveNumber: any;
    dateTime: any;
    checkedG: boolean;
    project: string;
    checkedItems: string[];
    buffer: boolean;
    addMovers: boolean;
    clicked: boolean;
    open: boolean;
    isDataArrived: boolean;
    confirmed: boolean;
    // dateTimeError: string;
    projectError: string;
    phoneError: string;
    request: boolean;
    isDataArrived2: boolean;
    isDataArrivedDash: boolean;
    serviceId: any;
    anchorEl: any;
    // user_ref:any;
    // moveId:any;
    // data:any
};

let validPhone: any = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

class AlmostDone extends Component<initialProps, initialState> {
    state: initialState = {
        DateFrom: '',
        DateTo: '',
        moveFrom: '',
        moveTo: '',
        moveNumber: '',
        name: '',
        phone: '',
        email: '',
        dateTime: null,
        checkedG: false,
        buffer: false,
        project: '',
        checkedItems: [],
        addMovers: false,
        clicked: false,
        open: false,
        isDataArrived: true,
        isDataArrived2: true,
        isDataArrivedDash: true,
        confirmed: false,
        // dateTimeError: '',
        projectError: '',
        request: false,
        serviceId: '',
        anchorEl: null,
        phoneError: '',
        // user_ref:'',
        // moveId:'',
        // data:''
    };

    componentDidMount() {
        if (
            !(
                this.props.moveState &&
                this.props.moveState.move &&
                this.props.moveState.move[0] &&
                this.props.moveState.move[0].user_ref
            )
        ) {
            this.props.moveAction.moveGet();
        }
        this.props.dashboardAction.checklist();

        window.addEventListener('popstate', () => {
            this.props.history.push({
                pathname: '/dashboard/movers/recommended',
                state: {
                    checkedItemsAlmost: this.state.checkedItems,
                    service_name:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.serviceName,
                    id:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.serviceId,
                    homeAdvisorData:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.homeAdvisorData,
                },
            });
        });
    }

    format = (inputDate: any) => {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        }
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.moveState && nextProps.moveState.move && currentState.isDataArrived) {
            nextProps.moveState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.moveFrom = item.source_detail ? item.source_detail : '';
                currentState.moveTo = item.destination_detail ? item.destination_detail : '';
                currentState.DateFrom = item.moving_date ? item.moving_date : '';
                currentState.DateTo = item.moving_date ? item.moving_date : '';
                currentState.moveNumber = item.destination_sq_foot ? item.destination_sq_foot : '';
                currentState.name =
                    item.user_ref && (item.user_ref.first_name || item.user_ref.last_name)
                        ? item.user_ref.first_name + ' ' + item.user_ref.last_name
                        : '';
                currentState.email = item.user_ref && item.user_ref.email ? item.user_ref.email : '';
                currentState.phone = item.user_ref && item.user_ref.phone ? item.user_ref.phone : '';
                // currentState.user_ref=  item.user_ref ?  item.user_ref : '';
                // currentState.moveId = item.id ? item.id : '';
                // currentState.data=item ? item :''
            });
        }

        if (nextProps && nextProps.moversState && nextProps.moversState.postDataArrived) {
            nextProps.moversState.postDataArrived = false;
            // nextProps.sendRequest(currentState.request)
            nextProps.history.push({
                pathname: '/dashboard/movers/thankyou',
                state: {
                    checkedItems: currentState.checkedItems,
                },
            });
            window.scrollTo(0, 0);

            nextProps.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 100,
                },
                service_id: currentState.serviceId,
            });
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrivedDash
        ) {
            currentState.isDataArrivedDash = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.serviceId = item.id;
                }
            });
        }
        return currentState;
    }

    handleConfirm = () => {
        this.setState({ confirmed: true });
    };

    dateTimeHandler = async (date: any) => {
        await this.setState({ dateTime: date });
    };

    handleAddMovers = () => {
        if (this.state.checkedItems.length <= 2) {
            this.setState({ addMovers: true });
        } else {
            this.setState({ open: true });
        }
    };

    handleProject = (e: any) => {
        this.setState({ project: e.target.value });
    };

    dateHandler = (e: any) => {
        this.setState({
            dateTime: e.toISOString().split('T')[0],
            anchorEl: null,
        });
    };

    handleClickDate = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    validateFields = () => {
        let validity: Boolean = true;

        if (this.state.phone === '' || !validPhone.test(this.state.phone)) {
            this.setState({ phoneError: 'Please Enter a Valid Phone Number' });
            validity = false;
        } else {
            this.setState({ phoneError: '' });
        }

        // if (this.state.project === '') {
        //     this.setState({ projectError: 'Please Enter Project Description' });
        //     validity = false;
        // } else {
        //     this.setState({ projectError: '' });
        // }

        return validity;
    };

    handleRequest = () => {
        if (this.validateFields()) {
            let idArray: string[] = [];
            this.state.checkedItems.map((item: any) => {
                idArray.push(item.id);
            });
            this.setState({ request: true });
            const payload: any = {
                selected_movers: idArray,
                is_flexible: this.state.checkedG,
                appointment_notes: this.state.project,
                date_time: this.state.dateTime,
                appointment_dates: [],
            };
            this.props.moversAction.Postmovers(payload);
        }
    };

    handleCloseModal = () => {
        this.setState({ open: false });
    };

    handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await this.setState({ checkedG: event.target.checked });
    };

    handleDeleteItem = async (item: any) => {
        let checkedItemsNext: string[] = this.state.checkedItems;
        checkedItemsNext = checkedItemsNext.filter((item2: any) => item2 != item);
        await this.setState({
            checkedItems: checkedItemsNext,
        });

        if (this.state.checkedItems.length === 0) {
            this.props.history.push('/dashboard/movers/recommended');
            window.scrollTo(0, 0);
        }
    };

    setValue = (checkedItems: any) => {
        this.setState({ checkedItems: checkedItems, buffer: true });
    };

    handleClick = () => {
        this.setState({ clicked: true, addMovers: false });
    };

    handleClick2 = (item: any) => {
        this.setState({ checkedItems: item });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    handleChangePhone = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({ phone: phonenumber });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.checkedItems &&
            this.props.location.state.checkedItems.length &&
            this.state.buffer === false
        ) {
            this.setValue(this.props.location.state.checkedItems);
        }

        if (this.state.addMovers === true) {
            this.props.history.push({
                pathname: '/dashboard/movers/recommended',
                state: {
                    checkedItemsAlmost: this.state.checkedItems,
                    service_name:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.serviceName,
                    id:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.serviceId,
                    homeAdvisorData:
                        this.props &&
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.homeAdvisorData,
                },
            });
            window.scrollTo(0, 0);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <React.Fragment>
                <div className={isDouglasElliman ? 'done-main done-mainDouglas' : 'done-main'}>
                    <Dialog
                        className="done-alert"
                        open={this.state.open}
                        onClose={this.handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {'Oops. Only Three Movers are Allowed for Selection'}
                        </DialogTitle>

                        <DialogActions>
                            <ButtonNew
                                onClick={this.handleCloseModal}
                                // color="#ffffff"
                            >
                                Ok
                            </ButtonNew>
                        </DialogActions>
                    </Dialog>
                    <div>
                        <h2 className="heading">Moving Services</h2>
                        <div className="path">
                            <p
                                className="Para"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (
                                        localStorage.getItem('isHomeOwner') &&
                                        localStorage.getItem('isHomeOwner') == 'true'
                                    ) {
                                        this.props.history.push('/dashboardhomeOwner');
                                    } else {
                                        this.props.history.push('/dashboard');
                                    }
                                }}
                            >
                                Dashboard {'> '}{' '}
                            </p>{' '}
                            <p
                                className="Para"
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                                onClick={() => {
                                    this.props.history.push('/dashboard/movers');
                                }}
                            >
                                Moving Services {'> '}
                            </p>
                            <p style={{ marginLeft: '5px' }}> Order Placing</p>
                        </div>
                    </div>
                    <div className="quote-div-mobile">
                        <GetAQuoteCard
                            className="selected-movers-count1"
                            cardImage={addArrowIcon}
                            width="98%"
                            text1="Select multiple movers"
                            text2="get a quote"
                            selectedMovers={this.state.checkedItems.length}
                            totalMover={3}
                            selectedMover={true}
                            clearClicked={() => {}}
                            addMore={true}
                            noQuote={true}
                            addMovers={() => {
                                this.handleAddMovers();
                            }}
                            features={this.state.checkedItems.map((item: any) => {
                                return {
                                    name: item.name,
                                    icon: item.van_line_logo,
                                    locationIcon: locationIcon,
                                    locationText: item.address,
                                    deleteClicked: () => {
                                        this.handleDeleteItem(item);
                                    },
                                };
                            })}
                        />
                    </div>
                    <div className="done-heading">Great, you are almost done.</div>
                    <div className="done-para">
                        Let's confirm your move details and schedule a call with your selected movers{' '}
                        <span style={{ marginLeft: '10px', marginTop: '4px' }}>
                            <img src={iIcon} width="15px" height="15px" />
                        </span>
                    </div>
                    <div className="main-outline">
                        <div className="move-cards-div">
                            <div className={this.state.confirmed === false ? 'moving-card' : 'moving-card-2'}>
                                <div
                                    className={
                                        this.state.confirmed === false ? 'moving-card-header' : 'moving-card-header-2'
                                    }
                                    style={
                                        this.state.confirmed === false
                                            ? {
                                                  background: isDouglasElliman
                                                      ? '#100b28'
                                                      : slug && slug.primary_color
                                                      ? `${slug.primary_color}`
                                                      : '#273E59',
                                                  color: '#FFF',
                                              }
                                            : {
                                                  background: '#FFF',
                                                  color: isDouglasElliman
                                                      ? '#100b28'
                                                      : slug && slug.primary_color
                                                      ? `${slug.primary_color}`
                                                      : '#273E59',
                                              }
                                    }
                                >
                                    <div className="card-number-div">
                                        <div
                                            className={this.state.confirmed === false ? 'number' : 'number-2'}
                                            style={
                                                this.state.confirmed === false
                                                    ? {
                                                          background: '#fff',
                                                          color: isDouglasElliman
                                                              ? '#100b28'
                                                              : slug && slug.primary_color
                                                              ? `${slug.primary_color}`
                                                              : '#273E59',
                                                      }
                                                    : {
                                                          background: isDouglasElliman
                                                              ? '#100b28'
                                                              : slug && slug.primary_color
                                                              ? `${slug.primary_color}`
                                                              : '#273E59',
                                                          color: '#fff',
                                                      }
                                            }
                                        >
                                            <span className="num">1</span>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            this.state.confirmed === false
                                                ? 'moving-card-heading'
                                                : 'moving-card-heading-2'
                                        }
                                        style={
                                            this.state.confirmed === false
                                                ? {
                                                      color: '#FFF',
                                                  }
                                                : {
                                                      color: isDouglasElliman
                                                          ? '#100b28'
                                                          : slug && slug.primary_color
                                                          ? `${slug.primary_color}`
                                                          : '#273E59',
                                                  }
                                        }
                                    >
                                        Moving Details
                                    </div>
                                </div>
                                {/* <div className="conditional-edit-button">
                     <div className="conditional-edit">
                     <Button
                                  width="80px"
                                  height="30px"
                                    onClick={()=>{}}
                                    backgroundColor="#FFFFFF"
                                    boxShadow="0 0 3px 0 #273E59"
                                    color="#273E59"
                                    borderRadius="5px"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Edit
                                </Button>
                     </div>
                      </div> */}
                                <div className={this.state.confirmed ? 'move-inputs-after' : 'move-inputs'}>
                                    <div className="input-left">
                                        <div className="input-move-1">
                                            <TextField
                                                className={
                                                    isDouglasElliman ? 'call-input-moving-after' : 'call-input-moving'
                                                }
                                                label="Date From"
                                                id="full-width-text-field"
                                                variant="filled"
                                                value={
                                                    this.state.DateFrom != '' ? this.format(this.state.DateFrom) : ''
                                                }
                                                fullWidth={true}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={this.state.DateFrom !== ''}
                                            />
                                        </div>
                                        <div className="input-move-1">
                                            <TextField
                                                className={
                                                    isDouglasElliman ? 'call-input-moving-after' : 'call-input-moving'
                                                }
                                                label="Move From"
                                                id="full-width-text-field"
                                                variant="filled"
                                                value={this.state.moveFrom}
                                                fullWidth={true}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={this.state.moveFrom !== ''}
                                            />
                                        </div>

                                        <div className="input-move-2">
                                            <TextField
                                                className={
                                                    isDouglasElliman ? 'call-input-moving-after' : 'call-input-moving'
                                                }
                                                label="Move Size"
                                                id="full-width-text-field"
                                                variant="filled"
                                                value={this.state.moveNumber}
                                                fullWidth={true}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={this.state.moveNumber !== ''}
                                            />
                                        </div>
                                    </div>
                                    <div className={this.state.confirmed ? 'arrow-2' : 'arrow'}>
                                        {isDouglasElliman ? (
                                            <img src={Play} height="20px" width="20px" />
                                        ) : (
                                            <img src={Playblue} height="20px" width="20px" />
                                        )}
                                    </div>
                                    <div className="input-right">
                                        <div className="input-move-1">
                                            <TextField
                                                className={
                                                    isDouglasElliman ? 'call-input-moving-after' : 'call-input-moving'
                                                }
                                                label="Date To"
                                                id="full-width-text-field"
                                                variant="filled"
                                                value={this.state.DateTo != '' ? this.format(this.state.DateTo) : ''}
                                                fullWidth={true}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={this.state.DateTo !== ''}
                                            />
                                        </div>
                                        <div className="input-move-1">
                                            <TextField
                                                className={
                                                    isDouglasElliman ? 'call-input-moving-after' : 'call-input-moving'
                                                }
                                                label="Move To"
                                                id="full-width-text-field"
                                                variant="filled"
                                                value={this.state.moveTo}
                                                fullWidth={true}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={this.state.moveTo !== ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.state.confirmed === false ? (
                                    <div className="move-buttons">
                                        <div className="move-button">
                                            <Button
                                                width="100px"
                                                height="50px"
                                                onClick={() => {
                                                    this.props.history.push('/dashboard/movers/recommended');
                                                    window.scrollTo(0, 0);
                                                }}
                                                backgroundColor="#FFFFFF"
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                border={
                                                    isDouglasElliman
                                                        ? '1px solid #100b28'
                                                        : slug && slug.primary_color
                                                        ? `1px solid ${slug.primary_color}`
                                                        : '1px solid #273E59'
                                                }
                                                color={
                                                    isDouglasElliman
                                                        ? '#100b28'
                                                        : slug && slug.primary_color
                                                        ? `${slug.primary_color}`
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="14px"
                                                fontWeight="600"
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div className="move-button">
                                            <Button
                                                width="150px"
                                                height="50px"
                                                onClick={() => {
                                                    this.handleConfirm();
                                                }}
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100b28'
                                                        : slug && slug.primary_color
                                                        ? `${slug.primary_color}`
                                                        : '#273E59'
                                                }
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                // color="#fff"
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="14px"
                                                fontWeight="600"
                                            >
                                                Confirm Details
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="call-card">
                                <div
                                    className={
                                        this.state.confirmed === false ? 'moving-card-header-2' : 'call-card-header'
                                    }
                                    style={
                                        this.state.confirmed === false
                                            ? {
                                                  background: '#FFF',
                                                  color: isDouglasElliman
                                                      ? '#100b28'
                                                      : slug && slug.primary_color
                                                      ? `${slug.primary_color}`
                                                      : '#273E59',
                                              }
                                            : {
                                                  background: isDouglasElliman
                                                      ? '#100b28'
                                                      : slug && slug.primary_color
                                                      ? `${slug.primary_color}`
                                                      : '#273E59',
                                                  color: '#FFF',
                                              }
                                    }
                                >
                                    <div className="card-number-div">
                                        <div
                                            className={this.state.confirmed === false ? 'number-2' : 'number'}
                                            style={
                                                this.state.confirmed === false
                                                    ? {
                                                          background: isDouglasElliman
                                                              ? '#100b28'
                                                              : slug && slug.primary_color
                                                              ? `${slug.primary_color}`
                                                              : '#273E59',
                                                          color: '#FFF',
                                                      }
                                                    : {
                                                          background: '#FFF',
                                                          color: isDouglasElliman
                                                              ? '#100b28'
                                                              : slug && slug.primary_color
                                                              ? `${slug.primary_color}`
                                                              : '#273E59',
                                                      }
                                            }
                                        >
                                            <span className="num"> 2</span>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            this.state.confirmed === false
                                                ? 'moving-card-heading-2'
                                                : 'moving-card-heading'
                                        }
                                        style={
                                            this.state.confirmed === false
                                                ? {
                                                      color: isDouglasElliman
                                                          ? '#100b28'
                                                          : slug && slug.primary_color
                                                          ? `${slug.primary_color}`
                                                          : '#273E59',
                                                  }
                                                : {
                                                      color: '#FFF',
                                                  }
                                        }
                                    >
                                        Schedule A Call
                                    </div>
                                </div>
                                {this.state.confirmed === true ? (
                                    <div style={{ padding: '0px 10px' }}>
                                        <div
                                            style={{
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                margin: '20px 10px 20px 10px',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="personal-details-1">
                                            <div className="moving-confirmationCard">
                                                <TextField
                                                    className={
                                                        isDouglasElliman
                                                            ? 'call-input-moving-2'
                                                            : 'call-input-moving-noDoug'
                                                    }
                                                    label="Full Name"
                                                    id="full-width-text-field"
                                                    value={this.state.name}
                                                    fullWidth={true}
                                                    InputProps={{ disableUnderline: true }}
                                                    disabled={this.state.name !== ''}
                                                    variant="filled"
                                                />
                                            </div>
                                            <div className="moving-confirmationCard">
                                                <TextField
                                                    className={
                                                        isDouglasElliman
                                                            ? 'call-input-moving-2'
                                                            : 'call-input-moving-noDoug'
                                                    }
                                                    label="Phone Number"
                                                    id="full-width-text-field"
                                                    onChange={(e: any) => {
                                                        this.handleChangePhone(e);
                                                        // this.setState({
                                                        //     phone: e.target.value,
                                                        // });
                                                    }}
                                                    value={this.state.phone}
                                                    fullWidth={true}
                                                    InputProps={{ disableUnderline: true }}
                                                    variant="filled"
                                                />
                                                <div style={{ height: '20px', color: 'red', marginTop: '10px' }}>
                                                    {''}
                                                    <small>
                                                        {this.state.phone == '' || !validPhone.test(this.state.phone)
                                                            ? this.state.phoneError
                                                            : null}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="moving-confirmationCard">
                                                <TextField
                                                    className={
                                                        isDouglasElliman
                                                            ? 'call-input-moving-2'
                                                            : 'call-input-moving-noDoug'
                                                    }
                                                    label="Email Id"
                                                    id="full-width-text-field"
                                                    value={this.state.email}
                                                    InputProps={{ disableUnderline: true }}
                                                    fullWidth={true}
                                                    disabled={this.state.email !== ''}
                                                    variant="filled"
                                                />
                                            </div>
                                        </div>
                                        {/* <div
                                            style={{
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                margin: '20px 10px 10px 10px',
                                                // height: '40px',
                                            }}
                                        >
                                            Schedule A Call
                                        </div> */}
                                        <div className="date-check">
                                            {/* <div className="date-check-inputDiv"> */}
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disablePast
                                                        className="MovingDateTime"
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="Next appointment"
                                                        value={this.state.dateTime}
                                                        onChange={(e: any) => {
                                                            this.dateHandler(e);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            onFocus: e => {
                                                                this.handleClickDate(e);
                                                            },
                                                        }}
                                                        autoOk={true}
                                                        PopoverProps={{
                                                            disableRestoreFocus: true,
                                                            onClose: () => {
                                                                this.setState({ anchorEl: null });
                                                            },
                                                            anchorEl: this.state.anchorEl,
                                                        }}
                                                        InputProps={{
                                                            onFocus: e => {
                                                                this.handleClickDate(e);
                                                            },
                                                        }}
                                                        open={Boolean(this.state.anchorEl)}
                                                        // error={Boolean(this.state.dateTimeError)}
                                                    />
                                                </MuiPickersUtilsProvider> */}
                                            {/* <div
                                                    style={{
                                                        height: '20px',
                                                        color: 'red',
                                                        marginTop: '10px',
                                                        marginLeft: '20px',
                                                    }}
                                                >
                                                    {''}
                                                    <small>{this.state.dateTimeError}</small>
                                                </div> */}
                                            {/* </div> */}

                                            <div className="checkbox-done">
                                                <FormControlLabel
                                                    label="I am flexible on time"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            className="checkbox"
                                                            checked={this.state.checkedG}
                                                            onChange={this.handleChange}
                                                            name="checkedG"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="moving-textarea">
                                            {/* What else we should know about the project? */}
                                        </div>
                                        <div className="moving-textarea-textarea">
                                            <textarea
                                                cols={60}
                                                rows={8}
                                                onChange={e => {
                                                    this.handleProject(e);
                                                }}
                                                value={this.state.project}
                                                className="text-text"
                                            />
                                            {/* <div style={{ height: '20px', color: 'red',marginTop:"10px",marginLeft:"20px"  }}>
                                                 {''}
                                                 <small>{this.state.projectError}</small>
                                             </div>    */}
                                        </div>

                                        <div className="move-buttons">
                                            <div className="move-button">
                                                <Button
                                                    width="200px"
                                                    height="50px"
                                                    onClick={() => {
                                                        this.handleRequest();
                                                    }}
                                                    backgroundColor={
                                                        isDouglasElliman
                                                            ? '#100b28'
                                                            : slug && slug.primary_color
                                                            ? `${slug.primary_color}`
                                                            : '#273E59'
                                                    }
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : slug && slug.primary_color
                                                            ? `0 0 3px 0 ${slug.primary_color}`
                                                            : '0 0 3px 0 #273E59'
                                                    }
                                                    // color="#fff"
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="16px"
                                                >
                                                    Request Appointment
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="quote-div">
                            <GetAQuoteCard
                                className="selected-movers-count1"
                                cardImage={addArrowIcon}
                                width="98%"
                                text1="Select multiple movers"
                                text2="get a quote"
                                selectedMovers={this.state.checkedItems.length}
                                totalMover={3}
                                selectedMover={true}
                                clearClicked={() => {}}
                                addMore={true}
                                noQuote={true}
                                addMovers={() => {
                                    this.handleAddMovers();
                                }}
                                features={this.state.checkedItems.map((item: any) => {
                                    return {
                                        name: item.name,
                                        icon: item.van_line_logo,
                                        locationIcon: locationIcon,
                                        locationText: item.address,
                                        deleteClicked: () => {
                                            this.handleDeleteItem(item);
                                        },
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moveAction: bindActionCreators(MoveAction, dispatch),
        moversAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moveState: state.common,
    moversState: state.move,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlmostDone);
