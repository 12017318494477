import React, { Component } from 'react';
import './standAlonePageRecommendation.scss';
import TrustedProsStandAloneCard from '../../components/atoms/TrustedProsStandAloneCard';
import RatingIcon from '../../Assets/images/MovingImages/rating.svg';
import callIcon from '../../Assets/images/MovingImages/call.svg';
import locationIcon from '../../Assets/images/MovingImages/location-icon.svg';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GetAQuoteCard from '../../components/atoms/GetAQuoteCard';
import addArrowIcon from '../../Assets/images/MovingImages/add-arrow-icon.svg';
import PrintModal from '../../components/atoms/Modal';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '../../components/atoms/Button';
import VendorNameCheckboxCard from '../../components/atoms/VendorNameCheckboxCard';
import RequestVendors from '../../Assets/images/TrustedPros/vendorRequest.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import serverAddress from '../../config';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as CommonAction from '../../store/common/actions';

type initialProps = {
    history?: any;
    trustedProsAction: any;
    trustedProsState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {
    vendors: any;
    checkBoxes: string[];
    checkedItems: any[];
    serviceName: string;
    serviceId: string;
    modalOpen: boolean;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    date: any;
    isSubmitClicked: boolean;
    project: string;
    isVendorClicked: boolean;
    requestSubmitted: boolean;
    site_name: any;
};

class StandAloneRecommendedPage extends Component<initialProps, initialState> {
    state: initialState = {
        vendors: [],
        checkBoxes: [],
        checkedItems: [],
        serviceName: '',
        serviceId: '',
        modalOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        date: null,
        isSubmitClicked: false,
        project: '',
        isVendorClicked: false,
        requestSubmitted: false,
        site_name: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.selectedService &&
            this.props.history.location.state.service_name &&
            this.props.history.location.state.site_name &&
            this.props.history.location.state.id
        ) {
            let vendors = this.props.history.location.state.selectedService.vendors;
            this.setState({
                vendors: vendors,
                serviceId: this.props.history.location.state.id,
                serviceName: this.props.history.location.state.service_name,
                site_name: this.props.history.location.state.site_name,
            });
        }

        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.trustedProsState && nextProps.trustedProsState.standAloneRequestVendorsPostedFlag) {
            nextProps.trustedProsState.standAloneRequestVendorsPostedFlag = false;
            currentState.modalOpen = false;
            currentState.requestSubmitted = true;
        }

        if (nextProps && nextProps.trustedProsState && nextProps.trustedProsState.standAlonetVendorsPostedFlag) {
            nextProps.trustedProsState.standAlonetVendorsPostedFlag = false;
            currentState.modalOpen = false;
            currentState.checkedItems = [];
            currentState.checkBoxes = [];

            nextProps.history.push({
                pathname: '/standalonefinal',
                state: {
                    serviceName: currentState.serviceName,
                },
            });
        }

        return currentState;
    }
    firstNameHandler = (e: any) => {
        let name: string = e.target.value.replace(/\d/g, '');
        name = name.replace(/[\W_]/g, '');
        this.setState({
            firstName: name,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        let name: string = e.target.value.replace(/\d/g, '');
        name = name.replace(/[\W_]/g, '');
        this.setState({
            lastName: name,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };
    phoneHandler = (e: any) => {
        let phone: any = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };
    handleProject = (e: any) => {
        this.setState({ project: e.target.value });
    };

    submitButtonClicked = () => {
        this.setState({
            isSubmitClicked: true,
        });
        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) &&
            this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
        ) {
            let data: any = [];
            this.state.checkedItems.map((item: any) => {
                data.push({
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    phone: this.state.phone,
                    email: this.state.email,
                    service_date:
                        new Date(this.state.date) &&
                        new Date(this.state.date).toISOString() &&
                        new Date(this.state.date).toISOString().split('T')[0]
                            ? new Date(this.state.date).toISOString().split('T')[0]
                            : '',
                    meta: {},
                    new_vendor: item.id,
                });
            });

            const payload = {
                data: data,
                site_name: serverAddress.includes('conciergeapidemo') ? 'moveeasy-demo' : this.state.site_name,
                description: this.state.project,
            };

            this.props.trustedProsAction.postStandAloneVendors(payload);
        }
    };

    handleSubmitRequest = () => {
        this.setState({ modalOpen: true, isVendorClicked: true });
    };
    submitJobInfo = () => {
        this.setState({
            isSubmitClicked: true,
        });

        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) &&
            this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
        ) {
            const payload = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                phone_number: this.state.phone,
                email_address: this.state.email,
                description: this.state.project,
                category_name: this.state.serviceName,
                site_name: serverAddress.includes('conciergeapidemo') ? 'moveeasy-demo' : this.state.site_name,
            };

            this.props.trustedProsAction.postStandAloneRequestVendors(payload);
        }
    };

    handleCheckBoxes = (name: string, item: any) => {
        let checkBoxesNew: string[] = this.state.checkBoxes;
        let checkedItemsNew: string[] = this.state.checkedItems;
        if (!this.state.checkBoxes.includes(name)) {
            checkBoxesNew.push(name);
            checkedItemsNew.push(item);
            this.setState({
                checkBoxes: checkBoxesNew,
                checkedItems: checkedItemsNew,
            });
        } else {
            checkBoxesNew = this.state.checkBoxes.filter(item => item != name);
            checkedItemsNew = this.state.checkedItems.filter((item2: any) => item2.name != item.name);
            this.setState({
                checkBoxes: checkBoxesNew,
                checkedItems: checkedItemsNew,
            });
        }
    };

    handleQuote = async () => {
        this.setState({
            modalOpen: true,
        });
    };

    handleDeleteItem = (name: string, item: any) => {
        let checkBoxesNext: string[] = this.state.checkBoxes;
        let checkedItemsNext: string[] = this.state.checkedItems;
        checkBoxesNext = this.state.checkBoxes.filter(item => item != name);
        checkedItemsNext = this.state.checkedItems.filter(item2 => item2 != item);
        this.setState({
            checkBoxes: checkBoxesNext,
            checkedItems: checkedItemsNext,
        });
    };

    clearClickedHandler = () => {
        this.setState({
            checkBoxes: [],
            checkedItems: [],
        });
    };

    handleBackRequest = () => {
        this.props.history.push('/standalonepage');
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    render() {
        return (
            <div>
                {this.state.modalOpen ? (
                    <PrintModal
                        isShowModal={this.state.modalOpen}
                        onCloseModal={() => this.setState({ modalOpen: false })}
                        showClose={true}
                        className="modalAppointment"
                        // style={{
                        //     position: 'absolute',
                        //     width: '700px',
                        //     // height: '650px',
                        //     backgroundColor: '#fff',
                        //     left: '25%',
                        //     top: '5%',
                        // }}
                    >
                        <div className="appointment-heading">
                            {this.state.isVendorClicked ? 'TELL US ABOUT YOUR PROJECT' : 'COMPLETE YOUR APPOINTMENT'}
                        </div>

                        <div className="inputs-div">
                            <div className="left-input-div">
                                <div className="div-1-name">
                                    <TextField
                                        id="time"
                                        label="First Name"
                                        value={this.state.firstName}
                                        onChange={(e: any) => this.firstNameHandler(e)}
                                        type="text"
                                        variant="standard"
                                        // className="date11"
                                        InputProps={{
                                            startAdornment: <PersonIcon />,
                                        }}
                                        inputProps={{ pattern: '[a-z]' }}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.firstName === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.firstName === ''
                                            ? 'Please enter first name'
                                            : ''}
                                    </div>
                                </div>
                                <div className="div-1-name">
                                    <TextField
                                        label="Last Name"
                                        value={this.state.lastName}
                                        onChange={(e: any) => this.lastNameHandler(e)}
                                        type="text"
                                        variant="standard"
                                        // className="date11"
                                        // disabled
                                        InputProps={{
                                            startAdornment: <PersonIcon />,
                                        }}
                                        error={this.state.isSubmitClicked && this.state.lastName === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.lastName === ''
                                            ? 'Please enter last name'
                                            : ''}
                                    </div>
                                </div>
                                <div className="div-1-name">
                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="standard"
                                        // className="date11"
                                        // disabled
                                        InputProps={{
                                            startAdornment: <EmailIcon />,
                                        }}
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked &&
                                        (this.state.email === '' ||
                                            !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'))
                                            ? 'Please enter Valid Email'
                                            : ''}
                                    </div>
                                </div>
                                <div className="div-1-name">
                                    <TextField
                                        id="time"
                                        label="Phone"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="standard"
                                        InputProps={{
                                            startAdornment: <PhoneIcon />,
                                        }}
                                        // className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked &&
                                        (this.state.phone === '' ||
                                            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                this.state.phone,
                                            ))
                                            ? 'Please enter Valid Phone Number'
                                            : ''}
                                    </div>
                                </div>
                                {this.state.isVendorClicked ? null : (
                                    <div className="div-1-name">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disablePast
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date"
                                                inputVariant="standard"
                                                label="I need this service"
                                                // className="date11"
                                                value={this.state.date ? new Date(this.state.date) : null}
                                                onChange={(e: any) => this.dateHandler(e)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                PopoverProps={{
                                                    style: { zIndex: 1000000 },
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                autoOk={true}
                                                error={this.state.isSubmitClicked && !this.state.date}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                            {this.state.isSubmitClicked && !this.state.date && 'Please select date'}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="right-input-div">
                                <div className="standalone-textarea">What else we should know about the project?</div>
                                <div className="standalone-textarea-textarea">
                                    <textarea
                                        cols={60}
                                        rows={8}
                                        onChange={e => {
                                            this.handleProject(e);
                                        }}
                                        value={this.state.project}
                                        className="text-text"
                                    />
                                    {/* <div style={{ height: '20px', color: 'red',marginTop:"10px",marginLeft:"20px"  }}>
                                            {''}
                                            <small>{this.state.projectError}</small>
                                        </div>    */}
                                </div>

                                <div className="selected-vendors">
                                    {this.state.checkedItems.map((item: any) => {
                                        return (
                                            <VendorNameCheckboxCard
                                                checked={this.state.checkBoxes.includes(item.name)}
                                                cardTitle={item.name}
                                                //    subHeading="Get additional quotes even if you have home insurance to make sure you’re getting the best rate."

                                                handleChange={() => {
                                                    this.handleCheckBoxes(item.name, item);
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        {this.state.isVendorClicked ? (
                            <div className="buttons-div">
                                <Button
                                    backgroundColor={'#FFFFFF'}
                                    color={'#000000'}
                                    borderRadius="5px"
                                    fontSize="16px"
                                    border={`1px solid ${'#273e59'}`}
                                    onClick={() => this.setState({ isVendorClicked: false, modalOpen: false })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    backgroundColor={'#273e59'}
                                    borderRadius="5px"
                                    fontSize="16px"
                                    color="#FFFFFF"
                                    border={`1px solid ${'#273e59'}`}
                                    onClick={() => this.submitJobInfo()}
                                    className="submitJobInfo"
                                >
                                    Submit Job Info
                                </Button>
                            </div>
                        ) : (
                            <div className="buttons-div">
                                <Button
                                    backgroundColor={'#273e59'}
                                    borderRadius="5px"
                                    fontSize="16px"
                                    color="#ffffff"
                                    border={`1px solid ${'#273e59'}`}
                                    onClick={() => this.submitButtonClicked()}
                                    disabled={!this.state.checkedItems.length}
                                >
                                    SUBMIT
                                </Button>
                            </div>
                        )}
                    </PrintModal>
                ) : null}

                {this.state.vendors.length ? (
                    <div className="standAlone-recommended-main-page">
                        <div className="left-recommendation">
                            <div className="vendors-heading">
                                {this.state.serviceName}({this.state.vendors.length})
                            </div>
                            {this.state.vendors.map((item: any) => {
                                return (
                                    <TrustedProsStandAloneCard
                                        checked={this.state.checkBoxes.includes(item.name)}
                                        cardTitle={item.name}
                                        //    subHeading="Get additional quotes even if you have home insurance to make sure you’re getting the best rate."

                                        handleChange={() => {
                                            this.handleCheckBoxes(item.name, item);
                                        }}
                                        rate={item.google_rating + item.yelp_rating}
                                        ratingIcon={RatingIcon}
                                        phoneIcon={callIcon}
                                        emailIcon={
                                            <MailOutlineIcon
                                                style={{ height: '20px', width: '20px', opacity: '0.7' }}
                                            />
                                        }
                                        email={item.email}
                                        phoneNumber={item.phone_no}
                                        locationIcon={locationIcon}
                                        locationtext={item.address}
                                        logoInitials={
                                            item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1)
                                        }
                                        imageBackGround="#FFF0C8"
                                        homeAdvisor={false}
                                        representation={item.representation}
                                        brokerage_image={
                                            this.props.commonState &&
                                            this.props.commonState.move &&
                                            this.props.commonState.move[0] &&
                                            this.props.commonState.move[0].realtor &&
                                            this.props.commonState.move[0].realtor.logo
                                        }
                                    />
                                );
                            })}
                        </div>

                        <div className="right-recommendation">
                            <div className="vendors-heading">
                                {/* Selected Vendors({this.state.checkedItems.length}) */}
                            </div>
                            <div className="get-quote-card">
                                <GetAQuoteCard
                                    cardImage={addArrowIcon}
                                    width="98%"
                                    text1="Select multiple vendors"
                                    text2="get a quote"
                                    vendors={true}
                                    selectedMovers={this.state.checkedItems.length}
                                    selectedMover={true}
                                    clearClicked={() => {
                                        this.clearClickedHandler();
                                    }}
                                    features={this.state.checkedItems.map((item: any) => {
                                        return {
                                            name: item.name,
                                            logoInitials:
                                                item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1),
                                            icon: item.van_line_logo,
                                            locationIcon: locationIcon,
                                            locationText: item.address,
                                            deleteClicked: () => {
                                                this.handleDeleteItem(item.name, item);
                                            },
                                        };
                                    })}
                                    onClickedHandle={() => this.handleQuote()}
                                    addMore={false}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="standAlone-recommended-no-vendors-page">
                        <div className="name-arrow-wrapper">
                            <div className="back-arrow" style={{ cursor: 'pointer' }}>
                                <ArrowBackIcon
                                    onClick={() => {
                                        this.props.history.push('/standalonepage');
                                    }}
                                />
                            </div>
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.serviceName}</div>
                        </div>
                        <div className="NoVendorsCenterDiv">
                            <div className="vendorsImageDiv">
                                <img src={RequestVendors} />
                            </div>

                            <div className="trustedProsCarpenterRow">
                                {/* <img src={NoVendorImage} /> */}
                                <div className="trustedProsCarpenterDesc1">
                                    Seems like we’ll need to do some more research here…
                                </div>
                                <div className="trustedProsCarpenterDesc2">
                                    Click the button below and our research team will research and get back to you.
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        onClick={this.handleSubmitRequest}
                                        disabled={this.state.requestSubmitted}
                                        // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                    >
                                        {this.state.requestSubmitted ? 'Request Submitted' : '   Request Vendors'}
                                    </Button>
                                    {this.state.requestSubmitted ? (
                                        <Button onClick={this.handleBackRequest}>Search Pros</Button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    trustedProsState: state.trustedPros,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(StandAloneRecommendedPage);
