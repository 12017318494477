import React from 'react';
import './ReserveStorageThankYou.scss';

type InitialProps = {
    location?: any;
    history?: any;
};

type InitialState = {};

class ReserveStorageThankYou extends React.Component<InitialProps, InitialState> {
    render() {
        return (
            <div className="ReserveStorageThankyouPageMainDiv">
                <div className="main-head-moving-order">
                    <h2 className="heading">Moving Services</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/movers');
                            }}
                        >
                            {' '}
                            Moving Services {'>'}{' '}
                        </p>{' '}
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/storage');
                            }}
                        >
                            {' '}
                            Reserve a Storage Unit {'>'}{' '}
                        </p>{' '}
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/dashboard/storage/${this.props.history.location.state.facilityId}`,
                                    state: {
                                        facilityId: this.props.history.location.state.facilityId,
                                        facilityName: this.props.history.location.state.name,
                                        facilityAddress: this.props.history.location.state.address,
                                        facilityImage: this.props.history.location.state.facilityImage,
                                    },
                                });
                            }}
                        >
                            {' '}
                            Tri-Village Self Storage {'>'}{' '}
                        </p>{' '}
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/dashboard/movers/reserve_order_place`,
                                    state: this.props.history.location.state,
                                });
                            }}
                        >
                            {' '}
                            Order Placing {'>'}{' '}
                        </p>{' '}
                        <p>Order Summary</p>
                    </div>
                </div>
                <div className="tab-div-moving">
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            (this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                ? 'tab-active'
                                : 'tab-text'
                        }
                        onClick={() => {
                            this.props.history.push('/dashboard/movers');
                            window.scrollTo(0, 0);
                        }}
                    >
                        Moving
                    </div>
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            this.props.history.location.pathname === '/dashboard/autoease'
                                ? 'tab-active'
                                : 'tab-text'
                        }
                        onClick={() => {
                            if (this.props && this.props.history) {
                                this.props.history.push('/dashboard/autoease');
                            }
                        }}
                    >
                        Auto Shipping
                    </div>
                    <div
                        className={
                            this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname &&
                            (this.props.history.location.pathname === '/dashboard/storage' ||
                                this.props.history.location.pathname === '/dashboard/movers/reserve_storage_thankyou')
                                ? 'tab-active'
                                : 'tab-text'
                        }
                        onClick={() => {
                            this.props.history.push('/dashboard/storage');
                        }}
                    >
                        Reserve a Storage Unit
                    </div>
                </div>
                <div className="reserveStorageThankYouMainPage">
                    <div className="reserveStorageThankYouHeading">Confirmed, thank you for your interest</div>
                    <div className="reserveStorageThankYouSubHeading">
                        Your dedicated storage facility will call you in 24 hrs.
                    </div>
                    <div className="selectedStorageDiv">
                        <div className="selectedStotageHeading">Selected storage units</div>
                        <div className="storageDiv">
                            <div className="nameHeading">{this.props.location.state.name}</div>
                            <div className="addressHeading">{this.props.location.state.address}</div>
                            <div className="unitCard">
                                {this.props.location.state.image ? (
                                    <div className="unitLogo">
                                        <img src={this.props.location.state.image} alt="" />
                                    </div>
                                ) : null}
                                <div className="unitTitleDiv">
                                    <div className="unitTitle">{`${this.props.location.state.dimension} Unit`}</div>
                                    <div className="unitLocation">
                                        {this.props.location.state.amenities.map((amenity: any) => {
                                            return `${amenity}, `;
                                        })}
                                    </div>
                                </div>
                                <div className="unitCurrencyDiv">
                                    <div className="unitCurrency">$</div>
                                    <div className="unitAmount">{this.props.location.state.price.split('.')[0]}</div>
                                    <div className="unitRate">/mo</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReserveStorageThankYou;
