import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
import Typography from '@material-ui/core/Typography';
import CallSvg from '../../Assets/images/ICImages/Phone';
import TvSvg from '../../Assets/images/ICImages/tv';
import InternetSvg from '../../Assets/images/ICImages/internet';
import OneSvg from '../../Assets/images/ICImages/one';
import TwoSvg from '../../Assets/images/ICImages/two';
import ThreeSvg from '../../Assets/images/ICImages/three';
import FourSvg from '../../Assets/images/ICImages/four';
import MovieSvg from '../../Assets/images/ICImages/movie';
import WebSvg from '../../Assets/images/ICImages/web';
import DeskSvg from '../../Assets/images/ICImages/desk';
import VideoCallSvg from '../../Assets/images/ICImages/video-call';
import DownloadSvg from '../../Assets/images/ICImages/download';
import EntertainmentSvg from '../../Assets/images/ICImages/popcorn';
import SportsSvg from '../../Assets/images/ICImages/trophy';
import KidsSvg from '../../Assets/images/ICImages/teddy';
import FilmsSvg from '../../Assets/images/ICImages/film';
import LongDSvg from '../../Assets/images/ICImages/usa';
import InternationalSvg from '../../Assets/images/ICImages/location';
import CheckboxCard from '../../components/atoms/CheckboxCards';
import './ICmain.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InternetAction from '../../store/internet/actions';
import * as DashboardAction from '../../store/dashboard/actions';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Footer from './Footer';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import CircularProgress from '@material-ui/core/CircularProgress';
import Linear from '../../components/atoms/linearProgress';
import * as Action from '../../store/common/actions';
import { formCompletedSegment, buttonClicked } from '../../utils/utlis';

type initialProps = {
    skip: any;
    tabChangeHandler?: any;
    address?: string;
    street?: string;
    city?: string;
    state?: any;
    zipcode?: string;
    moveId?: any;
    buyNow?: any;
    dashboardAction: any;
    dashboardState: any;
    internetAction?: any;
    internetState?: any;
    history?: any;
    location?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    fromLandingArrived: boolean;
    activeStep: number;
    skippedStep: number;
    step1: {
        internet: boolean;
        TV: boolean;
        Phone: boolean;
    };
    step2: {
        one: boolean;
        two: boolean;
        three: boolean;
        four: boolean;
    };
    step3: {
        movies: boolean;
        videoGames: boolean;
        wfh: boolean;
        videoCalls: boolean;
        downloading: boolean;
        noneOfThese: boolean;
    };
    step4: {
        entertaiment: boolean;
        sports: boolean;
        kids: boolean;
        films: boolean;
    };
    step5: {
        localAndLongDistanceCall: boolean;
        internationalCall: boolean;
    };
    address: string | undefined;
    street: string | undefined;
    city: string | undefined;
    state: any;
    zipcode: string | undefined;
    moveId: any;
    apiCalled: boolean;
    serviceId: any;
    slug: any;
    hash_code: any;
    customBrokerageName: any;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

export class steps extends Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 0,
        skippedStep: 0,
        serviceId: '',
        step1: {
            internet: false,
            TV: false,
            Phone: false,
        },
        step2: {
            one: false,
            two: false,
            three: false,
            four: false,
        },
        step3: {
            movies: false,
            videoGames: false,
            wfh: false,
            videoCalls: false,
            downloading: false,
            noneOfThese: false,
        },
        step4: {
            entertaiment: false,
            sports: false,
            kids: false,
            films: false,
        },
        step5: {
            localAndLongDistanceCall: false,
            internationalCall: false,
        },
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        apiCalled: true,
        slug: { primary_color: '' },
        fromLandingArrived: true,
        hash_code: '',
        customBrokerageName: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        this.props.internetAction.getSteps();
        this.props.dashboardAction.checklist();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.internetState &&
            nextProps.internetState.steps &&
            nextProps.internetState.steps &&
            nextProps.internetState.steps[0] &&
            nextProps.internetState.steps[0].filter_values &&
            currentState.apiCalled
        ) {
            if (nextProps.location && nextProps.location.state && nextProps.location.state.type === 'edit') {
                currentState.activeStep = 0;
            } else if (nextProps.internetState.steps[0].filter_values.activeStep === 5) {
                currentState.activeStep = 0;
            } else {
                currentState.activeStep = nextProps.internetState.steps[0].filter_values.activeStep;
            }
            currentState.step1 = nextProps.internetState.steps[0].filter_values.step1;
            currentState.step2 = nextProps.internetState.steps[0].filter_values.step2;
            currentState.step3 = nextProps.internetState.steps[0].filter_values.step3;
            currentState.step4 = nextProps.internetState.steps[0].filter_values.step4;
            currentState.step5 = nextProps.internetState.steps[0].filter_values.step5;
            currentState.apiCalled = false;
        }

        if (
            currentState.activeStep === 5 &&
            nextProps &&
            nextProps.location &&
            nextProps.location.state &&
            nextProps.location.state.fromLanding &&
            nextProps.location.state.fromLanding === 'fromLanding' &&
            currentState.fromLandingArrived
        ) {
            currentState.fromLandingArrived = false;
            nextProps.history.push({
                pathname: '/landing/internet/recommendations',
                state: {
                    fromLanding: 'fromLanding',
                    data:
                        nextProps &&
                        nextProps.history &&
                        nextProps.history.location &&
                        nextProps.history.location.state &&
                        nextProps.history.location.state.data,
                },
            });
        }
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0]
        ) {
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.hash_code = nextProps.commonState.move[0].hash_code;
            currentState.customBrokerageName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }

        if (
            nextProps.internetState &&
            nextProps.internetState.steps &&
            nextProps.internetState.isStepsSuccess &&
            nextProps.internetState.steps.filter_values &&
            nextProps.internetState.steps.filter_values.activeStep
        ) {
            nextProps.internetState.isStepsSuccess = false;
            if (nextProps.internetState.steps.filter_values.activeStep === 5 && currentState.activeStep === 5) {
                nextProps.history.push('/dashboard/internet/recommendations');
            }
        }
        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        if (
            (prevProps && prevProps.dashboardState && prevProps.dashboardState.checklist) !==
                (this.props && this.props.dashboardState && this.props.dashboardState.checklist) &&
            this.props.dashboardState.checklist &&
            this.props.dashboardState.checklist.length > 0
        ) {
            this.props.dashboardState.checklist.map((el: any) => {
                if (el && el.do_it_now && el.do_it_now.new_url.includes('/dashboard/internet')) {
                    this.setState({ serviceId: el.id });
                }
            });
        }
    }

    editAnswer = () => {
        this.setState({
            activeStep: 0,
        });
    };
    getSteps = () => {
        return ['', '', '', '', ''];
    };

    setCheckboxValStep1 = async (e: any) => {
        const step1 = { ...this.state.step1, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step1 }));
    };

    setCheckboxValStep2 = async (e: any) => {
        const step = {
            one: false,
            two: false,
            three: false,
            four: false,
        };
        const step2 = { ...step, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step2 }));
    };

    setCheckboxValStep3 = async (e: any) => {
        // const step = {
        //     movies: false,
        //     videoGames: false,
        //     wfh: false,
        //     videoCalls: false,
        //     downloading: false,
        //     noneOfThese: false,
        // };
        // const step3 = { ...step, [e.target.name]: e.target.checked };
        const step3 = { ...this.state.step3, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step3 }));
    };

    setCheckboxValStep4 = async (e: any) => {
        const step4 = { ...this.state.step4, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step4 }));
    };

    setCheckboxValStep5 = async (e: any) => {
        const step5 = { ...this.state.step5, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step5 }));
    };

    handleChange1 = async (e: any, e1: any) => {
        const step1 = { ...this.state.step1, [e]: !e1 };
        await this.setState(() => ({ step1 }));
    };

    handleChange2 = async (e: any, e1: any) => {
        const step = {
            one: false,
            two: false,
            three: false,
            four: false,
        };
        const step2 = { ...step, [e]: !e1 };
        await this.setState(() => ({ step2 }));
    };
    handleChange3 = async (e: any, e1: any) => {
        const step3 = { ...this.state.step3, [e]: !e1 };
        await this.setState(() => ({ step3 }));
    };
    handleChange4 = async (e: any, e1: any) => {
        const step4 = { ...this.state.step4, [e]: !e1 };
        await this.setState(() => ({ step4 }));
    };

    handleChange5 = async (e: any, e1: any) => {
        const step5 = { ...this.state.step5, [e]: !e1 };
        await this.setState(() => ({ step5 }));
    };

    getStepContent = (step: number) => {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        switch (step) {
            case 0:
                return (
                    <div>
                        <div className="heading">What services are you interested in?</div>
                        <div className="para1">Select all that apply</div>
                        <div className="checkboxCards">
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step1.internet}
                                handleChange={(e: any) => this.setCheckboxValStep1(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange1(e, e1)}
                                name="internet"
                                icon={
                                    <InternetSvg
                                        checked={this.state.step1.internet}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Internet'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                                // iconColour="#FF0000"
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step1.TV}
                                handleChange={(e: any) => this.setCheckboxValStep1(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange1(e, e1)}
                                name="TV"
                                iconWidth="94px"
                                icon={
                                    <TvSvg
                                        checked={this.state.step1.TV}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'TV'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step1.Phone}
                                handleChange={(e: any) => this.setCheckboxValStep1(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange1(e, e1)}
                                name="Phone"
                                icon={
                                    <CallSvg
                                        checked={this.state.step1.Phone}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Phone'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="heading">How many people use internet in your home?</div>
                        <div className="para1">
                            If you have more people online at the same time, you’ll need a faster connection
                        </div>
                        <div className="checkboxCards">
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step2.one}
                                handleChange={(e: any) => this.setCheckboxValStep2(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange2(e, e1)}
                                name="one"
                                icon={
                                    <OneSvg
                                        checked={this.state.step2.one}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'1'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step2.two}
                                handleChange={(e: any) => this.setCheckboxValStep2(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange2(e, e1)}
                                name="two"
                                icon={
                                    <TwoSvg
                                        checked={this.state.step2.two}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'2'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step2.three}
                                handleChange={(e: any) => this.setCheckboxValStep2(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange2(e, e1)}
                                name="three"
                                icon={
                                    <ThreeSvg
                                        checked={this.state.step2.three}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'3'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step2.four}
                                handleChange={(e: any) => this.setCheckboxValStep2(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange2(e, e1)}
                                name="four"
                                icon={
                                    <FourSvg
                                        checked={this.state.step2.four}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'4+'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="heading">What do people in your home use internet for?</div>
                        <div className="para1">Select all that apply</div>
                        <div className="checkboxCards">
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step3.movies}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                name="movies"
                                icon={
                                    <MovieSvg
                                        checked={this.state.step3.movies}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Streaming Movies & Shows'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step3.videoGames}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                name="videoGames"
                                icon={
                                    <WebSvg
                                        checked={this.state.step3.videoGames}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Playing Online Games'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step3.wfh}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                name="wfh"
                                icon={
                                    <DeskSvg
                                        checked={this.state.step3.wfh}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Browsing Web & Email'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step3.videoCalls}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                name="videoCalls"
                                icon={
                                    <VideoCallSvg
                                        checked={this.state.step3.videoCalls}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Making Video Calls'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step3.downloading}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                name="downloading"
                                icon={
                                    <DownloadSvg
                                        checked={this.state.step3.downloading}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Connecting Smart Devices'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            {/* <CheckboxCard
                                className="individualCard"
                                checked={this.state.step3.noneOfThese}
                                handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                name="noneOfThese"
                                icon={noneSvg}
                                label={'None of these'}
                            /> */}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div className="heading">What do you usually watch on TV?</div>
                        <div className="para1">Select all that apply</div>
                        <div className="checkboxCards">
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step4.entertaiment}
                                handleChange={(e: any) => this.setCheckboxValStep4(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange4(e, e1)}
                                name="entertaiment"
                                icon={
                                    <EntertainmentSvg
                                        checked={this.state.step4.entertaiment}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'News and Local Channels'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step4.sports}
                                handleChange={(e: any) => this.setCheckboxValStep4(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange4(e, e1)}
                                name="sports"
                                icon={
                                    <SportsSvg
                                        checked={this.state.step4.sports}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Sports & Premium Content'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step4.kids}
                                handleChange={(e: any) => this.setCheckboxValStep4(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange4(e, e1)}
                                name="kids"
                                icon={
                                    <KidsSvg
                                        checked={this.state.step4.kids}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Kids Shows'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step4.films}
                                handleChange={(e: any) => this.setCheckboxValStep4(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange4(e, e1)}
                                name="films"
                                icon={
                                    <FilmsSvg
                                        checked={this.state.step4.films}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Movies'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <div className="heading">What do you use home phone for?</div>
                        <div className="para1">Select all that apply</div>
                        <div className="checkboxCards">
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step5.localAndLongDistanceCall}
                                handleChange={(e: any) => this.setCheckboxValStep5(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange5(e, e1)}
                                name="localAndLongDistanceCall"
                                icon={
                                    <LongDSvg
                                        checked={this.state.step5.localAndLongDistanceCall}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'Local & Long Distance Calling'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                fromLanding={
                                    this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding'
                                }
                                className="individualCard"
                                checked={this.state.step5.internationalCall}
                                handleChange={(e: any) => this.setCheckboxValStep5(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange5(e, e1)}
                                name="internationalCall"
                                icon={
                                    <InternationalSvg
                                        checked={this.state.step5.internationalCall}
                                        fromLanding={
                                            this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding'
                                        }
                                    />
                                }
                                label={'International Calling'}
                                iconColour={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                            />
                        </div>
                    </div>
                );
            default:
                return 'step not found';
        }
    };

    handleNext = async () => {
        if (
            this.state.step1.internet ||
            (this.state.step1.Phone && (this.state.activeStep === 3 || this.state.activeStep === 4)) ||
            (this.state.step1.TV && this.state.activeStep === 3)
        ) {
            await this.setState({
                activeStep: this.state.activeStep + 1,
            });
        }

        if (!this.state.step1.internet && this.state.activeStep === 0) {
            await this.setState({
                activeStep: this.state.activeStep + 3,
            });
        }
        if (
            this.state.step1.TV &&
            !this.state.step1.Phone &&
            !this.state.step1.internet &&
            this.state.activeStep === 0
        ) {
            await this.setState({
                activeStep: this.state.activeStep + 2,
            });
        }
        if (
            !this.state.step1.TV &&
            this.state.step1.Phone &&
            !this.state.step1.internet &&
            this.state.activeStep === 0
        ) {
            await this.setState({
                activeStep: 4,
            });
        }
        if (!this.state.step1.TV && this.state.activeStep === 3) {
            await this.setState({
                activeStep: this.state.activeStep + 1,
            });
        }
        if (!this.state.step1.Phone && this.state.activeStep === 4) {
            await this.setState({
                activeStep: this.state.activeStep + 1,
            });
        }
        if (this.state.activeStep === 5) {
            this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 50,
                },
                service_id: this.state.serviceId,
            });
            // if (
            //     this.props &&
            //     this.props.location &&
            //     this.props.location.state &&
            //     this.props.location.state.fromLanding &&
            //     this.props.location.state.fromLanding === 'fromLanding'
            // ) {
            //     this.props.history.push({
            //         pathname: '/landing/internet/recommendations',
            //         state: {
            //             fromLanding: 'fromLanding',
            //         },
            //     });
            // } else {
            //     this.props.history.push('/dashboard/internet/recommendations');
            // }
        }

        if (this.state.step1.internet && !this.state.step1.Phone && !this.state.step1.TV) {
            if (this.state.activeStep === 5) {
                let move_id: any = localStorage.getItem('moveKey');
                formCompletedSegment(
                    'Form Completed',
                    'Internet & Cable Page',
                    'Internet & Cable',
                    this.state.customBrokerageName,
                    this.state.brokerage_key,
                    this.state.agent_name,
                    this.state.agent_key,
                    '',
                    'See My Offers',
                    true,
                    this.state.prefilled,
                    'Internet & Cable Form',
                    3,
                    move_id,
                );
            }
        } else if (
            this.state.step1.TV &&
            ((!this.state.step1.Phone && this.state.step1.internet) ||
                (!this.state.step1.Phone && !this.state.step1.internet))
        ) {
            if (this.state.activeStep === 5) {
                let move_id: any = localStorage.getItem('moveKey');
                formCompletedSegment(
                    'Form Completed',
                    'Internet & Cable Page',
                    'Internet & Cable',
                    this.state.customBrokerageName,
                    this.state.brokerage_key,
                    this.state.agent_name,
                    this.state.agent_key,
                    '',
                    'See My Offers',
                    true,
                    this.state.prefilled,
                    'Internet & Cable Form',
                    4,
                    move_id,
                );
            }
        }
        if (
            this.state.step1.Phone &&
            ((this.state.step1.internet && this.state.step1.TV) ||
                (!this.state.step1.internet && this.state.step1.TV) ||
                (this.state.step1.internet && !this.state.step1.TV) ||
                (!this.state.step1.internet && !this.state.step1.TV))
        ) {
            if (this.state.activeStep === 5) {
                let move_id: any = localStorage.getItem('moveKey');
                formCompletedSegment(
                    'Form Completed',
                    'Internet & Cable Page',
                    'Internet & Cable',
                    this.state.customBrokerageName,
                    this.state.brokerage_key,
                    this.state.agent_name,
                    this.state.agent_key,
                    '',
                    'See My Offers',
                    true,
                    this.state.prefilled,
                    'Internet & Cable Form',
                    5,
                    move_id,
                );
            }
        }
       
        const payload = {
            page_name: 'Internet',
            filter_values: {
                activeStep: this.state.activeStep,
                step1: this.state.step1,
                step2: this.state.step2,
                step3: this.state.step3,
                step4: this.state.step4,
                step5: this.state.step5,
            },
        };
        this.props.internetAction.changeSteps(payload);
        window.scrollTo(0, 0);
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Internet & Cable',
            this.state.customBrokerageName,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Internet & Cable',
            this.state.activeStep === 5 ? 'See My Offers' : 'Next',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            this.state.activeStep,
            '',
            move_id,
        );
    };

    handleBack = async () => {
        // this.setState({
        //     activeStep: this.state.activeStep - 1,
        // });
        if (this.state.step1.internet) {
            if (this.state.step1.Phone && !this.state.step1.TV) {
                await this.setState({
                    activeStep: this.state.activeStep - 2,
                });
            } else {
                await this.setState({
                    activeStep: this.state.activeStep - 1,
                });
            }
        }

        if (this.state.step1.TV && !this.state.step1.internet && this.state.activeStep === 3) {
            await this.setState({
                activeStep: 0,
            });
        }
        if (this.state.step1.Phone && !this.state.step1.internet && this.state.activeStep === 4) {
            if (this.state.step1.TV) {
                await this.setState({
                    activeStep: 3,
                });
            } else {
                await this.setState({
                    activeStep: 0,
                });
            }
        }
        window.scrollTo(0, 0);
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Internet & Cable',
            this.state.customBrokerageName,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Internet & Cable',
            'Previous',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            this.state.activeStep + 1,
            '',
            move_id,
        );
    };

    handleSkip = () => {
        let step1 = {
            internet: false,
            TV: false,
            Phone: false,
        };
        let step2 = {
            one: false,
            two: false,
            three: false,
            four: false,
        };
        let step3 = {
            movies: false,
            videoGames: false,
            wfh: false,
            videoCalls: false,
            downloading: false,
            noneOfThese: false,
        };
        let step4 = {
            entertaiment: false,
            sports: false,
            kids: false,
            films: false,
        };
        let step5 = {
            localAndLongDistanceCall: false,
            internationalCall: false,
        };
        // this.props.skip(this.state.activeStep, this.getSteps().length);
        this.props.dashboardAction.updateServicePercentage({
            data: {
                completed_percentage: 50,
            },
            service_id: this.state.serviceId,
        });
        this.props.history.push('/dashboard/internet/recommendations');
        let payload;
        if (this.state.activeStep === 1) {
            payload = {
                page_name: 'Internet',
                filter_values: {
                    activeStep: this.state.activeStep,
                    step1: this.state.step1,
                    step2: step2,
                    step3: step3,
                    step4: step4,
                    step5: step5,
                },
            };
        } else if (this.state.activeStep === 2) {
            payload = {
                page_name: 'Internet',
                filter_values: {
                    activeStep: this.state.activeStep,
                    step1: this.state.step1,
                    step2: this.state.step2,
                    step3: step3,
                    step4: step4,
                    step5: step5,
                },
            };
        } else if (this.state.activeStep === 3) {
            payload = {
                page_name: 'Internet',
                filter_values: {
                    activeStep: this.state.activeStep,
                    step1: this.state.step1,
                    step2: this.state.step2,
                    step3: this.state.step3,
                    step4: step4,
                    step5: step5,
                },
            };
        } else if (this.state.activeStep === 4) {
            payload = {
                page_name: 'Internet',
                filter_values: {
                    activeStep: this.state.activeStep,
                    step1: this.state.step1,
                    step2: this.state.step2,
                    step3: this.state.step3,
                    step4: this.state.step4,
                    step5: step5,
                },
            };
        } else {
            payload = {
                page_name: 'Internet',
                filter_values: {
                    activeStep: this.state.activeStep,
                    step1: step1,
                    step2: step2,
                    step3: step3,
                    step4: step4,
                    step5: step5,
                },
            };
        }

        this.props.internetAction.changeSteps(payload);
        window.scrollTo(0, 0);
    };
    render() {
        const steps = this.getSteps();
        let num1 = steps.length;
        if (this.state.step1.Phone) {
            num1 = steps.length;
        } else if (this.state.step1.TV) {
            num1 = 4;
        } else if (this.state.step1.internet) {
            num1 = 3;
        }

        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="main-head">
                    <h2 className="heading">Internet And Cable</h2>
                    <div className="path">
                        <p
                            className="Para"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}> Internet And Cable</p>
                    </div>
                </div>
                <div className={isDouglasElliman ? 'internetStep internetStepDouglas' : 'internetStep'}>
                    <div className="steps">
                        <div className={this.state.activeStep === steps.length ? 'marginRemove' : ''}>
                            <div>
                                {this.state.activeStep === steps.length ? (
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div>
                                        <Typography>{this.getStepContent(this.state.activeStep)}</Typography>
                                        <div className="Para11">
                                            STEP {this.state.activeStep + 1} OF {num1}
                                        </div>
                                        <div
                                            className={isDouglasElliman ? 'progressStepD' : 'progressStep'}
                                            style={{ marginBottom: '15px' }}
                                        >
                                            {/* <LinearProgress
                                                className="linearProgressInternet"
                                                variant="determinate"
                                                value={((this.state.activeStep + 1) / 5) * 100}
                                                style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                            /> */}
                                            <Linear value={((this.state.activeStep + 1) / 5) * 100} />
                                        </div>
                                        <Stepper activeStep={this.state.activeStep} className="internetStepper">
                                            {steps.map((label: any) => {
                                                const stepProps: { completed?: boolean } = {};
                                                const labelProps: { optional?: React.ReactNode } = {};
                                                return (
                                                    <Step key={label} {...stepProps}>
                                                        <StepLabel {...labelProps}></StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                        <div className="buttonsInternet">
                                            {this.state.activeStep === steps.length - 1 && window ? (
                                                <div></div>
                                            ) : (
                                                <div
                                                    className={isDouglasElliman ? 'skipStyle' : 'skipMove'}
                                                    onClick={this.handleSkip}
                                                >
                                                    Skip and show me all offers in my area
                                                </div>
                                            )}

                                            <div className="buttondivInternet">
                                                {this.state.activeStep === 0 ? null : (
                                                    <Button
                                                        className="btnIC"
                                                        disabled={this.state.activeStep === 0}
                                                        onClick={this.handleBack}
                                                        backgroundColor="#FFFFFF"
                                                        boxShadow={
                                                            isDouglasElliman
                                                                ? 'none'
                                                                : `0 0 3px 0 ${
                                                                      this.state.slug && this.state.slug.primary_color
                                                                          ? this.state.slug.primary_color
                                                                          : '#273E59'
                                                                  }`
                                                        }
                                                        color={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : this.state.slug && this.state.slug.primary_color
                                                                ? this.state.slug.primary_color
                                                                : this.props &&
                                                                  this.props.location &&
                                                                  this.props.location.state &&
                                                                  this.props.location.state.fromLanding &&
                                                                  this.props.location.state.fromLanding ===
                                                                      'fromLanding'
                                                                ? '#30AB86'
                                                                : '#273E59'
                                                        }
                                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                        margin={
                                                            this.state.activeStep === num1 - 1
                                                                ? '0px 15px 0 15px'
                                                                : '0 15px 0 0'
                                                        }
                                                        border={
                                                            isDouglasElliman
                                                                ? '1px solid #100B28'
                                                                : `1px solid ${
                                                                      this.state.slug && this.state.slug.primary_color
                                                                          ? this.state.slug.primary_color
                                                                          : '#273e59'
                                                                  }`
                                                        }
                                                        fontSize="16px"
                                                        width="110px"
                                                        height="50px"
                                                    >
                                                        Previous
                                                    </Button>
                                                )}

                                                <Button
                                                    backgroundColor={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : this.state.slug && this.state.slug.primary_color
                                                            ? this.state.slug.primary_color
                                                            : this.props &&
                                                              this.props.location &&
                                                              this.props.location.state &&
                                                              this.props.location.state.fromLanding &&
                                                              this.props.location.state.fromLanding === 'fromLanding'
                                                            ? '#30AB86'
                                                            : '#273E59'
                                                    }
                                                    // color="#fff"
                                                    width={this.state.activeStep === num1 - 1 ? '210px' : '110px'}
                                                    height="50px"
                                                    margin={this.state.activeStep === num1 - 1 ? '0px 0 0 0' : '0'}
                                                    onClick={this.handleNext}
                                                    disabled={
                                                        this.state.activeStep === 0
                                                            ? !this.state.step1.Phone &&
                                                              !this.state.step1.TV &&
                                                              !this.state.step1.internet
                                                            : this.state.activeStep === 1
                                                            ? !this.state.step2.four &&
                                                              !this.state.step2.two &&
                                                              !this.state.step2.one &&
                                                              !this.state.step2.three
                                                            : this.state.activeStep === 2
                                                            ? !this.state.step3.downloading &&
                                                              !this.state.step3.movies &&
                                                              !this.state.step3.noneOfThese &&
                                                              !this.state.step3.videoCalls &&
                                                              !this.state.step3.videoGames &&
                                                              !this.state.step3.wfh
                                                            : this.state.activeStep === 3
                                                            ? !this.state.step4.entertaiment &&
                                                              !this.state.step4.films &&
                                                              !this.state.step4.kids &&
                                                              !this.state.step4.sports
                                                            : this.state.activeStep === 4
                                                            ? !this.state.step5.internationalCall &&
                                                              !this.state.step5.localAndLongDistanceCall
                                                            : true
                                                    }
                                                >
                                                    {this.state.activeStep === num1 - 1 ? 'See My Offers' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    fromLanding={
                        this.props &&
                        this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.fromLanding &&
                        this.props.location.state.fromLanding === 'fromLanding'
                    }
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        internetAction: bindActionCreators(InternetAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    internetState: state.internet,
    dashboardState: state.checklistDashboard,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(steps);
