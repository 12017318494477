import React from 'react';
import AutoComplete from '../../../components/atoms/autoComplete';
import fromHut from '../../../Assets/images/SignUp/fromHut.svg';
import toHut from '../../../Assets/images/SignUp/toHut.svg';
import moveDate from '../../../Assets/images/SignUp/moveDate.svg';
import forwardArrow from '../../../Assets/images/SignUp/arrowForward.svg';
import Button from '../../../components/atoms/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import './Signup.scss';

type InitialProps = {
    street: string;
    streetError: string;
    street2: string;
    street2Error: string;
    handleSelect: any;
    handleSelect2: any;
    handleChange: any;
    isHashCode: boolean;
    date: any;
    dateHandler: any;
    handleClick: any;
    handleClose: any;
    anchorEl: any;
    dateError: any;
    proceedButtonHandler: any;
    isClosingDate: boolean;
    handleSwitch: any;
    closingDate: any;
    getAge: any;
};

type InitialState = {
    slug: any;
};

class PreMoveSignup extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }
    render() {
        return (
            <div className="preMoveSignupFormMain">
                <div className="inputs-div-signUp">
                    <div className="firstLineInputs-signUp2">
                        <div className="input-div-1-signUp">
                            <div className="move-signUp2">
                                <div className="image-signUp">
                                    <img src={fromHut} width="20px" height="20px" />
                                </div>
                                <div>Moving From</div>
                            </div>
                            <div>
                                <AutoComplete
                                    className="input1-signUp2"
                                    error={Boolean(this.props.streetError)}
                                    value={this.props.street}
                                    onChange={e => {
                                        this.props.handleChange(e, 'street');
                                    }}
                                    onSelect={this.props.handleSelect}
                                    placeholder="Street Name & Number"

                                    // inputTitle="To"
                                />
                                <div className="errorSignup">
                                    {''}
                                    {this.props.street == '' ? <small>{this.props.streetError}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="center-arrow-signUp">
                            <img src={forwardArrow} className="forwardArrow-signUp" />
                        </div>
                        <div className="input-div-2-signUp">
                            <div className="move-signUp2">
                                <div className="image-signUp">
                                    <img src={toHut} width="20px" height="20px" />
                                </div>
                                <div>Moving To</div>
                            </div>
                            <div>
                                <AutoComplete
                                    className="input2-signUp2"
                                    error={Boolean(this.props.street2Error)}
                                    value={this.props.street2}
                                    onChange={e => {
                                        this.props.handleChange(e, 'street2');
                                    }}
                                    onSelect={this.props.handleSelect2}
                                    placeholder="Street Name & Number"

                                    // inputTitle="To"
                                />
                                <div className="errorSignup">
                                    {''}
                                    {this.props.street2 == '' ? <small>{this.props.street2Error}</small> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="secondLineInput-signUp2">
                        <div className="input-div-3-signUp">
                            <div className="move-signUp2">
                                <div className="image-signUp">
                                    <img src={moveDate} width="20px" height="20px" />
                                </div>
                                <div>{this.props.isClosingDate ? 'Closing Date' : 'Moving Date'}</div>
                            </div>
                            <div className="date-picker-div">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disablePast={this.props.isHashCode ? false : true}
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        // label="Date picker inline"
                                        className="date-signUp2"
                                        value={this.props.isClosingDate ? this.props.closingDate : this.props.date}
                                        onChange={(e: any) => {
                                            this.props.dateHandler(e);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            onFocus: e => {
                                                this.props.handleClick(e);
                                            },
                                        }}
                                        autoOk={true}
                                        PopoverProps={{
                                            disableRestoreFocus: true,
                                            onClose: () => {
                                                this.props.handleClose();
                                            },
                                            anchorEl: this.props.anchorEl,
                                        }}
                                        // InputProps={
                                        //     {
                                        //         //   onFocus: (e) => {
                                        //         //       this.handleClick(e)
                                        //         //   },
                                        //     }
                                        // }
                                        open={Boolean(this.props.anchorEl)}
                                        error={Boolean(this.props.dateError)}
                                    />
                                </MuiPickersUtilsProvider>
                                {this.props.isClosingDate ? (
                                    <div className="difference-div">
                                        {this.props.getAge(new Date(), new Date(this.props.closingDate)) == 0
                                            ? 'Today'
                                            : this.props.getAge(new Date(), new Date(this.props.closingDate)) == 1
                                            ? 'Tomorrow'
                                            : `${this.props.getAge(
                                                  new Date(),
                                                  new Date(this.props.closingDate),
                                              )} days to go`}
                                    </div>
                                ) : (
                                    <div className="difference-div">
                                        {this.props.getAge(new Date(), new Date(this.props.date)) == 0
                                            ? 'Today'
                                            : this.props.getAge(new Date(), new Date(this.props.date)) == 1
                                            ? 'Tomorrow'
                                            : `${this.props.getAge(new Date(), new Date(this.props.date))} days to go`}
                                    </div>
                                )}

                                {this.props.date == '' ? (
                                    <div className="errorSignup">
                                        {''}
                                        <small>{this.props.dateError}</small>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="secondLineInput-signUp3">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.isClosingDate}
                                    onChange={this.props.handleSwitch}
                                    color="primary"
                                    name="isClosingDate"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Closing Date"
                        />
                        <FormHelperText className="helperText">
                            Only if you are unsure about your moving date
                        </FormHelperText>
                        {/* <Switch
                            checked={this.props.isClosingDate}
                            onChange={this.props.handleSwitch}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          /> */}
                    </div>
                    <div className="buttonDiv-signUp2">
                        <Button
                            className="proceedToDashBoard"
                            onClick={this.props.proceedButtonHandler}
                            // backgroundColor={
                            //     this.state.slug && this.state.slug.primary_color
                            //         ? this.state.slug.primary_color
                            //         : '#273E59'
                            // }
                            // color="#fff"
                        >
                            Next
                        </Button>
                    </div>
                </div>{' '}
            </div>
        );
    }
}

export default PreMoveSignup;
