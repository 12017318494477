import React, { Component } from 'react';
import './homeSecurity.scss';
import Button from '../../components/atoms/Button';
// import CalenderIcon from '../../Assets/images/InsuranceImages/Iconmaterial-date-range.svg';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import Modal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import InternetCard2 from '../../Assets/images/InternetCard2.svg';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    selectedService: any;
    calendlyOpen2: boolean;
    commonAction?: any;
    commonState?: any;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    date: any;
    time: any;
};

class Summary extends Component<initialProps, initialState> {
    state: initialState = {
        selectedService: [],
        calendlyOpen2: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        date: '',
        time: '',
    };

    componentDidMount() {
        window.scroll(0, 0);
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.selectedService
        ) {
            this.setState({ selectedService: this.props.history.location.state.selectedService });
        }
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            this.setState({
                firstName: this.props.history.location.state.firstName,
                lastName: this.props.history.location.state.lastName,
                email: this.props.history.location.state.email,
                phone: this.props.history.location.state.phone,
                date: this.props.history.location.state.date,
                time: this.props.history.location.state.time,
            });
        }
    }

    calendlyHandle2 = () => {
        this.setState({ calendlyOpen2: true });
    };

    closeCalendlyModal2 = async () => {
        await this.setState({ calendlyOpen2: false, isSubmitClicked: false });
    };

    closeCalendlyModal = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            await this.setState({ calendlyOpen2: false, isSubmitClicked: false });
        }
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="summaryhs">
                        <div
                            className={'summaryhsHead'}
                            style={{
                                background: isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : this.props.history &&
                                      this.props.history.location &&
                                      this.props.history.location.state &&
                                      this.props.history.location.state.fromLanding &&
                                      this.props.history.location.state.fromLanding == 'fromLanding'
                                    ? '#30ab86'
                                    : '#273e59',
                                color: '#fff',
                            }}
                        >
                            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                Thank you for your interest in home security options{' '}
                            </div>
                            {/* <div style={{ fontSize: '16px', marginTop: '10px' }}>
                                Your concierge will call you on the invite to help you with this
                            </div> */}
                        </div>
                        <div className="summaryhsCards">
                            <div className="summaryButtonhsCards">
                                <div style={{ color: '#333', fontSize: '22px', fontWeight: 'bold' }}>
                                    Call schedule details
                                </div>
                            </div>
                            <div style={{ margin: '15px 0', display: 'flex', alignItems: 'center' }}>
                                One of our security specialists will call you within the next couple of hours to
                                finalize your deals and activate your account.
                                {/* <img src={CalenderIcon} /> 11:30AM, Friday, April 15, 2021 */}
                                {/* <div style={{ color: '#006DFF', marginLeft: '5px' }}> */}
                                {/* {new Date(el && el.appointment_time).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })} */}
                                {/* date , date */}
                                {/* {new Date(el && el.appointment_time).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })} */}
                                {/* </div> */}
                            </div>
                            {/* <div className="rescheduleHsButton">
                                <Button
                                    className="btnOrder11"
                                    backgroundColor="#FFFFFF"
                                    boxShadow={`0 0 3px 0 ${
                                        slug && slug.primary_color
                                            ? slug.primary_color
                                            : this.props.history &&
                                              this.props.history.location &&
                                              this.props.history.location.state &&
                                              this.props.history.location.state.fromLanding &&
                                              this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? '#30ab86'
                                            : '#273e59'
                                    }`}
                                    width="100px"
                                    height="40px"
                                    color={
                                        slug && slug.primary_color
                                            ? slug.primary_color
                                            : this.props.history &&
                                              this.props.history.location &&
                                              this.props.history.location.state &&
                                              this.props.history.location.state.fromLanding &&
                                              this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? '#30ab86'
                                            : '#273e59'
                                    }
                                    borderRadius="5px"
                                    fontSize="14px"
                                    margin="0 5px 0 0"
                                    onClick={() => {
                                        this.setState({
                                            calendlyOpen2: true,
                                        });
                                    }}
                                >
                                    Reschedule
                                </Button>
                            </div> */}
                        </div>
                        {this.state.selectedService.length ? (
                            <div className="summaryhsCards1">
                                <div className="titlehsSummary">Plan Selected</div>
                                <div>
                                    {this.state.selectedService.map((item: any) => {
                                        return (
                                            <div className="boxPlanDetailhs">
                                                <img
                                                    // src={el && el.insurance_quote && el.insurance_quote.image_url}
                                                    // src={XfinityIcon}
                                                    src={item.cardLogo}
                                                    className="hsCardImage"
                                                />
                                                <div className="boxPlanDetailInsidehs">
                                                    <div className="left-details">
                                                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                            {item.title}
                                                        </div>

                                                        <div className="planDetailsTags1hs">Monthly Estimate</div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-end',
                                                            height: '100px',
                                                        }}
                                                    >
                                                        <div className="planDetailsTags1hs">${item.monthlyfees}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}

                        <div style={{ display: 'flex', justifyContent: 'center', margin: '5px 0 25px 0' }}>
                            {/* <Button
                                className="btnOrder"
                                backgroundColor={
                                    slug && slug.primary_color
                                        ? slug.primary_color
                                        : this.props.history &&
                                          this.props.history.location &&
                                          this.props.history.location.state &&
                                          this.props.history.location.state.fromLanding &&
                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? '#30ab86'
                                        : '#273e59'
                                }
                                boxShadow={`0 0 3px 0 ${
                                    slug && slug.primary_color
                                        ? slug.primary_color
                                        : this.props.history &&
                                          this.props.history.location &&
                                          this.props.history.location.state &&
                                          this.props.history.location.state.fromLanding &&
                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? '#30ab86'
                                        : '#273e59'
                                }`}
                                width="170px"
                                height="50px"
                                // color="#FFFFFF"
                                borderRadius="5px"
                                fontSize="16px"
                                margin="0 5px 0 0"
                                onClick={() =>
                                    this.props.history.push({
                                        pathname:
                                            this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.state &&
                                            this.props.history.location.state.fromLanding &&
                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? '/securityLanding'
                                                : '/dashboard/home-security',
                                        state: { type: 'back' },
                                    })
                                }
                            >
                                Back to Dashboard
                            </Button> */}
                        </div>
                        {this.state.calendlyOpen2 && (
                            <Modal
                                isShowModal={this.state.calendlyOpen2}
                                onCloseModal={this.closeCalendlyModal2}
                                // onCloseModal={() => {
                                //     this.setState({ calendlyOpen: false })
                                //     // this.props.history.push({
                                //     //     pathname:'dashboard/home-security/summary'
                                //     // })
                                // }}
                                showClose={false}
                                className="modalClassesSecurity"
                            >
                                <div style={{ height: '100%' }}>
                                    <div className="CloseIcon">
                                        <div
                                            style={{
                                                color: '#333333',
                                                fontSize: '20px',
                                                fontWeight: 800,
                                                textAlign: 'left',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            Schedule a Call Back
                                        </div>
                                        <CloseIcon
                                            onClick={() => this.closeCalendlyModal2()}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="line12"></div>
                                    <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                        {/* <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Call Schedule Details
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disablePast
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date"
                                                            className="date11"
                                                            value={this.state.date ? new Date(this.state.date) : null}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            autoOk={true}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.date) ||
                                                                (this.state.date !== '' && !Date.parse(this.state.date))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.date &&
                                                            'Please select date') ||
                                                            (this.state.date !== '' &&
                                                                !Date.parse(this.state.date) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            disableToolbar
                                                            ampm={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="time"
                                                            inputVariant="filled"
                                                            label="Select time"
                                                            className="date11"
                                                            value={this.state.time ? this.state.time : null}
                                                            onChange={(e: any) => this.timeHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            keyboardIcon={<AccessAlarmIcon />}
                                                            autoOk={true}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen1: true });
                                                            //     },
                                                            // }}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.time) ||
                                                                (this.state.time !== '' && !Date.parse(this.state.time))
                                                            }
                                                            // open={this.state.isOpen1}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.time &&
                                                            'Please select time') ||
                                                            (this.state.time !== '' &&
                                                                !Date.parse(this.state.time) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                // marginTop: '10px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="peronalDetailsInsurance">
                                            <div className="flex-div">
                                                <div className="div-1-name">
                                                    <TextField
                                                        id="time"
                                                        label="First Name"
                                                        value={this.state.firstName}
                                                        // onChange={(e: any) => this.firstNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        disabled
                                                        error={
                                                            this.state.isSubmitClicked && this.state.firstName === ''
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.firstName === ''
                                                            ? 'Please enter first name'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="div-2-name">
                                                    <TextField
                                                        id="time"
                                                        label="Last Name"
                                                        value={this.state.lastName}
                                                        // onChange={(e: any) => this.lastNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        disabled
                                                        error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.lastName === ''
                                                            ? 'Please enter last name'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                // onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                                error={this.state.isSubmitClicked && this.state.email === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.email === ''
                                                    ? 'Please enter email'
                                                    : this.state.isSubmitClicked &&
                                                      !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                    ? 'Please enter valid email'
                                                    : ''}
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                // onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                                error={this.state.isSubmitClicked && this.state.phone === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.phone === ''
                                                    ? 'Please enter phone number'
                                                    : this.state.isSubmitClicked && this.state.phone.length < 12
                                                    ? 'Please enter valid phone number'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line12"></div>
                                    <div className="InsuranceOrderPageButton">
                                        <Button
                                            className="btnOrder"
                                            backgroundColor="#FFF"
                                            boxShadow={`0 0 3px 0 ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : this.props.history &&
                                                      this.props.history.location &&
                                                      this.props.history.location.state &&
                                                      this.props.history.location.state.fromLanding &&
                                                      this.props.history.location.state.fromLanding == 'fromLanding'
                                                    ? '#30ab86'
                                                    : '#273e59'
                                            }`}
                                            width="130px"
                                            height="50px"
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : this.props.history &&
                                                      this.props.history.location &&
                                                      this.props.history.location.state &&
                                                      this.props.history.location.state.fromLanding &&
                                                      this.props.history.location.state.fromLanding == 'fromLanding'
                                                    ? '#30ab86'
                                                    : '#273e59'
                                            }
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 10px 0 0"
                                            border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }`}
                                            onClick={() => this.closeCalendlyModal2()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btnOrder"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                    ? '#30AB86'
                                                    : '#273e59'
                                            }
                                            // boxShadow="0 0 3px 0 #273E59"
                                            width="130px"
                                            height="50px"
                                            // color="#FFFFFF"
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            onClick={() => this.closeCalendlyModal()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </div>
                </div>

                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <div className="what-next-main-div-insurance">
                        <div className="what-next-insurance">What's Next?</div>
                        <div className="card-5-next">
                            <div className="card-heading-next">
                                <span className="highlighted">Save up to 20% </span>by comparing home insurance options
                            </div>
                            <div className="button-next">
                                <Button
                                    // color="#fff"
                                    // backgroundColor="#30AB86"
                                    className="button-next-card"
                                    onClick={() => window.open('https://insurance.moveeasy.com')}
                                >
                                    Learn More
                                </Button>
                            </div>
                        </div>

                        <div className="card-6-next">
                            <div className="card-heading-next-2">
                                <div>
                                    Compare and order <span className="highlighted">internet/TV </span>in minutes
                                </div>
                                <div className="button-next">
                                    <Button
                                        // color="#fff"
                                        // backgroundColor="#30AB86"
                                        className="button-next-card"
                                        onClick={() => window.open('https://internet.moveeasy.com')}
                                    >
                                        Learn More
                                    </Button>
                                </div>
                            </div>
                            <div className="card-image">
                                <img src={InternetCard2} style={{ width: '100%', height: '100%' }} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
