import React, { Component } from 'react';
import './Moving.scss';
import Image from '../../Assets/images/Moving/MovingLanding';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as MoversAction from '../../store/move/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import Footer from './Footer';
import { CircularProgress } from '@material-ui/core';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import * as MoveAction from '../../store/common/actions';
import { setTimeout } from 'timers';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    moversAction?: any;
    moversState?: any;
    location?: any;
    moveState?: any;
    moveAction?: any;
};

type initialState = {
    tabName: string;
    tabNameOptions: any;
    activeStep: number;
    totalSteps: number;
    isMovingClicked: boolean;
    isAutoShippingClicked: boolean;
    isReserveStorageClicked: boolean;
    completedMovingPercentage: any;
    isDataArrived: boolean;
    isDataArrived2: boolean;
    isDataArrived3: boolean;
    checkedItems: any;
    showPage: boolean;
    hashCode: any;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    isDataArrivedHeader: boolean;
    reserveService: boolean;
    carShippingService: boolean;
};

class Moving extends Component<initialProps, initialState> {
    state: initialState = {
        tabName: 'landing',
        tabNameOptions: ['landing', 'homeDetails', 'lastPage'],
        activeStep: 0,
        totalSteps: 5,
        isMovingClicked: true,
        isAutoShippingClicked: false,
        isReserveStorageClicked: false,
        completedMovingPercentage: '',
        isDataArrived2: true,
        isDataArrived3: true,
        checkedItems: [],
        showPage: false,
        hashCode: '',
        isDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        isDataArrivedHeader: true,
        reserveService: false,
        carShippingService: false,
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.dashboardAction.checklist();
        this.props.moversAction.selectedMovers();

        setTimeout(() => {
            this.setState({ showPage: true });
        }, 2000);

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                localStorage.removeItem('token');
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                localStorage.removeItem('token');
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    category_name: 'Moving Services',
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        // if (
        //     nextProps.commonState &&
        //     nextProps.commonState.move &&
        //     nextProps.commonState.move.length > 0 &&
        //     nextProps.commonState.move[0] &&
        //     currentState.isDataArrived
        // ) {
        //     currentState.isDataArrived = false;
        //     console.log('xyz', nextProps.commonState.move[0]);
        // }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.dashboardAction.checklist();
                nextProps.moversAction.selectedMovers();
                nextProps.commonAction.moveGet();

                const isHomeOwner = localStorage.getItem('isHomeOwner');
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);

                nextProps.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0]
                        ? window.location.hostname.split('.')[0]
                        : slug && slug.slug,
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    if (localStorage.length > 0) {
                        localStorage.clear();
                        localStorage.removeItem('token');
                    } else {
                        history.push({
                            pathname: `/signUp`,
                            search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                        });
                    }
                }, 1000);
            }
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.completedMovingPercentage = item.completed_percentage;
                }
            });
        }

        if (
            nextProps &&
            nextProps.moversState &&
            nextProps.moversState.selectedMovers &&
            nextProps.moversState.selectedMovers.length &&
            currentState.isDataArrived3
        ) {
            currentState.isDataArrived3 = false;
            currentState.checkedItems = nextProps.moversState.selectedMovers;
            // nextProps.history.push({
            //     pathname: '/dashboard/movers/thankyou',
            //     state: {
            //         checkedItems: nextProps.moversState.selectedMovers,
            //     },
            // });
        }
        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.header &&
            nextProps.moveState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let reserve: any = [];
            let carShipping: any = [];
            reserve = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'reserve storage facility',
            );
            carShipping = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'car shipping',
            );

            if (reserve.length) {
                currentState.reserveService = true;
            }
            if (carShipping.length) {
                currentState.carShippingService = true;
            }
        }
        return currentState;
    }

    tabChangeHandler = (value: string) => {
        this.setState({
            tabName: value,
        });
    };

    skipHandler = async (activeStep: number, totalSteps: number) => {
        await this.setState({ activeStep: activeStep, totalSteps: totalSteps });
        this.tabChangeHandler('lastPage');
    };

    movingClicked = async () => {
        await this.setState({
            isMovingClicked: true,
            isAutoShippingClicked: false,
            isReserveStorageClicked: false,
        });
        this.props.history.push('/dashboard/movers');
    };

    autoShippingClicked = async () => {
        await this.setState({
            isMovingClicked: false,
            isAutoShippingClicked: true,
            isReserveStorageClicked: false,
        });
        // this.props.history.push('');
    };

    reserveStorageClicked = async () => {
        await this.setState({
            isMovingClicked: false,
            isAutoShippingClicked: false,
            isReserveStorageClicked: true,
        });
        this.props.history.push('/dashboard/storage');
    };
    handleAnalytics = () => {
        this.props.history.push('/dashboard/movers/steps');
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Moving Service',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'GET STARTED',
                    card: false,
                    card_title: 'Moving Service',
                    card_type: 'Start Page',
                    category_name: 'Moving Services',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    render() {
        let event = '';
        event = this.props && this.props.location && this.props.location.state && this.props.location.state.event;
        if (this.state.checkedItems.length && !(event == 'start')) {
            this.props.history.push({
                pathname: '/dashboard/movers/thankyou',
                state: {
                    checkedItems: this.state.checkedItems,
                },
            });
        }
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.state;
        return (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            isCoverImage={true}
                            pageImage="moving"
                            onClick={() => this.props.history.push('/dashboard/movers/steps')}
                            headerText={'Let Us Help You Find'}
                            highlightedText={'the Best Moving Options'}
                            buttonText={'Get Started'}
                        />
                    </div>
                ) : (
                    <div className="moving-main-page">
                        <div
                            className={
                                isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'
                            }
                        >
                            <h2 className="heading">Moving Services</h2>
                            <div className="path">
                                <p
                                    className="Para"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard <span>{'>'}</span>
                                </p>
                                <p style={{ marginLeft: '5px' }}>Moving Services</p>
                            </div>
                        </div>

                        <div className="tab-div-moving">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/movers' ||
                                        this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                // onClick={() => {
                                //     if (
                                //         !(
                                //             (this.props.history &&
                                //                 this.props.history.location &&
                                //                 this.props.history.location.pathname ===
                                //                     '/dashboard/movers/recommended') ||
                                //             this.props.history.location.pathname === '/dashboard/movers/get_a_quote'
                                //         )
                                //     ) {
                                //         this.props.history.push('/dashboard/movers/recommended');
                                //     }
                                // }}
                            >
                                Moving
                            </div>
                            {this.state.carShippingService === true ? (
                                <div
                                    className={
                                        this.props &&
                                        this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.pathname &&
                                        this.props.history.location.pathname === '/dashboard/autoease'
                                            ? 'tab-active'
                                            : 'tab-text'
                                    }
                                    onClick={() => {
                                        if (this.props && this.props.history) {
                                            this.props.history.push('/dashboard/autoease');
                                            window.scrollTo(0, 0);
                                        }
                                    }}
                                >
                                    Auto Shipping
                                </div>
                            ) : null}
                            {this.state.reserveService === true ? (
                                <div
                                    className={
                                        this.props &&
                                        this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.pathname &&
                                        (this.props.history.location.pathname === '/dashboard/storage' ||
                                            this.props.history.location.pathname === `/dashboard/storage/`)
                                            ? 'tab-active'
                                            : 'tab-text'
                                    }
                                    onClick={() => {
                                        this.props.history.push('/dashboard/storage');
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    Reserve a Storage Unit
                                </div>
                            ) : null}
                        </div>

                        {/* landing page */}
                        {this.state.showPage ? (
                            <div className="coverDivMove">
                                <div className="landingPageMoveMain">
                                    <Image height="22%" width="32%" className="MovingPageMainImage" />
                                    <h3 className="heading">
                                        Hey{' '}
                                        <span style={{ textTransform: 'capitalize' }}>{`${
                                            this.props.commonState &&
                                            this.props.commonState.move &&
                                            this.props.commonState.move[0] &&
                                            this.props.commonState.move[0].user_ref &&
                                            (this.props.commonState.move[0].user_ref.first_name ||
                                                this.props.commonState.move[0].user_ref.last_name)
                                                ? this.props.commonState.move[0].user_ref.first_name ||
                                                  this.props.commonState.move[0].user_ref.last_name
                                                : ''
                                        },`}</span>{' '}
                                        let us help you find the best moving options for your upcoming move
                                    </h3>
                                    {/* <p className="headPara">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in suscipit augue, eget
                            consectetur mauris.
                        </p> */}
                                    <div className="line"></div>
                                    <div className="btn-div">
                                        <Button
                                            className="movingGetStartedButton"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273E59'
                                            }
                                            width="125px"
                                            height="50px"
                                            onClick={this.handleAnalytics}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                )}
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        moversAction: bindActionCreators(MoversAction, dispatch),
        moveAction: bindActionCreators(MoveAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    commonState: state.common,
    CheckListDashboardState: state.checklistDashboard,
    moveState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(Moving);
