import React, { Component } from 'react';
import './HomeInsurance.scss';
import Button from '../../components/atoms/Button';
import CalenderIcon from '../../Assets/images/InsuranceImages/Iconmaterial-date-range.svg';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InsuranceAction from '../../store/insurance/actions';
import * as Action from '../../store/common/actions';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import Modal from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InternetCard2 from '../../Assets/images/InternetCard2.svg';
import { trustedFormUrl } from '../../config';

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    insuranceAction?: any;
    insuranceState?: any;
};

type initialState = {
    calendlyOpen: boolean;
    date: any;
    time: any;
    firstName: any;
    lastName: any;
    email: any;
    phone: any;
    zip: any;
    checked: any;
    data: any;
    type: string;
    submitClick: boolean;
    quoteId: string | number | null;
    isOpen: boolean;
    isOpen1: boolean;
    isDouglasElliman: boolean;
};

export class Summary extends Component<initialProps, initialState> {
    state = {
        calendlyOpen: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zip: '',
        checked: false,
        data: {},
        type: '',
        submitClick: false,
        quoteId: null,
        isOpen: false,
        isOpen1: false,
        isDouglasElliman: false,
    };
    
    trustedUrl: string | null | undefined = null

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        const state: any = this.props.history && this.props.history.location && this.props.history.location.state;
        this.setState({
            data: state && state.data ? { ...state.data } : {},
            type: state && state.type,
            quoteId: state && state.data && state.data.id,
        });
        this.props.insuranceAction.insuranceAppointmentGet();
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        
        
        if(!document.getElementById("trustedFormScriptTag")){
            const field = 'xxCertUrl';
            const provideReferrer: string = 'false';
            const invertFieldSensitivity = false;
            const tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.id = "trustedFormScriptTag"
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                `://${trustedFormUrl}/trustedform.js?provide_referrer=` +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date().getTime() +
                Math.random() +
                '&invert_field_sensitivity=' +
                invertFieldSensitivity;
            const s = document.getElementsByTagName('script')[0];

            s && s.parentNode && s.parentNode.insertBefore(tf, s);
        }
    }

    componentWillUnmount() {
        document.getElementById("trustedFormScriptTag")?.remove()
    }

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };
    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const prevI = prevProps && prevProps.insuranceState;
        const curI = this.props && this.props.insuranceState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const move = cur && cur.move && cur.move[0];
            let phone = this.formatPhoneInput(move && move.user_ref && move.user_ref.phone);
            this.setState({
                firstName: move && move.user_ref && move.user_ref.first_name,
                lastName: move && move.user_ref && move.user_ref.last_name,
                email: move && move.user_ref && move.user_ref.email,
                phone: phone,
                zip: move && move.destination && move.destination.zip_code,
            });
        }
        if (
            prevI.appointment !== curI.appointment &&
            curI.appointment &&
            curI.appointment[0] &&
            curI.appointment.status === 200
        ) {
            this.props.insuranceAction.insuranceAppointmentGet();
            this.setState({ submitClick: false, calendlyOpen: false });
        }
    }
    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };
    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    closeModal = () => {
        this.setState({
            calendlyOpen: false,
            submitClick: false,
        });
    };

    submitHandler = () => {
        this.setState({ submitClick: true });
        const { firstName, lastName, email, zip, phone, date, time, checked, quoteId } = this.state;
        if (firstName && lastName && email && zip && phone && date && time && checked && quoteId) {
            this.props.insuranceAction.insuranceAppointment({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                zip_code: zip,
                appointment_datetime: `${new Date(date).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`,
                quote_id: quoteId,
                trusted_url: this.trustedUrl 
            });
        }
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let el = this.props.insuranceState && this.props.insuranceState.appointment;
        let quote =
            this.props.insuranceState &&
            this.props.insuranceState.appointment &&
            this.props.insuranceState.appointment.insurance_quote &&
            this.props.insuranceState.appointment.insurance_quote.data &&
            this.props.insuranceState.appointment.insurance_quote.data.quote;
        let coverage =
            this.props.insuranceState &&
            this.props.insuranceState.appointment &&
            this.props.insuranceState.appointment.insurance_quote &&
            this.props.insuranceState.appointment.insurance_quote.data &&
            this.props.insuranceState.appointment.insurance_quote.data.coverage;
        let type =
            this.props.insuranceState &&
            this.props.insuranceState.appointment &&
            this.props.insuranceState.appointment.insurance_quote &&
            this.props.insuranceState.appointment.insurance_quote.data &&
            this.props.insuranceState.appointment.insurance_quote.data.status;
        const { isDouglasElliman } = this.state;
        return (
            <div className='HomeInsurance'>
                {(this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.fromLanding &&
                    this.props.history.location.state.fromLanding == 'fromLanding') ||
                window.location.pathname.includes('landing') ? (
                    <NavbarLanding />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="summaryInsurance">
                        <div
                            className="summaryInsuranceHead"
                            style={{
                                background: isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : window.location.pathname.includes('landing')
                                    ? '#30ab86'
                                    : '#273e59',
                                color: '#fff',
                            }}
                        >
                            <div style={{ fontSize: '24px', fontWeight: 800 }}>
                                Thank you for scheduilng your insurance appointment
                            </div>
                            <div style={{ fontSize: '16px', marginTop: '10px' }}>
                                One of our licensed insurance agents will call you and get you set up with your policy
                            </div>
                        </div>
                        <div className="summaryInsuranceCards">
                            <div className="summaryButtonInsuranceCards">
                                <div style={{ color: '#333', fontSize: '22px' }}>Call schedule details </div>
                                <div className="buttonInsuranceSummary">
                                    <Button
                                        className="btnOrder11"
                                        backgroundColor="#FFFFFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${
                                                      slug && slug.primary_color
                                                          ? slug.primary_color
                                                          : window.location.pathname.includes('landing')
                                                          ? '#30ab86'
                                                          : '#273e59'
                                                  }`
                                        }
                                        width="100px"
                                        height="40px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30ab86'
                                                : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color
                                                          ? slug.primary_color
                                                          : window.location.pathname.includes('landing')
                                                          ? '#30ab86'
                                                          : '#273e59'
                                                  }`
                                        }
                                        fontSize="14px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.setState({ calendlyOpen: true })}
                                    >
                                        Reschedule
                                    </Button>
                                </div>
                            </div>
                            <div style={{ margin: '15px 0', display: 'flex', alignItems: 'center' }}>
                                <img src={CalenderIcon} /> {/* 11:30AM, Friday, April 15, 2021 */}
                                <div style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF', marginLeft: '5px' }}>
                                    {new Date(el && el.appointment_time).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })}
                                    ,{' '}
                                    {new Date(el && el.appointment_time).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </div>
                            </div>
                            <div className="buttonInsuranceSummaryMobile">
                                <Button
                                    className="btnOrder11"
                                    backgroundColor="#FFFFFF"
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${
                                                  slug && slug.primary_color
                                                      ? slug.primary_color
                                                      : window.location.pathname.includes('landing')
                                                      ? '#30ab86'
                                                      : '#273e59'
                                              }`
                                    }
                                    width="100px"
                                    height="40px"
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30ab86'
                                            : '#273e59'
                                    }
                                    borderRadius={isDouglasElliman ? 'none' : '5px'}
                                    fontSize="14px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.setState({ calendlyOpen: true })}
                                >
                                    Reschedule
                                </Button>
                            </div>
                        </div>
                        <div className="summaryInsuranceCards1">
                            <div className="titleInsuaranceSummary">Insurance policy details</div>
                            <div>
                                <div className="boxPlanDetail">
                                    <img
                                        src={el && el.insurance_quote && el.insurance_quote.image_url}
                                        className="insuranceCardImage"
                                    />
                                    <div className="boxPlanDetailInside">
                                        <div>
                                            <div className="planDetailsTagsInsuranceSummary">Premium</div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div
                                                    className="policyDetailcurrency"
                                                    style={{ fontFamily: 'Poppins, sans-serif' }}
                                                >
                                                    $
                                                </div>
                                                <div
                                                    className="currencyTag"
                                                    style={{ fontFamily: 'Poppins, sans-serif' }}
                                                >
                                                    {Math.floor(quote && quote.annual_premium / 12)}
                                                </div>
                                                <div style={{ color: '#6B6C6F', fontSize: '14px', marginTop: '15px' }}>
                                                    *<InfoIcon style={{ height: '20px', width: '20px' }} />
                                                </div>
                                            </div>

                                            <div
                                                className="planDetailsTagsInsuranceSummary"
                                                style={{ fontSize: '12px' }}
                                            >
                                                Monthly Estimate
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div
                                                    className="policyDetailcurrency"
                                                    style={{ fontFamily: 'Poppins, sans-serif' }}
                                                >
                                                    $
                                                </div>
                                                <div
                                                    className="currencyTag"
                                                    style={{ fontFamily: 'Poppins, sans-serif' }}
                                                >
                                                    {quote && quote.annual_premium}
                                                </div>
                                                <div style={{ color: '#6B6C6F', fontSize: '14px', marginTop: '15px' }}>
                                                    *<InfoIcon style={{ height: '20px', width: '20px' }} />
                                                </div>
                                            </div>
                                            <div className="Summary" style={{ fontSize: '12px' }}>
                                                Yearly Estimate
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {type === 'succeeded' && el ? (
                            <div className="summaryInsuranceCards1">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        textAlign: 'left',
                                        padding: '25px 20px 25px 20px',
                                    }}
                                >
                                    Quote details
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Deductible</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage &&
                                        !coverage.deductible_all_other &&
                                        !coverage.deductible_theft &&
                                        !coverage.deductible_wind_hail
                                            ? 0
                                            : coverage.deductible_all_other +
                                              coverage.deductible_theft +
                                              coverage.deductible_wind_hail}
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Dwelling Coverage</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage && coverage.dwelling ? coverage.dwelling : 0}
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Liability Coverage</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage && coverage.personal_liability ? coverage.personal_liability : 0}
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Medical Coverage</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage && coverage.medical_payments ? coverage.medical_payments : 0}
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Personal Property</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage && coverage.personal_property ? coverage.personal_property : 0}
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="smallDiv">
                                    <div>Loss of Use</div>
                                    <div>
                                        <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                        {coverage && coverage.loss_of_use ? coverage.loss_of_use : 0}
                                    </div>
                                </div>
                                <div className="quoteSmall">
                                    *The insurance quote(s) displayed above are provided by licensed insurance agencies
                                    or carriers and are preliminary estimates only and are subject to change based on
                                    additional information we may receive later in the quoting process, the coverages,
                                    deductibles and payment options you choose along with other discounts, features or
                                    savings that may apply.
                                </div>
                            </div>
                        ) : null}

                        <div style={{ display: 'flex', justifyContent: 'center', margin: '5px 0 25px 0' }}>
                            <Button
                                className="btnOrder"
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273e59'
                                }
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0 ${
                                              slug && slug.primary_color
                                                  ? slug.primary_color
                                                  : window.location.pathname.includes('landing')
                                                  ? '#30ab86'
                                                  : '#273e59'
                                          }`
                                }
                                width="170px"
                                height="50px"
                                // color="#FFFFFF"
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                fontSize="16px"
                                margin="0 5px 0 0"
                                onClick={() =>
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.fromLanding
                                        ? this.props.history.push('/insuranceLandingPage')
                                        : localStorage.getItem('isHomeOwner') &&
                                          localStorage.getItem('isHomeOwner') == 'true'
                                        ? this.props.history.push({
                                              pathname: '/dashboardhomeOwner',
                                              state: { type: 'back' },
                                          })
                                        : this.props.history.push({
                                              pathname: '/dashboard',
                                              state: { type: 'back' },
                                          })
                                }
                            >
                                Back to Dashboard
                            </Button>
                        </div>
                    </div>
                </div>
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={() => this.closeModal()}
                        showClose={false}
                        className={isDouglasElliman ? 'modalClasses modalClassesDouglasOrder' : 'modalClasses'}
                    >
                        <div className='HomeInsurance'>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="line12"></div>
                            <form>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    <div style={{ fontSize: '16px', color: '#333333', fontWeight: 'bolder' }}>
                                        Call Schedule Details
                                    </div>
                                    <div className="ModalTimeDate">
                                        <div>
                                            {/* <TextField
                                                id="date"
                                                label="Select Date"
                                                defaultValue={Date.now}
                                                value={this.state.date}
                                                onChange={(e: any) => this.dateHandler(e)}
                                                type="date"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Select date"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    value={this.state.date ? new Date(this.state.date) : null}
                                                    onChange={(e: any) => this.dateHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: () => {
                                                            this.setState({ isOpen: true });
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        style: { zIndex: 1000000 },
                                                        onClose: () => {
                                                            this.setState({ isOpen: false });
                                                        },
                                                    }}
                                                    InputProps={{
                                                        onFocus: () => {
                                                            this.setState({ isOpen: true });
                                                        },
                                                    }}
                                                    error={this.state.submitClick && !this.state.date}
                                                    open={this.state.isOpen}
                                                    style={{ zIndex: 10000000 }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {this.state.submitClick && !this.state.date && 'Please select date'}
                                            </div>
                                        </div>
                                        <div>
                                            {/* <TextField
                                                id="time"
                                                label="Select Time"
                                                value={this.state.time}
                                                defaultValue="12:00"
                                                onChange={(e: any) => this.timeHandler(e)}
                                                type="time"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    ampm={false}
                                                    variant="inline"
                                                    margin="normal"
                                                    id="time"
                                                    inputVariant="filled"
                                                    label="Select time"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    value={this.state.time ? this.state.time : null}
                                                    onChange={(e: any) => this.timeHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',

                                                        onFocus: () => {
                                                            this.setState({ isOpen1: true });
                                                        },
                                                    }}
                                                    keyboardIcon={<AccessAlarmIcon />}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        style: { zIndex: 1000000 },
                                                        onClose: () => {
                                                            this.setState({ isOpen1: false });
                                                        },
                                                    }}
                                                    InputProps={{
                                                        onFocus: () => {
                                                            this.setState({ isOpen1: true });
                                                        },
                                                    }}
                                                    error={this.state.submitClick && !this.state.time}
                                                    open={this.state.isOpen1}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {this.state.submitClick && !this.state.time && 'Please select time'}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <div className="peronalDetailsInsurance">
                                        <TextField
                                            id="time"
                                            label="First Name"
                                            value={this.state.firstName}
                                            // onChange={(e: any) => this.firstNameHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            disabled
                                        />
                                        <TextField
                                            id="time"
                                            label="Last Name"
                                            value={this.state.lastName}
                                            // onChange={(e: any) => this.lastNameHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            disabled
                                        />
                                        <TextField
                                            id="time"
                                            label="Email"
                                            value={this.state.email}
                                            // onChange={(e: any) => this.emailHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            disabled
                                        />
                                        <TextField
                                            id="time"
                                            label="Phone Number"
                                            value={this.state.phone}
                                            // onChange={(e: any) => this.phoneHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            disabled
                                        />
                                        <TextField
                                            id="time"
                                            label="Zip code"
                                            value={this.state.zip}
                                            // onChange={(e: any) => this.zipHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            disabled
                                        />
                                    </div>
                                    <div className={isDouglasElliman ? 'checkboxMain' : 'checkboxnoDoug'}>
                                        <FormControlLabel
                                            label="By clicking the 'Submit' button, you are providing your signed written consent to receive calls or texts from InsuraMatch and its agents to market insurance products at the number (wireless or otherwise) you provided above. As part of that consent, you understand and agree that (1) those calls may be auto-dialed and use artificial or pre-recorded voice messages or SMS communications., and (2) your agreement to receive these calls is not required to purchase any goods or services, and (3) InsuraMatch will not sell your information to third parties without your consent. You agree to InsuraMatch's Terms & Conditions, Compensation and Information Disclosure and Privacy Policy and authorize InsuraMatch and the companies it represents to obtain consumer reports on the persons listed in your application."
                                            className="checkbox11"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.checked}
                                                    onChange={this.handleChange}
                                                    name="checkedG"
                                                />
                                            }
                                        />
                                        <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                            {this.state.submitClick && !this.state.checked && 'Please accept'}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                        }
                                        width="130px"
                                        height="50px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                        }
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                        ? '#30AB86'
                                                        : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.submitHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                <input type="hidden" name="xxCertUrl" ref={(input) => { this.trustedUrl = input?.value }} />
                            </form>
                        </div>
                    </Modal>
                )}

                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <div className="what-next-main-div-insurance">
                        <div className="what-next-insurance">What's Next?</div>
                        <div className="card-3-next">
                            <div className="card-heading-next">
                                Protect Your Home With 24/7 <span className="highlighted">Smart Security</span>
                            </div>
                            <div className="button-next">
                                <Button
                                    onClick={() => window.open('https://security.moveeasy.com')}
                                    // color="#fff"
                                    // backgroundColor="#30AB86"
                                    className="button-next-card"
                                >
                                    Learn More
                                </Button>
                            </div>
                        </div>

                        <div className="card-4-next">
                            <div className="card-heading-next-2">
                                <div>
                                    Get Our <span className="highlighted">Best Internet Deals</span>
                                </div>
                                <div className="button-next">
                                    <Button
                                        onClick={() => window.open('https://internet.moveeasy.com')}
                                        // color="#fff"
                                        // backgroundColor="#30AB86"
                                        className="button-next-card"
                                    >
                                        Learn More
                                    </Button>
                                </div>
                            </div>
                            <div className="card-image">
                                <img src={InternetCard2} style={{ width: '100%', height: '100%' }} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({ commonState: state.common, insuranceState: state.insurance });

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
