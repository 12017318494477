import React, { Component } from 'react';
import ButtonNew from '../../components/atoms/Button';
import './homeSecurity.scss';
import securityImage from '../../Assets/images/SecurityLanding/securityLandingHeaderBackground.svg';
// import layer from '../../Assets/images/layer.svg';
// import flyers from '../../Assets/images/flyers.svg';
// import Person from '../../Assets/images/person.svg';
// import vivint from '../../Assets/images/SecurityLanding/vivint.png';
// import adt from '../../Assets/images/SecurityLanding/adt.png';
// import simplysafe from '../../Assets/images/SecurityLanding/simplysafe.png';
// import alder from '../../Assets/images/HomeSecurity/alder.png';
// import footprint from '../../Assets/images/SecurityLanding/footprint.svg';
// import ring from '../../Assets/images/SecurityLanding/ring.svg';
// import brinkhome from '../../Assets/images/SecurityLanding/brinks-home-primary-tm.svg';
// import cove from '../../Assets/images/SecurityLanding/cove.png';
// import adobe from '../../Assets/images/SecurityLanding/adobe.png';
// import securitypricer from '../../Assets/images/SecurityLanding/securitypricer.png';
// import link from '../../Assets/images/SecurityLanding/link.png';
// import scoute from '../../Assets/images/SecurityLanding/Scout.png';
import test_avatar from '../../Assets/images/test_avatar.png';
// import review from '../../Assets/images/review.svg';
// import serialOneImg from '../../Assets/images/SecurityLanding/securityLandingHowItWorks1.png';
// import serialTwoImg from '../../Assets/images/SecurityLanding/securityHowItWorks2.png';
// import serialThreeImg from '../../Assets/images/SecurityLanding/securityHowItWorks3.png';
import CloseIcon from '@material-ui/icons/Close';
// import HowItWorksCard from '../InternetLanding/howItWorksCard';
// import Card from '../../components/atoms/Card';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
// import SimpleAccordion from '../../components/atoms/FAQ';
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import Button from '../../components/atoms/Button';
// import CarouselCard from '../../components/atoms/CarouselCard';
// import Carousel from 'react-material-ui-carousel';
// import ArrowForwardIcon from '../../Assets/images/ArrowForwardIcon.svg';
// import ArrowBackIcon from '../../Assets/images/ArrowBackIcon.svg';
// import TabComponent from './TabComponentSecurity';
import InternetLandingFooter from '../InternetLanding/InternetLandingFooter';
import moveEasy from '../../Assets/images/move_easy.svg';
// import PhoneIcon from '@material-ui/icons/Phone';
import { scroller } from 'react-scroll';
// import customerServiceImg from '../../Assets/images/SecurityCall.png';
// import HowItWorksMobile from './HowItWorksMobileSecurity';
// import InfoIcon from '@material-ui/icons/Info';
// import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
// import { list } from '../../components/atoms/USAlist';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import collectionImage from '../../Assets/images/SecurityLanding/collectionImage.svg';
import Modal from '../../components/atoms/Modal';
import Recommendation from '../HomeSecurity/Recommendation'

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
};

type initialState = {
    moveId: string;
    address: string;
    active: number;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    streetError: string;
    cityError: string;
    stateError: string;
    zipcodeError: string;
    checkedG: boolean;
    counter: number;
    serialOne: boolean;
    serialTwo: boolean;
    serialThree: boolean;
    clicked: number;
    navLinks: any;
    activeTab: string;
    inactiveTab: string;
    quote: boolean;
    counter2: number;
    isDataArrived: boolean;
    progress1: boolean;
    progress2: boolean;
    progress3: boolean;
    hostname: string;
    calendlyOpen2: boolean;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    progressCard1: any;
    progressCard2: any;
    progressCard3: any;
};

export class LandingPageHomeSecurity extends Component<initialProps, initialState> {
    state: initialState = {
        calendlyOpen2: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        moveId: '',
        address: '',
        isDataArrived: true,
        active: 0,
        street: '',
        city: '',
        state: '',
        zipcode: '',
        streetError: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        checkedG: false,
        counter: 0,
        counter2: 0,
        serialOne: true,
        serialTwo: false,
        serialThree: false,
        clicked: 1,
        progress1: false,
        progress2: false,
        progress3: false,
        quote: false,
        hostname: '',
        navLinks: [
            {
                main: 'Home',
                subLinks: [
                    {
                        heading: 'Home',
                        exact: true,
                        class: 'mortgage',
                        className: 'dynamic-div-security',
                    },
                ],
            },
            {
                main: 'How it Works',
                subLinks: [
                    {
                        heading: 'How it Works',
                        exact: true,
                        class: 'how-it-works',
                        className: 'how-it-works-wrapper-security',
                    },
                ],
            },
            {
                main: 'Support',
                subLinks: [
                    {
                        // link: '/support',
                        // link: '/home',
                        heading: 'Support',
                        exact: true,
                        class: 'support',
                        className: 'call-wrapper-security',
                    },
                ],
            },
            {
                main: 'How We Compare',
                subLinks: [
                    {
                        heading: 'How We Compare',
                        exact: true,
                        class: 'how-we-compare',
                        className: 'compare-section-security',
                    },
                ],
            },
            // {
            //     main: 'Reviews',
            //     subLinks: [
            //         {
            //             heading: 'Reviews',
            //             exact: true,
            //             class: 'reviews',
            //             className: 'carousel-security',
            //         },
            //     ],
            // },
            // {
            //     main: 'FAQ',
            //     subLinks: [
            //         {
            //             heading: 'FAQ',
            //             exact: true,
            //             class: 'faq',
            //             className: 'questions-security',
            //         },
            //     ],
            // },
        ],
        activeTab: 'nav_selected',
        inactiveTab: 'nav_unselected',
        progressCard1: 0,
        progressCard2: 0,
        progressCard3: 0,
    };

    componentDidMount() {
        const domain = window.location.hostname;
        let name = domain.split('.');
        if (name[0]) {
            this.setState({ hostname: name[0] });
        }
        if (!(name[0].includes('security') || name[0].includes('security-demo'))) {
            this.props.commonAction.moveGet();
        }
        setInterval(() => this.handleCardChange(), 3000);

        setInterval(() => this.handleProgressChange(), 500);
        setInterval(() => this.handleProgressChange2(), 500);
        setInterval(() => this.handleProgressChange3(), 500);
    }

    handleProgressChange = () => {
        if (this.state.progressCard1 >= 75) {
            this.setState({ progressCard1: 100 });
        } else if (this.state.progressCard1 < 75) {
            this.setState({
                progressCard1: this.state.progressCard1 + 15,
            });
        }
    };
    handleProgressChange2 = () => {
        if (this.state.progressCard2 >= 75) {
            this.setState({ progressCard2: 100 });
        } else if (this.state.progressCard2 < 75) {
            this.setState({
                progressCard2: this.state.progressCard2 + 15,
            });
        }
    };
    handleProgressChange3 = () => {
        if (this.state.progressCard3 >= 75) {
            this.setState({ progressCard3: 100 });
        } else if (this.state.progressCard3 < 75) {
            this.setState({
                progressCard3: this.state.progressCard3 + 15,
            });
        }
    };

    componentWillUnmount() {
        clearInterval();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length &&
            currentState.isDataArrived
        ) {
            nextProps.commonState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.zipcode = item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.city = item.destination && item.destination.city_name ? item.destination.city_name : '';
                currentState.state = item.destination && item.destination.state_code ? item.destination.state_code : '';
                currentState.street = item.destination && item.destination_street ? item.destination_street : '';
                currentState.moveId = item.id ? item.id : '';
            });
        }
        return currentState;
    }

    streetHandler = (e: any) => {
        this.setState({
            street: e,
        });
    };
    cityHandler = (e: any) => {
        this.setState({
            city: e.target.value,
        });
    };
    stateHandler = (e: any) => {
        this.setState({
            state: e.target.value,
        });
    };
    zipcodeHandler = (e: any) => {
        this.setState({
            zipcode: e.target.value,
        });
    };

    tabChangeHandler = () => { };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkedG: event.target.checked });
    };

    handleCardChange = async () => {
        let { counter2 } = this.state;
        if (counter2 == 0) {
            this.handleSerialOne();
            await this.setState({
                counter2: counter2 + 1,
                progress1: true,
                progress2: false,
                progress3: false,
                progressCard1: 0,
            });
        }
        if (counter2 == 1) {
            this.handleSerialTwo();
            await this.setState({
                counter2: counter2 + 1,
                progress1: false,
                progress2: true,
                progress3: false,
                progressCard2: 0,
            });
        }
        if (counter2 == 2) {
            this.handleSerialThree();
            await this.setState({
                counter2: 0,
                progress1: false,
                progress2: false,
                progress3: true,
                progressCard3: 0,
            });
        }
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
        });
    };

    handleSerialOne = () => {
        this.setState({
            serialOne: true,
            serialTwo: false,
            serialThree: false,
            clicked: 1,
        });
    };

    handleSerialTwo = () => {
        this.setState({
            serialOne: false,
            serialTwo: true,
            serialThree: false,
            clicked: 2,
        });
    };

    handleSerialThree = () => {
        this.setState({
            serialOne: false,
            serialTwo: false,
            serialThree: true,
            clicked: 3,
        });
    };

    validateFields = () => {
        let validity: Boolean = true;

        if (this.state.street === '') {
            this.setState({ streetError: 'Enter street Name' });
            validity = false;
        } else {
            this.setState({ streetError: '' });
        }

        if (this.state.state === '') {
            this.setState({ stateError: 'Enter State Name' });
            validity = false;
        } else {
            this.setState({ stateError: '' });
        }

        if (this.state.city === '') {
            this.setState({ cityError: 'Enter City Name' });
            validity = false;
        } else {
            this.setState({ cityError: '' });
        }

        if (this.state.zipcode === '') {
            this.setState({ zipcodeError: 'Enter Zip Code' });
            validity = false;
        } else {
            this.setState({ zipcodeError: '' });
        }

        return validity;
    };

    handleQuote = async (fromQuote: boolean) => {
        if (fromQuote) {
            if (this.validateFields()) {
                const { street, state, zipcode, city } = this.state;
                if (street && state && zipcode && city) {
                    if (!(this.state.hostname.includes('security') || this.state.hostname.includes('security-demo'))) {
                        this.props.commonAction.moveAddressUpdate({
                            destination_detail: `${street}, ${city}, ${state}, ${zipcode}`,
                            move_id: this.state.moveId,
                        });
                        this.props.history.push({
                            pathname: '/landing/home-security/steps',
                            state: {
                                fromLanding: 'fromLanding',
                            },
                        });
                    } else {
                        this.props.history.push({
                            // pathname: '/landing/home-security/steps',
                            pathname: '/landing/home-security/recommendations',
                            state: {
                                fromLanding: 'fromLanding',
                                data: {
                                    destination: {
                                        lookup_name: city,
                                        zip_code: zipcode,
                                        state_data: state,
                                    },
                                    destination_street: street,
                                    destination_full_address: this.state.address,
                                },
                            },
                        });
                    }
                }
            }
        } else {
            this.props.history.push({
                // pathname:'/dashboard/home-security/steps',
                pathname: '/dashboard/home-security/recommendations',
                state: {
                    fromLanding: 'fromLanding',
                  
                },
            });
        }
    };

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: state,
                    });
                }

                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    closeModal = () => {
        this.setState({ calendlyOpen2: false, isSubmitClicked: false });
    };

    closeCalendlyModal = async () => {
        await this.setState({ isSubmitClicked: true });
        const { street, state, zipcode, city } = this.state;
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            await this.setState({ calendlyOpen2: false, isSubmitClicked: false });

            this.props.history.push({
                pathname: '/landing/home-security/summary',
                state: {
                    fromLanding: 'fromLanding',
                    data: {
                        destination: {
                            lookup_name: city,
                            zip_code: zipcode,
                            state_data: state,
                        },
                        destination_street: street,
                        destination_full_address: this.state.address,
                    },
                    date: this.state.date,
                    time: this.state.time,
                },
            });
        }
    };

    render() {
        const { navLinks } = this.state;
        // const { serialOne, serialTwo, serialThree, clicked } = this.state;
        // const quesAns: any = [
        //     {
        //         ques: 'How much does the platform cost after the trial period?',
        //         ans: 'Not Available',
        //     },
        //     {
        //         ques: 'Is your Human Concierge included in the overall service?',
        //         ans:
        //             'Yes. Its all included. Your clients can talk to a live human being who will help them switch all their utilities in just one call at no extra charge. This includes electric, gas, water, internet, cable, TV & security, moving, home services etc.',
        //     },
        //     {
        //         ques: 'How does my client access this service?',
        //         ans: 'Not Available',
        //     },
        //     {
        //         ques: 'Do you sell our clients contact information?',
        //         ans: 'Not Available',
        //     },
        //     {
        //         ques: 'Does your utility and DMV list cover my city and state?',
        //         ans: 'Not Available',
        //     },
        // ];

        let carouselItems: any = [
            [
                {
                    avatar: test_avatar,
                    name: 'Jessica Mules',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 2',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 3',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 4',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
            [
                {
                    avatar: test_avatar,
                    name: 'Person 5',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 6',
                    title: 'Customer',
                    rate: 4,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 7',
                    title: 'Customer',
                    rate: 3,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 8',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
        ];

        let carouselItemsMedia: any = [];

        carouselItems.map((item: any) => {
            item.map((item2: any) => {
                carouselItemsMedia.push(item2);
            });
        });

        return (
            <div style={{ fontFamily: 'Lato !important' }}>
                <div className="landingHeader-security">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="logo-container-security">
                            <img src={moveEasy} />
                        </div>
                        {this.state.quote === false ? (
                            <div className="navLinkFlexDiv-security">
                                <nav>
                                    <ul style={{ display: 'flex', flexDirection: 'row', margin: '0 11px' }}>
                                        {navLinks.map((row: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {row.subLinks.map((sublink: any) => {
                                                        return (
                                                            <li
                                                                className="landingNav-li-security"
                                                                key={sublink.heading}
                                                                onClick={() => {
                                                                    scroller.scrollTo(sublink.className, {
                                                                        duration: 800,
                                                                        delay: 0,
                                                                        smooth: 'easeInOutQuart',
                                                                    });
                                                                }}
                                                            >
                                                                <div
                                                                    className={`${sublink.class} navlinkHeading-security`}
                                                                >
                                                                    <span style={{ color: '#707070' }}>
                                                                        {sublink.heading}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </nav>
                                <div className="phoneButtonSecurityDiv">
                                    {/* <span className="phone-security">
                                        <span className="phone-icon-security MuiSvgIcon-root">
                                            {' '}
                                            <PhoneIcon />
                                        </span>
                                        <a href={`tel: (810) 525-8185`}>(810) 525-8185</a>
                                    </span> */}
                                    <span>
                                        <ButtonNew
                                            className="landingHeaderButton-security"
                                            onClick={() => {
                                                scroller.scrollTo('offers-div', {
                                                    duration: 800,
                                                    delay: 0,
                                                    smooth: 'easeInOutQuart',
                                                });
                                            }}
                                            // onClick={() => this.handleQuote(false)}
                                        >
                                            Get Started
                                        </ButtonNew>
                                    </span>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="mobile-div-security">
                    <Button
                        className="getStartedButton-security"
                        border="none"
                        boxShadow="none"
                        // onClick={() => {
                        //     scroller.scrollTo('address-wrapper-security', {
                        //         duration: 800,
                        //         delay: 0,
                        //         smooth: 'easeInOutQuart',
                        //     });
                        // }}
                        onClick={() => this.handleQuote(false)}
                    >
                        Get Started
                    </Button>
                </div>

                {this.state.quote === false ? (
                    <div id="dynamic-div-security">
                        {/* <div className="dynamicHeaderImageDiv-security">
                            <img src={securityImage} width="100%" />
                        </div> */}
                        <div className="Landing-main-page-security">
                            <div>
                                <div className="you-deserve-text-security">Protect Your Home With</div>
                                <div>
                                    <span className="you-deserve-text-security">24/7</span>&nbsp;&nbsp;&nbsp;
                                    {''}
                                    <span className="wfh-security" id="change-text">
                                        Smart Security
                                    </span>
                                </div>
                                <p className="header-desc-security">
                                    Compare Home Security and Home Automation options and customize your perfect
                                    smart home security system for your home and family in minutes.

                                </p>
                                <ButtonNew
                                    className="landingGetQuoteButton-security"
                                    border="none"
                                    boxShadow="none"
                                    onClick={() => {
                                        scroller.scrollTo('offers-div', {
                                            duration: 800,
                                            delay: 0,
                                            smooth: 'easeInOutQuart',
                                        });
                                    }}
                                    // onClick={() => this.handleQuote(false)}
                                >
                                    See My Offers
                                </ButtonNew>
                            </div>
                        </div>
                        <div className="dynamicHeaderImageDiv-securityMobile">
                            <img src={securityImage} width="100%" style={{ marginTop: '-10px' }} />
                        </div>

                        <div className="offers-div">
                            <Recommendation landing={true} history={this.props.history} />
                        </div>
{/* 
                        <div className="worlds-security">
                            <div className="worlds-heading-security">Choose from the Top Providers</div>
                            <div className="worlds-para-security">
                                MoveEasy compares options from some of the biggest and best home security and
                                home automation companies
                            </div>

                            <div className="main-div-security">
                                <div className="div-1-security">
                                   <div className="image-div"> <img src={vivint}  /></div>
                                   <div className="image-div"><img src={adt}  /></div>
                                   <div className="image-div"><img src={simplysafe}  /></div>
                                   <div className="image-div"><img src={alder} /></div>
                                </div>

                            </div>
                        </div> */}
                        {/* <div className="collection-security">
                            <div className="collectionDetailsDiv">
                                <div className="collectionHeading">
                                    Get Free Installation and up to  <span className="collectionColorHeading">$1,700</span> in Free Equipment*
                                </div>
                                <div className="collectionPara">
                                    Being one of the biggest dealers of Home Security in the country, MoveEasy can get
                                    you your preferred home security options at unbeatable prices, with unmatched free
                                    equipment and free installation. Ask your security expert today to customize your
                                    plan.
                                    *Conditions Apply

                                </div>

                                <div>
                                <ButtonNew
                                    className="landingGetQuoteButton-security"
                                    border="none"
                                    boxShadow="none"
                               
                                    onClick={() => this.handleQuote(false)}
                                >
                                    See if I qualify
                                </ButtonNew>
                                </div>
                            </div>
                            <div className="collectionImageDiv">
                                <img src={collectionImage} />
                            </div>
                     
                        </div> */}
{/* 
                        <div className="how-it-works-wrapper-security">
                            <div className="worlds-heading-security">How It Works</div>
                            <div className="worlds-para-security">
                                Moveeasy customizes your home security and home automation needs by matching
                                you with THE best provider
                            </div>
                            <div className="card-img-wrapper-security" style={{ width: '100%' }}>
                                <div className="how-it-works-card-div-security">
                                    <div
                                        className="progressBarFlexDiv-security"
                                  
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={1}
                                            heading="Select Your Home Security & Automation Needs"
                                            desc="Let us know what you are looking for in your home security and home automation
                                            system"
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress1 ? (
                                                <LinearProgress
                                                    className="linearProgressLanding-security"
                                                    variant="determinate"
                                                    value={this.state.progressCard1}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className="progressBarFlexDiv-security"
                             
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={2}
                                            heading="Choose the best deal"
                                            desc="Compare different offers and choose the option that works best for you"
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress2 ? (
                                                <LinearProgress
                                                    className="linearProgressLanding-security"
                                                    variant="determinate"
                                                    value={this.state.progressCard2}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className="progressBarFlexDiv-security"
                                  
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={3}
                                            heading="Schedule a call with our security experts"
                                            desc="Schedule a call with one of our security experts to lock in your deals and set up your
                                            installation."
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress3 ? (
                                                <LinearProgress
                                                    className="linearProgressLanding-security"
                                                    variant="determinate"
                                                    value={this.state.progressCard3}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="how-it-works-image-div-security">
                                    {serialOne ? (
                                        <img src={serialOneImg} height={620} />
                                    ) : serialTwo ? (
                                        <img src={serialTwoImg} height={620} />
                                    ) : serialThree ? (
                                        <img src={serialThreeImg} height={620} />
                                    ) : (
                                        <img src={serialOneImg} height={620} />
                                    )}
                                </div>
                            </div>

                            <div className="how-it-works-tab-mobile-security">
                                <HowItWorksMobile />
                            </div>
                        </div> */}

                        {/* Give us a call */}
                        {/* <div className="call-wrapper-security card-img-wrapper-security">
                            <div className="imgDiv-security">
                                <img src={customerServiceImg} />
                            </div>
                            <div className="scheduleCallDiv-security">
                                <div className="worlds-heading-security">Give us a call.</div>
                                <div className="worlds-heading-security">We'll get you taken care of.</div>
                                <div className="card-desc-security">
                                    Whether you know exactly what you need or want a little help deciding, weʼll help you
                                    select your ideal provider, get you the best deals and set up your installation in a
                                    matter of minutes.
                                </div>
                            
                                <div className="week-time-div-security">
                                    <div className="week-time-security">
                                        <div className="day-security">Monday - Friday</div>
                                        <div className="time-security">8:00 a.m. to 10:00 p.m. EST</div>
                                    </div>

                                    <div className="week-time-security">
                                        <div className="day-security">Saturday</div>
                                        <div className="time-security">9:00 a.m. to 7:00 p.m. EST</div>
                                    </div>

                             
                                </div>
                            </div>
                        </div> */}
                        {this.state.calendlyOpen2 && (
                            <Modal
                                isShowModal={this.state.calendlyOpen2}
                                onCloseModal={this.closeModal}
                                showClose={false}
                                className="modalClassesSecurity"
                            >
                                <div style={{ height: '100%' }}>
                                    <div className="CloseIcon">
                                        <div
                                            style={{
                                                color: '#333333',
                                                fontSize: '20px',
                                                fontWeight: 800,
                                                textAlign: 'left',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            Schedule a Call Back
                                        </div>
                                        <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div className="line12"></div>
                                    <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                        {/* <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Call Schedule Details
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disablePast
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date"
                                                            className="date11"
                                                            value={this.state.date ? new Date(this.state.date) : null}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            autoOk={true}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.date) ||
                                                                (this.state.date !== '' && !Date.parse(this.state.date))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.date &&
                                                            'Please select date') ||
                                                            (this.state.date !== '' &&
                                                                !Date.parse(this.state.date) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            disableToolbar
                                                            ampm={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="time"
                                                            inputVariant="filled"
                                                            label="Select time"
                                                            className="date11"
                                                            value={this.state.time ? this.state.time : null}
                                                            onChange={(e: any) => this.timeHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            keyboardIcon={<AccessAlarmIcon />}
                                                            autoOk={true}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen1: true });
                                                            //     },
                                                            // }}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.time) ||
                                                                (this.state.time !== '' && !Date.parse(this.state.time))
                                                            }
                                                        // open={this.state.isOpen1}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.time &&
                                                            'Please select time') ||
                                                            (this.state.time !== '' &&
                                                                !Date.parse(this.state.time) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                // marginTop: '10px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="peronalDetailsInsurance">
                                            <div className="flex-div">
                                                <div className="div-1-name">
                                                    <TextField
                                                        id="time"
                                                        label="First Name"
                                                        value={this.state.firstName}
                                                        onChange={(e: any) => this.firstNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className="date11"
                                                        // disabled
                                                        error={
                                                            this.state.isSubmitClicked && this.state.firstName === ''
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.firstName === ''
                                                            ? 'Please enter first name'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="div-2-name">
                                                    <TextField
                                                        id="time"
                                                        label="Last Name"
                                                        value={this.state.lastName}
                                                        onChange={(e: any) => this.lastNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className="date11"
                                                        // disabled
                                                        error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.lastName === ''
                                                            ? 'Please enter last name'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.email === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.email === ''
                                                    ? 'Please enter email'
                                                    : this.state.isSubmitClicked &&
                                                        !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                        ? 'Please enter valid email'
                                                        : ''}
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.phone === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.phone === ''
                                                    ? 'Please enter phone number'
                                                    : this.state.isSubmitClicked && this.state.phone.length < 12
                                                        ? 'Please enter valid phone number'
                                                        : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line12"></div>
                                    <div className="InsuranceOrderPageButton">
                                        <Button
                                            className="btnOrder"
                                            backgroundColor="#FFF"
                                            boxShadow={`0 0 3px 0 ${'#30ab86'}`}
                                            width="130px"
                                            height="50px"
                                            color={'#30ab86'}
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 10px 0 0"
                                            onClick={() => this.closeModal()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btnOrder"
                                            backgroundColor={'#30AB86'}
                                            // boxShadow="0 0 3px 0 #273E59"
                                            width="130px"
                                            height="50px"
                                            // color="#FFFFFF"
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            onClick={() => this.closeCalendlyModal()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        {/* <div className="compare-section-security">
                            <h2 className="worlds-heading-security" style={{ textAlign: 'center' }}>
                                How we compare?
                            </h2>
                            <p className="card-desc-security" style={{ textAlign: 'center', marginTop: '-10px' }}>
                                Compare the traditional experience with ours
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TabComponent />
                            </div>
                        </div> */}

                        {/* <div className="address-wrapper-security">
                            <div className="address-security">
                                <div className="heading-security">
                                    Enter your address to view what's available near you
                                </div>
                                <div className="boxCard-security">
                                    <div className="Para1">
                                        Why do we ask for your address? <InfoIcon style={{ marginLeft: '10px' }} />
                                    </div>
                                 
                                    <div className="inputs-security">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <AutoComplete
                                                    error={Boolean(this.state.streetError)}
                                                    className="input1"
                                                    placeholder="Street"
                                                    onChange={this.streetHandler}
                                                    onSelect={this.handleSelect}
                                                    value={this.state.street}
                                                />
                                            </div>
                                            {this.state.streetError ? (
                                                <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                    {''}
                                                    <small>{this.state.streetError}</small>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="innerInputs-security">
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput1Security"
                                            >
                                                <div>
                                                    {' '}
                                                    <TextField
                                                        error={Boolean(this.state.cityError)}
                                                        className="input2"
                                                        label="City"
                                                        id="outlined-start-adornment"
                                                        onChange={this.cityHandler}
                                                        variant="outlined"
                                                        value={this.state.city}
                                                    />
                                                </div>
                                                {this.state.cityError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.cityError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput2Security"
                                            >
                                                <div>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        className="input3landing"
                                                        error={Boolean(this.state.stateError)}
                                                        label="State"
                                                        value={this.state.state}
                                                        onChange={(e: any) => this.stateHandler(e)}
                                                        variant="outlined"
                                                    >
                                                        {list.map((option: any) => (
                                                            <MenuItem key={option.name} value={option.name}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                {this.state.stateError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.stateError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput1Security"
                                            >
                                                <div>
                                                    <TextField
                                                        error={Boolean(this.state.zipcodeError)}
                                                        className="input4"
                                                        label="Zip code"
                                                        id="outlined-start-adornment"
                                                        onChange={this.zipcodeHandler}
                                                        variant="outlined"
                                                        value={this.state.zipcode}
                                                    />
                                                </div>
                                                {this.state.zipcodeError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.zipcodeError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="landingFormButtonDiv-security">
                                        <Button
                                            border="none"
                                            boxShadow="none"
                                            className="landingGetQuoteButton-security"
                                            marginTop="10px"
                                            onClick={() => this.handleQuote(true)}
                                        >
                                            Get A Quote
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div>
                            <InternetLandingFooter />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageHomeSecurity);
