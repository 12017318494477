import { all, call, put, takeLatest } from 'redux-saga/effects';
// import { store } from 'react-notifications-component';
import {
    checklistByMoveIdFailure,
    checklistByMoveIdSuccess,
    postSubscriptionDetailsFailure,
    postSubscriptionDetailsSuccess,
    getSubscriptionDetailsFailure,
    getSubscriptionDetailsSuccess,
    updateSubscriptionDetailsFailure,
    updateSubscriptionDetailsSuccess,
} from './actions';
import {
    GET_CHECKLIST_BY_MOVE_ID,
    POST_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_DETAILS,
    UPDATE_SUBSCRIPTION_DETAILS,
} from './actionTypes';
import apiJunction from '../../utils/api';
import { store } from 'react-notifications-component';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function errorNotification(key: any, message: any) {
    store.addNotification({
        title: `Error ${key} `,
        message: message,

        type: 'danger',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
            onScreen: true,
        },
    });
}

function* checklistByMoveId(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/checklist-api/get-checklist-by-move/?move_id=${action.payload.moveId}&must_do=true`,
        });
        if (response.data && response.status === 200) {
            yield put(checklistByMoveIdSuccess({ checklistByMoveId: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(checklistByMoveIdFailure({ error: e.message }));
        // history.push('/server-error');
    }
}
function* postSubscriptionDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-services-subscription/`,
            body: action.payload,
        });
        if (response.data && response.status == 201) {
            yield put(postSubscriptionDetailsSuccess({ postSubscriptionDetails: response.data }));
            store.addNotification({
                title: 'Success',
                message: 'Service subscription added successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postSubscriptionDetailsFailure({ error: e.message }));
        if (e && e.response && e.response.data) {
            for (let d of e.response.data) {
                let data = d;
                for (let key of Object.keys(data)) {
                    let array = data[key];
                    for (let element of array) {
                        if (typeof element == 'string') {
                            errorNotification(key, element);
                        }
                        if (typeof element == 'object') {
                            for (let key1 of Object.keys(element)) {
                                errorNotification(key1, element[key1][0]);
                            }
                        }
                    }
                }
            }
        }
    }
}

function* getSubscriptionDetails() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-services-subscription/`,
        });
        if (response.data && response.status === 200) {
            yield put(getSubscriptionDetailsSuccess({ getSubscriptionDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getSubscriptionDetailsFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* updateSubscriptionDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'put',
            url: `/api/move/move-services-subscription/${action.payload.id}/`,
            body: action.payload.body,
        });
        if (response.data) {
            yield put(updateSubscriptionDetailsSuccess({ updateSubscriptionDetails: response.data }));
            store.addNotification({
                title: 'Success',
                message: 'Service subscription added successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateSubscriptionDetailsFailure({ error: e.message }));
        if (e && e.response && e.response.data) {
            for (let d of e.response.data) {
                let data = d;
                for (let key of Object.keys(data)) {
                    let array = data[key];
                    for (let element of array) {
                        if (typeof element == 'string') {
                            errorNotification(key, element);
                        }
                        if (typeof element == 'object') {
                            for (let key1 of Object.keys(element)) {
                                errorNotification(key1, element[key1][0]);
                            }
                        }
                    }
                }
            }
        }
    }
}

export default function* HomeOwnerDashboardSaga() {
    yield all([takeLatest(GET_CHECKLIST_BY_MOVE_ID, checklistByMoveId)]);
    yield all([takeLatest(POST_SUBSCRIPTION_DETAILS, postSubscriptionDetails)]);
    yield all([takeLatest(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails)]);
    yield all([takeLatest(UPDATE_SUBSCRIPTION_DETAILS, updateSubscriptionDetails)]);
}
