import * as React from 'react';
import { uiConfig } from '../../../config';


function SvgComponent(props: any) { 

    const stroke = uiConfig.colors?.icon?.stroke
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={39.595} height={53.777} viewBox="0 0 39.595 53.777">
            <defs>
                <style>
                    { `.trashprefix__a{
                        fill:#fff
                    }
                    .trashprefix__b{
                        fill:#FFF; 
                        stroke:${stroke};
                    }
                    .trashprefix__c{
                        stroke:${stroke};
                    }`}
                </style>
            </defs>
            <g transform="translate(-67.516)">
                <path className="trashprefix__a" d="M70.89 8.402V3.676h14.149L91.732.788h12.319v7.614" />
                <path
                    className="trashprefix__b"
                    d="M106.323 12.813H68.304v-2.357a2.054 2.054 0 012.054-2.054h33.91a2.054 2.054 0 012.054 2.054v2.357zM100.185 52.989H74.751L70.89 12.813h33.161z"
                />
                <path
                    className="trashprefix__c"
                    d="M85.393 34.438h-4.874a.329.329 0 01-.29-.486l2.234-4.134.36 1a.741.741 0 001.009.473.788.788 0 00.473-1.009l-.958-2.648a.8.8 0 00-.935-.5l-2.864.729a.788.788 0 10.389 1.527l1.122-.286-2.215 4.1c-.794 1.563-.306 2.81 1.676 2.81h4.874a.788.788 0 000-1.576zM96.55 33.055l-2.577-4.464a.788.788 0 00-1.364.788l2.577 4.464a.329.329 0 01-.268.494h-4.731l.707-.84a.788.788 0 10-1.2-1.015l-1.815 2.154a.8.8 0 00.038 1.057l2.063 2.116a.788.788 0 001.128-1.1l-.777-.8h4.6a1.805 1.805 0 001.619-2.854zM90.169 25.188l-1.042-.186a.788.788 0 10-.277 1.551l2.773.494a.79.79 0 00.9-.567l.8-2.839a.788.788 0 00-1.517-.427l-.314 1.115-2.443-3.969c-.92-1.376-2.285-1.56-3.272.046l-2.473 4.284a.788.788 0 001.364.788l2.473-4.284a.329.329 0 01.566-.008z"
                />
                <path className="trashprefix__a" d="M95.528 4.752h8.521v3.65h-8.521z" />
                <circle
                    className="trashprefix__a"
                    cx={5.777}
                    cy={5.777}
                    r={5.777}
                    transform="translate(94.402 41.437)"
                />
                <circle cx={2.381} cy={2.381} r={2.381} transform="translate(97.798 44.832)" fill="#ecf6fe" />
                <path
                    className="trashprefix__c"
                    d="M104.836 7.671V.788a.788.788 0 00-.787-.788H98.7a.788.788 0 000 1.575h4.561v2.39h-7.733a.788.788 0 00-.788.788v2.861H71.677v-3.15h13.362a.788.788 0 00.312-.064l6.544-2.824h3.373a.788.788 0 000-1.575h-3.536a.788.788 0 00-.312.064l-6.544 2.823H70.889a.788.788 0 00-.788.788v3.951a2.846 2.846 0 00-2.586 2.83v2.357a.788.788 0 00.788.788h1.87l3.8 39.464a.788.788 0 00.784.712h25.426a6.565 6.565 0 001.955-12.831l.388-4.029a.788.788 0 00-1.568-.151l-.375 3.9a6.643 6.643 0 00-6.964 6.551 6.551 6.551 0 002.3 4.989h-20.45L71.757 13.6h4.39a.788.788 0 000-1.575h-7.056v-1.568a1.268 1.268 0 011.267-1.267h33.911a1.268 1.268 0 011.267 1.267v1.569H79.58a.788.788 0 000 1.575h23.6l-2.262 23.756a.788.788 0 00.709.859h.076a.788.788 0 00.783-.712l2.278-23.904h1.559a.788.788 0 00.788-.788v-2.355a2.847 2.847 0 00-2.275-2.785zM95.19 47.213a4.989 4.989 0 114.991 4.989h-.008a4.994 4.994 0 01-4.983-4.989zM96.315 5.54h6.946v2.074h-6.946z"
                />
                <g>
                    <path
                        className="trashprefix__c"
                        d="M100.179 50.381a3.168 3.168 0 113.168-3.168 3.172 3.172 0 01-3.168 3.168zm0-4.761a1.593 1.593 0 101.593 1.593 1.595 1.595 0 00-1.593-1.592z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
