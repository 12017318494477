import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    vendorsSuccess,
    vendorsFailure,
    categoriesSuccess,
    categoriesFailure,
    impressionSuccess,
    impressionFailure,
    categoriesQuestionsFailure,
    categoriesQuestionsSuccess,
    postVendorsFailure,
    postVendorsSuccess,
    getMoveVendorsSuccess,
    getMoveVendorsFailure,
    requestVendorsFailure,
    requestVendorsSuccess,
    getHomeAdvisorFailure,
    getHomeAdvisorSuccess,
    standAloneVendorsFailure,
    standAloneVendorsSuccess,
    standAloneOfficesSuccess,
    standAloneOfficesFailure,
    postStandAloneVendorsSuccess,
    postStandAloneVendorsFailure,
    postStandAloneRequestVendorsFailure,
    postStandAloneRequestVendorsSuccess,
    postStandAloneImpressionFailure,
    postStandAloneImpressionSuccess,
} from './actions';
import {
    GET_VENDORS,
    GET_CATEGORIES,
    POST_IMPRESSION,
    GET_CATEGORIES_QUESTIONS,
    POST_VENDORS,
    REQUEST_VENDORS,
    GET_MOVE_VENDOR,
    GET_HOME_ADVISOR,
    GET_STANDALONE_VENDORS,
    GET_STANDALONE_OFFICES,
    POST_STANDALONE_VENDORS,
    POST_STANDALONE_REQUESTVENDORS,
    POST_STANDALONE_IMPRESSION,
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* vendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: action.payload.address
                ? `api/realtors/vendor-list?address=${action.payload.address}`
                : `api/realtors/vendor-list`,
        });

        if (response.data && response.status === 200) {
            yield put(vendorsSuccess({ vendors: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(vendorsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getMoveVendors() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-vendors/`,
        });

        if (response.data && response.status === 200) {
            yield put(getMoveVendorsSuccess({ getMoveVendor: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getMoveVendorsFailure({ getMoveVendorerror: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* categories() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/realtors/vendor-category-list/`,
        });

        if (response.data && response.status === 200) {
            yield put(categoriesSuccess({ categories: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(categoriesFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* impression(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/realtors/vendor-impressions/`,
            body: action.payload,
        });

        if (response.data && response.status === 201) {
            yield put(impressionSuccess({ impression: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(impressionFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* postVendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/move-vendors/`,
            body: action.payload,
        });

        if (response.data && response.status === 201) {
            yield put(postVendorsSuccess({ postVendors: response.data }));
        } else {
            store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(postVendorsFailure({ error: (e as Error).message }));
        store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

function* categoriesQuestions(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/realtors/home-advisor-task/?category_name=${action.payload}`,
        });

        if (response.data && response.status === 200) {
            yield put(categoriesQuestionsSuccess({ categoriesQuestions: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(categoriesQuestionsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* requestVendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/concierge/notify/missing_trusted_pros/`,
            body: action.payload,
        });

        if (response.data && response.status === 200) {
            yield put(requestVendorsSuccess({ requestVendors: response.data, isRequestVendors: true }));
        }
    } catch (e) {
        yield put(requestVendorsFailure({ error: (e as Error).message, isRequestVendors: false }));
    }
}

function* getHomeAdvisor(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/move/home-advisor/?address=${action.payload.address}&address_type=${action.payload.address_type}&task_id=${action.payload.task_id}&vendor_type=${action.payload.vendor_type}`,
        });
        if (response.data && response.status === 200) {
            yield put(getHomeAdvisorSuccess({ homeAdvisor: response.data, isGetHomeAdvisor: true }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getHomeAdvisorFailure({ error: (e as Error).message, isGetHomeAdvisor: false }));
        // history.push('/server-error');
    }
}

//standalone vendors
function* standaloneVendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/realtors/unauthenticated-vendor-list/?site_name=${action.payload.site_name}`,
        });

        if (response.data && response.status === 200) {
            yield put(standAloneVendorsSuccess({ standAloneVendors: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(standAloneVendorsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//standalone offices
function* standaloneOffices(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/realtors/realtor-list/${action.payload.site_name}/get_offices/`,
        });

        if (response.data && response.status === 200) {
            yield put(standAloneOfficesSuccess({ standAloneOffices: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(standAloneOfficesFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//post stand alone vendors
function* postStandAloneVendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/unauthenticated-user-select-vendor/`,
            body: action.payload,
        });

        if (response.data && response.status === 201) {
            yield put(postStandAloneVendorsSuccess({ standAloneVendorsPosted: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postStandAloneVendorsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//post stand alone request vendors
function* postStandAloneRequestVendors(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/concierge/unauthenticated-notify/missing_trusted_pros/`,
            body: action.payload,
        });

        if (response.data && response.status === 200) {
            yield put(postStandAloneRequestVendorsSuccess({ standAloneRequestVendorsPosted: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postStandAloneRequestVendorsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//post stand alone impression
function* postStandAloneImpression(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/realtors/standalone-vendor-impressions/`,
            body: action.payload,
        });

        if (response.data && response.status === 201) {
            yield put(postStandAloneImpressionSuccess({ standAloneImpressionPosted: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postStandAloneImpressionFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* vendorsSaga() {
    yield all([takeLatest(GET_VENDORS, vendors)]);
    yield all([takeLatest(GET_CATEGORIES, categories)]);
    yield all([takeLatest(POST_IMPRESSION, impression)]);
    yield all([takeLatest(GET_CATEGORIES_QUESTIONS, categoriesQuestions)]);
    yield all([takeLatest(POST_VENDORS, postVendors)]);
    yield all([takeLatest(REQUEST_VENDORS, requestVendors)]);
    yield all([takeLatest(GET_MOVE_VENDOR, getMoveVendors)]);
    yield all([takeLatest(GET_HOME_ADVISOR, getHomeAdvisor)]);
    yield all([takeLatest(GET_STANDALONE_VENDORS, standaloneVendors)]);
    yield all([takeLatest(GET_STANDALONE_OFFICES, standaloneOffices)]);
    yield all([takeLatest(POST_STANDALONE_VENDORS, postStandAloneVendors)]);
    yield all([takeLatest(POST_STANDALONE_REQUESTVENDORS, postStandAloneRequestVendors)]);
    yield all([takeLatest(POST_STANDALONE_IMPRESSION, postStandAloneImpression)]);
}
