import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    postOrderWarrantyFailure,
    postOrderWarrantySuccess,
    getProductsFailure,
    getProductsSuccess,
    getProductDetailsFailure,
    getProductDetailsSuccess,
    getHomeWarrantyDetailsFailure,
    getHomeWarrantyDetailsSuccess,
    updateHomeWarrantyDetailsInfoSuccess,
    updateHomeWarrantyDetailsInfoFailure,
    postHomeWarrantyDetailsFailure,
    postHomeWarrantyDetailsSuccess,
    getPlanTaxFailure,
    getPlanTaxSuccess,
} from './actions';
import {
    GET_PRODUCTS,
    GET_PRODUCT_DETAILS,
    POST_ORDER_WARRANTY,
    GET_HOMEWARRANTY_DETAILS,
    UPDATE_HOMEWARRANTY_DETAILS,
    POST_HOMEWARRANTY_DETAILS,
    GET_PLAN_TAX,
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* postOrderWarranty(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/order-frondoor-warranty`,
            body: action.payload,
        });
        if (response.data) {
            yield put(postOrderWarrantySuccess({ postOrderWarranty: response.data }));
            store.addNotification({
                title: 'Success',
                message: 'Order created successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postOrderWarrantyFailure({ error: e.response && e.response.data }));
        // history.push('/server-error');
        store.addNotification({
            title: 'Error',
            message:
                // e.response && e.response.data
                //     ? e.response.data
                //     : e.message && e.message.length < 50
                //     ? e.message
                //     :
                'Something went wrong!',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

function* getProducts(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/get-frontdoor-plans?year_built=${action.payload.year_built}&total_area_sq_ft=${action.payload.total_area}&residence_type=${action.payload.residence_type}`,
        });
        if (response.data) {
            yield put(getProductsSuccess({ getProducts: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getProductsFailure({ error: 'api failed' }));
        // history.push('/server-error');
    }
}

function* getProductDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/get-frontdoor-plan-details?product_id=${action.payload.product_id}&residence_type=${action.payload.residence_type}`,
        });
        if (response.data) {
            yield put(getProductDetailsSuccess({ getProductDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getProductDetailsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getHomeWarrantyDetails() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/home-warranty-details`,
            // body: action.payload.data,
        });
        if (response.data) {
            yield put(getHomeWarrantyDetailsSuccess({ getHomeWarrantyDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getHomeWarrantyDetailsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* updateHomeWarrantyDetailsInfo(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `api/move/home-warranty-details`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(updateHomeWarrantyDetailsInfoSuccess({ updateHomeWarantyDetailsInfo: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateHomeWarrantyDetailsInfoFailure({ error: e.message }));
        // history.push('/server-error');
    }
}

function* postHomeWarrantyDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/home-warranty-details`,
            body: action.payload,
        });
        if (response.data) {
            yield put(postHomeWarrantyDetailsSuccess({ postHomeWarrantyDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postHomeWarrantyDetailsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getPlanTax(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/get-frontdoor-plan-tax`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getPlanTaxSuccess({ getPlanTax: response.data }));
        }
    } catch (e) {
        yield put(getPlanTaxFailure({ error: (e as Error).message }));
    }
}

export default function* warrantySaga() {
    yield all([
        takeLatest(POST_ORDER_WARRANTY, postOrderWarranty),
        takeLatest(GET_PRODUCTS, getProducts),
        takeLatest(GET_PRODUCT_DETAILS, getProductDetails),
        takeLatest(GET_HOMEWARRANTY_DETAILS, getHomeWarrantyDetails),
        takeLatest(UPDATE_HOMEWARRANTY_DETAILS, updateHomeWarrantyDetailsInfo),
        takeLatest(POST_HOMEWARRANTY_DETAILS, postHomeWarrantyDetails),
        takeLatest(GET_PLAN_TAX, getPlanTax),
    ]);
}
