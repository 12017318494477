import LocalStorageService from './localStorage';

const brokerage_name = 'PODS Move In Services';
const getMoveKey = () => {
    return LocalStorageService.getItem('moveKey') || '';
}

const getAnalytics = (): any => {
    const windownew: any = window;
    return windownew && windownew.analytics ? windownew.analytics : [];
};

const AnalyticsService = {
    navigationClicked: ({ page, category_name, main_navigation_bar, more_drop_down_list }: any) => {
        getAnalytics().track(
            'Navigation Category Clicked',
            {
                page,
                brokerage_name,
                category_name: category_name,
                main_navigation_bar,
                more_drop_down_list,
            },
            {
                user_id: getMoveKey(),
            },
        );
    },

    pageViewed: ({ page }: any) => {
        getAnalytics().track(
            'Page Viewed',
            {
                page,
                brokerage_name,
                category_name: page,
            },
            {
                user_id: getMoveKey(),
            },
        );
    },

    buttonClicked: ({
        page,
        category_name,
        button_copy,
        popup,
        popup_title,
        card,
        card_title,
        card_type,
        banner,
        banner_title,
        form_button,
        form_step_number,
        brand_name,
    }: any) => {
        getAnalytics().track(
            'Button Clicked',
            {
                page: page || 'Dashboard',
                brokerage_name,
                category_name: category_name || '',
                brand_name: brand_name || '',
                button_copy: button_copy || '',
                form_button: form_button || false,
                banner: banner || false,
                banner_title: banner_title || '',
                popup: popup || false,
                popup_title: popup_title || '',
                card: card || false,
                card_title: card_title || '',
                card_type: card_type || '',
                form_step_number: form_step_number || null,
            },
            { user_id: getMoveKey() },
        );
    },

    cardClicked: ({ page, category_name, button_copy, card, card_title, card_type, eventName }: any) => {
        getAnalytics().track(
            eventName || 'Card Clicked',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                category_name: category_name || '',
                button_copy: button_copy || '',
                card: card || false,
                card_title: card_title || '',
                card_type: card_type || '',
            },
            { user_id: getMoveKey() },
        );
    },

    formCompleted: ({
        page,
        category_name,
        button_copy,
        form_name,
        form_button,
        form_pre_filled,
        form_step_number,
        eventName,
    }: any) => {
        getAnalytics().track(
            eventName || 'Form Completed',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                category_name: category_name || '',
                button_copy: button_copy || '',
                form_name: form_name || '',
                form_button: form_button || true,
                form_pre_filled: form_pre_filled || false,
                form_step_number: form_step_number || null,
            },
            { user_id: getMoveKey() },
        );
    },

    cardViewed: ({ cardTitle, page, category_name, brandName, eventName }: any) => {
        getAnalytics().track(
            eventName || 'Card Viewed',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                category_name: category_name || '',
                card: true,
                card_title: cardTitle,
                brand_partner_name: brandName
            },
            { user_id: getMoveKey() },
        );
    },

    tableViewed: ({ tableTitle, page, category_name }: any) => {
        getAnalytics().track(
            'Product Table Viewed',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                category_name: category_name || '',
                table: true,
                table_title: tableTitle,
            },
            { user_id: getMoveKey() },
        );
    },

    bannerViewed: ({ bannerTitle, page, category_name }: any) => {
        getAnalytics().track(
            'Banner Viewed',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                category_name: category_name || '',
                banner: true,
                banner_title: bannerTitle,
            },
            { user_id: getMoveKey() },
        );
    },
    formFieldStarted: ({
        page,
        category_name,
        brand_partner_name,
        button_copy,
        form_button,
        form_pre_filled,
        form_field_name,
        form_step_number,
        hasMinimalFields,
    }: any) => {
        const dataToTrack: any = {
            page: page || 'Dashboard',
            category_name: category_name || '',
            brokerage_name: brokerage_name,
            form_field_name: form_field_name || '',
            form_pre_filled: form_pre_filled || false,
        };

        if (!hasMinimalFields) {
            dataToTrack.brand_partner_name = brand_partner_name || '';
            dataToTrack.button_copy = button_copy || '';
            dataToTrack.form_button = form_button || true;
            dataToTrack.form_step_number = form_step_number || null;
        }

        getAnalytics().track(
            'Form Started',
            {
                ...dataToTrack,
            },
            { user_id: getMoveKey() },
        );
    },

    formFieldInput: ({
        page,
        category_name,
        brand_partner_name,
        button_copy,
        hasMinimalFields,
        form_button,
        form_pre_filled,
        form_field_name,
        form_step_number,
    }: any) => {

        const dataToTrack: any = {
            page: page || 'Dashboard',
            category_name: category_name || '',
            brokerage_name: brokerage_name,
            form_field_name: form_field_name || '',
            form_pre_filled: form_pre_filled || false,
        };

        if (!hasMinimalFields) {
            dataToTrack.brand_partner_name = brand_partner_name || '';
            dataToTrack.button_copy = button_copy || '';
            dataToTrack.form_button = form_button || true;
            dataToTrack.form_step_number = form_step_number || null;
        }

        getAnalytics().track(
            'Form Field Inputted',
            {
                ...dataToTrack,
            },
            { user_id: getMoveKey() },
        );
    },

    scheduleCallStarted: ({
        page,
        concierge_name,
        appointment_type
    }: any) => {
        getAnalytics().track(
            'Schedule Call Started',
            {
                page: page || 'Dashboard',
                brokerage_name: brokerage_name,
                concierge_name: concierge_name || '',
                appointment_type: appointment_type || ''
            },
            { user_id: getMoveKey() },
        );
    },
};

export default AnalyticsService;
