import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    className?: any;
    fromLanding?: boolean;
};
function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={39.798}
            height={79.15}
            viewBox="0 0 39.798 79.15"
            {...props}
            className={props.className}
        >
            <defs>
                <style>
                    {`.hsStepprefix__b{fill:${
                        slug && slug.primary_color && props.checked
                            ? slug.primary_color
                            : props.fromLanding && props.checked
                            ? '#30ab86'
                            : props.checked
                            ? '#273E59'
                            : '#929dab'
                    }}.hsStepprefix__a{fill:#fff}`}
                </style>
            </defs>
            <path className="hsStepprefix__a" d="M1.159 1.159h37.479V77.99H1.159z" />
            <path
                className="hsStepprefix__a"
                d="M12.947 30.367h13.491v-9.643s-2.9-4.507-6.361-4.507-7.478 4.507-7.478 4.507z"
            />
            <path
                className="hsStepprefix__b"
                d="M38.638 0H1.159A1.159 1.159 0 000 1.159v55.976a1.16 1.16 0 102.319 0V2.319h35.16v19.255a1.16 1.16 0 102.319 0V1.159A1.159 1.159 0 0038.638 0z"
            />
            <path
                className="hsStepprefix__b"
                d="M38.638 19.23a1.159 1.159 0 00-1.159 1.159v56.442H2.32V56.769a1.16 1.16 0 00-2.319 0v21.222A1.159 1.159 0 001.16 79.15h37.479a1.159 1.159 0 001.159-1.159v-57.6a1.159 1.159 0 00-1.16-1.161z"
            />
            <path
                className="hsStepprefix__b"
                d="M9.439 23.417a1.16 1.16 0 001.09.765h1.964v5.087a1.159 1.159 0 001.159 1.159h12.493a1.159 1.159 0 001.159-1.159v-5.087h1.964a1.159 1.159 0 00.742-2.05l-9.37-7.808a1.16 1.16 0 00-1.485 0l-9.37 7.808a1.159 1.159 0 00-.346 1.285zm10.46-6.694l6.17 5.142a1.159 1.159 0 00-1.083 1.157v5.087h-3.928v-2.276a1.16 1.16 0 10-2.319 0v2.276h-3.928v-5.087a1.159 1.159 0 00-1.083-1.157z"
            />
            <path
                className="hsStepprefix__b"
                d="M35.113 5.844a1.159 1.159 0 00-1.159-1.159H5.844a1.159 1.159 0 00-1.159 1.159v28.11a1.159 1.159 0 001.159 1.159h28.109a1.159 1.159 0 001.159-1.159zm-2.319 26.95H7V7.004h25.79zM28.448 47.891a12.085 12.085 0 100 17.1 12.1 12.1 0 000-17.1zm-.87 14.589l-2.808-2.808a5.854 5.854 0 000-6.463l2.808-2.808a9.788 9.788 0 010 12.079zm-10.172-3.547a3.525 3.525 0 114.986 0 3.5 3.5 0 01-4.986 0zm2.493-12.26a9.748 9.748 0 016.04 2.088L23.13 51.57a5.862 5.862 0 00-6.461 0l-2.809-2.809a9.749 9.749 0 016.04-2.088zm-7.68 3.727l2.808 2.808a5.854 5.854 0 000 6.463l-2.808 2.808a9.766 9.766 0 010-12.08zm1.64 13.719l2.809-2.809a5.859 5.859 0 006.461 0l2.809 2.809a9.788 9.788 0 01-12.079.001zM31.611 38.416a3.5 3.5 0 103.5 3.5 3.506 3.506 0 00-3.5-3.5zm0 4.685a1.183 1.183 0 111.183-1.185 1.184 1.184 0 01-1.183 1.185zM8.187 45.416a3.5 3.5 0 10-3.5-3.5 3.506 3.506 0 003.5 3.5zm0-4.685a1.183 1.183 0 11-1.188 1.185 1.184 1.184 0 011.188-1.181zM31.611 74.466a3.5 3.5 0 10-3.5-3.5 3.506 3.506 0 003.5 3.5zm0-4.685a1.183 1.183 0 11-1.183 1.183 1.184 1.184 0 011.183-1.183zM8.187 74.466a3.5 3.5 0 10-3.5-3.5 3.506 3.506 0 003.5 3.5zm0-4.685a1.183 1.183 0 11-1.183 1.183 1.184 1.184 0 011.183-1.183z"
            />
            <path
                className="hsStepprefix__b"
                d="M10.529 11.689h4.685a1.16 1.16 0 000-2.319h-4.685a1.16 1.16 0 000 2.319zM10.529 15.749h2.342a1.16 1.16 0 000-2.319h-2.342a1.16 1.16 0 100 2.319zM24.583 11.689h4.685a1.16 1.16 0 000-2.319h-4.685a1.16 1.16 0 000 2.319zM29.269 13.43h-2.342a1.16 1.16 0 100 2.319h2.342a1.16 1.16 0 100-2.319z"
            />
        </svg>
    );
}

export default SvgComponent;
