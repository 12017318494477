import React, { Component } from 'react';
import Modal from '../../components/atoms/Modal';
import './checklistModal.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '../../Assets/images/ICImages/close.svg';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '../../components/atoms/Button';

type initialProps = {
    calender: boolean;
    reminderModal?: any;
    closeModal?: any;
    date: any;
    reminders: any;
    emailId: string;
    phoneNumber: any;
    dateHandler?: any;
    handleSetReminders?: any;
    handleEmail?: any;
    handlePhone?: any;
    setReminderFinal?: any;
    taskData?: any;
    isSecondEmail?: any;
    handleEmail1?: any;
    handlePhone1?: any;
    emailId1: string;
    phoneNumber1?: string;
    secondEmailShow?: any;
    secondEmailRemove?: any;
    buttonText?: any;
    deleteReminderFinal?: any;
    isDouglasElliman?: any;
};

type initialState = {
    setRemainder: boolean;
    getMovingQuotes: boolean;
};
export class ChecklistReminderModal extends Component<initialProps, initialState> {
    state: initialState = {
        setRemainder: false,
        getMovingQuotes: false,
    };

    handleSendReminders = (type: string) => {
        let reminder = this.props.reminders;
        type === 'phone'
            ? (reminder.phone = !reminder.phone)
            : type === 'email'
            ? (reminder.email = !reminder.email)
            : (reminder = reminder);
        this.props.handleSetReminders(reminder);
    };

    render() {
        const { calender, reminderModal } = this.props;
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={'checklistModals'}>
                <Modal
                    isShowModal={reminderModal}
                    onCloseModal={() => this.props.closeModal()}
                    showClose={false}
                    className={this.props.isDouglasElliman ? 'setReminder setReminderDouglas' : 'setReminder'}
                >
                    <div className="setReminderContent">
                        <div className="setReminderContentClose">
                            <img src={CloseIcon} width="21px" onClick={() => this.props.closeModal()} />
                        </div>
                        {calender ? (
                            <div className="setReminderContentHead">
                                <div>
                                    <ArrowBackIcon onClick={() => this.props.closeModal()} />
                                </div>
                                <div className="setReminderHeading">Add a reminder</div>
                            </div>
                        ) : (
                            <div className="setReminderContentHead">
                                <div className="setReminderHeading">Set a reminder</div>
                            </div>
                        )}
                        <div className="setReminderContentBody">
                            <div className="setReminderContentBody-btn">
                                <TextField
                                    id="date"
                                    label="Reminder Date"
                                    defaultValue={this.props.date}
                                    value={this.props.date}
                                    onChange={(e: any) => this.props.dateHandler(e)}
                                    type="datetime-local"
                                    variant="filled"
                                    className={
                                        isDouglasElliman
                                            ? 'reminderModalTextField reminderModalTextFieldDouglas'
                                            : 'reminderModalTextField'
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className=" setReminderContentBody-btn">
                                <div className="setReminderContentBody-checkbox-wrapper">
                                    <div className="setReminderContentBody-checkbox-heading">
                                        Where should we send you reminders?
                                    </div>
                                    <div>
                                        <FormGroup>
                                            <div className="setReminderContentBody-row">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.props.reminders.email}
                                                            onChange={() => this.handleSendReminders('email')}
                                                            name="email"
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{
                                                                color: this.props.reminders.email
                                                                    ? '#2270EE'
                                                                    : '#B3B3B3',
                                                            }}
                                                        />
                                                    }
                                                    label="Email"
                                                    style={{
                                                        color: '#424242',
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Roboto',
                                                        fontSize: '12px',
                                                    }}
                                                />
                                                {this.props.reminders.email &&
                                                    (!this.props.isSecondEmail ? (
                                                        <div
                                                            onClick={this.props.secondEmailShow}
                                                            className={
                                                                isDouglasElliman
                                                                    ? 'setReminder-email-addPeople setReminder-email-addPeopleDouglas'
                                                                    : 'setReminder-email-addPeople'
                                                            }
                                                        >
                                                            + Add People
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={this.props.secondEmailRemove}
                                                            className="setReminder-email-removePeople"
                                                        >
                                                            - Remove People
                                                        </div>
                                                    ))}
                                            </div>
                                            {this.props.reminders.email && (
                                                <div>
                                                    <TextField
                                                        id="email"
                                                        label="Email Id"
                                                        value={this.props.emailId}
                                                        onChange={(e: any) => this.props.handleEmail(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={
                                                            isDouglasElliman
                                                                ? 'reminderModalTextField reminderModalTextFieldDouglas'
                                                                : 'reminderModalTextField'
                                                        }
                                                        // disabled
                                                    />
                                                    {this.props.isSecondEmail && (
                                                        <TextField
                                                            id="email"
                                                            label="Email Id"
                                                            value={this.props.emailId1}
                                                            onChange={(e: any) => this.props.handleEmail1(e)}
                                                            type="text"
                                                            variant="filled"
                                                            className={
                                                                isDouglasElliman
                                                                    ? 'reminderModalTextField reminderModalTextFieldDouglas'
                                                                    : 'reminderModalTextField'
                                                            }
                                                            // disabled
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.reminders.phone}
                                                        onChange={() => this.handleSendReminders('phone')}
                                                        name="phone"
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{
                                                            color: this.props.reminders.phone ? '#2270EE' : '#B3B3B3',
                                                        }}
                                                    />
                                                }
                                                label="Phone"
                                                style={{
                                                    color: '#424242',
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Roboto',
                                                    fontSize: '12px',
                                                }}
                                            />
                                            {this.props.reminders.phone && (
                                                <div>
                                                    <TextField
                                                        id="phone"
                                                        label="Phone Number"
                                                        value={this.props.phoneNumber}
                                                        onChange={(e: any) => this.props.handlePhone(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={
                                                            isDouglasElliman
                                                                ? 'reminderModalTextField reminderModalTextFieldDouglas'
                                                                : 'reminderModalTextField'
                                                        }
                                                        // disabled
                                                    />
                                                    {this.props.isSecondEmail && (
                                                        <TextField
                                                            id="phone"
                                                            label="Phone Number"
                                                            value={this.props.phoneNumber1}
                                                            onChange={(e: any) => this.props.handlePhone1(e)}
                                                            type="text"
                                                            variant="filled"
                                                            className={
                                                                isDouglasElliman
                                                                    ? 'reminderModalTextField reminderModalTextFieldDouglas'
                                                                    : 'reminderModalTextField'
                                                            }
                                                            // disabled
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="setReminderContentBody-btn">
                                <Button
                                    backgroundColor={
                                        this.props.isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    // color="#fff"
                                    borderRadius={this.props.isDouglasElliman ? '0px' : '4px'}
                                    // border={`1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`}
                                    disabled={!this.props.date}
                                    onClick={() => this.props.setReminderFinal()}
                                >
                                    {this.props.buttonText}
                                </Button>
                            </div>
                            {this.props.taskData && this.props.taskData.taskreminder !== null ? (
                                <div
                                    onClick={() => this.props.deleteReminderFinal()}
                                    className="setReminderContentBody-delReminder"
                                >
                                    Delete this reminder
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ChecklistReminderModal;
