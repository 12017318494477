import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={26} height={24}>
        <g data-name="Group 35693" transform="translate(-3 -4)">
            <rect data-name="Rectangle 2469" width={25} height={14} rx={3} transform="translate(3.5 4.5)" fill="#fff" />
            <path
                data-name="Path 42862"
                d="M28.5 7.5v7h-25v-7a2.846 2.846 0 0 1 .4-1.49 1.4 1.4 0 0 1 .1-.16A2.978 2.978 0 0 1 6.5 4.5h19a3.009 3.009 0 0 1 3 3Z"
                fill="#fff"
            />
            <path data-name="Path 42863" d="M6.5 18.5a3 3 0 0 1-3-3v-1h5v4Z" fill="#fff" />
            <path data-name="Rectangle 2470" fill="#fff" d="M8.5 14.5h15v2h-15z" />
            <path data-name="Rectangle 2471" fill="#fff" d="M8.5 16.5h15v2h-15z" />
            <g
                data-name="Group 35693"
                fill={
                    window.location.pathname.includes('home-warranty') ||
                    window.location.pathname.includes('homewarranty')
                        ? '#009CBD'
                        : '#100b28'
                }
            >
                <path
                    data-name="Path 42864"
                    d="M25.5 4h-19A3.5 3.5 0 0 0 3 7.5v8A3.5 3.5 0 0 0 6.5 19h19a3.5 3.5 0 0 0 3.5-3.5v-8A3.5 3.5 0 0 0 25.5 4Zm-19 1h19A2.5 2.5 0 0 1 28 7.5V14H4V7.5A2.5 2.5 0 0 1 6.5 5ZM23 16H9v-1h14Zm-19-.5V15h4v3H6.5A2.5 2.5 0 0 1 4 15.5ZM9 18v-1h14v1Zm16.5 0H24v-3h4v.5a2.5 2.5 0 0 1-2.5 2.5Z"
                />
                <path data-name="Path 42865" d="M25.5 13h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 0 1Z" />
                <path
                    data-name="Path 42866"
                    d="M15.42 20.5a.5.5 0 0 0-1 0 1.264 1.264 0 0 1-.326.873 2.326 2.326 0 0 0 0 2.917 1.339 1.339 0 0 1 0 1.751 2.243 2.243 0 0 0-.514 1.459.5.5 0 0 0 1 0 1.265 1.265 0 0 1 .326-.875 2.329 2.329 0 0 0 0-2.919 1.335 1.335 0 0 1 0-1.748 2.243 2.243 0 0 0 .514-1.458Z"
                />
                <path
                    data-name="Path 42867"
                    d="M18.42 20.5a.5.5 0 0 0-1 0 1.272 1.272 0 0 1-.325.873 2.325 2.325 0 0 0 0 2.917 1.339 1.339 0 0 1 0 1.751 2.243 2.243 0 0 0-.515 1.459.5.5 0 0 0 1 0 1.27 1.27 0 0 1 .326-.875 2.329 2.329 0 0 0 0-2.919 1.336 1.336 0 0 1 0-1.748 2.237 2.237 0 0 0 .514-1.458Z"
                />
                <path
                    data-name="Path 42868"
                    d="M21.42 20.5a.5.5 0 0 0-1 0 1.272 1.272 0 0 1-.325.873 2.325 2.325 0 0 0 0 2.917 1.339 1.339 0 0 1 0 1.751 2.243 2.243 0 0 0-.515 1.459.5.5 0 0 0 1 0 1.27 1.27 0 0 1 .326-.875 2.329 2.329 0 0 0 0-2.919 1.336 1.336 0 0 1 0-1.748 2.237 2.237 0 0 0 .514-1.458Z"
                />
                <path
                    data-name="Path 42869"
                    d="M12.42 20.5a.5.5 0 0 0-1 0 1.264 1.264 0 0 1-.326.873 2.326 2.326 0 0 0 0 2.917 1.339 1.339 0 0 1 0 1.751 2.243 2.243 0 0 0-.514 1.459.5.5 0 0 0 1 0 1.265 1.265 0 0 1 .326-.875 2.329 2.329 0 0 0 0-2.919 1.335 1.335 0 0 1 0-1.748 2.243 2.243 0 0 0 .514-1.458Z"
                />
            </g>
        </g>
    </svg>
);

export default SvgComponent;
