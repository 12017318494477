import React, { Component } from 'react';
import './HomeInsurance.scss';
import Image from '../../Assets/images/InsuranceImages/mainInsurance';
import Button from '../../components/atoms/Button';
// import Steps from './steps';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as signupAction from '../../store/login/signup/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import Footer from './Footer';
import CustomPage from '../CustomPage/CustomPage';
// import { CircularProgress } from '@material-ui/core';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { UIContext } from '../../UIContext';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import localStorage from '../../utils/localStorage';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history?: any;
    insuranceAction?: any;
    insuranceState?: any;
    commonAction?: any;
    commonState?: any;
    signup?: any;
};

type initialState = {
    isDataArrivedHeader: boolean;
    contentText: any;
    isDefault: boolean;
    hashCode: any;
    isHashcodeDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    slug: any;
    moveId: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    category_name: any;
    address: any;
    isError: boolean;
    isAnalitycsTriggerredOnce: any;
};

class HomeInsurance extends Component<initialProps, initialState> {
    appContext = this.context;
    state: initialState = {
        isDataArrivedHeader: true,
        isDefault: true,
        contentText: '',
        hashCode: '',
        isHashcodeDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        slug: null,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        category_name: '',
        address: {},
        isError: false,
        isAnalitycsTriggerredOnce: {
            address: false
        }
    };
    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }

        this.props.commonAction.moveReset()
        this.props.signup.landingMoveReset()
                
        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name && this.appContext?.appType !== 'WITHOUT_AUTH') {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Home Insurance',
                    category_name: 'Home Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        } else {
            AnalyticsService.pageViewed({ page: "Home Insurance" });
        }
        let addressForLocalStorage = localStorage.getItem("pods_destination_address");
        if (addressForLocalStorage) {
            let checkIfDoublequotes = JSON.parse(addressForLocalStorage)
            this.setState({ address: checkIfDoublequotes });
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.insuranceState;
        const cur = this.props && this.props.insuranceState;
        if (
            prev.appointment !== cur.appointment &&
            cur.appointment &&
            (cur.appointment.appointment_time || cur.appointment.id)
        ) {
            this.props.history.push('/dashboard/home-insurance/summary');
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashcodeDataArrived
        ) {
            currentState.isHashcodeDataArrived = false;
            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                // nextProps.commonAction.moveGet();
                if (
                    nextProps.commonState &&
                    nextProps.commonState.header &&
                    nextProps.commonState.header.length === 0
                ) {
                    localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                    const isHomeOwner = localStorage.getItem('isHomeOwner');
                    let slug: any = localStorage.getItem('slug');
                    slug = JSON.parse(slug);

                    nextProps.commonAction.header({
                        partner_slug: window.location.hostname.split('.')[0]
                            ? window.location.hostname.split('.')[0]
                            : slug && slug.slug,
                        fetchMoveService: isHomeOwner ? 1 : 0,
                    });
                }
                if (
                    !(
                        nextProps.history &&
                        nextProps.history.location &&
                        nextProps.history.location.state &&
                        nextProps.history.location.state.type === 'back'
                    )
                ) {
                    nextProps.insuranceAction.insuranceAppointmentGet();
                }
            } else {
                setTimeout(() => {
                    localStorage.clearStorage();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.header &&
            nextProps.commonState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let insurance: any = [];
            insurance = nextProps.commonState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'insurance',
            );

            if (insurance.length) {
                currentState.isDefault = insurance[0].is_default;
                currentState.contentText = insurance.content_text;
            }
        }
        return currentState;
    }
    buttonClicked = () => {
        this.props.history.push({
            pathname: '/dashboard/home-insurance/steps',
            state: {
                data: this.props.commonState && this.props.commonState.move && this.props.commonState.move[0],
            },
        });
    };
    handleAnalytics = () => {
        if (this.state.address?.street) {
            this.setState({
                isError: false,
            });
        } else {
            this.setState({
                isError: true,
            });
            return null
        }        
        this.props.history.push({
            pathname: '/dashboard/home-insurance/steps',
            state: {
                data: this.props.commonState && this.props.commonState.move && this.props.commonState.move[0],
                address: this.state.address
            },
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        if (this.appContext?.appType !== 'WITHOUT_AUTH') {
            let brokerage: any;
            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;
            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');
            let windownew: any = window;
    
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    
            analytics.track(
                'Button Clicked',
                {
                    page: 'Home Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'GET STARTED',
                    card: false,
                    card_title: '',
                    card_type: 'Start Page',
                    category_name: 'Home Insurance',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        } else {
            AnalyticsService.buttonClicked({
                page: 'Home Insurance',
                category_name: 'Home Insurance',
                button_copy: 'Get Started',
                form_button: true,
            });
        }
    };
    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                    const addressString = results[0].formatted_address;
                    let addressObj = {
                        street: `${streetNumber} ${route}`,
                        city,
                        zipcode: zip,
                        address: addressString,
                        state
                    };
                    localStorage.setItem('pods_destination_address', addressObj);
    
                    if (country === 'USA' || country === 'US') {
                        this.setState({
                            address : addressObj 
                        });
                    }
            })
            .catch(error => console.error('error', error));
    };
    
    onChangeHandlerStep1 = (e: any, name: string) => {
        let address: any;
        if (name === 'address') {
            address = { ...this.state.address, [name]: e };
        } else {
            address = { ...this.state.address, [name]: e.target.value };
        }

        this.setState(() => ({ address }));
    };

    handleAddressChange = (e: any) => {
        this.onChangeHandlerStep1(e, 'address')

        if (!this.state.isAnalitycsTriggerredOnce.address) {
            AnalyticsService.formFieldInput({ 
                page: "Home Insurance",
                hasMinimalFields: true,
                category_name: 'Home Insurance',
                form_field_name: 'Enter Address'
            });
        }

        this.setState({
            isAnalitycsTriggerredOnce: {
                address: true
            }
        })
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.state;
        return this.state.isDefault == true ? (
            <div className="HomeInsurance">
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="insurance"
                            onClick={this.buttonClicked}
                            isCoverImage={true}
                            headerText={`Hey ${this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    (this.props.commonState.move[0].user_ref.first_name ||
                                        this.props.commonState.move[0].user_ref.last_name)
                                    ? this.props.commonState.move[0].user_ref.first_name ||
                                    this.props.commonState.move[0].user_ref.last_name
                                    : ''
                                } Get Insurance Quotes `}
                            subheaderText={'Get multiple quotes in minutes!'}
                            highlightedText={'In Minutes'}
                            buttonText={'Get Started'}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="main-head-insurance">
                            <h2 className="heading-insurance-regular" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>Insurance</h2>
                            <div className="path-insurance">
                                <p
                                    className="Para-insurance"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (
                                            localStorage.getItem('isHomeOwner') &&
                                            localStorage.getItem('isHomeOwner') == 'true'
                                        ) {
                                            this.props.history.push('/dashboardhomeOwner');
                                        } else {
                                            this.props.history.push('/dashboard');
                                        }
                                    }}
                                >
                                    Dashboard {'>'}
                                </p>{' '}
                                <p style={{ marginLeft: '5px' }}>Insurance</p>
                            </div>
                        </div>
                        <div className="InsurancecoverDiv">
                            <div className="mainPageInsurance">
                                <Image className="InsuranceImageMain" />
                                <h2 className="heading" style={slug?.custom_primary_font ? { fontFamily: slug.custom_primary_font }: {}}>
                                Get home insurance quotes in minutes*
                                </h2>
                                <p className="sub-heading">
                                    Compare personalized insurance quotes from multiple providers all in one place
                                </p>
                                <div className="lineInsurance"></div>
                                <div className="btn-div">
                                    <div className='insurance-address' style={{
                                        marginRight:10
                                    }}>
                                        <AutoComplete
                                            value={this.state.address?.address}
                                            onChange={this.handleAddressChange}
                                            onSelect={this.handleSelect}
                                            placeholder="Enter Address"
                                            className='input'
                                        />
                                        {this.state.isError && <span className='error'> Please enter the address</span>}
                                    </div>
                                    <Button
                                        backgroundColor={
                                            slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.appContext.colors.button.bg
                                        }
                                        color={
                                            slug && slug.primary_font_color
                                                ? slug.primary_font_color
                                                : this.appContext.colors.button.text
                                        }
                                        fontSize="16px"
                                        width="100%"
                                        height="54px"
                                        borderRadius={'4px'}
                                        onClick={this.handleAnalytics}
                                        disabled={ !this.state.address?.street}
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </div>
                            <div className="note-text" style={{paddingBottom: '80px'}}>
                                *PODS is not a licensed insurance agency or insurance company.  All information provided on this website regarding insurance is for information purposes only and no solicitation or offer of insurance is being made by or on behalf of PODS. Insurance information, quotes and products are offered through InsuraMatch, LLC., a licensed insurance agency (CA: InsuraMatch Insurance Agency, LLC #0L09834, NY: InsuraMatch Agency # 1335192, TX:  InsuraMatch, LLC # 2138210), and the insurance companies that partner with InsuraMatch.  LiveEasy may receive compensation from InsuraMatch for allowing InsuraMatch to provide insurance through this website.  Neither LiveEasy nor PODS endorse any particular insurance carrier or policy that may appear on this website and are not involved in the sale, solicitation or negotiation of insurance.  Use of this website is completely optional and provided to you as a convenience to obtain insurance quotes. Not all insurance options that may be available to you are listed on this website.
                            </div>
                        </div>
                    </div>
                )}
                {this.appContext?.appType !== 'WITHOUT_AUTH' && 
                <Footer show={isDouglasElliman ? true : false} /> }
            </div>
        ) : (
            <CustomPage history={this.props.history} />
        );
        // }
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        commonAction: bindActionCreators(Action, dispatch),
        signup: bindActionCreators(signupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    insuranceState: state.insurance,
    commonState: state.common
});

HomeInsurance.contextType = UIContext;

export default connect(mapStateToProps, mapDispatchToProps)(HomeInsurance);
