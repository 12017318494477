import React from 'react';
import { uiConfig } from './config';

export const UIContext = React.createContext({
    ...uiConfig,
    // doSomething: () => {}
});

const UIControlProvider = (props: any) => {
  
    // const doSomething = () => {
    //   console.log(answer.value);
    //   console.log("Ready!");
    // };
  
    return (
      <UIContext.Provider value={uiConfig}>
        {props.children}
      </UIContext.Provider>
    );
  };
  export default UIControlProvider