import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    insuranceAppointmentFailure,
    insuranceAppointmentSuccess,
    insuranceQuoteFailure,
    insuranceQuoteSuccess,
    insuranceQuoteListFailure,
    insuranceQuoteListSuccess,
    updatePersonalFailure,
    updatePersonalSuccess,
    updatePropertyFailure,
    updatePropertySuccess,
    insuranceAppointmentGetFailure,
    insuranceAppointmentGetSuccess,
} from './actions';
import {
    UPDATE_MOVE_PERSONAL_INFO,
    UPDATE_MOVE_PROPERTY_INFO,
    INSURANCE_APPOINTMENTS,
    INSURANCE_QUOTE,
    INSURANCE_QUOTE_LIST,
    INSURANCE_APPOINTMENTS_GET,
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* insuranceQuote(action: any) {
    let paramObj : { method: string, url:string, token?:string, body?: any } = {
        method: 'post',
        url: `/api/services/insurance-quote/`,
        token: action.payload.token,
        body: action.payload.data
    }

    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, paramObj );
        if (response.data) {
            yield put(insuranceQuoteSuccess({ quote: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceQuoteFailure({ errorQuote: e.message }));
        // history.push('/server-error');
    }
}

function* insuranceQuoteList(action: any) {
    let paramObj : { method: string, url:string, token?:string } = {
        method: 'get',
        url: `/api/move/insurance-quote-list/`,
    }

    if(action.payload.token){
        paramObj = {...paramObj, token: action.payload.token }
    }

    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, paramObj);
        if (response.data) {
            yield put(insuranceQuoteListSuccess({ quoteList: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceQuoteListFailure({ errorQuoteList: e.message }));
        // history.push('/server-error');
    }
}

function* insuranceAppointment(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/insurance-appointments/`,
            body: action.payload,
        });
        
        if (response.data) {
            yield put(insuranceAppointmentSuccess({ appointment: { ...response.data, status: response.status } }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        
        if(e.response?.status == '400' && e.response?.data?.length )
            yield put(insuranceAppointmentSuccess({ appointment: { ...e.response?.data, status: 200 } }));
        else
            yield put(insuranceAppointmentFailure({ errorAppointment: e.message }));
        // history.push('/server-error');
    }
}

function* insuranceAppointmentGet() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/insurance-appointments/`,
        });
        if (response.data) {
            yield put(insuranceAppointmentGetSuccess({ appointmentGet: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceAppointmentGetFailure({ errorAppointmentGet: e.message }));
        // history.push('/server-error');
    }
}

function* updatePersonal(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/${action.payload.move_id}/update_move_insurance_personal_information/`,
            body: action.payload.payload,
        });
        if (response.data) {
            yield put(updatePersonalSuccess({ updatePersonal: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updatePersonalFailure({ errorUpdatePersonal: e.message }));
        // history.push('/server-error');
    }
}

function* updateProperty(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/${action.payload.move_id}/update_move_property_information/`,
            body: action.payload.payload,
        });

        if (response.data) {
            yield put(updatePropertySuccess({ updateProperty: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updatePropertyFailure({ errorUpdateProperty: e.message }));
        // history.push('/server-error');
    }
}

export default function* internetSaga() {
    yield all([
        takeLatest(INSURANCE_APPOINTMENTS, insuranceAppointment),
        takeLatest(INSURANCE_APPOINTMENTS_GET, insuranceAppointmentGet),
        takeLatest(INSURANCE_QUOTE, insuranceQuote),
        takeLatest(INSURANCE_QUOTE_LIST, insuranceQuoteList),
        takeLatest(UPDATE_MOVE_PERSONAL_INFO, updatePersonal),
        takeLatest(UPDATE_MOVE_PROPERTY_INFO, updateProperty),
    ]);
}
