import React, { Component } from 'react';
import './TrustedProsScheduledServices.scss';
import ServiceCard from '../../components/TrustedProsComponents/scheduledServicesCard';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type initialProps = {
    history?: any;
    location?: any;
};

type initialState = {
    expand: boolean;
    AnchorEl?: any;
    sort: any;
};
class TrustedProsScheduledServices extends Component<initialProps, initialState> {
    state: initialState = {
        expand: true,
        AnchorEl: null,
        sort: { value: 'latest', label: 'Latest' },
    };

    getFirstCharacter = (str: string) => {
        // let matches: any = str.match(/\b(\w)/g);
        let acronym: any = str.charAt(0) + str.charAt(str.indexOf(' ') + 1);
        return acronym;
    };

    handleCollapse = () => {
        this.setState({
            expand: false,
        });
    };

    handleExpand = () => {
        this.setState({
            expand: true,
        });
    };

    handleOldest = () => {
        this.setState({
            sort: { value: 'oldest', label: 'Oldest' },
        });

        this.handleClose();
    };
    handleLatest = () => {
        this.setState({
            sort: { value: 'latest', label: 'Latest' },
        });

        this.handleClose();
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    render() {
        let vendorlist: any = [];
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.vendorlist) {
            vendorlist = this.props.location.state.vendorlist;
        }
        const { expand } = this.state;

        if (this.state.sort.value == 'oldest') {
            vendorlist = vendorlist
                .sort((a: any, b: any) => (a.date > b.date ? 1 : -1))
                .map((item: any) => {
                    return item;
                });
        }
        if (this.state.sort == 'latest') {
            vendorlist = vendorlist
                .sort((a: any, b: any) => (a.date < b.date ? 1 : -1))
                .map((item: any) => {
                    return item;
                });
        }
        return (
            <div className="TrustedScheduledServicesMain">
                <h2 className="TrustedScheduledServicesheading">Trusted Pros</h2>
                <div className="TrustedScheduledServicespath">
                    <p
                        className="TrustedScheduledServicesPara"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
                                this.props.history.push('/dashboardhomeOwner');
                            } else {
                                this.props.history.push('/dashboard');
                            }
                        }}
                    >
                        Dashboard {'>'}
                    </p>{' '}
                    <p style={{ marginLeft: '5px' }} className="TrustedScheduledServicesPara">
                        Trusted Pros {'>'}
                    </p>{' '}
                    <p style={{ marginLeft: '5px' }}>Scheduled Services</p>
                </div>

                <div className="TrustedScheduledRow">
                    <div className="TrustedScheduledSort">
                        <div
                            className="TrustedScheduledSortIcon"
                            onClick={this.handleMenu}
                            style={{
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div>Sort by: {this.state.sort && this.state.sort.label}</div>
                            <ExpandMoreIcon />
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.AnchorEl}
                            keepMounted
                            open={Boolean(this.state.AnchorEl)}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.handleLatest}>Latest</MenuItem>
                            <MenuItem onClick={this.handleOldest}>Oldest</MenuItem>
                        </Menu>
                    </div>
                </div>

                <div className="TrustedScheduledAccordion">
                    <div className="TrustedScheduledLine" />
                    <div className="TrustedScheduledHead">{vendorlist[0].category}</div>
                    <div className="TrustedScheduledNumber">{vendorlist.length}</div>
                    <div className="TrustedScheduledLine" />
                    <div className="TrustedScheduledShareCollapse">
                        <div style={{ paddingRight: '7px' }}></div>
                        <div>
                            {expand ? (
                                <div onClick={this.handleCollapse}>
                                    <Tooltip title="Collapse this section" placement="top" arrow>
                                        <RemoveCircleOutlineIcon style={{ color: '#6b6c6f' }} />
                                    </Tooltip>
                                </div>
                            ) : (
                                <div onClick={this.handleExpand}>
                                    <Tooltip title="Expand this section" placement="top" arrow>
                                        <ControlPointIcon style={{ color: '#6b6c6f' }} />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {expand
                    ? vendorlist.map((item: any) => {
                          return (
                              <div className="scheduleServiceCard">
                                  <ServiceCard
                                      company={item.vendor.name}
                                      firstChar={this.getFirstCharacter(item.vendor.name)}
                                      rating={item.vendor.google_rating + item.vendor.yelp_rating}
                                      phone={item.vendor.phone_no}
                                      address1={item.vendor.address}
                                      date={item.date_str}
                                      time={item.time_str}
                                      address2={item.address}
                                      //   vendorContactStatus="Pending"
                                      //   btnText="Cancel Service"
                                      bgColor="#FFF0C8"
                                      requested={item.date}
                                  />
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    }
}

export default TrustedProsScheduledServices;
