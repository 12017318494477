import React, { Component } from 'react';
import RoomIcon from '@material-ui/icons/Room';
// import SelectInput from '../../components/atoms/SelectInput';
import MovingStorageFacilityCard from '../../components/atoms/MovingStorageFacilityCard';
import RatingIcon from '../../Assets/images/MovingImages/rating.svg';
import RatingIconBlue from '../../Assets/images/MovingImages/ratingBlue.svg';
// import logo from '../../Assets/images/MovingImages/facilityImage.png';
// import StorageCardDetails from './StorageCardDetails';
// import history from '../../routes/History';
import './Moving.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/move/actions';
import * as MoveAction from '../../store/common/actions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import SortIcon from '@material-ui/icons/Sort';
import Footer from './Footer';
import * as DashboardAction from '../../store/dashboard/actions';
import downArrow from '../../Assets/images/Moving/down-arrow.png';
import history from '../../routes/History';
import { buttonClicked } from '../../utils/utlis';

type initialProps = {
    moversAction?: any;
    moversState?: any;
    moveAction?: any;
    moveState?: any;
    // destinationZipCode?: string;
    // destinationAddress?: string;
    dashboardAction: any;
    CheckListDashboardState: any;
    history: any;
};

type initialState = {
    sortBy: string;
    // facilityData: any;
    isStorageFacilityCards: boolean;
    isStorageDetails: boolean;
    isCompleteReservation: boolean;
    facilityId: any;
    facilityName: string;
    facilityAddress: string;
    facilityImage: any;
    isDataArrived?: boolean;
    destinationZipCode: string;
    destinationAddress: string;
    AnchorEl?: any;
    moveId: any;
    isDataArrived2: boolean;
    isDataArrived3: boolean;
    checkedItems: any;
    serviceId: any;
    completedMovingPercentage: any;
    hashCode: any;
    isDataArrivedNew: boolean;
    isDataArrivedHeader: boolean;
    movingService: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    carShippingService: boolean;
};

class ReverseStorageFacility extends Component<initialProps, initialState> {
    state: initialState = {
        sortBy: '',
        AnchorEl: null,
        // facilityData: [
        // {
        //   id:1,
        //     title: 'Tri-Village Self Storage',
        //     subTitle: '3490 Trabue Road Columbus, OH 43204',
        //     overall_rating: '4.9',
        //     verified_reviews: '200 verified reviews',
        //     logo: logo,
        // },
        // ],
        isStorageFacilityCards: true,
        isStorageDetails: false,
        isCompleteReservation: false,
        facilityId: '',
        facilityName: '',
        facilityAddress: '',
        facilityImage: '',
        isDataArrived: false,
        destinationZipCode: '',
        destinationAddress: '',
        moveId: null,
        isDataArrived2: true,
        serviceId: '',
        completedMovingPercentage: '',
        checkedItems: [],
        isDataArrived3: true,
        hashCode: '',
        isDataArrivedNew: true,
        isDataArrivedHeader: true,
        movingService: false,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        carShippingService: false,
    };

    handleLTH = () => {
        this.setState({ sortBy: 'Low to High' });
        this.handleClose();
    };

    handleHTL = () => {
        this.setState({ sortBy: 'High to Low' });
        this.handleClose();
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props.moveState &&
                this.props.moveState.move &&
                this.props.moveState.move[0] &&
                this.props.moveState.move[0].user_ref
            )
        ) {
            this.props.moveAction.moveGet();
        }
        // this.props.moveAction.moveGet();
        this.props.moversAction.selectedMovers();
        this.props.dashboardAction.checklist();
        this.setState({ isDataArrived: true });
        // const payload = {
        //     zip_code: this.props.destinationZipCode,
        // };
        // this.props.moversAction.reverseStorage(payload);

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.moveAction.moveGetWithHashCode(payload);
        }
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Reserve Storage Facility',
                    category_name: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.moveWithHashCode &&
            nextProps.moveState.moveWithHashCode[0] &&
            currentState.isDataArrivedNew
        ) {
            currentState.isDataArrivedNew = false;
            let moveData = nextProps.moveState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                nextProps.moversAction.selectedMovers();
                nextProps.dashboardAction.checklist();
                nextProps.moveAction.moveGet();
                localStorage.setItem('token', nextProps.moveState.moveWithHashCode[0].user_ref.token);
            } else {
                localStorage.clear();
                history.push({
                    pathname: `/signUp`,
                    search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                });
            }
        }
        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.move &&
            nextProps.moveState.move[0] &&
            currentState.isDataArrived
        ) {
            nextProps.moveState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.moveId = item && item.id;
                currentState.destinationZipCode =
                    item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.destinationAddress =
                    item.destination && item.destination_full_address ? item.destination_full_address : '';
                const payload = {
                    zip_code: currentState.destinationZipCode,
                };
                nextProps.moversAction.reverseStorage(payload);
            });
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.map((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/storage')) {
                    currentState.serviceId = item.id;
                }
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.completedMovingPercentage = item.completed_percentage;
                }
            });
        }

        if (
            nextProps &&
            nextProps.moversState &&
            nextProps.moversState.selectedMovers &&
            nextProps.moversState.selectedMovers.length &&
            currentState.isDataArrived3
        ) {
            currentState.isDataArrived3 = false;
            currentState.checkedItems = nextProps.moversState.selectedMovers;
        }

        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.header &&
            nextProps.moveState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let moving: any = [];
            let carshipping: any = [];
            moving = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'moving service',
            );
            carshipping = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'car shipping',
            );

            carshipping = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'car shipping',
            );

            if (moving.length) {
                currentState.movingService = true;
            }
            if (carshipping.length) {
                currentState.carShippingService = true;
            }
        }
        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.moveState;
        const cur = this.props && this.props.moveState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest: any = cur.move[0] && cur.move[0].destination;
            this.setState({
                // city: dest && dest.city_name,
                // state: { name: dest && dest.state_code, label: dest && dest.state_code },
                destinationZipCode: dest && dest.zip_code,
                // street: cur.move[0] && cur.move[0].dest_street,
                destinationAddress: cur.move[0] && cur.move[0].destination_full_address,
                moveId: cur.move[0] && cur.move[0].id,
            });
            const payload = {
                zip_code: dest && dest.zip_code,
            };
            this.props.moversAction.reverseStorage(payload);
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.moveAction.moveGet();
        }
    }

    viewDetailsLink = (item: any) => {
        // history.push(`dashboard/movers/reverse_storage_facility/${item.id}`)
        this.setState({
            isStorageFacilityCards: false,
            // isStorageDetails: true,
            isCompleteReservation: false,
            facilityId: item.id,
            facilityName: item.title,
            facilityAddress: item.subTitle,
            facilityImage: item.logo,
        });

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            buttonClicked(
                'Button Clicked',
                'Storage Facility',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                'Moving Services',
                'View Pricing',
                false,
                '',
                true,
                item.title,
                'Storage Facility Card',
                false,
                '',
                false,
                '',
                '',
                move_id,
            );

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Product Details Viewed',
                {
                    page: 'Reserve Stroage Facility',
                    category_name: 'Reserve Stroage Facility',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card: true,
                    card_title: item && item.title,
                    product_name: 'Reserve Stroage Facility',
                    brand_name: item && item.title,
                },
                { user_id: move_id },
            );
        }
        this.props.history.push({
            pathname: `/dashboard/storage/id=${item.id}`,
            state: {
                facilityId: item.id,
                facilityName: item.title,
                facilityAddress: item.subTitle,
                facilityImage: item.logo,
            },
        });
        window.scrollTo(0, 0);
        this.props.dashboardAction.updateServicePercentage({
            data: {
                completed_percentage: 33,
            },
            service_id: this.state.serviceId,
        });
    };

    // sortBy = (e: any) => {
    //     this.setState({ sortBy: e.value });
    // };
    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                // console.log(
                //     'Date of results',
                //     results && results[0] && results[0].address_components && results[0].address_components,
                // );
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : el.types.includes('administrative_area_level_3')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                // console.log("el",zip)
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        destinationAddress: add,
                        destinationZipCode: zip,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    // console.log('Heyyy it working');
                    this.props.moveAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }

                // console.log('address after call', `${streetNumber} ${route}, ${city}, ${state}, ${zip}`);
            })
            .catch(error => console.error('error', error));
    };
    render() {
        const facilityData: any = [];
        if (this.props && this.props.moversState && this.props.moversState.reverseStorage) {
            this.props.moversState.reverseStorage.map((item: any) => {
                facilityData.push({
                    id: item.facilityId,
                    title: item.name,
                    subTitle: item.address,
                    overall_rating: item.average_rating,
                    verified_reviews: '200 verified reviews',
                    logo: item.image,
                });
            });
        }
        if (this.state.sortBy !== '' && this.state.sortBy === 'High to Low') {
            facilityData.sort((a: any, b: any) => {
                let overallRatingA = parseFloat(a.overall_rating);
                let overallRatingB = parseFloat(b.overall_rating);
                if (overallRatingA < overallRatingB) {
                    return 1;
                }
                if (overallRatingA > overallRatingB) {
                    return -1;
                }
                return 0;
            });
        }
        if (this.state.sortBy !== '' && this.state.sortBy === 'Low to High') {
            facilityData.sort((a: any, b: any) => {
                let overallRatingA = parseFloat(a.overall_rating);
                let overallRatingB = parseFloat(b.overall_rating);
                if (overallRatingA < overallRatingB) {
                    return -1;
                }
                if (overallRatingA > overallRatingB) {
                    return 1;
                }
                return 0;
            });
        }
        const { isDouglasElliman } = this.state;
        return (
            <div className={isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'}>
                <div className="moving-main-div">
                    {this.state.isStorageFacilityCards ? (
                        <div style={{ width: '100%' }}>
                            <div className="main-head-2">
                                <h2 className="heading">Moving Services</h2>
                                <div className="path">
                                    <p
                                        className="Para"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (
                                                localStorage.getItem('isHomeOwner') &&
                                                localStorage.getItem('isHomeOwner') == 'true'
                                            ) {
                                                this.props.history.push('/dashboardhomeOwner');
                                            } else {
                                                this.props.history.push('/dashboard');
                                            }
                                        }}
                                    >
                                        {' '}
                                        Dashboard {'>'}
                                    </p>{' '}
                                    <p
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            this.props.history.push('/dashboard/movers');
                                        }}
                                    >
                                        {' '}
                                        Moving Services {'>'}
                                    </p>{' '}
                                    <p>Reserve a Storage Unit</p>
                                </div>
                            </div>
                            <div className="tab-div-moving">
                                {this.state.movingService == true ? (
                                    <div
                                        className={
                                            this.props &&
                                            this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.pathname &&
                                            (this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                                this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                                this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                                ? 'tab-active'
                                                : 'tab-text'
                                        }
                                        onClick={() => {
                                            if (
                                                this.state.completedMovingPercentage >= 60 &&
                                                this.state.completedMovingPercentage < 100
                                            ) {
                                                this.props.history.push('/dashboard/movers/recommended');
                                            }
                                            if (this.state.completedMovingPercentage == 100) {
                                                this.props.history.push({
                                                    pathname: '/dashboard/movers/thankyou',
                                                    state: {
                                                        checkedItems: this.state.checkedItems,
                                                    },
                                                });
                                            }
                                            if (this.state.completedMovingPercentage < 60) {
                                                this.props.history.push('/dashboard/movers');
                                            }
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Moving
                                    </div>
                                ) : null}
                                {this.state.carShippingService === true ? (
                                    <div
                                        className={
                                            this.props &&
                                            this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.pathname &&
                                            this.props.history.location.pathname === '/dashboard/autoease'
                                                ? 'tab-active'
                                                : 'tab-text'
                                        }
                                        onClick={() => {
                                            if (this.props && this.props.history) {
                                                this.props.history.push('/dashboard/autoease');
                                            }
                                        }}
                                    >
                                        Auto Shipping
                                    </div>
                                ) : null}
                                <div
                                    className={
                                        this.props &&
                                        this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.pathname &&
                                        (this.props.history.location.pathname === '/dashboard/storage' ||
                                            this.props.history.location.pathname === '/dashboard/storage/')
                                            ? 'tab-active'
                                            : 'tab-text'
                                    }
                                    onClick={() => {
                                        this.props.history.push('/dashboard/storage');
                                    }}
                                >
                                    Reserve a Storage Unit
                                </div>
                            </div>
                            <div className="storageFacilityDiv">
                                <div className="headerDiv">
                                    <div className="titleDiv">
                                        <span className="titleHeader">{`5 storage facilities in`}</span>
                                        <div
                                            style={{ display: 'flex', alignItems: 'flex-end' }}
                                            className="reserveStorage-autocompleteDiv"
                                        >
                                            <RoomIcon className="roomIcon" />
                                            {/* <span className="titleLocation">{this.state.destinationAddress}</span> */}
                                            <AutoComplete
                                                className="reserveStorage-autocomplete"
                                                value={
                                                    this.state.destinationAddress ? this.state.destinationAddress : ''
                                                }
                                                onChange={e => {
                                                    this.setState({ destinationAddress: e });
                                                }}
                                                onSelect={this.handleSelect}
                                                placeholder="Location"
                                                // inputTitle="To"
                                                edit={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="selectDiv">
                                        <div
                                            className="avg-customer-review"
                                            onClick={this.handleMenu}
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {' '}
                                            <SortIcon className="sortIcon" />
                                            Sort by: Recommended
                                        </div>
                                        <div className="down-arrow">
                                            <img src={downArrow} className="sortArrow" />
                                        </div>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={this.state.AnchorEl}
                                            keepMounted
                                            open={Boolean(this.state.AnchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                            <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                        </Menu>
                                        {/* <span className="selectLabel">sort by:</span>
                                    <div>
                                        <SelectInput
                                            className="react-select sortBy-select"
                                            onChange={this.sortBy}
                                            options={[
                                                { value: 'High to Low', label: 'Rating High to Low' },
                                                { value: 'Low to High', label: 'Rating Low to High' },
                                            ]}
                                            placeholder={this.state.sortBy ? this.state.sortBy : 'Select'}
                                            value={this.state.sortBy}
                                        />
                                    </div> */}
                                        <div></div>
                                    </div>
                                </div>
                                <div className="facilityCardsDiv">
                                    {facilityData.length ? (
                                        facilityData.map((item: any) => {
                                            return (
                                                <MovingStorageFacilityCard
                                                    className="facilityCard"
                                                    // width="98%"
                                                    cardImage={item.logo}
                                                    cardTitle={item.title}
                                                    cardSubTitle={item.subTitle}
                                                    rate={item.overall_rating}
                                                    ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                                                    verifiedReview={item.verified_reviews}
                                                    handlePriceDetails={() => console.log()}
                                                    viewDetailsLink={() => this.viewDetailsLink(item)}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div>
                                            <MovingStorageFacilityCard className="facilityCard" />
                                            <MovingStorageFacilityCard className="facilityCard" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* {this.state.isStorageDetails ? (
                    <StorageCardDetails
                        facilityId={this.state.facilityId}
                        facilityName={this.state.facilityName}
                        facilityAddress={this.state.facilityAddress}
                        facilityImage={this.state.facilityImage}
                    />
                ) : null} */}
                </div>
                <div className="moving-Footer">
                    <Footer />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(Action, dispatch),
        moveAction: bindActionCreators(MoveAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    moveState: state.common,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReverseStorageFacility);
