import { uiConfig } from '../config';

export const colorSelector = (landing: boolean, flag: string = 'a'): string => {
    let slug: any = localStorage.getItem('slug');
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    if (slug) {
        slug = JSON.parse(slug);
    }
    let color = '';
    if (flag == 'a') {
        if (slug && slug.color_shade4) {
            if (isDouglasElliman) {
                color = '#ffffff';
            }

            color = slug.color_shade4;
        } else {
            if (landing) {
                color = '#30ab86';
            }
            color = '#8195ac';
        }
        if (!isDouglasElliman) {
            color = '#ffffff';
        }
    } else if (flag == 'b') {
        if (slug && slug.primary_color) {
            if (isDouglasElliman) {
                color = '#333333';
            }

            color = slug.primary_color;
        } else {
            if (landing) {
                color = '#1D7E64';
            }
            color = '#273e59';
        }

        if (!isDouglasElliman) {
            color = '#1c3947';
        }
    }

    return color;
};

export const navigationIconColorSelector = (active: boolean = false, flag: string = 'a', type: string): string => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    const { fill: fillColor, stroke: strokeColor } = uiConfig.colors.icon;
    let color = '#bebebe';
    if (flag == 'a') {
        if(type == "fill") {
            if(fillColor){
                if(active && slug)
                    return slug.primary_color
                else
                    return fillColor
            }

        } else if(type == "stroke" && strokeColor){
            return strokeColor
        }
        return '#bebebe'
    } else if (flag == 'b') {
        if(type == "fill" && fillColor){
            return fillColor
        } else if (type == "stroke" && strokeColor){
            return strokeColor
        } else if (slug && slug.primary_color) {
            return slug.primary_color;
        }
        return '#6b6c6f'
    }    
   
    return color;
};
