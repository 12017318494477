import React, { Component } from 'react';
import './homeSecurity.scss';
import Footer from './Footer';
import Image from '../../Assets/images/HomeSecurity/homeSecurityMain';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { UIContext } from '../../UIContext';

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    hashCode: any;
    isDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
};

class HomeSecurity extends Component<initialProps, initialState> {
    uiControl = this.context;
    state: initialState = {
        hashCode: '',
        isDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }

        const appType = this.uiControl.appType
        // console.log(this.uiControl)
        if(appType !== 'WITHOUT_AUTH')
        if (!this.state.hashCode && !localStorage.getItem('token')) {

            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);

            let brokerage: any;
            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Home Security',
                    category_name: 'Home Security',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                const isHomeOwner = localStorage.getItem('isHomeOwner');
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);

                nextProps.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0]
                        ? window.location.hostname.split('.')[0]
                        : slug && slug.slug,
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }

        return currentState;
    }

    handleAnalytics = () => {
        this.props.history.push({
            // pathname:'/dashboard/home-security/steps',
            pathname: '/dashboard/home-security/recommendations',
        });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Button Clicked',
            {
                page: 'Home Security',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'GET STARTED',
                card: false,
                card_title: 'Home Security',
                card_type: 'Start Page',
                category_name: 'Home Security',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;
        return (
            <div>
                {' '}
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            isCoverImage={true}
                            pageImage="homeSecurity"
                            onClick={() =>
                                this.props.history.push({
                                    // pathname:'/dashboard/home-security/steps',
                                    pathname: '/dashboard/home-security/recommendations',
                                })
                            }
                            headerText={`Protect Your Home With`}
                            subheaderText={'Quickly and Easily get multiple quotes in minutes!'}
                            highlightedText={'Smart Home Security'}
                            buttonText={'Get Started'}
                        />
                        <Footer history={this.props.history} />
                    </div>
                ) : (
                    <div className="hsPage">
                        <div className="hs-main-head">
                            <div>
                                <h2 className="hs-heading">Home Security</h2>
                                <div className="hs-path">
                                    <p
                                        className="hs-Para"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (
                                                localStorage.getItem('isHomeOwner') &&
                                                localStorage.getItem('isHomeOwner') == 'true'
                                            ) {
                                                this.props.history.push('/dashboardhomeOwner');
                                            } else {
                                                this.props.history.push('/dashboard');
                                            }
                                        }}
                                    >
                                        Dashboard {'>'}
                                    </p>{' '}
                                    <p style={{ marginLeft: '5px' }}>Home Security</p>
                                </div>
                            </div>
                        </div>
                        <div className="hs-FirstPage ">
                            <div className="hs-landing">
                                <Image className="hs-mainImg" />
                                <h2 className="hs-heading">Protect your home 24/7 with smart home monitoring plans</h2>
                                <p className="hs-subtext">
                                    Protect your home 24/7 with smart home monitoring plans.Quickly and easily get
                                    multiple quotes in minutes!
                                </p>
                                <div className="hs-line"></div>
                                <div className="hs-btn-div">
                                    <div className="hs-btn-div1">
                                        <Button
                                            className="btnhs"
                                            height="50px"
                                            borderRadius='4px'
                                            width="100%"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            onClick={this.handleAnalytics}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer history={this.props.history} />
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});
HomeSecurity.contextType = UIContext
export default connect(mapStateToProps, mapDispatchToProps)(HomeSecurity);
