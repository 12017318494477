import React from 'react';
import TextField from '@material-ui/core/TextField';
import '../../styles/Login.scss';
import { connect } from 'react-redux';
import * as Action from '../../store/login/actions';
import { bindActionCreators } from 'redux';
import Button from '../../components/atoms/Button';
import mobileLogin from '../../Assets/images/login/loginbackMobile.svg';

type initialProps = {
    history: any;
    loginAction: any;
    loginState: any;
};

type initialState = {
    newPassword: string;
    confirmPassword: string;
    passwordErr: string;
    confirmPasswordErr: string;
    hashCode: string;
};

class ResetPassword extends React.Component<initialProps, initialState> {
    state: initialState = {
        newPassword: '',
        confirmPassword: '',
        passwordErr: '',
        confirmPasswordErr: '',
        hashCode: '',
    };

    componentDidMount() {
        if (window.location.pathname.includes('resetpassword/')) {
            this.setState({
                hashCode: window.location.pathname.split('resetpassword/')[1],
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.loginState && nextProps.loginState.passwordResetSuccessful) {
            nextProps.loginState.passwordResetSuccessful = false;
            currentState.newPassword = '';
            currentState.confirmPassword = '';

            nextProps.history.push('/login');
        }
        return currentState;
    }

    handlePasswordChange = (e: any) => {
        this.setState({
            newPassword: e.target.value,
        });
    };

    handleConfirmPasswordChange = (e: any) => {
        this.setState({
            confirmPassword: e.target.value,
        });
    };

    backToLoginHandler = () => {
        this.props.history.push('/login');
    };

    validateFields = () => {
        let validate = true;

        if (this.state.newPassword.length < 8 || this.state.newPassword.length > 16) {
            validate = false;
            this.setState({
                passwordErr: 'Password length should be between 8 to 16 characters',
            });
        } else {
            this.setState({
                passwordErr: '',
            });
        }

        return validate;
    };

    handleReset = () => {
        if (this.validateFields()) {
            if (this.state.confirmPassword != '' && this.state.confirmPassword != this.state.newPassword) {
                this.setState({
                    confirmPasswordErr: 'Password does not match',
                });
            } else {
                this.setState({
                    confirmPasswordErr: '',
                });

                this.props.loginAction.resetPasswordData({
                    hash_code: this.state.hashCode,
                    password: this.state.newPassword,
                });
            }
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const {
            newPassword,
            passwordErr,
            confirmPassword,
            confirmPasswordErr,
            // , keepMeSignedIn
        } = this.state;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={isDouglasElliman ? 'container containerDouglas' : 'container'}>
                <div className={isDouglasElliman ? 'login-getStartedDiv login-getStartedDivD' : 'login-getStartedDiv'}>
                    <div className="loginContainer">
                        <div className="loginContent">
                            <div>
                                <div className="loginWelcomeText">Reset Password</div>
                                <div
                                    className="loginLine"
                                    style={{
                                        backgroundColor: slug && slug.primary_color ? slug.primary_color : '#273e59',
                                    }}
                                ></div>
                                {/* <div className="loginSignInText">Reset Password</div> */}
                            </div>

                            <div className="login-form-main-div">
                                <div>
                                    <div className="Login-inputDiv">
                                        <TextField
                                            className="loginInput"
                                            error={Boolean(passwordErr)}
                                            type="password"
                                            label="Enter New Password"
                                            id="outlined-start-adornment"
                                            variant="filled"
                                            onChange={e => this.handlePasswordChange(e)}
                                            // onKeyPress={this.handleKeyPress}
                                            value={newPassword}
                                        />
                                        {passwordErr !== '' ? (
                                            <div className="errorLogin">
                                                <small>{passwordErr}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="Login-inputDiv">
                                        <TextField
                                            className="loginInput"
                                            error={Boolean(confirmPasswordErr)}
                                            type="password"
                                            label="Confirm New Password"
                                            id="outlined-start-adornment"
                                            variant="filled"
                                            onChange={e => this.handleConfirmPasswordChange(e)}
                                            // onKeyPress={this.handleKeyPress}
                                            value={confirmPassword}
                                        />
                                        {confirmPasswordErr !== '' ? (
                                            <div className="errorLogin">
                                                <small>{confirmPasswordErr}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    <Button
                                        className="signInButton"
                                        onClick={this.handleReset}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        disabled={this.state.newPassword && this.state.confirmPassword ? false : true}
                                    >
                                        Reset Password
                                    </Button>

                                    <Button
                                        className="signInButton"
                                        onClick={this.backToLoginHandler}
                                        boxShadow={`0 0 3px 0 ${
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273E59'
                                        }`}
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        backgroundColor="#fff"
                                    >
                                        Back to Login
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loginMobileImgBottom">
                    <img src={mobileLogin} alt="" width="100%" />
                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        loginAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    loginState: state.login,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
