import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DocumentryAction from '../../store/documentary/actions';
import NavPreview from './PreviewNav';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import RightNavPreview from './DocumentLeftNav';
// import consumerImage from '../../Assets/images/documentry/consumer.png';
import Modal from '../../components/atoms/Modal';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SelectInput from '../../components/atoms/SelectInput';
import Button from '../../components/atoms/Button';
import pdfIcon from '../../Assets/images/documentry/pdfIcon.png';
import csvIcon from '../../Assets/images/documentry/csvIcon.png';
import wordIcon from '../../Assets/images/documentry/wordIcon.png';
import docIcon from '../../Assets/images/documentry/documentIcon.jpg';

type initialProps = {
    history?: any;
    documentryAction?: any;
    documentryState?: any;
};

type initialState = {
    toggling: boolean;
    deleteDocument: boolean;
    documentData: any;
    currentDocument: any;
    editDocument: boolean;
    name: string;
    imageUrl: any;
    imageObject: any;
    description: string;
    foldersList: any;
    folder: any;
    continueClicked: boolean;
    folderId: any;
    documentId: any;
};
class documentPreview extends Component<initialProps, initialState> {
    state: initialState = {
        toggling: false,
        deleteDocument: false,
        editDocument: false,
        documentData: [],
        currentDocument: null,
        name: '',
        imageUrl: '',
        imageObject: null,
        description: '',
        foldersList: [],
        folder: null,
        continueClicked: false,
        folderId: '',
        documentId: '',
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            this.setState({
                folderId: this.props.history.location.state.folderId ? this.props.history.location.state.folderId : '',
                documentId: this.props.history.location.state.currentDocumentId
                    ? this.props.history.location.state.currentDocumentId
                    : '',
                foldersList: this.props.history.location.state.folderOptionsSelect
                    ? this.props.history.location.state.folderOptionsSelect
                    : [],
            });
        }
        this.props.documentryAction.getFolders();
        this.props.documentryAction.getDocuments();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getFolders &&
            nextProps.documentryState.isGetFolders
        ) {
            nextProps.documentryState.isGetFolders = false;
            let folders: any = [];

            nextProps.documentryState.getFolders.data.map((item: any) => {
                folders.push({
                    item: { id: item[0], name: item[1], description: item[2] },
                    value: item[0],
                    label: item[1],
                });
            });
            currentState.foldersList = folders;
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.updateDocuments &&
            // nextProps.documentryState.updateDocuments.data &&
            nextProps.documentryState.isUpdateDocuments
        ) {
            nextProps.documentryState.isUpdateDocuments = false;
            currentState.editDocument = false;
            currentState.currentDocument = nextProps.documentryState.updateDocuments;
            currentState.name = '';
            currentState.description = '';
            currentState.imageUrl = '';
            currentState.imageObject = null;
            currentState.folder = null;
            nextProps.documentryAction.getDocuments();
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            // nextProps.documentryState.deleteDocuments &&
            // nextProps.documentryState.updateDocuments.data &&
            nextProps.documentryState.isDeleteDocuments
        ) {
            nextProps.documentryState.isDeleteDocuments = false;
            currentState.deleteDocument = false;
            currentState.currentDocument = null;
            currentState.name = '';
            currentState.description = '';
            currentState.imageUrl = '';
            currentState.imageObject = null;
            currentState.folder = null;
            nextProps.documentryAction.getDocuments();
            nextProps.history.push('/dashboard/my-documents/documents');
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getDocuments &&
            nextProps.documentryState.getDocuments.results &&
            nextProps.documentryState.isGetDocuments
        ) {
            nextProps.documentryState.isGetDocuments = false;
            let documentsResults: any = [];

            nextProps.documentryState.getDocuments.results.map((item: any) => {
                documentsResults.push(item);
                return documentsResults;
            });
            documentsResults = documentsResults.filter((item: any) => item.folders_id == currentState.folderId);

            if (currentState.currentDocument == null) {
                let currentDocument: any = null;
                documentsResults.map((item: any) => {
                    if (item.id == currentState.documentId) {
                        currentDocument = item;
                    }
                });
                currentState.currentDocument = currentDocument;
            }

            currentState.documentData = documentsResults;
        }

        return currentState;
    }
    toggle = () => {
        this.setState({
            toggling: !this.state.toggling,
        });
    };
    handleBack = () => {
        this.setState({
            toggling: false,
        });
    };
    deleteImage = () => {
        this.setState({
            deleteDocument: true,
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Delete',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    deleteHandler = () => {
        let paylaod: any = {
            id: this.state.currentDocument && this.state.currentDocument.id,
        };
        this.props.documentryAction.deleteDocuments(paylaod);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Yes, Delete It',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    editHandle = () => {
        this.setState({
            editDocument: true,
            name: this.state.currentDocument && this.state.currentDocument.name ? this.state.currentDocument.name : '',
            description:
                this.state.currentDocument && this.state.currentDocument.description
                    ? this.state.currentDocument.description
                    : '',
            imageUrl:
                this.state.currentDocument && this.state.currentDocument.document
                    ? this.state.currentDocument.document
                    : '',
            folder:
                this.state.currentDocument && this.state.currentDocument.folders
                    ? {
                          item: [
                              this.state.currentDocument.folders.id,
                              this.state.currentDocument.folders.name,
                              this.state.currentDocument.folders.slug,
                          ],
                          label: this.state.currentDocument.folders.name,
                          value: this.state.currentDocument.folders.id,
                      }
                    : null,
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Edit',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    editDocumentHandler = () => {
        this.setState({ continueClicked: true });
        if (
            this.state.currentDocument &&
            this.state.currentDocument.move_id &&
            this.state.name &&
            // this.state.imageObject &&
            // this.state.imageObject != null &&
            this.state.folder &&
            this.state.folder.value
        ) {
            let payload: any = {
                move_id: this.state.currentDocument && this.state.currentDocument.move_id,
                folders_id: this.state.folder && this.state.folder.value,
                name: this.state.name,
                description: this.state.description,
                // document: this.state.imageObject,
            };
            const form = new FormData();
            for (var key in payload) {
                form.append(key, payload[key]);
            }
            if (this.state.imageObject && this.state.imageObject != null) {
                form.append('document', this.state.imageObject);
            }
            this.props.documentryAction.updateDocuments({
                formData: form,
                id: this.state.currentDocument && this.state.currentDocument.id,
            });
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Done',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    handleGoback = () => {
        this.setState({ deleteDocument: false });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Go back',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let imageIcon: any = '';
        if (this.state.imageUrl) {
            if (this.state.imageUrl && this.state.imageUrl.includes('pdf')) {
                imageIcon = pdfIcon;
            } else if (this.state.imageUrl && this.state.imageUrl.includes('csv')) {
                imageIcon = csvIcon;
            } else if (
                this.state.imageUrl &&
                (this.state.imageUrl.includes('.doc') ||
                    this.state.imageUrl.includes('docx') ||
                    this.state.imageUrl.includes('.docm') ||
                    this.state.imageUrl.includes('dot') ||
                    this.state.imageUrl.includes('dotm') ||
                    this.state.imageUrl.includes('dotx'))
            ) {
                imageIcon = wordIcon;
            } else if (
                this.state.imageUrl &&
                (this.state.imageUrl.includes('gif') ||
                    this.state.imageUrl.includes('GIF') ||
                    this.state.imageUrl.includes('jpg') ||
                    this.state.imageUrl.includes('JPG') ||
                    this.state.imageUrl.includes('jpeg') ||
                    this.state.imageUrl.includes('JPEG') ||
                    this.state.imageUrl.includes('JFIF') ||
                    this.state.imageUrl.includes('jfif') ||
                    this.state.imageUrl.includes('pjpeg') ||
                    this.state.imageUrl.includes('PJPEG') ||
                    this.state.imageUrl.includes('pjp') ||
                    this.state.imageUrl.includes('PJP') ||
                    this.state.imageUrl.includes('png') ||
                    this.state.imageUrl.includes('PNG') ||
                    this.state.imageUrl.includes('svg') ||
                    this.state.imageUrl.includes('SVG') ||
                    this.state.imageUrl.includes('WEBP') ||
                    this.state.imageUrl.includes('webp'))
            ) {
                imageIcon = this.state.imageUrl;
            } else {
                imageIcon = docIcon;
            }
        }

        let imagePreview: any = '';
        let isVideo: boolean = false;
        let videoType: string = '';
        // let isPdf: boolean = false;
        // let pdfLink: string = '';
        if (this.state.currentDocument && this.state.currentDocument.document) {
            if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                this.state.currentDocument.document.includes('pdf')
            ) {
                imagePreview = pdfIcon;
                // imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                // isPdf = true;
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                this.state.currentDocument &&
                this.state.currentDocument.document.includes('csv')
            ) {
                imagePreview = csvIcon;
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                ((this.state.currentDocument && this.state.currentDocument.document.includes('.doc')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('docx')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('.docm')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('dot')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('dotm')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('dotx')))
            ) {
                imagePreview = wordIcon;
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                ((this.state.currentDocument && this.state.currentDocument.document.includes('gif')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('GIF')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('jpg')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('JPG')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('jpeg')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('JPEG')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('jfif')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('JFIF')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('pjpeg')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('PJPEG')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('pjp')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('PJP')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('png')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('PNG')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('svg')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('SVG')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('webp')) ||
                    (this.state.currentDocument && this.state.currentDocument.document.includes('WEBP')))
            ) {
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                (this.state.currentDocument.document.includes('mp4') ||
                    this.state.currentDocument.document.includes('MP4'))
            ) {
                isVideo = true;
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                videoType = 'mp4';
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                (this.state.currentDocument.document.includes('mov') ||
                    this.state.currentDocument.document.includes('MOV'))
            ) {
                isVideo = true;
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                videoType = 'mov';
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                (this.state.currentDocument.document.includes('wmv') ||
                    this.state.currentDocument.document.includes('WMV'))
            ) {
                isVideo = true;
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                videoType = 'wmv';
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                (this.state.currentDocument.document.includes('avi') ||
                    this.state.currentDocument.document.includes('AVI'))
            ) {
                isVideo = true;
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                videoType = 'avi';
            } else if (
                this.state.currentDocument &&
                this.state.currentDocument.document &&
                (this.state.currentDocument.document.includes('mkv') ||
                    this.state.currentDocument.document.includes('MKV'))
            ) {
                isVideo = true;
                imagePreview = this.state.currentDocument && this.state.currentDocument.document;
                videoType = 'mkv';
            } else {
                imagePreview = docIcon;
            }
        }
        return (
            <div>
                <div>
                    <NavPreview
                        imageName={this.state.currentDocument && this.state.currentDocument.name}
                        deleteHandle={this.deleteImage}
                        editHandle={this.editHandle}
                        goBack={() => this.props.history.push('/dashboard/my-documents/documents')}
                        imageLink={this.state.currentDocument && this.state.currentDocument.document}
                    />
                    <div className="preview-main-div">
                        <div className="preview-main-left">
                            <div className="images">
                                {isVideo ? (
                                    <video controls>
                                        <source src={imagePreview} type={`video/${videoType}`} />
                                    </video>
                                ) : (
                                    //     isPdf ? (
                                    //     // <object data={imagePreview} width="300" height="200"></object>
                                    //     <FileViewer fileType={pdf} filePath={imagePreview} />
                                    // ) : (
                                    // <FilePreviewer
                                    //     file={{
                                    //         url: imagePreview,
                                    //     }}
                                    // />
                                    <img src={imagePreview} />
                                )}
                            </div>
                        </div>
                        {this.state.toggling ? null : (
                            <div className="preview-main-right">
                                <ArrowLeftIcon onClick={this.toggle} />
                            </div>
                        )}
                        {this.state.toggling ? (
                            <RightNavPreview
                                imageName={this.state.currentDocument && this.state.currentDocument.name}
                                toggle={this.handleBack}
                                description={this.state.currentDocument && this.state.currentDocument.description}
                            />
                        ) : null}
                    </div>

                    <div className="previewDocumentDetails">
                        <div className="descriptionHeading">Description</div>
                        <div className="descriptionData">
                            {this.state.currentDocument && this.state.currentDocument.description}
                            {/* This is my new coffee maker bought from my Savings and im not letting it go. */}
                        </div>
                    </div>
                </div>
                {/* ); */}
                {/* })} */}

                <div className=""></div>
                {this.state.deleteDocument ? (
                    <Modal
                        isShowModal={this.state.deleteDocument}
                        onCloseModal={() => {
                            this.setState({ deleteDocument: false });
                        }}
                        showClose={false}
                        className="modalDeleteFolder"
                    >
                        <div>
                            <div className="deleteModalMain">
                                <div className="deleteModalHead">Are you sure you want to delete this image?</div>
                                <div className="deleteModalButtons">
                                    <div className="deleteButtonBack" onClick={this.handleGoback}>
                                        Go back
                                    </div>
                                    <div className="deleteButtonRed" onClick={this.deleteHandler}>
                                        Yes, Delete it
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                ) : null}
                {this.state.editDocument ? (
                    <Modal
                        isShowModal={this.state.editDocument}
                        onCloseModal={() => {
                            this.setState({ editDocument: false });
                        }}
                        showClose={false}
                        className="modalEditFolder"
                    >
                        <div className="editModal">
                            <div className="title">{this.state.name}</div>
                            <div className="image">
                                <img src={imageIcon} />
                            </div>
                            <div className="uploadText">
                                <div
                                    className="inputFileDiv"
                                    id="upload"
                                    onClick={() =>
                                        // `current` points to the mounted file input element
                                        {
                                            let element: any = document.getElementById('file') as HTMLElement;
                                            element.click();
                                        }
                                    }
                                >
                                    Replace Document
                                </div>
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileUploader"
                                    style={{ display: 'none' }}
                                    onChange={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        let currentTarget = document.querySelector(`.uploadText`);
                                        if (e.target.files.length > 0) {
                                            let fileSize: number = e.target.files[0] && e.target.files[0].size / 1024;
                                            if (fileSize <= 2048) {
                                                const fileReader = new FileReader();
                                                fileReader.readAsDataURL(e.target.files[0]);
                                                let image: any = '';
                                                if (
                                                    e.target.files[0] &&
                                                    e.target.files[0].type &&
                                                    e.target.files[0].type.includes('image')
                                                ) {
                                                    image = fileReader && fileReader.result;
                                                } else if (
                                                    e.target.files[0] &&
                                                    e.target.files[0].type &&
                                                    e.target.files[0].type == 'application/pdf'
                                                ) {
                                                    image = pdfIcon;
                                                } else if (
                                                    e.target.files[0] &&
                                                    e.target.files[0].type &&
                                                    e.target.files[0].type.includes('application/') &&
                                                    e.target.files[0].name &&
                                                    e.target.files[0].name.split('.')[1] == 'csv'
                                                ) {
                                                    image = csvIcon;
                                                } else if (
                                                    e.target.files[0] &&
                                                    e.target.files[0].type &&
                                                    e.target.files[0].type.includes('application/') &&
                                                    e.target.files[0].name &&
                                                    (e.target.files[0].name.split('.')[1] == 'doc' ||
                                                        e.target.files[0].name.split('.')[1] == 'docx' ||
                                                        e.target.files[0].name.split('.')[1] == 'docm' ||
                                                        e.target.files[0].name.split('.')[1] == 'dot' ||
                                                        e.target.files[0].name.split('.')[1] == 'dotx' ||
                                                        e.target.files[0].name.split('.')[1] == 'dotm')
                                                ) {
                                                    image = wordIcon;
                                                } else {
                                                    image = docIcon;
                                                }
                                                this.setState({
                                                    imageObject: e.target.files[0],
                                                    imageUrl: image,
                                                });

                                                fileReader.onload = () => {
                                                    if (currentTarget && currentTarget !== null) {
                                                        this.setState({
                                                            //  imageObject: e.target.files[0],
                                                            imageUrl: image,
                                                        });
                                                        // currentTarget.innerHTML = `<img src="${fileReader.result}" />`;
                                                    }
                                                };
                                            } else {
                                                window.alert('File size should not be more than 2MB');
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="inputValueDiv">
                                <TextField
                                    className="input1"
                                    label="Title"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.setState({ name: e.target.value })}
                                    variant="filled"
                                    value={this.state.name}
                                />
                                <div className="error">
                                    {this.state.continueClicked && this.state.name == '' ? 'Enter Title' : ''}
                                </div>
                            </div>
                            <div className="inputTextValueDiv">
                                <TextareaAutosize
                                    // label="Description"
                                    aria-label="minimum height"
                                    rows={4}
                                    placeholder="Enter Description here (Optional)"
                                    value={this.state.description}
                                    onChange={(e: any) => this.setState({ description: e.target.value })}
                                />
                            </div>
                            <div className="inputValueDiv" style={{ marginBottom: '10px' }}>
                                <SelectInput
                                    onChange={(e: any) => this.setState({ folder: e })}
                                    options={this.state.foldersList}
                                    placeholder={
                                        this.state.folder && this.state.folder.label
                                            ? this.state.folder.label
                                            : 'Select Folder'
                                    }
                                    value={this.state.folder && this.state.folder.value ? this.state.folder.value : ''}
                                    label="Add to"
                                />
                                <div className="error">
                                    {this.state.continueClicked && this.state.folder == null ? 'Select Folder' : ''}
                                </div>
                            </div>
                            <div className="buttonDocument">
                                <Button
                                    color="#fff"
                                    onClick={this.editDocumentHandler}
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                >
                                    Done
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        documentryAction: bindActionCreators(DocumentryAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    documentryState: state.documentry,
});

export default connect(mapStateToProps, mapDispatchToProps)(documentPreview);
