import React, { Component } from 'react';
import './HomeWarantyViewDetails.scss';
// import { Collapse } from 'react-collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ShieldImage from '../../Assets/images/WarantyHome/shield.png';
// import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ButtonNew from '../atoms/Button';
import CheckedIcon from '../../Assets/images/WarantyHome/checked.png';
import CrossIcon from '../../Assets/images/WarantyHome/cross.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';

type initialProps = {
    title: string;
    basic?: any;
    appliances?: any;
    additional?: any;
    specialMember?: any;
    optional?: any;
    data?: any;
    onOptionalDataClick?: any;
    handleSelectPlanData: any;
    commonState?: any;
    commonAction: any;
};

type initialState = {
    basicState: boolean;
    slug: any;

    appliancesState: boolean;
    additionalState: boolean;
    specialMemberState: boolean;
    optionalState: boolean;
    // basic: any;
    isDataArrived: boolean;
    data: any;
    moveId: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
};

export class HomeWarantyViewDetails extends Component<initialProps, initialState> {
    state: initialState = {
        basicState: false,
        slug: null,
        appliancesState: false,
        additionalState: false,
        specialMemberState: false,
        optionalState: true,
        isDataArrived: true,
        // basic: [
        //     { title: 'Air Conditionar', icon: 'true', nothing: '' },
        //     { title: 'Air Conditionar', icon: 'true', nothing: '' },
        //     { title: 'Air Conditionar', icon: 'true', nothing: '' },
        // ],
        data: null,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        if (this.props && this.props.data) {
            this.setState({ data: this.props.data });
        }

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        let cardValues: any = [];
        this.props.data.map((item: any) => {
            cardValues.push(item.description);
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        analytics.track(
            `Product Table Viewed`,
            {
                page: 'Home Warranty',
                brokerage_name: this.state.brokerage_name,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: this.state.agent_name,
                category_name: 'Home Warranty',
                card: false,
                card_title: '',
                product_name: cardValues,
                brand_name: cardValues,
            },
            { user_id: move_id },
        );
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }
        return currentState;
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let { data } = this.state;
        let includedCoveragesData: any = [];
        let optionalCoveragesData: any = [];
        let groupCoveragesData: any = [];
        let guestUnitsData: any = [];
        let guestUnitTiersData: any = [];

        data &&
            data.map((item1: any) => {
                let keys = item1 && item1.coverages && Object.keys(item1.coverages);
                keys &&
                    keys.map((key: any) => {
                        if (key == 'includedCoverages') {
                            item1.coverages[key] &&
                                item1.coverages[key].map((item: any) => {
                                    if (!includedCoveragesData.includes(item.description)) {
                                        includedCoveragesData.push(item.description);
                                    }
                                    // });
                                });
                        }
                        if (key === 'optionalCoverages') {
                            item1.coverages[key] &&
                                item1.coverages[key].map((item: any) => {
                                    if (!optionalCoveragesData.includes(item.description)) {
                                        optionalCoveragesData.push(item.description);
                                    }
                                });
                        }
                        if (key === 'groupCoverages') {
                            item1.coverages[key] &&
                                item1.coverages[key].map((item: any) => {
                                    if (!groupCoveragesData.includes(item.description)) {
                                        groupCoveragesData.push(item.description);
                                    }
                                });
                        }
                        if (key === 'guestUnits') {
                            item1.coverages[key] &&
                                item1.coverages[key].map((item: any) => {
                                    if (!guestUnitsData.includes(item.description)) {
                                        guestUnitsData.push(item.description);
                                    }
                                });
                        }
                        if (key === 'guestUnitTiers') {
                            item1.coverages[key] &&
                                item1.coverages[key].map((item: any) => {
                                    if (!guestUnitTiersData.includes(item.description)) {
                                        guestUnitTiersData.push(item.description);
                                    }
                                });
                        }
                    });
            });
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="main-div-HomeWarantyDetails">
                <div className="card-1-homewarantydetails">
                    <div className="recommended-waranty-card"></div>
                    <div className="brand-title-waranty"></div>

                    <div
                        className="comman-subContent-style"
                        onClick={() => {
                            this.setState({ basicState: !this.state.basicState });
                        }}
                    >
                        {this.state.basicState ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        Included Coverages
                    </div>
                    {this.state.basicState
                        ? includedCoveragesData &&
                          includedCoveragesData.map((item: any) => {
                              return (
                                  <div>
                                      {' '}
                                      <div className="comman-subContent-inside style-color-even">{item}</div>
                                  </div>
                              );
                          })
                        : null}
                    <div
                        className="comman-subContent-style style-color-even"
                        onClick={() => {
                            this.setState({ appliancesState: !this.state.appliancesState });
                        }}
                    >
                        {this.state.appliancesState ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        Group Coverages
                    </div>

                    {this.state.appliancesState
                        ? groupCoveragesData &&
                          groupCoveragesData.map((item: any) => {
                              return (
                                  <div>
                                      {' '}
                                      <div className="comman-subContent-inside">{item}</div>
                                  </div>
                              );
                          })
                        : null}
                    <div
                        className="comman-subContent-style "
                        onClick={() => {
                            this.setState({ additionalState: !this.state.additionalState });
                        }}
                    >
                        {this.state.additionalState ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        Guest Units
                    </div>
                    {this.state.additionalState
                        ? guestUnitsData &&
                          guestUnitsData.map((item: any) => {
                              return (
                                  <div>
                                      {' '}
                                      <div className="comman-subContent-inside">{item}</div>
                                  </div>
                              );
                          })
                        : null}
                    <div
                        className="comman-subContent-style style-color-even"
                        onClick={() => {
                            this.setState({ specialMemberState: !this.state.specialMemberState });
                        }}
                    >
                        {this.state.specialMemberState ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        Guest Unit Tiers
                    </div>
                    {this.state.specialMemberState
                        ? guestUnitTiersData &&
                          guestUnitTiersData.map((item: any) => {
                              return (
                                  <div>
                                      {' '}
                                      <div className="comman-subContent-inside">{item}</div>
                                  </div>
                              );
                          })
                        : null}

                    <div
                        className="comman-subContent-style "
                        onClick={() => {
                            this.setState({ optionalState: !this.state.optionalState });
                        }}
                    >
                        {this.state.optionalState ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        Optional Coverages
                    </div>
                    {this.state.optionalState
                        ? optionalCoveragesData &&
                          optionalCoveragesData.map((item: any) => {
                              return (
                                  <div>
                                      {' '}
                                      <div className="comman-subContent-inside">
                                          {' '}
                                          {/* <Checkbox
                                              checked={item.isChecked ? true : false}
                                              onChange={() => this.props.onOptionalDataClick(index)}
                                          /> */}
                                          {item}
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
                <div className="main-cards-homewarantydetails">
                    {this.state.data &&
                        this.state.data.length &&
                        this.state.data.map((item: any, index: number) => {
                            let data: any = item.coverages;
                            let keys = data && Object.keys(data);
                            let includedCoveragesDataInside: any = [];
                            let optionalCoveragesDataInside: any = [];
                            let groupCoveragesDataInside: any = [];
                            let guestUnitsDataInside: any = [];
                            let guestUnitTiersDataInside: any = [];

                            keys &&
                                keys.map((key: any) => {
                                    if (key === 'includedCoverages') {
                                        data[key] &&
                                            data[key].map((item: any) => {
                                                includedCoveragesDataInside.push({ ...item });
                                            });
                                    }
                                    if (key === 'optionalCoverages') {
                                        data[key] &&
                                            data[key].map((item: any) => {
                                                optionalCoveragesDataInside.push({ ...item });
                                            });
                                    }
                                    if (key === 'groupCoverages') {
                                        data[key] &&
                                            data[key].map((item: any) => {
                                                groupCoveragesDataInside.push({ ...item });
                                            });
                                    }
                                    if (key === 'guestUnits') {
                                        data[key] &&
                                            data[key].map((item: any) => {
                                                guestUnitsDataInside.push({ ...item });
                                            });
                                    }
                                    if (key === 'guestUnitTiers') {
                                        data[key] &&
                                            data[key].map((item: any) => {
                                                guestUnitTiersDataInside.push({ ...item });
                                            });
                                    }
                                });
                            return (
                                <div className="card-2-homewarantydetails" style={{ boxShadow: '0px 0px 3px #bebebe' }}>
                                    <div className="recommended-waranty-card">
                                        {/* {item.description.includes('ShieldPlus') ? (
                                            <div className="inner-recommend">Recommended</div>
                                        ) : null} */}
                                    </div>
                                    <div className="brand-title-waranty">
                                        <div className="icon-waranty-view">
                                            {/* {item.description} */}
                                            {item.description && item.description.split('Shield')[1]
                                                ? 'Shield'
                                                : item.description}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {/* {item.description && item.description.split('Shield')[1]} */}
                                                {item.description &&
                                                item.description.split('Shield')[1] &&
                                                item.description &&
                                                item.description.split('Shield')[1].split(': Buyer Only') &&
                                                item.description.split('Shield')[1].split(': Buyer Only')[0]
                                                    ? item.description.split('Shield')[1].split(': Buyer Only')[0]
                                                    : item.description && item.description.split('Shield')[1]}
                                            </span>
                                            {/* {this.props.title &&
                                this.props.title.split('Shield')[1] &&
                                this.props.title.split('Shield')[1] &&
                                this.props.title.split('Shield')[1].charAt(0) ? (
                                    <div className="shield">
                                        <div className="firstLetter">{`${this.props.title &&
                                            this.props.title.split('Shield')[1] &&
                                            this.props.title.split('Shield')[1] &&
                                            this.props.title.split('Shield')[1].charAt(0)}`}</div>
                                    </div>
                                ) : null} */}
                                        </div>
                                        <div className="dollar-plan-price">
                                            <span>$</span>
                                            {item.basePriceWithoutTax}
                                        </div>
                                        {/* {item.description.includes('ShieldPlus') ? (
                                            <div className="submitButtonView">
                                                <ButtonNew
                                                    className="submitButtonViewDetail"
                                                    // border={
                                                    //     slug && slug.primary_color
                                                    //         ? `1px solid ${slug.primary_color}`
                                                    //         : '1px solid #273E59'
                                                    // }
                                                    color="#fff"
                                                    // color={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                                    backgroundColor={
                                                        slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                    }
                                                    onClick={() => this.props.handleSelectPlanData(index)}
                                                >
                                                    Select Plan
                                                </ButtonNew>
                                            </div>
                                        ) : ( */}
                                        <div className="submitButtonView">
                                            <ButtonNew
                                                className="submitButtonViewDetail"
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug && slug.primary_color
                                                        : '#273E59'
                                                }
                                                border={`1px solid ${
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug && slug.primary_color
                                                        : '#273E59'
                                                }`}
                                                backgroundColor="#ffff"
                                                onClick={() => this.props.handleSelectPlanData(index)}
                                            >
                                                Select Plan
                                            </ButtonNew>
                                        </div>
                                        {/* )} */}
                                    </div>

                                    <div
                                        className="comman-subContent-style "
                                        onClick={() => {
                                            this.setState({ basicState: !this.state.basicState });
                                        }}
                                    ></div>

                                    {this.state.basicState
                                        ? includedCoveragesData &&
                                          includedCoveragesData.map((item: any) => {
                                              let right: any = 'false';
                                              includedCoveragesDataInside.map((item2: any) => {
                                                  if (item == item2.description) {
                                                      right = 'true';
                                                      return right;
                                                  }
                                              });
                                              return (
                                                  <div className="comman-subContent-inside1">
                                                      {right && right == 'true' ? (
                                                          <img src={CheckedIcon} />
                                                      ) : (
                                                          <img src={CrossIcon} />
                                                      )}
                                                  </div>
                                              );
                                          })
                                        : null}

                                    <div
                                        className="comman-subContent-style style-color-even"
                                        onClick={() => {
                                            this.setState({ appliancesState: !this.state.appliancesState });
                                        }}
                                    ></div>
                                    {this.state.appliancesState
                                        ? groupCoveragesData &&
                                          groupCoveragesData.map((item: any) => {
                                              let right: any = 'false';
                                              groupCoveragesDataInside.map((item2: any) => {
                                                  if (item == item2.description) {
                                                      right = 'true';
                                                      return right;
                                                  }
                                              });
                                              return (
                                                  <div className="comman-subContent-inside1">
                                                      {right && right == 'true' ? (
                                                          <img src={CheckedIcon} />
                                                      ) : (
                                                          <img src={CrossIcon} />
                                                      )}
                                                  </div>
                                              );
                                          })
                                        : null}

                                    <div
                                        className="comman-subContent-style "
                                        onClick={() => {
                                            this.setState({ additionalState: !this.state.additionalState });
                                        }}
                                    ></div>
                                    {this.state.additionalState
                                        ? guestUnitsData &&
                                          guestUnitsData.map((item: any) => {
                                              let right: any = 'false';
                                              guestUnitsDataInside.map((item2: any) => {
                                                  if (item == item2.description) {
                                                      right = 'true';
                                                      return right;
                                                  }
                                              });
                                              return (
                                                  <div className="comman-subContent-inside1">
                                                      {right && right == 'true' ? (
                                                          <img src={CheckedIcon} />
                                                      ) : (
                                                          <img src={CrossIcon} />
                                                      )}
                                                  </div>
                                              );
                                          })
                                        : null}

                                    <div
                                        className="comman-subContent-style style-color-even"
                                        onClick={() => {
                                            this.setState({ specialMemberState: !this.state.specialMemberState });
                                        }}
                                    ></div>
                                    {this.state.specialMemberState
                                        ? guestUnitTiersData &&
                                          guestUnitTiersData.map((item: any) => {
                                              let right: any = 'false';
                                              guestUnitTiersDataInside.map((item2: any) => {
                                                  if (item == item2.description) {
                                                      right = 'true';
                                                      return right;
                                                  }
                                              });
                                              return (
                                                  <div className="comman-subContent-inside1">
                                                      {right && right == 'true' ? (
                                                          <img src={CheckedIcon} />
                                                      ) : (
                                                          <img src={CrossIcon} />
                                                      )}
                                                  </div>
                                              );
                                          })
                                        : null}

                                    <div
                                        className="comman-subContent-style"
                                        onClick={() => {
                                            this.setState({ optionalState: !this.state.optionalState });
                                        }}
                                    ></div>
                                    {this.state.optionalState
                                        ? optionalCoveragesData &&
                                          optionalCoveragesData.map((item: any) => {
                                              //   return (
                                              //       <div className="comman-subContent-inside">
                                              //           {item.optionalBaseType === 'OPTIONAL'
                                              //               ? 'Add-On'
                                              //               : item.optionalBaseType}
                                              //       </div>
                                              //   );
                                              let addOn: any = 'false';
                                              optionalCoveragesDataInside.map((item2: any) => {
                                                  if (item == item2.description) {
                                                      addOn = 'true';
                                                      return addOn;
                                                  }
                                              });
                                              return (
                                                  <div className="comman-subContent-inside1">
                                                      {addOn && addOn == 'true'
                                                          ? 'Add-On'
                                                          : item.optionalBaseType
                                                          ? item.optionalBaseType
                                                          : '-'}
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                        })}

                    {/* <div className="card-2-homewarantydetails" style={{ boxShadow: '0px 0px 3px #0066F5' }}>
                        <div className="recommended-waranty-card">
                            <div className="inner-recommend">Recommended</div>
                        </div>

                        <div className="brand-title-waranty">
                            <div className="icon-waranty-view">
                                {this.props.title}
                                
                            </div>
                            {this.props.title}
                            <div className="dollar-plan-price">
                                <span>$</span>490
                            </div>
                            <ButtonNew
                                className="submitButton"
                                border={
                                    slug && slug.primary_color ? `1px solid ${slug.primary_color}` : '1px solid #273E59'
                                }
                                color={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                backgroundColor="#ffff"
                                onClick={this.props.handleSelectPlanData}
                            >
                                Select Plan
                            </ButtonNew>
                        </div>

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ basicState: !this.state.basicState });
                            }}
                        ></div>

                        {this.state.basicState
                            ? includedCoveragesData &&
                              includedCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ appliancesState: !this.state.appliancesState });
                            }}
                        ></div>
                        {this.state.appliancesState
                            ? groupCoveragesData &&
                              groupCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ additionalState: !this.state.additionalState });
                            }}
                        ></div>
                        {this.state.additionalState
                            ? guestUnitsData &&
                              guestUnitsData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ specialMemberState: !this.state.specialMemberState });
                            }}
                        ></div>
                        {this.state.specialMemberState
                            ? guestUnitTiersData &&
                              guestUnitTiersData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ optionalState: !this.state.optionalState });
                            }}
                        ></div>
                        {this.state.optionalState
                            ? optionalCoveragesData &&
                              optionalCoveragesData.map((item: any) => {
                                  return (
                                      <div className="comman-subContent-inside">
                                          {item.optionalBaseType === 'OPTIONAL' ? 'Add-On' : item.optionalBaseType}
                                      </div>
                                  );
                              })
                            : null}
                    </div> */}
                    {/* <div className="card-2-homewarantydetails" style={{ boxShadow: '0px 0px 3px #bebebe' }}>
                        <div className="recommended-waranty-card"></div>

                        <div className="brand-title-waranty">
                            <div className="icon-waranty-view">{this.props.title}</div>
                            {this.props.title}
                            <div className="dollar-plan-price">
                                <span>$</span>490
                            </div>
                            <ButtonNew
                                className="submitButton"
                                border={
                                    slug && slug.primary_color ? `1px solid ${slug.primary_color}` : '1px solid #273E59'
                                }
                                color={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                backgroundColor="#ffff"
                                onClick={this.props.handleSelectPlanData}
                            >
                                Select Plan
                            </ButtonNew>
                        </div>

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ basicState: !this.state.basicState });
                            }}
                        ></div>

                        {this.state.basicState
                            ? includedCoveragesData &&
                              includedCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ appliancesState: !this.state.appliancesState });
                            }}
                        ></div>
                        {this.state.appliancesState
                            ? groupCoveragesData &&
                              groupCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ additionalState: !this.state.additionalState });
                            }}
                        ></div>
                        {this.state.additionalState
                            ? guestUnitsData &&
                              guestUnitsData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ specialMemberState: !this.state.specialMemberState });
                            }}
                        ></div>
                        {this.state.specialMemberState
                            ? guestUnitTiersData &&
                              guestUnitTiersData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.icon}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ optionalState: !this.state.optionalState });
                            }}
                        ></div>
                        {this.state.optionalState
                            ? optionalCoveragesData &&
                              optionalCoveragesData.map((item: any) => {
                                  return (
                                      <div className="comman-subContent-inside">
                                          {item.optionalBaseType === 'OPTIONAL' ? 'Add-On' : item.optionalBaseType}
                                      </div>
                                  );
                              })
                            : null}
                    </div> */}
                    {/* <div className="card-3-homewarantydetails">
                        <div className="recommended-waranty-card"></div>
                        <div className="brand-title-waranty"></div>

                        <div
                            className="comman-subContent-style"
                        >
                            </div>
                        
                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ appliancesState: !this.state.appliancesState });
                            }}
                        >
                        </div>
                        {this.state.appliancesState
                            ? groupCoveragesData &&
                              groupCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.empty}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                        >
                            </div>
                        {this.state.additionalState
                            ? guestUnitsData &&
                              guestUnitsData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.empty}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ specialMemberState: !this.state.specialMemberState });
                            }}
                        >
                            </div>
                        {this.state.specialMemberState
                            ? guestUnitTiersData &&
                              guestUnitTiersData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{item.empty}</div>;
                              })
                            : null}

                        <div
                            className="comman-subContent-style"
                            onClick={() => {
                                this.setState({ optionalState: !this.state.optionalState });
                            }}
                        >
                           </div>
                        {this.state.optionalState
                            ? optionalCoveragesData &&
                              optionalCoveragesData.map((item: any) => {
                                  return <div className="comman-subContent-inside">{`$${item.coveragePrice}`}</div>;
                              })
                            : null}
                    </div> */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarantyViewDetails);
