import React from 'react';
import './threeDivSignUp.scss';
import Image1 from '../../../Assets/images/SignUp/signupimage1.svg';
import Image2 from '../../../Assets/images/SignUp/signupimage2.svg';
import Image3 from '../../../Assets/images/SignUp/signupimage3.svg';
import Title from '../../../Assets/images/SignUp/titleIcon';
import Insurance from '../../../Assets/images/SignUp/home-insurance';
import Mortgage from '../../../Assets/images/SignUp/Mortgage';
import Warranty from '../../../Assets/images/SignUp/homewarrenty';
import Utility from '../../../Assets/images/SignUp/Utility';
import Internet from '../../../Assets/images/SignUp/internet';
import Moving from '../../../Assets/images/SignUp/moving';
import Security from '../../../Assets/images/SignUp/Security';
import Electrician from '../../../Assets/images/SignUp/Electrician';
import Alarm from '../../../Assets/images/SignUp/Alarm';
import Appliance from '../../../Assets/images/SignUp/Appliance';
import Carpentar from '../../../Assets/images/SignUp/Carpenter';

const ThreeDivSignUp = () => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    // let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div className="three-wrapper">
            <div className="three-para">
                Your {slug && slug.slug == 'gomillie' ? 'PCS' : 'Lifetime Move'} & Home Management Assistant
            </div>
            <div className="three-div1">
                <div className="three-image1">
                    <img src={Image1} />
                    {/* <Image1 /> */}
                </div>
                <div className={'three-content1'}>
                    <div className="content1-heading">
                        Before The {slug && slug.slug == 'gomillie' ? 'PCS' : 'Move'}
                    </div>
                    <div className="content1-para">
                        Your {slug && slug.concierge_reference_field} access begins the day you list your home or go
                        under contract, helping you find the best rates and the right partners to finance and protect
                        your home
                    </div>
                    <div className="content1-icons">
                        <div className="icon-div-first">
                            <Title />
                            <div className="text-new">Title</div>
                        </div>
                        <div className="icon-div">
                            <div className="content-title-icon">
                                <Insurance />
                                <div className="text-new">Home Insurance</div>
                            </div>
                        </div>
                        <div className="icon-div">
                            <div className="content-title-icon">
                                <Mortgage />
                                <div className="text-new">Mortgage</div>
                            </div>
                        </div>
                        <div className="icon-div">
                            <div className="content-title-icon">
                                <Warranty />
                                <div className="text-new">Home Warranty</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="three-div2">
                <div className={'three-content2'}>
                    <div className="content2-heading">
                        During The {slug && slug.slug == 'gomillie' ? 'PCS' : 'Move'}
                    </div>
                    <div className="content2-para">
                        Once you are ready for your move, your {slug && slug.concierge_reference_field} makes it easy to
                        compare and set up your internet, TV, electricty, gas, movers and home security in minutes.
                        <div className="content2-icons">
                            <div className="icon-div-first">
                                <div className="content-title-icon">
                                    <Utility />
                                    <div className="text-new">Utility</div>
                                </div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Internet />
                                    <div className="text-new">Internet & Cable</div>
                                </div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Moving />
                                    <div className="text-new">Moving Service</div>
                                </div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Security />
                                    <div className="text-new">Home Security</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="three-image2">
                    <img src={Image2} />
                    {/* <Image2 /> */}
                </div>
            </div>
            <div className="three-div3">
                <div className="three-image3">
                    <img src={Image3} />
                    {/* <Image3 /> */}
                </div>
                <div className={'three-content3'}>
                    <div className="content3-heading">After The {slug && slug.slug == 'gomillie' ? 'PCS' : 'Move'}</div>
                    <div className="content3-para">
                        Your {slug && slug.concierge_reference_field} access doesn't end with your move. Get lifetime
                        access to top rated home pros for your home improvement and home maintenance needs
                        <div className="content3-icons">
                            <div className="icon-div-first">
                                <Electrician />
                                <div className="text-new">Electrician</div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Alarm />
                                    <div className="text-new">Alarm & Security</div>
                                </div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Appliance />
                                    <div className="text-new">Appliance repair</div>
                                </div>
                            </div>
                            <div className="icon-div">
                                <div className="content-title-icon">
                                    <Carpentar />
                                    <div className="text-new">Carpenter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeDivSignUp;
// export default Login;
