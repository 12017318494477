import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import './CheckIfQualify.scss';
// import InfoIcon from '@material-ui/icons/Info';
import { getCardsDatas } from './CardsDatas';
import AutoComplete from '../autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect, useDispatch } from 'react-redux';
import { postSecurity } from '../../../store/security/actions';
import Button from '../Button';
import { landingMoveGet, landingMovePost } from '../../../store/login/signup/actions';
import { UIContext } from '../../../UIContext';
import History from '../../../routes/History';
import AnalyticsService from '../../../utils/analytics-service';
import _ from 'lodash';

type initialProps = {
    history: any;
    securityState?: any;
    signupState: any;
};

type Itypes = {
    [name: string]: boolean;
    address: boolean;
    email: boolean;
    phone: boolean;
}
const CheckIfQualify = (props: initialProps) => {
    const { domain: appDomain } = useContext(UIContext)
    const { history, securityState, signupState } = props;
    const [prevSignupState, setPrevSignupState] = useState<any>({})
    const { location } = history;
    const { state } = location;
    const [isChecked, setIsChecked] = useState(false)
    const [selectedCard, setSelectedCard] = useState<any>({})
    const [personalDetails, setPersonalDetails] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
    })
    const [isSubmitClicked, setIsSubmitClicked] = useState(false)
    const [checkBoxError, setCheckBoxError] = useState("");
    const dispatch = useDispatch();
    const [isDouglasElliman, setIsDouglasElliman] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState<Itypes>({
        name: false,
        address: false,
        email: false,
        phone: false
    })
    const [isFormStarted, setIsFormStarted] = useState(false);
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        return () => { };
    }, [])

    useEffect(() => {
        if (
            signupState?.landingMoveGet &&
            prevSignupState?.landingMoveGet?.user_ref?.token !=
            signupState?.landingMoveGet?.user_ref?.token
        ) {
            const token = signupState?.landingMoveGet?.user_ref?.token;
            if (token) {
                selectSecurityPlan(token)
            }
        }

        if (prevSignupState?.landingMovePostStatus != signupState?.landingMovePostStatus) {
            if (signupState.landingMovePostStatus == 'success') {
                const token = signupState?.landingMovePost?.user_ref?.token;
                if (token) {
                    selectSecurityPlan(token)
                }
            } else if (signupState?.landingMoveGet == null && signupState.landingMovePostStatus == "error") {
                if (personalDetails.email)
                    dispatch(landingMoveGet({
                        email: personalDetails.email,
                        slug: appDomain,
                    }));
            }
        }

        setPrevSignupState(signupState)
    }, [signupState])

    useEffect(() => {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            setIsDouglasElliman(true)
        }
        return () => { }
    }, [])

    useEffect(() => {
        if (state?.id && _.isEmpty(selectedCard)) {
            const cardsDatas = getCardsDatas();
            cardsDatas.map((item: any) => {
                if (item.id === state.id) {
                    setSelectedCard(item);
                }
            })
        }
        return () => { }
    }, [state?.id, selectedCard, slug])

    useEffect(() => {
        if (state) {
            const addressObj = localStorage.getItem('pods_destination_address')
            setPersonalDetails({ name: state.firstName && `${state.firstName}  ${state.lastName}`, email: state.email, phone: state.phone, address: addressObj && JSON.parse(addressObj)?.address })
        }
        return () => { }
    }, [state])

    const inputAnalyticsTracking = (fieldLabel: string, fieldName: string) => {
        if (!isAnalitycsTriggerredOnce[fieldName]) {

            const trackProperties = {
                page: 'Home Security',
                category_name: 'Home Security Checkifqualify',
                button_copy: 'submit',
                form_button: true,
                form_pre_filled: false,
                form_field_name: fieldLabel
            }
            
            if (isFormStarted) {
                AnalyticsService.formFieldInput(trackProperties);
            } else {
                AnalyticsService.formFieldStarted(trackProperties);
            }
            setIsFormStarted(true);
        }

        setIsAnalitycsTriggerredOnce({
            ...isAnalitycsTriggerredOnce,
            [fieldName]: true
        })
    }

    const firstNameHandler = (e: any) => {
        setPersonalDetails({ ...personalDetails, name: e.target.value })
        inputAnalyticsTracking('Name', 'name')
    };

    const addressHandler = (e: any) => {
        setPersonalDetails({ ...personalDetails, address: e });
        inputAnalyticsTracking('Enter Address', 'address')
    };

    const emailHandler = (e: any) => {
        setPersonalDetails({ ...personalDetails, email: e.target.value })
        inputAnalyticsTracking('Email', 'email')
    };

    const formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    const phoneHandler = (e: any) => {
        let phoneNumber = formatPhoneInput(e.target.value);
        setPersonalDetails({ ...personalDetails, phone: phoneNumber })
        inputAnalyticsTracking('Phone', 'phone')
    };

    const onSubmitHandler = () => {
        setIsSubmitClicked(true)
        
        if (personalDetails.name &&
            personalDetails.address && personalDetails.email &&
            personalDetails.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$') &&
            personalDetails.phone && personalDetails.phone.length >= 12) {

            if (isChecked) {
                setCheckBoxError("");
                setLoading(true);
                onCallEndpoint();
            } else {
                setLoading(false);
                setCheckBoxError("Please select check box");
            }

            setIsSubmitClicked(false)

            AnalyticsService.formCompleted({
                page: 'Home Security',
                category_name: 'Home Security Checkifqualify',
                button_copy: 'Submit',
                form_name: 'Confirm Your Details'
            })

        } else {
            setLoading(false);
            setIsSubmitClicked(true)
        }

        AnalyticsService.buttonClicked({
            page: 'Home Security',
            category_name: 'Home Security Checkifqualify',
            button_copy: 'Submit',
            form_button: true
        })
    }

    const selectSecurityPlan = (token: string) => {
        const payload: any = {
            provider_name: selectedCard.title,
            plan_name: selectedCard.title,
            price: selectedCard.monthlyfees,
            plan_details: {
                securityType: "",
                securityInstallation: "",
                installationCost: selectedCard.installationCost,
                installationType: selectedCard.installationType,
                monthlyfees: selectedCard.monthlyfees,
                topFeatures: selectedCard.topFeatures
            }
        };

        dispatch(postSecurity({ apiPayload: payload, token }));
    }
    const onCallEndpoint = async () => {
        if (personalDetails["name"] && personalDetails["email"] && personalDetails["phone"] && personalDetails["address"]) {
            let splitName = personalDetails["name"].split(" ")
            const payload = {
                destination_detail: personalDetails["address"],
                destination_home: '1',
                moving_date: new Date().toISOString().split('T')[0],
                moving_in_date: null,
                moving_items_list: ['2'],
                moving_option_value: ['2'],
                selected_service_list: ['11'],
                type: 'security',
                user_ref: {
                    email: personalDetails["email"],
                    first_name: splitName && splitName[0] ? splitName[0] : '',
                    last_name: splitName && splitName[1] ? splitName[1] : '',
                    phone: personalDetails["phone"],
                    site_name: appDomain,
                },
            };

            dispatch(landingMovePost({ apiPayload: payload, type: "open" }))
        }
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                const addressString = results[0].formatted_address;
                let addressObj = {
                    street: `${streetNumber} ${route}`,
                    city,
                    zipcode: zip,
                    address: addressString,
                    state
                };
                localStorage.setItem('pods_destination_address', JSON.stringify(addressObj));

                if (country === 'USA' || country === 'US') {
                    setPersonalDetails({ ...personalDetails, address: addressString })
                }
            })
            .catch(error => console.error('error', error));
    };

    return (
        <div className='check-if-qualify-wrap'>
            {securityState?.PostSecurity && securityState?.PostSecurity.length === 0 ?
                <div>
                    <div className='almost-done-section'>
                        <span>Great, you are almost done</span>
                        <p>Confirm your details and our security specialist will follow up to complete your qualification.
                            {/* <InfoIcon style={{ color: '#6B6C6F', height: '15px', width: '15px' }} /> */}
                        </p>
                    </div>
                    <div className={`confirm-and-plan-detail-section ${!state?.id && 'content-center'}`}>
                        <div className='confirm-section'>
                            <h3>Confirm Your Details</h3>
                            <p>Personal details</p>
                            <div className='personal-detail-section'>
                                <div className='personal-flex-div'>
                                    <div className='personal-wrap'>
                                        <TextField
                                            variant="filled"
                                            label='Name'
                                            type="text"
                                            value={personalDetails.name}
                                            onChange={firstNameHandler}
                                            error={isSubmitClicked && personalDetails.name == ''}
                                        />
                                        <div className="error">
                                            {isSubmitClicked && personalDetails.name == ''
                                                ? 'Please enter first name'
                                                : ''}
                                        </div>
                                    </div>
                                    <div className='personal-wrap'>
                                        <TextField
                                            variant="filled"
                                            label='Email'
                                            type="text"
                                            value={personalDetails.email}
                                            onChange={emailHandler}
                                            error={isSubmitClicked && personalDetails.email === '' && !personalDetails.email?.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')}
                                        />
                                        <div className="error">
                                            {isSubmitClicked && personalDetails.email === ''
                                                ? 'Please enter email'
                                                : (
                                                    isSubmitClicked &&
                                                    (!personalDetails.email?.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'))
                                                ) ? 'Please enter valid email'
                                                    : ''}
                                        </div>
                                    </div>

                                </div>
                                <div className='personal-flex-div'>
                                    <div className='personal-wrap'>
                                        <TextField
                                            variant="filled"
                                            label='Phone'
                                            type="text"
                                            value={personalDetails.phone}
                                            onChange={phoneHandler}
                                            error={isSubmitClicked && personalDetails.phone?.length < 12}
                                        />
                                        <div className="error">
                                            {isSubmitClicked && personalDetails.phone === ''
                                                ? 'Please enter phone number'
                                                : isSubmitClicked && personalDetails.phone?.length < 12
                                                    ? 'Please enter valid phone number'
                                                    : ''}
                                        </div>

                                    </div>
                                    <div className='personal-wrap'>
                                        <AutoComplete
                                            onChange={addressHandler}
                                            value={personalDetails.address}
                                            onSelect={handleSelect}
                                            placeholder="Enter Address"
                                            className='input'
                                        />
                                        <div className="error">
                                            {isSubmitClicked && personalDetails.address === ''
                                                && 'Please enter address'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='disclosure-section'>
                                <div className='disclosure-checkbox'>
                                    <Checkbox
                                        color="default"
                                        checked={isChecked}
                                        onClick={() => {
                                            setIsChecked(!isChecked);
                                            setCheckBoxError("");
                                        }}
                                    />
                                </div>
                                <div className='disclosure-text'>
                                    By submitting you expressly authorize Move Easy to call and send you marketing text messages to the telephone number entered, which you certify is your own. Consent is not a condition of any purchase.
                                </div>
                            </div>
                            <div className="error">
                                {checkBoxError}
                            </div>
                            <div className='confirm-btn-group'>
                                <Button className='confirm-btn bg-white'
                                    onClick={() => {
                                        AnalyticsService.buttonClicked({
                                            page: 'Home Security',
                                            category_name: 'Home Security Checkifqualify',
                                            button_copy: 'Cancel',
                                            form_button: true
                                        })

                                        History.goBack();
                                    }}
                                >Cancel </Button>
                                <Button className={`confirm-btn bg-red ${loading && 'btn-big'}`} onClick={onSubmitHandler} loading={loading} >Submit </Button>
                            </div>
                        </div>
                        {state?.id &&
                            <div className='plan-and-quote-detail-section'>
                                <div className='plan-detail-section'>
                                    <div className='plan-header-section'>
                                        Plan details
                                    </div>
                                    <div className='monthly-fee-section'>
                                        <div className='plan-image'>
                                            <img src={selectedCard && selectedCard.cardLogo} />
                                        </div>
                                        <div className='price-section'>
                                            <div className='price-title-section'>
                                                {selectedCard && selectedCard.title} Monthly Fee
                                            </div>
                                            <div className='amount-section'>
                                                <span className='amount'>{selectedCard && selectedCard.monthlyfees}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='quote-detail-section'>
                                    <div className='quote-header-section'>
                                        Quote details
                                    </div>
                                    <div className='quote-list'>
                                        <span>Upfront cost</span>
                                        <span>{selectedCard && selectedCard.upfrontCost}</span>
                                    </div>
                                    <div className='quote-list'>
                                        <span>Installation cost</span>
                                        {selectedCard && selectedCard.installationCost !== 'NA' ?
                                            <div className='installation-cost'>
                                                <span className='waived'>{selectedCard && selectedCard.installationCost}</span>
                                                <span>Waived off</span>
                                            </div> :
                                            <span>{selectedCard.installationCost}</span>
                                        }
                                    </div>
                                    <div className='quote-list'>
                                        <span>Installation type</span>
                                        <span>{selectedCard && selectedCard.installationType}</span>
                                    </div>
                                    <div className='quote-list'>
                                        <span>Exclusive deals</span>
                                        <span>{selectedCard && selectedCard.price}</span>
                                    </div>
                                    <div className='quote-list'>
                                        <span>Top features</span>
                                        <span className='top-features-text'>{selectedCard && selectedCard.topFeatures}</span>
                                    </div>
                                    <div className='quote-extra-text'>*The product/service names listed in this document</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <div className='home-security-thank-you'>
                    <div className='thank-you-text-div'>
                        <span>Thank you for your interest in home security </span>
                        <p>A security specialist will reach out to you shortly,</p>
                        <p>or for immediate assistance, please call 614-618-4647.</p>
                    </div>
                    {selectedCard && selectedCard.monthlyfees ? (
                        <div className='plan-detail-div'>
                            <div className='plan-text'>Plan Selected</div>
                            <div className='plan-section'>
                                <div className='plan-image'>
                                    <img src={selectedCard && selectedCard.cardLogo} />
                                </div>
                                <div className='plan-detail'>
                                    <div className='plan-title'>
                                        {selectedCard && selectedCard.title}
                                    </div>
                                    <div className='monthly-div'>
                                        <div className='monthly-text'>Monthly fee</div>
                                        <div className='amount'>${selectedCard && selectedCard.monthlyfees}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ""}
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '5px 0 25px 0' }}>
                        <Button
                            className="btnOrder"
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                            }
                            boxShadow={
                                isDouglasElliman
                                    ? 'none'
                                    : `0 0 3px 0 ${slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                            ? '#30ab86'
                                            : '#273e59'
                                    }`
                            }
                            width="170px"
                            height="50px"
                            // color="#FFFFFF"
                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                            fontSize="16px"
                            margin="0 5px 0 0"
                            onClick={() => 
                               {
                                 AnalyticsService.buttonClicked({
                                    page: 'Home Security',
                                    category_name: 'Home Security Checkifqualify',
                                    button_copy: 'Back To Dashboard',
                                    form_button: true,
                                    card: true,
                                    card_title: 'Thank you for your interest in home security',
                                    card_type: 'Info Card',
                                })

                                history.push('/')
                            }
                            }
                        >
                            Back to Dashboard
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        securityState: state.security,
        signupState: state.signup,
    }
};
export default connect(mapStateToProps, null)(CheckIfQualify);