import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './TrustedProsCarpenter.scss';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import * as CommonAction from '../../store/common/actions';
import * as TrustedProsAction from '../../store/trustedPros/actions';
import RoomIcon from '@material-ui/icons/Room';
import Button from '../../components/atoms/Button';
import arrowIcon from '../../Assets/images/TrustedPros/arrow_back_black_24dp.svg';
// import GreenTick from '../../Assets/images/TrustedPros/greenTick.svg';
// import RequestVendors from '../../Assets/images/TrustedPros/vendorRequest.svg';
import TrustedProsRequest from '../../components/TrustedProsComponents/TrustedProsRequest';
import HomeAdvisorCard from '../../components/TrustedProsComponents/HomeAdvisorCard';
import * as MoverAction from '../../store/move/actions';
import Modal from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';

type InitialProps = {
    history: any;
    commonAction: any;
    commonState: any;
    moverState: any;
    trustedProsAction: any;
    trustedProsState: any;
    moverAction?: any;
};
type InitialState = {
    address: string;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    moveId: any;
    reqSubmitted: boolean;
    reqSubmitted1: boolean;
    serviceName: string;
    isDataArrived: boolean;
    isMoveDataArrived: boolean;
    userDetails: any;
    submittingReq: boolean;
    submittingSearch: boolean;
    disclaimerOpen: boolean;
    checkContinue: boolean;
    homeAdvisorData: any;
    serviceId: any;
    homeTaskId: any;
    yesField: any;
    taskId: any;
    vendor_type: any;
    homeAdvisorApiCall: boolean;
    categoryQuestion: any;
};

class TrustedProsCarpenter extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        reqSubmitted: false,
        reqSubmitted1: false,
        serviceName: '',
        isDataArrived: true,
        isMoveDataArrived: true,
        userDetails: {},
        submittingReq: false,
        submittingSearch: false,
        disclaimerOpen: false,
        checkContinue: false,
        homeAdvisorData: [],
        serviceId: '',
        homeTaskId: '',
        yesField: '',
        taskId: '',
        vendor_type: '',
        homeAdvisorApiCall: true,
        categoryQuestion: '',
    };

    componentDidMount() {
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }

        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.service_name &&
            this.props.history.location.state.serviceDetails
        ) {
            this.setState({
                serviceName: this.props.history.location.state.service_name,
                serviceId: this.props.history.location.state.serviceId,
                homeTaskId: this.props.history.location.state.homeTaskId,
                yesField: this.props.history.location.state.yesField,
                taskId: this.props.history.location.state.taskId,
                vendor_type: this.props.history.location.state.vendor_type,
                categoryQuestion: this.props.history.location.state.categoryQuestion,
            });
        }

        let TrustedProsAddress: any = localStorage.getItem('TrustedProsAddress');
        if (TrustedProsAddress) {
            this.setState({
                address: TrustedProsAddress,
            });
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps?.trustedProsState?.homeAdvisor?.results && prevProps.trustedProsState.isGetHomeAdvisor) {
            prevProps.trustedProsState.isGetHomeAdvisor = false;
            this.setState({ homeAdvisorData: prevProps.trustedProsState.homeAdvisor.results });
        }
        prevProps.trustedProsState.isGetHomeAdvisor = true;
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (nextProps && nextProps.commonState && nextProps.commonState.move && nextProps.commonState.move[0]) {
            currentState.userDetails = nextProps.commonState.move[0].user_ref;
        }

        if (nextProps && nextProps.trustedProsState && nextProps.trustedProsState.isRequestVendors) {
            nextProps.trustedProsState.isRequestVendors = false;
            currentState.reqSubmitted = true;
            currentState.reqSubmitted1 = true;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            // currentState.fullName = `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;

            if (!localStorage.getItem('TrustedProsAddress')) {
                currentState.street = nextProps.commonState.move[0].destination_street;
                currentState.zipcode = nextProps.commonState.move[0].destination.zip_code;
                currentState.moveId = nextProps.commonState.move[0].id;
                currentState.city = nextProps.commonState.move[0].destination.city_name;
                currentState.address = nextProps.commonState.move[0].destination_full_address;
                currentState.state = {
                    name: nextProps.commonState.move[0].destination.state_code,
                    label: nextProps.commonState.move[0].destination.state_code,
                };
            }
        }
        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.homeAdvisor &&
            nextProps.trustedProsState.homeAdvisor.results &&
            nextProps.trustedProsState.isGetHomeAdvisor
        ) {
            // console.log(nextProps.trustedProsState.homeAdvisor.results);
            nextProps.trustedProsState.isGetHomeAdvisor = false;
            currentState.homeAdvisorData = nextProps.trustedProsState.homeAdvisor.results;
        }

        return currentState;
    }

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                if (
                    country === 'USA' ||
                    country === 'US'
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    // this.state.moveId
                ) {
                    // this.props.commonAction.moveAddressUpdate({
                    //     destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    //     move_id: this.state.moveId,
                    // });
                    localStorage.setItem('TrustedProsAddress', `${streetNumber} ${route}, ${city}, ${state}, ${zip}`);
                    localStorage.setItem('CityStateZip', ` ${city}, ${state}, ${zip}`);

                    this.props.trustedProsAction.vendors({
                        address: `${city},${state},${zip}`,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    goBack = () => {
        // if (this.state.activeStep === 0) {
        this.props.history.push('/dashboard/ourvendors');
        // } else {
        //     this.setState({
        //         activeStep: this.state.activeStep - 1,
        //     });
        // }
    };

    handleSubmitRequest = async () => {
        const payload = {
            category_name: this.state.serviceName,
            email_address: this.state.userDetails.email ? this.state.userDetails.email : '',
            first_name: this.state.userDetails.first_name ? this.state.userDetails.first_name : '',
            last_name: this.state.userDetails.last_name ? this.state.userDetails.last_name : '',
            phone_number: this.state.userDetails.phone ? this.state.userDetails.phone : '',
            site_name: this.state.userDetails.site_name ? this.state.userDetails.site_name : '',
        };
        await this.props.trustedProsAction.requestVendors(payload);
        // this.setState({
        //     reqSubmitted: true,
        // });
    };
    handleSubmitRequest1 = async () => {
        const payload = {
            category_name: this.state.serviceName,
            email_address: this.state.userDetails.email ? this.state.userDetails.email : '',
            first_name: this.state.userDetails.first_name ? this.state.userDetails.first_name : '',
            last_name: this.state.userDetails.last_name ? this.state.userDetails.last_name : '',
            phone_number: this.state.userDetails.phone ? this.state.userDetails.phone : '',
            site_name: this.state.userDetails.site_name ? this.state.userDetails.site_name : '',
        };
        await this.props.trustedProsAction.requestVendors(payload);
        // this.setState({
        //     reqSubmitted: true,
        // });
    };
    handleRequestSearch = () => {
        this.setState({
            submittingReq: true,
        });
    };
    handleVendorSearch = () => {
        this.setState({
            disclaimerOpen: true,
        });

        if (
            this.props &&
            this.props.moverState &&
            this.props.moverState.updateDisclaimer &&
            this.props.moverState.updateDisclaimer.has_accepted_home_advisor_condition
        ) {
            if (this.state?.homeAdvisorData?.length > 0) {
                this.props.history.push({
                    pathname: '/dashboard/ourvendors/recommended',
                    state: {
                        service_name: this.state.serviceName,
                        id: this.state.serviceId,
                        homeTaskId: this.state.homeTaskId,
                        homeAdvisorData: this.state.homeAdvisorData,
                        description: this.state.yesField,
                        address: this.state.address,
                        taskId: this.state.taskId,
                        vendor_type: this.state.vendor_type,
                    },
                });
            }
        }

        if (this.props?.moverState?.updateDisclaimer?.has_accepted_home_advisor_condition) {
            if (this.state.homeTaskId && this.state.homeAdvisorApiCall) {
                this.state.homeAdvisorApiCall = false;
                const payload = {
                    address: this.state.address,
                    address_type: 'destination',
                    task_id: this.state.taskId,
                    vendor_type: this.state.vendor_type,
                };
                this.props.trustedProsAction.getHomeAdvisor(payload);
            }
        }

        // this.setState({
        //     submittingSearch: true,
        // });
    };
    handleContinueVendor = () => {
        this.setState({
            checkContinue: !this.state.checkContinue,
        });
    };
    continueClick = () => {
        if (this.state?.homeAdvisorData?.length) {
            this.props.history.push({
                pathname: '/dashboard/ourvendors/recommended',
                state: {
                    service_name: this.state.serviceName,
                    id: this.state.serviceId,
                    homeTaskId: this.state.homeTaskId,
                    homeAdvisorData: this.state.homeAdvisorData,
                    description: this.state.yesField,
                    address: this.state.address,
                    taskId: this.state.taskId,
                    vendor_type: this.state.vendor_type,
                },
            });

            let payload: any = {
                move_id: localStorage.getItem('moveId'),
                data: { has_accepted_home_advisor_condition: true },
            };
            this.props.moverAction.updateDisclaimer(payload);
        } else {
            this.setState({
                submittingSearch: true,
                disclaimerOpen: false,
            });
        }
    };
    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { reqSubmitted } = this.state;
        const { reqSubmitted1 } = this.state;
        let hello = this.state.submittingSearch;
        if (this.state.homeTaskId && this.state.homeAdvisorApiCall && this.state.checkContinue) {
            this.state.homeAdvisorApiCall = false;
            const payload = {
                address: this.state.address,
                address_type: 'destination',
                task_id: this.state.taskId,
                vendor_type: this.state.vendor_type,
            };
            this.props.trustedProsAction.getHomeAdvisor(payload);
        }
        return (
            <div className="trustedPros-carpenter-main-page">
                <div className="trustedPros-carpenter-title-div">
                    <div className="main-head-2">
                        <h2 className="heading">Trusted Pros</h2>
                        <div className="path">
                            <p className="Para"> Dashboard</p>
                            <p className="para-para">{'>'} Trusted Pros</p>
                        </div>
                    </div>
                    <div className="trustedProsCarpenterAddressDiv">
                        <div className="addressTextCarpenter">Your address</div>
                        <RoomIcon
                            className="addressRoomIcon"
                            style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF' }}
                        />
                        <AutoComplete
                            className={
                                isDouglasElliman
                                    ? 'trustedProsStepsAutoCompleteCarpenter'
                                    : 'trustedProsStepsAutoCompleteCarpenter1'
                            }
                            value={this.state.address}
                            onChange={e => {
                                this.setState({ address: e });
                            }}
                            onSelect={this.handleSelect}
                        />
                    </div>
                </div>

                <div className="trustedProsStepperCarpenter">
                    <div className="TrustedProsSteps">
                        <img src={arrowIcon} onClick={this.goBack} style={{ cursor: 'pointer' }} />

                        <div className="serviceTitleCarpenter">{this.state.serviceName}</div>
                        {/* <div className="vendorsImageDiv">
                            <img src={RequestVendors} />
                        </div> */}

                        {/* {!reqSubmitted ? ( */}
                        <div className="trustedProsCarpenterRow">
                            {/* <img src={NoVendorImage} /> */}
                            {/* <div className="trustedProsCarpenterDesc1">
                                    Seems like we’ll need to do some more research here…
                                </div>
                                <div className="trustedProsCarpenterDesc2">
                                    Click the button below and our research team will research and get back to you.
                                </div> */}
                            <div className="newCardsTrustedHomeFlow">
                                {hello === true ? (
                                    <TrustedProsRequest
                                        submit={reqSubmitted1}
                                        searchRequest={this.handleSubmitRequest1}
                                    />
                                ) : (
                                    <TrustedProsRequest
                                        submit={reqSubmitted}
                                        searchRequest={this.handleSubmitRequest}
                                    />
                                )}

                                {hello === true ? null : this.state.categoryQuestion ? (
                                    <HomeAdvisorCard searchVendor={this.handleVendorSearch} />
                                ) : null}
                            </div>
                            <div className="buttonCarpenter">
                                <Button
                                    // backgroundColor={
                                    //     slug && slug.primary_color ? slug.primary_color : '#273E59'
                                    // }
                                    // color="#fff"
                                    width="98px"
                                    height="40px"
                                    fontSize="14px"
                                    fontWeight="500"
                                    onClick={() => this.props.history.push('/dashboard/ourvendors')}
                                >
                                    Go Back
                                </Button>
                            </div>
                            {/* <Button
                                    onClick={this.handleSubmitRequest}
                                    // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                >
                                    Submit Request
                                </Button> */}
                        </div>
                    </div>
                </div>
                {this.props &&
                this.props.moverState &&
                this.props.moverState.updateDisclaimer &&
                this.props.moverState.updateDisclaimer.has_accepted_home_advisor_condition ? null : (
                    <Modal
                        isShowModal={this.state.disclaimerOpen}
                        onCloseModal={() => {
                            this.setState({
                                disclaimerOpen: false,
                            });
                        }}
                        showClose={false}
                        className="modalFolderCreateDisclaimer"
                    >
                        <div>
                            <div className="CloseIcon123">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        textAlign: 'left',

                                        fontFamily: 'Roboto',
                                    }}
                                >
                                    Disclaimer
                                </div>
                                <div className="closeIconWaranty">
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({
                                                disclaimerOpen: false,
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div className="homeAdvisorHeading">HomeAdvisor’s Terms & Conditions</div>
                            <div className="homeAdvisorSubData">
                                By clicking “Continue”, you affirm you have read and agree to the HomeAdvisor Terms and
                                you agree HomeAdvisor and its networks of Service Professionals may use automated
                                technology to deliver marketing calls, texts, or emails to the number or email you
                                provided regarding your project. You also agree HomeAdvisor and its affiliates may use
                                automated technology to deliver marketing calls, texts, or emails to you regarding
                                offers. Consent is not a condition of purchase.
                            </div>
                            <div className="homeAdvisorOnClick">
                                <span>
                                    {' '}
                                    <Checkbox
                                        className={isDouglasElliman ? 'deBlue' : 'deMoveColor'}
                                        checked={this.state.checkContinue}
                                        onClick={this.handleContinueVendor}
                                    />
                                </span>{' '}
                                By clicking “continue” you are agreeing to HomeAdvisor’s
                                <span
                                    onClick={() =>
                                        window.open('https://www.insuramatch.com/terms-and-conditions', '_blank')
                                    }
                                    className={isDouglasElliman ? 'deBlue' : 'deMoveColor'}
                                >
                                    Terms & Conditions{' '}
                                </span>
                                and accepting their communication and outreach methods.
                            </div>
                            <div className="borderDisclaimerLine"></div>
                            <div className="buttonDisclaimerHomeAdvisor">
                                <Button
                                    width="100px"
                                    border={
                                        slug && slug.primary_color
                                            ? `1px solid ${slug.primary_color}
       `
                                            : '1px solid #273E59'
                                    }
                                    borderRadius={'4px'}
                                    color={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                    backgroundColor={'#fff'}
                                    // color="#fff"
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                    onClick={() => {
                                        this.setState({
                                            disclaimerOpen: false,
                                        });
                                    }}
                                >
                                    Decline
                                </Button>
                                <Button
                                    backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                    width="100px"
                                    borderRadius={'4px'}
                                    disabled={!this.state.checkContinue}
                                    // color="#fff"
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                    onClick={this.continueClick}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        trustedProsAction: bindActionCreators(TrustedProsAction, dispatch),
        moverAction: bindActionCreators(MoverAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    moverState: state.move,
    trustedProsState: state.trustedPros,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsCarpenter);
