import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import {
    headerFailure,
    headerSuccess,
    moveGetSuccess,
    moveGetFailure,
    moveAddressUpdateFailure,
    moveAddressUpdateSuccess,
    realtorFailure,
    realtorSuccess,
    requestServiceFailure,
    requestServiceSuccess,
    requestServiceGetFailure,
    requestServiceGetSuccess,
    moveGetWithHashCodeSuccess,
    moveGetWithHashCodeFailure,
    appointmentGetFailure,
    appointmentGetSuccess,
    cancelAppointmentSuccess,
    cancelAppointmentFailure,
    scheduleCallPostSuccess,
    scheduleCallPostFailure,
} from './actions';
import {
    HEADER_OPTIONS,
    MOVE_API_GET,
    MOVE_ADDRESS_UPDATE,
    REALTOR_DETAILS,
    REQUEST_SERVICE,
    REQUEST_SERVICE_GET,
    MOVE_API_WITH_HASHCODE_GET,
    APPOINTMENT_DETAILS_GET,
    POST_CANCELLATION_GET,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED,
} from './actionTypes';
import apiJunction from '../../utils/api';
import history from '../../routes/History';
import { uiConfig } from '../../config';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* header(action: any) {
    let apiPath = 'realtors';
    let url = `/api/${apiPath}/service-list/?partner_slug=${action.payload.partner_slug}&fetch_post_move_service=${action.payload.fetchMoveService}`;

    if (action.payload.apiPath) {
        const { state: state, zipcode: zipcode, city: city} = action.payload
        apiPath = action.payload.apiPath;
        url = `/api/${apiPath}/services-list/?state=${state}&zip=${zipcode}&city=${city}&slug=${action.payload.slug}`;
    }

    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url,
        });
        if (response.data && response.status) {
            if(uiConfig.appType == "WITHOUT_AUTH"){
                const dashboardObj = {
                    "id": 1089999,
                    "name": "Dashboard",
                    "route" : "/",
                    "slug": "/",
                    "react_url_route": "dashboard"
                }
                yield put(headerSuccess({ header: [ dashboardObj, ...response.data ] }));
            }else{
                yield put(headerSuccess({ header: response.data }));
            }
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(headerFailure({ errorHeader: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* realtor(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/realtors/realtor-list/?slug=${action.payload.slug}`,
        });
        if (response && response.data && response.status) {
            if (response.data && response.data.length > 0) {
                localStorage.setItem('slug', JSON.stringify({...response.data[0], primary_font_color:"#ffffff" }));
                localStorage.setItem('displayName', response.data[0] && response.data[0].display_name);
                localStorage.setItem(
                    'concierge_reference_field',
                    response.data[0] && response.data[0].concierge_reference_field,
                );

                yield put(realtorSuccess({ realtor: response.data[0] }));
                if (
                    (response.data[0] && response.data[0].brokerage_slug === 'douglas-elliman') ||
                    (response.data[0] && response.data[0].brokerage_slug === 'douglas-elliman-demo')
                ) {
                    localStorage.setItem('isDouglasElliman', 'True');
                }
            }
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(realtorFailure({ errorRealtor: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* requestService(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/request-service/`,
            body: action.payload,
        });
        if (response.data && response.status) {
            yield put(requestServiceSuccess({ requestService: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(requestServiceFailure({ errorService: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* requestServiceGet() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/request-service/`,
        });
        if (response.data && response.status) {
            yield put(requestServiceGetSuccess({ requestService: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(requestServiceGetFailure({ errorService: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* moveGet(action: any) {
    let paramObj: { method: string; url: string; token?: string } = {
        method: 'get',
        url: `/api/move/move-api/`,
    };
    if (action.payload.token) {
        paramObj = { ...paramObj, token: action.payload.token };
    }
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, paramObj);
        if (response && response.data && response.status) {
            if (!action.payload.token) {
                localStorage.setItem('moveId', response.data[0] && response.data[0].id);
                localStorage.setItem('moveKey', response.data[0] && response.data[0].move_key);
            }
            yield put(moveGetSuccess({ move: response.data }));
            if (!action.payload.token) {
                localStorage.setItem('moveId', response.data[0] && response.data[0].id);
                localStorage.setItem('moveKey', response.data[0] && response.data[0].move_key);
            }
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(moveGetFailure({ errorMove: (e as Error).message }));

        // history.push('/server-error');
    }
}

function* moveAddressUpdate(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/move/move-api/${action.payload.move_id}/`,
            body: action.payload,
            headers: { 'Content-Type': 'application/json' },
        });
        if (response.data && response.status) {
            yield put(moveAddressUpdateSuccess({ moveAddress: { ...response.data, status: response.status } }));
        } else {
            store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(moveAddressUpdateFailure({ errorMoveAddress: (e as Error).message }));
        store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

function* moveGetWithHashCode(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-api/?hash_code=${action.payload.hashCode}`,
        });

        if (response.data && response.data.length && response.status) {
            yield put(moveGetWithHashCodeSuccess({ moveWithHashCode: response.data }));
            localStorage.setItem('customrealtorname', response.data[0].custom_realtor_name);
            localStorage.setItem('token', response.data[0].user_ref.token);
            localStorage.setItem('moveId', response.data[0].id);
        } else {
            localStorage.clear();
            history.push('/signup');
        }
    } catch (e) {
        yield put(moveGetWithHashCodeFailure({ errorMoveWithHashCode: (e as Error).message }));
    }
}

//appointment
function* getAppointment(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/appointment-details/${action.payload.hash_code}`,
        });
        if (response.data && response.status) {
            yield put(appointmentGetSuccess({ appointmentDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(appointmentGetFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//cancellation
function* postCancellation(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `csr/api/v1/appointment-cancellation/`,
            body: action.payload,
        });
        if (response.data && response.status) {
            yield put(cancelAppointmentSuccess({ cancelAppointment: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(cancelAppointmentFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

//schedule call post
function* scheduleCallPost(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/client-vendor-contact-request/${action.payload.move_id}/`,
            body: action.payload.body,
        });
        if (response && response.status && response.status === 202) {
            yield put(scheduleCallPostSuccess({ scheduleCallPost: response }));
            store.addNotification({
                title: 'Success',
                message: 'Call has been scheduled!',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
        }
    } catch (e) {
        yield put(scheduleCallPostFailure({ error: (e as Error).message }));
        // history.push('/server-error');
        store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

export default function* commonSaga() {
    yield all([
        takeLatest(HEADER_OPTIONS, header),
        takeLatest(MOVE_API_GET, moveGet),        
        takeLatest(MOVE_ADDRESS_UPDATE, moveAddressUpdate),
        takeLatest(REALTOR_DETAILS, realtor),
        takeLatest(REQUEST_SERVICE, requestService),
        takeLatest(REQUEST_SERVICE_GET, requestServiceGet),
        takeLatest(MOVE_API_WITH_HASHCODE_GET, moveGetWithHashCode),
        takeLatest(APPOINTMENT_DETAILS_GET, getAppointment),
        takeLatest(POST_CANCELLATION_GET, postCancellation),
        takeLatest(SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED, scheduleCallPost)
    ]);
}
