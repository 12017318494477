import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    className?: any;
    fromLanding?: boolean;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={77.66}
            height={73.019}
            viewBox="0 0 77.66 73.019"
            {...props}
            className={props.className}
        >
            <defs>
                <style>
                    {`.hsHomeSecurityprefix__b{fill:${
                        slug && slug.primary_color && props.checked
                            ? slug.primary_color
                            : props.fromLanding && props.checked
                            ? '#30ab86'
                            : props.checked
                            ? '#273E59'
                            : '#929dab'
                    }}`}
                </style>
            </defs>
            <g data-name="Group 39762">
                <g data-name="Group 32946">
                    <path
                        data-name="Path 38926"
                        d="M56.759.188a1.59 1.59 0 00-.837 1.4v11.605L39.785 1.445a1.59 1.59 0 00-1.87 0L.674 28.559a1.589 1.589 0 101.87 2.569l8.383-6.1v46.4a1.588 1.588 0 001.589 1.589h52.67a1.589 1.589 0 001.589-1.589v-46.4l8.382 6.1a1.59 1.59 0 001.87-2.57l-10.252-7.463V6.668a1.589 1.589 0 00-.712-1.324L58.388.264a1.591 1.591 0 00-1.629-.076zm6.838 69.652H14.102V22.712L38.85 4.695l24.746 18.017zm0-62.319v11.26l-4.5-3.273V4.547zm0 0"
                        // fill="#6b6c6f"
                        className="hsHomeSecurityprefix__b"
                    />
                </g>
                <path
                    data-name="Path 38899"
                    d="M34.825 50.986a1.423 1.423 0 001.988 0l14.3-13.945a1.424 1.424 0 10-1.988-2.038l-13.3 12.976-6.942-6.77a1.424 1.424 0 00-1.988 2.038z"
                    //   fill="#6b6c6f"
                    className="hsHomeSecurityprefix__b"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
