import React, { Component } from 'react';
import { AddTasks, EditTask } from '../../components/atoms/checklistComponents/addTasks';
import './checklistOverdue.scss';
import WeekSwitch from '../../components/atoms/checklistComponents/WeekSwitch';
import ChecklistCard from '../../components/atoms/checklistComponents/checklistCard';
import * as ChecklistAction from '../../store/checklist/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@material-ui/core';
import AddTaskCard from '../../components/atoms/checklistComponents/AddTaskCard';
import Modals from './checklistReminderModal';
import * as CommonAction from '../../store/common/actions';

type initialProps = {
    history?: any;
    checklistAction?: any;
    checklistState?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    showTasks: boolean;
    task: any;
    showAll: string;
    AnchorEl?: any;
    sort: any;
    history?: any;
    index: number;
    checklistKeys: any;
    reminderModal: boolean;
    date: any;
    reminders: any;
    emailId: string;
    phoneNumber: any;
    taskData: any;
    movingDate: any;
    emailId1: any;
    isSecondEmail: any;
    editAddtask: boolean;
    editId: any;
};

var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export class checklistOverdue extends Component<initialProps, initialState> {
    state: initialState = {
        showTasks: false,
        task: '',
        showAll: 'Show All',
        AnchorEl: null,
        sort: { value: 'Weekly', label: 'Weekly' },
        index: -1,
        checklistKeys: [],
        reminderModal: false,
        date: '',
        reminders: { phone: false, email: false },
        emailId: '',
        phoneNumber: '',
        taskData: {},
        movingDate: '',
        emailId1: '',
        isSecondEmail: false,
        editAddtask: false,
        editId: null,
    };

    componentDidMount() {
        this.props.commonAction.moveGet();
        this.props.checklistAction.getChecklist();
    }

    static getDerivedStateFromProps(nextProps: any, currentState: any) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].moving_date
        ) {
            currentState.movingDate = nextProps.commonState.move[0].moving_date;
        }
        if (nextProps && nextProps.checklistState) {
            if (
                nextProps.checklistState.checklistTask &&
                nextProps.checklistState.isAdded &&
                Object.keys(nextProps.checklistState.checklistTask).length > 0 &&
                currentState.showTasks
            ) {
                nextProps.checklistState.isAdded = false;
                currentState.task = '';
                currentState.showTasks = false;

                nextProps.checklistAction.getChecklist();
            }
            if (nextProps.checklistState.isDeleted) {
                nextProps.checklistState.isDeleted = false;
                currentState.editAddtask = false;
                currentState.editId = null;

                nextProps.checklistAction.getChecklist();
            }
            if (!nextProps.checklistState.isLoading && nextProps.checklistState.checklist) {
                currentState.checklistKeys = Object.keys(nextProps.checklistState.checklist);
            }

            if (
                nextProps.checklistState.checklistUpdateTask &&
                nextProps.checklistState.isUpdated &&
                Object.keys(nextProps.checklistState.checklistUpdateTask).length > 0
            ) {
                nextProps.checklistState.isUpdated = false;
                currentState.editAddtask = false;
                currentState.editId = null;
                nextProps.checklistAction.getChecklist();
            }
            if (
                nextProps.checklistState.setReminder &&
                nextProps.checklistState.isSetReminder &&
                Object.keys(nextProps.checklistState.setReminder).length > 0
            ) {
                nextProps.checklistState.isSetReminder = false;
                nextProps.checklistAction.getChecklist();
            }
            if (
                nextProps.checklistState.updateReminder &&
                nextProps.checklistState.isUpdateReminder &&
                Object.keys(nextProps.checklistState.updateReminder).length > 0
            ) {
                nextProps.checklistState.isUpdateReminder = false;
                nextProps.checklistAction.getChecklist();
            }
            if (nextProps.checklistState.isDeleteReminder) {
                nextProps.checklistState.isDeleteReminder = false;
                nextProps.checklistAction.getChecklist();
            }
        }
        if (currentState.index < 0 && currentState.checklistKeys.length > 0) {
            let month = new Date().toLocaleString('default', { month: 'long' });
            currentState.checklistKeys.length > 0 &&
                currentState.checklistKeys.find((el: any, index: number) => {
                    if (el.includes(month) && currentState.index < 0) {
                        currentState.index = index;
                    }
                });

            if (currentState.index < 0) currentState.index = 0;
        }
        return currentState;
    }

    handleShowTasks = () => {
        this.setState({
            showTasks: !this.state.showTasks,
        });
    };

    handleTasksChange = (e: any) => {
        this.setState({
            task: e.target.value,
        });
    };

    onSelectHandler = (e: any) => {
        this.setState({
            showAll: e.value,
        });
    };

    handleWeekly = () => {
        this.setState({
            sort: { value: 'Weekly', label: 'Weekly' },
        });

        this.handleClose();
    };

    handleMonthly = () => {
        this.setState({
            sort: { value: 'Monthly', label: 'Monthly' },
        });

        this.handleClose();
    };

    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    handlePrevWeek = () => {
        let { index } = this.state;
        if (index <= 0) {
            this.setState({
                index: 0,
            });
        } else {
            this.setState({
                index: index - 1,
            });
        }
    };

    handleNextWeek = () => {
        let { index, checklistKeys } = this.state;
        let len = checklistKeys.length;
        if (index >= len - 1) {
            this.setState({
                index: len - 1,
            });
        } else {
            this.setState({
                index: index + 1,
            });
        }
    };

    doneClicked = (id: any) => {
        let payload1 = {
            checklistId: id,
            payload: {
                is_completed: true,
            },
        };
        this.props.checklistAction.updateTask(payload1);
    };
    cardClicked = (url: any) => {
        window.open(url, '_blank');
    };

    reminderClicked = async (item: any) => {
        if (item.taskreminder) {
            let email = false,
                phone = false;
            if (item.taskreminder.reminder_option === 'Email') {
                email = true;
            } else if (item.taskreminder.reminder_option === 'Phone') {
                phone = true;
            } else if (item.taskreminder.reminder_option === 'Email & Phone') {
                email = true;
                phone = true;
            }
            await this.setState({
                emailId: item.taskreminder.primary_email ? item.taskreminder.primary_email : '',
                emailId1: item.taskreminder.secondary_email ? item.taskreminder.secondary_email : '',
                phoneNumber: item.taskreminder.primary_phone ? item.taskreminder.primary_phone : '',
                date:
                    item.taskreminder.datetime && item.taskreminder.datetime.length >= 16
                        ? `${item.taskreminder.datetime.substring(0, 16)}`
                        : '',
                reminders: { phone: phone, email: email },
            });
        }
        this.setState({ reminderModal: true, taskData: item });
    };
    closeModal = () => {
        this.setState({
            reminderModal: false,
            taskData: {},
            date: '',
            emailId: '',
            emailId1: '',
            phoneNumber: '',
            reminders: { phone: false, email: false },
        });
    };
    editClicked = (id: any, name: string) => {
        this.setState({ editAddtask: true, editId: id, task: name });
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e.target.value,
        });
    };

    handleSetReminders = (reminder: string) => {
        this.setState({ reminders: reminder });
    };

    handleEmail = (e: any) => {
        this.setState({
            emailId: e.target.value,
        });
    };
    handleEmail1 = (e: any) => {
        this.setState({
            emailId1: e.target.value,
        });
    };

    secondEmailShow = () => {
        this.setState({
            isSecondEmail: true,
        });
    };

    secondEmailRemove = () => {
        this.setState({
            isSecondEmail: false,
            emailId1: '',
        });
    };
    handlePhone = (e: any) => {
        this.setState({
            phoneNumber: e.target.value,
        });
    };

    setReminderFinal = () => {
        if (this.state.taskData && this.state.taskData.id && !this.state.taskData.taskreminder) {
            let reminderOption = '';
            if (this.state.reminders.email && this.state.reminders.phone) {
                if (this.state.emailId && this.state.phoneNumber) {
                    reminderOption = 'Email & Phone';
                } else if (this.state.emailId) {
                    reminderOption = 'Email';
                } else if (this.state.phoneNumber) {
                    reminderOption = 'Phone';
                }
            } else if (this.state.reminders.email && this.state.emailId) {
                reminderOption = 'Email';
            } else if (this.state.reminders.phone && this.state.phoneNumber) {
                reminderOption = 'Phone';
            }

            let payload = {
                checklist: this.state.taskData.id,
                datetime: this.state.date ? this.state.date : null,
                reminder_option: reminderOption,
                primary_email: this.state.reminders.email && this.state.emailId ? this.state.emailId : null,
                primary_phone: this.state.reminders.phone && this.state.phoneNumber ? this.state.phoneNumber : null,
                secondary_email: this.state.reminders.email && this.state.emailId1 ? this.state.emailId1 : null,
                secondary_phone: null,
            };
            this.props.checklistAction.setReminder(payload);
        } else {
            let reminderOption = '';
            if (this.state.reminders.email && this.state.reminders.phone) {
                if (this.state.emailId && this.state.phoneNumber) {
                    reminderOption = 'Email & Phone';
                } else if (this.state.emailId) {
                    reminderOption = 'Email';
                } else if (this.state.phoneNumber) {
                    reminderOption = 'Phone';
                }
            } else if (this.state.reminders.email && this.state.emailId) {
                reminderOption = 'Email';
            } else if (this.state.reminders.phone && this.state.phoneNumber) {
                reminderOption = 'Phone';
            }

            let payload = {
                checklistId: this.state.taskData.taskreminder.id,
                payload: {
                    checklist: this.state.taskData.id,
                    datetime: this.state.date ? this.state.date : null,
                    reminder_option: reminderOption,
                    primary_email: this.state.reminders.email && this.state.emailId ? this.state.emailId : null,
                    primary_phone: this.state.reminders.phone && this.state.phoneNumber ? this.state.phoneNumber : null,
                    secondary_email: this.state.reminders.email && this.state.emailId1 ? this.state.emailId1 : null,
                    secondary_phone: null,
                },
            };
            this.props.checklistAction.updateReminder(payload);
        }
        this.closeModal();
    };

    deleteReminderFinal = () => {
        if (this.state.taskData && this.state.taskData.taskreminder) {
            let payload = {
                checklistId: this.state.taskData.taskreminder.id,
            };
            this.props.checklistAction.deleteReminder(payload);
        }
        this.closeModal();
    };

    saveHandler = () => {
        if (this.state.task && this.state.movingDate && this.state.checklistKeys) {
            if (this.state.editAddtask && this.state.editId) {
                const payload = {
                    payload: { name: this.state.task },
                    checklistId: this.state.editId,
                };
                this.props.checklistAction.updateTask(payload);
            } else {
                let year = new Date().getFullYear();
                let date = parseInt(this.state.checklistKeys[this.state.index].match(/\d+/)[0]);
                let month = months.indexOf(this.state.checklistKeys[this.state.index].split(' ')[0]);
                let date1: any = new Date(this.state.movingDate);
                let date2: any = new Date(year, month, date);
                var Difference_In_Time = date2.getTime() - date1.getTime();
                var Days: any = Difference_In_Time / (1000 * 3600 * 24);

                const payload = {
                    name: this.state.task,
                    days_to_move: parseInt(Days),
                };
                this.props.checklistAction.addTask(payload);
            }
        }
    };
    closeHandler = () => {
        this.setState({ showTasks: false, task: '', editAddtask: false, editId: null });
    };
    deleteHandler = () => {
        if (this.state.editId) {
            this.props.checklistAction.deleteTask({ checklistId: this.state.editId });
        }
    };
    render() {
        const { showTasks, task, index, checklistKeys } = this.state;
        let cardsArray: any = [];
        if (
            this.props.checklistState &&
            this.props.checklistState.checklist &&
            Object.keys(this.props.checklistState.checklist).length > 0
        ) {
            for (const [key, value] of Object.entries(this.props.checklistState.checklist)) {
                if (key === checklistKeys[index]) {
                    cardsArray = value;
                }
            }
            cardsArray =
                this.state.showAll === 'Show All'
                    ? cardsArray.filter((item: any) => {
                          return item.is_overdue && !item.must_do && !item.is_completed;
                      })
                    : cardsArray.filter((item: any) => {
                          if (
                              item.associated_items &&
                              item.associated_items.length > 0 &&
                              item.associated_items[0].name === this.state.showAll
                          )
                              return item.is_overdue && !item.must_do && !item.is_completed;
                      });
        }

        return (
            <div>
                <div className="ChecklistMain">
                    <h2 className="Checklistheading">Your Moving Checklist</h2>
                    <div className="Checklistpath">
                        <p
                            className="ChecklistPara"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Checklist</p>
                    </div>
                </div>
                <div className="ChecklistOverdueContent">
                    {checklistKeys && checklistKeys.length > 0 ? (
                        <WeekSwitch
                            onSelectHandler={this.onSelectHandler}
                            showAll={this.state.showAll}
                            handlePrevWeek={this.handlePrevWeek}
                            handleNextWeek={this.handleNextWeek}
                            handleMenu={(e: any) => this.handleMenu(e)}
                            handleClose={this.handleClose}
                            AnchorEl={this.state.AnchorEl}
                            sort={this.state.sort}
                            // startMonth={this.state.startMonth}
                            // endMonth={this.state.endMonth}
                            // startDt={this.state.startDt}
                            // endDt={this.state.endDt}
                            handleWeekly={this.handleWeekly}
                            handleMonthly={this.handleMonthly}
                            checklistKeys={checklistKeys}
                            index={index}
                        />
                    ) : (
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}

                    {/* tab div */}
                    <div className="checklistTabdiv">
                        <div className="checklistTabInnerDiv">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist' ||
                                        this.props.history.location.pathname === `/dashboard/checklist/`)
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist');
                                }}
                            >
                                Essentials And Overdue
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist/overdue' ||
                                        this.props.history.location.pathname === '/dashboard/checklist/overdue/')
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist/overdue');
                                }}
                            >
                                Overdue
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/checklist/completed' ||
                                        this.props.history.location.pathname === '/dashboard/checklist/completed/')
                                        ? 'checklistTabactive'
                                        : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/checklist/completed');
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        <div className="checklistAddTask" onClick={this.handleShowTasks}>
                            + Add Task
                        </div>
                    </div>
                    <div className="checklistAddTaskMobile" onClick={this.handleShowTasks}>
                        + Add Task
                    </div>
                    {showTasks && (
                        <AddTasks
                            handleTasksChange={this.handleTasksChange}
                            value={task}
                            onSave={this.saveHandler}
                            onClose={this.closeHandler}
                        />
                    )}
                    <div className="checklistOverViewMaindiv">
                        {cardsArray && cardsArray.length > 0 ? (
                            <div className="checklistMainCards">
                                {cardsArray.map((item: any) => {
                                    if (item.is_custom && this.state.editAddtask && item.id === this.state.editId) {
                                        return (
                                            <EditTask
                                                handleTasksChange={this.handleTasksChange}
                                                value={task}
                                                onSave={this.saveHandler}
                                                onClose={this.closeHandler}
                                                onDelete={this.deleteHandler}
                                            />
                                        );
                                    } else if (item.is_custom) {
                                        return (
                                            <AddTaskCard
                                                mainData={item.name}
                                                onClick={() =>
                                                    this.cardClicked(
                                                        item.do_it_now && item.do_it_now.new_url
                                                            ? item.do_it_now.new_url
                                                            : item.do_it_now && item.do_it_now.url
                                                            ? item.do_it_now.url
                                                            : '',
                                                    )
                                                }
                                                doneClicked={() => this.doneClicked(item.id)}
                                                reminderClicked={() => this.reminderClicked(item)}
                                                editClicked={() => this.editClicked(item.id, item.name)}
                                                reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                            />
                                        );
                                    } else {
                                        return (
                                            <ChecklistCard
                                                mainData={item.name}
                                                paraData={item.description}
                                                blueCard={'Overdue'}
                                                isQuote={false}
                                                onClick={() =>
                                                    this.cardClicked(
                                                        item.do_it_now && item.do_it_now.new_url
                                                            ? item.do_it_now.new_url
                                                            : item.do_it_now && item.do_it_now.url
                                                            ? item.do_it_now.url
                                                            : '',
                                                    )
                                                }
                                                doneClicked={() => this.doneClicked(item.id)}
                                                reminderClicked={() => this.reminderClicked(item)}
                                                reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                                buttonText={
                                                    item.do_it_now && item.do_it_now.heading
                                                        ? item.do_it_now.heading
                                                        : 'Read It'
                                                }
                                            />
                                        );
                                    }
                                })}
                            </div>
                        ) : (
                            <div className="dataAvailable">No task available</div>
                        )}
                    </div>
                </div>
                <Modals
                    calender={false}
                    reminderModal={this.state.reminderModal}
                    closeModal={this.closeModal}
                    date={this.state.date}
                    reminders={this.state.reminders}
                    emailId={this.state.emailId}
                    phoneNumber={this.state.phoneNumber}
                    taskData={this.state.taskData}
                    dateHandler={(e: any) => this.dateHandler(e)}
                    handleSetReminders={(val: any) => this.handleSetReminders(val)}
                    handleEmail={(e: any) => this.handleEmail(e)}
                    handlePhone={(e: any) => this.handlePhone(e)}
                    setReminderFinal={() => this.setReminderFinal()}
                    deleteReminderFinal={() => this.deleteReminderFinal()}
                    handleEmail1={(e: any) => this.handleEmail1(e)}
                    emailId1={this.state.emailId1}
                    secondEmailShow={() => this.secondEmailShow()}
                    secondEmailRemove={() => this.secondEmailRemove()}
                    isSecondEmail={this.state.isSecondEmail}
                    buttonText={
                        this.state.taskData && this.state.taskData.taskreminder ? 'Update Reminder' : 'Set Reminder'
                    }
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        checklistAction: bindActionCreators(ChecklistAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    checklistState: state.checklist,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(checklistOverdue);
