import React, { Component } from 'react';
import './driving.scss';
import DrivingLicenceGetStartImage from '../../Assets/images/DrivingLicence/drivingLicenceGetStart.png';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as signupAction from '../../store/login/signup/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import { UIContext } from '../../UIContext';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import localStorage from '../../utils/localStorage';
import AnalyticsService from '../../utils/analytics-service';

type initialProps = {
    history?: any;
    insuranceAction?: any;
    insuranceState?: any;
    commonAction?: any;
    commonState?: any;
    signup?: any;
};

type initialState = {
    hashCode: any;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    slug: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    category_name: any;
    address: any;
    isError: boolean;
    isAnalitycsTriggerredOnce: any;
};

class DrivingLicenceGetStart extends Component<initialProps, initialState> {
    appContext = this.context;
    state: initialState = {
        hashCode: '',
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        slug: null,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        category_name: '',
        address: {},
        isError: false,
        isAnalitycsTriggerredOnce: {
            address: false
        }
    };
    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }

        this.props.commonAction.moveReset()
        this.props.signup.landingMoveReset()

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name && this.appContext?.appType !== 'WITHOUT_AUTH') {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Driver License',
                    category_name: 'Driver License',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
        let addressForLocalStorage = localStorage.getItem("pods_destination_address");
        if (addressForLocalStorage) {
            let checkIfDoublequotes = JSON.parse(addressForLocalStorage)
            this.setState({ address: checkIfDoublequotes });
        }
    }

    handleAnalytics = () => {
        if (this.state.address?.street) {
            this.setState({
                isError: false,
            });
        } else {
            this.setState({
                isError: true,
            });
            return null
        }
        this.props.history.push({
            pathname: '/dashboard/driverlicense'
        });
        if (this.appContext?.appType !== 'WITHOUT_AUTH') {
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Button Clicked',
            {
                page: 'Driver License',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'GET STARTED',
                card: false,
                card_title: '',
                card_type: 'Start Page',
                category_name: 'Driver License',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
        } else {
            AnalyticsService.buttonClicked({
                page: 'Drivers License',
                category_name: 'Drivers License Started Button',
                button_copy: 'Get Started',
                form_button: true,
            });
        }

    };
    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                                ? el.long_name
                                : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                const addressString = results[0].formatted_address;
                let addressObj = {
                    street: `${streetNumber} ${route}`,
                    city,
                    zipcode: zip,
                    address: addressString,
                    state
                };
                localStorage.setItem('pods_destination_address', addressObj);

                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: addressObj
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    onChangeHandlerStep1 = (e: any, name: string) => {
        let address: any;
        if (name === 'street') {
            address = { ...this.state.address, [name]: e };
        } else {
            address = { ...this.state.address, [name]: e.target.value };
        }

        this.setState(() => ({ address }));

        if (!this.state.isAnalitycsTriggerredOnce.address) {
            AnalyticsService.formFieldInput({ 
                page: "Drivers License",
                hasMinimalFields: true,
                category_name: 'Drivers License',
                form_field_name: 'Enter Address'
            });
        }
        
        this.setState({
            isAnalitycsTriggerredOnce: {
                address: true
            }
        })
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        return (
            <div>
                <div className="main-head-dl">
                    <h2 className="heading-dl-regular">Driver's License</h2>
                    <div className="path-dl">
                        <p
                            className="Para-dl"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (
                                    localStorage.getItem('isHomeOwner') &&
                                    localStorage.getItem('isHomeOwner') == 'true'
                                ) {
                                    this.props.history.push('/dashboardhomeOwner');
                                } else {
                                    this.props.history.push('/dashboard');
                                }
                            }}
                        >
                            Dashboard {'>'}
                        </p>{' '}
                        <p style={{ marginLeft: '5px' }}>Driver's License</p>
                    </div>
                </div>
                <div className="dl-Div">
                    <div className="mainPagedl">
                        <div className='dl-image'>
                            <img src={DrivingLicenceGetStartImage} className="dlImageMain" />
                        </div>
                        <h2 className="heading">
                            Update your driver's license
                        </h2>
                        <p className="sub-heading">
                            Check out your state resources for license & registration
                        </p>
                        <div className="lineDLPage"></div>
                        <div className="btn-div">
                            <div className='insurance-address' style={{
                                marginRight: 10
                            }}>
                                <AutoComplete
                                    value={this.state.address.street}
                                    onChange={e => this.onChangeHandlerStep1(e, 'street')}
                                    onSelect={this.handleSelect}
                                    placeholder="Enter Address"
                                    className='input'
                                />
                                {this.state.isError && <span className='error'> Please enter the address</span>}
                            </div>
                            <Button
                                backgroundColor={
                                    slug && slug.primary_color
                                        ? slug.primary_color
                                        : this.appContext.colors.button.bg
                                }
                                color={
                                    slug && slug.primary_font_color
                                        ? slug.primary_font_color
                                        : this.appContext.colors.button.text
                                }
                                fontSize="16px"
                                width="100%"
                                height="54px"
                                borderRadius={'4px'}
                                onClick={this.handleAnalytics}
                                disabled={!this.state.address?.street}
                            >
                                Get Started
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        commonAction: bindActionCreators(Action, dispatch),
        signup: bindActionCreators(signupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    insuranceState: state.insurance,
    commonState: state.common
});

DrivingLicenceGetStart.contextType = UIContext;

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLicenceGetStart);
