import React from 'react';
import { makeStyles } from '@material-ui/core';
import './dashboardCss/blogCardTopImage.scss';
import AnalyticsService from '../../utils/analytics-service';

type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    paragraph?: any;
    onClick?: any;
    buttonMargin?: any;
    buttonMargin2?: any;
    padding?: any;
    viewLink: string;
};

const BlogCardTopImage = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
                boxShadow: '0 0 10px #e4e6e8',
            },
        },
        checkroot: {},
    }));


    const handleViewClick = (blogUrl: string) => {
       AnalyticsService.buttonClicked({
            category_name: 'Blog View Link Clicked',
            button_copy: 'View',
            card: true,
            card_title: props.title,
            card_type: 'Blog Card',
        });

        blogUrl && window.open(blogUrl, '_blank', 'noopener,noreferrer');
    }


    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} blogCard-main-div`}
            style={{
                width: props.width ? props.width : '285px',
                height: props.height ? props.height : '370px',
                padding: props.padding ? props.padding : '0px',
                borderRadius: '0px',
                border: '1px solid #DADCE0',
            }}
        >
            <div className="blogCardTopImageDiv">
                <div className="blogCardTopImageImgDiv">
                    <img
                        src={props.icon}
                        height={props.imageHeight ? props.imageHeight : '100%'}
                        width={props.imageWidth ? props.imageWidth : '100%'}
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                </div>
                <div className="BlogCardTopImageContentDiv">
                    <div>
                        <div className="blogCardTopTitle">{props.title}</div>
                        <div className="blogCardTopImageSubTitle">{props.subTitle}</div>
                    </div>
                    <div
                        className={
                            isDouglasElliman ? 'blogCardTopImageView blogCardTopImageViewD' : 'blogCardTopImageView'
                        }
                    >
                        <span onClick={() => handleViewClick(props.viewLink)}>
                            View
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCardTopImage;
