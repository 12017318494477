import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import InsuranceHeader from '../../Assets/images/Yourbrokage.png';
import InsuranceProfile from '../../Assets/images/profile2.png';
// import Button from '../../components/atoms/Button';
import Avatar from '@material-ui/core/Avatar';
// import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import DropdownIcon from '../../Assets/images/chevron-down.svg';

import Divider from '@material-ui/core/Divider';
import './Navbar.css';
import { connect } from 'react-redux';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import ProfileImg from '../../Assets/images/profile2.png';

import { moveGet, header } from '../../store/common/actions';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { getMoveSelector } from '../../store/common/selectors';
// import Footer from './Footer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Popover } from '@material-ui/core';
import DashboardIcon from '../../Assets/images/headerImages/dashboard';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Logout from '../../Assets/images/logout';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from 'react-loading-skeleton';
import Relocation from '../../Assets/images/headerImages/homeManagement';
import DouglasLogo from '../../Assets/images/DouglasImages/DouglasLogo.svg';
// import Propertymanagement from '../../Assets/images/headerImages/property-management';

// douglas icons
import MortgageD from '../../Assets/images/DouglasImages/header/mortgage';
import InsuranceD from '../../Assets/images/DouglasImages/header/insurance';
import MovingD from '../../Assets/images/DouglasImages/header/moving';
import UpdateAddressD from '../../Assets/images/DouglasImages/header/updateAddress';
import UtilityProvidersD from '../../Assets/images/DouglasImages/header/utility';
import DriversLicenceD from '../../Assets/images/DouglasImages/header/drivingLicence';
import MoreIconD from '../../Assets/images/DouglasImages/header/more';
import InternetCableD from '../../Assets/images/DouglasImages/header/internet';
import HomeWarrantyD from '../../Assets/images/DouglasImages/header/homeWarranty';
import ExclusiveDealsD from '../../Assets/images/DouglasImages/header/exclusive';
import ReserveStorageD from '../../Assets/images/headerImages/reserveStorage';
import HomeSecurityD from '../../Assets/images/DouglasImages/header/homeSecurity';
import TitleD from '../../Assets/images/DouglasImages/header/title';
import TrustedProsD from '../../Assets/images/DouglasImages/header/trustedPros';
import ChecklistD from '../../Assets/images/DouglasImages/header/checklist';
import CarShippingD from '../../Assets/images/DouglasImages/header/carShipping';

//default icons

import Energy from '../../Assets/images/headerImages/energy';
import Document from '../../Assets/images/headerImages/document';
import Mortgage from '../../Assets/images/headerImages/mortgage';
import Insurance from '../../Assets/images/headerImages/home-insurance';
import Moving from '../../Assets/images/headerImages/moving-truck';
import UpdateAddress from '../../Assets/images/headerImages/home-address';
import UtilityProviders from '../../Assets/images/headerImages/hydro-power';
import DriversLicence from '../../Assets/images/headerImages/id-card';
import MoreIcon from '../../Assets/images/headerImages/more';
import InternetCable from '../../Assets/images/headerImages/livingroom';
import HomeWarranty from '../../Assets/images/headerImages/home-waranty';
import ExclusiveDeals from '../../Assets/images/headerImages/exclusiveDeals';
import ReserveStorage from '../../Assets/images/headerImages/reserveStorage';
import HomeSecurity from '../../Assets/images/headerImages/homeSecurity';
import Title from '../../Assets/images/headerImages/title';
import TrustedPros from '../../Assets/images/headerImages/trustedPros';
import Checklist from '../../Assets/images/headerImages/checklist';
import nextMoveGIF from '../../Assets/images/NextMove/Next_Move.gif';
import { UIContext } from '../../UIContext';
import  DashboardImage from '../../Assets/images/headerImages/dashboardPods';
import PodsFaq from '../../Assets/images/headerImages/pods-faq';
import AnalyticsService from '../../utils/analytics-service';

// import { hexToCSSFilter } from "hex-to-css-filter";

// const options = [
//     { value: 'None', label: 'None' },
//     { value: 'Atria', label: 'Atria' },
//     // 'Atria',
//     // 'Callisto',
//     // 'Dione',
//     // 'Ganymede',
//     // 'Hangouts Call',
// ];

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // marginBottom: '100px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: '15px',
        cursor: 'pointer',
    },
    tabRoot: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        marginTop: '10px',
        // marginBottom: '100px',
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

type initialProps = {
    history: any;
    commonState?: any;
};

const Navbar = (props: initialProps) => {

    const {appType: appType, domain: appDomain, mainDomain: mainDomain} = useContext(UIContext)
    let isPODS: boolean = appType == 'WITHOUT_AUTH' ? true : false;
    const dispatch = useDispatch();
    const classes: any = useStyles();
    const [value, setValue] = React.useState(0);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    // const [moveData, setMoveData] = React.useState({});
    // const [errorData, setErrorData] = React.useState(null);
    // const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const customerService = "(855) 940-1768"
    let headerOption: any = [];
    let moreOption: any = [];
    let AllOption: any = [];
    let totalValue: any = new Map();
    let slug: any = localStorage.getItem('slug');

    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    if (
        slug &&
        slug.slug === 'opentoclose' &&
        window.location.pathname === '/signUp' &&
        window.location.search.includes('hash_code')
    ) {
        // console.log(window.location);
        localStorage.setItem('otcbrokerage', 'otcOnBehalfOf');
    }
    if (
        slug &&
        slug.slug === 'opentoclose' &&
        window.location.pathname === '/signUp' &&
        !window.location.search.includes('hash_code')
    ) {
        // console.log(window.location);
        localStorage.removeItem('otcbrokerage');
    }

    let isHomeOwner: boolean = false;
    if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
        isHomeOwner = true;
    }

    const [left, setleft] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setleft(open);
    };

    let num: any = 7;
    if (window.screen.width >= 1920) {
        num = 8;
    }
    if (window.screen.width < 1025) {
        num = 5;
    }
    if (window.screen.width < 769) {
        num = 4;
    }

    const setCurrentTab = (url: string) => {
        url = url.split('/')[2];
        let matchedHeader = false
        if (headerOption && headerOption.length > 0 && totalValue && totalValue.size > 0 && url) {
            for (let [key, value1] of totalValue) {
                if (url === value1) {
                    matchedHeader = true
                    setValue(key);
                }
            }
            if (!matchedHeader){
                setValue(-1);
            }
        } else {
            if(window.location.pathname === '/'){
                setValue(0);    
            }else if(appType !== "WITHOUT_AUTH"){
                setValue(num + 1);
            }
        }
    }

    useEffect(() => {
        setCurrentTab(window.location.pathname);
    });

    useEffect(() => {
        if ( appType !== 'WITHOUT_AUTH' &&
            !(
                props.commonState &&
                props.commonState.move &&
                props.commonState.move.length > 0 &&
                props.commonState.move[0]
            )
        ) {
            dispatch(moveGet());
        }
    }, []);

    useEffect(() => {
        if(appType == 'WITHOUT_AUTH'){
            let addressObj: any = localStorage.getItem('pods_destination_address');
            let state = '', city= '', zipcode= ''
            try{
                if(addressObj){
                    addressObj = JSON.parse(addressObj)
                    state = addressObj['state']
                    city = addressObj['city']
                    zipcode = addressObj['zipcode']
                }
            } catch(_e){
                state = ''
                city = ''
                zipcode = ''
            }
            dispatch(header({ apiPath: "pods", slug: appDomain, state, city, zipcode }));
        } else if (slug) {
            const isHomeOwner = localStorage.getItem('isHomeOwner');
            const moveCheck = localStorage.getItem('moveId');
            dispatch(header({ partner_slug: slug.slug, fetchMoveService: isHomeOwner ? 1 : 0, moveId: moveCheck }));
        }
    }, []);

    // useEffect(() => {
    //     const data = move && move[0];
    //     setMoveData(data);
    // }, [move]);
    // useEffect(() => {
    //     setErrorData(error);
    // }, [error]);

    const handleMenuItemClick = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

           if (isPODS) {
            AnalyticsService.navigationClicked({ 
                page: "Dashboard",
                main_navigation_bar: false,
                more_drop_down_list: true,
                category_name: item.name,
             });
           } else {
            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: false,
                    more_drop_down_list: true,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
           }
        }
    };

    // const handleClick = (event: any) => {
    //     event.stopPropagation();
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClickMore = (event: any) => {
        event.stopPropagation();
        setAnchorEl1(event.currentTarget);
        // setValue(8);
    };
    const handleAnalytics = () => {};

    const handleAnalytics1 = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

           if (isPODS) {
            AnalyticsService.navigationClicked({ 
                page: "Dashboard",
                main_navigation_bar: true,
                more_drop_down_list: false,
                category_name: item.name,
             });
           } else {
            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: true,
                    more_drop_down_list: false,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
           }
            
        }
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleCloseMore = () => {
        setAnchorEl1(null);
    };
    const handleChange = (newValue: any) => {
        if (parseInt(newValue.currentTarget.id.split('-')[2]) !== num) {
            setValue(parseInt(newValue.currentTarget.id.split('-')[2]));
        }
        // setValue(newValue.currentTarget.innerText)
        // return (<Link to='/dashboard/mortgage'></Link>);
    };

    const logoutClicked = async () => {
        localStorage.clear();

        let brokerage: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.display_name
                ? props.commonState.move[0].realtor.display_name
                : '';
        let brokerage_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.brokerage_link
                ? props.commonState.move[0].realtor.brokerage_key
                : '';
        let agent_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.brokerage_link
                ? props.commonState.move[0].realtor.realtor_key
                : '';
        let agent_name: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.contact_name
                ? props.commonState.move[0].realtor.contact_name
                : '';

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        let move_id: any = localStorage.getItem('moveKey');
        await analytics.track(
            'Signed Out ',
            { brokerage_name: brokerage, brokerage_key: brokerage_key, agent_key: agent_key, agent_name: agent_name },
            { user_id: move_id },
        );
        props.history.push('/signUp');
    };
    const anaySeg = async (view: any) => {
        let brokerage: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.display_name
                ? props.commonState.move[0].realtor.display_name
                : '';
        let brokerage_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.id
                ? props.commonState.move[0].realtor.brokerage_key
                : '';
        let agent_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.id
                ? props.commonState.move[0].realtor.realtor_key
                : '';
        let agent_name: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.contact_name
                ? props.commonState.move[0].realtor.contact_name
                : '';
        let move_id: any =
            props.commonState.move && props.commonState.move[0] && props.commonState.move[0].id
                ? props.commonState.move[0].id
                : '';

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        await analytics.track(
            `${view}`,
            {
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: agent_name,
                page: 'HomeOwner Dashboard',
            },
            { user_id: move_id },
        );
    };
    const list = () => (
        <div
            className={`${clsx(classes.list)} ${appType === 'WITHOUT_AUTH' && 'pods-mobile-toggle'}`}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {appType == 'WITHOUT_AUTH' ? (
                <a href={`tel:${customerService}`}>
                    <ListItem
                        key={0}
                        style={{
                            padding: '15px 16px',
                        }}
                        className={
                            window.location.pathname === '/dashboard' ||
                            window.location.pathname === '/dashboardhomeowner'
                                ? 'mobileListActive'
                                : 'mobileList'
                        }
                    >
                        <ListItemText
                            style={{
                                fontSize: '14px',
                            }}
                            primary={customerService}
                        />
                    </ListItem>
                </a>
            ) : (
                <Link to={isHomeOwner ? `/dashboardhomeowner` : `/dashboard`}>
                    <ListItem
                        key={0}
                        style={{
                            padding: '15px 16px',
                        }}
                        className={
                            window.location.pathname === '/dashboard' ||
                            window.location.pathname === '/dashboardhomeowner'
                                ? 'mobileListActive'
                                : 'mobileList'
                        }
                    >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText
                            style={{
                                fontSize: '14px',
                            }}
                            primary="Dashboard"
                        />
                    </ListItem>
                </Link>
            )}
            <Divider />
            <List>
                {AllOption &&
                    AllOption.length > 0 &&
                    AllOption.map((item: any, key: number) => {
                        let icon = getIcon(item);
                        return (
                            <Link
                                key={key}
                                to={{
                                    pathname: `/dashboard/${item.route}`,
                                    state:
                                        props.commonState &&
                                        props.commonState.header &&
                                        props.commonState.header.length > 0
                                            ? props.commonState.header
                                            : '',
                                }}
                            >
                                <ListItem
                                    className={
                                        value === item.id &&
                                        window.location.pathname !== '/dashboard' &&
                                        window.location.pathname !== '/dashboardhomeowner'
                                            ? 'mobileListActive'
                                            : 'mobileList'
                                    }
                                    key={item.id}
                                    onClick={() => handleMenuItemClick(item)}
                                >
                                    <ListItemIcon>
                                        {/* <img src={icon}  /> */}
                                        {icon}
                                    </ListItemIcon>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            color: `${
                                                window.location.pathname === `/dashboard/${item.route}`
                                                    ? `#333333`
                                                    : '#6B6C6F'
                                            }`,
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                </ListItem>
                            </Link>
                        );
                    })}
            </List>
            {appType != 'WITHOUT_AUTH' && (
                <>
                    <div className="lineLogout"></div>
                    <div className="logoutButton1" onClick={() => logoutClicked()}>
                        <Logout className="logoutText" />
                        <div className="logoutText">Logout</div>
                    </div>
                </>
            )}
        </div>
    );

    const handleChangeRoute = () => {
        if (isHomeOwner) {
            props.history.push('/dashboardhomeowner');
        } else {
            props.history.push('/dashboard');
        }
    };

    const getIcon = (item: any) => {
        if (isDouglasElliman) {
            let icon: any = <Relocation className="tabIcons" item={item} />;
            if (item.route === 'mortgage') {
                if (value === item.id) {
                    icon = <MortgageD className="tabIcons" />;
                } else {
                    icon = <MortgageD className="tabIcons" />;
                }
            } else if (item.route === 'title') {
                if (value === item.id) {
                    icon = <TitleD className="tabIcons" />;
                } else {
                    icon = <TitleD className="tabIcons" />;
                }
            } else if (item.route === 'homewarranty' || item.route === 'home-warranty') {
                if (value === item.id) {
                    icon = <HomeWarrantyD className="tabIcons" />;
                } else {
                    icon = <HomeWarrantyD className="tabIcons" />;
                }
            } else if (item.route === 'movers') {
                if (value === item.id) {
                    icon = <MovingD className="tabIcons" />;
                } else {
                    icon = <MovingD className="tabIcons" />;
                }
            } else if (item.route === 'storage') {
                if (value === item.id) {
                    icon = <ReserveStorageD className="tabIcons" />;
                } else {
                    icon = <ReserveStorageD className="tabIcons" />;
                }
            } else if (item.route === 'address') {
                if (value === item.id) {
                    icon = <UpdateAddressD className="tabIcons" />;
                } else {
                    icon = <UpdateAddressD className="tabIcons" />;
                }
            } else if (item.route === 'utilities') {
                if (value === item.id) {
                    icon = <UtilityProvidersD className="tabIcons" />;
                } else {
                    icon = <UtilityProvidersD className="tabIcons" />;
                }
            } else if (item.route === 'internet') {
                if (value === item.id) {
                    icon = <InternetCableD className="tabIcons" />;
                } else {
                    icon = <InternetCableD className="tabIcons" />;
                }
            } else if (item.route === 'insurance') {
                if (value === item.id) {
                    icon = <InsuranceD className="tabIcons" />;
                } else {
                    icon = <InsuranceD className="tabIcons" />;
                }
            } else if (item.route === 'ourvendors') {
                if (value === item.id) {
                    icon = <TrustedProsD className="tabIcons" />;
                } else {
                    icon = <TrustedProsD className="tabIcons" />;
                }
            } else if (item.route === 'driverlicense') {
                if (value === item.id) {
                    icon = <DriversLicenceD className="tabIcons" />;
                } else {
                    icon = <DriversLicenceD className="tabIcons" />;
                }
            } else if (item.route === 'home-security') {
                if (value === item.id) {
                    icon = <HomeSecurityD className="tabIcons" />;
                } else {
                    icon = <HomeSecurityD className="tabIcons" />;
                }
            } else if (item.route === 'deals') {
                if (value === item.id) {
                    icon = <ExclusiveDealsD className="tabIcons" />;
                } else {
                    icon = <ExclusiveDealsD className="tabIcons" />;
                }
            } else if (item.route === 'checklist') {
                if (value === item.id) {
                    icon = <ChecklistD className="tabIcons" />;
                } else {
                    icon = <ChecklistD className="tabIcons" />;
                }
            } else if (item.route === 'autoease') {
                if (value === item.id) {
                    icon = <CarShippingD className="tabIcons" />;
                } else {
                    icon = <CarShippingD className="tabIcons" />;
                }
            } else if (item.route === 'my-documents') {
                if (value === item.id) {
                    icon = <Document className="tabIcons" />;
                } else {
                    icon = <Document className="tabIcons" />;
                }
            } else if (item.route === 'energy-savings') {
                if (value === item.id) {
                    icon = <Energy className="tabIcons" />;
                } else {
                    icon = <Energy className="tabIcons" />;
                }
            }
            return icon;
        } else {
            let icon: any = <Relocation className="tabIcons" item={item} />;
            if (item.route === 'mortgage') {
                if (value === item.id) {
                    icon = <Mortgage className="tabIcons" />;
                } else {
                    icon = <Mortgage className="tabIcons" />;
                }
            } 
            else if (item.route === 'title') {
                if (value === item.id) {
                    icon = <Title className="tabIcons" />;
                } else {
                    icon = <Title className="tabIcons" />;
                }
            } else if (item.route === 'homewarranty' || item.route === 'home-warranty') {
                icon = <HomeWarranty className="tabIcons" active={value === item.id}/>;
                
            } else if (item.route === 'movers') {
                if (value === item.id) {
                    icon = <Moving className="tabIcons" />;
                } else {
                    icon = <Moving className="tabIcons" />;
                }
            } else if (item.route === 'storage') {
                if (value === item.id) {
                    icon = <ReserveStorage className="tabIcons" />;
                } else {
                    icon = <ReserveStorage className="tabIcons" />;
                }
            } else if (item.route === 'address') {
                if (value === item.id) {
                    icon = <UpdateAddress className="tabIcons" />;
                } else {
                    icon = <UpdateAddress className="tabIcons" />;
                }
            } else if (item.route === 'utilities') {
                icon = <UtilityProviders className="tabIcons" active={value === item.id} />;
            } else if (item.route === 'internet') {
                icon = <InternetCable className="tabIcons" active={value === item.id}/>;
            } else if (item.route === 'insurance') {
                icon = <Insurance className="tabIcons" active={value === item.id} />;
            } else if (item.route === 'ourvendors') {
                if (value === item.id) {
                    icon = <TrustedPros className="tabIcons" />;
                } else {
                    icon = <TrustedPros className="tabIcons" />;
                }
            } else if (item.route === 'driverlicense') {
                icon = <DriversLicence className="tabIcons" active={value === item.id}/>;

            } else if (item.route === 'home-security') {
                icon = <HomeSecurity className="tabIcons" active={value === item.id}/>;

            } else if (item.route === 'deals') {
                if (value === item.id) {
                    icon = <ExclusiveDeals className="tabIcons" />;
                } else {
                    icon = <ExclusiveDeals className="tabIcons" />;
                }
            } else if (item.route === 'checklist') {
                    icon = <Checklist className="tabIcons" active={value === item.id}/>;
            
            } else if (item.route === 'autoease') {
                if (value === item.id) {
                    icon = <CarShippingD className="tabIcons" />;
                } else {
                    icon = <CarShippingD className="tabIcons" />;
                }
            } else if (item.route === 'my-documents') {
                if (value === item.id) {
                    icon = <Document className="tabIcons" />;
                } else {
                    icon = <Document className="tabIcons" />;
                }
            } else if (item.route === 'energy-savings') {
                icon = <Energy className="tabIcons"  active={value === item.id}/>;

            } else if(item.route === '/'){
                icon = <DashboardImage className="tabIcons" active={value === item.id || window.location.pathname === '/dashboard'}/>;

            }else if (item.route === 'home-insurance') {
                icon = <Insurance className="tabIcons" active={value === item.id} />;
            } else if(item.route === 'pods-faq'){
                icon = <PodsFaq className="tabIcons" active={value === item.id} />;
            }
            return icon;
        }
    };
    if (props.commonState && props.commonState.header && props.commonState.header.length > 0) {
        let array123 = props.commonState.header.filter((item: any) => item.slug !== 'exclusive_deals');

        array123.map((item: any, index: number) => {
            let slug =
                item.react_url_route && item.react_url_route.split('/').length > 1
                    ? item.react_url_route.split('/')[2]
                    : item.slug;
            // if (item.slug === 'home-insurance') {
            //     slug = 'home-insurance';
            // }

            if (index < num) {
                headerOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    id: index,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    id: index,
                });
                totalValue.set(index, slug);
            } else {
                moreOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    id: index,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    id: index,
                });
                totalValue.set(index, slug);
            }
        });

        if(appType == "WITHOUT_AUTH"){
            AllOption.push({
                name: 'FAQ',
                icon: '',
                route: 'pods-faq',
                id: props.commonState.header.length + 1,
            })
            moreOption.push({
                name: 'FAQ',
                icon: '',
                route: 'pods-faq',
                id: props.commonState.header.length + 1,
            })
        }
        //if (!isHomeOwner) {
            // moreOption.push({
            //     name: 'Checklist',
            //     icon: '',
            //     route: 'checklist',
            //     id: props.commonState.header.length + 1,
            // });

            // AllOption.push({
            //     name: 'Checklist',
            //     icon: '',
            //     route: 'checklist',
            //     id: props.commonState.header.length + 1,
            // });

            // totalValue.set(props.commonState.header.length + 1, 'checklist');

            // moreOption.push({
            //     name: 'Reserve storage facility',
            //     icon: '',
            //     route: 'storage',
            //     id: props.commonState.header.length + 1,
            // });
            // moreOption.push({
            //     name: 'Car Shipping',
            //     icon: '',
            //     route: 'autoease',
            //     id: props.commonState.header.length + 1,
            // });
            // AllOption.push({
            //     name: 'Reserve storage facility',
            //     icon: '',
            //     route: 'storage',
            //     id: props.commonState.header.length + 1,
            // });
            // AllOption.push({
            //     name: 'Car Shipping',
            //     icon: '',
            //     route: 'autoease',
            //     id: props.commonState.header.length + 1,
            // });
            // totalValue.set(props.commonState.header.length + 1, 'storage');
            // totalValue.set(props.commonState.header.length + 1, 'autoease');
        // }
    }
    let SkeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
       
        <div>
            <div className={`${classes.root} MobileScreenHeader`}>
                <AppBar position="fixed" className={`${appType === 'WITHOUT_AUTH' && 'pods-mobile'}`}>
                    <Toolbar>
                        <div className='headerContentWrapper'>
                            { appType != 'WITHOUT_AUTH' && (window.location.pathname === '/signUp' ||
                            window.location.pathname === '/servicerequirement' ||
                            window.location.pathname === '/laCarte' ||
                            window.location.pathname === '/scheduleacall' ||
                            window.location.pathname === '/signUp/next' ||
                            window.location.pathname.includes('/resetpassword') ||
                            window.location.pathname.includes('/setpassword') ||
                            window.location.pathname === '/premovesignup' ||
                            window.location.pathname === '/standalonepage' ||
                            window.location.pathname === '/standalonepagerecommendations' ||
                            window.location.pathname === '/standalonefinal' ||
                            window.location.pathname === '/dashboard/my-documents/preview' ||
                            window.location.pathname === '/login' )? null : (
                                <div>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                    >
                                        <MenuIcon onClick={toggleDrawer(true)} />
                                        <React.Fragment>
                                            <Drawer anchor="left" open={left} onClose={toggleDrawer(false)}>
                                                {list()}
                                            </Drawer>
                                        </React.Fragment>
                                    </IconButton>
                                </div>
                            )}
                            <div
                                onClick={() => {
                                    if(appType == 'WITHOUT_AUTH'){
                                        window.open(mainDomain, '_blank')
                                    } else {
                                        if (isHomeOwner) {
                                            props.history.push('/dashboardhomeowner');
                                        } else {
                                            props.history.push('/dashboard');
                                        }
                                    }
                                }}
                                className="MoblogoCover"
                            >
                                {isDouglasElliman ? (
                                    <img style={{ marginLeft: '-25px' }} src={DouglasLogo} />
                                ) : (
                                    <img src={slug && slug.logo} width="80px" />
                                )}
                            </div>
                           {appType === 'WITHOUT_AUTH' && <div className="mobileProfileText">
                                <a className="phoneNumber" href={`tel:+1 ${customerService}`}>
                                    {customerService}
                                </a>
                            </div>}
                            {slug && slug.is_brokerage == false ? (
                                <div>
                                    <Avatar
                                        style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                        alt="Remy Sharp"
                                        src={slug && slug.contact_logo ? slug.contact_logo : InsuranceProfile}
                                    />
                                </div>
                            ) : isDouglasElliman ? (
                                <div></div>
                            ) : null}
                        </div>
                    </Toolbar>
                </AppBar>
            </div>{' '}
            {appType === 'WITHOUT_AUTH' && <div className='pods-top-bar'> </div>}
            <div className={`${classes.root} bigScreenHeader`}>
                <AppBar position="fixed" className={`firstBar ${appType === 'WITHOUT_AUTH' && 'pods-firstBar'}`} style={{ zIndex: 10000 }}>
                    <div className="InnerNavDiv">
                        <Toolbar>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => {
                                            if(appType == 'WITHOUT_AUTH'){
                                                window.open(mainDomain, '_blank')
                                            } else {
                                                if (localStorage.getItem('token')) {
                                                    if (isHomeOwner) {
                                                        props.history.push('/dashboardhomeowner');
                                                    } else {
                                                        props.history.push('/dashboard');
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <div className="icon-button-image">
                                            <img src={
                                                // appType === 'WITHOUT_AUTH' ? {PODSLogo} : 
                                                isDouglasElliman ? DouglasLogo : slug && slug.logo} />
                                        </div>
                                    </IconButton>
                                    {
                                    // window.location.pathname === '/' ||
                                    appType == 'WITHOUT_AUTH' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/servicerequirement' ||
                                    window.location.pathname === '/laCarte' ||
                                    window.location.pathname === '/scheduleacall' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ||
                                    window.location.pathname.includes('/setpassword') ||
                                    window.location.pathname.includes('/resetpassword') ||
                                    window.location.pathname === '/premovesignup' ||
                                    window.location.pathname === '/standalonepage' ||
                                    window.location.pathname === '/standalonepagerecommendations' ||
                                    window.location.pathname === '/standalonefinal' ||
                                    window.location.pathname === '/login' ? null : (
                                        <Typography
                                            variant="h6"
                                            className={classes.title}
                                            noWrap
                                            onClick={handleChangeRoute}
                                        >
                                            {isHomeOwner ? 'Home Owner Dashboard' : 'Dashboard'}
                                        </Typography>
                                    )}
                                    {isHomeOwner ? (
                                        <div className="nextmoveIconMainDiv">
                                            <div>
                                                <img src={nextMoveGIF} />
                                            </div>
                                            <div
                                                className="nextMoveSymbol"
                                                // onClick={() => {
                                                //     anaySeg('Next Move Clicked');
                                                // }}
                                            >
                                                <div
                                                    className="clickNext"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        props.history.push('/dashboard/nextmove');
                                                        anaySeg('Next Move Clicked');
                                                    }}
                                                >
                                                    Click Here
                                                </div>
                                                <Typography
                                                    variant="h6"
                                                    className={classes.title}
                                                    noWrap
                                                    onClick={() => {
                                                        props.history.push('/dashboard/nextmove');
                                                        anaySeg('Next Move Clicked');
                                                    }}
                                                >
                                                    {isHomeOwner ? 'Your Next Move' : ''}
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : null}
                                    {/* {window.location.pathname === '/' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ? null : (
                                        <div className="service-main-div">
                                            Services
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                                className={classes.title}
                                            >
                                                <img className="dropdownIcon" src={DropdownIcon} />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}
                                            >
                                                {options.map(option => (
                                                    <MenuItem key={option.value} onClick={handleClose}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>
                                    )} */}
                                </div>
                                <div className="lastHeaderRow">
                                    {!isPODS ? 
                                        <>
                                            {slug && slug.is_brokerage == false ? (
                                                <div>
                                                    <Avatar
                                                        style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                                        alt="Remy Sharp"
                                                        src={slug && slug.contact_logo ? slug.contact_logo : InsuranceProfile}
                                                    />
                                                </div>
                                            ) : null}
                                            
                                            <span className="profileText">
                                                {slug && slug.contact_name ? slug.contact_name : ''}
                                                {slug &&
                                                slug.slug === 'opentoclose' &&
                                                slug.brokerage_slug === 'opentoclose' &&
                                                localStorage.getItem('otcbrokerage') === 'otcOnBehalfOf' &&
                                                slug &&
                                                slug.contact_name ? (
                                                    <div className="onbehalfof">on behalf of</div>
                                                ) : (
                                                    ''
                                                )}
                                                {slug &&
                                                slug.slug === 'opentoclose' &&
                                                slug.brokerage_slug === 'opentoclose' &&
                                                localStorage.getItem('otcbrokerage') === 'otcOnBehalfOf' &&
                                                slug.contact_name
                                                    ? localStorage.getItem('customrealtorname')
                                                        ? localStorage.getItem('customrealtorname')
                                                        : ''
                                                    : ''}
                                                <div className="dre-no"> {slug && slug.phone ? slug.phone : ''} </div>
                                                <div className="dre-no"> {slug && slug.dre_no ? slug.dre_no : ''} </div>
                                            </span>

                                            {window.location.pathname === '/' ||
                                            window.location.pathname === '/signUp' ||
                                            window.location.pathname === '/servicerequirement' ||
                                            window.location.pathname === '/laCarte' ||
                                            window.location.pathname === '/scheduleacall' ||
                                            window.location.pathname === '/signUp/' ||
                                            window.location.pathname === '/signUp/next' ||
                                            window.location.pathname.includes('/setpassword') ||
                                            window.location.pathname.includes('/resetpassword') ||
                                            window.location.pathname === '/premovesignup' ||
                                            window.location.pathname === '/standalonepage' ||
                                            window.location.pathname === '/standalonepagerecommendations' ||
                                            window.location.pathname === '/standalonefinal' ||
                                            window.location.pathname === '/dashboard/my-documents/preview' ||
                                            window.location.pathname === '/dashboard/savings-flow' ||
                                            window.location.pathname === '/dashboard/savings-flow/confirmSaving' ||
                                            window.location.pathname === '/dashboard/savings-flow/summary' ||
                                            window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
                                            window.location.pathname === '/dashboard/subscription_details/home_protection' ||
                                            window.location.pathname === '/login' ? null : (
                                                // <Button
                                                //     className="btnICbtn"
                                                //     height="40px"
                                                //     // width="125px"
                                                //     margin="0px 0px 0px 10px"
                                                //     width="100px"
                                                //     backgroundColor={
                                                //         slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'
                                                //     }
                                                //     // onClick={() => this.tabChangeHandler('addressCard')}
                                                //     onClick={() => logoutClicked()}
                                                // >
                                                //     Logout
                                                // </Button>

                                                <div className="logoutButton" onClick={() => logoutClicked()}>
                                                    <Logout className="logoutText" />
                                                    <div className="logoutText">Logout</div>
                                                </div>
                                            )}
                                        </>
                                        :
                                        <div className="profileText">
                                            <div className="phoneNumber">{customerService}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Toolbar>
                    </div>
                </AppBar>

                {appType != 'WITHOUT_AUTH' && (window.location.pathname === '/dashboard' ||
                (window.location.pathname === '/' && !localStorage.getItem('isHomeOwner')) ||
                window.location.pathname === '/servicerequirement' ||
                window.location.pathname === '/laCarte' ||
                window.location.pathname === '/scheduleacall' ||
                window.location.pathname === '/signUp' ||
                window.location.pathname === '/signUp/' ||
                window.location.pathname === '/signUp/next' ||
                window.location.pathname.includes('/setpassword') ||
                window.location.pathname.includes('/resetpassword') ||
                window.location.pathname === '/premovesignup' ||
                window.location.pathname === '/standalonepage' ||
                window.location.pathname === '/standalonepagerecommendations' ||
                window.location.pathname === '/standalonefinal' ||
                window.location.pathname === '/standalonefinal' ||
                window.location.pathname.includes('/dashboard/utilities/utilitiesSavings') ||
                window.location.pathname.includes('/dashboard/utilities/utilitiesConfirmation') ||
                window.location.pathname === '/dashboard/nextmove' ||
                window.location.pathname === '/dashboard/nextMoveFinal' ||
                window.location.pathname === '/dashboard/savings-flow' ||
                window.location.pathname === '/dashboard/savings-flow/awesomeSaving' ||
                window.location.pathname === '/dashboard/savings-flow/confirmSaving' ||
                window.location.pathname === '/dashboard/savings-flow/summary' ||
                window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
                window.location.pathname === '/dashboard/subscription_details/home_protection' ||
                window.location.pathname === '/login') ? null : (
                    <AppBar position="fixed" style={{ marginTop: '61px' }} className={`secondBar ${appType === 'WITHOUT_AUTH' && 'pods-secondBar'}`}>
                        <div className="links">
                            <div className={`${classes.tabRoot} Dashboard`} style={{ marginTop: '20px' }}>
                                <div>
                                    <Tabs
                                        value={value}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        className="headerTab"
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: {
                                                background: appType !== 'WITHOUT_AUTH' ?  slug && slug.primary_color ? slug.primary_color : '#273E59' : 'none',
                                            },
                                        }}
                                        // scrollButtons="auto"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        // aria-label="simple tabs example"
                                        aria-label="icon label tabs example"
                                        // className={classes.customTabRoot}
                                    >
                                        {headerOption && headerOption.length > 0
                                            ? headerOption.map((item: any, key: number) => {
                                                  let icon = getIcon(item);

                                                  return (
                                                      <Tab
                                                          key={key}
                                                          onClick={() => handleAnalytics1(item)}
                                                          component={Link}
                                                          to={{
                                                              pathname: item.route === '/' ? '/' :  `/dashboard/${item.route}`,
                                                              state:
                                                                  props.commonState &&
                                                                  props.commonState.header &&
                                                                  props.commonState.header.length > 0
                                                                      ? props.commonState.header
                                                                      : '',
                                                          }}
                                                          className={`${appType === 'WITHOUT_AUTH' && item.id === value && 'pods-font-color'}`}
                                                          label={item.name}
                                                          icon={
                                                              //   <img src={icon} className="tabIcons" />
                                                              icon
                                                          }
                                                          {...a11yProps(item.id)}
                                                      />
                                                  );
                                              })
                                            : num === 8
                                            ? SkeletonArray.map((_label, key: number) => {
                                                  return (
                                                      <Tab
                                                          key={key}
                                                          label={<Skeleton height={10} width={80} />}
                                                          icon={<Skeleton height={30} width={40} />}
                                                      />
                                                  );
                                              })
                                            : num === 5
                                            ? SkeletonArray.map((index, key: number) => {
                                                  if (index <= 6) {
                                                      return (
                                                          <Tab
                                                              key={key}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })
                                            : num === 4
                                            ? SkeletonArray.map((index, key: number) => {
                                                  if (index <= 5) {
                                                      return (
                                                          <Tab
                                                              key={key}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })
                                            : SkeletonArray.map((index, key: number) => {
                                                  if (index <= 8) {
                                                      return (
                                                          <Tab
                                                              key={key}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })}

                                        {headerOption &&
                                            headerOption.length > 0 &&
                                            moreOption &&
                                            moreOption.length > 0 && (
                                                <Tab
                                                    label={
                                                        open1 ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={handleAnalytics}
                                                            >
                                                                More
                                                                <ExpandLessIcon
                                                                    style={{
                                                                        fill: '#6b6c6f',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={handleAnalytics}
                                                            >
                                                                More
                                                                <ExpandMoreIcon
                                                                    height="5px"
                                                                    width="8px"
                                                                    style={{
                                                                        fill: '#6b6c6f',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    icon={
                                                        open1 || value > num ? (
                                                            isDouglasElliman ? (
                                                                <MoreIconD isActive={true} className="moreIcon" />
                                                            ) : (
                                                                <MoreIcon isActive={true} className="moreIcon" />
                                                            )
                                                        ) : isDouglasElliman ? (
                                                            <MoreIconD isActive={false} className="moreIcon" />
                                                        ) : (
                                                            <MoreIcon isActive={false} className="moreIcon" />
                                                        )
                                                    }
                                                    {...a11yProps(num)}
                                                    // onMouseOver={num >= 7 ? handleClickMore : () => {}}
                                                    // onClick={num < 7 ? handleClickMore : () => {}}
                                                    onClick={handleClickMore}
                                                />
                                            )}
                                    </Tabs>
                                    <Popover
                                        open={open1}
                                        anchorEl={anchorEl1}
                                        onClick={handleCloseMore}
                                        className="headerPopover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        {moreOption.map((item: any, key: number) => {
                                            let icon = getIcon(item);
                                            return (
                                                <div
                                                    key={key}
                                                    onClick={() => handleMenuItemClick(item)}
                                                    // onClick={() => handleMenuItemClick(item.route)}
                                                    className="HeaderDropdownValues"
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `/dashboard/${item.route}`,
                                                            state:
                                                                props.commonState &&
                                                                props.commonState.header &&
                                                                props.commonState.header.length > 0
                                                                    ? props.commonState.header
                                                                    : '',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '30%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {/* <img className="tabIcons" src={icon} /> */}
                                                            {icon}
                                                        </div>
                                                        <MenuItem style={appType === 'WITHOUT_AUTH' && item.id === value ? {color : slug.primary_color ? slug.primary_color : '#273E59' , fontWeight:'bold' } : {}}>{item.name}</MenuItem>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        {/* <Footer /> */}
                    </AppBar>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

// Navbar.contextType = UIContext

export default connect(mapStateToProps, null)(Navbar);
